<div class="dashboard">
  <div *ngIf="!userAccount">
    <div class="loading-box">
      <app-empty-state class="fh" [isLoading]="true"></app-empty-state>
    </div>
  </div>
  <div *ngIf="userAccount" class="parent">
    <div *ngIf="invite_success" class="success-box p-1 pl-2 mt-2 mb-3">
      <div class="font-14 text">
        Your invitation has been sent successfully!
      </div>
      <button mat-icon-button (click)="invite_success = false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="workspace && workspace.departmentId">
      <div>
        <div class="font-21 mb-2">
          {{ workspace.departmentName ? workspace.departmentName : "No" }}
          Department
        </div>
        <div class="description">
          <div class="font-16 mt-3">Manage the users in your department.</div>
          <ul>
            <li>You can add or remove users from your department.</li>
            <li>You can also change their 'On Call' status.</li>
          </ul>
        </div>
      </div>
      <div class="list pt-3">
        <app-department-members-list
          (membersToAdd)="membersToAdd($event)"
          [userAccount]="userAccount"
          [loading]="loading"
          [list]="list"
          [departmentId]="workspace.departmentId ? workspace.departmentId : ''"
          [companyId]="workspace.companyId"
          [departmentName]="
            userAccount.departmentName ? userAccount.departmentName : ''
          "
          [refreshCount]="refreshCount"
          (removeMember)="removeMemberFromDepartment($event)"
          (onCallChange)="toggleOnCall($event)"
        ></app-department-members-list>
      </div>
    </div>
    <div *ngIf="workspace && !workspace.departmentId">
      <div>Sorry, you do not belong to a department.</div>
    </div>
    <div *ngIf="!workspace">
      <app-empty-state
        [isLoading]="false"
        [isEmpty]="true"
        [title]="'No workspaces'"
        [message]="
          'You do not have any workspaces. Please contact us at support@celohealth.com'
        "
      ></app-empty-state>
    </div>
  </div>
</div>
