import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ProfileService } from "app/modules/profile/profile.service";
import { WorkspaceService } from "app/workspace/workspace.service";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import {
  BasicWorkplaceWithClaimsModel,
  CompanyBasicModel,
  ContactModel,
  ConversationModel,
  ConversationModelV2,
  FullUserProfileModel,
  VerificationStatus,
} from "types";
import { AlertService } from "../../../core/old/alert.service";
import { AnalyticsService } from "../../../core/old/analytics.service";
import { ConversationService } from "../../../core/old/conversation.service";
import { SharedService } from "../../../core/old/shared.service";
import { UserAccountService } from "../../../core/old/user-account.service";
import { ContactSelection } from "../search-result/search-result.component";
import { Contact } from "./../contacts-expansion-panel/contacts-expansion-panel.component";

// TODO Add actual type definition for attachments and attachmentType
export interface NewChatComponentData {
  attachments?: any[];
  attachmentType?: string;
  type?: PageType;
  addParticipantsParameters?: AddParticipantsParameters;
  workspaceId?: string;
  workspaceIdToHide?: string;
  contactsToFilter?: any;
}

export enum PageType {
  NewChat = "new_chat",
  NewGroup = "new_group",
  NewCase = "new_case",
  NewBroadcast = "new_broadcast",
  CaseDetails = "case_details",
  GroupDetails = "group_details",
  AddGroupParticipants = "add_group_participants",
  AddCaseParticipants = "add_case_participants",
  AddTeamParticpants = "add_team_participants",
  DepartmentAdmin = "department_admin",
  WorkspaceAdmin = "workspace_admin",
}
export enum SelectionLabel {
  Connection = "connection",
  Colleague = "colleague",
  Suggestion = "suggestion",
}

export interface AddParticipantsParameters {
  currentParticipants: Contact[];
  onSave: (contacts: Contact[]) => Observable<void>;
}

export interface SelectedContacts {
  map: {
    [x: string]: Contact;
  };
  list: Contact[];
}

interface PageMetadata {
  title: string;
  type: string;
  show_participants: boolean;
  hidden: boolean;
  max_visible_workspaces: number;
  directory: boolean;
  searchable: boolean;
  select_mode: boolean;
  show_search_bar: boolean;
  show_conversation_name?: boolean;
  hide_connections?: boolean;
  hide_other_workspaces?: boolean;
  hide_self?: boolean;
  hide_network?: boolean;
  hide_suggestions?: boolean;
  max_capacity?: number;
  maxout_message?: string;
  item_salutation?: string;
  selectedContactsTitle?: string;
}

@Component({
  selector: "app-new-chat",
  templateUrl: "./new-chat.component.html",
  styleUrls: ["./new-chat.component.scss"],
})
export class NewChatComponent implements OnInit, OnDestroy {
  public PAGE_META: {
    [x in PageType]: PageMetadata;
  } = {
    new_chat: {
      title: "New Chat",
      type: "Chat",
      show_participants: false,
      hidden: true,
      max_visible_workspaces: 2,
      directory: true,
      searchable: true,
      select_mode: false,
      show_search_bar: true,
      max_capacity: 255,
      maxout_message: "Maximum participants reached",
      item_salutation: "participant",
    },
    new_group: {
      title: "New Group",
      type: "Group",
      show_participants: true,
      hidden: true,
      max_visible_workspaces: 2,
      directory: true,
      searchable: true,
      select_mode: true,
      show_search_bar: true,
      hide_network: true,
      max_capacity: 255,
      maxout_message: "Maximum participants reached",
      item_salutation: "participant",
    },
    new_case: {
      title: "New Case",
      type: "Case",
      show_participants: true,
      hidden: true,
      max_visible_workspaces: 2,
      directory: true,
      searchable: true,
      select_mode: true,
      show_search_bar: true,
      hide_network: true,
      max_capacity: 255,
      maxout_message: "Maximum participants reached",
      item_salutation: "participant",
    },
    new_broadcast: {
      title: "New Broadcast",
      type: "Broadcast",
      show_participants: true,
      hidden: false,
      max_visible_workspaces: 1,
      directory: true,
      searchable: true,
      select_mode: true,
      show_search_bar: true,
      hide_network: true,
      hide_connections: true,
      hide_other_workspaces: true,
      hide_self: true,
      hide_suggestions: true,
      max_capacity: null,
      maxout_message: "Maximum participants reached",
      item_salutation: "participant",
    },
    department_admin: {
      title: "Add users to your department",
      type: "Broadcast",
      show_participants: true,
      hidden: false,
      max_visible_workspaces: 1,
      directory: true,
      searchable: true,
      select_mode: true,
      show_search_bar: true,
      hide_network: true,
      hide_connections: true,
      hide_other_workspaces: true,
      hide_self: true,
      hide_suggestions: true,
      max_capacity: null,
      maxout_message: "Maximum participants reached",
      item_salutation: "participant",
    },
    workspace_admin: {
      title: "Add to workspace",
      type: "Workspace",
      show_participants: true,
      hidden: false,
      max_visible_workspaces: 22,
      directory: true,
      searchable: true,
      select_mode: true,
      show_search_bar: true,
      hide_network: true,
      hide_connections: false,
      hide_other_workspaces: false,
      hide_self: true,
      hide_suggestions: true,
      max_capacity: 256,
      maxout_message: "You can only add 20 users at a time.",
      item_salutation: "member",
    },
    case_details: {
      title: "New Case",
      type: "Case",
      show_participants: true,
      hidden: true,
      max_visible_workspaces: 2,
      directory: false,
      searchable: false,
      select_mode: false,
      show_search_bar: false,
      show_conversation_name: true,
      hide_network: true,
      max_capacity: 255,
      maxout_message: "Maximum participants reached",
      item_salutation: "participant",
    },
    group_details: {
      title: "New Group",
      type: "Group",
      show_participants: true,
      hidden: true,
      max_visible_workspaces: 2,
      directory: false,
      searchable: false,
      select_mode: false,
      show_search_bar: false,
      show_conversation_name: true,
      hide_network: true,
      max_capacity: 255,
      maxout_message: "Maximum participants reached",
      item_salutation: "participant",
    },
    add_group_participants: {
      title: "Add Participants",
      type: "Group",
      show_participants: true,
      hidden: true,
      max_visible_workspaces: 2,
      directory: true,
      searchable: true,
      select_mode: true,
      show_search_bar: true,
      show_conversation_name: true,
      hide_network: true,
      max_capacity: 255,
      maxout_message: "Maximum participants reached",
      item_salutation: "participant",
    },
    add_case_participants: {
      title: "Add Participants",
      type: "Case",
      show_participants: true,
      hidden: true,
      max_visible_workspaces: 2,
      directory: true,
      searchable: true,
      select_mode: true,
      show_search_bar: true,
      show_conversation_name: true,
      hide_network: true,
      max_capacity: 255,
      maxout_message: "Maximum participants reached",
      item_salutation: "participant",
    },
    add_team_participants: {
      title: "Add New Members",
      type: "TeamChat",
      show_participants: true,
      hidden: true,
      max_visible_workspaces: 2,
      directory: false,
      searchable: true,
      select_mode: true,
      show_search_bar: true,
      show_conversation_name: true,
      hide_network: true,
      max_capacity: 255,
      maxout_message: "Maximum members reached",
      item_salutation: "member",
      selectedContactsTitle: "Members",
    },
  };

  public isAddingParticipants: boolean = false;

  public showAll = false;
  public searchTerm: string;
  public workspaces = [];
  public workspacesMap: { [x: string]: CompanyBasicModel };

  partneredWorkspaces: CompanyBasicModel[] = [];
  myWorkspaces: CompanyBasicModel[] = [];
  userAccount: FullUserProfileModel;
  myCompaniesIds: string[];
  myVerifiedWorkspaceIds: string[];
  hasConnections = false;
  hasSuggestions = false;
  page_type: PageType = PageType.NewChat;

  addContact$: Subject<any> = new Subject<any>();
  canRemoveContact$: Subject<any> = new Subject<any>();
  removeContact$: Subject<any> = new Subject<any>();
  searchNow$: Subject<any> = new Subject<any>();
  groupedContacts$;
  @Output() selectionChange = new EventEmitter();
  @Output() selected = new EventEmitter();
  @Output() gobackevent = new EventEmitter();
  public uneditableContactIds: Set<string> = new Set();
  selectedContacts: SelectedContacts = {
    map: {},
    list: [],
  };

  playingMessage: any;
  selectionDone = false;
  q: string;
  patientData: any = {
    uid: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    gender: null,
  };

  loading: boolean;
  name: any;
  nameInvalid = true;
  attachments: any = [];
  attachmentType: any;
  searchMode: boolean;
  discoverable = true;
  modelChanged: Subject<string> = new Subject<string>();
  searchPending: boolean;
  inputSubscription: any;
  workspacesLoading = true;
  contactsToFilter: string[] = [];

  selectedContactsLabelMap: { [key: string]: string } = {};
  isOnline: boolean;
  myWorkspacesAndPartnerWorkspaceIds: string[];
  workspaceIdToHide: string;

  constructor(
    private userAccountService: UserAccountService,
    private sharedService: SharedService,
    private workspaceService: WorkspaceService,
    private alertService: AlertService,
    private profileService: ProfileService,
    private conversationService: ConversationService,
    private router: Router,
    private dialogReference: MatDialogRef<NewChatComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NewChatComponentData,
    private analyticsService: AnalyticsService
  ) {}

  public static openDepartmentAdminAddUsersDialog(
    matDialog: MatDialog,
    workspaceId: string,
    contactsToFilter: string[]
  ): MatDialogRef<NewChatComponent> {
    const data: NewChatComponentData = {
      type: PageType.DepartmentAdmin,
      workspaceId: workspaceId,
      contactsToFilter: contactsToFilter,
    };
    return matDialog.open(NewChatComponent, {
      autoFocus: false,
      panelClass: "new-chat",
      data,
    });
  }

  public static openWorkspaceAdminAddUsersDialog(
    matDialog: MatDialog,
    workspaceId: string,
    contactsToFilter: string[]
  ): MatDialogRef<NewChatComponent> {
    const data: NewChatComponentData = {
      type: PageType.WorkspaceAdmin,
      workspaceIdToHide: workspaceId,
      contactsToFilter: contactsToFilter,
    };
    return matDialog.open(NewChatComponent, {
      autoFocus: false,
      panelClass: "new-chat",
      data,
    });
  }

  public static openCreateNewBroadcastChooseParticipantsDialog(
    matDialog: MatDialog
  ): MatDialogRef<NewChatComponent> {
    const data: NewChatComponentData = {
      type: PageType.NewBroadcast,
    };
    return matDialog.open(NewChatComponent, {
      autoFocus: false,
      panelClass: "new-chat",
      data,
    });
  }

  ngOnInit(): void {
    this.inputSubscription = this.modelChanged
      .pipe(debounceTime(500))
      .subscribe((model) => {
        if (!this.searchPending) return;
        if (!this.searchTerm) return;
        if (model) {
          this.searchNow(model);
        }
      });

    if (this.data) {
      if (this.data.attachments) {
        this.attachments = this.data.attachments;
        this.attachmentType = this.data.attachmentType;
      }
      if (this.data.type) {
        this.page_type = this.data.type;
      }
      if (this.data.workspaceId) {
        this.saveselectedCompanyId(this.data.workspaceId);
      }
      if (this.data.workspaceIdToHide) {
        this.workspaceIdToHide = this.data.workspaceIdToHide;
      }
      if (this.data.contactsToFilter) {
        this.contactsToFilter = this.data.contactsToFilter;
      }
    }

    if (
      this.page_type === PageType.AddGroupParticipants ||
      this.page_type === PageType.AddCaseParticipants
    ) {
      this.isAddingParticipants = true;
      this.uneditableContactIds = new Set(
        this.data.addParticipantsParameters.currentParticipants.map(
          (p) => p.userId
        )
      );
    }

    this.userAccountService.getUserAccount(false, (userAccount) => {
      this.userAccount = userAccount;
      this.setMyWorkspaceIds(userAccount.workplaces);
      this.setMyVerifiedWorkspaceIds(userAccount.workplaces);
      this.getAllWorkspaces();

      if (this.page_type === PageType.NewBroadcast) {
        this.uneditableContactIds = new Set([this.userAccount.userId]);
      }
    });

    this.removeContact$.asObservable().subscribe((contact: any) => {
      this.canRemoveContact$.next(contact);
    });
    if (this.data && this.data.attachments) {
      this.attachments = this.data.attachments;
      this.attachmentType = this.data.attachmentType;
    }
    this.loadPrivacy();
    this.isOnline = this.sharedService.isOnline();
    if (!this.isOnline) this.sharedService.noInternetSnackbar();
  }

  loadPrivacy() {
    const path =
      environment.celoApiEndpoint + "/api/v2/Account/Privacy/Settings";
    this.sharedService.getObjectById(path).subscribe(
      (data) => {
        if (!data || !data.length) {
          return;
        }
        this.discoverable = this.userAccountService.getPrivacy(
          data,
          "discoverability",
          "network"
        );
      },
      (err) => {
        this.discoverable = false;
      }
    );
  }

  getAllWorkspaces() {
    this.userAccountService.getCompanies("", (workspaces) => {
      this.workspaces = workspaces;
      this.myWorkspacesAndPartnerWorkspaceIds = workspaces.map((w) => w.id);
      this.setPartneredWorkspaces(workspaces);
      this.setMyWorkspaces(workspaces);
      this.setWorkspacesMap([
        ...this.myWorkspaces,
        ...this.partneredWorkspaces,
      ]);
      this.workspacesLoading = false;
    });
  }

  setWorkspacesMap(workspaces: CompanyBasicModel[]): void {
    this.workspacesMap = {};
    workspaces.forEach((w) => (this.workspacesMap[w.id] = w));
  }

  setMyWorkspaceIds(workspaces: BasicWorkplaceWithClaimsModel[]) {
    this.myCompaniesIds = [];

    if (!workspaces) {
      return;
    }

    for (const workspace of workspaces) {
      this.myCompaniesIds.push(workspace.companyId);
    }
  }

  setMyVerifiedWorkspaceIds(workspaces: BasicWorkplaceWithClaimsModel[]) {
    this.myVerifiedWorkspaceIds = [];

    if (!workspaces) {
      return;
    }

    workspaces.forEach((workspace) => {
      if (workspace.verificationStatus == VerificationStatus.Verified)
        this.myVerifiedWorkspaceIds.push(workspace.companyId);
    });
  }

  setPartneredWorkspaces(workspaces: CompanyBasicModel[]) {
    this.partneredWorkspaces = this.workspaceService.getPartnerWorkspaces(
      workspaces,
      this.myCompaniesIds
    );
  }

  setMyWorkspaces(workspaces: CompanyBasicModel[]) {
    this.myWorkspaces = this.workspaceService.getMyWorkspacesOnly(
      workspaces,
      this.myCompaniesIds
    );

    if (this.page_type != PageType.NewBroadcast) {
      this.setRecentlySelectedWorkspaceToTop();
    }
  }

  onSearchInputChanged(text: string) {
    if (!text) {
      this.searchMode = false;
      return;
    }
    this.searchPending = true;
    if (text) {
      this.modelChanged.next(text);
      if (text.length == 1) {
        this.analyticsService.raiseEvent("search", {
          flow: "message",
        });
      }
    } else {
      this.modelChanged.next("");
      setTimeout(() => {
        this.searchNow("");
      }, 100);
    }
  }

  searchNow(value) {
    this.sharedService.ifOnline(() => {
      this.searchTerm = value;
      this.searchPending = true;
      if (value.length < 3) {
        this.alertService.showSnackBar(
          "Please enter at least 3 characters to begin your search.",
          3
        );
        return;
      }
      this.searchMode = true;
      setTimeout(() => {
        this.searchNow$.next(value);
      }, 100);
    });
  }

  onSelect(contact: Contact, selected: boolean, label?: SelectionLabel) {
    if (this.page_type === PageType.NewChat) {
      return;
    }
    if (selected) {
      this.addContact$.next(contact);
      if (label) this.selectedContactsLabelMap[contact.userId] = label;

      this.raiseCardClickEvent(contact, label);
    } else {
      this.removeContact$.next(contact);
      if (this.selectedContactsLabelMap[contact.userId]) {
        delete this.selectedContactsLabelMap[contact.userId];
      }
    }
  }

  onSearchContactSelection(selection: ContactSelection) {
    this.onSelect(selection.contact, true, selection.label);
  }

  onSearchContactClick(selection: ContactSelection) {
    this.onClick(selection.contact, selection.label);
  }

  onClick(contact, label: string) {
    this.sharedService.ifOnline(() => {
      this.raiseCardClickEvent(contact, label);
      if (
        (label == "suggestion" && contact["type"] != "Random") ||
        this.userAccountService.isInTheSameNetwork(contact, this.workspaces)
      ) {
        // you are allowed to create a chat if you are in the same network or the returned suggested contact is not of the type Random
        this.dialogReference.close();
        let instance = this;
        this.createChat(contact["userId"], function (res: ConversationModel) {
          if (!res) return;
          // instance.raiseNewChatEvent(res);
          instance.dialogReference.close();
          instance.router.navigate([`/conversations/${res.id}/messages`]);
          instance.initialMessage(res.id);
        });
      } else {
        this.openProfilePreview(contact["userId"]);
      }
    });
  }

  raiseNewChatEvent(conversation: ConversationModelV2) {
    this.analyticsService.buttonClickEvent("create_message", {
      flow: "message",
      source:
        this.attachments && this.attachments.length
          ? "share_button"
          : "new_message",
      conversationType: this.analyticsService.getFormatedConversationType(
        conversation.type
      ),
    });
  }

  raiseNewConversationEvent() {
    this.analyticsService.buttonClickEvent("create_message", {
      flow: "message",
      source:
        this.attachments && this.attachments.length
          ? "share_button"
          : "new_message",
      conversation_type: this.analyticsService.getFormatedConversationType(
        this.PAGE_META[this.page_type].type
      ),
      number_of_connections_selected:
        this.countUsersSelectedByLabels("connection"),
      number_of_colleagues_selected:
        this.countUsersSelectedByLabels("colleague"),
      number_of_suggested_selected:
        this.countUsersSelectedByLabels("suggestion"),
    });
  }

  raiseToggleEvents() {
    let buttonId = "show_less";
    if (this.showAll) {
      buttonId = "show_more";
    }
    this.analyticsService.buttonClickEvent(buttonId, {
      flow: "message",
    });
  }

  raiseCardClickEvent(contact: ContactModel, label?: string) {
    let params = {
      flow: "message",
      conversation_type: this.analyticsService.getFormatedConversationType(
        this.PAGE_META[this.page_type].type
      ),
      card_type: label,
    };
    if (contact["type"]) {
      params["suggestion_card_type"] = contact["type"].toLowerCase();
    }
    this.analyticsService.buttonClickEvent("user_card", params);
  }

  openProfilePreview(id) {
    this.profileService.openProfile(id);
  }

  changeSelectedContacts(selection: SelectedContacts) {
    this.selectedContacts = selection;
    if (this.selectedContacts && this.selectedContacts.list) {
      this.selectionChange.emit(this.selectedContacts.list);
    }
  }

  emitSelected() {
    this.selected.emit(this.selectedContacts.list);
  }

  capacityReached() {
    if (!this.playingMessage) {
      this.capacityReachedMessage(1);
    }
    return;
  }

  capacityReachedMessage(duration) {
    this.alertService.showSnackBar(
      this.PAGE_META[this.page_type].maxout_message,
      duration
    );
    this.playingMessage = true;
    setTimeout(() => {
      this.playingMessage = false;
    }, duration * 1000);
  }

  search(s: string) {
    this.q = s;
  }

  createBroadcast() {
    this.sharedService.ifOnline(() => {
      this.dialogReference.close(this.selectedContacts.list);
    });
  }

  addUsers() {
    this.sharedService.ifOnline(() => {
      this.dialogReference.close(this.selectedContacts.list);
    });
  }

  createConversation() {
    this.sharedService.ifOnline(this.createConversationCallback.bind(this));
  }

  private createConversationCallback() {
    const convo: any = {
      // was Createany but is out of date..
      name: this.name.trim(),
      participants: this.participantList(),
    };
    if (this.PAGE_META[this.page_type].type == "Case") {
      convo.type = "Case";
      if (this.patientData) {
        convo.patientData = this.patientData;
      }
    } else if (this.PAGE_META[this.page_type].type == "Group") {
      convo.type = "Group";
    }
    convo.id = this.sharedService.uuidv4();

    this.loading = true;
    // this.sharedService.closeAllDialogs();
    this.conversationService.createConversation(convo).subscribe(
      (createdConvo: ConversationModel) => {
        this.router.navigate([`/conversations/${createdConvo.id}/messages`]);
        this.initialMessage(createdConvo.id);
      },
      (error) => {
        this.loading = false;
        let errorMessage = "";
        if (error.status == 400) {
          const errBody = error.error;
          if (errBody.uid) {
            errorMessage += " " + errBody.uid[0];
          }
          if (errBody.dateOfBirth) {
            errorMessage += " " + errBody.dateOfBirth[0];
          }
        }

        if (error && error.error) {
          errorMessage = errorMessage
            ? errorMessage
            : this.sharedService.getErrorMessageFromErrorObject(error.error);
        }

        this.alertService.confirm(
          "Error creating conversation.",
          errorMessage,
          "Ok",
          ""
        );
      }
    );
    // this.raiseNewConversationEvent();
  }

  createChat(userId: any, callback?) {
    if (userId == this.userAccount.userId) {
      this.conversationService.createSelfChat(userId, function (createdConvo) {
        callback(createdConvo);
      });
      return;
    }
    const chatId = this.conversationService.getChatId(userId);
    this.conversationService.createChat([userId], chatId).subscribe(
      (createdConvo: any) => {
        callback(createdConvo);
      },
      (err) => {
        callback(undefined);
      }
    );
  }

  getChatId(receiver_id) {
    return this.conversationService.getChatId(receiver_id);
  }

  public participantList(): any[] {
    return this.selectedContacts.list.map((p) => ({ userId: p.userId }));
  }

  initialMessage(convoId) {
    if (!this.attachments || !this.attachments.length || !this.attachmentType) {
      return;
    }
    let files = [];
    let path = "";
    if (this.attachmentType === "MEDIA") {
      files = this.sharedService
        .extractIdsFromArray(this.attachments)
        .map((photoId) => ({
          photoId,
          content: "",
          marker: this.sharedService.uuidv4(),
        }));
      path =
        environment.celoApiEndpoint +
        "/api/v2/Conversations/{conversationId}/media/send".replace(
          "{" + "conversationId" + "}",
          String(convoId)
        );
    } else if (this.attachmentType === "DOCUMENT") {
      files = this.sharedService
        .extractIdsFromArray(this.attachments)
        .map((docId) => ({
          fileId: docId,
          content: "",
          marker: this.sharedService.uuidv4(),
        }));
      path = `${environment.celoApiEndpoint}/api/Conversations/${convoId}/SendPatientFile`;
    }
    return this.sharedService.postObjectById(path, {}, files).subscribe(
      (res) => {
        this.sharedService.closeAllDialogs();
      },
      (error) => {}
    );
  }

  backToNewChat() {
    this.page_type = PageType.NewChat;
    this.uneditableContactIds = new Set();
    this.unselectAllContacts(this.selectedContacts.list);
    this.clearConversationInfo();
  }

  clearConversationInfo() {
    this.patientData = {};
    this.name = "";
  }

  unselectAllContacts(contacts) {
    contacts.forEach((contact) => {
      this.removeContact$.next(contact);
    });
  }

  newGroup() {
    this.sharedService.ifOnline(() => {
      this.page_type = PageType.NewGroup;
      this.onNewConversation();
    });
  }

  newCase() {
    this.sharedService.ifOnline(() => {
      this.page_type = PageType.NewCase;
      this.onNewConversation();
    });
  }

  onNewConversation() {
    setTimeout(() => {
      this.addSelf();
    }, 100);
  }

  addSelf() {
    const currentUser = {
      firstName: this.userAccount["firstName"],
      lastName: this.userAccount["lastName"],
      profilePic: this.userAccount["picture"],
      userId: this.userAccount["userId"],
      disableRemove: true,
    };
    this.addContact$.next(currentUser);
    this.uneditableContactIds = new Set([currentUser.userId]);
  }

  onSave() {
    this.data.addParticipantsParameters
      .onSave(this.selectedContacts.list)
      .subscribe({
        next: () => this.dialogReference.close(),
        error: () =>
          this.alertService.alert(
            "Error",
            "Error updating conversation, please try again."
          ),
      });
  }

  onCancel() {
    this.dialogReference.close();
  }

  ngOnDestroy() {
    if (this.inputSubscription) {
      this.inputSubscription.unsubscribe();
    }
  }

  setRecentlySelectedWorkspaceToTop() {
    const recentlySelectedCompanyId = this.getRecentlySelectedCompanyId();
    if (!recentlySelectedCompanyId) {
      return;
    }
    this.sharedService.pullToTop(
      this.myWorkspaces,
      "id",
      recentlySelectedCompanyId
    );
  }

  getRecentlySelectedCompanyId() {
    return localStorage.getItem("company_selected");
  }

  saveselectedCompanyId(id) {
    localStorage.setItem("company_selected", id);
  }

  countUsersSelectedByLabels(label: string) {
    let count = 0;
    for (const iterator in this.selectedContactsLabelMap) {
      if (this.selectedContactsLabelMap[iterator] == label) {
        count++;
      }
    }
    return count;
  }

  exitSearchMode() {
    this.searchMode = false;
    this.searchTerm = "";
  }

  verifyIdentity() {
    this.alertService.verifyIdentityDialog();
  }
}
