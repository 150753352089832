import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { ConversationParticipantModelV2 } from "@types";

export enum ConversationPartcipantPopupMenuActionType {
  SecureMessage,
  ViewProfile,
  MakeRoleAdmin,
  RemoveRoleAdmin,
  RemoveFromRole,
}

export interface ConversationParticipantPopupMenuAction {
  participant: ConversationParticipantModelV2;
  type: ConversationPartcipantPopupMenuActionType;
}

@Component({
  selector: "app-conversation-participant-popup-menu",
  templateUrl: "./conversation-participant-popup-menu.component.html",
  styleUrls: ["./conversation-participant-popup-menu.component.scss"],
})
export class ConversationParticipantPopupMenuComponent {
  @Input() public participant: ConversationParticipantModelV2 | null = null;
  @Input() public isAdmin: boolean = false;
  @Input() public isCurrentUserAdmin: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public removeText: string | null = null;
  @Output() public action =
    new EventEmitter<ConversationParticipantPopupMenuAction>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | null = null;

  public menuX = 0;
  public menuY = 0;

  public onParticipantClick(mouseEvent: MouseEvent): void {
    this.menuX = mouseEvent.offsetX;
    this.menuY = mouseEvent.offsetY + 10;

    this.trigger?.menuClosed.subscribe(() => {
      this.menuX = 0;
      this.menuY = 0;
    });

    this.trigger?.openMenu();
  }

  public emitActionType(type: ConversationPartcipantPopupMenuActionType) {
    if (!this.participant) return;
    this.action.emit({ participant: this.participant, type });
  }

  public onSecureMessage(): void {
    this.emitActionType(
      ConversationPartcipantPopupMenuActionType.SecureMessage
    );
  }

  public onViewProfileClick(): void {
    this.emitActionType(ConversationPartcipantPopupMenuActionType.ViewProfile);
  }

  public onMakeAdminClick(): void {
    this.emitActionType(
      ConversationPartcipantPopupMenuActionType.MakeRoleAdmin
    );
  }

  public onRemoveAdminClick(): void {
    this.emitActionType(
      ConversationPartcipantPopupMenuActionType.RemoveRoleAdmin
    );
  }

  public onRemovePartipantClick(): void {
    this.emitActionType(
      ConversationPartcipantPopupMenuActionType.RemoveFromRole
    );
  }
}
