import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ConversationsModule } from "@modules/conversations/conversations.module";
import { FileUploadModule } from "ng2-file-upload";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CeloMaterialModule } from "./../../celo-material/celo-material.module";
import { SharedModule } from "./../shared/shared.module";
import { ConsentInfoComponent } from "./consents/consent-info/consent-info.component";
import { ConsentPickerComponent } from "./consents/consent-picker/consent-picker.component";
import { ConsentComponent } from "./consents/consent/consent.component";
import { ConsentsComponent } from "./consents/consents.component";
import { DocumentDetailsComponent } from "./documents/document-details/document-details.component";
import { DocumentComponent } from "./documents/document/document.component";
import { DocumentsComponent } from "./documents/documents.component";
import { DocumentsService } from "./documents/documents.service";
import { DropBoxComponent } from "./drop-box/drop-box.component";
import { LibraryLightboxComponent } from "./library-lightbox/library-lightbox.component";
import { LibraryRoutingModule } from "./library-routing.module";
import { LibrarySecondbarToolbarComponent } from "./library-secondbar-toolbar/library-secondbar-toolbar.component";
import { LibrarySelectImgsComponent } from "./library-select-imgs/library-select-imgs.component";
import { LibraryShareImgComponent } from "./library-share-img/library-share-img.component";
import { LibraryComponent } from "./library.component";
import { LibraryService } from "./library.service";
import { MediaDetailsComponent } from "./media/media-details/media-details.component";
import { MediaInfoComponent } from "./media/media-info/media-info.component";
import { MediaListComponent } from "./media/media-list/media-list.component";
import { MediaComponent } from "./media/media.component";

@NgModule({
  declarations: [
    LibraryShareImgComponent,
    ConsentInfoComponent,
    ConsentComponent,
    ConsentPickerComponent,
    ConsentsComponent,
    DocumentDetailsComponent,
    DocumentComponent,
    DocumentsComponent,
    DropBoxComponent,
    LibraryLightboxComponent,
    LibrarySecondbarToolbarComponent,
    LibrarySelectImgsComponent,
    LibraryComponent,
    MediaDetailsComponent,
    MediaInfoComponent,
    MediaListComponent,
    MediaComponent,
  ],
  imports: [
    CommonModule,
    CeloMaterialModule,
    SharedModule,
    LibraryRoutingModule,
    ConversationsModule,
    FileUploadModule,
    InfiniteScrollModule,
    PdfViewerModule,
  ],
  providers: [LibraryService, DocumentsService],
})
export class LibraryModule {}
