import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "app-expansion-panel-header",
  templateUrl: "./expansion-panel-header.component.html",
  styleUrls: ["./expansion-panel-header.component.scss"],
})
export class ExpansionPanelHeaderComponent {
  @Input() public expanded: boolean = false;

  @HostBinding("class.sticky")
  @Input()
  public sticky: boolean = true;

  @Input()
  public stickyTop: number = 0;

  @HostBinding("style.top")
  public get top() {
    return `${this.stickyTop}px`;
  }
}
