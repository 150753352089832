<div class="carousel">
  <!-- Carousel items -->
  <ng-container *ngFor="let item of carouselItems; let i = index">
    <div class="slide" [@slideInOut]="getState(i)">
      <ng-container [ngTemplateOutlet]="item.templateRef"></ng-container>
    </div>
  </ng-container>

  <!-- Next/previous buttons -->
  <button class="control scroll prev" (click)="previous()">
    <img src="../../assets/icons/icon-arrow-circle.svg" />
  </button>
  <button class="control scroll next" (click)="next()">
    <img
      src="../../assets/icons/icon-arrow-circle.svg"
      class="flip-horizontal"
    />
  </button>

  <!-- Pagination -->
  <div class="pages">
    <ng-container *ngFor="let item of carouselItems; let i = index">
      <button class="control pager" (click)="scrollTo(i)">
        <div
          class="circle"
          [ngClass]="{ highlight: i === currentSlideIndex }"
        ></div>
      </button>
    </ng-container>
  </div>
</div>
