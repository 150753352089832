import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { EmojiEvent } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { FileUploader } from "ng2-file-upload";
import { Subscription } from "rxjs";
import { FullUserProfileModel, Message } from "types";
import {
  AnalyticsService,
  ConService,
  MemoryService,
  MessageService,
} from "../../modules/core";
import { MessageComposeV2Component } from "../message-compose-v2/message-compose-v2.component";
import { ValidationError } from "../message-input/ValidationError";
import {
  MessageComposeModel,
  MessageInputErrorCode,
} from "./../message-input/message-input.component";

@Component({
  selector: "app-messages-bottom-text-bar",
  templateUrl: "./messages-bottom-text-bar.component.html",
  styleUrls: ["./messages-bottom-text-bar.component.scss"],
})
export class MessagesBottomTextBarComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Output() uploadPhotos = new EventEmitter<any>();
  @Output() addPhotos = new EventEmitter<any>();
  @Output() attachFiles = new EventEmitter<any>();
  @Output() cancelQuote = new EventEmitter<any>();
  @Output() submitMessage = new EventEmitter<MessageComposeModel>();
  @Output() changeMessage = new EventEmitter<string>();
  @Output() validationEvent = new EventEmitter<ValidationError | null>();

  @Input() conversationId;
  @Input() public quotedMessage: Message | null = null;
  @Input() conversation;
  @Input() conversationType;
  @Input() userAccount: FullUserProfileModel;
  @Input() pMap: { [key: string]: any };
  @Input() uploader: FileUploader;
  @Input() participants: any[];
  @Input() public maxLength: number = 3000;

  @ViewChild("messageCompose")
  public messageCompose: MessageComposeV2Component | null = null;

  emojiOpen: boolean;
  public initialValue: MessageComposeModel | null = null;

  public isValid: boolean = true;
  public submitTooltip: string = "";

  private messageQuotedSubscription: Subscription | null = null;

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.emojiOpen = false;
    }
  }

  constructor(
    private connectionService: ConService,
    private memoryService: MemoryService,
    private messageService: MessageService,
    private analyticsService: AnalyticsService,
    private eRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const changedConversationId = changes["conversationId"];
    if (
      !changedConversationId ||
      changedConversationId.isFirstChange() ||
      changedConversationId.previousValue === changedConversationId.currentValue
    ) {
      return;
    }

    const messageDraft =
      this.memoryService.memory.messageDrafts[
        changedConversationId.currentValue
      ];
    this.messageCompose?.setValue(messageDraft);
  }

  ngOnInit(): void {
    this.messageQuotedSubscription =
      this.messageService.messageQuoted.subscribe({
        next: () => {
          // Restore focus when replying to a message
          this.messageCompose.restoreFocus();
        },
      });
  }

  ngOnDestroy(): void {
    this.messageQuotedSubscription?.unsubscribe();
  }

  handleSubmit(message: MessageComposeModel) {
    this.submitMessage.emit(message);
    this.connectionService.updateUserAction(
      this.conversationId,
      false,
      "Typing"
    );
    this.emojiOpen = false;
    delete this.memoryService.memory.messageDrafts[this.conversationId];
  }

  emojiButton() {
    this.raiseMessageEvent("message_emoji");
  }

  attachButton() {
    this.raiseMessageEvent("message_attach");
  }

  secureLibraryButton() {
    this.raiseMessageEvent("message_attach_secure_library");
  }

  uploadButton() {
    this.raiseMessageEvent("message_attach_local_gallery");
  }

  raiseMessageEvent(button_id) {
    this.analyticsService.buttonClickEvent(button_id, {
      flow: "message",
      conversation_type:
        this.analyticsService.ConversationTypes[this.conversationType],
    });
  }

  addEmoji({ emoji, $event }: EmojiEvent) {
    $event.preventDefault();
    this.messageService.onInsertEmojiSubject.next(event);
    this.messageCompose?.insertText(emoji.native);
  }

  handleChange(message: MessageComposeModel) {
    this.updateMemory(message);
    if (!this.memoryService.memory.messageDrafts[this.conversationId]) {
      this.connectionService.updateUserAction(
        this.conversationId,
        false,
        "Typing"
      );
    } else {
      this.connectionService.updateUserAction(
        this.conversationId,
        true,
        "Typing"
      );
    }

    this.emojiOpen = false;
  }

  updateMemory(message: MessageComposeModel) {
    this.memoryService.memory.messageDrafts[this.conversationId] = message;
  }

  handlePaste(e: ClipboardEvent) {
    const dataTransfer = (e.clipboardData ||
      e["originalEvent"]?.clipboardData) as DataTransfer;
    const items = dataTransfer.items;

    // Don't trigger upload if there is a text representation of the content on the clipboard as we
    // prefer to use this over other formats if available
    const hasText = dataTransfer.getData("text/plain");
    if (hasText) return;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (
        item.type.indexOf("image") !== 0 &&
        item.type.indexOf("application/pdf") !== 0
      ) {
        continue;
      }

      const blob = item.getAsFile();
      e.preventDefault();
      this.uploader.addToQueue([blob]);
    }
  }

  public handleValidationError(validationError: ValidationError | null) {
    this.isValid = validationError === null;

    this.submitTooltip = "";
    if (validationError?.code === MessageInputErrorCode.MAX_LENGTH_EXCEEDED) {
      this.submitTooltip = `Message must be less then ${this.maxLength} characters`;
    }

    this.validationEvent.next(validationError);
  }
}
