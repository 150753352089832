<div
  [style.padding]="padding"
  [class.padded]="padding === null"
  class="content celo-hoverable celo-cursor-pointer"
  (click)="onClick()"
>
  <app-roles-icon
    start
    [backgroundSize]="avatarBackgroundSize"
    [size]="avatarSize"
  ></app-roles-icon>

  <div *ngIf="team" class="title mat-body">{{ team.name }}</div>
  <div *ngIf="isOnCall" class="on-call">On Call</div>
</div>
