<div class="avatar-container">
  <ngx-avatars
    [name]="name"
    [initialsSize]="3"
    [size]="size"
    bgColor="#bebebe"
    fgColor="#FFFFFF"
  ></ngx-avatars>

  <img
    *ngIf="src && (!srcError || fallback !== null) && !fallbackError"
    id="avatar"
    [src]="srcError ? fallback : src"
    [width]="size"
    [height]="size"
    (error)="onError($event)"
  />

  <img
    *ngIf="badgeSrc"
    id="badge"
    [src]="badgeSrc"
    [width]="badgeSize"
    [height]="badgeSize"
  />
</div>
