import { Injectable } from "@angular/core";
import { TitleStrategy, RouterStateSnapshot } from "@angular/router";

/**
 * TitleStrategy that does not update the document title.
 */
@Injectable({ providedIn: "root" })
export class VoidTitleStrategy extends TitleStrategy {
  updateTitle(snapshot: RouterStateSnapshot): void {
    // Intentionally do nothing. Title changes are managed by an ngrx effect as we need them to be triggered
    // from a navigation change and a change in the number of unread messages
  }
}
