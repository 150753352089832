import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  ConversationParticipantModelV2,
  FullUserProfileModel,
  MessageModel,
} from "types";

@Component({
  selector: "app-messages-row",
  templateUrl: "./messages-row.component.html",
  styleUrls: ["./messages-row.component.scss"],
})
export class MessagesRowComponent {
  @Input() message: MessageModel; // group of messages sent by one person in quick succession.
  @Input() userAccount: FullUserProfileModel;
  @Input() patientId: string;
  @Input() isOnDND: boolean;
  @Input() isFirstOfGroup: boolean;
  @Input() conversationId: string;
  @Input() conversationType: string;
  @Input() pMap: { [key: string]: ConversationParticipantModelV2 };
  @Output() selectMessage = new EventEmitter<MessageModel>();
  @Output() quote_clicked = new EventEmitter<MessageModel>();
  @Output() forward = new EventEmitter<MessageModel>();
  @Output() delete = new EventEmitter<MessageModel>();
  @Output() copyMessage = new EventEmitter<MessageModel>();
  @Input() searchQuery: string;

  @Input() fromMe: boolean;
  @Input() fromSender: boolean;
  @Input() fromSystem: boolean;
  @Input() public isBlocked: boolean = false;

  down: boolean;
  dragging: boolean;
  draggingFor = 0;

  @Input() public isConversationAdmin: boolean = false;
  @Input() public isActiveParticipant: boolean = false;
  @Input() public isPreviewMode: boolean = false;

  public onCopy() {
    this.copyMessage.emit(this.message);
  }

  public onReply() {
    this.quote_clicked.emit(this.message);
  }

  public onForward() {
    this.forward.emit(this.message);
  }

  public onDelete() {
    this.delete.emit(this.message);
  }

  mouseDown() {
    this.down = true;
    this.draggingFor = 1;
  }

  mouseMove() {
    if (this.down) {
      this.draggingFor++;
    }
  }

  mouseUp() {
    if (!this.fromSystem && this.draggingFor < 10) {
      this.selectMessage.emit(this.message);
    }

    this.down = false;
    this.draggingFor = 0;
  }

  public handleDoubleClick() {
    if (this.fromSystem || this.message.deletedOnUtc) return;
    this.quote_clicked.emit(this.message);
  }
}
