<div class="close-button">
  <button mat-icon-button mat-dialog-close>
    <i class="material-icons">close</i>
  </button>
</div>
<div class="page celo-input-outline relative">
  <div class="content">
    <div class="pic-box">
      <img class="pic" src="../../../assets/create-new-workspace.svg" alt="" />
    </div>
    <div class="title">Create New Workspace</div>
    <div class="celo-grey celo-text-14 subtitle">
      Once you have created a workspace you can invite others to join it.
    </div>
    <div class="fields">
      <mat-form-field appearance="outline" class="fw">
        <mat-label>Workspace</mat-label>
        <input
          matInput
          class="fw"
          type="name"
          autocomplete="off"
          placeholder="Workspace name"
          [(ngModel)]="name"
          id="nameField"
          name="nameField"
          #nameField="ngModel"
          maxlength="256"
        />
      </mat-form-field>
    </div>
    <div class="celo-grey celo-text-14 subtitle">
      Have an existing workspace?
      <a class="celo-link" (click)="joinWorkspace()">Join workspace</a>
    </div>
    <div class="buttons">
      <div class="button">
        <button
          (click)="createWorkspace()"
          [disabled]="!name || !nameField || nameField.invalid || loading"
          mat-button
          class="celo-primary-button squarebutton uppercase"
        >
          <mat-icon *ngIf="loading"
            ><mat-spinner diameter="20" strokeWidth="2"></mat-spinner
          ></mat-icon>
          <span>Create workspace</span>
        </button>
      </div>
    </div>
  </div>
</div>
