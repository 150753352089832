import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { AuthService } from "@modules/core";

@Injectable({
  providedIn: "root",
})
export class ForceCreatePasswordGuard  {
  public constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot) {
    if (this.authService.isForceCreatePassword()) {
      return this.router.parseUrl("/set-password");
    }
    return true;
  }
}
