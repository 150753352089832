import { Observable, Subject, forkJoin, of, throwError } from "rxjs";
import { map, toArray } from "rxjs/operators";
import {
  ApiRequestOptions,
  BasicContactModel,
  CompanyBasicModel,
  ContactModel,
  ContactModelPagedResult,
  ContactsRequestOptions,
  ContactsV1RequestOptions,
  IdentityVerificationStatus,
  ProfessionVerificationStatus,
  SuggestedUserModel,
  Team,
  UserProfileModel,
  VerificationStatus,
} from "types";
import { concatNotNull, distinct, flatMap, intersection, partition, sorted, union } from "utils";
import { CompaniesService, ContactsService } from "../core";
import { UserService } from "../core/services/user.service";
import {
  UserSelectionList,
  UserSelectionListItem,
} from "../shared/basic-user-selection-list/basic-user-selection-list.component";
import { localeCompareIfNotNull } from "./../../../utils/string-utils";
import { UserPickerSubmitCallback } from "./user-picker.service";
import { AccountService } from "./../core/services/account.service";
import { TeamsService } from "./../core/services/teams.service";
import { UsersService } from "./../core/services/users.service";
import {
  LoadedSelectionList,
  SelectionList,
  SelectionListType,
  SingleLoadableSelectionList,
  UserPickerProvider,
  UserSelectionListId,
} from "./basic-user-picker.service";
import { NetworkUserPickerMode } from "./network-user-picker.service";

export interface NetworkUserPickerProviderOptions {
  teamsService: TeamsService;
  contactsService: ContactsService;
  usersService: UsersService;
  accountService: AccountService;
  companiesService: CompaniesService;
  userService: UserService;
  disabledUserIds?: string[];
  initiallySelectedUserIds?: string[];
  excludeTeams?: boolean;
  excludeConnections?: boolean;
  excludeSuggestions?: boolean;
  asTeamId?: string | null;
  workspaceId?: string | null;
  excludeSelf?: boolean;
  mode?: NetworkUserPickerMode;
  submitCallback?: UserPickerSubmitCallback;
}

export class NetworkUserPickerProvider implements UserPickerProvider {
  private teamsService: TeamsService;
  private contactsService: ContactsService;
  private usersService: UsersService;
  private accountService: AccountService;
  private companiesService: CompaniesService;
  private userService: UserService;
  private disabledUserIds: Set<string>;
  private initiallySelectedUserIds: Set<string>;
  private currentUserId: string | null;
  private submitCallback: UserPickerSubmitCallback | null = null;
  private excludeTeams: boolean;
  private excludeConnections: boolean;
  private excludeSuggestions: boolean;
  private asTeamId: string | null;
  private workspaceId: string | null;
  private excludeSelf: boolean;
  private mode: NetworkUserPickerMode;

  private alwaysSelectedAndDisabledUserIds: Set<string>;

  private userGroupSubject = new Subject<SelectionList>();
  public userGroup$ = this.userGroupSubject.asObservable();

  public constructor(options: NetworkUserPickerProviderOptions) {
    this.teamsService = options.teamsService;
    this.contactsService = options.contactsService;
    this.usersService = options.usersService;
    this.accountService = options.accountService;
    this.companiesService = options.companiesService;
    this.userService = options.userService;
    this.disabledUserIds = new Set([...(options.disabledUserIds ?? [])]);
    this.initiallySelectedUserIds = new Set(options.initiallySelectedUserIds);
    this.currentUserId = this.userService.getUserId();
    this.excludeTeams = options.excludeTeams ?? false;
    this.excludeConnections = options.excludeConnections ?? false;
    this.excludeSuggestions = options.excludeSuggestions ?? false;
    this.asTeamId = options.asTeamId ?? null;
    this.workspaceId = options.workspaceId ?? null;
    this.excludeSelf = options.excludeSelf ?? false;
    this.mode = options.mode ?? NetworkUserPickerMode.CREATE;
    this.submitCallback = options.submitCallback ?? null;

    if (this.currentUserId) {
      this.disabledUserIds.add(this.currentUserId);
    }

    this.alwaysSelectedAndDisabledUserIds = intersection(
      this.initiallySelectedUserIds,
      this.disabledUserIds
    );
  }

  public getUserGroups(
    selectedUsers: UserSelectionList,
    isInit: boolean
  ): Observable<SelectionList[]> {
    const selectedUserIds = isInit
      ? this.initiallySelectedUserIds
      : new Set<string>(selectedUsers.map((u) => u.id));

    const userAccount$ = this.accountService.getAccount();

    const connections$ = this.excludeConnections
      ? of([])
      : this.contactsService
          .getContacts({ fetchAll: true })
          .pipe(flatMap((page) => page.data));

    // TODO filter by workspaceId if specified
    const companies$ = this.companiesService
      .getCompanies({ fetchAll: true })
      .pipe(flatMap((page) => page.data));

    const suggestions$ = this.excludeSuggestions
      ? of([])
      : this.usersService.getSuggestions();

    const team$ = this.asTeamId
      ? this.teamsService.getUserTeam({
          teamId: this.asTeamId,
          includePartnerWorkspaceIds: true,
          ignoreCache: true,
        })
      : of(null);

    const selectionLists$ = forkJoin({
      userAccount: userAccount$,
      connections: connections$,
      companies: companies$,
      suggestions: suggestions$,
      team: team$,
    }).pipe(
      map(({ userAccount, connections, companies, suggestions, team }) => {
        const userWorkspaceIds = new Set(
          (userAccount.workplaces
            ?.map((w) => w?.companyId)
            .filter((w) => w != null) as string[]) ?? []
        );

        const selectionLists: SelectionList[] = [];

        if (connections?.length) {
          const connectionsList = this.mapConnections(
            connections,
            selectedUserIds
          );
          selectionLists.push(connectionsList);
        }

        if (companies.length) {
          const companiesLists = this.mapCompanies(
            companies,
            userWorkspaceIds,
            selectedUserIds,
            team
          );
          selectionLists.push(...companiesLists);
        }

        if (suggestions?.length) {
          const suggestionsList = this.mapSuggestions(
            suggestions,
            selectedUserIds
          );
          selectionLists.push(suggestionsList);
        }

        return selectionLists;
      })
    );

    return selectionLists$;
  }

  private mapConnections(
    connections: UserProfileModel[],
    selectedUserIds: Set<string>
  ): SelectionList {
    const data: UserSelectionList = connections.map((connection) => ({
      id: connection.userId ?? "",
      name: concatNotNull([connection.firstName, connection.lastName]),
      isWorkspaceVerified:
        connection.workplaces?.some(
          (w) =>
            w.isActive && w.verificationStatus === VerificationStatus.Verified
        ) ?? false,
      isIdentityVerified:
        connection.identityVerificationStatus ===
          IdentityVerificationStatus.Verified ?? false,
      isProfessionVerified:
        connection.professions?.some(
          (p) => p.verificationStatus === ProfessionVerificationStatus.Verified
        ) ?? false,
      isSelected: connection.userId
        ? selectedUserIds.has(connection.userId)
        : false,
      isDisabled: connection.userId
        ? this.disabledUserIds.has(connection.userId)
        : false,
      fetchImage: connection.profilePic != null,
    }));
    return {
      id: UserSelectionListId.CONNECTIONS,
      expandableControlName: "Connections",
      type: SelectionListType.SINGLE_GROUP,
      isExpandable: true,
      isLoaded: true,
      data,
    };
  }

  private mapCompanies(
    companies: CompanyBasicModel[],
    userWorkspaceIds: Set<string>,
    selectedUserIds: Set<string>,
    asTeam?: Team | null
  ): SelectionList[] {
    const partnerWorkspaceIds = new Set(
      asTeam?.workspace?.partnerWorkspaceIds ?? []
    );
    const selectionLists = companies
      .filter((company) => {
        if (!company.id) throw new Error("Invalid company id");
        if (!asTeam) return true;
        return (
          company.id === asTeam.workspace?.id ||
          partnerWorkspaceIds.has(company.id)
        );
      })
      .map((company) => this.mapCompany(company, selectedUserIds, asTeam))
      .filter((selectionList) => selectionList !== null) as SelectionList[];
    const alphabetically = selectionLists.sort((a, b) =>
      localeCompareIfNotNull(a.name, b.name)
    );

    const [userWorkspaces, partnerWorkspaces] = partition(
      alphabetically,
      (selectionList) => userWorkspaceIds.has(selectionList.id)
    );

    return userWorkspaces.concat(partnerWorkspaces);
  }

  private mapCompany(
    company: CompanyBasicModel,
    selectedUserIds: Set<string>,
    asTeam?: Team | null
  ): SelectionList | null {
    if (!company.id) return null;
    return {
      id: company.id,
      name: company.name,
      type: SelectionListType.SUBGROUPED,
      isExpandable: true,
      isLoaded: false,
      data: null,
      load: () => this.loadCompany(company, selectedUserIds, asTeam),
    };
  }

  private loadCompany(
    company: CompanyBasicModel,
    selectedUserIds: Set<string>,
    asTeam?: Team | null
  ) {
    if (!company.id) throw new Error("Invalid company id");

    let teams$: Observable<Team[]> = of([]);

    if (!this.excludeTeams) {
      teams$ = this.teamsService
        .getWorkspaceTeams({
          workspaceId: company.id,
          fetchAll: true,
        })
        .pipe(
          flatMap((page) => page.data),
          map((teams) => {
            const activeTeams = this.teamsService.filterOutInactiveTeams(teams);
            return activeTeams.filter(
              (team) => !this.teamsService.isCurrentUserMember(team)
            );
          })
        );
    }

    const contacts$ = this.companiesService
      .getContacts({
        companyId: company.id,
        fetchAll: true,
        includeSelf: !this.excludeSelf,
      })
      .pipe(flatMap((page) => page.data));

    const joined = forkJoin({
      teams: teams$,
      contacts: contacts$,
    });

    joined.subscribe({
      next: ({ teams, contacts }) => {
        if (!company.id) throw new Error("Invalid company id");

        const groups: LoadedSelectionList<
          SelectionListType.SINGLE_GROUP,
          UserSelectionList
        >[] = [];

        if (teams.length) {
          const teamsGroup: LoadedSelectionList<
            SelectionListType.SINGLE_GROUP,
            UserSelectionList
          > = {
            id: `${company.id}-roles`,
            name: "Roles",
            type: SelectionListType.SINGLE_GROUP,
            isLoaded: true,
            isTeams: true,
            data: teams.map((team) => {
              if (!team.id) throw new Error("Invalid team id");
              if (!team.name) throw new Error("Invalid team name");
              return {
                id: team.id,
                name: team.name,
                isDisabled: false,
                isSelected: false,
                isIdentityVerified: false,
                isProfessionVerified: false,
                isWorkspaceVerified: false,
                isTeam: true,
              };
            }),
          };

          groups.push(teamsGroup);
        }

        const filteredContacts = contacts.filter(
          (c) =>
            c.userId &&
            (!asTeam || !this.teamsService.isMember(c.userId, asTeam))
        );

        const departmentGroups: LoadedSelectionList<
          SelectionListType.SINGLE_GROUP,
          UserSelectionList
        >[] = this.groupContactsByDepartment(
          sorted(filteredContacts, this.compareContact.bind(this)),
          selectedUserIds,
          company.id
        );

        groups.push(...departmentGroups);

        this.userGroupSubject.next({
          id: company.id,
          name: company.name,
          type: SelectionListType.SUBGROUPED,
          isExpandable: true,
          isLoaded: true,
          data: groups,
        });
      },
    });
  }

  private mapSuggestions(
    suggestions: SuggestedUserModel[],
    selectedUserIds: Set<string>
  ): SelectionList {
    const data = suggestions.map((suggestion) =>
      this.mapSuggestion(suggestion, selectedUserIds)
    );

    return {
      id: UserSelectionListId.SUGGESTIONS,
      type: SelectionListType.SINGLE_GROUP,
      isLoaded: true,
      data,
    };
  }

  private mapSuggestion(
    suggestion: SuggestedUserModel,
    selectedUserIds: Set<string>
  ): UserSelectionListItem {
    return {
      id: suggestion.userId ?? "",
      name: concatNotNull([suggestion.firstName, suggestion.lastName]),
      description: suggestion.profession ?? null,
      isWorkspaceVerified: suggestion.workspaceVerified ?? false,
      isIdentityVerified: suggestion.identityVerified ?? false,
      isProfessionVerified: suggestion.professionVerified ?? false,
      isSelected: suggestion.userId
        ? selectedUserIds.has(suggestion.userId)
        : false,
      isDisabled: suggestion.userId
        ? this.disabledUserIds.has(suggestion.userId)
        : false,
      fetchImage: suggestion.profilePic != null,
    };
  }

  private mapContacts(
    data: ContactModel[],
    selectedUserIds: Set<string>,
    getDescriptionCallback?: (contact: ContactModel) => string | null
  ): UserSelectionList {
    return data.map((contact) =>
      this.mapContact(contact, selectedUserIds, getDescriptionCallback)
    );
  }

  private mapContact(
    contact: ContactModel,
    selectedUserIds: Set<string>,
    getDescriptionCallback?: (contact: ContactModel) => string | null
  ): UserSelectionListItem {
    return {
      id: contact.userId ?? "",
      name: `${contact.firstName} ${contact.lastName}`,
      description: getDescriptionCallback
        ? getDescriptionCallback(contact)
        : this.getContactDescription(contact),
      isWorkspaceVerified:
        contact.workplaces?.some(
          (w) => w.verificationStatus === VerificationStatus.Verified
        ) ?? false,
      isIdentityVerified:
        contact.identityVerificationStatus ===
        IdentityVerificationStatus.Verified,
      isProfessionVerified:
        contact.professions?.some(
          (p) => p.verificationStatus === ProfessionVerificationStatus.Verified
        ) ?? false,
      isSelected: contact.userId ? selectedUserIds.has(contact.userId) : false,
      isDisabled: contact.userId
        ? this.disabledUserIds.has(contact.userId)
        : false,
      fetchImage: contact.profilePic != null,
      suffix: contact.userId === this.currentUserId ? " (You)" : null,
    };
  }

  private getContactDescription(contact: ContactModel) {
    return contact.professions?.[0]?.profession;
  }

  private getColleagueDescription(contact: ContactModel) {
    return concatNotNull(
      [contact.position, contact.department, contact.companyName],
      ", "
    );
  }

  private getConnectionDescription(contact: ContactModel) {
    return null;
  }

  private getDepartmentContactDescription(
    contact: ContactModel
  ): string | null {
    return contact.position ?? null;
  }

  private getNetworkSearchResultsDescription(
    contact: BasicContactModel
  ): string | null {
    return (
      contact.professions?.find(
        (p) => p.verificationStatus === ProfessionVerificationStatus.Verified
      )?.profession ?? null
    );
  }

  private compareContact(a: ContactModel, b: ContactModel): number {
    return concatNotNull([a.firstName, a.lastName]).localeCompare(
      concatNotNull([b.firstName, b.lastName])
    );
  }

  private mapTeams(
    teams: Team[],
    selectedUserIds: Set<string>
  ): UserSelectionList {
    return teams.map((team) => {
      if (!team.id) throw new Error("Invalid team id");
      if (!team.name) throw new Error("Invalid team name");
      return {
        id: team.id,
        name: team.name,
        isSelected: selectedUserIds.has(team.id),
        isDisabled: this.disabledUserIds.has(team.id),
        isTeam: true,
      };
    });
  }

  public searchUsers(
    query: string,
    selectedUsers: UserSelectionList
  ): Observable<SelectionList[]> {
    const getContactsV1Config: ContactsV1RequestOptions & ApiRequestOptions = {
      fetchAll: true,
      includeSelf: !this.excludeSelf,
    };

    if (this.workspaceId) {
      getContactsV1Config.companyId = this.workspaceId;
    }

    const getContactsV2Config: ContactsRequestOptions & ApiRequestOptions = {
      fetchAll: true,
    };

    const extractContacts = (pages: ContactModelPagedResult[]) => {
      return pages.flatMap(({ data }) => data ?? []);
    };
    // Colleague search results
    const nameSearch = this.contactsService
      .getContactsV1({
        ...getContactsV1Config,
        fullName: query,
      })
      .pipe(toArray(), map(extractContacts));

    const positionSearch = this.contactsService
      .getContactsV1({
        ...getContactsV1Config,
        position: query,
      })
      .pipe(toArray(), map(extractContacts));

    // Department search results
    const departmentSearch = this.contactsService
      .getContactsV1({
        ...getContactsV1Config,
        department: query,
      })
      .pipe(toArray(), map(extractContacts));

    // Connection search results
    const connectionsSearch = this.excludeConnections
      ? of([])
      : this.contactsService
          .getContacts({
            ...getContactsV2Config,
            search: query,
          })
          .pipe(toArray(), map(extractContacts));

    // Roles search results
    let rolesSearch: Observable<Team[]> = of([]);

    if (!this.excludeTeams) {
      rolesSearch = this.teamsService
        .searchTeams({
          fetchAll: true,
          isActive: true,
          search: query,
        })
        .pipe(
          flatMap((teams) => teams),
          map((teams) => {
            return teams.filter(
              (team) => !this.teamsService.isCurrentUserMember(team)
            );
          })
        );
    }

    // People/network search results
    const isDiscoverableAndVerified =
      this.accountService.isDiscoverableAndVerified();
    const networkSearch: Observable<BasicContactModel[]> =
      isDiscoverableAndVerified
        ? this.usersService
            .getUsers({
              fetchAll: true,
              search: query,
              pageSize: 100,
            })
            .pipe(flatMap((page) => page?.data))
        : of([]);

    const team = this.asTeamId
      ? this.teamsService.getUserTeam({
          teamId: this.asTeamId,
          includePartnerWorkspaceIds: true,
          ignoreCache: true,
        })
      : of(null);

    const joined = forkJoin({
      nameSearch,
      departmentSearch,
      connectionsSearch,
      positionSearch,
      rolesSearch,
      networkSearch,
      team,
    });

    const groupedSearchResults = joined.pipe(
      map(
        ({
          nameSearch,
          departmentSearch,
          connectionsSearch,
          positionSearch,
          rolesSearch,
          networkSearch,
          team,
        }) => {
          const selectedUserIds = union(
            new Set<string>(selectedUsers.map((u) => u.id)),
            this.alwaysSelectedAndDisabledUserIds
          );

          const filterContacts = (c: ContactModel) =>
            c.userId && (!team || !this.teamsService.isMember(c.userId, team));

          // Connections
          const connections = this.mapContacts(
            connectionsSearch.filter(filterContacts),
            selectedUserIds,
            this.getConnectionDescription.bind(this)
          );

          // Roles
          const activeRoles =
            this.teamsService.filterOutInactiveTeams(rolesSearch);
          const roles = this.mapTeams(activeRoles, selectedUserIds);

          // Colleagues
          const distinctResults = distinct(
            nameSearch.concat(positionSearch),
            (c) => c.userId
          ).filter(filterContacts);
          const sortedDistinctResults = sorted(
            distinctResults,
            this.compareContact.bind(this)
          );
          const colleagues = this.mapContacts(
            sortedDistinctResults,
            selectedUserIds,
            this.getColleagueDescription.bind(this)
          );

          // People (network search results)
          const connectionUserIds = new Set(
            connections.map((s) => s.id.toLowerCase())
          );
          const people = this.mapContacts(
            networkSearch.filter(
              (s) => s.userId && !connectionUserIds.has(s.userId?.toLowerCase())
            ),
            selectedUserIds,
            this.getNetworkSearchResultsDescription.bind(this)
          );

          // Departments
          const sortedDepartmentResults = sorted(
            departmentSearch.filter(filterContacts),
            this.compareContact.bind(this)
          );
          const departmentGroups = this.groupContactsByDepartment(
            sortedDepartmentResults,
            selectedUserIds,
            null,
            this.getDepartmentContactDescription.bind(this)
          );

          const groups: SingleLoadableSelectionList[] = [];

          if (connections.length) {
            groups.push({
              id: UserSelectionListId.CONNECTIONS,
              name: "Connections",
              selectAllControlName: "Select All",
              data: connections,
              type: SelectionListType.SINGLE_GROUP,
              isLoaded: true,
            });
          }

          if (roles.length) {
            groups.push({
              id: UserSelectionListId.TEAMS,
              name: "Roles",
              isLoaded: true,
              type: SelectionListType.SINGLE_GROUP,
              isTeams: true,
              data: roles,
            });
          }

          if (colleagues.length) {
            groups.push({
              id: UserSelectionListId.COLLEAGUES,
              name: "Colleagues",
              selectAllControlName: "Select All",
              description: "Found in your workspaces",
              data: colleagues,
              type: SelectionListType.SINGLE_GROUP,
              isLoaded: true,
            });
          }

          groups.push(...departmentGroups);

          if (people.length) {
            groups.push({
              id: UserSelectionListId.PEOPLE,
              name: "People",
              selectAllControlName: "Select All",
              description: "Results from the network",
              data: people,
              type: SelectionListType.SINGLE_GROUP,
              isLoaded: true,
            });
          } else if (!isDiscoverableAndVerified) {
            groups.push({
              id: UserSelectionListId.PEOPLE_IDENTITY_VERIFICATION_AND_DISCOVERABILITY,
              name: "People",
              selectAllControlName: "Select All",
              description: "Results from the network",
              data: [],
              type: SelectionListType.SINGLE_GROUP,
              isLoaded: true,
            });
          }

          return groups;
        }
      )
    );

    return groupedSearchResults;
  }

  private groupContactsByDepartment(
    contacts: ContactModel[],
    selectedUserIds: Set<string>,
    workspaceId: string | null,
    getDescriptionCallback?: (contact: ContactModel) => string | null
  ): LoadedSelectionList<SelectionListType.SINGLE_GROUP, UserSelectionList>[] {
    const NO_DEPARTMENT_ID = "NO_DEPARTMENT";
    const NO_DEPARTMENT_NAME = "No Department";

    const groups = new Map<
      string | null,
      LoadedSelectionList<SelectionListType.SINGLE_GROUP, UserSelectionList>
    >();

    for (const contact of contacts) {
      let departmentName = contact.department;
      let departmentId = contact.departmentId;

      const workspace = contact.workplaces?.find(
        (w) => w.companyId === workspaceId
      );

      if (workspace) {
        departmentName = workspace.departmentName;
        departmentId = workspace.departmentId;
      }

      // Contact isn't a member of any department
      if (!departmentId) {
        departmentName = NO_DEPARTMENT_NAME;
        departmentId = NO_DEPARTMENT_ID;
      }

      const selectionListItem = this.mapContact(
        contact,
        selectedUserIds,
        getDescriptionCallback
      );
      const group = groups.get(departmentId);

      if (group) {
        group.data?.push(selectionListItem);
      } else {
        let description: string | null | undefined = null;

        if (departmentId !== NO_DEPARTMENT_ID) {
          description = workspace?.companyName || contact.companyName;
        }

        if (!departmentId) throw new Error("Invalid department id");
        if (!departmentName) throw new Error("Invalid department name");

        groups.set(departmentId, {
          id: departmentId,
          name: departmentName,
          description,
          data: [selectionListItem],
          type: SelectionListType.SINGLE_GROUP,
          isLoaded: true,
        });
      }
    }

    const [departmentGroups, noDepartmentGroups] = partition(
      Array.from(groups.values()),
      (group) => group.id !== null
    );

    const sortedGroups = sorted(departmentGroups, (a, b) =>
      localeCompareIfNotNull(a.name, b.name)
    );

    sortedGroups.push(...noDepartmentGroups);

    return sortedGroups;
  }

  public submitSelectedUsers(
    selectedUsers: UserSelectionList
  ): Observable<void> {
    if (!this.submitCallback) {
      return throwError(new Error("No submit callback has been set"));
    }
    return this.submitCallback(selectedUsers);
  }

  public setData(data: unknown): void {
    if (typeof data !== "function") {
      throw Error(
        `Expected data to be 'function' but received '${typeof data}'`
      );
    }
    this.submitCallback = data as UserPickerSubmitCallback;
  }
}
