import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AlertComponent } from "@modules/core/components";
import { NetworkUserPickerMode } from "@modules/user-picker/network-user-picker.service";
import { UserPickerService } from "@modules/user-picker/user-picker.service";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
import {
  ConversationModelV2,
  CreateMessageMediaModel,
  CreateMessagePatientFileModel,
} from "types";
import { dateCompareIfNotNull } from "utils";
import { v4 as uuidv4 } from "uuid";
import {
  AlertService,
  ConversationService,
  UserAccountService,
} from "../../core";
import {
  ConversationType,
  CreateMessageModel,
  FullUserProfileModel,
} from "./../../../../types/api-v2";
import { SharedService } from "./../../core/old/shared.service";
import { ConversationsService } from "./../../core/services/conversations.service";
import { SnackbarService } from "./../../core/services/snackbar.service";

enum ConversationFilterType {
  NonPatientCase,
  PatientCaseOrNonPatientCase,
  All,
}

@Component({
  selector: "app-conversation-picker",
  templateUrl: "./conversation-picker.component.html",
  styleUrls: ["./conversation-picker.component.scss"],
})
export class ConversationPickerComponent implements OnInit {
  public conversations: ConversationModelV2[] = [];
  public userAccount: FullUserProfileModel | null;
  isLoading = false;
  convoId = null;
  @Input() public header: string | null;
  @Input() public createNewConversationButtonText: string | null;
  @Input() public sendButtonText: string | null;
  @Input() public message: CreateMessageModel | null = null;
  @Input() patientUids = null;
  @Input() attached = null;
  @Input() attachmentType = "Photo";
  @Output() conversationId = new EventEmitter<string>();
  @Output() conversationTypeChange = new EventEmitter<string>();
  patientUid: any;
  page = 0;
  pageSize = 20;
  hasNext = true;

  public isSearching: boolean = false;
  public isSearchLoading: boolean = false;
  public searchQuery = new UntypedFormControl("");
  public searchResultsQuery: string = "";
  public searchResults: ConversationModelV2[] = [];
  private valueChangeSubscription: Subscription | null = null;
  private searchConversationsSubscription: Subscription | null = null;

  private conversationType: ConversationFilterType = ConversationFilterType.All;

  constructor(
    private userService: UserAccountService,
    private matDialog: MatDialog,
    private matDialogRef: MatDialogRef<ConversationPickerComponent>,
    private conversationService: ConversationService,
    private alertService: AlertService,
    private snackbarService: SnackbarService,
    private conversationsService: ConversationsService,
    private sharedService: SharedService,
    private userPickerService: UserPickerService
  ) {}

  async ngOnInit() {
    this.userService.getUserAccount(false, (userAccount) => {
      this.userAccount = userAccount;
      this.patientUid = this.getPatientUid();
      this.updateConversationType();
      this.loadNextPage();
    });
    this.observeQueryValue();
  }

  private observeQueryValue() {
    // Cancel any pending debounced function calls
    this.valueChangeSubscription?.unsubscribe();
    this.valueChangeSubscription = this.searchQuery.valueChanges
      .pipe(
        tap(this.clearIfEmpty.bind(this)),
        distinctUntilChanged(),
        debounceTime(200)
      )
      .subscribe({ next: this.search.bind(this) });
  }

  public clearIfEmpty(value: string) {
    if (value) return;
    this.observeQueryValue();
    this.clearSearch();
  }

  public handleSearchEnter() {
    this.observeQueryValue();
    this.search(this.searchQuery.value);
  }

  private search(value: string, minQueryLength = 3) {
    this.clearSearch();
    if (value.length >= minQueryLength) {
      this.isSearching = true;
      this.isSearchLoading = true;
      this.searchResultsQuery = value;
      this.searchConversationsSubscription = this.conversationsService
        .getConversations({
          search: value,
          pageSize: 20,
          fetchAll: true,
        })
        .pipe(map((page) => page.data))
        .subscribe({
          next: (conversations) => {
            this.searchResults = this.searchResults.concat(
              this.filterConversations(conversations)
            );
          },
          error: () => {
            AlertComponent.openErrorDialog(this.matDialog);
            this.clearSearch();
          },
          complete: () => (this.isSearchLoading = false),
        });
    } else if (value.length === 0) {
      this.clearSearch();
    } else {
      this.snackbarService.show(
        `Please enter at least ${minQueryLength} characters to begin your search.`
      );
    }
  }

  private clearSearch() {
    this.searchConversationsSubscription?.unsubscribe();
    this.searchResults = [];
    this.searchResultsQuery = "";
    this.isSearchLoading = false;
    this.isSearching = false;
  }

  updateConversationType() {
    if (!this.patientUids || !this.patientUids.length) {
      this.conversationType = ConversationFilterType.All;
    } else if (this.patientUids && this.patientUids.length < 2) {
      this.conversationType =
        ConversationFilterType.PatientCaseOrNonPatientCase;
    } else {
      this.conversationType = ConversationFilterType.NonPatientCase;
    }
  }

  onLoadMore() {
    this.loadNextPage();
  }

  onConversationSelected(conversation: ConversationModelV2) {
    this.convoId = conversation.id;
    const conversationType =
      this.conversationService.getConversationType(conversation);
    this.conversationTypeChange.emit(conversationType);
  }

  getPatientUid() {
    if (this.patientUids && this.patientUids.length === 1) {
      this.patientUid = this.patientUids[0];
    }
    return this.patientUid;
  }

  private loadNextPage = async () => {
    this.isLoading = true;

    const targetCount = (this.page + 1) * this.pageSize;

    // Since we have to perform some client-side filtering we need to keep loading conversations either until
    // there aren't anymore to load or we've loaded enough to meet our target count after filtering
    while (this.hasNext && this.conversations.length < targetCount) {
      try {
        const response = await this.conversationService
          .getConversationsByApiV2({
            page: this.page,
            pageSize: this.pageSize,
          })
          .toPromise();
        this.hasNext = response.hasNext;
        this.updateConversations(response.data);
      } catch (e) {
        if (this.sharedService.isOnline()) {
          this.alertService.error(
            "Error loading conversations, please try again."
          );
        } else {
          this.sharedService.noInternetSnackbar();
        }
        break;
      }

      this.page++;
    }
    this.isLoading = false;
  };

  private isPatientCaseOrNonPatientCase = (conversation: ConversationModelV2) =>
    (conversation.patientData &&
      (conversation.patientData.uid === this.patientUid ||
        conversation.patientData.uid === "")) ||
    conversation.patientData == null;

  private isBlockedOrLeft = (conversation: ConversationModelV2) => {
    const currentUserId = this.userAccount?.userId;

    const currentUserParticipant = conversation.participants?.find(
      (p) => p.userId === currentUserId
    );
    if (currentUserParticipant?.leftOnUtc) return true;

    if (conversation.type != ConversationType.Chat) return false;

    return conversation.participants?.some(
      (p) => p.blockedByMe?.isBlocked || p.blockedMe?.isBlocked
    );
  };

  private filterConversations = (
    conversations: ConversationModelV2[]
  ): ConversationModelV2[] => {
    let items: ConversationModelV2[];

    switch (this.conversationType) {
      case ConversationFilterType.NonPatientCase:
        // Load conversations with no patient id number
        items = conversations.filter(
          (conversation) => conversation.patientData == null
        );
        break;
      case ConversationFilterType.PatientCaseOrNonPatientCase:
        // Load conversations from the patient with the specified patient id as well as conversation with no patient id number
        items = conversations.filter(this.isPatientCaseOrNonPatientCase);
        break;
      case ConversationFilterType.All:
        // Load all conversations (regardless of patient id number)
        items = conversations;
        break;
      default:
        throw new Error("Unhandled conversation type.");
    }

    items = items.filter((item) => !this.isBlockedOrLeft(item));

    return items;
  };

  private updateConversations = (conversations: ConversationModelV2[]) => {
    const items = this.filterConversations(conversations);
    this.conversations = [...this.conversations, ...items].sort((a, b) => {
      const dateA = a.lastMessage?.sentOnUtc ?? a.createdOnUtc;
      const dateB = b.lastMessage?.sentOnUtc ?? b.createdOnUtc;
      return dateCompareIfNotNull(dateA, dateB);
    });
  };

  openCreateConvo() {
    const media =
      this.attachmentType === "MEDIA" ? this.mapToMedia(this.attached) : null;
    const patientFiles =
      this.attachmentType === "DOCUMENT"
        ? this.mapToPatientFiles(this.attached)
        : null;

    this.userPickerService
      .openNetworkUserPicker({
        selectedHeader: "Participants",
        selectedQuantityLabels: {
          one: "participant",
          plural: "participants",
          zero: "participants",
        },
        searchPlaceholder: "Search network",
        mode: NetworkUserPickerMode.CREATE,
        message: this.message,
        media,
        patientFiles,
      })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result?.isSubmitted) return;
          this.matDialog.closeAll();
        },
      });

    this.matDialogRef.close();
  }

  private mapToMedia(
    attachments: { id?: string }[]
  ): CreateMessageMediaModel[] {
    const messages: CreateMessageMediaModel[] = attachments.map(
      (attachment) => {
        if (!attachment.id) throw Error("Invalid attachment id");
        return {
          marker: uuidv4(),
          photoId: attachment.id,
        };
      }
    );

    return messages;
  }

  private mapToPatientFiles(
    attachments: { id?: string }[]
  ): CreateMessagePatientFileModel[] {
    const messages: CreateMessagePatientFileModel[] = attachments.map(
      (attachment) => {
        if (!attachment.id) throw Error("Invalid attachment id");
        return {
          marker: uuidv4(),
          fileId: attachment.id,
        };
      }
    );

    return messages;
  }

  select(convoId) {
    this.matDialogRef.close({ convoId, conversationId: convoId });
  }
}
