import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { SharedService } from "../modules/core";

@Injectable({
  providedIn: "root",
})
export class RolesService {
  protected basePath = environment.celoApiEndpoint;

  constructor(private sharedService: SharedService) {}

  adminUserPatch(companyId: any, userId: string, patch: any, path, callback) {
    this.sharedService.patchObjectById(path, patch).subscribe(
      (data) => {
        callback(true);
      },
      (error) => {
        callback(false);
      }
    );
  }
}
