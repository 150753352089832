<div class="celo-toolbar g-10px flex justify-between">
  <div class="toolbar-button" *ngIf="!isSelecting">
    <button mat-button class="celo-secondary-button" (click)="start()">
      <div class="flex items-center">
        <mat-icon *ngIf="pageName === 'Media'">photo</mat-icon>
        <mat-icon
          *ngIf="pageName === 'Documents'"
          svgIcon="icon-documents-consents"
        ></mat-icon>
        <mat-icon
          *ngIf="pageName === 'Consents'"
          svgIcon="icon-documents-consents"
        ></mat-icon>
        <span class="text">
          Select <span class="lowercase">{{ pageName }}</span>
        </span>
      </div>
    </button>
  </div>

  <div *ngIf="isSelecting" class="toolbar-tools">
    <button mat-button (click)="cancel()">Cancel</button>
    <span class="sep">|</span>
    <button
      *ngIf="!singleFileDownloadUrl"
      [matTooltip]="'Download ' + pageName.toLowerCase()"
      mat-icon-button
      (click)="onAction('download')"
    >
      <i class="material-icons">file_download</i>
    </button>
    <a
      *ngIf="singleFileDownloadUrl"
      [matTooltip]="'Download ' + singleFileType"
      [href]="singleFileDownloadUrl | secure"
      [download]="singleFileName"
      (click)="$event.stopPropagation()"
    >
      <button mat-icon-button>
        <i class="material-icons">file_download</i>
      </button>
    </a>
    <button
      *ngIf="pageName !== 'Consents'"
      [matTooltip]="'Share ' + pageName.toLowerCase()"
      mat-icon-button
      (click)="onAction('share')"
    >
      <i class="material-icons">share</i>
    </button>
    <button
      *ngIf="pageName !== 'Consents'"
      [matTooltip]="'Delete ' + pageName.toLowerCase()"
      mat-icon-button
      (click)="onAction('delete')"
    >
      <i class="material-icons">delete</i>
    </button>
  </div>

  <div
    class="toolbar-button"
    *ngIf="
      !hideExportToClinicalRecord &&
      !isSelecting &&
      (isPhotoExportEnabled$ | async)
    "
  >
    <button mat-button class="celo-secondary-button" (click)="export()">
      <div class="flex items-center">
        <mat-icon svgIcon="ic-emr-red"></mat-icon>
        <span class="text">Export to clinical record </span>
      </div>
    </button>
  </div>
</div>
