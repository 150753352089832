<mat-drawer-container class="workspace network-outlet" [hasBackdrop]="false">
  <mat-drawer
    #drawer
    class="sidenav"
    position="end"
    [mode]="drawerMode$ | async"
    [opened]="!!sidenavType"
  >
    <div class="close">
      <button (click)="closeSidenavClicked()" mat-icon-button>
        <i class="material-icons-round">close</i>
      </button>
    </div>

    <div class="sidenav-content">
      <app-profile
        *ngIf="sidenavType === 2"
        [contact]="contact"
        [contactId]="contactId"
        [template]="'type1'"
      ></app-profile>
      <app-workspace-details
        *ngIf="workspaceId && (sidenavType === 1 || sidenavType === 4)"
        [workspaceId]="workspaceId"
        [userAccount]="userAccount"
        [disabeleRemoveMembers]="
          !removableWorkspaceContacts || !removableWorkspaceContacts.length
        "
        [inviteMode]="sidenavType === 4"
        (workspaceUpdated)="onWorkspaceUpdated($event)"
        (userWorkspaceUpdated)="loadWorkspace()"
        (closeSidenav)="closeSidenav()"
        (removeMembersClicked)="onEditMembers()"
        (handleAddMembersClicked)="handleAddMembers(this.workspaceId)"
      ></app-workspace-details>
      <div *ngIf="sidenavType === 3" class="workspace-members">
        <app-workspace-members
          [participants]="removableWorkspaceContacts"
          [workspaceId]="workspaceId"
          (closeClick)="closeSidenav()"
          (needRefresh)="refresh()"
        ></app-workspace-members>
      </div>
      <app-role-details
        *ngIf="
          sidenavType === 5 && userWorkspace?.workspace?.id && selectedTeam
        "
        [team]="selectedTeam"
        [workspaceId]="userWorkspace.workspace.id"
        (updateTeam)="handleUpdateTeam($event)"
        (leaveTeam)="handleUpdateTeam($event)"
        (showProfile)="handleShowTeamMemberProfile($event)"
      ></app-role-details>
      <app-profile
        *ngIf="sidenavType === 6 && selectedUserId"
        [contactId]="selectedUserId"
        [userAccount]="userAccount"
      >
      </app-profile>
    </div>
  </mat-drawer>

  <div *ngIf="userWorkspace && userWorkspace.workspace" class="outlet-content">
    <div class="network-header network-header-margin header">
      <div class="network-cover">
        <div class="cover">
          <div class="cover-background">
            <div class="cover-image"></div>
          </div>
        </div>
      </div>
      <div class="logo">
        <div
          *ngIf="
            !isAvatarInvalid &&
            userWorkspace &&
            userWorkspace.workspace &&
            userWorkspace.workspace.profilePictureUri
          "
        >
          <app-basic-celo-avatar
            [name]="userWorkspace.workspace.name"
            [size]="120"
            [workspaceId]="userWorkspace.workspace.id"
            (imageError)="onAvatarError()"
          ></app-basic-celo-avatar>
        </div>
        <div
          *ngIf="
            isAvatarInvalid ||
            !userWorkspace.workspace ||
            !userWorkspace.workspace ||
            !userWorkspace.workspace.profilePictureUri
          "
          class="icon"
        >
          <img src="../../../../assets/icons/ic-workplace.svg" alt="" />
        </div>
      </div>
      <div class="network-header-content">
        <div class="title">
          <span *ngIf="!userWorkspace.workspace.name">&nbsp;</span>
          <span *ngIf="userWorkspace.workspace.name">{{
            userWorkspace.workspace.name
          }}</span>
        </div>
        <div
          *ngIf="
            userWorkspace.workspace.verificationStatus &&
            userWorkspace.workspace.verificationStatus !== 'Verified'
          "
          class="subtitle"
        >
          <a
            href="https://support.celohealth.com/hc/en-us/articles/1500003870121"
            target="_blank"
            class="celo-link"
            >Learn more</a
          >
          about how you can verify and make changes to this workspace.
        </div>
        <div class="workspace-butttons network-buttons">
          <div
            *ngIf="
              userWorkspace &&
              userWorkspace.claims &&
              userWorkspace.claims.workspaceInvitationInvite === 'true'
            "
            class="button"
          >
            <button
              mat-button
              class="celo-primary-button"
              (click)="inviteToWorkspaceButtonClick()"
            >
              <div class="uppercase">
                <span>Invite</span>
              </div>
            </button>
          </div>
          <div *ngIf="isMyWorkspace" class="button">
            <button
              mat-button
              class="celo-secondary-button"
              (click)="viewDetailsButtonClick()"
            >
              <div class="uppercase">
                <span>View Details</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="search">
        <div class="searchbar">
          <div class="bar">
            <app-celo-topbar-search-input
              class="fw"
              [searchTerm]="searchTerm"
              [placeholder]="
                'Search ' +
                (userWorkspace?.workspace?.name
                  ? userWorkspace.workspace.name
                  : '')
              "
              (doSearch)="handleSearch($event)"
            >
            </app-celo-topbar-search-input>
          </div>
          <div
            class="celo-open-horizontal cancel"
            [style.width.px]="searchTerm ? 100 : 0"
          >
            <button
              class="celo-primary-button-blue celo-hoverable m-1"
              mat-button
              (click)="handleClearSearch()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="workspaceId" class="results">
        <app-empty-state
          *ngIf="isLoading; else results"
          [spinner]="false"
          [animation]="true"
          [isLoading]="true"
          [message]="' '"
          [diameter]="36"
          [containerHeight]="350"
        ></app-empty-state>

        <ng-template #results>
          <!-- Roles/teams -->
          <div
            *ngIf="
              (!searchTerm && teams.length) ||
              (!searchTerm && isCurrentUserWorkspaceAdmin) ||
              (searchTerm && teamsSearchResults.length)
            "
            class="roles-container"
          >
            <app-workspace-list-title title="Roles">
              <app-workspace-create-role-button
                *ngIf="
                  isCurrentUserWorkspaceAdmin && !searchTerm && teams.length
                "
                (click)="handleCreateRoleClicked()"
                end
              ></app-workspace-create-role-button>
            </app-workspace-list-title>
            <div class="spacer"></div>

            <app-workspace-create-role-button
              *ngIf="
                isCurrentUserWorkspaceAdmin && !searchTerm && !teams.length
              "
              (click)="handleCreateRoleClicked()"
              fontSize="16px"
              fontWeight="normal"
              padding="10px 0 0 0"
            ></app-workspace-create-role-button>

            <app-roles-list
              *ngIf="(searchTerm ? teamsSearchResults : teams).length"
              [teams]="searchTerm ? teamsSearchResults : teams"
              (role)="handleRoleClicked($event)"
              avatarBackgroundSize="32px"
              avatarSize="28px"
            ></app-roles-list>
          </div>

          <!-- Department users -->
          <app-workspace-list
            [workspaceId]="workspaceId"
            [userAccount]="userAccount"
            [searchTerm]="searchTerm"
            [groupedLocalContactsDepartments]="
              searchTerm
                ? groupedLocalContactsDepartmentsSearchResults
                : groupedLocalContactsDepartments
            "
            (contact)="onCardClicked($event)"
          ></app-workspace-list>

          <!-- People -->
          <div *ngIf="searchTerm">
            <app-workspace-list
              [workspaceId]="workspaceId"
              [userAccount]="userAccount"
              [searchTerm]="searchTerm"
              [groupedLocalContactsDepartments]="groupedLocalContactsPeople"
              (contact)="onCardClicked($event)"
            ></app-workspace-list>
          </div>
        </ng-template>

        <div
          *ngIf="searchTerm && isSearchResultsEmpty && !isLoading"
          class="empty-state"
        >
          <img src="../../../assets/no-results-found-directory-v2.svg" alt="" />
          <div class="title">No results found</div>
          <div class="subtitle">
            You can search for people by their name, position or department.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!userWorkspace || !userWorkspace.workspace">
    <app-empty-state
      [spinner]="true"
      [animation]="false"
      [isLoading]="true"
      [message]="' '"
      [diameter]="16"
      [stroke]="2"
    ></app-empty-state>
  </div>
</mat-drawer-container>
