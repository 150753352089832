<div class="card-container">
  <ng-content select="[popup-menu-trigger]"></ng-content>

  <ng-container *ngIf="!disabled">
    <div
      [matMenuTriggerFor]="menu"
      class="menu-button"
      (click)="onClick($event)"
      [style.left.px]="menuX"
      [style.top.px]="menuY"
    ></div>
    <mat-menu #menu="matMenu" overlapTrigger="true">
      <ng-content select="[popup-menu-content]"></ng-content>
    </mat-menu>
  </ng-container>
</div>
