<div class="mute-dialog">
  <div style="text-align: left !important" class="title dialog-title">
    {{ title }}
  </div>
  <div
    *ngIf="subtitle"
    style="text-align: left !important"
    class="content dialog-subtitle"
  >
    {{ subtitle }}
  </div>
  <div class="content dialog-content">
    <mat-radio-group
      aria-labelledby="radio-group-label"
      class="radio-group"
      [(ngModel)]="selected"
    >
      <div *ngFor="let item of configData.payload" class="row">
        <mat-radio-button class="radio-button" [value]="item.value">
          {{ item.display }}
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <mat-dialog-actions align="end">
    <button class="celo-secondary-button" mat-button (click)="onNoClick()">
      <span>CANCEL</span>
    </button>
    <button
      class="celo-primary-button"
      mat-button
      [mat-dialog-close]="selected"
    >
      {{ action }}
    </button>
  </mat-dialog-actions>
  <div cdkFocusInitial></div>
</div>
