<button mat-icon-button aria-label="Close" id="close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<h2 class="mat-h2 title">Role details</h2>

<app-role-details
  [team]="team"
  [workspaceId]="workspaceId"
  [hidePrimaryAction]="hidePrimaryAction"
  (updateTeam)="handleUpdateTeam($event)"
  (leaveTeam)="handleLeaveTeam($event)"
  (showProfile)="handleShowTeamMemberProfile($event)"
  marginTop="0"
  membersListMaxHeight="170px"
></app-role-details>
