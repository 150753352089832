<div class="network">
  <div class="sidenav">
    <div class="list">
      <div class="search">
        <div class="searchbar">
          <div class="bar">
            <app-celo-topbar-search-input
              class="fw"
              [searchTerm]="searchTerm"
              [placeholder]="'Search the network'"
              (doSearch)="search($event)"
            >
            </app-celo-topbar-search-input>
          </div>
          <div
            class="celo-open-horizontal cancel"
            [style.width.px]="searchTerm ? 100 : 0"
          >
            <button
              class="celo-primary-button-blue celo-hoverable m-1"
              mat-button
              (click)="search('')"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="searchTerm" class="search-results">
        <div class="sidenav-padding">
          <app-search-result
            [searchNow$]="searchNow$"
            [discoverable]="discoverable"
            [selectable]="false"
            [page_type]="'new_chat'"
            [hideConnections]="false"
            [hideNetwork]="false"
            [myWorkspacesAndPartnerWorkspaceIds]="
              myWorkspacesAndPartnerWorkspaceIds
            "
            [userAccount]="userAccount"
            (contactClick)="onSearchContactClick($event)"
            (roleClick)="handleRoleClicked($event)"
            [workspaces]="allWorkspaces"
          ></app-search-result>
        </div>
      </div>
    </div>
    <div [ngClass]="{ hidden: searchTerm }">
      <div class="list">
        <div class="title">
          <div class="text">My Workspaces</div>
          <a
            *ngIf="myWorkspaces && myWorkspaces.length && canCreateWorkspace"
            (click)="onAddAWorspaceClicked()"
            class="celo-link add-workspace"
            >Add a workspace</a
          >
        </div>
        <a
          *ngIf="(!myWorkspaces || !myWorkspaces.length) && canCreateWorkspace"
          (click)="onAddAWorspaceClicked()"
          class="celo-link add-workspace sidenav-padding"
          >Add a workspace</a
        >
        <ul>
          <li
            *ngFor="let workspace of myWorkspaces; let index = index"
            [routerLink]="['/network/workspace', workspace.id]"
            [routerLinkActive]="['selected-workspace']"
            (click)="
              raiseWorkspaceClickEvent(index + 1 > visibleWorkspaceCount)
            "
          >
            <div
              *ngIf="showAllWorkspaces || index < visibleWorkspaceCount"
              class="workspace-name celo-hoverable"
            >
              <div>{{ workspace.name }}</div>
            </div>
          </li>
        </ul>
        <div
          *ngIf="
            showAllWorkspaces &&
            partneredWorkspaces &&
            partneredWorkspaces.length
          "
          class="title"
        >
          <div class="text">Partner Workspaces</div>
        </div>
        <ul>
          <li
            *ngFor="let workspace of partneredWorkspaces; let index = index"
            [routerLink]="['/network/workspace', workspace.id]"
            [routerLinkActive]="['selected-workspace']"
            (click)="raiseWorkspaceClickEvent(true)"
          >
            <div
              *ngIf="showAllWorkspaces"
              class="workspace-name celo-hoverable"
            >
              <div>{{ workspace.name }}</div>
            </div>
          </li>
        </ul>
        <div
          *ngIf="
            (partneredWorkspaces && partneredWorkspaces.length) ||
            (myWorkspaces && myWorkspaces.length > visibleWorkspaceCount)
          "
          class="sidenav-padding"
        >
          <a class="celo-link" (click)="toggleShowAll()">
            <span *ngIf="!showAllWorkspaces">Show more workspaces</span>
            <span *ngIf="showAllWorkspaces">Show less workspaces</span>
          </a>
        </div>
      </div>
      <div class="list">
        <div class="title">
          <div class="text">Connections</div>
          <a
            *ngIf="connectionsCount"
            (click)="addConnections()"
            class="celo-link add-workspace"
            >Add connections</a
          >
        </div>
        <app-connections-list
          (connectionsLoaded)="connectionsCount = $event"
        ></app-connections-list>
        <div
          *ngIf="connectionsCount === 0"
          matRipple
          class="connections-empty-state celo-hoverable celo-cursor-pointer"
          (click)="addConnections()"
        >
          <div class="image">
            <img
              src="../../../../assets/ctas/cta-add-your-colleagues.svg"
              alt=""
            />
          </div>
          <div class="texts">
            <div class="connections-title">Add connections</div>
            <div class="connections-subtitle">
              Connect with your colleagues to start a conversation
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <router-outlet></router-outlet>
    <div *ngIf="userHasNoWorkpaces" class="empty-state">
      <app-workspace-empty-state></app-workspace-empty-state>
    </div>
    <div *ngIf="!workspaceInfoLoaded" class="empty-state">
      <app-empty-state
        [spinner]="true"
        [animation]="false"
        [isLoading]="true"
        [message]="' '"
        [diameter]="16"
        [stroke]="2"
      ></app-empty-state>
    </div>
  </div>
</div>
