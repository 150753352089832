<div class="title mb-3 appear_anim_0">{{ config[type].title }}</div>
<div class="settings-grid">
  <div *ngIf="type === 'general'" class="privacy grid-15 appear_anim_1">
    <div class="setting">
      <div class="option celo-cursor-pointer" matRipple (click)="qualitySettingChange()">
        <div>
          <div class="option-text">Upload photos in high quality</div>
          <div class="option-subtext">
            This may impact your data speed and usage.
          </div>
        </div>
        <div class="option-toggle no-pointer-events">
          <mat-slide-toggle hideIcon [ngModel]="image_quality" [color]="'primary'"></mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="type === 'notifications'" class="privacy grid-15 appear_anim_1">
    <div class="setting">
      <div class="option celo-cursor-pointer" matRipple (click)="workspaceNotificationSettingChange()">
        <div>
          <div class="option-text">Receive Workspace Notifications</div>
          <div class="option-subtext">
            Get notifed when someone new joins one of your workspaces.
          </div>
        </div>
        <div class="option-toggle no-pointer-events">
          <mat-slide-toggle hideIcon [ngModel]="workspaceNotificationEnabled" [color]="'primary'"></mat-slide-toggle>
        </div>
      </div>
      <div class="option celo-cursor-pointer" matRipple (click)="mentionNotificationSettingChange()">
        <div>
          <div class="option-text">Always notify when mentioned</div>
          <div class="option-subtext">
            Always get notified when you're mentioned, even in muted
            conversations and while on 'Do Not Disturb'.
          </div>
        </div>
        <div class="option-toggle no-pointer-events">
          <mat-slide-toggle hideIcon [ngModel]="mentionNotificationEnabled" [color]="'primary'"></mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="type === 'privacy'" class="privacy grid-15 appear_anim_1">
    <div *ngFor="let setting of privacy_settings" class="setting">
      <div class="title">{{ setting.title }}</div>
      <hr />
      <div class="subtitle">{{ setting.subtitle }}</div>
      <div *ngFor="let option of setting.options" class="option">
        <div class="option-text">{{ option.title }}</div>
        <div class="option-toggle">
          <mat-slide-toggle hideIcon [(ngModel)]="privacy[setting.type][option.type]" [color]="'primary'"
            (change)="settingChange(setting)"></mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="setting">
      <div class="title">Blocked</div>
      <hr />
      <div class="subtitle">
        Blocked contacts will no longer be able to contact you.
      </div>
      <div class="content">
        <div *ngIf="!blocked || blocked.length === 0">
          You haven’t blocked anyone.
        </div>
        <div matRipple *ngFor="let block of blocked" class="celo-hoverable celo-cursor-pointer user-card"
          (click)="openUser.emit(block.userId)">
          <app-celo-contact-card class="contact-card" [contact]="block" [showAllWorkspaces]="false"
            [showCompany]="false" [showDepartment]="false" [showBadge]="true">
          </app-celo-contact-card>
          <div class="arrow">
            <i class="material-icons">navigate_next</i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="type === 'security'" class="security celo-input-outline">
    <mat-accordion>
      <mat-expansion-panel #pinPanel [expand]="expanded" (opened)="expanded = true" class="appear_anim_1">
        <mat-expansion-panel-header>
          <mat-panel-title> Change Device Pin </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="setting-item">
          <mat-form-field appearance="outline">
            <mat-label>Enter new pin (4 digits)</mat-label>
            <input matInput type="password" autocomplete="off" minlength="4" maxlength="4" autocomplete="new-password"
              [(ngModel)]="passcode" />
          </mat-form-field>
          <app-celo-cancel-save-buttons [showCancel]="false" (save)="savePin()"
            [showNow]="passcode && passcode.length > 3">
          </app-celo-cancel-save-buttons>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="appear_anim_2">
        <mat-expansion-panel-header>
          <mat-panel-title> Change Password </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="setting-item">
          <mat-form-field appearance="outline">
            <mat-label>Current Password</mat-label>
            <input #currentP="ngModel" name="currentP" matInput type="password" autocomplete="new-password"
              placeholder="Current Password" required minlength="8" [(ngModel)]="currentPassword" />
          </mat-form-field>
          <div>
            <mat-form-field appearance="outline">
              <mat-label>New Password</mat-label>
              <input #newP="ngModel" name="newP" matInput type="password" placeholder="New Password" required
                minlength="8" autocomplete="new-password" [(ngModel)]="newPassword" />
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-bottom: 4em">
              <mat-label>Re-enter New Password</mat-label>
              <input #confirmP="ngModel" name="confirmP" matInput type="password" placeholder="Re-enter New Password"
                required minlength="8" autocomplete="new-password" [(ngModel)]="confirmPassword" />
              <mat-hint [innerText]="'The password MUST be a minimum of 8 characters and contain lowercase characters (a-z), uppercase characters (A-Z), digits (0-9) and symbols (ex. !@#$%^&).'"></mat-hint>
            </mat-form-field>
          </div>
          <app-celo-cancel-save-buttons [showCancel]="false" (save)="changePassword()" [showNow]="
              currentP.valid && newP.valid && confirmP.valid && !saving
            ">
          </app-celo-cancel-save-buttons>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>