import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";
import "assets/logo-anim.json";

@Component({
  selector: "app-empty-state",
  templateUrl: "./empty-state.component.html",
  styleUrls: ["./empty-state.component.scss"],
})
export class EmptyStateComponent implements AfterViewInit {
  @Input() isLoading: boolean;
  @Input() animation = true;
  @Input() spinner = false;
  @Input() isEmpty: boolean;
  @Input() noImage: boolean;
  @Input() title: string;
  @Input() no_message: boolean;
  @Input() message: string;
  @Input() theme = "dark";
  @Input() image: string;
  @Input() diameter = 30;
  @Input() containerHeight = 200;
  @Input() stroke = 2;
  @Input() type = "";
  @Output() init = new EventEmitter();

  random = Math.floor(Math.random() * 7) + 1;

  private anim: any;
  @Input() animationSpeed = 2;

  ngAfterViewInit() {
    this.init.emit();
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }
}
