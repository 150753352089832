import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { AlertService } from "app/modules/core/old/alert.service";
import { ProfileService } from "app/modules/profile/profile.service";

@Component({
  selector: "app-oncall-toggle-button",
  templateUrl: "./oncall-toggle-button.component.html",
  styleUrls: ["./oncall-toggle-button.component.scss"],
})
export class OncallToggleButtonComponent implements OnInit {
  @Input() isOnCall: boolean;
  @Input() height = 40;
  @Input() width: number;
  @Output() isOnCallChange = new EventEmitter<boolean>();
  isSavingOnCall: boolean;
  @Input()
  userAccount: any;

  canShowOnCall: boolean;

  constructor(
    private profileService: ProfileService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.setShowOnCall();
  }

  setShowOnCall() {
    this.canShowOnCall = false;
    if (
      this.userAccount &&
      this.userAccount["features"] &&
      this.userAccount["features"].onCall
    ) {
      this.canShowOnCall = true;
    }
  }

  toggleOnCall() {
    this.isSavingOnCall = true;
    const newOnCall = !this.isOnCall;
    this.profileService.safeUpdateOnCall(newOnCall).subscribe({
      next: (p: any) => {
        this.userAccount = p;
        const result = p.isOnCall === "true";
        this.isOnCallChange.emit(result);
        this.isSavingOnCall = false;
        if (result) {
          this.alertService.showSnackBar("You are on call now", 3);
        }
      },
      error: () => {
        this.isSavingOnCall = false;
      },
    });
  }
}
