<div
  class="tile-container"
  [ngClass]="{ selected: libItem.selected }"
  (click)="onClick()"
>
  <pdf-viewer
    [src]="source"
    [original-size]="false"
    [show-all]="false"
    [autoresize]="false"
    [render-text]="false"
    class="appear"
    [ngClass]="{ 'appear-now': loaded }"
    (page-rendered)="loaded = true"
    style="height: 100%"
  >
  </pdf-viewer>
  <div *ngIf="libItem.payload && loaded" class="name-panel">
    <div
      *ngIf="libItem.payload.patientData && libItem.payload.patientData.uid"
      class="mb-2"
    >
      <div class="patient-id patient-id-consent">
        {{ libItem.payload.patientData.uid }}
      </div>
    </div>
    <div class="name-box">
      <div class="font-grey font-12 by">by</div>
      <div class="name-field">
        <div *ngIf="!ownFile" class="celo-elipsis fw">
          {{ libItem.payload.creator.title }}
          {{ libItem.payload.creator.firstName }}
          {{ libItem.payload.creator.lastName }}
        </div>
        <div *ngIf="ownFile">You</div>
      </div>
    </div>
    <mat-icon *ngIf="canSelect">{{
      libItem.selected ? "check_circle" : "radio_button_unchecked"
    }}</mat-icon>
  </div>
</div>
