import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ExpansionPanelContentDirective } from "./expansion-panel-content.directive";

@Component({
  selector: "app-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  styleUrls: ["./expansion-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent implements OnInit {
  @Input() public expanded: boolean = false;
  @Input() public stickyHeader: boolean = false;
  @Input() public stickyHeaderTop: number = 0;

  @Output() public expandedChange: EventEmitter<boolean> = new EventEmitter();

  public isTouched: boolean = false;

  @ContentChild(ExpansionPanelContentDirective)
  public content!: ExpansionPanelContentDirective;

  public ngOnInit(): void {
    if (this.expanded) {
      this.isTouched = true;
    }
  }

  public handleClick() {
    this.expandedChange.emit(!this.expanded);
  }
}
