import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  SnackbarComponent,
  SnackbarTheme,
} from "../components/snackbar/snackbar.component";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  constructor(private matSnackBar: MatSnackBar) {}

  /**
   * Show a snackbar with the given message for the specified number of seconds. The returned observable will be
   * notified when the snackbar is closed, with the value being true if the snackbar was dismissed by the user.
   */
  public show(
    message: string,
    durationInSeconds: number = 5,
    theme: SnackbarTheme = "dark"
  ): Observable<boolean> {
    const ref = this.matSnackBar.openFromComponent(SnackbarComponent, {
      duration: durationInSeconds * 1000,
      panelClass: [`${theme}-snackbar`],
      data: {
        message,
        theme,
      },
      verticalPosition: "bottom",
      horizontalPosition: "center",
      announcementMessage: message,
    });
    return ref
      .afterDismissed()
      .pipe(map(({ dismissedByAction }) => dismissedByAction));
  }
}
