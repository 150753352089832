<div class="onboard">
  <div class="content">
    <div class="contents">
      <img class="logo" src="../../../assets/logo-v2.svg" alt="" />
      <img class="cover" src="../../../assets/confirm-email.png" alt="" />
      <div class="title">We need to confirm your email</div>
      <div class="subtitle">
        We’ve sent a code to {{ email }}. Didn’t receive a code?
        <span class="celo-link" [ngClass]="{ disabled: resendingCode }"
          ><a (click)="resendingCode ? '' : resendCode()">Resend Code</a></span
        >
      </div>
      <div class="fields celo-input-outline">
        <mat-form-field appearance="outline" class="fw">
          <mat-label>Enter Code</mat-label>
          <input
            matInput
            class="fw"
            type="text"
            autocomplete="off"
            placeholder="Code"
            required
            [(ngModel)]="code"
            id="codeField"
            name="codeField"
            #codeField="ngModel"
            (ngModelChange)="loading = false; message = ''"
            (keydown.enter)="
              codeField && !codeField.invalid && !loading && verifyEmail()
            "
            appAutofocus
          />
        </mat-form-field>
        <div *ngIf="message" class="error-message appear_1 text-center">
          {{ message }}
        </div>
      </div>
      <div class="subtitle">
        Please check your spam folder or contact us at
        <a
          (click)="contactSupportEvent()"
          class="celo-link"
          href="mailto:support@celohealth.com"
          >support@celohealth.com</a
        >
        if you didn’t receive an email
      </div>
      <div class="buttons">
        <a
          [routerLink]="['/onboard/email']"
          [queryParams]="{
            country_code: countryCode,
            country_name: countryName,
            country_is_available: countryIsAvailable,
            data_region_id: dataRegionId
          }"
          class="button"
        >
          <button mat-button class="celo-secondary-button">BACK</button>
        </a>
        <button
          mat-button
          class="celo-primary-button button"
          (click)="verifyEmail()"
          [disabled]="!codeField || codeField.invalid || loading"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</div>
