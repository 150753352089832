import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  EmailChangeDialogComponent,
  EmailCodeDialogComponent,
  EmailVerifiedDialogComponent,
} from "app/modules/profile/profile-page/profile-page.component";
import { config } from "configurations/config";
import { environment } from "environments/environment";
import {
  AlertService,
  AnalyticsService,
  SharedService,
  UserAccountService,
} from "../../modules/core";
import { WorkspaceService } from "../workspace.service";

@Component({
  selector: "app-workspace-details",
  templateUrl: "./workspace-details.component.html",
  styleUrls: ["./workspace-details.component.scss"],
})
export class WorkspaceDetailsComponent implements OnInit, OnChanges {
  workspace: any;
  workspaceDetails: any;
  @Input() workspaceId: string;
  @Input() userAccount: any = {};
  @Input() inviteMode: boolean = false;
  @Input() disabeleRemoveMembers: boolean = false;
  @Input() disabeleAddMembers: boolean = false;

  @Output() workspaceUpdated = new EventEmitter<any>();
  @Output() userWorkspaceUpdated = new EventEmitter<any>();
  @Output() closeSidenav = new EventEmitter<any>();
  @Output() removeMembersClicked = new EventEmitter<any>();
  @Output() handleAddMembersClicked = new EventEmitter<any>();

  removeWorkspaceUserFeatureEnabled: boolean;
  addWorkspaceUserFeatureEnabled: boolean;

  // link = "https://link.celo.com/df77dfg9du6s";
  workspaceSubtitle: string;
  workspace_details_valid = false;
  workspace_details: any;

  saving = false;

  constructor(
    private userAccountService: UserAccountService,
    private workspaceService: WorkspaceService,
    private alertService: AlertService,
    private analyticsService: AnalyticsService,
    private sharedService: SharedService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.removeWorkspaceUserFeatureEnabled =
      config.removeWorkspaceUserFeatureEnabled;
    this.addWorkspaceUserFeatureEnabled = config.addWorkspaceUserFeatureEnabled;
  }

  getWorkspace() {
    const instance = this;
    this.workspace = undefined;
    this.workspaceService.getWorkspace(this.workspaceId, function (workspace) {
      if (!workspace) {
        return;
      }
      instance.workspace = workspace;
      instance.workspaceDetails = {
        companyId: workspace.workspace.id,
        companyName: workspace.workspace.name,
        departmentId: workspace.department?.id,
        departmentName: workspace.department?.name,
        email: workspace.email,
        isActive: workspace.isActive,
        position: workspace.position,
        verificationStatus: workspace.workspace.verificationStatus,
      };
    });
  }

  ngOnChanges() {
    this.getWorkspace();
  }

  leaveWorkspace() {
    const instance = this;
    if (!this.userAccount || !this.workspaceDetails) {
      return;
    }
    this.userAccountService.deleteCompany(
      this.workspaceDetails,
      this.userAccount.workplaces,
      function (data) {
        if (data.type == "update_and_leave") {
          instance.getEmail(data.type, data.workspace);
          return;
        } else if (data.type == "delete") {
          let emails =
            instance.workspaceService.getValidEmailsFromProfile(data);
          instance.sharedService.onWorkspaceRemoveSuccess(data, emails);
        }

        if (data.type == "update_and_leave") {
          return;
        } else if (data.type == "delete") {
          const emails =
            instance.workspaceService.getValidEmailsFromProfile(data);
          instance.sharedService.onWorkspaceRemoveSuccess(data, emails);
        }

        instance.workspaceUpdated.emit(data);
        instance.closeSidenav.emit();
      }
    );
  }

  getEmail(mode?, workspace?) {
    const dialogRef = this.dialog.open(EmailChangeDialogComponent, {
      data: { countryCode: "+64" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return;
      this.getEmailCode(
        result.email,
        result["verificationId"],
        mode,
        workspace
      );
    });
  }

  getEmailCode(email, verificationId, mode, workspace) {
    const dialogRef = this.dialog.open(EmailCodeDialogComponent, {
      data: {
        email,
        verificationId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadProfile(true);
      let instance = this;
      if (!result) {
        return;
      }
      if (mode == "update_and_leave") {
        this.userAccountService.deleteWorkspace(
          workspace,
          false,
          function (data) {
            if (!data) {
              return;
            }
            let emails =
              instance.workspaceService.getValidEmailsFromProfile(data);
            instance.sharedService.onWorkspaceRemoveSuccess(data, emails);
            instance.workspaceUpdated.emit(data);
            instance.closeSidenav.emit();
          }
        );
        return;
      }
      this.emailVerified();
    });
  }

  emailVerified() {
    const dialogRef = this.dialog.open(EmailVerifiedDialogComponent, {});
  }

  loadProfile(important, callback?): any {
    let instance = this;
    this.userAccountService.getUserAccount(important, function (profile) {
      if (callback) callback();
    });
  }

  resetLink(invitationUri, workspace) {
    this.alertService
      .customDialog(
        "Reset Link",
        "Are you sure you want to reset the invite link for " +
          workspace.workspace.name +
          "? If you reset the link, no one will be able to use it to join this workspace.",
        "RESET LINK",
        "CANCEL",
        false,
        "",
        ""
      )
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        const code = this.sharedService.getUrlParameter(invitationUri, "code");
        const url =
          environment.celoApiEndpoint +
          "/api/v2/invitations/" +
          code +
          "/reset";
        this.sharedService.postObjectById(url).subscribe(
          (res) => {
            this.getWorkspace();
            this.alertService.customDialog(
              "Link has been reset",
              "The previous invite link has been reset and a new invite link has been created.",
              "OK",
              "",
              true,
              "",
              ""
            );
            this.analyticsService.raiseLinkResetEvents(
              "workspace".toLowerCase(),
              true
            );
          },
          (err) => {
            this.alertService.confirm(
              "",
              this.sharedService.STANDARD_ERROR_MESSAGE,
              "Done",
              "",
              true
            );
            this.analyticsService.raiseLinkResetEvents(
              "workspace".toLowerCase(),
              true
            );
          }
        );
      });
  }

  shareViaEmail(link) {
    this.sharedService.invite(
      "workspace",
      link,
      this.workspaceId,
      null,
      this.workspace.workspace.name
    );
  }

  copy(content) {
    // let instance = this;
    // let text = "Follow this link to join the "+this.workspace.workspace.name+" workspace on Celo \n"+content
    this.sharedService.copyWorkspaceLink(
      this.workspace.workspace.name,
      content
    );
    // this.sharedService.copy(text, function () {
    //   instance.alertService.showSnackBar('Link copied',3);
    // })
  }

  linkEvent(source) {
    this.analyticsService.raiseLinkEvents("workspace", source);
  }

  save() {
    this.saving = true;
    const instance = this;
    this.userAccountService.patchCompany(
      this.workspace_details,
      function (data) {
        instance.saving = false;
        instance.workspace_details = undefined;
        instance.userWorkspaceUpdated.emit();
      }
    );
  }

  cancel() {
    this.closeSidenav.emit();
  }

  editMembers() {
    this.removeMembersClicked.emit();
  }

  addMembers() {
    this.handleAddMembersClicked.emit();
  }
}
