<div class="fw fh loading-screen">
  <!-- <app-empty-state [spinner]="true" [animation]="false" [isLoading]="true" [message]="'Please wait'" [diameter]="20" [stroke]="1"></app-empty-state> -->
  <app-empty-state
    [no_message]="true"
    [isLoading]="true"
    [spinner]="false"
    [animation]="true"
    [diameter]="70"
  ></app-empty-state>
</div>
