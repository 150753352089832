import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ContactModel } from "types";
import { SharedService, UsersService } from "../../../modules/core";
import { NetworkParams } from "../network/network.component";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss", "./../network-style.scss"],
})
export class ContactComponent implements OnInit {
  userContact: ContactModel;
  contactId: string;

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: NetworkParams) => {
      if (!params.contactId) return;
      this.loadContact(params.contactId);
    });
  }

  loadContact(contactId) {
    this.userContact = undefined;
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }

    this.usersService.getUser(contactId).subscribe({
      next: (user) => {
        this.userContact = user;
      }
    });
  }
}
