<ng-container *ngIf="{ team: team$ | async } as state">
  <ng-container *ngIf="state.team && !isLoading; else loadingState">
    <!-- hidden is used instead of ngIf as router-outlet must be present if a nested route is requested -->
    <div
      [hidden]="!(state.team.isActive && isCurrentUserMember)"
      class="roles-home-container"
    >
      <app-roles-home-sidenav [team]="state.team"></app-roles-home-sidenav>

      <router-outlet #routerOutlet="outlet"></router-outlet>

      <app-roles-home-empty-state
        *ngIf="!routerOutlet.isActivated"
        [team]="state.team"
      ></app-roles-home-empty-state>
    </div>

    <ng-container *ngIf="!(state.team.isActive && isCurrentUserMember)">
      <div class="center">
        <div class="mat-body">
          {{
            state.team.isActive
              ? "You aren't a member of " + state.team.name + "."
              : state.team.name + " has been deactivated."
          }}
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #loadingState>
    <div class="center"><app-loading-logo></app-loading-logo></div>
  </ng-template>
</ng-container>
