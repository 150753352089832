import { NgModule } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  PreloadAllModules,
  RouteReuseStrategy,
  RouterModule,
  Routes,
} from "@angular/router";
import { CALL_ROUTE_PATH } from "@common";
import { UnauthorizedComponent } from "@modules/shared/unauthorized/unauthorized.component";
import { Role } from "@types";
import { BroadcastDashboardComponent } from "./broadcast/broadcast-dashboard/broadcast-dashboard.component";
import { BroadcastComponent } from "./broadcast/broadcast/broadcast.component";
import { ConversationResolver } from "./conversation.resolver";
import { CanDeactivateGuard } from "./deactivate.guard";
import { ContactComponent } from "./directory/network/contact/contact.component";
import { NetworkComponent } from "./directory/network/network/network.component";
import { WorkspaceComponent } from "./directory/network/workspace/workspace.component";
import { AuthGuard } from "./guards/auth.guard";
import { ForceCreatePasswordGuard } from "./guards/force-create-password.guard";
import { NotForceCreatePasswordGuard } from "./guards/not-force-create-password.guard";
import { PinGuard } from "./guards/pin.guard";
import { RolesGuard } from "./guards/roles.guard";
import { HomeComponent } from "./home/home.component";
import { LoggedInOidcComponent } from "./logged-in-oidc/logged-in-oidc.component";
import { SetPasswordComponent } from "./login-screen/set-password/set-password.component";
import { LoginComponent } from "./login/login.component";
import { LogoutOidcComponent } from "./logout-oidc/logout-oidc.component";
import { NotificationsComponent } from "./modules/notifications/notifications/notifications.component";
import { CountryFailComponent } from "./onboard/country-fail/country-fail.component";
import { CountryComponent } from "./onboard/country/country.component";
import { EmailConfirmComponent } from "./onboard/email-confirm/email-confirm.component";
import { EmailComponent } from "./onboard/email/email.component";
import { OnboardComponent } from "./onboard/onboard/onboard.component";
import { PasswordSetComponent } from "./onboard/password-set/password-set.component";
import { ProfileCreateComponent } from "./onboard/profile-create/profile-create.component";
import { WelcomeComponent } from "./onboard/welcome/welcome.component";
import { WorkspaceAddComponent } from "./onboard/workspace-add/workspace-add.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { PinscreenComponent } from "./pinscreen/pinscreen.component";
import { EditComponent } from "./profile/edit/edit.component";
import { AdminDashboardComponent } from "./roles/admin-dashboard/admin-dashboard.component";
import { RolesPanelComponent } from "./roles/roles-panel/roles-panel.component";
import { callDeactivateGuard } from "./voip/call-deactivate.guard";
import { CallComponent } from "./voip/call/call.component";

class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    // If the user is already on the call screen (i.e. they accepted to join another call while on the call screen)
    // then we force the call screen to reload to ensure all video call related elements are reconstructed.
    // This is in line with the Zoom SDK's best practices which suggest destroying the VideoClient after leaving a call.
    if (future.routeConfig?.path === CALL_ROUTE_PATH) {
      return false;
    }
    return super.shouldReuseRoute(future, curr);
  }
}

const appRoutes: Routes = [
  {
    title: "Admin",
    path: "admin",
    component: RolesPanelComponent,
    canActivate: [AuthGuard, PinGuard, RolesGuard, ForceCreatePasswordGuard],
    data: { roles: [Role.DepartmentAdmin] },
    children: [
      {
        redirectTo: "dashboard",
        path: "",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        pathMatch: "full",
        component: AdminDashboardComponent,
      },
    ],
  },
  {
    title: "Broadcast",
    path: "broadcast",
    canActivate: [AuthGuard, PinGuard, RolesGuard, ForceCreatePasswordGuard],
    data: { roles: [Role.Broadcaster] },
    children: [
      {
        redirectTo: "dashboard",
        path: "",
        pathMatch: "full",
      },
      {
        path: "new",
        pathMatch: "full",
        component: BroadcastComponent,
      },
      {
        path: "dashboard",
        component: BroadcastDashboardComponent,
      },
    ],
  },
  {
    title: "Secure Library",
    path: "secure",
    loadChildren: () =>
      import("./modules/library/library.module").then((m) => m.LibraryModule),
  },
  {
    path: "conversations",
    loadChildren: () =>
      import("./modules/conversations/conversations.module").then(
        (m) => m.ConversationsModule
      ),
  },
  {
    path: "roles",
    loadChildren: () =>
      import("./modules/roles/roles.module").then((m) => m.RolesModule),
  },
  {
    title: "Network",
    path: "network",
    component: NetworkComponent,
    canActivate: [AuthGuard, PinGuard, ForceCreatePasswordGuard],
    children: [
      {
        path: "contact/:contactId",
        component: ContactComponent,
      },
      {
        path: "workspace/:workspaceId",
        component: WorkspaceComponent,
      },
      {
        path: "workspace",
        component: WorkspaceComponent,
      },
    ],
  },
  {
    title: "Invite",
    path: "invite",
    redirectTo: "",
  },
  {
    path: "edit",
    canActivate: [AuthGuard, PinGuard, ForceCreatePasswordGuard],
    children: [
      {
        path: "",
        redirectTo: "general",
        pathMatch: "full",
      },
      {
        title: "General",
        path: "general",
        component: EditComponent,
        data: {
          type: "general",
        },
      },
      {
        title: "Privacy",
        path: "privacy",
        component: EditComponent,
        data: {
          type: "privacy",
        },
      },
      {
        title: "Security",
        path: "security",
        component: EditComponent,
        data: {
          type: "security",
        },
      },
      {
        title: "Notifications",
        path: "notifications",
        component: EditComponent,
        data: {
          type: "notifications",
        },
      },
    ],
  },
  {
    title: "Profile",
    path: "profile",
    canActivate: [AuthGuard, PinGuard, ForceCreatePasswordGuard],
    loadChildren: () =>
      import("./modules/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "notifications",
    component: NotificationsComponent,
    canActivate: [AuthGuard, PinGuard, ForceCreatePasswordGuard],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "unauthorized",
    redirectTo: "unauthorised",
    pathMatch: "full",
  },
  {
    path: "unauthorised",
    component: UnauthorizedComponent,
  },
  {
    path: "signin-oidc",
    component: LoggedInOidcComponent,
  },
  {
    path: "signout-callback-oidc",
    component: LogoutOidcComponent,
  },
  {
    title: "Pin Lock",
    path: "pin",
    component: PinscreenComponent,
    canActivate: [AuthGuard, ForceCreatePasswordGuard],
  },
  {
    path: "set-password",
    component: SetPasswordComponent,
    canActivate: [AuthGuard, NotForceCreatePasswordGuard],
  },
  {
    path: "",
    component: HomeComponent,
  },
  {
    title: "Join",
    path: "onboard",
    component: OnboardComponent,
    children: [
      {
        path: "",
        redirectTo: "country",
        pathMatch: "full",
      },
      {
        path: "country",
        component: CountryComponent,
      },
      {
        path: "country-unsupported",
        component: CountryFailComponent,
      },
      {
        path: "email",
        component: EmailComponent,
      },
      {
        path: "email-confirm",
        component: EmailConfirmComponent,
      },
      {
        path: "workspace-add",
        component: WorkspaceAddComponent,
        canActivate: [AuthGuard, PinGuard, ForceCreatePasswordGuard],
      },
      {
        path: "profile-create",
        component: ProfileCreateComponent,
      },
      {
        path: "welcome",
        component: WelcomeComponent,
      },
      {
        path: "password-set",
        component: PasswordSetComponent,
      },
    ],
  },
  {
    title: "External",
    path: "external",
    loadChildren: () =>
      import("./modules/external/external.module").then(
        (m) => m.ExternalModule
      ),
  },
  {
    title: "Celo Call",
    path: CALL_ROUTE_PATH,
    component: CallComponent,
    canActivate: [AuthGuard, PinGuard, ForceCreatePasswordGuard],
    canDeactivate: [callDeactivateGuard],
  },
  {
    title: "Not Found",
    path: "**",
    component: PageNotFoundComponent,
    canActivate: [AuthGuard, ForceCreatePasswordGuard, PinGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    PinGuard,
    ConversationResolver,
    CanDeactivateGuard,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
})
export class AppRoutingModule {}
