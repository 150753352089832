<app-basic-user-picker
  *ngIf="{ asTeam: asTeam$ | async } as data"
  [showAlternateRightHeader]="
    !isCreatingGroup && !isCreatingCase && !isMultipleSelect
  "
  [showAlternateRightContent]="
    !isCreatingGroup && !isCreatingCase && !isMultipleSelect
  "
  [showAlternateRightFooter]="true"
  [hideSearch]="isEnteringDetails"
  [showDisabledSelectedUsers]="!isMultipleSelect"
  [multiple]="multiple"
>
  <app-as-team-header
    *ngIf="data.asTeam"
    [name]="data.asTeam.name"
    left-header
  ></app-as-team-header>

  <ng-container left-content>
    <app-network-user-picker-content
      [hidden]="isEnteringDetails"
    ></app-network-user-picker-content>

    <app-new-message-create
      *ngIf="isEnteringDetails"
      [(name)]="conversationName"
      (nameInvalid)="isConversationNameInvalid = $event"
      [patientData]="patientData"
      [type]="isCreatingGroup ? 'Group' : 'Case'"
    ></app-new-message-create>
  </ng-container>

  <app-conversation-type-buttons right-content></app-conversation-type-buttons>

  <ng-container
    right-footer
    *ngIf="isCreatingGroup || isCreatingCase || isMultipleSelect"
  >
    <button
      mat-button
      class="celo-primary-button uppercase"
      (click)="handleSubmit()"
      [disabled]="isEnteringDetails && isConversationNameInvalid"
    >
      {{ submitButtonText || "Continue" }}
    </button>

    <button
      mat-button
      class="celo-secondary-button uppercase"
      (click)="handleBack()"
    >
      {{ cancelButtonText || "Back" }}
    </button>
  </ng-container>
</app-basic-user-picker>
