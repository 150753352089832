<div class="open-slow" [ngClass]="{ 'open-slow-to-height': showNow }">
  <button
    *ngIf="showSave"
    mat-button
    class="celo-secondary-button button-hover m-1"
    (click)="save.emit($event)"
  >
    {{ textSave }}
  </button>
  <button
    *ngIf="showCancel"
    mat-button
    class="celo-secondary-button button-hover thin-font m-1"
    (click)="cancel.emit($event)"
  >
    {{ textCancel }}
  </button>
</div>
