import { createActionGroup, props } from "@ngrx/store";
import {
  ConversationMuteUpdate,
  MessageUpdate,
  PinnedConversationUpdate,
  VideoCallUpdate,
} from "@types";

export const SignalRActions = createActionGroup({
  source: "SignalR",
  events: {
    "Conversation Pinned Updated": props<PinnedConversationUpdate>(),
    "Conversation Mute Updated": props<ConversationMuteUpdate>(),
    // This is wrapped in an object as the 'type' property on messages conflicts with the 'type' property of the action
    "Message Update": props<{ update: MessageUpdate }>(),
    "Video Call Update": props<{ update: VideoCallUpdate }>(),
  },
});
