import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { RoleMemberPopupMenuAction } from "./../role-member-popup-menu/role-member-popup-menu.component";

@Injectable()
export class RoleDetailsService {
  private popupMenuActionSubject = new Subject<RoleMemberPopupMenuAction>();
  public popupMenuAction = this.popupMenuActionSubject.asObservable();

  emitPopupMenuAction(action: RoleMemberPopupMenuAction) {
    this.popupMenuActionSubject.next(action);
  }
}
