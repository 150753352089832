import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from "@angular/core";
import { WorkspaceService } from "app/workspace/workspace.service";
import { AlertService } from "../../../core";

@Component({
  selector: "app-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent implements OnChanges {
  @Input() identityVerificationStatus;
  @Input() profession;
  @Input() doNotShowProfessionVerificationStatus;
  @Input() workspaces = [];
  @Input() isWorkspaceVerified = false;
  @Input() isProfessionVerified = false;
  @Input() showTooltip = false;
  @Input() clickable = false;
  @Input() size = 17;
  @Input() hideUnverifiedBadge = false;
  @Input() ownProfile: boolean;
  @Input() hasImage: boolean;
  @Input() name: string;
  @Input() userId: string;
  @Input() title: string;
  @Input() name_verified_as: string;
  @Input() isExternal: boolean = false;

  constructor(
    private workspaceService: WorkspaceService,
    private alertService: AlertService
  ) {}

  ngOnChanges() {
    if (this.workspaces && this.workspaces.length) {
      this.isWorkspaceVerified = this.workspaceService.isWorkspaceVerified(
        this.workspaces
      );
    }
  }

  openBadgeInfo() {
    const title = "";
    const confirmButtonText = "DONE ";
    const payload = [
      {
        type: "badgeInfo",
        ownProfile: this.ownProfile,
        hasImage: this.hasImage,
        name: this.name,
        name_verified_as: this.name_verified_as,
        title: this.title,
        userId: this.userId,
        workspaces: this.workspaces,
        identityVerificationStatus: this.identityVerificationStatus,
        profession: this.profession,
      },
    ];
    this.alertService.customDialog(
      title,
      "",
      confirmButtonText,
      "",
      true,
      "vertical",
      payload,
      true
    );
  }
}
