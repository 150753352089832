import { Component, OnChanges, Input } from "@angular/core";
import { environment } from "@env";

@Component({
  selector: "app-consent-download",
  templateUrl: "./consent-download.component.html",
  styleUrls: ["./consent-download.component.scss"],
})
export class ConsentDownloadComponent implements OnChanges {
  @Input() consent: any;
  consentLink: any;

  ngOnChanges() {
    this.consentLink = `${environment.celoApiEndpoint}/api/Consents/${this.consent.id}`;
  }
}
