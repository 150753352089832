import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { AlertComponent } from "@modules/core/components";
import { Team } from "types";
import { SubscriptionContainer, createDialogConfig } from "utils";
import { isNotWhitespaceValidator } from "validators";
import { TeamsService } from "./../../core/services/teams.service";

export interface CreateRoleDialogData {
  workspaceId: string;
}

export interface CreateRoleDialogResult {
  /** The team that was created or null if no team was created. */
  team: Team | null;
}

@Component({
  selector: "app-create-role",
  templateUrl: "./create-role.component.html",
  styleUrls: ["./create-role.component.scss"],
})
export class CreateRoleComponent implements OnInit, OnDestroy {
  public nameFormControl: UntypedFormControl = new UntypedFormControl("", [
    Validators.required,
    isNotWhitespaceValidator,
  ]);

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: this.nameFormControl,
  });

  public isLoading: boolean = false;

  private workspaceId: string | null = null;
  private subscriptions: SubscriptionContainer = new SubscriptionContainer();

  public constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: CreateRoleDialogData,
    private teamsService: TeamsService,
    private matDialogRef: MatDialogRef<
      CreateRoleComponent,
      CreateRoleDialogResult
    >,
    private matDialog: MatDialog
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: CreateRoleDialogData
  ): MatDialogRef<CreateRoleComponent, CreateRoleDialogResult> {
    const config = createDialogConfig<CreateRoleDialogData>(data);
    return matDialog.open<
      CreateRoleComponent,
      CreateRoleDialogData,
      CreateRoleDialogResult
    >(CreateRoleComponent, config);
  }

  public ngOnInit() {
    this.workspaceId = this.dialogData.workspaceId;
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private setLoadingState(isLoading: boolean) {
    if (isLoading) {
      this.isLoading = true;
      this.formGroup.disable();
    } else {
      this.isLoading = false;
      this.formGroup.enable();
    }
  }

  public handleSubmit() {
    if (!this.workspaceId) return;

    this.setLoadingState(true);

    const subscription = this.teamsService
      .createTeam({
        workspaceId: this.workspaceId,
        name: this.nameFormControl.value?.trimEnd(),
        description: "",
      })
      .subscribe({
        next: (team) => {
          this.setLoadingState(false);
          this.matDialogRef.close({
            team,
          });
        },
        error: () => {
          AlertComponent.openErrorDialog(this.matDialog);

          this.setLoadingState(false);
        },
      });

    this.subscriptions.add(subscription);
  }
}
