<div class="appear_anim_3">
  <div class="title">
    <button mat-icon-button (click)="closeMe.emit()">
      <mat-icon>close</mat-icon>
    </button>
    <div>
      Upload
      <span
        *ngIf="
          uploader.queue[selectedItemIndex] &&
          uploader.queue[selectedItemIndex].file &&
          uploader.queue[selectedItemIndex].file.type
        "
        class="lowercase"
      >
        <span
          *ngIf="
            uploader.queue[selectedItemIndex].file.type.startsWith(
              'application/pdf'
            )
          "
          >document</span
        >
        <span
          *ngIf="
            uploader.queue[selectedItemIndex].file.type.startsWith('image/')
          "
          >photo</span
        >
      </span>
    </div>
  </div>

  <div
    class="preview-box"
    ng2FileDrop
    [uploader]="uploader"
    (fileOver)="dropZoneState($event)"
    (onFileDrop)="handleNewFiles()"
    [ngClass]="{ valid: dropZoneActive === 'valid' }"
  >
    <div class="drop-highlight">
      <div>
        <i class="material-icons icon-xxl">cloud_upload</i>
        <div>UPLOAD</div>
      </div>
    </div>

    <div *ngIf="!uploader.isUploading" class="preview-content">
      <div
        *ngIf="uploader.queue && uploader.queue[selectedItemIndex]"
        class="preview"
      >
        <img
          *ngIf="uploader.queue[selectedItemIndex].pic_url"
          [src]="uploader.queue[selectedItemIndex].pic_url"
          alt=""
        />
        <pdf-viewer
          *ngIf="uploader.queue[selectedItemIndex].preview"
          [src]="uploader.queue[selectedItemIndex].preview.data"
          [autoresize]="true"
          [original-size]="false"
          [render-text]="false"
          [zoom]="0.95"
        >
        </pdf-viewer>
      </div>
      <div class="list">
        <div class="tile add-tile">
          <div class="add-button">
            <button mat-icon-button (click)="fileSingle.click()">
              <mat-icon>add</mat-icon>
            </button>
            <input
              [multiple]="true"
              [accept]="
                type === 'Photos'
                  ? 'image/png, image/jpeg'
                  : type === 'Documents'
                  ? 'application/pdf'
                  : 'image/png, image/jpeg, application/pdf'
              "
              #fileSingle
              class="d-none"
              type="file"
              ng2FileSelect
              [uploader]="uploader"
              (change)="handleNewFiles()"
            />
          </div>
        </div>
        <div
          *ngFor="let preview of uploader.queue; let index = index"
          (click)="selectedItemIndex = index"
        >
          <div
            class="tile"
            [ngClass]="{ selected: selectedItemIndex === index }"
          >
            <img *ngIf="preview.pic_url" [src]="preview.pic_url" alt="" />
            <div *ngIf="preview.preview" class="pdf-tile">
              <div>
                {{ index + 1 }}
              </div>
            </div>
            <div
              class="close-button"
              (click)="removeFile(index); $event.stopPropagation()"
            >
              <i class="material-icons">close</i>
            </div>
          </div>
        </div>
      </div>
      <div class="description">
        <mat-form-field *ngIf="uploader.queue[selectedItemIndex]">
          <textarea
            matInput
            [placeholder]="
              'Add ' + (isInSecureLibrary ? 'description' : 'caption')
            "
            rows="1"
            [(ngModel)]="uploader.queue[selectedItemIndex].description"
          ></textarea>
        </mat-form-field>
        <mat-form-field
          *ngIf="
            uploader.queue[selectedItemIndex] &&
            uploader.queue[selectedItemIndex].file &&
            uploader.queue[selectedItemIndex].file.type &&
            uploader.queue[selectedItemIndex].file.type.startsWith(
              'application/pdf'
            )
          "
        >
          <input
            matInput
            placeholder="Filename"
            rows="1"
            [(ngModel)]="uploader.queue[selectedItemIndex].filename"
          />
        </mat-form-field>
        <button
          mat-button
          class="celo-primary-button"
          (click)="upload()"
          [disabled]="!patientValidity"
        >
          Upload
        </button>
      </div>
      <div class="patient-box">
        <div>
          <div class="details-title">Patient Details</div>
          <div class="details-sub">
            This will be added to all
            <span
              *ngIf="
                uploader.queue[selectedItemIndex] &&
                uploader.queue[selectedItemIndex].file &&
                uploader.queue[selectedItemIndex].file.type
              "
              class="lowercase"
            >
              <span
                *ngIf="
                  uploader.queue[selectedItemIndex].file.type.startsWith(
                    'application/pdf'
                  )
                "
                >documents</span
              >
              <span
                *ngIf="
                  uploader.queue[selectedItemIndex].file.type.startsWith(
                    'image/'
                  )
                "
                >photos</span
              >
            </span>
            you are uploading
          </div>
        </div>
      </div>
      <div class="patient-details-box">
        <div class="patient-details">
          <app-edit-patient
            [patient]="patient"
            [optionalId]="true"
            (validity)="patientValidity = $event"
          ></app-edit-patient>
        </div>
      </div>
      <div #patientDetails></div>
    </div>
    <div *ngIf="uploader.isUploading" class="loading-box">
      <div
        class="spinner-container appear"
        [ngClass]="{ 'appear-now': uploader.isUploading }"
      >
        <mat-spinner [strokeWidth]="3" [diameter]="30"></mat-spinner>
        <div>
          Uploading {{ uploadService.uploadedFiles.length }}/{{
            uploader.queue.length
          }}
        </div>
      </div>
    </div>
  </div>
</div>
