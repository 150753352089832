import { Component, Input } from "@angular/core";

@Component({
  selector: "app-as-team-header",
  templateUrl: "./as-team-header.component.html",
  styleUrls: ["./as-team-header.component.scss"],
})
export class AsTeamHeaderComponent {
  @Input() public name: string = "";
}
