import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDrawer } from "@angular/material/sidenav";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute } from "@angular/router";
import { NewChatComponent } from "@modules/conversations/new-conversations/new-chat/new-chat.component";
import { Broadcast } from "@types";
import {
  SharedService,
  SnackbarService,
  UserAccountService,
} from "../../modules/core";
import { BroadcastService } from "../broadcast.service";
import { BroadcastComponent } from "../broadcast/broadcast.component";

const minSearchLength: number = 3;

@Component({
  selector: "app-broadcast-dashboard",
  templateUrl: "./broadcast-dashboard.component.html",
  styleUrls: ["./broadcast-dashboard.component.scss"],
})
export class BroadcastDashboardComponent implements OnInit {
  displayedColumns: string[] = [
    "message",
    "sent",
    "delivered",
    "seen",
    "seenpercent",
    "actions",
  ];

  dataSource: MatTableDataSource<Broadcast>;
  userAccount: any;

  @ViewChild(MatSort) sort: MatSort;
  pageSizeDefault = 5;
  pageSize = 5;
  total = 0;
  keyword = "";
  loading = true;

  public selectedBroadcastId: string | null = null;

  @ViewChild(MatDrawer)
  private drawer: MatDrawer;

  constructor(
    public broadcastService: BroadcastService,
    private sharedService: SharedService,
    private userAccountService: UserAccountService,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private snackbarService: SnackbarService
  ) {
    this.userAccountService.getUserAccount(false, (userAccount) => {
      this.userAccount = userAccount;
    });
    this.broadcastService.BroadcastChange$.subscribe((data) => {
      this.setTableData();
    });
  }

  setTableData() {
    this.dataSource = new MatTableDataSource(this.broadcastService.history);
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    this.getPackage(this.pageSize);
  }

  loadMore() {
    // let instance = this;
    // var anchor = document.getElementById('bottomAnchor') as HTMLElement;
    this.pageSize = this.pageSize + this.pageSizeDefault;
    this.getPackage(this.pageSize, function () {
      // instance.scrollTo(anchor);
    });
  }

  getPackage(pageSize, callback?) {
    const instance = this;
    this.loading = true;
    this.broadcastService.getBroadcasts(pageSize, function (total) {
      instance.loading = false;
      if (total) {
        instance.total = total;
        instance.setTableData();
      }
      if (callback) {
        callback();
      }
    });
  }

  change() {
    if (!this.keyword) {
      this.cancelSearch();
    }
  }

  search() {
    if (!this.keyword || !this.keyword.length) return;

    if (this.keyword.trim().length < minSearchLength) {
      this.snackbarService.show(
        `Please enter at least ${minSearchLength} characters to begin your search.`
      );
      return;
    }

    if (this.keyword) {
      this.getAndSearch(10000, this.keyword.trim());
    } else {
      this.cancelSearch();
    }
  }

  cancelSearch() {
    this.keyword = "";
    this.pageSize = this.pageSizeDefault;
    this.getAndSearch(this.pageSize, "");
  }

  getAndSearch(pageSize, keyword) {
    const instance = this;
    this.getPackage(pageSize, function () {
      instance.applyFilter(keyword);
    });
  }

  scrollTo(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);
  }

  newBroadcast() {
    NewChatComponent.openCreateNewBroadcastChooseParticipantsDialog(
      this.matDialog
    )
      .afterClosed()
      .subscribe((res) => {
        if (!res || !res.length) return;
        this.newBroadcastMessage(res);
      });
  }

  newBroadcastMessage(recipients) {
    BroadcastComponent.openCreateNewBroadcastDialog(this.matDialog, recipients)
      .afterClosed()
      .subscribe((res) => {
        if (!res) return;
        this.getPackage(this.pageSize);
      });
  }

  handleViewStatus(broadcast: Broadcast) {
    this.selectedBroadcastId = broadcast.id;
    this.drawer.open();
  }

  handleDrawerClosed() {
    this.selectedBroadcastId = null;
  }
}
