<div class="parent pb-1" #parent>
  <div #titlepart class="titlebar">
    <div *ngIf="title || !hideSelectionInfo" class="pb-2">
      <div class="title sentancify">{{ title }}</div>
      <div *ngIf="!hideSelectionInfo" class="subtitle">
        {{ selectedParticipantIds.length }} participant{{
          selectedParticipantIds.length === 1 ? "" : "s"
        }}
        selected
      </div>
    </div>
  </div>
  <div
    *ngIf="participantsToShow && participantsToShow.length"
    class="list_content"
  >
    <div class="search_bar">
      <app-celo-topbar-search-input
        class="fw"
        [searchTerm]="''"
        [placeholder]="'Search'"
        [autoSearch]="false"
        [characterLimit]="false"
        (doSearch)="filter($event)"
      >
      </app-celo-topbar-search-input>
    </div>
    <ul #chipList>
      <li
        *ngFor="let participant of participantsToShow"
        matRipple
        (click)="pickParticipant(participant)"
        class="chip celo-hoverable"
      >
        <div class="selector">
          <i
            *ngIf="selectedParticipantIds.indexOf(participant.userId) === -1"
            class="material-icons"
            >check_box_outline_blank</i
          >
          <i
            *ngIf="selectedParticipantIds.indexOf(participant.userId) !== -1"
            class="material-icons selected"
            >check_box</i
          >
        </div>
        <div class="icon">
          <app-avatar
            [name]="participant.firstName + ' ' + participant.lastName"
            [userId]="participant.userId"
            [width]="40"
            [height]="40"
            [hasImage]="!!participant.profilePic"
            [picUrl]="participant.profilePic"
            [showBadge]="showBadge"
            [badgeSize]="18"
            [workspaces]="participant.workplaces"
            [identityVerificationStatus]="
              participant.identityVerificationStatus
            "
            [showTooltip]="false"
            [user]="participant"
          ></app-avatar>
        </div>
        <div class="texts fw block">
          <div class="name celo-elipsis">
            {{ participant.firstName }} {{ participant.lastName }}
          </div>
          <div
            *ngIf="
              !hideProfession &&
              participant.professions[0] &&
              participant.professions[0].category &&
              participant.professions[0].verificationStatus === 'Verified'
            "
            class="subtitle-text"
          >
            {{ participant.professions[0].profession }}
          </div>
        </div>
        <button
          mat-icon-button
          class="info_button"
          (click)="$event.stopPropagation(); preview.emit(participant.userId)"
        >
          <mat-icon svgIcon="icon-info" class="icon-xl"></mat-icon>
        </button>
      </li>
      <div #bottom id="bottom"></div>
    </ul>
  </div>
  <div *ngIf="!participantsToShow.length" class="ro_results_window">
    <div class="no_results appear_1">{{ noResultsMessage }}</div>
  </div>
</div>
