import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import {
  AccountService,
  IntegrationService,
  SharedService,
} from "@modules/core";
import {
  FullUserProfileModel,
  IntegrationServiceModel,
  ViewMyWorkplaceIntegrationModel,
} from "@types";
import { WorkspaceService } from "app/workspace/workspace.service";

export interface IntegrationItem {
  companyId: string | null;
  integrationId: string | null;
  serviceCode: string | null;
  services: IntegrationServiceModel[] | null;
}

@Component({
  selector: "app-integrations-picker",
  templateUrl: "./integrations-picker.component.html",
  styleUrls: ["./integrations-picker.component.scss"],
})
export class IntegrationsPickerComponent implements OnInit {
  @Input() exportableTo: string[] = [];
  @Input() ignoreExportableTo: boolean = false;
  @Input() selected: ViewMyWorkplaceIntegrationModel | null = null;
  @Input() integrations: ViewMyWorkplaceIntegrationModel[] = [];
  @Input() services: IntegrationServiceModel[] = [];
  @Input() public type: string | null = null;

  @Output() selectionChange = new EventEmitter<IntegrationItem>();
  userAccount: FullUserProfileModel;
  selectedControl = new UntypedFormControl();
  serviceControl = new UntypedFormControl();
  @ViewChild("integrationSelect", { static: true }) integrationSelect;
  serviceCode: string | null = null;

  constructor(
    private sharedService: SharedService,
    private workspaceService: WorkspaceService,
    private accountService: AccountService,
    private integrationService: IntegrationService
  ) {}

  ngOnInit() {
    this.emit();
    this.accountService.userAccount$.subscribe({
      next: (userAccount) => (this.userAccount = userAccount),
    });

    if (this.type) {
      this.integrationService.getIntegrations(this.type).subscribe({
        next: (integrations) => {
          this.integrations = integrations;
          if (this.integrations.length === 1) {
            this.selectFirstIntegration();
          }
        },
      });
    } else if (this.integrations?.length === 1) {
      this.selectFirstIntegration();
    }

    this.serviceControl.disable();
  }

  selectFirstIntegration() {
    this.selectedControl.setValue(this.integrations[0]);

    if (this.integrations.length === 1) {
      this.selectedControl.disable();
    } else {
      this.selectedControl.enable();
    }

    this.updateIntegrationSelection(this.integrations[0]);
  }

  updateIntegrationSelection(integration: ViewMyWorkplaceIntegrationModel) {
    this.selected = integration;
    this.getServices(this.selected.id, this.selected.company.id);
    this.serviceCode = null;
    this.emit();
  }

  updateServiceSelection(service: IntegrationServiceModel) {
    this.serviceCode = service.code ?? null;
    this.emit();
  }

  onChange(event) {
    this.updateIntegrationSelection(event.value);
  }

  onServiceChange(event) {
    this.updateServiceSelection(event.value);
  }

  emit() {
    const params = {
      companyId: this.selected?.company?.id ?? null,
      integrationId: this.selected?.id ?? null,
      serviceCode: this.serviceCode,
      services: this.services,
    };
    this.selectionChange.emit(params);
  }

  getServices(integrationId, companyId) {
    this.services = null;
    let department = this.workspaceService.getDepartmentFromWorkspaceList(
      this.userAccount.workplaces,
      this.selected.company.id
    );

    this.integrationService.getIntegration(integrationId, companyId).subscribe({
      next: (integration) => {
        this.services = integration.services;

        if (this.services.length === 1) {
          this.serviceControl.setValue(this.services[0]);
          this.serviceControl.disable();
        } else {
          this.serviceControl.enable();
        }

        this.sharedService.sortArrayByField(this.services, "name");
        if (department)
          this.sharedService.pullToTop(this.services, "name", department);
        this.emit();
      },
    });
  }
}
