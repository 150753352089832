<div class="content">
  <div class="img appear_anim_1">
    <img src="../../../assets/phone-verification.svg" alt="" />
  </div>
  <div mat-dialog-content class="appear_anim_2">
    <div class="title">Your mobile number has been verified successfully</div>
    <div class="text">
      You can choose who you share your number with. Go to the Privacy Settings
      in your profile.
    </div>
  </div>
  <div mat-dialog-actions class="done fw">
    <button
      mat-button
      class="celo-primary-button fw"
      [mat-dialog-close]="data"
      cdkFocusInitial
    >
      DONE
    </button>
  </div>
  <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="false">
      <i class="material-icons">cancel</i>
    </button>
  </div>
</div>
