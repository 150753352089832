import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";
import {
  DomSanitizer,
  SafeUrl,
  SafeResourceUrl,
} from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AvatarService implements OnDestroy {
  cache: Map<string, SafeUrl>;

  ngOnDestroy(): void {
    this.cache = new Map<string, SafeUrl>();
  }

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.cache = new Map<string, SafeUrl>();
  }

  loadPicture(
    id: string,
    dimension,
    important?: boolean,
    picurl?
  ): Observable<SafeUrl> {
    dimension = dimension ? dimension : 150;
    const key = picurl ? picurl : id;
    const cacheItem = important ? null : this.cache.get(key);
    if (!cacheItem) {
      return this.loadPictureFromApi(id, dimension, dimension, picurl).pipe(
        tap((url) => {
          this.cache.set(key, url);
        })
      );
    }
    return of(cacheItem);
  }

  loadPictureFromApi(userId, width, height, picurl?): Observable<SafeUrl> {
    const custom_url = `/api/User/${userId}/Picture`;
    let url = picurl ? picurl : custom_url;

    url = environment.celoApiEndpoint + url;

    url += `?width=${width}&height=${height}`;
    return this.http
      .get<ArrayBuffer>(url, { responseType: "arraybuffer" as "json" })
      .pipe(
        map((ab) => {
          const arrayBufferView = new Uint8Array(ab);
          const blob = new Blob([arrayBufferView], { type: "image/jpeg" });
          const urlCreator = <any>window.URL;
          const url = urlCreator.createObjectURL(blob);
          return this.sanitizer.bypassSecurityTrustUrl(url);
        })
      );
  }
}
