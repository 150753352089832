<div *ngIf="template === 'type1'">
  <div *ngIf="contact" class="profile">
    <div class="picture-container">
      <app-avatar
        [name]="contact.firstName + ' ' + contact.lastName"
        [userId]="contact.userId"
        [width]="150"
        [height]="150"
        [hasImage]="!!contact.profilePic"
        [picUrl]="contact.profilePic"
        [badgeMargin]="'5'"
        [badgeBorder]="'4'"
      >
      </app-avatar>
      <div class="name celo-elipsis-2">
        <div class="text">
          {{ contact.title }} {{ contact.firstName }} {{ contact.lastName }}
        </div>
        <div class="badge-icon">
          &nbsp;<app-badge
            class="inline-block"
            [identityVerificationStatus]="contact.identityVerificationStatus"
            [workspaces]="contact.workplaces"
            [profession]="contact.professions[0]"
            [size]="26"
            [showTooltip]="false"
            [clickable]="true"
            [title]="contact.title"
            [name]="contact.firstName + ' ' + contact.lastName"
            [name_verified_as]="contact.legalFirstName + ' ' + contact.lastName"
            [userId]="contact.userId"
            [hasImage]="!!contact.profilePic"
          ></app-badge>
        </div>
      </div>
      <div
        *ngIf="contact.professions && contact.professions[0]"
        class="subname celo-elipsis-2"
      >
        <div class="text">
          {{ contact.professions[0].profession }}
          <span
            *ngIf="contact.professions[0].verificationStatus === 'Unverified'"
            >(Unverified)</span
          >
        </div>
      </div>
      <div *ngIf="dndUntil && dndUntil > now">
        <div class="dnd-status appear_2">
          <img
            class="svgIcon"
            src="../../assets/icons/icon-dnd-v2.svg"
            alt=""
          />
          <div class="dnd-text celo-elipsis">Do not disturb</div>
        </div>
      </div>
      <div
        class="appear_2 blocked-text"
        *ngIf="contact['blockedByMe'] && contact['blockedByMe'].isBlocked"
      >
        You’ve blocked {{ contact.title }} {{ contact.firstName }}
        {{ contact.lastName }}. You can unblock this user to send them a
        message.
      </div>
      <div
        class="appear_2 blocked-text"
        *ngIf="
          contact['blockedMe'] &&
          contact['blockedMe'].isBlocked &&
          !(contact['blockedByMe'] && contact['blockedByMe'].isBlocked)
        "
      >
        This user is unavailable to contact on Celo.
        <a class="celo-link" (click)="userUnavailableDialog()">Learn more.</a>
      </div>
    </div>
    <div
      *ngIf="
        (!contact['blockedByMe'] || !contact['blockedByMe'].isBlocked) &&
        (!contact['blockedMe'] || !contact['blockedMe'].isBlocked)
      "
      class="profile-buttons"
    >
      <div
        class="button"
        [ngClass]="{ fw: !showSecureMessageButton() }"
        *ngIf="!isCurrentUser"
      >
        <button
          *ngIf="
            !contact['connection'] ||
            !contact['connection'].state ||
            contact['connection'].state === 'Disconnected' ||
            contact['connection'].state === 'Rejected'
          "
          mat-button
          class="celo-primary-button"
          (click)="connect()"
        >
          <div class="button-content pending-connect-button">
            <span>Connect</span>
          </div>
        </button>
        <button
          *ngIf="
            contact['connection'] && contact['connection'].state === 'Accepted'
          "
          mat-button
          class="celo-secondary-button"
          [matMenuTriggerFor]="menu"
        >
          <div class="button-content">
            <span>Connected</span>
            <i class="material-icons">keyboard_arrow_down</i>
          </div>
        </button>
        <mat-menu #menu="matMenu" class="remove-text">
          <button
            (click)="removeConnection(contact['connection'])"
            mat-menu-item
            class="remove-text"
          >
            Remove Connection
          </button>
        </mat-menu>
        <div
          *ngIf="
            contact['connection'] && contact['connection'].state === 'Pending'
          "
          
        >
          <button
            *ngIf="contact['connection']['createdBy'] === contact.userId"
            mat-button
            (click)="acceptConnection(contact['connection'])"
            class="celo-primary-button"
          >
            <div class="button-content">
              <span>Accept connection</span>
            </div>
          </button>
          <button
            *ngIf="contact['connection']['createdBy'] !== contact.userId"
            mat-button
            disabled
            class="celo-primary-button"
          >
            <div class="button-content pending-connect-button">
              <span>Pending</span>
            </div>
          </button>
        </div>
      </div>

      <div
        class="button"
        *ngIf="showSecureMessageButton()"
        [style.width]="isCurrentUser ? '100%' : null"
      >
        <button
          (click)="secureMessage(chatId)"
          mat-button
          class="celo-primary-button"
        >
          <div class="button-content">
            <span>Secure Message</span>
          </div>
        </button>
      </div>
    </div>
    <div
      *ngIf="
        (!contact['connection'] ||
          contact['connection'].state !== 'Accepted') &&
        !hasCommanWorkspace &&
        companiesLoaded &&
        !(contact['blockedByMe'] && contact['blockedByMe'].isBlocked) &&
        !(contact['blockedMe'] && contact['blockedMe'].isBlocked)
      "
      class="no-network"
    >
      Connect with this user to contact them.
    </div>
    <div class="profile-info">
      <div class="profile-title">Personal Info</div>
      <div *ngIf="contact.firstName" class="field appear_1">
        <div class="celo-field-title">Preferred Name</div>
        <div class="celo-text-14">{{ contact.firstName }}</div>
      </div>
      <div class="field appear_2">
        <div class="celo-field-title">Full Name</div>
        <div class="celo-text-14 celo-break-word flex items-center">
          {{ contact.legalFirstName }} {{ contact.lastName }}
          <app-badge
            *ngIf="contact.identityVerificationStatus === 'Verified'"
            class="inline-block badge"
            [identityVerificationStatus]="contact.identityVerificationStatus"
            [profession]="contact.professions[0]"
            [doNotShowProfessionVerificationStatus]="true"
            [workspaces]="contact.workplaces"
            [size]="20"
            [showTooltip]="false"
            [clickable]="true"
            [name]="contact.firstName + ' ' + contact.lastName"
            [name_verified_as]="contact.legalFirstName + ' ' + contact.lastName"
            [title]="contact.title"
            [userId]="contact.userId"
            [hasImage]="!!contact.profilePic"
          ></app-badge>
        </div>
      </div>
      <div
        *ngIf="contact.professions[0] && contact.professions[0].category"
        class="field appear_2"
      >
        <div class="celo-field-title">Profession</div>
        <div class="celo-text-14 flex items-center">
          <span>{{ contact.professions[0].profession }}</span>
          <div
            *ngIf="contact.professions[0].verificationStatus === 'Verified'"
            class="badge"
            (click)="$event.stopPropagation()"
          >
            <app-badge
              [identityVerificationStatus]="'Verified'"
              [profession]="contact.professions[0]"
              [workspaces]="contact.workplaces"
              [size]="20"
              [showTooltip]="false"
              [clickable]="true"
              [ownProfile]="false"
              [name]="contact.firstName + ' ' + contact.lastName"
              [name_verified_as]="
                contact.legalFirstName + ' ' + contact.lastName
              "
              [title]="contact.title"
              [userId]="contact.userId"
              [hasImage]="!!contact.profilePic"
            ></app-badge>
          </div>
          <span
            *ngIf="contact.professions[0].verificationStatus === 'Unverified'"
            class="subtext"
            >(Unverified)</span
          >
        </div>
      </div>
    </div>
    <div *ngIf="contact.phoneNumber || contact.email" class="profile-info">
      <div class="profile-title">Contact Info</div>
      <div class="profile-info-contents">
        <div
          *ngIf="contact.phoneNumber"
          mat-ripple
          class="celo-hoverable field"
        >
          <a [href]="'tel:' + contact.phoneNumber">
            <div class="celo-field-title">Mobile Number</div>
            <div class="celo-text-14">{{ contact.phoneNumber }}</div>
          </a>
        </div>
        <div *ngIf="contact.email" mat-ripple class="celo-hoverable field">
          <a [href]="'mailto:' + contact.email">
            <div class="celo-field-title">Primary Email</div>
            <div class="celo-text-14">{{ contact.email }}</div>
          </a>
        </div>
      </div>
    </div>

    <div class="profile-info">
      <div class="profile-title">Workspace Info</div>
      <div class="profile-info-contents">
        <div mat-ripple *ngFor="let company of companies">
          <div
            *ngIf="company.companyName && company.isActive !== false"
            class="celo-hoverable company-info celo-break-word"
          >
            <app-info-line
              [title]="company.companyName"
              [subtitles]="[
                company.position,
                company.departmentName
                  ? company.departmentName
                  : 'No department',
                company.email
              ]"
              [showBadge]="true"
              [workspaces]="[company]"
            ></app-info-line>
          </div>
        </div>
        <div *ngIf="!companies || !companies.length" class="no-workspace">
          No workspaces
        </div>
      </div>
    </div>
    <div *ngIf="!isCurrentUser">
      <button
        mat-button
        class="block-button"
        (click)="onBlockStatusChange(contact.userId)"
      >
        <span
          *ngIf="!contact['blockedByMe'] || !contact['blockedByMe'].isBlocked"
          >Block</span
        >
        <span *ngIf="contact['blockedByMe'] && contact['blockedByMe'].isBlocked"
          >Unblock</span
        >
      </button>
    </div>
  </div>
  <div *ngIf="loading && !contact">
    <app-empty-state [isLoading]="true"></app-empty-state>
  </div>
</div>
<div *ngIf="template === 'type2'" class="type-2">
  <div *ngIf="contact" class="profile">
    <div class="outlet-content">
      <div class="network-header header">
        <div class="network-cover">
          <div class="cover">
            <div class="cover-background">
              <div class="cover-image"></div>
            </div>
          </div>
        </div>
        <div class="logo">
          <div *ngIf="!false" class="icon">
            <app-avatar
              [name]="contact.firstName + ' ' + contact.lastName"
              [userId]="contact.userId"
              [width]="120"
              [height]="120"
              [hasImage]="!!contact.profilePic"
              [picUrl]="contact.profilePic"
              [badgeMargin]="'5'"
              [badgeBorder]="'4'"
            >
            </app-avatar>
          </div>
        </div>
        <div class="network-header-content">
          <div class="flex">
            <div class="title">
              {{ contact.title }} {{ contact.firstName }} {{ contact.lastName }}
            </div>
            <div class="badge-icon">
              &nbsp;<app-badge
                class="inline-block"
                [identityVerificationStatus]="
                  contact.identityVerificationStatus
                "
                [workspaces]="contact.workplaces"
                [profession]="contact.professions[0]"
                [size]="26"
                [showTooltip]="false"
                [clickable]="true"
                [title]="contact.title"
                [name]="contact.firstName + ' ' + contact.lastName"
                [name_verified_as]="
                  contact.legalFirstName + ' ' + contact.lastName
                "
                [userId]="contact.userId"
                [hasImage]="!!contact.profilePic"
              ></app-badge>
            </div>
          </div>
          <div
            *ngIf="contact.professions && contact.professions[0]"
            class="subtitle"
          >
            {{ contact.professions[0].profession }}
            <span
              *ngIf="contact.professions[0].verificationStatus === 'Unverified'"
              >(Unverified)</span
            >
          </div>
          <div *ngIf="dndUntil && dndUntil > now">
            <div class="dnd-status appear_2">
              <img
                class="svgIcon"
                src="../../assets/icons/icon-dnd-v2.svg"
                alt=""
              />
              <div class="dnd-text celo-elipsis">Do not disturb</div>
            </div>
          </div>
          <div
            class="appear_2 blocked-text"
            *ngIf="contact['blockedByMe'] && contact['blockedByMe'].isBlocked"
          >
            You’ve blocked {{ contact.title }} {{ contact.firstName }}
            {{ contact.lastName }}. You can unblock this user to send them a
            message.
          </div>
          <div
            class="appear_2 blocked-text"
            *ngIf="
              contact['blockedMe'] &&
              contact['blockedMe'].isBlocked &&
              !(contact['blockedByMe'] && contact['blockedByMe'].isBlocked)
            "
          >
            This user is unavailable to contact on Celo.
            <a class="celo-link" (click)="userUnavailableDialog()"
              >Learn more.</a
            >
          </div>
          <div class="flex">
            <div
              *ngIf="
                (!contact['connection'] ||
                  contact['connection'].state !== 'Accepted') &&
                !hasCommanWorkspace &&
                companiesLoaded &&
                !(contact['blockedByMe'] && contact['blockedByMe'].isBlocked) &&
                !(contact['blockedMe'] && contact['blockedMe'].isBlocked)
              "
              class="no-network"
            >
              Connect with this user to contact them.
            </div>
            <div
              *ngIf="
                (!contact['blockedByMe'] ||
                  !contact['blockedByMe'].isBlocked) &&
                (!contact['blockedMe'] || !contact['blockedMe'].isBlocked)
              "
              class="profile-buttons network-buttons"
            >
              <div
                class="button"
                [ngClass]="{ fw: !showSecureMessageButton() }"
                *ngIf="!isCurrentUser"
              >
                <button
                  *ngIf="
                    !contact['connection'] ||
                    !contact['connection'].state ||
                    contact['connection'].state === 'Disconnected' ||
                    contact['connection'].state === 'Rejected'
                  "
                  mat-button
                  class="celo-primary-button"
                  (click)="connect()"
                >
                  <div class="button-content pending-connect-button">
                    <span>Connect</span>
                  </div>
                </button>
                <button
                  *ngIf="
                    contact['connection'] &&
                    contact['connection'].state === 'Accepted'
                  "
                  mat-button
                  class="celo-secondary-button"
                  [matMenuTriggerFor]="menu"
                >
                  <div class="button-content">
                    <span>Connected</span>
                    <i class="material-icons">keyboard_arrow_down</i>
                  </div>
                </button>
                <mat-menu #menu="matMenu" class="remove-text">
                  <button
                    (click)="removeConnection(contact['connection'])"
                    mat-menu-item
                    class="remove-text"
                  >
                    Remove Connection
                  </button>
                </mat-menu>
                <div
                  *ngIf="
                    contact['connection'] &&
                    contact['connection'].state === 'Pending'
                  "
                  
                >
                  <button
                    *ngIf="
                      contact['connection']['createdBy'] === contact.userId
                    "
                    mat-button
                    (click)="acceptConnection(contact['connection'])"
                    class="celo-primary-button"
                  >
                    <div class="button-content">
                      <span>Accept connection</span>
                    </div>
                  </button>
                  <button
                    *ngIf="
                      contact['connection']['createdBy'] !== contact.userId
                    "
                    mat-button
                    disabled
                    class="celo-primary-button"
                  >
                    <div class="button-content pending-connect-button">
                      <span>Pending</span>
                    </div>
                  </button>
                </div>
              </div>

              <div class="button" *ngIf="showSecureMessageButton()">
                <button
                  (click)="secureMessage(chatId)"
                  mat-button
                  class="celo-primary-button"
                >
                  <div class="button-content">
                    <span>Secure Message</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div >
        <div class="flex">
          <div class="profile-box">
            <div
              class="profile-info profile-box-content profile-box-content-left"
            >
              <div class="profile-title">Personal Info</div>
              <div *ngIf="contact.firstName" class="field appear_1">
                <div class="celo-field-title">Preferred Name</div>
                <div class="celo-text-14">{{ contact.firstName }}</div>
              </div>
              <div class="field appear_2">
                <div class="celo-field-title">Full Name</div>
                <div class="celo-text-14 celo-break-word flex items-center">
                  {{ contact.legalFirstName }} {{ contact.lastName }}
                  <app-badge
                    *ngIf="contact.identityVerificationStatus === 'Verified'"
                    class="inline-block"
                    [identityVerificationStatus]="
                      contact.identityVerificationStatus
                    "
                    [profession]="contact.professions[0]"
                    [doNotShowProfessionVerificationStatus]="true"
                    [workspaces]="contact.workplaces"
                    [size]="20"
                    [showTooltip]="false"
                    [clickable]="true"
                    [name]="contact.firstName + ' ' + contact.lastName"
                    [name_verified_as]="
                      contact.legalFirstName + ' ' + contact.lastName
                    "
                    [title]="contact.title"
                    [userId]="contact.userId"
                    [hasImage]="!!contact.profilePic"
                  ></app-badge>
                </div>
              </div>
              <div
                *ngIf="
                  contact.professions[0] && contact.professions[0].category
                "
                class="field appear_2"
              >
                <div class="celo-field-title">Profession</div>
                <div class="celo-text-14">
                  <span>{{ contact.professions[0].profession }}</span>
                  <div
                    *ngIf="
                      contact.professions[0].verificationStatus === 'Verified'
                    "
                    class="badge"
                    (click)="$event.stopPropagation()"
                  >
                    <app-badge
                      [identityVerificationStatus]="'Verified'"
                      [profession]="contact.professions[0]"
                      [workspaces]="contact.workplaces"
                      [size]="20"
                      [showTooltip]="false"
                      [clickable]="true"
                      [ownProfile]="false"
                      [name]="contact.firstName + ' ' + contact.lastName"
                      [name_verified_as]="
                        contact.legalFirstName + ' ' + contact.lastName
                      "
                      [title]="contact.title"
                      [userId]="contact.userId"
                      [hasImage]="!!contact.profilePic"
                    ></app-badge>
                  </div>
                  <span
                    *ngIf="
                      contact.professions[0].verificationStatus === 'Unverified'
                    "
                    class="subtext"
                    >(Unverified)</span
                  >
                </div>
              </div>
            </div>
            <div
              class="profile-info profile-box-content profile-box-content-left"
              *ngIf="contact.phoneNumber || contact.email"
            >
              <div class="profile-title">Contact Info</div>
              <div class="profile-info-contents">
                <div
                  *ngIf="contact.phoneNumber"
                  mat-ripple
                  class="celo-hoverable field"
                >
                  <a [href]="'tel:' + contact.phoneNumber">
                    <div class="celo-field-title">Mobile Number</div>
                    <div class="celo-text-14">{{ contact.phoneNumber }}</div>
                  </a>
                </div>
                <div
                  *ngIf="contact.email"
                  mat-ripple
                  class="celo-hoverable field"
                >
                  <a [href]="'mailto:' + contact.email">
                    <div class="celo-field-title">Primary Email</div>
                    <div class="celo-text-14">{{ contact.email }}</div>
                  </a>
                </div>
              </div>
            </div>

            <div *ngIf="!isCurrentUser">
              <button
                mat-button
                class="block-button"
                (click)="onBlockStatusChange(contact.userId)"
              >
                <span
                  *ngIf="
                    !contact['blockedByMe'] || !contact['blockedByMe'].isBlocked
                  "
                  >Block</span
                >
                <span
                  *ngIf="
                    contact['blockedByMe'] && contact['blockedByMe'].isBlocked
                  "
                  >Unblock</span
                >
              </button>
            </div>
          </div>
          <div class="profile-box">
            <div
              class="profile-info profile-box-content profile-box-content-right"
            >
              <div class="profile-title">Workspace Info</div>
              <div class="profile-info-contents">
                <div mat-ripple *ngFor="let company of companies">
                  <div
                    *ngIf="company.companyName && company.isActive !== false"
                    class="celo-hoverable company-info celo-break-word"
                  >
                    <app-info-line
                      [title]="company.companyName"
                      [subtitles]="[
                        company.position,
                        company.departmentName
                          ? company.departmentName
                          : 'No department',
                        company.email
                      ]"
                      [showBadge]="true"
                      [workspaces]="[company]"
                    ></app-info-line>
                  </div>
                </div>
                <div
                  *ngIf="!companies || !companies.length"
                  class="no-workspace"
                >
                  No workspaces
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading && !contact">
    <app-empty-state [isLoading]="true"></app-empty-state>
  </div>
</div>
