<div  [ngClass]="{ row: responsive }">
  <div
    *ngFor="let companyob of companies"
    mat-ripple
    [ngClass]="{ 'col-12 col-lg-6': responsive }"
    class="p-2 celo-cursor-pointer celo-hoverable item"
    (click)="editable ? edit.emit(companyob) : ''"
  >
    <div class="company">
      <div class="celo-break-word">
        <div class="text">
          <app-info-line
            *ngIf="companyob.companyId"
            [title]="companyob.companyName"
            [subtitles]="[
              companyob.position,
              companyob.departmentName
                ? companyob.departmentName
                : 'No department',
              companyob.email
            ]"
            [showBadge]="true"
            [workspaces]="[companyob]"
          ></app-info-line>
        </div>
        <div *ngIf="editable" class="edit">
          <button mat-icon-button class="celo-tertiary-button">
            <span>Edit</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
