import { Component } from "@angular/core";

export interface InvitationInactiveDialogData {}

export interface InvitationInactiveDialogResult {}

@Component({
  selector: "app-invitation-inactive-dialog",
  templateUrl: "./invitation-inactive-dialog.component.html",
  styleUrls: ["./invitation-inactive-dialog.component.scss"],
})
export class InvitationInactiveDialogComponent {
  constructor() {}
}
