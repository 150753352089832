import { FullUserProfileModel, MessageModel } from "@types";
import { ConversationUtils, MessageUtils } from "@utils";
import { produce } from "immer";
import { ConversationsState } from "./types";

/**
 * **ONLY USE THIS FOR MESSAGES SENT BY THIS CLIENT!**
 *
 * Updates the given `state` with the given `messages`.
 *
 * This function assumes the conversation is already loaded. It should only be used when
 * merging in messages that have been sent by this client. Any other messages need to be
 * handled elsewhere as the related conversation or participant may need to be loaded in
 * order to merge it in.
 */
export const updateStateFromSentMessages = (
  messages: MessageModel[],
  user: FullUserProfileModel,
  state: ConversationsState
) => {
  return produce(state, (draft) => {
    for (const message of messages) {
      const existingConversationIndex = draft.conversations.findIndex(
        (c) => c.id === message.conversationId
      );

      if (existingConversationIndex === -1) {
        // This should never happen when sending a message
        throw new Error("Failed to to find conversation");
      }

      const existingConversation =
        draft.conversations[existingConversationIndex];

      const updatedConversation = ConversationUtils.updateFromMessageModel(
        existingConversation,
        message,
        user
      );

      draft.selectedReplyMessage = null;
      draft.conversations[existingConversationIndex] = updatedConversation;
      draft.conversations = ConversationUtils.sort(draft.conversations);

      if (draft.selectedConversationId !== message.conversationId) {
        continue;
      }

      const newMessage = MessageUtils.fromMessageModel(message);
      draft.selectedConversationMessages = MessageUtils.upsert(
        draft.selectedConversationMessages,
        newMessage,
        user
      );
    }
  });
};
