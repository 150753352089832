<div
  class="standard-quote-container"
  (click)="findChat.emit(message.id); $event.stopPropagation()"
>
  <div class="line"></div>
  <div
    class="quote"
    [ngClass]="{ 'from-me': fromMe, 'from-participant': fromSender }"
  >
    <div
      *ngIf="participantAccount"
      class="quote-name"
      [ngClass]="{
        self: userAccount.userId === message.sentBy,
        other: userAccount.userId !== message.sentBy
      }"
    >
      {{
        userAccount.userId === message.sentBy
          ? "You"
          : participantAccount.firstName + " " + participantAccount.lastName
      }}
    </div>

    <!-- Photo and Video -->
    <div *ngIf="message.type === 'Photo'" class="quoted-attachment-box">
      <div class="attachment-type">
        <mat-icon *ngIf="!message.mediaType || message.mediaType === 'Photo'"
          >photo_camera</mat-icon
        >
        <mat-icon *ngIf="message.mediaType === 'Video'">videocam</mat-icon>
        <span class="attachment-name">
          {{ message.mediaType ? message.mediaType : "Photo" }}</span
        >
      </div>
      <div class="preview-attachment">
        <img
          [src]="
            '/api/v2/media/' + message.metadata.photoId + '?size=Small'
              | clink
              | secure
          "
        />
      </div>
    </div>

    <!-- File -->
    <div *ngIf="message.type === 'PatientFile'" class="quoted-attachment-box">
      <div class="attachment-type">
        <mat-icon>insert_drive_file</mat-icon>
        <span class="attachment-name">
          {{
            message.metadata.fileName
              ? message.metadata.fileName
              : "No Filename"
          }}</span
        >
      </div>
      <div class="preview-attachment">
        <mat-icon>picture_as_pdf</mat-icon>
      </div>
    </div>

    <!-- Video Call -->
    <div *ngIf="message.type === 'VideoCall'" class="quoted-attachment-box">
      <div class="video-call-container">
        <div class="icon">
          <mat-icon svgIcon="video-on"></mat-icon>
        </div>
        <div class="info">
          <div class="title celo-elipsis" [innerText]="message.content"></div>
        </div>
      </div>
    </div>

    <!-- Text message -->
    <div class="quoted-text-box" *ngIf="!message.type">
      <p
        [ngClass]="{
          collapsed: collapsible,
          deleted: message.deletedOnUtc !== null
        }"
        [innerText]="message.content"
      ></p>
    </div>
  </div>
</div>
