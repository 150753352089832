<mat-sidenav-container class="fh">
  <mat-sidenav
    id="sidenavContact"
    
    #sidenav
    mode="over"
    (closed)="closed()"
    position="end"
  >
    <div class="appear_1">
      <app-profile
        *ngIf="profile"
        [contactId]="selectedContactId"
        [isDirectory]="true"
        [userAccount]="profile"
        (exit)="sidenav.close()"
      >
      </app-profile>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="fh">
    <div class="edit">
      <div class="list">
        <ul>
          <div class="title">Settings</div>
          <li [routerLink]="'/edit/general'" [routerLinkActive]="['selected']">
            <img
              class="celo-svg-icon"
              src="../../../assets/icons/icon-general-v2.svg"
              alt=""
            />
            <span>General</span>
          </li>
          <li [routerLink]="'/edit/privacy'" [routerLinkActive]="['selected']">
            <img
              class="celo-svg-icon"
              src="../../../assets/icons/icon-privacy-v3.svg"
              alt=""
            />
            <span>Privacy</span>
          </li>
          <li [routerLink]="'/edit/security'" [routerLinkActive]="['selected']">
            <img
              class="celo-svg-icon"
              src="../../assets/icons/icon-security-v2.svg"
              alt=""
            />
            <span>Security</span>
          </li>
          <li
            [routerLink]="'/edit/notifications'"
            [routerLinkActive]="['selected']"
          >
            <img
              class="celo-svg-icon"
              src="../../assets/icons/more-notifications.svg"
              alt=""
            />
            <span>Notifications</span>
          </li>
          <div class="title">Help & Support</div>
          <li>
            <a href="https://support.celohealth.com/hc/en-us/" target="_blank">
              <img
                class="celo-svg-icon"
                src="../../assets/icons/icon-helpcenter-v2.svg"
                alt=""
              />
              <span>Help Centre</span>
            </a>
          </li>
          <li>
            <a href="https://www.celohealth.com/contact-us/" target="_blank">
              <img
                class="celo-svg-icon"
                src="../../assets/icons/icon-contactus-v2.svg"
                alt=""
              />
              <span>Contact us</span>
            </a>
          </li>
          <li>
            <a href="https://www.celohealth.com/legal" target="_blank">
              <img
                class="celo-svg-icon"
                src="../../assets/icons/terms-of-use-v2.svg"
                alt=""
              />
              <span>Privacy & Terms</span>
            </a>
          </li>
          <li>
            <a (click)="addColleagues()" target="_blank">
              <img
                class="celo-svg-icon"
                src="../../assets/icons/icon-invitecolleagues-v2.svg"
                alt=""
              />
              <span>Add Colleagues</span>
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="profile" class="contents">
        <div
          class="content"
          *ngIf="
            page_type === 'security' ||
            page_type === 'privacy' ||
            page_type === 'general' ||
            page_type === 'notifications'
          "
        >
          <app-setting
            [userAccount]="profile"
            [type]="page_type"
            (openUser)="selectedContactId = $event; sidenav.open()"
          ></app-setting>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
