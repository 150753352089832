import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertComponent } from "@modules/core/components";
import { ZoomError, ZoomService } from "@modules/core/services/zoom.service";
import { SubscriptionContainer } from "@utils";
import { MediaDevice } from "@zoom/videosdk";
import {
  EMPTY,
  Subject,
  catchError,
  debounceTime,
  map,
  of,
  switchMap,
} from "rxjs";

@Component({
  selector: "app-voip-buttons",
  templateUrl: "./voip-buttons.component.html",
  styleUrls: ["./voip-buttons.component.scss"],
})
export class VoipButtonsComponent implements OnInit, OnDestroy {
  @Input() public participantId: string;
  @Input() public callId: string;
  @Input() public isAudioStarted: boolean;
  @Input() public isVideoStarted: boolean;

  @ViewChild("mediaInput")
  private mediaInput: ElementRef | null;

  @Output()
  public notifyCallPage: EventEmitter<void> = new EventEmitter<void>();

  @HostListener("document:click", ["$event.target"])
  public onMouseClick(targetElement) {
    const clickedInside =
      this.mediaInput?.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.isIODeviceListOpen = false;
    }
  }

  public isCapturingVideo$ = this.zoom.isCapturingVideo$;
  public isAudioMuted$ = this.zoom.isAudioMuted$;
  public isIODeviceListOpen = false;
  public speakerList: MediaDevice[] = [];
  public microphoneList: MediaDevice[] = [];
  public activeMicrophoneDevice: string = "";
  public activeSpeakerDevice: string = "";

  private toggleAudioSubject = new Subject<void>();
  private toggleVideoSubject = new Subject<void>();
  private debounceDuration = 100;

  private subscriptions = new SubscriptionContainer();

  constructor(
    private zoom: ZoomService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    const toggleAudioSubscription = this.toggleAudioSubject
      .pipe(
        debounceTime(this.debounceDuration),
        switchMap(() =>
          this.zoom.toggleAudio().pipe(
            map(() => ({ error: null })),
            catchError((error) => of({ error }))
          )
        )
      )
      .subscribe({
        next: (result) => {
          if (!result.error) return;
          this.handleError(result.error);
        },
      });

    const toggleVideoSubscription = this.toggleVideoSubject
      .pipe(
        debounceTime(this.debounceDuration),
        switchMap(() =>
          this.zoom.toggleVideo().pipe(
            map(() => ({ error: null })),
            catchError((error) => of({ error }))
          )
        )
      )
      .subscribe({
        next: (result) => {
          if (!result.error) return;
          this.handleError(result.error);
        },
      });

    this.zoom.speakerList$.subscribe((speakerList) => {
      this.speakerList = speakerList;
    });

    this.zoom.microphoneList$.subscribe((microphoneList) => {
      this.microphoneList = microphoneList;
    });

    this.subscriptions.add(toggleAudioSubscription, toggleVideoSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private handleError(error: unknown) {
    console.error(error);

    if (!(error instanceof ZoomError)) {
      AlertComponent.openErrorDialog(this.matDialog);
      return;
    }

    const descriptor = error.getErrorDescriptor();
    if (descriptor) {
      AlertComponent.openErrorDialogFromErrorDescriptor(
        this.matDialog,
        descriptor
      );
    } else {
      AlertComponent.openErrorDialog(this.matDialog);
    }
  }

  public toggleVideo = () => {
    this.toggleVideoSubject.next();
  };

  public toggleAudio = () => {
    this.toggleAudioSubject.next();
  };

  public leaveCall = () => {
    this.notifyCallPage.emit();
  };

  public toggleIOList = (event: MouseEvent) => {
    event.stopPropagation();
    this.isIODeviceListOpen = !this.isIODeviceListOpen;
    this.activeMicrophoneDevice = this.zoom.getActiveMicrophoneDevice();
    this.activeSpeakerDevice = this.zoom.getActiveSpeakerDevice();
  };

  public setMicrophoneDevice = (event: MouseEvent, deviceId: string) => {
    event.stopPropagation();
    this.zoom.setActiveMicrophone(deviceId).subscribe({
      next: () => {
        this.activeMicrophoneDevice = deviceId;
        this.isIODeviceListOpen = false;
      },
      error: (err) => {
        this.handleError(err);
      },
    });
  };

  public setSpeakerDevice = (event: MouseEvent, deviceId: string) => {
    event.stopPropagation();
    this.zoom.setActiveSpeaker(deviceId).subscribe({
      next: () => {
        this.activeSpeakerDevice = deviceId;
        this.isIODeviceListOpen = false;
      },
      error: (err) => {
        this.handleError(err);
      },
    });
  };
}
