<div *ngIf="exported" class="exported">Exported</div>

<div
  class="tile-container"
  [ngClass]="{ selected: libItem.selected, disabled }"
  (click)="handleClick()"
>
  <div *ngIf="libItem.payload.mediaType === 'Photo'" class="media-container">
    <img
      [src]="
        '/api/v2/media/' + libItem.payload.id + '?size=Medium' | clink | secure
      "
      (load)="loaded = true"
      class="appear-long"
      [ngClass]="{ 'appear-now': loaded }"
    />
    <div *ngIf="!loaded" class="loading">
      <mat-icon>photo</mat-icon>
    </div>
  </div>
  <div *ngIf="libItem.payload.mediaType === 'Video'" class="media-container">
    <img
      [src]="
        '/api/v2/media/' + libItem.payload.id + '?size=Medium' | clink | secure
      "
    />
  </div>
  <div *ngIf="libItem.payload && libItem.payload.creator" class="name-panel">
    <div
      *ngIf="
        libItem.payload &&
        libItem.payload.patientData &&
        libItem.payload.patientData.uid
      "
      class="patient-id"
    >
      {{ libItem.payload.patientData.uid }}
    </div>
    <div
      *ngIf="libItem.payload && libItem.payload.mediaType === 'Video'"
      class="video-icon"
    >
      <mat-icon [svgIcon]="'icon-video'"></mat-icon>
    </div>
    <div class="name-box">
      <div class="font-grey font-12 by">by</div>
      <div class="name-field">
        <div *ngIf="!ownFile" class="celo-elipsis fw">
          {{ libItem.payload.creator.title }}
          {{ libItem.payload.creator.firstName }}
          {{ libItem.payload.creator.lastName }}
        </div>
        <div *ngIf="ownFile">You</div>
      </div>
      <mat-icon *ngIf="canSelect">{{
        libItem.selected ? "check_circle" : "radio_button_unchecked"
      }}</mat-icon>
    </div>
  </div>
</div>
