import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { MentionModel, MessageModel } from "types";

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class MessageService {
  public onMessageSentSubject = new Subject<void>();
  public onMessageSent = this.onMessageSentSubject.asObservable();

  public onMessageSubmitSubject = new Subject<void>();

  public onMessageViewSubject = new Subject<MessageModel>();
  public onMessageView = this.onMessageViewSubject.asObservable();

  public onFirstMessageView = this.onMessageViewSubject.pipe(
    filter((m) => !!m.id && !this.viewedMessageIds.has(m.id)),
    tap((m) => {
      if (!m.id) return;
      this.viewedMessageIds.add(m.id);
    })
  );

  public onMentionClickedSubject = new Subject<any>();
  public onMentionClicked = this.onMentionClickedSubject.asObservable();

  public onInsertEmojiSubject = new Subject<any>();
  messageQuoted = new Subject<void>();

  private viewedMessageIds: Set<number> = new Set();

  getFormatedHtmlWithMentions(
    content: string,
    mentions: MentionModel[] = [],
    styleClasses: string
  ) {
    let previousIndex = 0;
    let contentHtml = "";
    mentions.forEach((mention) => {
      if (mention.index == null || !mention.name) {
        throw new Error("Invalid mention");
      }
      contentHtml += content.substring(previousIndex, mention.index);
      contentHtml += `<span class="${styleClasses}" data-id="${mention.id}"  data-type="${mention.type}">${mention.name}</span>`;
      previousIndex = mention.index + mention.name.length;
    });
    contentHtml += content.substring(previousIndex, content.length);
    return contentHtml;
  }

  isMentioned(mentions: MentionModel[] = [], userId: string) {
    if (!mentions) return false;
    let isMentioned = false;
    mentions.forEach((mention) => {
      if (mention.id === userId) {
        isMentioned = true;
        return;
      }
    });
    return isMentioned;
  }
}
