import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PinscreenService } from "app/pinscreen/pinscreen.service";

@Injectable()
export class PinGuard  {
  public constructor(private pinService: PinscreenService) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const isLocked = this.pinService.isLocked();
    if (isLocked) return this.pinService.getUrlTree(state);
    return true;
  }
}
