import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { createDialogConfig } from "@utils";
import { ProfileService } from "app/modules/profile/profile.service";
import { Team, TeamMember } from "types";

export interface RoleDetailsDialogData {
  team: Team;
  workspaceId: string;
  hidePrimaryAction?: boolean | null;
}

export interface RoleDetailsDialogResult {
  isLeft?: boolean;
}

@Component({
  selector: "app-role-details-dialog",
  templateUrl: "./role-details-dialog.component.html",
  styleUrls: ["./role-details-dialog.component.scss"],
})
export class RoleDetailsDialogComponent implements OnInit {
  public team: Team | null = null;
  public workspaceId: string | null = null;
  public hidePrimaryAction: boolean | null = null;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: RoleDetailsDialogData,
    private matDialogRef: MatDialogRef<
      RoleDetailsDialogComponent,
      RoleDetailsDialogResult
    >,
    private profileService: ProfileService
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: RoleDetailsDialogData
  ): MatDialogRef<RoleDetailsDialogComponent, RoleDetailsDialogResult> {
    const config = createDialogConfig<RoleDetailsDialogData>(data);
    return matDialog.open<
      RoleDetailsDialogComponent,
      RoleDetailsDialogData,
      RoleDetailsDialogResult
    >(RoleDetailsDialogComponent, config);
  }

  public ngOnInit(): void {
    const { team, workspaceId, hidePrimaryAction } = this.dialogData;
    this.team = team;
    this.workspaceId = workspaceId;
    this.hidePrimaryAction = hidePrimaryAction ?? null;
  }

  public handleUpdateTeam(team: Team) {
    this.team = team;
  }

  public handleLeaveTeam(team: Team) {
    this.matDialogRef.close({
      isLeft: true,
    });
  }

  public handleShowTeamMemberProfile(teamMember: TeamMember) {
    const userId = teamMember.user?.userId;
    if (!userId) return;
    this.profileService.openProfile(userId);
  }
}
