<app-role-member-popup-menu
  [member]="member"
  [isAdmin]="isAdmin"
  [isCurrentUserAdmin]="isCurrentUserAdmin"
  [disabled]="isSelf"
>
  <app-basic-user-avatar-card
    [name]="name"
    [description]="description"
    [userId]="member.user.userId"
    [isIdentityVerified]="member.user.identityVerified"
    [isWorkspaceVerified]="member.user.workspaceVerified"
    [isProfessionVerified]="member.user.professionVerified"
    [fetchImage]="!!member.user.profilePic"
  >
    <app-basic-user-statuses
      end
      [isOnCall]="isOnCall"
      [isAdmin]="isAdmin"
    ></app-basic-user-statuses>
  </app-basic-user-avatar-card>
</app-role-member-popup-menu>
