import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CeloMaterialModule } from "app/celo-material/celo-material.module";
import { SharedModule } from "app/modules/shared/shared.module";
import { ConversationsModule } from "./../conversations/conversations.module";
import { CreateRoleComponent } from "./create-role/create-role.component";
import { RoleDetailsDialogComponent } from "./role-details-dialog/role-details-dialog.component";
import { RoleDetailsFormComponent } from "./role-details-form/role-details-form.component";
import { RoleDetailsComponent } from "./role-details/role-details.component";
import { RoleMemberPopupMenuComponent } from "./role-member-popup-menu/role-member-popup-menu.component";
import { RolesClockOutComponent } from "./roles-clock-out/roles-clock-out.component";
import { RolesHomeEmptyStateComponent } from "./roles-home-empty-state/roles-home-empty-state.component";
import { RolesHomeSidenavComponent } from "./roles-home-sidenav/roles-home-sidenav.component";
import { RolesHomeComponent } from "./roles-home/roles-home.component";
import { RolesListItemComponent } from "./roles-list-item/roles-list-item.component";
import { RolesListComponent } from "./roles-list/roles-list.component";
import { RolesMembersListItemComponent } from "./roles-members-list-item/roles-members-list-item.component";
import { RolesMembersListComponent } from "./roles-members-list/roles-members-list.component";
import { RolesMenuComponent } from "./roles-menu/roles-menu.component";
import { RolesRoutingModule } from "./roles-routing.module";
import { EditRoleDetailsDialogComponent } from "./edit-role-details-dialog/edit-role-details-dialog.component";
import { RoleDetailsReadMoreDialogComponent } from "./role-details-read-more-dialog/role-details-read-more-dialog.component";

@NgModule({
  declarations: [
    RolesListComponent,
    RolesListItemComponent,
    RoleDetailsComponent,
    RolesMembersListComponent,
    RolesMembersListItemComponent,
    RoleDetailsFormComponent,
    RoleMemberPopupMenuComponent,
    CreateRoleComponent,
    RolesMenuComponent,
    RolesHomeComponent,
    RolesHomeSidenavComponent,
    RolesHomeEmptyStateComponent,
    RoleDetailsDialogComponent,
    RolesClockOutComponent,
    EditRoleDetailsDialogComponent,
    RoleDetailsReadMoreDialogComponent,
  ],
  imports: [
    CommonModule,
    CeloMaterialModule,
    SharedModule,
    ReactiveFormsModule,
    ConversationsModule,
    RolesRoutingModule,
  ],
  exports: [
    RolesListComponent,
    RoleDetailsComponent,
    CreateRoleComponent,
    RolesMenuComponent,
    RoleDetailsDialogComponent,
  ],
})
export class RolesModule {
  // public static forRoot(): ModuleWithProviders<RolesModule> {
  //   return {
  //     ngModule: RolesModule,
  //     providers: [TeamsService],
  //   };
  // }
}
