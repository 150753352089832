<div
  class="library"
  #library
  infiniteScroll
  [scrollWindow]="false"
  [infiniteScrollDistance]="2"
  [infiniteScrollUpDistance]="1.5"
  [infiniteScrollThrottle]="50"
  (scrolled)="onNewScrollPosition()"
>
  <!-- <div class="library" #library> -->
  <div class="library-container">
    <div class="library-tools" #tools>
      <div class="search-bar">
        <div class="topbar-input">
          <app-celo-topbar-search-input
            [placeholder]="'Search by Patient ID'"
            (doSearch)="queryChange($event)"
          >
          </app-celo-topbar-search-input>
        </div>
      </div>

      <div class="get-app-box font-14 celo-cursor-pointer" (click)="app()">
        <div>
          Consents can be created on the
          <span class="celo-link">Celo mobile app</span>
        </div>
      </div>

      <div class="toolbar">
        <div class="selector">
          <app-library-secondbar-toolbar
            (startSelect)="startSelect()"
            (cancelSelect)="cancelSelect()"
            [pageName]="'Consents'"
            [singleFileId]="
              getSelectedCount() === 1 && getFirstSelected()
                ? getFirstSelected().payload.id
                : ''
            "
            [singleFileName]="'Consent File.pdf'"
            [singleFileType]="'consent'"
            [selectedFileCount]="getSelectedCount()"
          >
          </app-library-secondbar-toolbar>
        </div>
        <div class="filter p-3">
          <div class="text">Uploaded by:&nbsp;&nbsp;</div>
          <div class="celo-radio-button">
            <mat-radio-group
              aria-label="Select an option"
              (change)="createdBy = $event.value; filterChange()"
            >
              <mat-radio-button class="mr-3" value="0" checked="true"
                >Me</mat-radio-button
              >
              <mat-radio-button class="mr-3" value="1">Others</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div
      class="library-list"
      [style.height.px]="library.offsetHeight - tools.offsetHeight"
    >
      <app-empty-state
        *ngIf="loading && (!consents || consents.length === 0)"
        [isLoading]="true"
        [message]="LOADING"
      >
      </app-empty-state>
      <div *ngIf="!loading && consents.length === 0">
        <app-empty-state
          *ngIf="!searchTerm"
          [title]="'Digital Consents'"
          [message]="
            'Easily capture patient consent for sharing information by using our simple digital signature feature.'
          "
          [isEmpty]="true"
          [image]="'empty_states/empty-state-consents.svg'"
        ></app-empty-state>
        <app-empty-state
          *ngIf="searchTerm"
          [title]="'No results found'"
          [message]="'Try searching by Patient ID'"
          [isEmpty]="true"
          [image]="'no-results-found-directory-v2.svg'"
        ></app-empty-state>
      </div>

      <div *ngIf="!loading && consents.length > 0" >
        <div class="group">
          <div class="list row">
            <div
              id="tile"
              #tile
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 item"
              *ngFor="let consent of consents"
              [style.height.px]="tile.offsetWidth"
            >
              <div class="grow-shadow card">
                <app-consent
                  [libItem]="consent"
                  (selected)="onSelected($event)"
                  [ownFile]="userId === consent.payload.creator.userId"
                ></app-consent>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="loading && !hideLoadMore">
          <app-empty-state [isLoading]="true" [message]="'Loading Consents'">
          </app-empty-state>
        </div>
        <div *ngIf="hideLoadMore" class="list-end">- That's all -</div>
      </div>
    </div>
  </div>
</div>
