import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserSelectionList } from "../..//shared/basic-user-selection-list/basic-user-selection-list.component";
import { Observable } from "rxjs";
import {
  SelectionList,
  UserPickerState,
  BasicUserPickerData,
  BasicUserPickerService,
} from "..";

@Component({
  selector: "app-workspace-user-picker-content",
  templateUrl: "./workspace-user-picker-content.component.html",
  styleUrls: ["./workspace-user-picker-content.component.scss"],
})
export class WorkspaceUserPickerContentComponent implements OnInit {
  public subheader?: string | null = "";

  public userGroups$: Observable<SelectionList[]> | null = null;
  public searchResults$: Observable<SelectionList[]> | null = null;
  public notDisabledSelectedUsers$: Observable<UserSelectionList> | null = null;
  public disabledSelectedUsers$: Observable<UserSelectionList> | null = null;

  public UserPickerState = UserPickerState;
  public state$: Observable<UserPickerState> | null = null;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: BasicUserPickerData,
    private userPickerService: BasicUserPickerService
  ) {}

  public ngOnInit(): void {
    const { subheader } = this.dialogData;

    this.subheader = subheader ?? null;

    this.state$ = this.userPickerService.state$;

    this.userGroups$ = this.userPickerService.userGroups$;
  }
}
