import { SYSTEM_USER_ID } from "@common";
import {
  CreateMessageMediaModel,
  CreateMessageModel,
  CreateMessagePatientFileModel,
  FullUserProfileModel,
  MediaType,
  Message,
  MessageModel,
  MessageStatuses,
  MessageType,
  Order,
  VideoCallStatus,
  VideoCallUpdate,
} from "@types";
import { produce } from "immer";
import { sorted } from "./array-utils";
import { dateCompareIfNotNull } from "./date-utils";
import { getCharacterLength, hasEmoji } from "./string-utils";

const updateMessage = (message: Message): void => {
  const characters = message.content ? getCharacterLength(message.content) : 0;
  const isSingleEmoji = characters === 1 && hasEmoji(message.content);
  message.isEmoji = isSingleEmoji;
};

const createMessage = (createdAndSentOnUtc: Date): Message => {
  const timestamp = createdAndSentOnUtc.toISOString();
  const message: Message = {
    id: Math.random() * Number.MAX_SAFE_INTEGER,
    marker: crypto.randomUUID(),
    content: "",
    type: null,
    createdOnUtc: timestamp,
    sentOnUtc: timestamp,
    isFirstOfTheDay: false,
  };
  return message;
};

const getMessageLastModifiedTime = (message: Message): Date => {
  return new Date(
    message.deletedOnUtc ?? message.sentOnUtc ?? message.createdOnUtc
  );
};

const getMessageTimestamp = (
  message: Message,
  currentUserId: string,
  defaultValue: Date | null = null
): Date | null => {
  /**
   * For ordering rules see:
   * https://celohealth.atlassian.net/wiki/spaces/CH/pages/98324/Chat+message+display+order+and+timestamps
   */

  if (message.type === MessageType.ConversationStarted)
    return new Date(message.createdOnUtc);

  if (!message.sentOnUtc && !message.createdOnUtc) return defaultValue;

  if (message.sentBy === currentUserId)
    return new Date(message.createdOnUtc ?? message.sentOnUtc);

  return new Date(message.sentOnUtc ?? message.createdOnUtc);
};

const updateMessageInPlace = (
  existingMessage: Message,
  updatedMessage: Message
): void => {
  for (const key in updatedMessage) {
    if (!updatedMessage.hasOwnProperty(key)) continue;
    if (key === "content" || key === "statuses") continue;
    existingMessage[key] = updatedMessage[key];
  }

  if (
    updatedMessage.deletedOnUtc ||
    updatedMessage.type === MessageType.VideoCall
  ) {
    existingMessage.content = updatedMessage.content;
  } else {
    // Don't replace statuses with ones from deleted messages as they don't include statuses
    existingMessage.statuses = updatedMessage.statuses;
  }

  if (updatedMessage.type === MessageType.VideoCall) {
    existingMessage.metadata = existingMessage.metadata ?? {};
    existingMessage.metadata.resourceCallDurationInSeconds =
      updatedMessage.metadata.resourceCallDurationInSeconds;
  }
};

const updateMessageMetadata = (messages: Message[]): void => {
  let prevDay = null;

  for (const message of messages) {
    const day = message.sentOnUtc ? new Date(message.sentOnUtc) : new Date();

    message.isFirstOfTheDay = false;

    if (
      !prevDay ||
      day.getDate() !== prevDay.getDate() ||
      day.getMonth() !== prevDay.getMonth() ||
      day.getFullYear() !== prevDay.getFullYear()
    ) {
      message.isFirstOfTheDay = true;
    }

    prevDay = day;
  }
};

export class MessageUtils {
  /**
   * Returns true if `newMessage` should replace `lastMessage` when updating a conversation's last message.
   *
   * Rules for updating a conversation's last message:
   *
   * - A conversation has no messages/last messages OR
   * - The last messages sentOnUtc value is less than or equal to the new message's value OR
   * - The last messages sentOnUtc value is greater than the new message's AND
   *   - the last message's marker is the same as the new messages AND
   *   - the last messages's last modified/deletedOnUtc value is less than or equal to the new message's
   */
  public static isMessageNewerThanLastMessage = (
    newMessage: Message,
    lastMessage: Message
  ): boolean => {
    const lastMessageSentOnUtc = new Date(lastMessage.sentOnUtc);
    const newMessageSentOnUtc = new Date(newMessage.sentOnUtc);

    if (lastMessageSentOnUtc <= newMessageSentOnUtc) return true;

    const lastMessageModifiedTime = getMessageLastModifiedTime(lastMessage);
    const newMessageModifiedTime = getMessageLastModifiedTime(newMessage);

    if (
      lastMessage.marker === newMessage.marker &&
      lastMessageModifiedTime <= newMessageModifiedTime
    ) {
      return true;
    }

    return false;
  };

  public static fromMessageModel = (model: MessageModel): Message => {
    const statuses = sorted(model.statuses, (a, b) => {
      return dateCompareIfNotNull(a.createdOnUtc, b.createdOnUtc);
    });

    const message: Message = {
      id: model.id,
      conversationId: model.conversationId,
      sentBy: model.sentBy,
      sentOnUtc: model.sentOnUtc,
      createdOnUtc: model.createdOnUtc,
      content: model.content,
      marker: model.marker,
      unreadCount: model.unreadCount,
      type: model.type,
      mediaType: model.mediaType,
      metadata: model.metadata,
      statuses,
      mentions: model.mentions,
      replyTo: model.replyTo,
      replyToMessage: model.replyToMessage,
      allowDelivery: model.allowDelivery,
      deletedOnUtc: model.deletedOnUtc,
      deletedBy: model.deletedBy,
      isSent: model.sentOnUtc != null,
      isFirstOfTheDay: false,
    };

    updateMessage(message);

    return message;
  };

  public static updateMessageFromVideoCallUpdate = (
    update: VideoCallUpdate,
    message: Message
  ): Message => {
    if (message.type !== MessageType.VideoCall)
      throw new Error(
        "Only video call messages can be updated from a video call update"
      );

    const content =
      update.status === VideoCallStatus.InProgress
        ? "Video call started"
        : "Video call ended";

    const timestamp =
      update.status === VideoCallStatus.InProgress
        ? update.startedOn
        : update.endedOn;

    let resourceCallDurationInSeconds: number | null = null;
    if (update.status === VideoCallStatus.Ended) {
      resourceCallDurationInSeconds =
        (new Date(update.endedOn).getTime() -
          new Date(update.createdOn).getTime()) /
        1000;
    }

    return produce(message, (draft) => {
      draft.type = MessageType.VideoCall;
      draft.content = content;
      draft.sentOnUtc = timestamp;
      draft.createdOnUtc = timestamp;
      draft.conversationId = update.conversationId;
      draft.sentBy = update.createdBy;
      draft.metadata = {
        resourceId: update.id,
        resourceStatus: update.status,
        resourceType: "VideoCall",
        resourceCallDurationInSeconds: `${resourceCallDurationInSeconds}`,
      };
    });
  };

  public static createVideoCallMessage = (update: VideoCallUpdate): Message => {
    const message = createMessage(
      new Date(update.createdOn ?? update.startedOn)
    );
    message.type = MessageType.VideoCall;
    const updatedMessage = this.updateMessageFromVideoCallUpdate(
      update,
      message
    );

    updateMessage(message);

    return updatedMessage;
  };

  public static fromCreateMessageModel = (
    createMessageModel: CreateMessageModel,
    conversationId: string,
    user: FullUserProfileModel,
    replyToMessage?: Message
  ): Message => {
    const userId = user.userId;
    const message: Message = {
      content: createMessageModel.content,
      mentions: createMessageModel.mentions,
      marker: createMessageModel.marker,
      createdOnUtc: createMessageModel.createdOnUtc,
      sentBy: userId,
      conversationId,
      metadata: {
        forwardFromConversationId: createMessageModel.forwardFromConversationId,
        forwardFromMessageId: createMessageModel.forwardFromMessageId,
      },
      statuses: [
        {
          status: MessageStatuses.Sending,
          createdOnUtc: createMessageModel.createdOnUtc,
          createdBy: userId,
        },
      ],
      isFirstOfTheDay: false,
    };

    if (replyToMessage) {
      message.replyToMessage = replyToMessage;
      message.replyTo = replyToMessage.marker;
    }

    updateMessage(message);

    return message;
  };

  public static fromCreateMessageMediaModel = (
    createMessageMediaModel: CreateMessageMediaModel,
    conversationId: string,
    user: FullUserProfileModel,
    replyToMessage?: Message
  ): Message => {
    const userId = user.userId;
    const message: Message = {
      content: createMessageMediaModel.content,
      mentions: createMessageMediaModel.mentions,
      marker: createMessageMediaModel.marker,
      createdOnUtc: createMessageMediaModel.createdOnUtc,
      sentBy: userId,
      conversationId,
      type: MessageType.Photo,
      mediaType: MediaType.Photo,
      metadata: {
        photoId: createMessageMediaModel.photoId,
      },
      statuses: [
        {
          status: MessageStatuses.Sending,
          createdOnUtc: createMessageMediaModel.createdOnUtc,
          createdBy: userId,
        },
      ],
      isFirstOfTheDay: false,
    };

    if (replyToMessage) {
      message.replyToMessage = replyToMessage;
      message.replyTo = replyToMessage.marker;
    }

    updateMessage(message);

    return message;
  };

  public static fromCreateMessagePatientFileModel = (
    createMessagePatientFileModel: CreateMessagePatientFileModel,
    conversationId: string,
    user: FullUserProfileModel,
    replyToMessage?: Message
  ): Message => {
    const userId = user.userId;
    const message: Message = {
      content: createMessagePatientFileModel.content,
      marker: createMessagePatientFileModel.marker,
      createdOnUtc: createMessagePatientFileModel.createdOnUtc,
      sentBy: userId,
      conversationId,
      type: MessageType.PatientFile,
      metadata: {
        fileId: createMessagePatientFileModel.fileId,
        forwardFromConversationId:
          createMessagePatientFileModel.fromConversationId,
        forwardFromMessageId: createMessagePatientFileModel.fromMessageId,
      },
      statuses: [
        {
          status: MessageStatuses.Sending,
          createdOnUtc: createMessagePatientFileModel.createdOnUtc,
          createdBy: userId,
        },
      ],
      isFirstOfTheDay: false,
    };

    if (replyToMessage) {
      message.replyToMessage = replyToMessage;
      message.replyTo = replyToMessage.marker;
    }

    updateMessage(message);

    return message;
  };

  public static upsert = (
    messages: Message[],
    newMessage: Message,
    user: FullUserProfileModel,
    options?: Partial<{ isAppendingEnabled: boolean }>
  ): Message[] => {
    return produce(messages, (draft) => {
      // Update replies to this message
      const updatedReplyMarkers = new Set<string>();
      let replyIndex = draft.findIndex(
        (m) => m.replyToMessage?.marker === newMessage.marker
      );

      while (replyIndex !== -1) {
        draft[replyIndex].replyToMessage = newMessage;
        updatedReplyMarkers.add(draft[replyIndex].marker);
        replyIndex = draft.findIndex(
          (m) =>
            m.replyToMessage?.marker === newMessage.marker &&
            !updatedReplyMarkers.has(m.marker)
        );
      }

      // Update message if it already exists on the client
      const existingMessageIndex = draft.findIndex(
        (m) => m.marker === newMessage.marker
      );

      if (existingMessageIndex !== -1) {
        draft[existingMessageIndex] = newMessage;
        updateMessageMetadata(draft);
        return;
      }

      // Add new message
      const newMessageTime = getMessageTimestamp(
        newMessage,
        user.userId,
        new Date()
      );

      if (!newMessage.sentOnUtc && !newMessage.createdOnUtc) {
        newMessage.sentOnUtc = newMessageTime.toISOString();
      }

      // If this is the first message in the conversation, add it and do nothing else
      if (draft == null || draft.length === 0) {
        const newMessages = [newMessage];
        updateMessageMetadata(newMessages);
        return newMessages;
      }

      const oldestMessageDate = getMessageTimestamp(draft[0], user.userId);
      const latestMessageDate = getMessageTimestamp(
        draft[draft.length - 1],
        user.userId
      );

      if (!oldestMessageDate || !latestMessageDate) {
        // Something's gone wrong. Every message should always have a sentOnUtc value
        // or a createdOnUtc value. If we couldn't find either of these fallback
        // to appending the message to the bottom of the conversation.
        draft.push(newMessage);
        updateMessageMetadata(draft);
        console.error("Failed to find timestamp for message");
        return;
      }

      if (newMessageTime < oldestMessageDate) {
        // If appending is disabled and this message is older than the oldest message we currently have
        // then do nothing, the client will load it when they scroll up.
        if (!options?.isAppendingEnabled) return;
        // WARNING: Appending the message to the end list of messages can break the existing
        // pagination system. See: https://celohealth.atlassian.net/browse/CELO-11021
        draft.unshift(newMessage);
        updateMessageMetadata(draft);
        return;
      }

      // If this message is older than the latest message we currently have
      // insert it before the latest message it is older than
      if (newMessageTime < latestMessageDate) {
        const insertionPosition =
          1 +
          draft.findLastIndex(
            (m) =>
              dateCompareIfNotNull(
                m.sentOnUtc ?? m.createdOnUtc,
                newMessage.sentOnUtc ?? newMessage.createdOnUtc
              ) > 0
          );
        draft.splice(insertionPosition, 0, newMessage);
        updateMessageMetadata(draft);
        return;
      }

      // Appending all other messages to the bottom
      if (draft.length > 0) {
        draft.push(newMessage);
        updateMessageMetadata(draft);
      }
    });
  };

  public static isRead = (message: Message, userId: string): boolean => {
    if (message.sentBy === SYSTEM_USER_ID || message.sentBy === userId)
      return true;
    const isRead = message.statuses?.some(
      (s) => s.createdBy === userId && s.status === MessageStatuses.Read
    );
    return isRead;
  };

  public static sort = (
    messages: Message[],
    user: FullUserProfileModel
  ): Message[] => {
    const userId = user.userId;
    return sorted(messages, (a, b) => {
      const dateA = getMessageTimestamp(a, userId);
      const dateB = getMessageTimestamp(b, userId);
      return dateA < dateB ? -1 : 1;
    });
  };

  public static getFirstMessageBySentOnUtc = (
    messages: Message[],
    order: Order
  ): Message => {
    if (!messages?.length) return null;
    return messages.reduce((prev, curr) => {
      if (!curr.sentOnUtc) return prev;
      if (!prev) return curr;

      if (prev.sentOnUtc === curr.sentOnUtc) return prev;

      if (order === Order.Descending) {
        return prev.sentOnUtc > curr.sentOnUtc ? prev : curr;
      }
      return prev.sentOnUtc < curr.sentOnUtc ? prev : curr;
    }, null);
  };

  public static isMentioned(message: Message, userId: string): boolean {
    if (!message.mentions?.length) return false;
    return message.mentions.some((m) => m.id === userId);
  }
}
