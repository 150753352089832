import { Injectable, OnDestroy } from "@angular/core";
import { UserPickerSubmitCallback } from "./user-picker.service";
import { BehaviorSubject, EMPTY, Observable, forkJoin, of } from "rxjs";
import { concatMap, mapTo, switchMap } from "rxjs/operators";
import {
  ConversationModelV2,
  ConversationType,
  CreateMessageMediaModel,
  CreateMessageModel,
  CreateMessagePatientFileModel,
  IdentityVerificationStatus,
  PatientDataModel,
  ProfessionVerificationStatus,
  Team,
  VerificationStatus,
} from "types";
import { SubscriptionContainer, concatNotNull } from "utils";
import { AccountService } from "../core/services/account.service";
import { ConversationsService } from "../core/services/conversations.service";
import { TeamsService } from "./../core/services/teams.service";
import {
  UserSelectionList,
  UserSelectionListItem,
} from "./../shared/basic-user-selection-list/basic-user-selection-list.component";
import {
  BasicUserPickerData,
  BasicUserPickerService,
} from "./basic-user-picker.service";
import { ConversationService } from "@modules/core";

export enum NetworkUserPickerMode {
  CREATE,
  MULTIPLE_SELECT,
}

export interface NetworkUserPickerData extends BasicUserPickerData {
  variant: "network-user-picker";
  disabledUserIds?: string[];
  initiallySelectedUserIds?: string[];
  mode?: NetworkUserPickerMode;
  excludeTeams?: boolean;
  excludeConnections?: boolean;
  excludeSuggestions?: boolean;
  workspaceId?: string | null;
  excludeSelf?: boolean;
  initiallyExpandedWorkspaceIds?: string[] | null;
  submitCallback?: UserPickerSubmitCallback;
  asTeamId?: string | null;

  /**
   * Only applies if mode is `NetworkUserPickerMode.CREATE`. This message will be sent to the chat that's created
   * by this picker.
   */
  message?: CreateMessageModel | null;

  /**
   * Only applies if mode is `NetworkUserPickerMode.CREATE`. These files will be sent to the chat that's created
   * by this picker.
   */
  media?: CreateMessageMediaModel[] | null;

  /**
   * Only applies if mode is `NetworkUserPickerMode.CREATE`. These files will be sent to the chat that's created
   * by this picker.
   */
  patientFiles?: CreateMessagePatientFileModel[] | null;
}

export enum NetworkUserPickerState {
  CREATE_CHAT,
  CREATE_GROUP,
  CREATE_CASE,
  MULTIPLE_SELECT,
}

@Injectable()
export class NetworkUserPickerService implements OnDestroy {
  private stateSubject = new BehaviorSubject<NetworkUserPickerState>(
    NetworkUserPickerState.CREATE_CHAT
  );

  public state$ = this.stateSubject.asObservable();

  private asTeamSubject = new BehaviorSubject<Team | null>(null);
  public asTeam$ = this.asTeamSubject.asObservable();

  private hideSuggestionsSubject = new BehaviorSubject<boolean>(false);
  public hideSuggestions$ = this.hideSuggestionsSubject.asObservable();

  private initiallyExpandedWorkspaceIdsSubject = new BehaviorSubject<string[]>(
    []
  );

  public initiallyExpandedWorkspaceIds$ =
    this.initiallyExpandedWorkspaceIdsSubject.asObservable();

  public isDiscoverable$: Observable<boolean> | null =
    this.accountService.isDiscoverable$;

  public isVerified$ = this.accountService.isVerified$;

  private userPickerService: BasicUserPickerService | null = null;
  private data: NetworkUserPickerData | null = null;

  private subscriptions = new SubscriptionContainer();

  public constructor(
    private accountService: AccountService,
    private conversationsService: ConversationsService,
    private teamsService: TeamsService,
    private conversationService: ConversationService
  ) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public initialise(
    userPickerService: BasicUserPickerService,
    data: NetworkUserPickerData
  ) {
    this.userPickerService = userPickerService;
    this.data = data;

    this.userPickerService?.user$.subscribe({
      next: (user) => {
        if (!user) return;
        this.handleUser(user);
      },
    });

    if (data.asTeamId) {
      const teamSubscription = this.teamsService
        .getTeam({ teamId: data.asTeamId })
        .subscribe((team) => {
          this.asTeamSubject.next(team);
        });
      this.subscriptions.add(teamSubscription);
    }

    this.hideSuggestionsSubject.next(data.excludeSuggestions ?? false);
    this.initiallyExpandedWorkspaceIdsSubject.next(
      data.initiallyExpandedWorkspaceIds ?? []
    );

    this.state$.subscribe(this.handleState.bind(this));

    if (data.mode === NetworkUserPickerMode.MULTIPLE_SELECT) {
      this.stateSubject.next(NetworkUserPickerState.MULTIPLE_SELECT);
    }
  }

  private selectCurrentuser() {
    const account = this.accountService.getCurrentAccount();
    if (
      !account ||
      !account.userId ||
      !account.workplaces ||
      !account.professions
    ) {
      throw new Error("User account is invalid or undefined");
    }
    this.userPickerService?.updateUser({
      id: account.userId,
      name: concatNotNull([account.firstName, account.lastName]),
      isWorkspaceVerified: account.workplaces.some(
        (w) =>
          w.isActive && w.verificationStatus === VerificationStatus.Verified
      ),
      isIdentityVerified:
        account.identityVerificationStatus ===
        IdentityVerificationStatus.Verified,
      isProfessionVerified: account.professions.some(
        (p) => p.verificationStatus === ProfessionVerificationStatus.Verified
      ),
      isSelected: true,
      isDisabled: true,
      fetchImage: account.picture != null,
      suffix: " (You)",
    });
  }

  public onCreateChat() {
    this.stateSubject.next(NetworkUserPickerState.CREATE_CHAT);
    this.userPickerService?.setTeamsVisibility(true);
  }

  public onCreateGroup() {
    this.selectCurrentuser();
    this.stateSubject.next(NetworkUserPickerState.CREATE_GROUP);
    this.userPickerService?.setTeamsVisibility(false);
  }

  public onCreateCase() {
    this.selectCurrentuser();
    this.stateSubject.next(NetworkUserPickerState.CREATE_CASE);
    this.userPickerService?.setTeamsVisibility(false);
  }

  public onBack() {
    this.userPickerService?.reset();
    this.stateSubject.next(
      this.data?.mode !== NetworkUserPickerMode.MULTIPLE_SELECT
        ? NetworkUserPickerState.CREATE_CHAT
        : NetworkUserPickerState.MULTIPLE_SELECT
    );
    this.userPickerService?.setTeamsVisibility(true);
  }

  private handleState(state: NetworkUserPickerState) {
    if (!this.userPickerService) return;

    if (this.data?.header) {
      this.userPickerService.setHeader(this.data?.header);
      return;
    }

    switch (state) {
      case NetworkUserPickerState.CREATE_CHAT:
        this.userPickerService.setHeader("New Chat");
        break;
      case NetworkUserPickerState.CREATE_GROUP:
        this.userPickerService.setHeader("New Group");
        break;
      case NetworkUserPickerState.CREATE_CASE:
        this.userPickerService.setHeader("New Case");
        break;
    }
  }

  private submit(
    submitCallback: (
      selectedUserIds: string[]
    ) => Observable<ConversationModelV2>,
    minimumSelectionSize: number = 1
  ) {
    const callback: UserPickerSubmitCallback = (users: UserSelectionList) => {
      const selectedUserIds = users.map((u) => u.id);

      // Get or create conversation
      let conversation$ = submitCallback(selectedUserIds);

      // Send/share media to conversation
      if (this.data?.mode === NetworkUserPickerMode.CREATE) {
        conversation$ = conversation$.pipe(
          concatMap((conversation) => {
            if (!conversation.id) throw new Error("Invalid conversation id");
            if (!this.data) return of(conversation);

            const { message, media, patientFiles } = this.data;
            if (!message && !media?.length && !patientFiles?.length) {
              return of(conversation);
            }

            const observables: Observable<unknown>[] = [];

            if (this.data?.message) {
              observables.push(
                this.conversationsService.sendMessage(
                  conversation.id,
                  this.data.message
                )
              );
            }

            if (this.data?.media?.length) {
              observables.push(
                this.conversationsService.sendMedia(
                  conversation.id,
                  this.data.media
                )
              );
            }

            if (this.data?.patientFiles?.length) {
              observables.push(
                this.conversationsService.sendPatientFiles(
                  conversation.id,
                  this.data.patientFiles
                )
              );
            }

            return forkJoin(observables).pipe(mapTo(conversation));
          })
        );
      }

      return conversation$.pipe(
        switchMap((conversation) => {
          // Add conversation to legacy conversation service
          this.conversationService.addOrReplaceConversation(conversation);

          // Navigate to conversation after it's been created
          if (!conversation.id) throw new Error("Invalid conversation id");
          if (conversation.type !== ConversationType.TeamChat) {
            this.conversationsService.navigateToConversation(conversation.id);
            return EMPTY;
          }

          const isMember = this.data?.asTeamId
            ? conversation.teamIds?.includes(this.data?.asTeamId)
            : false;

          if (isMember) {
            this.conversationsService.navigateToConversation(
              conversation.id,
              this.data?.asTeamId
            );
          } else {
            this.conversationsService.navigateToConversation(conversation.id);
          }
          return EMPTY;
        })
      );
    };

    this.userPickerService?.setData(callback);
    this.userPickerService?.submitSelectedUsers(minimumSelectionSize);
  }

  private handleUser(user: UserSelectionListItem) {
    if (!user) return;
    if (this.stateSubject.value !== NetworkUserPickerState.CREATE_CHAT) return;

    this.submit(() => {
      const asTeamId = this.asTeamSubject.value?.id;
      if (asTeamId) {
        if (user.isTeam)
          throw new Error("You cannot create a chat with multiple teams");
        return this.conversationsService.createTeamChat(asTeamId, user.id);
      } else {
        if (user.isTeam) {
          return this.conversationsService.createTeamChat(user.id);
        }
        return this.conversationsService.createChat(user.id);
      }
    }, 0);
  }

  public createGroup(conversationName: string) {
    this.submit((selectedUserIds) => {
      return this.conversationsService.createGroup(
        conversationName,
        selectedUserIds
      );
    });
  }

  public createCase(conversationName: string, patientData: PatientDataModel) {
    this.submit((selectedUserIds) => {
      return this.conversationsService.createCase(
        conversationName,
        selectedUserIds,
        patientData
      );
    });
  }

  public save() {
    this.userPickerService?.submitSelectedUsers();
  }
}
