import { CountryModel } from "./../../../types/api-v1";
import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  AnalyticsService,
  AuthService,
  SharedService,
} from "../../modules/core";

@Component({
  selector: "app-country",
  templateUrl: "./country.component.html",
  styleUrls: [
    "./country.component.scss",
    "../onboard.scss",
    "../../../celo-input.scss",
  ],
})
export class CountryComponent implements OnInit, OnDestroy {
  country = {};
  defaultOnboardingRegion = "EUR";

  constructor(
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private authService: AuthService
  ) {
    this.sharedService.setMeta("robots", "[index,nofollow]");
  }

  ngOnDestroy() {
    this.sharedService.setMeta("robots", "noindex");
  }

  ngOnInit() {
    this.analyticsService.raiseEvent("celo_page_view", {
      flow: "signing_up",
      page_name: "country_selection_page",
    });
  }

  onSelected(country: CountryModel) {
    this.authService.setOnboardingDataRegion(
      country.dataRegionId ?? this.defaultOnboardingRegion
    );

    this.analyticsService.raiseEvent("country_selected", {
      flow: "signing_up",
      selected_country: country.code,
      is_changed: "true",
    });
  }

  submit() {
    this.analyticsService.raiseEvent("country_submitted", {
      flow: "signing_up",
      selected_country: this.country["code"],
    });
  }

  public tAndCEvent() {
    this.analyticsService.buttonClickEvent("t_and_c_link", {
      flow: "signing_up",
      page_name: "country_selection_page",
    });
  }
}
