import { Component, Input } from "@angular/core";

@Component({
  selector: "app-roles-icon",
  templateUrl: "./roles-icon.component.html",
  styleUrls: ["./roles-icon.component.scss"],
})
export class RolesIconComponent {
  @Input() public backgroundSize: string = "40px";
  @Input() public size: string = "32px";
}
