<div *ngIf="workspace && workspace.workspace">
  <div *ngIf="!inviteMode">
    <div class="details">
      <div class="details-title-text">
        <div class="details-title celo-break-word">
          {{ workspace.workspace.name }}
          <span>
            <div class="badge">
              <app-badge
                class="inline-block"
                [identityVerificationStatus]="false"
                [workspaces]="[workspace.workspace]"
                [hideUnverifiedBadge]="true"
                [size]="22"
                [showTooltip]="false"
                [clickable]="false"
              ></app-badge>
            </div>
          </span>
        </div>
        <div
          class="details-subtitle"
          *ngIf="workspace.workspace.verificationStatus === 'Verified'"
        >
          Verified Workspace
        </div>
      </div>
      <div class="subtitle">
        <a
          class="celo-link"
          target="_blank"
          href="https://support.celohealth.com/hc/en-us/articles/1500003870121"
          >Learn more</a
        >
        about how you can
        <span *ngIf="workspace.verificationStatus !== 'Verified'"
          >verify and </span
        >make changes to this workspace.
      </div>
    </div>
    <div
      *ngIf="
        (workspace.claims &&
          workspace.claims.workspaceInvitationInvite === 'true' &&
          workspace.workspace.invitationUri) ||
        (workspace.claims && workspace.claims.workspaceUserManage === 'true')
      "
      class="settings"
    >
      <div class="title">Workspace settings</div>
      <div
        *ngIf="
          workspace.claims &&
          workspace.claims.workspaceInvitationInvite === 'true' &&
          workspace.workspace.invitationUri
        "
      >
        <div>
          <button
            matRipple
            class="celo-hoverable fw"
            (click)="inviteMode = !inviteMode"
          >
            <mat-icon svgIcon="add-filled"></mat-icon>
            <div class="text">Invite to workspace</div>
          </button>
        </div>
      </div>
      <button
        *ngIf="
          workspace.claims &&
          workspace.claims.workspaceUserManage === 'true' &&
          removeWorkspaceUserFeatureEnabled
        "
        matRipple
        [disabled]="disabeleRemoveMembers"
        class="celo-hoverable fw"
        (click)="editMembers()"
      >
        <mat-icon
          style="padding-left: 2px; padding-right: 2px"
          svgIcon="ic-remove-users"
        ></mat-icon>
        <div class="text">Remove members</div>
      </button>
      <button
        *ngIf="
          workspace.claims &&
          workspace.claims.workspaceUserManage === 'true' &&
          addWorkspaceUserFeatureEnabled
        "
        matRipple
        [disabled]="disabeleAddMembers"
        class="celo-hoverable fw"
        (click)="addMembers()"
      >
        <mat-icon svgIcon="ic-case"></mat-icon>
        <div class="text">Add members</div>
      </button>
    </div>
    <div class="settings">
      <div class="title">My details</div>
      <div #detailsAnchor id="detailsAnchor"></div>
      <app-add-company-details
        [company]="workspaceDetails"
        [workspace]="workspaceDetails"
        [department]="workspaceDetails.departmentId"
        [position]="workspaceDetails.position"
        [hideCompanyField]="true"
        [email]="workspaceDetails.email"
        [companyReadonly]="true"
        (validity)="workspace_details_valid = $event"
        (dataChange)="workspace_details = $event"
      ></app-add-company-details>
      <app-celo-cancel-save-buttons
        [showNow]="workspace_details && workspace_details_valid && !saving"
        (cancel)="workspace_details = undefined; cancel()"
        (save)="save()"
      >
      </app-celo-cancel-save-buttons>
    </div>
    <div class="leave-button">
      <a class="celo-link-danger celo-bold" (click)="leaveWorkspace()"
        >Leave workspace</a
      >
    </div>
  </div>
  <div *ngIf="inviteMode">
    <div class="settings">
      <div class="title">Invite via email</div>
      <app-emailer
        [type]="'workspace'"
        [id]="workspaceId"
        [hideTertiaryButton]="true"
        [sideMode]="true"
      ></app-emailer>
    </div>
    <div class="settings">
      <div class="title">Invite via link</div>
      <div class="subtitle">
        Copy and share the invite link with your colleagues. Anyone can join by
        following the link
      </div>
      <div>
        <app-link-share
          [link]="workspace.workspace.invitationUri"
          [title]="''"
          [subtitle]="''"
          [canReset]="
            workspace.claims &&
            workspace.claims.workspaceInvitationReset === 'true'
          "
          [disableEmailInvite]="true"
          [shareBody]="
            'Follow this link to join the ' +
            workspace.workspace.name +
            ' workspace on Celo ' +
            workspace.workspace.invitationUri
          "
          [hideLink]="true"
          [copyButttonText]="'Copy invite link'"
          (linkClick)="copy($event); linkEvent('details_link')"
          (shareClick)="
            shareViaEmail(workspace.workspace.invitationUri);
            linkEvent('share_button')
          "
          (copyClick)="copy($event); linkEvent('copy_from_details_page')"
          (resetLink)="resetLink($event, workspace)"
        ></app-link-share>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!workspace">
  <div class="loading-screen">
    <app-empty-state
      [no_message]="true"
      [isLoading]="true"
      [spinner]="false"
      [animation]="true"
      [diameter]="70"
    ></app-empty-state>
  </div>
</div>
