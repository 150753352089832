import { Component, Input } from "@angular/core";
import { AccountService } from "@modules/core";
import { isNotNullOrUndefined } from "@utils";
import { filter } from "rxjs";
import {
  ConversationParticipantModelV2,
  FullUserProfileModel,
  MessageModel,
} from "types/api-v2";

@Component({
  selector: "app-search-message-card",
  templateUrl: "./search-message-card.component.html",
  styleUrls: ["./search-message-card.component.scss"],
})
export class SearchMessageCardComponent {
  @Input()
  message: MessageModel;
  @Input()
  sender: ConversationParticipantModelV2;
  @Input()
  highlightString: string;
  currentUser: FullUserProfileModel;

  constructor(private accountService: AccountService) {
    accountService.userAccount$.pipe(filter(isNotNullOrUndefined)).subscribe({
      next: (userAccount) => {
        this.currentUser = userAccount;
      },
    });
  }

  public getDurationText(durationInSeconds: number) {
    const duration = Number(durationInSeconds);

    if (duration < 60) {
      return "Less than a min";
    }

    const durationMinutesRounded = Math.round(duration / 60);
    return `${durationMinutesRounded} min`;
  }
}
