import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { SharedService } from "../../core";

export interface CeloUserCardCompanyInput {
  id: string;
}

@Component({
  selector: "app-celo-user-card",
  templateUrl: "./celo-user-card.component.html",
  styleUrls: ["./celo-user-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CeloUserCardComponent implements OnInit, OnChanges {
  @Input() userId: string;
  @Input() title: string;
  @Input() firstName = "";
  @Input() lastName = "";
  @Input() profilePic: string;
  @Input() position: string;
  @Input() department: string;
  @Input() showPosition = true;
  @Input() showDepartment = true;
  @Input() showCompany = true;
  @Input() showAllWorkspaces: boolean;
  @Input() contact: any;
  @Input() workspaces: any[];
  @Input() canRemove = false;
  @Input() isAdmin = false;
  @Output() remove = new EventEmitter();
  @Input() companyInFocus: CeloUserCardCompanyInput;
  @Input() companyInFocusId: string;
  @Input() profileId: string;
  @Input() replaceMyName: string;
  @Input() hideWorkspace: boolean;
  @Input() hideProfession = true;
  @Input() profession: string;
  @Input() professionVerified = false;
  @Input() replaceWorkspace: string;
  @Input() doNotDisturbToUtc: string;
  @Input() showBadge: boolean;
  @Input() identityVerificationStatus: string;
  @Input() onlyShowPosition: string;
  @Input() subtext: string;
  @Input() isWorkspaceVerified = false;

  focusedCompany: any;

  constructor(private sharedService: SharedService) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  ngOnChanges() {
    if (this.contact && this.companyInFocus) {
      this.focusedCompany = this.sharedService.getCompanyById(
        this.companyInFocus.id,
        this.contact
      );
    }
  }
}
