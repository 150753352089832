import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  UserSelectionList,
  UserSelectionListItem,
} from "../basic-user-selection-list/basic-user-selection-list.component";
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from "@angular/material/checkbox";

@Component({
  selector: "app-basic-user-selection-list-group",
  templateUrl: "./basic-user-selection-list-group.component.html",
  styleUrls: ["./basic-user-selection-list-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } as MatCheckboxDefaultOptions}
  ]
})
export class BasicUserSelectionListGroupComponent implements OnChanges {
  @Input() public label: string | null = null;
  @Input() public users: UserSelectionList = [];
  @Input() public selectedLabelText: string | null = "Added";
  @Input() public multiple: boolean = true;
  @Input() public hideLabels: boolean = false;

  @Output() public usersChange: EventEmitter<UserSelectionList> =
    new EventEmitter();

  @Output() public user: EventEmitter<UserSelectionListItem> =
    new EventEmitter();

  public isChecked: boolean = false;
  public isDisabled: boolean = false;
  public isIndeterminate: boolean = false;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["users"]) {
      this.updateState();
    }
  }

  private updateState() {
    const disabledCount = this.getDisabledCount();
    const selectionCount = this.getSelectionCount();
    const maxSelectionCount = this.users.length - disabledCount;
    this.isChecked = this.getSelectionCount() === maxSelectionCount;
    this.isDisabled = this.users.every((user) => user.isDisabled);
    this.isIndeterminate =
      selectionCount > 0 && selectionCount < maxSelectionCount;
  }

  private getDisabledCount(): number {
    return this.users.filter((user) => user.isDisabled).length;
  }

  private getSelectionCount(): number {
    return this.users.filter((user) => user.isSelected && !user.isDisabled)
      .length;
  }

  public handleClick(pointerEvent: PointerEvent) {
    const isSelected = !this.isChecked;
    const users = this.users.map((u) => {
      if (u.isDisabled) return { ...u };
      return { ...u, isSelected };
    });
    this.usersChange.emit(users);
  }

  public handleUsersChange(users: UserSelectionList) {
    this.usersChange.emit(users);
  }

  public handleUser(user: UserSelectionListItem) {
    this.user.emit(user);
  }
}
