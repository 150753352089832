import { Component, OnInit, Input } from "@angular/core";
// import { PatientDataModel } from 'app/codegen-angular-2/model/PatientDataModel';
// import { PatientConsentModel } from 'app/codegen-angular-2/model/PatientConsentModel';
// import {ViewMyProfileModel} from '../../../codegen-angular-2';

@Component({
  selector: "app-consent-info",
  templateUrl: "./consent-info.component.html",
  styleUrls: ["./consent-info.component.scss"],
})
export class ConsentInfoComponent implements OnInit {
  @Input() consent: any;
  @Input() userAccount: any;
  canEdit = false;
  consentCopy: any;

  constructor() {}

  ngOnInit() {
    // this.consentCopy = JSON.parse(JSON.stringify(this.consent));
    // this.canEdit = (this.userAccount.userId === this.consent.creator.userId)
    this.canEdit = false;
  }

  save() {}

  cancel() {
    this.consent = JSON.parse(JSON.stringify(this.consentCopy));
  }
}
