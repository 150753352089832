import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConversationService, SnackbarService } from '@modules/core';
import { AlertComponent } from '@modules/core/components';
import { ProfileService } from '@modules/profile/profile.service';
import { BroadcastRecipient, BroadcastStatus, TokenPagedResponse } from '@types';
import { BehaviorSubject, Observable, Subject, concat, distinctUntilChanged, map, of, switchMap } from 'rxjs';
import { BroadcastService } from '../broadcast.service';

export enum BroadcastStatusFilter {
  Sent = 'Sent',
  Delivered = 'Delivered',
  Seen = 'Seen'
}

export const broadcastStatusFilterToBroadcastStatusMap: {
  [K in BroadcastStatusFilter]: BroadcastStatus
} = {
  [BroadcastStatusFilter.Sent]: BroadcastStatus.Sent,
  [BroadcastStatusFilter.Delivered]: BroadcastStatus.Delivered,
  [BroadcastStatusFilter.Seen]: BroadcastStatus.Read
}

type FetchParamsMode = 'append' | 'replace'

type FetchParams = Parameters<BroadcastService['getRecipients']>[0] & {
  mode: FetchParamsMode;
}

enum BroadcastStatusComponentViewState {
  Loading = 'Loading',
  FilterResults = 'FilterResults',
  SearchFocused = 'SearchFocused',
  SearchResults = 'SearchResults',
}

interface BroadcastStatusComponentState {
  recipients?: BroadcastRecipient[] | null;
  paginationToken?: string | null;
  isLoadingMore?: boolean;
  previousViewState?: BroadcastStatusComponentViewState;
  viewState: BroadcastStatusComponentViewState;
  isSearchSubmitted?: boolean | null;
}

enum BroadcastStatusComponentActionType {
  LoadData,
  LoadDataSuccess
}

interface BroadcastStatusComponentLoadDataAction {
  type: BroadcastStatusComponentActionType.LoadData;
  isLoadingMore?: boolean;
  isSearching?: boolean;
}

interface BroadcastStatusComponentLoadDataSuccessAction {
  type: BroadcastStatusComponentActionType.LoadDataSuccess;
  response: TokenPagedResponse<BroadcastRecipient>;
  fetchParams: FetchParams;
}

type BroadcastStatusComponentAction = BroadcastStatusComponentLoadDataAction | BroadcastStatusComponentLoadDataSuccessAction;

@Component({
  selector: 'app-broadcast-status',
  templateUrl: './broadcast-status.component.html',
  styleUrls: ['./broadcast-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BroadcastStatusComponent implements OnChanges, OnInit {
  @Input() public broadcastId: string;
  @Input() public minSearchLength: number = 3;

  @Output() public back = new EventEmitter();

  private loadDataSubject = new Subject<FetchParams>();
  private stateSubject = new BehaviorSubject<BroadcastStatusComponentState>({ viewState: BroadcastStatusComponentViewState.Loading });

  public viewState$ = this.stateSubject.pipe(map(state => state.viewState), distinctUntilChanged());
  public recipients$ = this.stateSubject.pipe(map(state => state.recipients));
  public hasMore$ = this.stateSubject.pipe(map(state => state.paginationToken != null), distinctUntilChanged());
  public isLoadingMore$ = this.stateSubject.pipe(map(state => state.isLoadingMore ?? false), distinctUntilChanged());
  public isSearchSubmitted$ = this.stateSubject.pipe(map(state => state.isSearchSubmitted ?? false), distinctUntilChanged());
  public isFiltersEnabled$ = this.stateSubject.pipe(map(state => {
    if (state.viewState === BroadcastStatusComponentViewState.SearchFocused) return false;
    if (state.viewState === BroadcastStatusComponentViewState.SearchResults) return false;
    if (state.viewState === BroadcastStatusComponentViewState.Loading && state.isSearchSubmitted) return false;
    return true;
  }), distinctUntilChanged());

  public searchForm = this.formBuilder.nonNullable.group({
    search: this.formBuilder.control('', {
      validators: [Validators.required, Validators.minLength(this.minSearchLength)]
    })
  });

  public filterForm = this.formBuilder.nonNullable.group({
    status: this.formBuilder.control<BroadcastStatusFilter>(BroadcastStatusFilter.Delivered)
  })

  public statuses: BroadcastStatusFilter[] = [
    BroadcastStatusFilter.Sent,
    BroadcastStatusFilter.Delivered,
    BroadcastStatusFilter.Seen
  ];

  public ViewState = BroadcastStatusComponentViewState;

  public filterEmptyStateMessages: Record<BroadcastStatusFilter, string> = {
    Sent: 'Recipients will appear here once the broadcast message has been sent to them.',
    Delivered: 'Recipients will appear here once the broadcast message has been delivered to them.',
    Seen: 'Recipients will appear here once the broadcast message has been seen by them.'
  }

  public constructor(
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private broadcastService: BroadcastService,
    private conversationService: ConversationService,
    private matDialog: MatDialog,
    private profileService: ProfileService
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes.broadcastId?.currentValue === 'string') {
      this.reset(true);
    }
  }

  public ngOnInit(): void {
    this.searchForm.valueChanges.subscribe({
      next: ({ search }) => {
        if (search || this.searchForm.pristine) return;
        this.reset();
      }
    });

    this.filterForm.valueChanges.subscribe({
      next: () => {
        this.loadRecipients();
      }
    });

    this.loadDataSubject.pipe(switchMap((fetchParams) => {
      let actions: Observable<BroadcastStatusComponentAction>[] = [
        of({
          type: BroadcastStatusComponentActionType.LoadData,
          isLoadingMore: fetchParams.mode === 'append',
          isSearching: fetchParams.search != null
        }),
        this.broadcastService.getRecipients(fetchParams).pipe(map((response) => ({
          type: BroadcastStatusComponentActionType.LoadDataSuccess,
          response,
          fetchParams
        })))
      ];
      return concat<BroadcastStatusComponentAction[]>(...actions)
    }))
      .subscribe({
        next: (action) => {
          switch (action.type) {
            case BroadcastStatusComponentActionType.LoadData:
              this.handleLoadDataAction(action);
              break;
            case BroadcastStatusComponentActionType.LoadDataSuccess:
              this.handleLoadDataSuccessAction(action);
              break;
            default:
              action satisfies never;
              throw new Error("Non-exhaustive switch statement");
          }
        }
      });

    this.loadRecipients();
  }

  private handleLoadDataAction(action: BroadcastStatusComponentLoadDataAction) {
    const state = this.stateSubject.value;
    this.stateSubject.next({
      ...state,
      viewState: action.isLoadingMore ? state.viewState : BroadcastStatusComponentViewState.Loading,
      isLoadingMore: action.isLoadingMore,
      isSearchSubmitted: action.isSearching
    });
  }

  private handleLoadDataSuccessAction(action: BroadcastStatusComponentLoadDataSuccessAction) {
    const { response, fetchParams: { mode, search } } = action;
    const state = this.stateSubject.value;

    let recipients: BroadcastRecipient[];
    switch (mode) {
      case 'append':
        recipients = [...(state.recipients ?? []), ...response.data];
        break;
      case 'replace':
        recipients = response.data;
        break;
      default:
        mode satisfies never;
        throw new Error("Non-exhaustive switch statement");
    }

    const isSearchResults = search != null;

    let viewState = BroadcastStatusComponentViewState.FilterResults;
    if (isSearchResults) {
      viewState = BroadcastStatusComponentViewState.SearchResults;
    } else if (state.viewState === BroadcastStatusComponentViewState.SearchFocused) {
      viewState = BroadcastStatusComponentViewState.SearchFocused
    }

    this.stateSubject.next({
      recipients,
      paginationToken: response.token,
      viewState
    });
  }

  private loadRecipients(paginationToken?: string) {
    const filter = this.filterForm.value?.status;
    const status = filter ? broadcastStatusFilterToBroadcastStatusMap[filter] : null;
    const search = this.searchForm.value?.search;

    const fetchParams: FetchParams = {
      mode: paginationToken ? 'append' : 'replace',
      id: this.broadcastId,
      token: paginationToken
    };

    if (search) {
      fetchParams.search = search;
    } else {
      fetchParams.status = status;
    }

    this.loadDataSubject.next(fetchParams);
  }

  public onSearchFocus() {
    const state = this.stateSubject.value;
    if (state.viewState === BroadcastStatusComponentViewState.SearchResults) return;

    this.stateSubject.next({
      ...state,
      previousViewState: state.viewState,
      viewState: BroadcastStatusComponentViewState.SearchFocused
    });
  }

  public onSearchBlur() {
    const state = this.stateSubject.value;

    if (
      state.viewState === BroadcastStatusComponentViewState.SearchResults ||
      state.viewState == BroadcastStatusComponentViewState.Loading
    ) {
      return;
    }

    this.stateSubject.next({
      ...state,
      previousViewState: null,
      viewState: state.previousViewState ?? BroadcastStatusComponentViewState.FilterResults
    });
  }

  public submit(): void {
    const state = this.stateSubject.value;
    this.stateSubject.next({
      ...state,
      isSearchSubmitted: true
    })

    if (!this.searchForm.valid) {
      this.snackbarService.show(`Please enter at least ${this.minSearchLength} characters to begin your search`);
      return;
    }

    this.loadRecipients();
  }

  public reset(isForced: boolean = false) {
    const state = this.stateSubject.value;
    this.stateSubject.next({
      ...state,
      isSearchSubmitted: false
    })

    this.searchForm.reset({
      search: ''
    }, {
      emitEvent: false
    });

    if (
      !isForced &&
      state.viewState !== BroadcastStatusComponentViewState.SearchResults &&
      state.viewState !== BroadcastStatusComponentViewState.Loading
    ) {
      return;
    }

    this.filterForm.reset({
      status: BroadcastStatusFilter.Delivered
    })
    this.loadRecipients();
  }

  public refresh() {
    this.loadRecipients();
  }

  public loadMore() {
    this.loadRecipients(this.stateSubject.value.paginationToken);
  }

  public handleSecureMessage(recipient: BroadcastRecipient) {
    this.conversationService
      .createAndNavigateToChat(recipient.user.id)
      .subscribe({
        error: () => AlertComponent.openErrorDialog(this.matDialog),
      });
  }

  public handleViewProfile(recipient: BroadcastRecipient) {
    this.profileService.openProfile(recipient.user.id);
  }
}
