import { Component, Input } from "@angular/core";

@Component({
  selector: "app-voip-avatar",
  templateUrl: "./voip-avatar.component.html",
  styleUrls: ["./voip-avatar.component.scss"],
})
export class VoipAvatarComponent {
  @Input() public userId: string;
  @Input() public name: string;

  @Input() public isVideoOn: boolean;
  @Input() public isMuted: boolean;
  @Input() public isLastActiveSpeaker: boolean;
  @Input() public isYou: boolean;

  @Input() public isWorkspaceVerified: boolean | null = null;
  @Input() public isIdentityVerified: boolean | null = null;
  @Input() public isProfessionVerified: boolean | null = null;

  @Input() public disableDetails: boolean | undefined = null;
}
