import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { LibraryService } from "../library.service";
import { LibraryLightboxLibraryItemContent } from "../library-lightbox/library-lightbox.component";

@Directive()
export abstract class LibraryItemComponent implements OnInit, OnDestroy {
  // todo: remove This class. Should use composition with angular not inheritence
  @Input() libItem;
  @Input() public ignoreStateChange: boolean = false;
  @Output() selected = new EventEmitter<boolean>();
  private selectSub = null;
  canSelect = false;

  constructor(protected libraryService: LibraryService) {}

  ngOnInit() {
    this.selectSub = this.libraryService.canSelectEmitted$.subscribe(
      (canSelect) => {
        this.canSelect = canSelect;
        if (!this.canSelect) {
          this.libItem.selected = false;
          // this.selected.emit(this.libItem.selected);
        }
      }
    );
  }

  ngOnDestroy() {
    this.selectSub = null;
  }

  protected abstract getType(): string;

  onClick() {
    if (this.canSelect) {
      this.libItem.selected = !this.ignoreStateChange && !this.libItem.selected;
      this.selected.emit(this.libItem.selected);
    } else {
      this.libItem.payload["mediaType"] = this.getType();
      this.libItem.mediaType = 'libraryItem'
      this.libraryService.openLightbox(
        this.libItem as unknown as LibraryLightboxLibraryItemContent,
        true
      );
    }
  }
}
