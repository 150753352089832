<div class="body">
  <div class="title">
    <div>{{ title }}</div>
  </div>
  <div class="content bodycontent">
    <div>{{ content }}</div>
    <div *ngIf="payload">
      <div *ngFor="let item of payload" class="appear_1">
        <div *ngIf="item.type === 'verifyIdentityInfo'">
          <app-verify-identity-info
            [message]="item.message"
          ></app-verify-identity-info>
        </div>
        <div *ngIf="item.type === 'downloadMobileAppInfo'">
          <app-verify-identity-info
            [message]="item.message"
          ></app-verify-identity-info>
        </div>
        <div *ngIf="item.type === 'badgeInfo'" >
          <app-badge-info
            [ownProfile]="item.ownProfile"
            [userId]="item.userId"
            [name]="item.name"
            [name_verified_as]="item.name_verified_as"
            [title]="item.title"
            [workspaces]="item.workspaces"
            [identityVerificationStatus]="item.identityVerificationStatus"
            [profession]="item.profession"
            [hasImage]="item.hasImage"
          ></app-badge-info>
        </div>
        <div *ngIf="item.type === 'patientData'" class="pt-3 pb-3">
          <app-edit-patient
            [patient]="item.data"
            [canSave]="false"
            [editMode]="false"
            [showConsent]="true"
            [consent]="item.consent"
            [alignment]="'horizontal'"
          ></app-edit-patient>
        </div>
        <div
          *ngIf="item.type === 'text'"
          class="subtext celo-text-14 celo-grey"
        >
          <p>{{ item.data }}</p>
        </div>
        <div *ngIf="item.type === 'innerHTML'" class="subtext celo-text-14">
          <p [innerHtml]="item.data"></p>
        </div>
        <div *ngIf="item.type === 'editField'" class="subtext">
          <form #form class="pt-3 celo-input-outline">
            <div>
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>{{ item.data.fieldName }}</mat-label>
                <input
                  autocomplete="off"
                  matInput
                  name="Field"
                  #fieldName
                  required
                  [(ngModel)]="item.data.value"
                  (ngModelChange)="
                    editFieldChange(item.data); result_type = item.type
                  "
                  [attr.maxlength]="
                    item.data.maxlength ? item.data.maxlength : null
                  "
                />
              </mat-form-field>
            </div>
          </form>
        </div>
        <div *ngIf="item.type === 'selectField'" class="subtext">
          <form #form class="pt-3 celo-input-outline">
            <div>
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Title (Optional)</mat-label>
                <mat-select
                  name="title"
                  [(ngModel)]="item.data.value"
                  (ngModelChange)="
                    editFieldChange(item.data); result_type = item.type
                  "
                >
                  <mat-option
                    value="{{ tit.value }}"
                    *ngFor="let tit of item.options"
                    >{{ tit.display }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </form>
        </div>
        <div *ngIf="item.type === 'addCompany'" class="company_details">
          <app-add-company-details
            [company]="item.data"
            [workspace]="item.data"
            [workspaceId]="item.data.companyId"
            [department]="item.data.departmentId"
            [position]="item.data.position"
            [email]="item.data.email"
            [companyReadonly]="true"
            (validity)="company_details_valid = $event"
            (dataChange)="
              company_details = $event;
              result_data = $event;
              result_type = 'save'
            "
          ></app-add-company-details>
          <div>
            <button
              class="celo-danger-button celo-hoverable"
              [mat-dialog-close]="{
                type: 'delete',
                payload: item.data.companyId,
                workspace: item.data
              }"
              mat-button
            >
              Leave workspace
            </button>
          </div>
        </div>
        <div *ngIf="item.type === 'removedWorkspace'" class="workspace_removed">
          <img src="../../../assets/empty_states/workspace-remove.svg" alt="" />
          <div class="title">Your workspace has been removed</div>
          <div class="subtitle">
            You can still log in to your Celo account using the following email
            address(s) and your existing password
          </div>
          <div *ngIf="item.emails" class="emails">
            <div *ngFor="let email of item.emails">
              <div class="email">{{ email }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="item.type === 'celoSnackbar'" class="workspace_removed">
          <div class="title-line">
            <div *ngIf="item.icon" class="icon">
              <mat-icon [svgIcon]="item.icon"></mat-icon>
            </div>
            <span *ngIf="item.title" class="title">
              {{ item.title }}
            </span>
          </div>
          <div
            *ngIf="item.text"
            class="subtext celo-text-14 celo-grey celo-text-centred"
          >
            <p>{{ item.text }}</p>
          </div>
        </div>
        <div *ngIf="item.type === 'email_invite'" class="workspace_removed">
          <app-emailer
            [type]="item.invite_type"
            [link]="item.link"
            [id]="item.id"
            (completed)="close()"
            [payload]="item.payload"
            [workspace_name]="item.workspace_name"
          ></app-emailer>
        </div>
        <div *ngIf="item.type === 'updateEmail'" class="vertical">
          <div>&nbsp;</div>
          <button
            [disabled]="disabled"
            [mat-dialog-close]="{ type: 'update_and_leave' }"
            mat-button
            class="celo-primary-button uppercase"
          >
            update and leave
          </button>
          <button
            [disabled]="disabled"
            [mat-dialog-close]="{ type: 'leave' }"
            mat-button
            class="celo-secondary-button uppercase"
          >
            leave without updating
          </button>
          <button
            [mat-dialog-close]="false"
            mat-button
            class="celo-secondary-button uppercase"
          >
            cancel
          </button>
        </div>
        <div *ngIf="item.type === 'addProfession'" class="company_details">
          <app-edit-profession
            [status]="item.status"
            [myCategory]="item.myCategory"
            [myProfession]="item.myProfession"
            [professionReadonly]="item.status === 'Pending'"
            (dataChange)="result_data = $event"
            (validity)="disabled = !$event"
            (confirmButtonTextChange)="confirmButtonText = $event"
            (verifyProfession)="verifyProfession($event)"
          ></app-edit-profession>
        </div>
        <div *ngIf="item.type === 'editProfession'" class="company_details">
          <app-edit-profession
            [status]="item.status"
            [editMode]="true"
            [myCategory]="item.myCategory"
            [myProfession]="item.myProfession"
            [professionReadonly]="true"
            (dataChange)="result_data = $event"
            (validity)="disabled = !$event"
            (verifyProfession)="verifyProfession($event)"
            (removeProfession)="removeProfession($event)"
          ></app-edit-profession>
        </div>
        <div *ngIf="item.type === 'verifyProfession'" class="company_details">
          <app-verify-profession
            [countryCode]="item.countryCode"
            [myCategory]="item.myCategory"
            [myProfession]="item.myProfession"
            (dataChange)="result_data = $event"
            (validity)="disabled = !$event"
            (verifyLater)="verifyLater($event)"
          ></app-verify-profession>
        </div>
        <div
          *ngIf="item.type === 'verifyProfessionSuccess'"
          class="company_details"
        >
          <app-verify-profession-success></app-verify-profession-success>
        </div>
        <div *ngIf="item.type === 'registerWorkspace'" class="company_details">
          <app-register-company
            [workEmail]="item.email"
            (validity)="disabled = !$event"
            (dataChange)="result_data = $event"
          ></app-register-company>
        </div>
        <div
          *ngIf="item.type === 'registerWorkspaceSuccess'"
          class="company_details"
        >
          <app-register-company-success
            [hideLogo]="true"
          ></app-register-company-success>
        </div>
        <div *ngIf="item.type === 'shareMyProfile'">
          <app-profile-share></app-profile-share>
        </div>
        <div *ngIf="item.type === 'home_cta'" class="company_details cta">
          <div class="title">{{ item.data.title }}</div>
          <div
            *ngFor="let step of item.data.steps; let index = index"
            class="step celo-hoverable-dark"
          >
            <img [src]="step.image" alt="" />
            <div class="texts">
              <div class="subtitle">{{ step.title }}</div>
              <div class="subdescription">{{ step.description }}</div>
              <button
                *ngIf="step.button"
                [id]="step.id"
                [mat-dialog-close]="{ type: step.id }"
                (click)="(step.action)"
                mat-button
                class="celo-primary-button uppercase"
              >
                {{ step.button }}
              </button>
            </div>
          </div>
        </div>
        <div
          *ngIf="item.type === 'integrations'"
          class="mt-4 mb-3 celo-input-outline"
        >
          <div class="subtitle">Select where you want to export the photo</div>
          <app-integrations-picker
            (selectionChange)="
              result_data = $event;
              result_type = 'integrations';
              disabled =
                !result_data.integrationId ||
                !result_data.services ||
                (result_data.services.length != 0 && !result_data.serviceCode)
            "
            [integrations]="item['integrations']"
            [exportableTo]="item['exportableTo']"
            [consent]="item.consent"
          ></app-integrations-picker>
        </div>
      </div>
    </div>
  </div>
  <div
    class="action"
    [ngClass]="{
      vertical: alignment === 'vertical',
      horizontal: alignment !== 'vertical'
    }"
  >
    <button
      *ngIf="!confirmOnly && cancelButtonText"
      [mat-dialog-close]="false"
      mat-button
      class="celo-secondary-button uppercase"
    >
      {{ cancelButtonText }}
    </button>
    <button
      *ngIf="confirmButtonText"
      [disabled]="disabled"
      (click)="submit()"
      mat-button
      class="celo-primary-button uppercase"
    >
      {{ confirmButtonText }}
    </button>
  </div>
  <div *ngIf="!hideCloseButton" class="close">
    <button mat-icon-button [mat-dialog-close]="false">
      <i class="material-icons-round">close</i>
    </button>
  </div>
</div>
