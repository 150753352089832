import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { AnalyticsService } from "../../core/old/analytics.service";
import { UserSelectionList } from "../../shared/basic-user-selection-list/basic-user-selection-list.component";
import {
  SelectionList,
  BasicUserPickerService,
  UserSelectionListId,
} from "../basic-user-picker.service";
import { NetworkUserPickerService } from "./../network-user-picker.service";

@Component({
  selector: "app-user-picker-grouped-selection-lists",
  templateUrl: "./user-picker-grouped-selection-lists.component.html",
  styleUrls: ["./user-picker-grouped-selection-lists.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPickerGroupedSelectionListsComponent implements OnInit {
  @Input() public stickyGroupHeaders: boolean = false;
  @Input() public groups: SelectionList[] = [];
  @Input() public maxItems: number | null = null;
  @Input() public multiple: boolean = true;
  @Input() public hideLabels: boolean = false;
  @Input() public isSearchResults: boolean = false;

  public UserSelectionListId = UserSelectionListId;

  public hideTeams$: Observable<boolean> = of(false);
  public initiallyExpandedWorkspaceIds$: Observable<Set<string>> | null = null;
  public isNetworkUserPicker: boolean = false;

  public constructor(
    @Optional()
    private networkUserPickerService: NetworkUserPickerService | null,
    private userPickerService: BasicUserPickerService,
    private analyticsService: AnalyticsService
  ) {}

  public ngOnInit() {
    this.hideTeams$ = this.userPickerService.hideTeams$;
    this.initiallyExpandedWorkspaceIds$ =
      this.networkUserPickerService?.initiallyExpandedWorkspaceIds$.pipe(
        map((s) => new Set(s))
      ) ?? null;
    this.isNetworkUserPicker = this.networkUserPickerService !== null;
  }

  public handleChange(users: UserSelectionList) {
    this.userPickerService.updateUsers(users);
    if (this.isSearchResults) {
      this.analyticsService.buttonClickEvent("user_card", {
        flow: "message",
        card_type: "searched_team",
      });
    }
  }

  public groupTrackBy(index: number, group: SelectionList) {
    return group.id;
  }
}
