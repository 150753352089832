import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrUndefined } from "utils";

@Pipe({
  name: "trim",
})
export class TrimPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string | null | undefined {
    if (isNullOrUndefined(value)) return value;
    if (typeof value !== "string")
      throw new Error("trim pipe input must be a string");

    return value.trim();
  }
}
