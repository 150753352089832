<div class="card-container">
  <ng-content></ng-content>

  <ng-container *ngIf="!disabled">
    <div
      [matMenuTriggerFor]="menu"
      class="menu-button"
      (click)="onParticipantClick($event)"
      [style.left.px]="menuX"
      [style.top.px]="menuY"
    ></div>
    <mat-menu #menu="matMenu" overlapTrigger="true">
      <div class="menu-title">
        {{ participant.firstName + " " + participant.lastName }}
      </div>

      <mat-divider></mat-divider>

      <button mat-menu-item (click)="onSecureMessage()">Secure Message</button>
      <button mat-menu-item (click)="onViewProfileClick()">View Profile</button>
      <button
        *ngIf="isCurrentUserAdmin"
        mat-menu-item
        (click)="isAdmin ? onRemoveAdminClick() : onMakeAdminClick()"
      >
        {{ isAdmin ? "Remove Admin" : "Make Admin" }}
      </button>
      <button
        *ngIf="isCurrentUserAdmin"
        mat-menu-item
        (click)="onRemovePartipantClick()"
      >
        {{ removeText ?? "Remove Participant" }}
      </button>
    </mat-menu>
  </ng-container>
</div>
