import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  Optional,
  Provider,
  SkipSelf,
} from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ApplicationinsightsAngularpluginErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import ZoomVideo from "@zoom/videosdk";
import { ProfileModule } from "./../profile/profile.module";
import { SharedModule } from "./../shared/shared.module";
import { authInitializerFactory } from "./auth.initializer";
import {
  AlertComponent,
  LinkActionComponent,
  OldAlertComponent,
  SnackbarComponent,
} from "./components";
import { ActionUserInvitationDialogComponent } from "./components/action-user-invitation-dialog/action-user-invitation-dialog.component";
import { InvitationAlreadyUsedDialogComponent } from "./components/invitation-already-used-dialog/invitation-already-used-dialog.component";
import { InvitationInactiveDialogComponent } from "./components/invitation-inactive-dialog/invitation-inactive-dialog.component";
import { InvitationInvalidDialogComponent } from "./components/invitation-invalid-dialog/invitation-invalid-dialog.component";
import {
  AuthInterceptor,
  FeatureFlagErrorInterceptor,
  RetryInterceptor,
} from "./interceptors";
import {
  AlertService,
  AnalyticsService,
  ConService,
  ConversationService,
  DirectoryService,
  FileUploadService,
  LinkService,
  MemoryService,
  MessageService,
  MiscService,
  PatientService,
  RoutingService,
  SharedService,
  UserAccountService,
} from "./old";
import {
  AVATAR_SERVICE,
  AccountService,
  ApiService,
  AuthService,
  AvatarService,
  CONVERSATIONS_SERVICE,
  CompaniesService,
  ContactsService,
  ConversationsService,
  IntegrationService,
  MediaService,
  SelectionService,
  SettingsService,
  SnackbarService,
  TeamsService,
  USER_SERVICE,
  UserService,
  UsersService,
  WorkspacesService,
} from "./services";
import { ApplicationInsightsService } from "./services/application-insights.service";

const OLD_SERVICES: Provider[] = [
  AlertService,
  ConService,
  ConversationService,
  DirectoryService,
  MiscService,
  PatientService,
  SharedService,
  UserAccountService,
  LinkService,
  AnalyticsService,
  MemoryService,
  MessageService,
  FileUploadService,
  RoutingService,
];

const OLD_COMPONENTS = [OldAlertComponent, LinkActionComponent];

const zoomInitializerFactory = () => {
  console.log("isSharedArrayBufferEnabled", {
    "typeof SharedArrayBuffer": typeof SharedArrayBuffer,
    crossOriginIsolated,
  });
  return () => ZoomVideo.preloadDependentAssets();
};

@NgModule({
  declarations: [
    SnackbarComponent,
    AlertComponent,
    ...OLD_COMPONENTS,
    InvitationAlreadyUsedDialogComponent,
    InvitationInvalidDialogComponent,
    InvitationInactiveDialogComponent,
    ActionUserInvitationDialogComponent,
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    ProfileModule,
  ],
  exports: [],
  providers: [
    ApiService,
    AuthService,
    SnackbarService,
    TeamsService,
    UserService,
    UsersService,
    WorkspacesService,
    AvatarService,
    CompaniesService,
    ContactsService,
    AccountService,
    ConversationsService,
    ApplicationInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    MediaService,
    IntegrationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FeatureFlagErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authInitializerFactory,
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: zoomInitializerFactory,
      multi: true,
    },
    {
      provide: USER_SERVICE,
      useClass: UserService,
    },
    {
      provide: CONVERSATIONS_SERVICE,
      useClass: ConversationsService,
    },
    {
      provide: AVATAR_SERVICE,
      useClass: AvatarService,
    },
    SettingsService,
    SelectionService,
    ...OLD_SERVICES,
  ],
})
export class CoreModule {
  // See: https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
  public constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        "CoreModule is already loaded. Import it in the AppModule only"
      );
    }
  }
}
