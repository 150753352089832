import { HttpClient } from "@angular/common/http";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { environment } from "@env";
import {
  ConService,
  ConversationService,
  UserAccountService,
} from "@modules/core";
import { SharedService } from "@modules/core/old/shared.service";
import { MediaType, MessageModel, PatientFileDetailsModel } from "@types";
import { retry, tap } from "rxjs/operators";
import { ConsentViewModel } from "../consents/consents.component";
import { DocumentsListItem } from "../documents/documents.component";
import {
  DocumentsService,
  ShareDocumentsOptions,
} from "../documents/documents.service";
import { MediaService, SharePhotosOptions } from "../media/media.service";

interface LibraryLightboxContent {
  /** Used for type narrowing. */
  mediaType: "message" | "consent" | "document" | "libraryItem";
}

/** Used by MessageCardComponent  */
export interface LibraryLightboxMessageContent extends LibraryLightboxContent {
  mediaType: "message";
  enableMediaNavigation?: boolean;
  conversationId: string;
  /** Note: this should be the message's marker. */
  messageId: string;
  payload: {
    /** Id of the media being shown. */
    id: string;
    mediaType: "photo" | "video" | "document";
  };
}

/** Used by ConsentsComponent */
export interface LibraryLightboxConsentContent
  extends ConsentViewModel,
    LibraryLightboxContent {
  mediaType: "consent";
}

/** Used by DocumentsComponent */
export interface LibraryLightboxDocumentContent
  extends DocumentsListItem,
    LibraryLightboxContent {
  mediaType: "document";
}

/** Provided by non-abstract extensions of LibraryItemComponent */
export interface LibraryLightboxLibraryItemContent
  extends LibraryLightboxContent {
  mediaType: "libraryItem";
  payload: {
    mediaType?: "consent" | "document" | MediaType;
  };
}

export type LibraryLightBoxDataContent =
  | LibraryLightboxMessageContent
  | LibraryLightboxConsentContent
  | LibraryLightboxDocumentContent
  | LibraryLightboxLibraryItemContent;

export interface LibraryLightboxData {
  content: LibraryLightBoxDataContent;
  isFromLibrary?: boolean;
  /** Whether controls to navigate to the next or previous message with a photo or video should be enabled.
   *
   * This should only be used when viewing messages in a conversation.
   */
  enableMediaNavigation?: boolean;
  conversationId?: string;
  conversationType?: string;
}

interface MediaListItem {
  payload: {
    id: string;
    type: MediaType;
    mediaType: MediaType;
  };
  next?: MediaListItem;
  prev?: MediaListItem;
}

@Component({
  selector: "app-library-lightbox",
  templateUrl: "./library-lightbox.component.html",
  styleUrls: ["./library-lightbox.component.scss"],
})
export class LibraryLightboxComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  public type: string;
  /**
   * Haven't been able to determine the type for this - it gets modified often and assigned various values/types.
   */
  public content: any;
  isFromLibrary: boolean;
  canDownload: boolean;
  showInfo = false;
  isLoading = true;
  isPreviewLoading = true;
  consentSource: any;
  documentSource: any;
  userAccount: any;
  document: PatientFileDetailsModel | null = null;
  downloadUrl: string;
  downloadFileName = "";
  photoLoader: any;
  photoInfoRefreshCount = 0;
  detailsChangeSubscriber: any;
  isFromConversation: boolean;
  sub: any;
  mediaList: MediaListItem[] = [];
  index = 0;
  indexes = [];
  refreshCount = 0;
  documentSub: any;
  consentSub: any;
  timeout: NodeJS.Timeout;
  audio: any;
  videoPath: string;
  videoplayer: any;
  playing = false;
  conversationType: string;

  @ViewChild("videoPlayer")
  set mainVideoEl(el: ElementRef) {
    if (el) {
      this.videoplayer = el.nativeElement;
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LibraryLightboxData,
    private http: HttpClient,
    private ref: MatDialogRef<LibraryLightboxComponent>,
    public dialog: MatDialog,
    private mediaService: MediaService,
    private conService: ConService,
    private _changeDetectionRef: ChangeDetectorRef,
    private userAccountService: UserAccountService,
    private conversationService: ConversationService,
    private documentService: DocumentsService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.content = this.data.content;
    this.conversationType = this.data.conversationType;
    this.type = "photo";
    if (
      this.data.content.mediaType === "message" ||
      this.data.content.mediaType === "libraryItem"
    ) {
      this.setType(this.data.content.payload);
    }
    this.isFromLibrary = this.data.isFromLibrary;
    this.isFromConversation = this.data.enableMediaNavigation;

    if (this.type === "photo") {
      this.loadContextInConversation(() => {
        this.loadPhoto(this.content.payload.id);
      });
    } else if (this.type === "video") {
      this.loadContextInConversation(() => {
        this.loadVideo(this.content.payload.id);
      });
    } else if (this.type === "consent") {
      this.loadConsent();
      this.downloadUrl = this.addQueryParams(
        `${environment.celoApiEndpoint}/api/Consents/${this.content.payload.id}`
      );
    } else if (this.type === "document") {
      this.loadDocument();
      this.loadDocumentObject();
      this.downloadUrl = this.addQueryParams(
        `${environment.celoApiEndpoint}/api/PatientFiles/${this.content.payload.id}`
      );
    }
    const instance = this;
    this.userAccountService.getUserAccount(false, function (data) {
      instance.userAccount = data;
    });
    this.audio = new Audio();
  }

  ngOnDestroy() {
    // destroy audio here
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
    }
  }

  private loadDocumentObject() {
    const path = this.addQueryParams(
      `${environment.celoApiEndpoint}/api/PatientFiles/${this.content.payload.id}/Details`
    );
    this.isLoading = true;
    this.canDownload = false;
    this.http
      .get<PatientFileDetailsModel>(path)
      .pipe(
        retry(3),
        tap(() => (this.isLoading = false))
      )
      .subscribe(
        (document) => {
          this.document = document;
          this.allowDownload(1500);
          if (document.fileName) {
            this.downloadFileName = document.fileName;
          }
        },
        () => {}
      );
  }

  ngAfterViewChecked(): void {
    this._changeDetectionRef.detectChanges();
  }

  private loadDocument() {
    this.isLoading = true;
    if (!this.content.payload) {
      this.onClose();
    }
    const path = this.addQueryParams(
      `${environment.celoApiEndpoint}/api/PatientFiles/${this.content.payload.id}`
    );
    if (this.documentSub) {
      this.documentSub.unsubscribe();
    }
    this.documentSub = this.http
      .get<ArrayBuffer>(path, { responseType: "arraybuffer" as "json" })
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((ab) => {
        this.documentSource = {
          data: new Uint8Array(ab),
        };
        return this.documentSource;
      });
  }

  private loadConsent() {
    this.isLoading = true;
    this.canDownload = false;
    const path = `${environment.celoApiEndpoint}/api/Consents/${this.content.payload.id}`;
    if (this.consentSub) {
      this.consentSub.unsubscribe();
    }
    this.consentSub = this.http
      .get<ArrayBuffer>(path, { responseType: "arraybuffer" as "json" })
      .pipe(
        tap(() => {
          this.isLoading = false;
        })
      )
      .subscribe((ab) => {
        if (this.isFromLibrary) {
          this.allowDownload(1000);
        }
        return (this.consentSource = {
          data: new Uint8Array(ab),
        });
      });
  }

  private onDetailsUpdate() {
    this.detailsChangeSubscriber = this.conService.PhotoDetails$.subscribe(
      (photoDetails: any[]) => {
        let photoDetail: any;
        if (photoDetails[0]) {
          photoDetail = photoDetails[0];
        }
        if (!photoDetail) {
          return;
        }
        if (photoDetail.id == this.content.payload.id) {
          this.content.mediaDetails = photoDetail;
          this.content.payload = photoDetail;
          this.content.payload.loaded = true;
          this.photoInfoRefreshCount++;
        } else {
        }
      }
    );
  }

  private loadContextInConversation(callback: () => void) {
    if (
      this.data.content.mediaType === "message" &&
      this.data.enableMediaNavigation
    ) {
      this.isLoading = true;
      const mediaMessages = this.conversationService.messages.filter(
        (message) => message.type === "Photo" || message.mediaType === "Video"
      );
      this.content = this.setPivot(mediaMessages);
      this.setIndex();
    }

    return callback();
  }

  sortBySentTime(photos: any[]) {
    photos.sort((a: any, b: any) => {
      const adate = new Date(a.sentOnUtc);
      const bdate = new Date(b.sentOnUtc);
      if (adate < bdate) {
        return 1;
      }
      if (adate > bdate) {
        return -1;
      }
      return 0;
    });
  }

  setPivot(photos: MessageModel[]) {
    const photoCollection: MediaListItem[] = photos.map((p) => ({
      payload: {
        id: p.metadata.photoId,
        type: p.mediaType,
        mediaType: p.mediaType,
      },
    }));

    this.mediaList = [];
    for (let i = 0; i < photoCollection.length; i++) {
      const a = photoCollection[i];
      if (photoCollection[i + 1]) {
        const b = photoCollection[(i + 1) % photoCollection.length];
        a.next = b;
        b.prev = a;
      }
      this.mediaList.push(a);
    }

    for (let i = 0; i < this.mediaList.length; i++) {
      if (this.mediaList[i].payload.id == this.content.payload.id) {
        this.content.payload = this.mediaList[i];
        return this.mediaList[i];
      }
    }
  }

  setIndex() {
    this.indexes = [];
    for (let i = 0; i < this.mediaList.length; i++) {
      if (this.mediaList[i].payload.id == this.content.payload.id) {
        this.indexes.push(i + 1);
      }
    }
  }

  private loadPhoto(id) {
    this.canDownload = false;
    const path = this.addQueryParams(
      `${environment.celoApiEndpoint}/api/v2/media/${id}/Details`
    );
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.http.get<any>(path).subscribe((photo) => {
      this.content.mediaDetails = photo;
      this.content.payload = photo;
      this.content.payload.mediaType = MediaType.Photo;
      this.content.payload.loaded = true;
      this.photoInfoRefreshCount++; // to force ngonchanges() of app-media-info component

      if (this.detailsChangeSubscriber) {
        this.detailsChangeSubscriber.unsubscribe();
      }
      this.onDetailsUpdate();
      this.allowDownload(1000);
    });
    this.downloadUrl = this.addQueryParams(
      `${environment.celoApiEndpoint}/api/v2/media/${this.content.payload.id}`
    );
  }

  videoOnLoad() {}

  private loadVideo(id) {
    this.canDownload = false;
    this.videoPath = environment.celoApiEndpoint + "/api/v2/media/" + id;
    this.downloadUrl = this.addQueryParams(this.videoPath);

    const detailsPath = this.addQueryParams(
      `${environment.celoApiEndpoint}/api/v2/media/${id}/details`
    );
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.http.get<any>(detailsPath).subscribe((details) => {
      this.content.mediaDetails = details;
      this.content.payload = details;
      this.content.payload.mediaType = MediaType.Video;
      this.content.payload.loaded = true;
      this.photoInfoRefreshCount++; // to force ngonchanges() of app-media-info component

      if (this.detailsChangeSubscriber) {
        this.detailsChangeSubscriber.unsubscribe();
      }
      this.onDetailsUpdate();
    });
  }

  allowDownload(time?) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(
      () => {
        this.canDownload = true;
      },
      time ? time : 1000
    );
  }

  onMovePivot(next) {
    ++this.refreshCount;
    this.onMovePivotNow(next);
  }

  setType(content) {
    if (content.mediaType) {
      this.type = content.mediaType.toLowerCase();
    }
  }

  onMovePivotNow(next) {
    const pivot = next ? "next" : "prev";
    if (!this.content[pivot]) {
      return;
    }
    this.setType(this.content[pivot]["payload"]);
    if (
      this.content["payload"] &&
      this.content["payload"].id != this.content[pivot]["payload"].id
    ) {
      this.isLoading = true;
    }

    if (this.content[pivot]) {
      this.isLoading = true;
      this.isPreviewLoading = true;
      this.content = this.content[pivot];
      this.setType(this.content.payload);
      this.reload();
    }
    this.setIndex();
  }

  private reload() {
    this.videoPath = "";
    if (this.type === "consent") {
      this.loadConsent();
    } else if (this.type === "photo") {
      this.loadPhoto(this.content.payload.id);
    } else if (this.type === "video") {
      setTimeout(() => {
        this.loadVideo(this.content.payload.id);
      }, 1000);
    } else if (this.type === "document") {
      this.loadDocument();
      this.loadDocumentObject();
    }
  }

  @HostListener("window:keydown", ["$event"])
  public keyboardInput(event: KeyboardEvent) {
    if (event.shiftKey) {
      return;
    }
    if (event.code === "ArrowRight") {
      this.onMovePivot(true);
    } else if (event.code === "ArrowLeft") {
      this.onMovePivot(false);
    }
  }

  toggleInfo() {
    this.showInfo = !this.showInfo;
  }

  delete() {
    const i = this;
    if (this.type === "photo" || this.type === "video") {
      this.mediaService.deleteMedia(
        [this.content.payload],
        this.type,
        function (status) {
          if (status) {
            i.ref.close({
              success: status,
              refreshLibrary: true,
            });
          }
        }
      );
    } else if (this.type === "document") {
      this.documentService.deleteDocuments([this.document], function (status) {
        if (status) {
          i.ref.close({
            success: status,
            refreshLibrary: true,
          });
        }
      });
    }
  }

  downloadVideo() {}

  onAction(action: string) {
    if (action === "share") {
      if (!this.sharedService.isOnline()) {
        this.sharedService.noInternetSnackbar();
        return;
      }

      if (this.type === "photo" || this.type === "video") {
        this.shareMedia();
      } else if (this.type === "consent") {
      } else if (this.type === "document") {
        this.shareDocument();
      }
    } else if (action === "info") {
      this.toggleInfo();
    }
  }

  private shareDocument() {
    const options: ShareDocumentsOptions = {};

    if (this.data?.content.mediaType === "message") {
      options.fromConversationId = this.data.content.conversationId;
      options.fromMessageId = this.data.content.messageId;
    }

    this.documentService.share([this.document], options).subscribe((isDone) => {
      if (!isDone) return;
      this.ref.close();
    });
  }

  private shareMedia() {
    const options: SharePhotosOptions = {
      isFromLibrary: this.isFromLibrary,
      conversationType: this.conversationType,
    };

    if (this.data?.content.mediaType === "message") {
      options.fromConversationId = this.data.content.conversationId;
      options.fromMessageId = this.data.content.messageId;
    }

    this.mediaService
      .sharePhotos([this.content.payload], options)
      .subscribe((isDone) => {
        if (!isDone) return;
        this.ref.close();
      });
  }

  onClose() {
    this.ref.close();
  }

  /** Assumes that the given url does not already have any query params. */
  private addQueryParams(url: string) {
    if (this.data?.content?.mediaType !== "message") return url;
    const { conversationId, messageId } = this.data.content;
    return `${url}?conversationId=${conversationId}&messageId=${messageId}`;
  }
}
