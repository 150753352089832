<div class="container">
  <button mat-icon-button aria-label="Close" id="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <div matDialogTitle class="title">Edit details</div>

  <div matDialogContent>
    <app-role-details-form
      #form
      [teamId]="team.id"
      [name]="team.name"
      [description]="team.description"
      (saved)="handleSave($event)"
    ></app-role-details-form>
  </div>

  <div matDialogActions class="flex justify-end">
    <button class="celo-secondary-button uppercase" mat-button matDialogClose>
      Cancel
    </button>
    <button
      class="celo-primary-button uppercase"
      mat-button
      [disabled]="!form.isSaveable"
      (click)="form.save()"
    >
      Save
    </button>
  </div>
</div>
