import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AnalyticsService, AuthService, SharedService } from "@modules/core";
import { ProfileService } from "../profile.service";

@Component({
  selector: "app-profile-invitation",
  templateUrl: "./profile-invitation.component.html",
  styleUrls: ["./profile-invitation.component.scss"],
})
export class ProfileInvitationComponent implements OnInit, OnChanges {
  @Input() userId: string;
  loggedInuserId: string;
  @Input() code = "";
  @Input() invitation: any;
  @Output() action = new EventEmitter<any>();
  contact: any;
  loading: boolean;
  actioning: boolean;

  constructor(
    private sharedService: SharedService,
    private profileService: ProfileService,
    private analyticsService: AnalyticsService,
    private authService: AuthService,
    private ref: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.loggedInuserId = this.authService.getUserId();
  }

  ngOnChanges(): void {
    this.getContactInfo();
  }

  getContactInfo() {
    this.loading = true;
    const instance = this;
    this.sharedService.getContact(this.userId, function (contact) {
      if (!contact) {
        instance.ref.close();
        instance.sharedService.standardErrorToast();
      }
      instance.loading = false;
      instance.contact = contact;
    });
  }

  connect() {
    const instance = this;
    this.profileService.sendConnectionRequest(
      this.contact.userId,
      function (res) {
        if (res["connection"]) {
          instance.contact["connection"] = res["connection"];
        }
      }
    );
  }

  actionNow() {
    // this.action.emit();
    const instance = this;
    this.actioning = true;
    return this.sharedService.action(this.code, function (result) {
      instance.actioning = false;
      if (result) {
        instance.getContactInfo();
      }
    });
  }

  viewProfile(id) {
    this.ref.close();
    if (this.loggedInuserId != id) {
      this.profileService.openProfile(id);
      this.analyticsService.buttonClickEvent("view_profile_from_link");
      return;
    }
    this.sharedService.navigateToProfile();
  }
}
