import { Component, Input } from "@angular/core";
import { Team, TeamMember } from "./../../../../types/api-v2";

export interface TeamMemberWithDepartment extends TeamMember {
  departmentName?: string | null;
}

@Component({
  selector: "app-roles-members-list",
  templateUrl: "./roles-members-list.component.html",
  styleUrls: ["./roles-members-list.component.scss"],
})
export class RolesMembersListComponent {
  @Input() public members: TeamMemberWithDepartment[] = [];
  @Input() public team: Team | null = null;

  constructor() {}
}
