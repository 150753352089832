import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ErrorDetail, InvitationModel, InvitationType } from "@types";
import { isCeloErrorResponse, isHttpErrorResponse } from "@utils";
import {
  ErrorCode,
  ErrorDescriptor,
  getErrorDescriptor,
} from "utils/error-utils";
import { SharedService } from "../../old/shared.service";

export interface LinkActionData {
  code: string;
  invitationObj: InvitationModel;
}

@Component({
  selector: "app-link-action",
  templateUrl: "./link-action.component.html",
  styleUrls: ["./link-action.component.scss"],
})
export class LinkActionComponent implements OnInit {
  loading: boolean = false;
  userAccount: any;

  public joinWorkspaceErrorDescriptor: ErrorDescriptor | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LinkActionData,
    private ref: MatDialogRef<LinkActionComponent>,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    if (!this.data || !this.data.code) {
      return;
    }
  }

  action(invitationId: string) {
    this.loading = true;

    const invitation = this.data.invitationObj;

    this.sharedService.action(invitationId, (res) => {
      res["resultType"] = invitation.type;

      const isWorkspaceInvitation =
        invitation.type === InvitationType.UserSpecificWorkspaceJoin ||
        invitation.type === InvitationType.WorkspaceJoin;

      if (
        !res.errored ||
        !isWorkspaceInvitation ||
        !(isHttpErrorResponse(res.data) && isCeloErrorResponse(res.data.error))
      ) {
        this.ref.close(res);
        return;
      }

      const error = res.data.error;
      const shouldHandleError = error.errors.some(
        (e) =>
          e.code === ErrorCode.SinglePaidWorkspce ||
          e.code === ErrorCode.WorkspaceUserLimit
      );

      if (!shouldHandleError) {
        this.ref.close(res);
        return;
      }

      this.joinWorkspaceErrorDescriptor = getErrorDescriptor(error);
    });
  }

  viewWorkspace(id: string) {
    this.sharedService.navigateToDirectory(id);
    this.close();
  }

  viewConversation(id: string) {
    this.sharedService.navigateToConversation(id);
  }

  close() {
    this.ref.close();
  }
}
