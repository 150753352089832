import { createActionGroup, emptyProps, props } from "@ngrx/store";
import {
  Conversation,
  ConversationInvitationModel,
  ConversationParticipantModel,
  ConversationType,
  CreateMessageMediaModel,
  CreateMessageModel,
  CreateMessagePatientFileModel,
  ErrorResponse,
  FullUserProfileModel,
  Message,
  MessageModel,
  MessageUpdate,
  PatientDataModel,
  ResetInvitationResponseModel,
  VideoCallUpdate,
} from "@types";
import {
  ApiPagedResult,
  ClearUnreadConversationModelV3,
  ConversationModelV3,
  ConversationParticipantModelV3,
  ConversationSkeletonModelV3,
} from "types/api-v3";
import { WithUser } from "./../../../types/utils";

// TODO add network status changed event -> effect
export const ConversationsPageActions = createActionGroup({
  source: "Conversations Page",
  events: {
    "Clear Unread Badge": props<{
      conversationId: string;
    }>(),
    "Conversation Opened": props<{ conversationId: string }>(),
    "Load More Older Messages": emptyProps(),
    "Load More Newer Messages": emptyProps(),
    "Messages Scrolled To Top": emptyProps(),
    "Patient Updated": props<{
      conversationId: string;
      patientData: PatientDataModel;
    }>(),
    "Conversation Details Opened": props<{ conversationId: string }>(),
    "Invitation Details Opened": props<{ conversationId: string }>(),
    "Toggle Invitation Allow All": props<{
      conversationId: string;
    }>(),
    "Reset Invitation": props<{ conversationId: string }>(),

    "Inbox Opened": emptyProps(),
    "Inbox Load More": emptyProps(),
    "Inbox Search": props<{ query: string }>(),
    "Inbox Search Load More": emptyProps(),
    "Inbox Search Reset": emptyProps(),

    "External Inbox Search": props<{ query: string }>(),
    "External Inbox Opened": emptyProps(),
    "External Inbox Load More": emptyProps(),
    "External Inbox Search Load More": emptyProps(),
    "External Inbox Search Reset": emptyProps(),

    "Roles Inbox Search": props<{ query: string }>(),
    "Roles Inbox Opened": props<{ teamId: string }>(),
    "Roles Inbox Load More": emptyProps(),
    "Roles Inbox Search Load More": emptyProps(),
    "Roles Inbox Search Reset": emptyProps(),

    "Pin Conversation": props<{ conversationId: string }>(),
    "Unpin Conversation": props<{ conversationId: string }>(),
    "Edit Conversation Photo": props<{ conversationId: string; file: File }>(),
    "Remove Conversation Photo": props<{ conversationId: string }>(),
    "Edit Conversation Details": props<{
      conversationId: string;
      conversationType: ConversationType;
      subject: string;
      name: string;
      patientData?: PatientDataModel | null;
    }>(),
    "Mute Conversation": props<{
      conversationId: string;
      muteInterval: number;
    }>(),
    "Unmute Conversation": props<{ conversationId: string }>(),
    "Send Message": props<{
      conversationId: string;
      user: FullUserProfileModel;
      message: Omit<CreateMessageModel, "createdOnUtc">;
    }>(),
    "View Message Card": props<{
      message: Message;
      user: FullUserProfileModel;
      isPreviewMode: boolean;
      isSentBySelf: boolean;
    }>(),
    "Archive Conversation": props<{ conversationId: string }>(),
    "Undo Archive Conversation": props<{ conversationId: string }>(),
    "Delete Message": props<{ conversationId: string; marker: string }>(),
    "Forward Message": props<{
      conversationId: string;
      user: FullUserProfileModel;
      message: Omit<CreateMessageModel, "createdOnUtc">;
    }>(),
    "Send Media": props<{
      conversationId: string;
      user: FullUserProfileModel;
      media: Omit<CreateMessageMediaModel, "createdOnUtc">[];
    }>(),
    "Send Patient Files": props<{
      conversationId: string;
      user: FullUserProfileModel;
      patientFiles: Omit<CreateMessagePatientFileModel, "createdOnUtc">[];
    }>(),
    "Reply To Message": props<{ message: Message }>(),
    "Reply To Message Reset": emptyProps(),
  },
});

export const ConversationsPreprocessingActions = createActionGroup({
  source: "Conversations Preprocessing",
  events: {
    "Send Message Preprocessed": props<{
      conversationId: string;
      user: FullUserProfileModel;
      message: CreateMessageModel;
    }>(),
    "Send Media Preprocessed": props<{
      conversationId: string;
      user: FullUserProfileModel;
      media: CreateMessageMediaModel[];
    }>(),
    "Send Patient Files Preprocessed": props<{
      conversationId: string;
      user: FullUserProfileModel;
      patientFiles: CreateMessagePatientFileModel[];
    }>(),
  },
});

export type MessagesPageType = "initial" | "next" | "previous";

export const ConversationsApiActions = createActionGroup({
  source: "Conversations API",
  events: {
    "Load Messages Success": props<
      WithUser<{
        conversationId: string;
        messages: MessageModel[];
        pageType: MessagesPageType;
      }>
    >(),
    "Load Messages Error": props<{
      error: ErrorResponse;
      pageType: MessagesPageType;
    }>(),
    "Load Conversations Success": props<
      WithUser<{
        response: ApiPagedResult<ConversationModelV3>;
        isSearch: boolean;
      }>
    >(),
    "Load Conversations Error": props<{
      error: ErrorResponse;
      isSearch: boolean;
    }>(),
    "Load External Conversations Success": props<
      WithUser<{
        response: ApiPagedResult<ConversationModelV3>;
        isSearch: boolean;
      }>
    >(),
    "Load External Conversastions Error": props<{
      error: ErrorResponse;
      isSearch: boolean;
    }>(),
    "Load Roles Conversations Success": props<
      WithUser<{
        response: ApiPagedResult<ConversationModelV3>;
        isSearch: boolean;
      }>
    >(),
    "Load Roles Conversations Error": props<{
      error: ErrorResponse;
      isSearch: boolean;
    }>(),
    "Load Conversation Success":
      props<WithUser<{ conversation: ConversationModelV3 }>>(),
    "Load Conversation Error": props<{
      error: ErrorResponse;
      conversationId: string;
    }>(),
    "Load Unread Skeleton Conversations Success": props<
      WithUser<{
        response: ApiPagedResult<ConversationSkeletonModelV3>;
      }>
    >(),
    "Load Unread Skeleton Conversations Error": props<ErrorResponse>(),
    "Mark Conversation As Read Success": props<
      WithUser<{
        conversationId: string;
        clearUnreadConversationModel: ClearUnreadConversationModelV3;
      }>
    >(),
    "Mark Conversation As Read Error": props<ErrorResponse>(),
    "Pin Conversation Success":
      props<WithUser<{ conversation: ConversationModelV3 }>>(),
    "Pin Conversation Error": props<ErrorResponse>(),
    "Unpin Conversation Success": props<
      WithUser<{
        conversation: ConversationModelV3;
      }>
    >(),
    "Unpin Conversation Error": props<ErrorResponse>(),
    "Edit Conversation Photo Success": props<
      WithUser<{
        conversation: ConversationModelV3;
      }>
    >(),
    "Edit Conversation Photo Error": props<ErrorResponse>(),
    "Remove Conversation Photo Success": props<
      WithUser<{
        conversation: ConversationModelV3;
      }>
    >(),
    "Remove Conversation Photo Error": props<ErrorResponse>(),
    "Edit Conversation Details Success":
      props<WithUser<{ conversation: ConversationModelV3 }>>(),
    "Edit Conversation Details Error": props<ErrorResponse>(),
    "Mute Conversation Success": props<{
      conversationId: string;
      participant: ConversationParticipantModel;
    }>(),
    "Mute Conversation Error": props<ErrorResponse>(),
    "Unmute Conversation Success": props<{
      conversationId: string;
      participant: ConversationParticipantModel;
    }>(),
    "Unmute Conversation Error": props<ErrorResponse>(),
    "Video Call Update": props<
      WithUser<{
        update: VideoCallUpdate;
        conversation: Conversation;
        participant: ConversationParticipantModelV3 | null;
      }>
    >(),
    "Message Update": props<
      WithUser<{
        update: MessageUpdate;
        conversation: Conversation;
        participant: ConversationParticipantModelV3 | null;
      }>
    >(),
    "Send Message Success": props<
      WithUser<{
        message: MessageModel;
      }>
    >(),
    "Send Message Error": props<ErrorResponse>(),
    "Read Messages Success":
      props<WithUser<{ conversationId: string; messages: MessageModel[] }>>(),
    "Read Messages Error": props<ErrorResponse>(),
    "Archive Conversation Success": props<{ conversationId: string }>(),
    "Archive Conversation Error": props<{
      error: ErrorResponse;
      conversationId: string;
    }>(),
    "Delete Message Success": props<WithUser<{ message: MessageModel }>>(),
    "Delete Message Error": props<ErrorResponse>(),
    "Send Media Success": props<
      WithUser<{
        messages: MessageModel[];
      }>
    >(),
    "Send Media Error": props<ErrorResponse>(),
    "Send Patient Files Success": props<
      WithUser<{
        messages: MessageModel[];
      }>
    >(),
    "Send Patient Files Error": props<ErrorResponse>(),
    "Create Invitation Success": props<{
      conversationId: string;
      invitation: ConversationInvitationModel;
    }>(),
    "Create Invitation Error": props<ErrorResponse>(),
    "Toggle Invitation Allow All Success": props<{
      conversationId: string;
      invitation: ConversationInvitationModel;
    }>(),
    "Toggle Invitation Allow All Error": props<ErrorResponse>(),
    "Reset Invitation Success": props<{
      conversationId: string;
      resetInvitationModel: ResetInvitationResponseModel;
    }>(),
    "Reset Invitation Error": props<ErrorResponse>(),
  },
});
