<div class="celo-left-side-nav">
  <div class="celo-page-title title p-2 pl-3">
    <div>Secure Library</div>
  </div>

  <app-celo-side-menu-sub-list [celoTemplate]="2">
  </app-celo-side-menu-sub-list>
</div>

<div class="library-content">
  <router-outlet></router-outlet>
</div>
