import { Injectable } from "@angular/core";
import { environment } from "@env";
import { UrlHelperService } from "app/modules/shared/auth.img.service";
import { ZipFilesService } from "@modules/shared/zip-files.service";

@Injectable({
  providedIn: "root",
})
export class ConsentService {
  downloadDocs(consents: any) {
    // throw new Error("Method not implemented.");
    this.zipFilesService.downloadZip(
      consents.map((consent) => {
        // const fileName = consent.data.id + '.pdf';
        const fileName = this.zipFilesService.createFileName(
          consent.data.createdOn,
          "pdf",
          consent.data.patientData
        );
        return {
          url: `${environment.celoApiEndpoint}/api/consents/${consent.data.id}`,
          fileName,
        };
      })
    );
  }

  constructor(
    private urlHelperService: UrlHelperService,
    private zipFilesService: ZipFilesService
  ) {}

  // todo: delete this service, not used?
  public downloadConsent(consentId: any) {
    const url = `${environment.celoApiEndpoint}/api/consents/${consentId}`;
    this.urlHelperService.get(url).subscribe((m) => {
      window.open(m);
    });
  }
}
