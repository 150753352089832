<div>
  <h1>Add a workspace</h1>
  <div class="subtitle">
    Members of a workspace can private message and create group chats with other
    members.
  </div>
  <div class="cotainer">
    <div class="box">
      <img
        src="../../../../assets/images/join-workspace/create-workspace.svg"
        alt=""
      />
      <div class="title">Create a new workspace</div>
      <div>Set up a new workspace and invite your team</div>
      <div class="button">
        <button (click)="createWorkspace()" class="celo-primary-button">
          Create a workspace
        </button>
      </div>
    </div>
    <div class="box">
      <img
        src="../../../../assets/images/join-workspace/join-via-email.svg"
        alt=""
      />
      <div class="title">Join a workspace via Email</div>
      <div>Verify your work email to join</div>
      <div class="button">
        <button (click)="joinWorkspace()" class="celo-primary-button">
          Join a workspace
        </button>
      </div>
    </div>
    <div class="box">
      <img
        src="../../../../assets/images/join-workspace/join-via-link.svg"
        alt=""
      />
      <div class="title">Join a workspace via Link</div>
      <div>Follow an invite link shared by a member of the workspace</div>
      <div class="button">
        <a (click)="learnMore()" class="celo-link">Learn more</a>
      </div>
    </div>
  </div>
</div>
