<div class="content">
  <img class="logo" src="../../../assets/logo-v2.svg" alt="" />
  <img class="pic appear_anim_1" src="../../../assets/welcome.svg" alt="" />
  <div class="title appear_anim_2">Welcome to Celo!</div>
  <div class="subtitle appear_anim_3 celo-text-18 celo-grey">
    Your Celo account has been successfully set up!
  </div>
  <a
    [routerLink]="['/login']"
    [queryParams]="{ email: email, data_region_id: dataRegionId }"
    ><button mat-button class="celo-primary-button appear_anim_4" autofocus>
      LOGIN
    </button></a
  >
</div>
