import { inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SYSTEM_USER_ID } from "@common";
import { environment } from "@env";
import {
  ConversationsService,
  InvitationsService,
  SnackbarService,
  TeamsService,
  UserService,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { NotificationService } from "@modules/core/services/notification.service";
import { SoundPlayService } from "@modules/shared/sound-play.service";
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from "@ngrx/effects";
import { mapResponse, tapResponse } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import {
  ConversationType,
  GetUnreadSkeletonConversationRequestOptions,
  MessageStatuses,
  MessageStatusUpdate,
  MessagesV2RequestOptions,
  MessageType,
  Order,
  ParticipantRole,
  PinnedMode,
  Team,
  UpdateConversationInvitationModel,
} from "@types";
import {
  bufferDebounce,
  ConversationUtils,
  getNotificationTargetUrl,
  getQueryParameter,
  isHttpErrorResponse,
  isNotNullOrUndefined,
  maxDate,
  MessageNotificationData,
  MessageUtils,
  NotificationData,
} from "@utils";
import {
  combineLatest,
  EMPTY,
  exhaustMap,
  expand,
  filter,
  first,
  groupBy,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs";
import { assertIsExhaustive } from "utils/typescript-utils";
import { selectUser, selectUserId, SignalRActions } from "..";
import { AlertService } from "./../../modules/core/old/alert.service";
import { AnalyticsService } from "./../../modules/core/old/analytics.service";
import { SharedService } from "./../../modules/core/old/shared.service";
import {
  ConversationsApiActions,
  ConversationsPageActions,
  ConversationsPreprocessingActions,
  MessagesPageType,
} from "./conversations.actions";
import {
  conversationsFeature,
  selectConversation,
  selectUnreadMessageCount,
} from "./conversations.reducers";

const loadUnreadConversations = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(
        ConversationsPageActions.inboxOpened,
        ConversationsPageActions.rolesInboxOpened,
        ConversationsPageActions.externalInboxOpened,
        ROOT_EFFECTS_INIT
      ),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      exhaustMap(([action, user]) => {
        const conversationTypeFilters: ConversationType[] = [
          ConversationType.Chat,
          ConversationType.Group,
          ConversationType.Case,
          ConversationType.TeamChat,
        ];

        const options: Omit<
          GetUnreadSkeletonConversationRequestOptions,
          "page"
        > = {
          conversationTypeFilters,
          pageSize: 100,
        };

        return conversationsService
          .getUnreadConversationSkeletons({
            ...options,
            page: 0,
          })
          .pipe(
            // Load all pages
            expand((response) => {
              const lastPageNumber = response.totalPages - 1;
              const nextPageNumber = response.page + 1;

              if (nextPageNumber > lastPageNumber) return EMPTY;

              return conversationsService.getUnreadConversationSkeletons({
                ...options,
                page: nextPageNumber,
              });
            }),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.loadUnreadSkeletonConversationsSuccess(
                  {
                    user,
                    data: {
                      response,
                    },
                  }
                );
              },
              error: (error) => {
                return ConversationsApiActions.loadUnreadSkeletonConversationsError(
                  {
                    errors: [
                      {
                        code: "errorCodePlaceholder",
                        message: "errorMessagePlaceholder",
                      },
                    ],
                  }
                );
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const loadInbox = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(
        ConversationsPageActions.inboxOpened,
        ConversationsPageActions.inboxLoadMore
      ),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
          store.select(conversationsFeature.selectInboxPaginationData),
        ]).pipe(first());
      }),
      switchMap(([action, user, paginationData]) => {
        return conversationsService
          .getConversations({
            page:
              action.type === "[Conversations Page] Inbox Opened"
                ? 0
                : paginationData.nextPageNumber,
            pageSize: 20,
            pinnedMode: PinnedMode.PinnedTop,
            isExternal: false,
          })
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.loadConversationsSuccess({
                  user,
                  data: { response, isSearch: false },
                });
              },
              error: (error) => {
                return ConversationsApiActions.loadConversationsError({
                  error: {
                    errors: [
                      {
                        code: "errorCodePlaceholder",
                        message: "errorMessagePlaceholder",
                      },
                    ],
                  },
                  isSearch: false,
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const loadInboxSearchResults = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(
        ConversationsPageActions.inboxSearch,
        ConversationsPageActions.inboxSearchLoadMore
      ),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
          store.select(
            conversationsFeature.selectInboxSearchResultsPaginationData
          ),
          store.select(conversationsFeature.selectInboxSearchQuery),
        ]).pipe(first());
      }),
      switchMap(([action, user, paginationData, query]) => {
        return conversationsService
          .search({
            page:
              action.type === "[Conversations Page] Inbox Search"
                ? 0
                : paginationData.nextPageNumber,
            pageSize: 50,
            search:
              action.type === "[Conversations Page] Inbox Search"
                ? action.query
                : query,
            isExternal: false,
          })
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.loadConversationsSuccess({
                  user,
                  data: { response, isSearch: true },
                });
              },
              error: (error) => {
                return ConversationsApiActions.loadConversationsError({
                  error: {
                    errors: [
                      {
                        code: "errorCodePlaceholder",
                        message: "errorMessagePlaceholder",
                      },
                    ],
                  },
                  isSearch: true,
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const loadConversation = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.conversationOpened),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      switchMap(([action, user]) => {
        return conversationsService.getConversation(action.conversationId).pipe(
          first(),
          mapResponse({
            next: (response) => {
              return ConversationsApiActions.loadConversationSuccess({
                user,
                data: {
                  conversation: response,
                },
              });
            },
            error: (error) => {
              return ConversationsApiActions.loadConversationError({
                error: {
                  errors: [
                    {
                      code: "errorCodePlaceholder",
                      message: "errorMessagePlaceholder",
                    },
                  ],
                },
                conversationId: action.conversationId,
              });
            },
          })
        );
      })
    );
  },
  { functional: true }
);

const loadMessages = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.conversationOpened),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      switchMap(([action, user]) => {
        return conversationsService
          .getMessages({
            conversationId: action.conversationId,
          })
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.loadMessagesSuccess({
                  user,
                  data: {
                    conversationId: action.conversationId,
                    messages: response,
                    pageType: "initial",
                  },
                });
              },
              error: (error) => {
                return ConversationsApiActions.loadMessagesError({
                  pageType: "initial",
                  error: {
                    errors: [
                      {
                        code: "errorCodePlaceholder",
                        message: "errorMessagePlaceholder",
                      },
                    ],
                  },
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const loadMoreMessages = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(
        ConversationsPageActions.loadMoreNewerMessages,
        ConversationsPageActions.loadMoreOlderMessages,
        ConversationsPageActions.messagesScrolledToTop
      ),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
          store.select(conversationsFeature.selectSelectedConversationId),
          store.select(conversationsFeature.selectSelectedConversationMessages),
        ]).pipe(first());
      }),
      switchMap(([action, user, conversationId, messages]) => {
        const options: Partial<MessagesV2RequestOptions> = {
          count: 50,
        };

        let pageType: MessagesPageType = "previous";
        const actionType = action.type;
        switch (actionType) {
          case "[Conversations Page] Load More Newer Messages":
            const latestMessage = MessageUtils.getFirstMessageBySentOnUtc(
              messages,
              Order.Descending
            );
            options.after = latestMessage.sentOnUtc;
            pageType = "next";
            break;
          case "[Conversations Page] Load More Older Messages":
          case "[Conversations Page] Messages Scrolled To Top":
            const oldestMessage = MessageUtils.getFirstMessageBySentOnUtc(
              messages,
              Order.Ascending
            );
            options.before = oldestMessage.sentOnUtc;
            break;
          default:
            return assertIsExhaustive(actionType);
        }

        return conversationsService
          .getMessages({
            conversationId,
            ...options,
          })
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.loadMessagesSuccess({
                  user,
                  data: {
                    conversationId,
                    messages: response,
                    pageType,
                  },
                });
              },
              error: (error) => {
                return ConversationsApiActions.loadMessagesError({
                  pageType,
                  error: {
                    errors: [
                      {
                        code: "errorCodePlaceholder",
                        message: "errorMessagePlaceholder",
                      },
                    ],
                  },
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const readMessages = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.viewMessageCard),
      filter((action) => !action.isSentBySelf),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      mergeMap(([action, user]) => {
        const messages = [action.message];
        const conversationId = action.message.conversationId;

        const statuses: MessageStatusUpdate[] = messages
          .filter((m) => !MessageUtils.isRead(m, user.userId))
          .flatMap((m) => {
            const newStatuses: MessageStatusUpdate[] = [
              {
                MessageId: m.id,
                Status: MessageStatuses.Read,
              },
            ];

            const isDelivered = m.statuses?.some(
              (s) =>
                s.createdBy === user.userId &&
                s.status === MessageStatuses.Delivered
            );

            if (!isDelivered) {
              newStatuses.push({
                MessageId: m.id,
                Status: MessageStatuses.Delivered,
              });
            }

            return newStatuses;
          });

        if (statuses.length === 0) return EMPTY;

        return conversationsService
          .updateMessageStatuses(conversationId, statuses)
          .pipe(
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.readMessagesSuccess({
                  user,
                  data: {
                    conversationId,
                    messages: response,
                  },
                });
              },
              error: (error) => {
                return ConversationsApiActions.readMessagesError({
                  errors: [
                    {
                      code: "errorCodePlaceholder",
                      message: "errorMessagePlaceholder",
                    },
                  ],
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

/**
 * This specifically refers to automatically updating the point a user has read up to in a conversation when
 * scrolling or viewing the messages inside it
 */
const markConversationAsRead = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.viewMessageCard),
      filter((action) => !action.isPreviewMode && !action.isSentBySelf),
      groupBy((action) => action.message.conversationId),
      mergeMap((group) => {
        const conversationId = group.key;
        return group.pipe(
          bufferDebounce(500),
          map((actions) => {
            const maxAsReadToUtc = maxDate(
              actions.map((action) => new Date(action.message.sentOnUtc))
            );
            return {
              conversationId,
              maxAsReadToUtc,
            };
          }),
          switchMap((action) => {
            return combineLatest([
              of(action),
              store.select(selectUser).pipe(first(isNotNullOrUndefined)),
              store
                .select(selectConversation(conversationId))
                .pipe(first(isNotNullOrUndefined)),
            ]).pipe(first());
          }),
          filter(([aggregateAction, user, conversation]) => {
            const participant = ConversationUtils.findConversationParticipant(
              conversation,
              user.userId
            );
            if (!participant) throw new Error("Failed to find participant");

            const serverAsReadToUtc = participant.asReadToUtc;
            if (!serverAsReadToUtc) return true;

            return (
              new Date(aggregateAction.maxAsReadToUtc) >
              new Date(serverAsReadToUtc)
            );
          }),
          mergeMap(([aggregateAction, user]) => {
            const asReadToUtc = aggregateAction.maxAsReadToUtc.toISOString();
            return conversationsService
              .clearUnread(conversationId, {
                asReadToUtc: asReadToUtc,
              })
              .pipe(
                mapResponse({
                  next: (response) => {
                    return ConversationsApiActions.markConversationAsReadSuccess(
                      {
                        user,
                        data: {
                          conversationId,
                          clearUnreadConversationModel: response,
                        },
                      }
                    );
                  },
                  error: (error) => {
                    return ConversationsApiActions.markConversationAsReadError({
                      errors: [
                        {
                          code: "errorCodePlaceholder",
                          message: "errorMessagePlaceholder",
                        },
                      ],
                    });
                  },
                })
              );
          })
        );
      })
    );
  },
  { functional: true }
);

const clearUnreadBadge = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService),
    snackbarService = inject(SnackbarService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.clearUnreadBadge),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      mergeMap(([action, user]) => {
        return conversationsService
          .clearUnread(action.conversationId, {
            asReadToUtc: new Date().toISOString(),
          })
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                snackbarService.show("Cleared successfully", 3);
                return ConversationsApiActions.markConversationAsReadSuccess({
                  user,
                  data: {
                    conversationId: action.conversationId,
                    clearUnreadConversationModel: response,
                  },
                });
              },
              error: (error) => {
                return ConversationsApiActions.markConversationAsReadError({
                  errors: [
                    {
                      code: "errorCodePlaceholder",
                      message: "errorMessagePlaceholder",
                    },
                  ],
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const pinConversation = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService),
    snackbarService = inject(SnackbarService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.pinConversation),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      mergeMap(([{ conversationId }, user]) => {
        return conversationsService.pinConversation(conversationId).pipe(
          switchMap(() => {
            return conversationsService.getConversation(conversationId);
          }),
          first(),
          mapResponse({
            next: (response) => {
              return ConversationsApiActions.pinConversationSuccess({
                user,
                data: {
                  conversation: response,
                },
              });
            },
            error: (error) => {
              if (isHttpErrorResponse(error)) {
                if (
                  error.status === 400 &&
                  error.error.errors?.[0]?.code === "400017"
                ) {
                  snackbarService.show("You can only pin 5 conversations.", 3);
                }
              }

              return ConversationsApiActions.pinConversationError({
                errors: [
                  {
                    code: "errorCodePlaceholder",
                    message: "errorMessagePlaceholder",
                  },
                ],
              });
            },
          })
        );
      })
    );
  },
  { functional: true }
);

const unpinConversation = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService),
    snackbarService = inject(SnackbarService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.unpinConversation),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      mergeMap(([{ conversationId }, user]) => {
        return conversationsService.unpinConversation(conversationId).pipe(
          switchMap(() => {
            return conversationsService.getConversation(conversationId);
          }),
          first(),
          mapResponse({
            next: (response) => {
              return ConversationsApiActions.unpinConversationSuccess({
                user,
                data: {
                  conversation: response,
                },
              });
            },
            error: (error) => {
              snackbarService.show("Could not be unpinned, try again.", 3);

              return ConversationsApiActions.unpinConversationError({
                errors: [
                  {
                    code: "errorCodePlaceholder",
                    message: "errorMessagePlaceholder",
                  },
                ],
              });
            },
          })
        );
      })
    );
  },
  { functional: true }
);

const editConversationPhoto = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService),
    snackbarService = inject(SnackbarService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.editConversationPhoto),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      mergeMap(([{ conversationId, file }, user]) => {
        const formData = new FormData();
        formData.append("fileData", new Blob([file]));

        snackbarService.show("Uploading Profile Picture...", 10);

        return conversationsService
          .uploadProfilePhoto(formData, conversationId)
          .pipe(
            tap(() => {
              snackbarService.show("Conversation Profile Photo Uploaded!", 3);
            }),
            switchMap(() => {
              return conversationsService.getConversation(conversationId);
            }),
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.editConversationPhotoSuccess({
                  user,
                  data: {
                    conversation: response,
                  },
                });
              },
              error: (error) => {
                return ConversationsApiActions.editConversationPhotoError({
                  errors: [
                    {
                      code: "errorCodePlaceholder",
                      message: "errorMessagePlaceholder",
                    },
                  ],
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const removeConversationPhoto = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService),
    snackbarService = inject(SnackbarService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.removeConversationPhoto),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      mergeMap(([{ conversationId }, user]) => {
        return conversationsService.removeProfilePhoto(conversationId).pipe(
          tap(() => {
            snackbarService.show("Conversation Profile Photo Removed!", 3);
          }),
          switchMap(() => {
            return conversationsService.getConversation(conversationId);
          }),
          first(),
          mapResponse({
            next: (response) => {
              return ConversationsApiActions.removeConversationPhotoSuccess({
                user,
                data: {
                  conversation: response,
                },
              });
            },
            error: (error) => {
              return ConversationsApiActions.removeConversationPhotoError({
                errors: [
                  {
                    code: "errorCodePlaceholder",
                    message: "errorMessagePlaceholder",
                  },
                ],
              });
            },
          })
        );
      })
    );
  },
  { functional: true }
);

const editConversationDetails = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService),
    snackbarService = inject(SnackbarService),
    alertService = inject(AlertService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.editConversationDetails),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      mergeMap(([{ conversationId, ...action }, user]) => {
        const details: Parameters<
          typeof conversationsService.updateConversationDetails
        >[1] = {
          subject: action.subject,
          name: action.name,
        };

        if (action.patientData) {
          details.patientData = action.patientData;
        }

        return conversationsService
          .updateConversationDetails(conversationId, details)
          .pipe(
            switchMap(() => {
              return conversationsService.getConversation(conversationId);
            }),
            first(),
            mapResponse({
              next: (response) => {
                snackbarService.show(
                  `${action.conversationType} has been updated successfully`,
                  3
                );
                return ConversationsApiActions.editConversationDetailsSuccess({
                  user,
                  data: {
                    conversation: response,
                  },
                });
              },
              error: (error) => {
                let errText = "Error updating conversation. ";
                if (error["status"] == 400) {
                  const errBody = error["error"];
                  if (errBody.uid) {
                    errText += " " + errBody.uid[0];
                  }
                  if (errBody.dateOfBirth) {
                    errText += " " + errBody.dateOfBirth[0];
                  }
                }
                alertService.alert("", errText, true);

                return ConversationsApiActions.editConversationDetailsError({
                  errors: [
                    {
                      code: "errorCodePlaceholder",
                      message: "errorMessagePlaceholder",
                    },
                  ],
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const muteConversation = createEffect(
  (
    actions$ = inject(Actions),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.muteConversation),
      mergeMap(({ conversationId, muteInterval }) => {
        return conversationsService
          .muteConversation(conversationId, muteInterval)
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.muteConversationSuccess({
                  conversationId,
                  participant: response,
                });
              },
              error: (error) => {
                return ConversationsApiActions.muteConversationError({
                  errors: [
                    {
                      code: "errorCodePlaceholder",
                      message: "errorMessagePlaceholder",
                    },
                  ],
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const unmuteConversation = createEffect(
  (
    actions$ = inject(Actions),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.unmuteConversation),
      mergeMap(({ conversationId }) => {
        return conversationsService.unmuteConversation(conversationId).pipe(
          first(),
          mapResponse({
            next: (response) => {
              return ConversationsApiActions.unmuteConversationSuccess({
                conversationId,
                participant: response,
              });
            },
            error: (error) => {
              return ConversationsApiActions.unmuteConversationError({
                errors: [
                  {
                    code: "errorCodePlaceholder",
                    message: "errorMessagePlaceholder",
                  },
                ],
              });
            },
          })
        );
      })
    );
  },
  { functional: true }
);

const messageOrVideoCallUpdate = createEffect(
  (
    actions$ = inject(Actions),
    conversationsService = inject(ConversationsService),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(SignalRActions.videoCallUpdate, SignalRActions.messageUpdate),
      mergeMap((action) => {
        return store
          .select(selectConversation(action.update.conversationId))
          .pipe(
            withLatestFrom(
              store.select(selectUser).pipe(first(isNotNullOrUndefined))
            ),
            switchMap(([conversation, user]) => {
              if (conversation) return of({ user, conversation });
              return conversationsService
                .getConversation(action.update.conversationId)
                .pipe(
                  map((conversationModel) => {
                    const newConversation =
                      ConversationUtils.fromConversationModelV3(
                        conversationModel,
                        user
                      );
                    return { user, conversation: newConversation };
                  })
                );
            }),
            first(),
            switchMap(({ user, conversation }) => {
              // Load the participant associated with this update if it isn't already loaded
              const participantId =
                action.type === "[SignalR] Message Update"
                  ? action.update.sentBy
                  : action.update.createdBy;

              if (participantId === SYSTEM_USER_ID) {
                return of({
                  conversation,
                  user,
                  participant: null,
                });
              }

              const participant = ConversationUtils.findConversationParticipant(
                conversation,
                participantId
              );

              if (!participant) {
                return conversationsService
                  .getParticipant(action.update.conversationId, participantId)
                  .pipe(
                    map((participant) => {
                      return { action, conversation, user, participant };
                    })
                  );
              }

              return of({
                conversation,
                user,
                participant: null,
              });
            }),
            map(({ conversation, user, participant }) => {
              if (action.type === "[SignalR] Message Update") {
                return ConversationsApiActions.messageUpdate({
                  user,
                  data: {
                    update: action.update,
                    conversation,
                    participant,
                  },
                });
              }

              return ConversationsApiActions.videoCallUpdate({
                user,
                data: {
                  update: action.update,
                  conversation,
                  participant,
                },
              });
            })
          );
      })
    );
  },
  { functional: true }
);

const sendMessagePreprocessing = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(
        ConversationsPageActions.sendMessage,
        ConversationsPageActions.forwardMessage,
        ConversationsPageActions.sendMedia,
        ConversationsPageActions.sendPatientFiles
      ),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(conversationsFeature.selectConversations),
        ]).pipe(first());
      }),
      map(([action, conversations]) => {
        const { conversationId, user } = action;

        const createdOnUtc =
          ConversationUtils.getComputedMessageCreatedOnUtc(
            conversations
          ).toISOString();

        const actionType = action.type;
        switch (actionType) {
          case "[Conversations Page] Send Message":
          case "[Conversations Page] Forward Message":
            return ConversationsPreprocessingActions.sendMessagePreprocessed({
              conversationId,
              user,
              message: {
                ...action.message,
                createdOnUtc,
              },
            });
          case "[Conversations Page] Send Media":
            return ConversationsPreprocessingActions.sendMediaPreprocessed({
              conversationId,
              user,
              media: action.media.map((media) => {
                return {
                  ...media,
                  createdOnUtc,
                };
              }),
            });
          case "[Conversations Page] Send Patient Files":
            return ConversationsPreprocessingActions.sendPatientFilesPreprocessed(
              {
                conversationId,
                user,
                patientFiles: action.patientFiles.map((patientFile) => {
                  return {
                    ...patientFile,
                    createdOnUtc,
                  };
                }),
              }
            );
          default:
            return assertIsExhaustive(actionType);
        }
      })
    );
  },
  { functional: true }
);

const sendMessage = createEffect(
  (
    actions$ = inject(Actions),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(
        ConversationsPreprocessingActions.sendMessagePreprocessed,
        ConversationsPreprocessingActions.sendMediaPreprocessed,
        ConversationsPreprocessingActions.sendPatientFilesPreprocessed
      ),
      mergeMap((action) => {
        const { conversationId, user } = action;

        const actionType = action.type;
        switch (actionType) {
          case "[Conversations Preprocessing] Send Message Preprocessed":
            return conversationsService
              .sendMessage(conversationId, action.message)
              .pipe(
                first(),
                mapResponse({
                  next: (response) => {
                    if (response.metadata.forwardFromConversationId) {
                      conversationsService.navigateToConversation(
                        response.conversationId
                      );
                    }

                    return ConversationsApiActions.sendMessageSuccess({
                      user,
                      data: {
                        message: response,
                      },
                    });
                  },
                  error: (error) => {
                    return ConversationsApiActions.sendMessageError({
                      errors: [
                        {
                          code: "errorCodePlaceholder",
                          message: "errorMessagePlaceholder",
                        },
                      ],
                    });
                  },
                })
              );
          case "[Conversations Preprocessing] Send Media Preprocessed":
            return conversationsService
              .sendMedia(conversationId, action.media)
              .pipe(
                first(),
                mapResponse({
                  next: (response) => {
                    return ConversationsApiActions.sendMediaSuccess({
                      user,
                      data: {
                        messages: response,
                      },
                    });
                  },
                  error: (error) => {
                    return ConversationsApiActions.sendMediaError({
                      errors: [
                        {
                          code: "errorCodePlaceholder",
                          message: "errorMessagePlaceholder",
                        },
                      ],
                    });
                  },
                })
              );
          case "[Conversations Preprocessing] Send Patient Files Preprocessed":
            return conversationsService
              .sendPatientFiles(conversationId, action.patientFiles)
              .pipe(
                first(),
                mapResponse({
                  next: (response) => {
                    return ConversationsApiActions.sendPatientFilesSuccess({
                      user,
                      data: {
                        messages: response,
                      },
                    });
                  },
                  error: (error) => {
                    return ConversationsApiActions.sendPatientFilesError({
                      errors: [
                        {
                          code: "errorCodePlaceholder",
                          message: "errorMessagePlaceholder",
                        },
                      ],
                    });
                  },
                })
              );
          default:
            return assertIsExhaustive(actionType);
        }
      })
    );
  },
  { functional: true }
);

const deleteMessage = createEffect(
  (
    actions$ = inject(Actions),
    conversationsService = inject(ConversationsService),
    store = inject(Store),
    matDialog = inject(MatDialog)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.deleteMessage),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
        ]).pipe(first());
      }),
      mergeMap(([{ conversationId, marker }, user]) => {
        return conversationsService.deleteMessage(conversationId, marker).pipe(
          first(),
          mapResponse({
            next: (response) => {
              return ConversationsApiActions.deleteMessageSuccess({
                user,
                data: {
                  message: response,
                },
              });
            },
            error: (error) => {
              AlertComponent.openErrorDialog(matDialog);
              return ConversationsApiActions.deleteMessageError({
                errors: [
                  {
                    code: "errorCodePlaceholder",
                    message: "errorMessagePlaceholder",
                  },
                ],
              });
            },
          })
        );
      })
    );
  },
  { functional: true }
);

const archiveConversation = createEffect(
  (
    actions$ = inject(Actions),
    conversationsService = inject(ConversationsService),
    matSnackbar = inject(MatSnackBar),
    matDialog = inject(MatDialog)
  ) => {
    return actions$.pipe(
      ofType(ConversationsPageActions.archiveConversation),
      mergeMap(({ conversationId }) => {
        const snackbarRef = matSnackbar.open(
          "Conversation archived. You can retrieve this by searching for it.",
          "Undo",
          {
            duration: 3000,
          }
        );

        return snackbarRef.afterDismissed().pipe(
          switchMap(({ dismissedByAction }) => {
            if (dismissedByAction) {
              return of(
                ConversationsPageActions.undoArchiveConversation({
                  conversationId,
                })
              );
            }

            return conversationsService
              .archiveConversation(conversationId)
              .pipe(
                first(),
                mapResponse({
                  next: (response) => {
                    return ConversationsApiActions.archiveConversationSuccess({
                      conversationId,
                    });
                  },
                  error: (error) => {
                    AlertComponent.openErrorDialog(
                      matDialog,
                      "Could not be archived, try again"
                    );

                    return ConversationsApiActions.archiveConversationError({
                      error: {
                        errors: [
                          {
                            code: "errorCodePlaceholder",
                            message: "errorMessagePlaceholder",
                          },
                        ],
                      },
                      conversationId,
                    });
                  },
                })
              );
          })
        );
      })
    );
  },
  { functional: true }
);

const playMessageSentSound = createEffect(
  (actions$ = inject(Actions), soundPlayService = inject(SoundPlayService)) => {
    return actions$.pipe(
      ofType(
        ConversationsApiActions.sendMessageSuccess,
        ConversationsApiActions.sendMediaSuccess,
        ConversationsApiActions.sendPatientFilesSuccess
      ),
      exhaustMap(() => {
        soundPlayService.playSentSound();
        return EMPTY;
      })
    );
  },
  { functional: true, dispatch: false }
);

const showNotification = createEffect(
  (
    actions$ = inject(Actions),
    soundPlayService = inject(SoundPlayService),
    userService = inject(UserService),
    notificationService = inject(NotificationService),
    teamsService = inject(TeamsService),
    conversationsService = inject(ConversationsService),
    store = inject(Store)
  ) => {
    return actions$.pipe(
      ofType(ConversationsApiActions.messageUpdate),
      switchMap((action) => {
        const conversation = action.data.conversation;

        let team: Team | null = null;
        if (conversation.type === ConversationType.TeamChat) {
          const teamId = conversationsService.tryParsePotentialTeamId(
            conversation.id
          );
          team = teamId ? teamsService.getLocalTeam(teamId) : null;
        }

        return combineLatest([
          of(action),
          of(team),
          store.select(selectUnreadMessageCount),
        ]).pipe(first());
      }),
      exhaustMap((data) => {
        return of(data).pipe(
          tapResponse({
            next: ([action, team, unreadMessageCount]) => {
              const userId = action.user.userId;
              const message = MessageUtils.fromMessageModel(action.data.update);
              const conversation = action.data.conversation;

              if (message.deletedOnUtc) return;
              if (message.sentBy === SYSTEM_USER_ID) return;

              let notificationData: MessageNotificationData = {
                conversationType: conversation.type,
              };
              if (conversation.type === ConversationType.TeamChat) {
                // Do not show any notifications for team chats where we cannot find the team
                // Note: it would be better to try load the team, but this is consistent with
                // the existing implementation at this point in time
                if (!team) return;

                notificationData = {
                  ...notificationData,
                  teamId: team.id,
                  isTeamChat: true,
                  isCurrentUserTeamMember: false,
                  isCurrentUserOnCallForTeam: false,
                };

                const isCurrentUserMentioned = MessageUtils.isMentioned(
                  message,
                  userId
                );

                if (teamsService.isCurrentUserMember(team)) {
                  notificationData.isCurrentUserTeamMember = true;

                  const memberOnCall = teamsService.findFirstMemberOnCall(team);

                  // Don't create a notification if someone else is on call and this
                  // user hasn't been mentioned
                  if (
                    !isCurrentUserMentioned &&
                    userId &&
                    memberOnCall &&
                    memberOnCall.user?.userId !== userId
                  ) {
                    return;
                  }
                }
              }

              if (
                Notification.permission === "granted" &&
                userId !== message.sentBy &&
                message.type !== MessageType.ConversationStarted
              ) {
                const targetUrl = getNotificationTargetUrl(
                  message,
                  notificationData
                );
                let body = "New message";

                if (unreadMessageCount) {
                  body = `${unreadMessageCount} unread message${unreadMessageCount > 1 ? "s" : ""}`;
                }

                if (!message.marker) throw Error("Invalid message marker");

                const notificationObj: NotificationData = {
                  title: "New Celo Message",
                  id: message.marker,
                  icon: environment.origin + "/assets/icon.png",
                  body,
                  requireInteraction: true,
                  target: targetUrl,
                  data: {
                    url: targetUrl,
                    time: Date.now(),
                  },
                  tag: "unreadMessagesNotification",
                };

                const isMentionNotificationEnabled =
                  userService.isMentionNotificationEnabled();
                const isMentioned = MessageUtils.isMentioned(message, userId);
                const isCurrentUserOnCallForTeam =
                  team != null && teamsService.isCurrentUserOnCall(team);

                const isPriorityNotification =
                  (isMentionNotificationEnabled && isMentioned) ||
                  isCurrentUserOnCallForTeam;

                const isMuted = ConversationUtils.isMuted(conversation, userId);
                if (isMuted && !isPriorityNotification) return;

                const isConversationOpen =
                  window.location.href.includes(
                    "conversations/" + message.conversationId + "/messages"
                  ) && document.hasFocus();

                if (
                  isConversationOpen &&
                  (!isMuted || isPriorityNotification)
                ) {
                  soundPlayService.playReceivedSound();
                  return;
                }

                notificationService.showPushNotification(
                  notificationObj,
                  isPriorityNotification
                );
              }
            },
            error: () => {},
          })
        );
      })
    );
  },
  { functional: true, dispatch: false }
);

const loadExternalInbox = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(
        ConversationsPageActions.externalInboxOpened,
        ConversationsPageActions.externalInboxLoadMore
      ),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
          store.select(conversationsFeature.selectExternalInboxPaginationData),
        ]).pipe(first());
      }),
      switchMap(([action, user, externalPaginationData]) => {
        return conversationsService
          .getExternalConversations({
            page:
              action.type ===
              ConversationsPageActions.externalInboxOpened().type
                ? 0
                : externalPaginationData.nextPageNumber,
            pageSize: 20,
          })
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.loadExternalConversationsSuccess(
                  {
                    user,
                    data: { response, isSearch: false },
                  }
                );
              },
              error: (error) => {
                return ConversationsApiActions.loadExternalConversastionsError({
                  error: {
                    errors: [
                      {
                        code: "errorCodePlaceholder",
                        message: "errorMessagePlaceholder",
                      },
                    ],
                  },
                  isSearch: false,
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const loadExternalInboxSearchResult = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(
        ConversationsPageActions.externalInboxSearch,
        ConversationsPageActions.externalInboxSearchLoadMore
      ),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
          store.select(conversationsFeature.selectExternalInboxPaginationData),
          store.select(conversationsFeature.selectExternalInboxSearchQuery),
        ]).pipe(first());
      }),
      switchMap(([action, user, paginationData, query]) => {
        return conversationsService
          .getExternalConversations({
            page:
              action.type === "[Conversations Page] External Inbox Search"
                ? 0
                : paginationData.nextPageNumber,
            pageSize: 20,
            search:
              action.type === "[Conversations Page] External Inbox Search"
                ? action.query
                : query,
          })
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.loadExternalConversationsSuccess(
                  {
                    user,
                    data: { response, isSearch: true },
                  }
                );
              },
              error: (error) => {
                return ConversationsApiActions.loadExternalConversastionsError({
                  error: {
                    errors: [
                      {
                        code: "errorCodePlaceholder",
                        message: "errorMessagePlaceholder",
                      },
                    ],
                  },
                  isSearch: true,
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const loadRolesInbox = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(
        ConversationsPageActions.rolesInboxOpened,
        ConversationsPageActions.rolesInboxLoadMore
      ),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
          store.select(conversationsFeature.selectRolesInboxPaginationData),
          store.select(conversationsFeature.selectSelectedTeamId),
        ]).pipe(first());
      }),
      switchMap(([action, user, paginationData, teamId]) => {
        return conversationsService
          .getTeamConversations({
            teamId:
              action.type === "[Conversations Page] Roles Inbox Opened"
                ? action.teamId
                : teamId,
            page:
              action.type === "[Conversations Page] Roles Inbox Opened"
                ? 0
                : paginationData.nextPageNumber,
            pageSize: 20,
          })
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.loadRolesConversationsSuccess({
                  user,
                  data: { response, isSearch: false },
                });
              },
              error: (error) => {
                return ConversationsApiActions.loadRolesConversationsError({
                  error: {
                    errors: [
                      {
                        code: "errorCodePlaceholder",
                        message: "errorMessagePlaceholder",
                      },
                    ],
                  },
                  isSearch: false,
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const loadRolesInboxSearchResults = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return actions$.pipe(
      ofType(
        ConversationsPageActions.rolesInboxSearch,
        ConversationsPageActions.rolesInboxSearchLoadMore
      ),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUser).pipe(first(isNotNullOrUndefined)),
          store.select(conversationsFeature.selectRolesInboxPaginationData),
          store.select(conversationsFeature.selectRolesInboxSearchQuery),
          store.select(conversationsFeature.selectSelectedTeamId),
        ]).pipe(first());
      }),
      switchMap(([action, user, paginationData, query, teamId]) => {
        return conversationsService
          .getTeamConversations({
            teamId,
            page:
              action.type === "[Conversations Page] Roles Inbox Search"
                ? 0
                : paginationData.nextPageNumber,
            pageSize: 20,
            search:
              action.type === "[Conversations Page] Roles Inbox Search"
                ? action.query
                : query,
          })
          .pipe(
            first(),
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.loadRolesConversationsSuccess({
                  user,
                  data: { response, isSearch: true },
                });
              },
              error: (error) => {
                return ConversationsApiActions.loadRolesConversationsError({
                  error: {
                    errors: [
                      {
                        code: "errorCodePlaceholder",
                        message: "errorMessagePlaceholder",
                      },
                    ],
                  },
                  isSearch: true,
                });
              },
            })
          );
      })
    );
  },
  { functional: true }
);

const createInvitation = createEffect(
  (
    action$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService)
  ) => {
    return action$.pipe(
      ofType(ConversationsPageActions.invitationDetailsOpened),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectUserId).pipe(first(isNotNullOrUndefined)),
          store.select(selectConversation(action.conversationId)),
        ]).pipe(first());
      }),
      exhaustMap(([action, userId, conversation]) => {
        const participant = ConversationUtils.findConversationParticipant(
          conversation,
          userId
        );
        const isAdministrator =
          participant.role !== ParticipantRole.Administrator;

        // Business rule: don't generate an invitation link when the
        // conversation invitation details is opened if there is
        // already an invitation URI *or* the current user is not
        // an administrator
        if (
          action.type === "[Conversations Page] Invitation Details Opened" &&
          (conversation?.invitation?.uri || !isAdministrator)
        ) {
          return EMPTY;
        }

        return conversationsService
          .createInvitation(action.conversationId)
          .pipe(
            mapResponse({
              next: (response) => {
                return ConversationsApiActions.createInvitationSuccess({
                  conversationId: action.conversationId,
                  invitation: response,
                });
              },
              error: (err) => {
                return ConversationsApiActions.createInvitationError({
                  errors: [
                    {
                      code: "errorCodePlaceholder",
                      message: "errorMessagePlaceholder",
                    },
                  ],
                });
              },
            })
          );
      })
    );
  },
  {
    functional: true,
  }
);

const toggleInvitationAllowAll = createEffect(
  (
    action$ = inject(Actions),
    store = inject(Store),
    conversationsService = inject(ConversationsService),
    alertService = inject(AlertService),
    analyticsService = inject(AnalyticsService),
    sharedService = inject(SharedService)
  ) => {
    return action$.pipe(
      ofType(ConversationsPageActions.toggleInvitationAllowAll),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectConversation(action.conversationId)),
        ]).pipe(first());
      }),
      exhaustMap(([action, conversation]) => {
        const conversationType = conversation?.type?.toLowerCase();

        const invitation = conversation?.invitation;
        if (!invitation) return EMPTY;

        const update: UpdateConversationInvitationModel = {
          allowAll: !invitation.allowAll,
        };

        let confirmation$: Observable<boolean> = of(true);
        if (update.allowAll) {
          confirmation$ = alertService
            .customDialog(
              "Are you sure you want to restrict invite access to admins only?",
              "A new link will be generated and the old link will no longer work to join this " +
                conversationType +
                ".",
              "CONTINUE",
              "CANCEL",
              false,
              "",
              ""
            )
            .afterClosed()
            .pipe(map((result) => !!result));
        }

        return confirmation$.pipe(
          switchMap((isConfirmed) => {
            if (!isConfirmed) return EMPTY;
            return conversationsService
              .updateInvitation(action.conversationId, update)
              .pipe(
                mapResponse({
                  next: (response) => {
                    if (response.allowAll) {
                      analyticsService.raiseEvent("link_toggle_sharing", {
                        link_type: conversationType,
                        is_link_reset: false,
                        success: true,
                      });
                    } else {
                      alertService.customDialog(
                        "Link has been reset",
                        "The previous invite link has been reset and a new invite link has been created. Only admins will have access to this new link.",
                        "OK",
                        "",
                        true,
                        "",
                        ""
                      );
                      analyticsService.raiseEvent("link_toggle_sharing", {
                        link_type: conversationType,
                        is_link_reset: true,
                        success: true,
                      });
                    }

                    return ConversationsApiActions.toggleInvitationAllowAllSuccess(
                      {
                        conversationId: action.conversationId,
                        invitation: response,
                      }
                    );
                  },
                  error: (err) => {
                    alertService.confirm(
                      "",
                      sharedService.STANDARD_ERROR_MESSAGE,
                      "Done",
                      "",
                      true
                    );
                    analyticsService.raiseEvent("link_toggle_sharing", {
                      link_type: conversationType,
                      success: false,
                    });

                    return ConversationsApiActions.toggleInvitationAllowAllError(
                      {
                        errors: [
                          {
                            code: "errorCodePlaceholder",
                            message: "errorMessagePlaceholder",
                          },
                        ],
                      }
                    );
                  },
                })
              );
          })
        );
      })
    );
  },
  {
    functional: true,
  }
);

const resetInvitation = createEffect(
  (
    action$ = inject(Actions),
    store = inject(Store),
    invitationsService = inject(InvitationsService),
    alertService = inject(AlertService),
    analyticsService = inject(AnalyticsService)
  ) => {
    return action$.pipe(
      ofType(ConversationsPageActions.resetInvitation),
      switchMap((action) => {
        return combineLatest([
          of(action),
          store.select(selectConversation(action.conversationId)),
        ]).pipe(first());
      }),
      exhaustMap(([action, conversation]) => {
        const invitationUri = conversation?.invitation?.uri;
        if (!invitationUri) return EMPTY;

        const invitationCode = getQueryParameter(invitationUri, "code");
        if (!invitationCode) return EMPTY;

        return alertService
          .customDialog(
            "Are you sure you want to reset the invite link for " +
              conversation.name +
              "?",
            "A new link will be generated and the old link will no longer work to join this " +
              conversation.type.toLocaleLowerCase() +
              ".",
            "RESET LINK",
            "CANCEL",
            false,
            "",
            ""
          )
          .afterClosed()
          .pipe(
            switchMap((result) => {
              if (!result) return EMPTY;
              return invitationsService.resetInvitation(invitationCode).pipe(
                mapResponse({
                  next: (response) => {
                    alertService.customDialog(
                      "Link has been reset",
                      "The previous invite link has been reset and a new invite link has been created.",
                      "OK",
                      "",
                      true,
                      "",
                      ""
                    );

                    analyticsService.raiseLinkResetEvents(
                      conversation.type.toLowerCase(),
                      true
                    );

                    return ConversationsApiActions.resetInvitationSuccess({
                      conversationId: action.conversationId,
                      resetInvitationModel: response,
                    });
                  },
                  error: (err) => {
                    const errorMessage =
                      "Something went wrong, please try again later";
                    alertService.confirm("", errorMessage, "Done", "", true);

                    analyticsService.raiseLinkResetEvents(
                      conversation.type.toLowerCase(),
                      false
                    );

                    return ConversationsApiActions.resetInvitationError({
                      errors: [
                        {
                          code: "errorCodePlaceholder",
                          message: "errorMessagePlaceholder",
                        },
                      ],
                    });
                  },
                })
              );
            })
          );
      })
    );
  },
  {
    functional: true,
  }
);

export const conversationsEffects = {
  loadUnreadConversations,
  loadInbox,
  loadInboxSearchResults,
  loadExternalInboxSearchResult,
  loadConversation,
  loadMessages,
  loadMoreMessages,
  readMessages,
  clearUnreadBadge,
  markConversationAsRead,
  pinConversation,
  unpinConversation,
  editConversationPhoto,
  removeConversationPhoto,
  editConversationDetails,
  muteConversation,
  unmuteConversation,
  messageOrVideoCallUpdate,
  sendMessagePreprocessing,
  sendMessage,
  deleteMessage,
  archiveConversation,
  playMessageSentSound,
  loadExternalInbox,
  createInvitation,
  toggleInvitationAllowAll,
  resetInvitation,
  loadRolesInbox,
  loadRolesInboxSearchResults,
  showNotification,
};
