import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { BasicUserPickerService } from "../basic-user-picker.service";

@Component({
  selector: "app-user-picker-selection-count",
  templateUrl: "./user-picker-selection-count.component.html",
  styleUrls: ["./user-picker-selection-count.component.scss"],
})
export class UserPickerSelectionCountComponent implements OnInit {
  @Input() public zero: string = "";
  @Input() public one: string = "";
  @Input() public plural: string = "";
  @Input() public countDisabled: boolean = false;

  public selectedUserCount!: Observable<number>;

  public constructor(private userPickerService: BasicUserPickerService) {}

  public ngOnInit(): void {
    this.selectedUserCount = this.userPickerService.selectedUsers$.pipe(
      map((users) => {
        if (this.countDisabled) {
          return users.length;
        }
        return users.filter((u) => !u.isDisabled).length;
      })
    );
  }
}
