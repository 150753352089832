import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { PictureSize } from "types";
import { AVATAR_SERVICE, AvatarServiceProvider, Badge } from "./../../core/services";

const badgeAssets: {
  [x in Badge]: string | null;
} = {
  [Badge.None]: null,
  [Badge.Unverified]: "../../../../assets/icons/ic-unverified.svg",
  [Badge.VerifiedWorkspace]:
    "../../../../assets/icons/ic-verified-workspace.svg",
  [Badge.VerifiedIdentity]: "../../../../assets/icons/ic-verified.svg",
  [Badge.VerifiedProfession]: "../../../../assets/icons/ic-verified-red.svg",
  [Badge.External]: "../../../../assets/icons/ic-external.svg",
};

@Component({
  selector: "app-basic-celo-avatar",
  templateUrl: "./basic-celo-avatar.component.html",
  styleUrls: ["./basic-celo-avatar.component.scss"],
})
export class BasicCeloAvatarComponent implements OnChanges {
  @Input() public name: string = "";
  @Input() public size: number = 24;
  @Input() public badgeSize: number = 17;
  @Input() public isWorkspaceVerified: boolean | null = null;
  @Input() public isIdentityVerified: boolean | null = null;
  @Input() public isProfessionVerified: boolean | null = null;
  @Input() public isExternal: boolean | null = null;
  @Input() public pictureSize: PictureSize = PictureSize.Small;

  /** If true, this component will try to fetch the given users's image. */
  @Input() public fetchImage: boolean = true;
  @Input() public userId: string | null = null;
  @Input() public workspaceId: string | null = null;

  @Input() public fallback: string | null = null;
  @Output() public imageError: EventEmitter<void> = new EventEmitter();

  public badgeSrc: string | null = null;
  public src: Observable<SafeUrl> | null = null;

  public constructor(@Inject(AVATAR_SERVICE) private avatarService: AvatarServiceProvider) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["userId"] || changes["workspaceId"]) {
      this.updateAvatarSrc();
    }

    if (
      changes["isWorkspaceVerified"] ||
      changes["isIdentityVerified"] ||
      changes["isProfessionVerified"] ||
      changes["isExternal"]
    ) {
      this.updateBadgeSrc();
    }
  }

  private updateBadgeSrc() {
    let badge: Badge = Badge.None;
    if (this.userId || this.isExternal) {
      badge = this.avatarService.getBadgeForUserVerificationState({
        isWorkspaceVerified: this.isWorkspaceVerified,
        isIdentityVerified: this.isIdentityVerified,
        isProfessionVerified: this.isProfessionVerified,
        isExternal: this.isExternal,
      });
    } else if (this.workspaceId && this.isWorkspaceVerified) {
      badge = this.avatarService.getBadgeForWorkspaceVerificationState(
        this.isWorkspaceVerified
      );
    }
    this.badgeSrc = badgeAssets[badge];
  }

  private updateAvatarSrc() {
    if (!this.fetchImage) return;
    if (this.userId) {
      this.src = this.avatarService.getUserAvatar({
        userId: this.userId,
        size: this.pictureSize,
        width: this.size,
        height: this.size,
      });
    } else if (this.workspaceId) {
      this.src = this.avatarService.getWorkspaceAvatar({
        workspaceId: this.workspaceId,
        size: this.pictureSize,
      });
    } else {
      this.src = null;
    }
  }

  public onError() {
    this.imageError.emit();
  }
}
