import { Component, Input } from "@angular/core";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent {
  @Input() title: string | null = null;
  @Input() content: string | null = null;
  @Input() confirmOnly = false;
  confirmButtonText = "Confirm";
  cancelButtonText = "Cancel";
}
