<div
  *ngFor="let user of users; trackBy: userTrackBy"
  id="card-container"
  [class.celo-hoverable]="!hoverDisabled"
  matRipple
  [matRippleDisabled]="rippleDisabled"
>
  <app-basic-user-avatar-card
    [name]="user.name"
    [suffix]="user.suffix ? user.suffix : null"
    [description]="showDescription ? user.description : null"
    [userId]="user.id"
    [isIdentityVerified]="user.isIdentityVerified"
    [isWorkspaceVerified]="user.isWorkspaceVerified"
    [isProfessionVerified]="user.isProfessionVerified"
    [fetchImage]="user.fetchImage || user.fetchImage === undefined"
    [isTeam]="user.isTeam"
    [leftContentMargin]="leftContentMarger"
    (click)="handleUserClick(user)"
  >
    <ng-container start *ngIf="multiple || radio">
      <app-basic-celo-checkbox
        [checked]="user.isSelected"
        [disabled]="user.isDisabled"
        [radio]="radio"
      ></app-basic-celo-checkbox>
    </ng-container>

    <ng-container
      *ngIf="
        selectedContent &&
        (alwaysShowSelectedContent || user.isSelected) &&
        !user.isDisabled
      "
      end
      [ngTemplateOutlet]="selectedContent.templateRef"
    ></ng-container>
  </app-basic-user-avatar-card>
</div>
