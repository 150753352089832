import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertComponent } from "@modules/core/components";
import { Store } from "@ngrx/store";
import { ConversationsPageActions, selectConversation } from "app/state";

const acceptedFileTypes = ["image/png", "image/jpg", "image/jpeg"];

@Component({
  selector: "app-conversation-picture",
  templateUrl: "./conversation-picture.component.html",
  styleUrls: ["./conversation-picture.component.scss"],
})
export class ConversationPictureComponent implements OnInit {
  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;
  @Input() conversationId: string;

  public pictureUri: string = "";

  constructor(
    private matDialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.store.select(selectConversation(this.conversationId)).subscribe({
      next: (conversation) => {
        this.pictureUri = conversation.profilePictureUri;
      },
    });
  }

  createEditDialog(): void {
    this.fileInput.nativeElement.click();
  }

  removePhoto(): void {
    this.store.dispatch(
      ConversationsPageActions.removeConversationPhoto({
        conversationId: this.conversationId,
      })
    );
  }

  async handleFileUpload(): Promise<void> {
    let files = this.fileInput.nativeElement.files;
    let file = files.item(0);

    if (!!file) {
      if (!acceptedFileTypes.includes(file.type)) {
        const ref = AlertComponent.openDialog(this.matDialog, {
          title: "Image Not Supported",
          message:
            "At this time we do not support direct upload of some of the files in your selection.",
          acceptButtonText: "Close",
          closeButtonText: "Cancel",
          acceptOnly: true,
        });

        return;
      }

      this.store.dispatch(
        ConversationsPageActions.editConversationPhoto({
          conversationId: this.conversationId,
          file,
        })
      );

      this.fileInput.nativeElement.value = null;
    }
  }
}
