import { Pipe, PipeTransform } from "@angular/core";
import { AsYouType } from "libphonenumber-js";

@Pipe({
  name: "phoneFormat",
})
export class PhoneFormatPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value !== "string") return null;
    return new AsYouType().input(value);
  }
}
