import { Injectable } from "@angular/core";
import {
  ViewMyWorkplaceIntegrationDetailModel,
  ViewMyWorkplaceIntegrationModel,
} from "@types";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class IntegrationService {
  public constructor(private apiService: ApiService) {}

  public getIntegrations(
    type: string
  ): Observable<ViewMyWorkplaceIntegrationModel[]> {
    const path = `/api/Integrations`;
    return this.apiService.get({
      path,
      queryParams: { type },
    });
  }

  public getIntegration(
    id: string,
    companyId: string | null | undefined = null
  ): Observable<ViewMyWorkplaceIntegrationDetailModel> {
    const path = `/api/Integrations/${id}`;
    const queryParams: Record<string, string> = {};
    if (companyId) queryParams.companyId = companyId;
    return this.apiService.get({
      path,
      queryParams: queryParams,
    });
  }
}
