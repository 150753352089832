<div
  *ngFor="let participant of participantsList; let index = index"
  class="celo-cursor-pointer usercard"
  (mousedown)="handleMouseDown($event)"
  (click)="handleClick($event, participant)"
  [ngClass]="{ 'celo-hovered': index === selectedIndex }"
>
  <div [id]="'selectionAnchor' + index" class="selection-anchor"></div>
  <app-celo-user-card
    [userId]="participant.userId"
    [title]="participant.title"
    [firstName]="participant.firstName"
    [lastName]="participant.lastName"
    [profilePic]="participant.profilePicture"
    [showBadge]="false"
    [contact]="participant"
    [profileId]="userId"
    [replaceMyName]="
      participant.firstName + ' ' + participant.lastName + ' (You)'
    "
  >
  </app-celo-user-card>
</div>
