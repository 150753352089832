import { OncallToggleButtonComponent } from "./oncall-toggle-button/oncall-toggle-button.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { RouterModule, Routes } from "@angular/router";
import { CeloMaterialModule } from "app/celo-material/celo-material.module";
import { FileUploadModule } from "ng2-file-upload";
import { SharedModule } from "../shared/shared.module";
import { CompaniesListComponent } from "./companies-list/companies-list.component";
import { EditCompanyComponent } from "./edit-company/edit-company.component";
import { EditProfilePicComponent } from "./edit-profile-pic/edit-profile-pic.component";
import { EditSettingsComponent } from "./edit-settings/edit-settings.component";
import { InfoLineComponent } from "./info-line/info-line.component";
import { ProfileDialogComponent } from "./profile-dialog/profile-dialog.component";
import { ProfileInvitationComponent } from "./profile-invitation/profile-invitation.component";
import {
  EmailChangeDialogComponent,
  EmailCodeDialogComponent,
  EmailVerifiedDialogComponent,
  PhoneChangeDialogComponent,
  PhoneCodeDialogComponent,
  PhoneVerifiedDialogComponent,
  ProfilePageComponent,
} from "./profile-page/profile-page.component";
import { ProfileComponent } from "./profile/profile.component";
import { ProfileRolesListComponent } from "./profile-roles-list/profile-roles-list.component";
import { AddCompanyDetailsComponent } from "@modules/shared/add-company-details/add-company-details.component";

const routes: Routes = [
  {
    path: "",
    component: ProfilePageComponent,
  },
];

@NgModule({
  declarations: [
    ProfileComponent,
    CompaniesListComponent,
    EditCompanyComponent,
    EditProfilePicComponent,
    EditSettingsComponent,
    InfoLineComponent,
    ProfileDialogComponent,
    ProfileInvitationComponent,
    ProfilePageComponent,
    PhoneChangeDialogComponent,
    PhoneCodeDialogComponent,
    PhoneVerifiedDialogComponent,
    EmailChangeDialogComponent,
    EmailCodeDialogComponent,
    EmailVerifiedDialogComponent,
    ProfileRolesListComponent,
    OncallToggleButtonComponent,
  ],
  exports: [
    ProfileComponent,
    CompaniesListComponent,
    EditCompanyComponent,
    EditProfilePicComponent,
    EditSettingsComponent,
    InfoLineComponent,
    ProfileDialogComponent,
    ProfileInvitationComponent,
    ProfilePageComponent,
  ],
  imports: [
    CommonModule,
    CeloMaterialModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    SharedModule,
    FileUploadModule,
  ],
})
export class ProfileModule {}
