<div [matMenuTriggerFor]="menu"></div>
<mat-menu #menu="matMenu">
  <div class="menu-container">
    <button mat-menu-item *ngFor="let team of teams$ | async; trackBy: teamTrackBy" class="option"
      (click)="handleTeamClicked(team)">
      <div class="content-wrapper">
        <div class="start-content">
          <div class="mat-body-strong truncate">{{ team.name }}</div>
          <div class="mat-caption description">
            <app-basic-celo-avatar *ngIf="team.memberOnCall?.user?.userId" [userId]="team.memberOnCall.user.userId"
              [name]="team.memberOnCall?.user?.fullName" [size]="12"></app-basic-celo-avatar>
            <div class="truncate">
              {{
              team.isCurrentUserOnCall
              ? "You are on call"
              : team.memberOnCall?.user?.firstName
              ? team.memberOnCall.user.firstName + " is on call"
              : "No one is clocked in"
              }}
            </div>
          </div>
        </div>
        <div class="end-content" *ngIf="team.unreadConversationIds?.length">
          {{ team.unreadConversationIds.length }}
        </div>
      </div>
    </button>
  </div>
</mat-menu>