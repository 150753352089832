import { Pipe, PipeTransform } from "@angular/core";
import { SharedService } from "../../core";

@Pipe({
  name: "countryFromCode",
  pure: false,
})
export class CountryFromCodePipe implements PipeTransform {
  countries = [];
  value: string;
  constructor(private sharedService: SharedService) {
    const instance = this;
    this.sharedService.getSupportedCountries(false, function (countries) {
      instance.countries = countries;
    });
  }

  transform(value: string, args?: any): any {
    let name = "-";
    this.value = value;
    value = value.toLowerCase();
    for (let index = 0; index < this.countries.length; index++) {
      const country = this.countries[index];
      if (country["shortName"] == value) {
        name = country["name"];
        return name;
      }
    }
    return name;
  }
}
