import { Component, Input, HostBinding } from "@angular/core";

@Component({
  selector: "app-basic-user-statuses",
  templateUrl: "./basic-user-statuses.component.html",
  styleUrls: ["./basic-user-statuses.component.scss"],
})
export class BasicUserStatusesComponent {
  @Input() public isOnCall: boolean = false;
  @Input() public isAdmin: boolean = false;
  @Input() public onCallColor: string | null = null;

  @HostBinding("style.alignItems")
  @Input()
  public alignItems: string | null = null;
}
