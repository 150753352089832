import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { NewChatComponent } from "@modules/conversations/new-conversations/new-chat/new-chat.component";
import { Contact } from "../../modules/conversations/new-conversations/contacts-expansion-panel/contacts-expansion-panel.component";
import { AlertService, SharedService } from "../../modules/core";

@Component({
  selector: "app-department-members-list",
  templateUrl: "./department-members-list.component.html",
  styleUrls: ["./department-members-list.component.scss"],
})
export class DepartmentMembersListComponent implements OnChanges {
  @Input() companyId: string;
  @Input() userAccount: any;
  @Input() departmentName: string;
  @Input() departmentId: string;
  @Input() loading: boolean;
  @Input() refreshCount: number;
  @Output() removeMember = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCallChange = new EventEmitter();
  @Output() membersToAdd = new EventEmitter();
  @Input() list: Contact[];
  listUserIds: any[];

  query = "";

  dataSource: MatTableDataSource<any>;
  // shouldSort=true;

  displayedColumns: string[] = ["firstName", "isOnCall", "action"];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;

  members = [];

  constructor(
    private dialog: MatDialog,
    public alertService: AlertService,
    public sharedService: SharedService
  ) {}

  ngOnChanges() {
    if (this.list) {
      this.setDataToTable(this.list);
      this.listUserIds = this.list.map((property) => property.userId);
    }
    if (this.query) {
      this.applyFilter();
    }
  }

  setDataToTable(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    // setTimeout(() => {
    //   // this.dataSource.paginator = this.paginator;
    //   this.dataSource.sort = this.sort;
    // }, 200);

    // this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
    //   if(data[sortHeaderId])
    //     return data[sortHeaderId].toLocaleLowerCase()
    // };
  }

  remove(member: any) {
    this.removeMember.emit(member);
  }

  toggleOnCall(member: any) {
    this.onCallChange.emit(member);
  }

  applyFilter() {
    this.dataSource.filter = this.query.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  pickUsers() {
    if (!this.departmentId) {
      this.alertService.confirm(
        "No Department",
        "Sorry, you do not belong to a department."
      );
      return;
    }

    NewChatComponent.openDepartmentAdminAddUsersDialog(
      this.dialog,
      this.companyId,
      this.listUserIds
    )
      .afterClosed()
      .subscribe((res) => {
        if (!res || !res.length) return;
        this.membersToAdd.emit(res);
      });
  }
}
