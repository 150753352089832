import { TeamsService } from "../../core/services/teams.service";
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Team } from "types";

@Component({
  selector: "app-roles-list-item",
  templateUrl: "./roles-list-item.component.html",
  styleUrls: ["./roles-list-item.component.scss"],
})
export class RolesListItemComponent implements OnInit, OnChanges {
  @Input() public team: Team | null = null;
  @Input() public padding: string | null = null;
  @Input() public avatarBackgroundSize: string = "40px";
  @Input() public avatarSize: string = "32px";
  public isOnCall: boolean = false;

  @Output() private role: EventEmitter<Team> = new EventEmitter();

  constructor(private teamsService: TeamsService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["team"]) {
      this.updateOnCallStatus();
    }
  }

  public ngOnInit(): void {
    this.updateOnCallStatus();
  }

  public onClick() {
    if (!this.team) return;
    this.role.emit(this.team);
  }

  private updateOnCallStatus() {
    if (!this.team) return;
    this.isOnCall = this.teamsService.isAnyMemberOnCall(this.team);
  }
}
