<div class="onboard">
  <div class="content">
    <div class="contents">
      <img class="logo" src="../../../assets/logo-v2.svg" alt="" />
      <img class="cover" src="../../../assets/country-select.svg" alt="" />
      <div class="title">Select your location</div>
      <div class="subtitle">
        Please select the location you currently practice in.
      </div>
      <app-celo-countries
        [required]="true"
        (selected)="country = $event; onSelected($event)"
      ></app-celo-countries>
      <div class="subtitle">
        By continuing, you agree to use Celo in line with our
        <a
          (click)="tAndCEvent()"
          target="_blank"
          href="https://www.celohealth.com/legal"
          class="celo-link nowrap"
          >Terms and Conditions of Use</a
        >.
      </div>
      <div class="buttons">
        <a class="button" [routerLink]="['/']">
          <button mat-button class="celo-secondary-button">BACK</button>
        </a>
        <a
          class="button"
          [ngClass]="{ 'no-pointer-events': !country['shortName'] }"
          [routerLink]="['/onboard/email']"
          [queryParams]="{
            country_code: country['shortName'],
            country_name: country['name'],
            country_is_available: country['isAvailable'],
            data_region_id: country['dataRegionId']
              ? country['dataRegionId']
              : defaultOnboardingRegion
          }"
        >
          <button
            mat-button
            class="celo-primary-button"
            [disabled]="!country['shortName']"
            (click)="submit()"
          >
            NEXT
          </button>
        </a>
      </div>
    </div>
  </div>
</div>
