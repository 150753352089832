import { Component, Input } from "@angular/core";
import { SharedService } from "../../../core";

@Component({
  selector: "app-verify-identity-info",
  templateUrl: "./verify-identity-info.component.html",
  styleUrls: ["./verify-identity-info.component.scss"],
})
export class VerifyIdentityInfoComponent {
  apps = [];
  @Input() message = "";

  constructor(private sharedService: SharedService) {
    this.apps = sharedService.apps;
  }
}
