import { Component, OnInit, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-mute-menu",
  templateUrl: "./mute-menu.component.html",
  styleUrls: ["./mute-menu.component.scss"],
})
export class MuteMenuComponent implements OnInit {
  @Input() configData;
  @Input() action = "Mute";
  @Input() title = "";
  @Input() subtitle = "";
  selected: any;

  constructor(private ref: MatDialogRef<MuteMenuComponent>) {}

  ngOnInit() {
    this.selected = this.configData["default"];
  }

  onNoClick(): void {
    this.ref.close();
  }
}
