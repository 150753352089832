<div
  *ngIf="!showParticipantProfile && (type === 'Case' || type === 'Group')"
  class="parent"
>
  <div class="contents">
    <div *ngIf="type === 'Group' && isInConversation" class="picture">
      <app-conversation-picture [leftConversation]="!isInConversation"
        [conversationId]="conversation.id"></app-conversation-picture>
    </div>
    <div *ngIf="type === 'Group' && !isInConversation" class="picture">
      <app-celo-avatar
        [width]="120"
        [height]="120"
        [type]="type"
        [participant]="participantToShow ? participantToShow : ''"
        [showBadge]="participantToShow?.isExternal ? false : true"
      />
    </div>

    <form #form1="ngForm" class="name-field">
      <div class="celo-input-outline">
        <mat-form-field appearance="outline">
          <mat-label>{{ type }} name</mat-label>
          <input
            [disabled]="leftChat"
            required
            matInput
            [placeholder]="type + ' name'"
            name="name"
            #name="ngModel"
            [(ngModel)]="cName"
            pattern="\s*\S+.*"
            autocomplete="off"
            maxlength="200"
          />
        </mat-form-field>
      </div>
      <div class="mt-2">
        <app-celo-cancel-save-buttons
          (cancel)="
            cName = conversation.name;
            cSubject = conversation.subject;
            form1.form.markAsPristine()
          "
          (save)="updateConvo()"
          [showNow]="form1.dirty && !saving && cName && name.valid"
        >
        </app-celo-cancel-save-buttons>
      </div>
    </form>

    <div *ngIf="!leftChat && userAccount" class="mt-4 mb-4 settings">
      <div class="section-title">{{ type }} settings</div>
      <div class="button">
        <button
          mat-button
          class="celo-tertiary-button celo-hoverable fw celo-extended-button"
          (click)="onAddParticipants()"
        >
          <mat-icon svgIcon="add-filled"></mat-icon>
          <span class="text">Add participants</span>
          <!-- <i class="material-icons-round arrow">navigate_next</i> -->
        </button>
      </div>
      <div *ngIf="conversation.invitation" class="button">
        <button
          mat-button
          class="celo-tertiary-button celo-hoverable fw celo-extended-button"
          (click)="
            isAdmin.emit(activeAdminList.indexOf(userAccount.userId) !== -1);
            openWindow.emit('invite')
          "
        >
          <mat-icon *ngIf="type === 'Group'" svgIcon="ic-group"></mat-icon>
          <mat-icon *ngIf="type === 'Case'" svgIcon="ic-case"></mat-icon>
          <span class="text"
            >Invite to<span class="lowercase">{{ type }}</span></span
          >
        </button>
      </div>
      <div class="button" *ngIf="type === 'Case'">
        <button
          mat-button
          class="celo-tertiary-button celo-hoverable fw celo-extended-button"
          (click)="onExportToPdf()"
        >
          <i class="material-icons ml-2">file_download</i>
          <span class="text">Export to PDF</span>
        </button>
      </div>
    </div>
    <div *ngIf="type === 'Case'" class="mt-4 mb-4">
      <div class="section-title">Patient Details</div>
      <div #patientInfo class="patient-section">
        <app-edit-patient
          [patient]="patient"
          [alignment]="'vertical'"
          [canSave]="true"
          [editMode]="!leftChat"
          [optionalId]="true"
          (patientSave)="updateConvo($event)"
        >
        </app-edit-patient>
      </div>
    </div>
    <div class="mt-4 mb-4">
      <div class="section-title">Participants</div>
      <mat-list *ngIf="userAccount && participants && !isConversationUpdating">
        <div *ngIf="participants.length">
          <div
            *ngFor="let participant of participants; let last = last"
            class="celo-hoverable"
          >
            <div
              [ngClass]="{
                'celo-cursor-pointer': userAccount.userId !== participant.userId
              }"
              class="appear_1"
            >
              <app-celo-participant-card
                [isAdmin]="activeAdminList.indexOf(participant.userId) !== -1"
                [profileId]="userAccount.userId"
                [replaceMyName]="
                  userAccount.firstName + ' ' + userAccount.lastName + ' (You)'
                "
                [participant]="participant"
                [hideWorkspace]="true"
                [hideProfession]="false"
                [userAccount]="userAccount"
                [conversation]="conversation"
                [adminIds]="activeAdminList"
                [type]="type"
                (showProfile)="showProfile($event)"
                (remove)="remove(participant.userId, participant.firstName)"
              >
              </app-celo-participant-card>
            </div>
          </div>
        </div>
        <div *ngIf="!participants.length" class="subtitle">No participants</div>
      </mat-list>
      <div *ngIf="!userAccount || !participants || isConversationUpdating">
        <app-empty-state
          [no_message]="true"
          [isLoading]="true"
          [spinner]="true"
          [animation]="false"
          [diameter]="25"
        ></app-empty-state>
      </div>
    </div>

    <div *ngIf="!leftChat" class="leave-button celo-hoverable mt-2">
      <button mat-button (click)="leaveConvo(); closeClick.emit()" class="fw">
        <span
          >Leave <span class="lowercase">{{ type }}</span></span
        >
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="
    showParticipantProfile ||
    type === 'Chat' ||
    type === 'SelfChat' ||
    type === 'UserProfile' ||
    type === 'TeamChat'
  "
>
  <app-profile
    [contactId]="profileInFocus"
    [userAccount]="userAccount"
    [hideSecureMessageButton]="hideSecureMessageButton"
    (userDataChanged)="userDataChanged.emit()"
  >
  </app-profile>
</div>
