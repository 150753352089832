import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";
import { ParticipantModel } from "types";

export enum KEY_CODE {
  UP_ARROW = 38,
  DOWN_ARROW = 40,
  RETURN = 13,
  TAB = 9,
}
const NAV_KEY_CODES = [KEY_CODE.UP_ARROW, KEY_CODE.DOWN_ARROW];
const SELECTION_KEY_CODES = [KEY_CODE.RETURN, KEY_CODE.TAB];

@Component({
  selector: "app-participant-list",
  templateUrl: "./participant-list.component.html",
  styleUrls: ["./participant-list.component.scss"],
})
export class ParticipantListComponent implements OnChanges {
  @Input() participantsList: ParticipantModel[];

  @Input() userId: string;

  @Input() selectable = false;
  // To force ngOnChanges
  @Input() searchQuery: string;

  @Input()
  public preventFocus: boolean = false;

  selectedIndex: number = 0;
  @Output() newMentionClicked = new EventEmitter<ParticipantModel>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["searchQuery"]) {
      this.selectedIndex = 0;
      this.scrollToSelectedItem();
    }
  }

  public handleMouseDown(event: MouseEvent) {
    if (!this.preventFocus) return;
    event.preventDefault();
  }

  public handleClick(event: MouseEvent, participant: ParticipantModel) {
    this.participantSelected(participant);
  }

  participantSelected = (participant) => {
    this.newMentionClicked.emit(participant);
  };

  @HostListener("window:keydown", ["$event"])
  keyDownEvent(event: KeyboardEvent) {
    if (NAV_KEY_CODES.indexOf(event.keyCode) != -1) {
      event.preventDefault();
      if (event.keyCode == KEY_CODE.UP_ARROW) {
        this.selectedIndex--;
        this.selectedIndex = this.selectedIndex < 0 ? 0 : this.selectedIndex;
      } else if (event.keyCode == KEY_CODE.DOWN_ARROW) {
        this.selectedIndex++;
        this.selectedIndex =
          this.selectedIndex >= this.participantsList.length
            ? this.participantsList.length - 1
            : this.selectedIndex;
      }
      this.scrollToSelectedItem();
    }
  }

  @HostListener("window:keyup", ["$event"])
  keyUpEvent(event: KeyboardEvent) {
    if (SELECTION_KEY_CODES.indexOf(event.keyCode) != -1) {
      event.preventDefault();
      this.participantSelected(this.participantsList[this.selectedIndex]);
    }
  }

  scrollToSelectedItem() {
    const anchor = document.getElementById(
      "selectionAnchor" + this.selectedIndex
    ) as HTMLElement;
    if (!anchor) return;
    this.scrollTo(anchor);
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
