import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { createDialogConfig } from "@utils";
import { Team } from "types";
import { RoleDetailsFormComponent } from "./../role-details-form/role-details-form.component";

export interface EditRoleDetailsDialogData {
  team: Team;
}

export interface EditRoleDetailsDialogResult {
  team: Team;
}

@Component({
  selector: "app-edit-role-details-dialog",
  templateUrl: "./edit-role-details-dialog.component.html",
  styleUrls: ["./edit-role-details-dialog.component.scss"],
})
export class EditRoleDetailsDialogComponent implements OnInit {
  public team: Team | null = null;

  @ViewChild("form") public roleDetailsForm: RoleDetailsFormComponent | null =
    null;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: EditRoleDetailsDialogData,
    private matDialogRef: MatDialogRef<
      EditRoleDetailsDialogComponent,
      EditRoleDetailsDialogResult
    >
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: EditRoleDetailsDialogData
  ): MatDialogRef<EditRoleDetailsDialogComponent, EditRoleDetailsDialogResult> {
    const config = createDialogConfig<EditRoleDetailsDialogData>(data);
    return matDialog.open<
      EditRoleDetailsDialogComponent,
      EditRoleDetailsDialogData,
      EditRoleDetailsDialogResult
    >(EditRoleDetailsDialogComponent, config);
  }

  public ngOnInit() {
    this.team = this.dialogData.team;
  }

  public handleSave(team: Team) {
    this.matDialogRef.close({
      team,
    });
  }
}
