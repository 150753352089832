// This file contains type predicates that can be used for type narrowing with typescript.
//
// See https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates for info on type narrowing.

import { HttpErrorResponse } from "@angular/common/http";
import { ErrorResponse } from "@types";

export const isNullOrUndefined = (
  value?: unknown
): value is null | undefined => {
  return value === null || value === undefined;
};

export const isNotNullOrUndefined = <T>(value?: T | null): value is T => {
  return !isNullOrUndefined(value);
};

export const isHttpErrorResponse = (
  value: unknown
): value is HttpErrorResponse => {
  return value instanceof HttpErrorResponse;
};

export const isCeloErrorResponse = (value: unknown): value is ErrorResponse => {
  if (typeof value !== "object") return false;
  if (Array.isArray(value)) return false;
  const errors: unknown = value["errors"];
  if (!Array.isArray(errors)) return false;
  return (errors as unknown[]).every((e) => {
    return typeof e["code"] === "string" && typeof e["message"] === "string";
  });
};
