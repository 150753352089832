<div #anchor class="anchor" [attr.data-marker]="message.marker"></div>

<div
  class="message-column"
  [ngClass]="{
    'from-system': fromSystem,
    'from-me': fromMe,
    'from-participant': fromSender
  }"
>
  <div [ngSwitch]="true" [ngClass]="{ 'first-of-group': isFirstOfGroup }">
    <div *ngSwitchCase="isWelcomeMessage" class="welcome">
      {{ message.content }}
    </div>

    <div *ngSwitchCase="isWelcomeMessage2" class="welcome smaller-width">
      {{ message.content }}
    </div>

    <!-- System -->
    <div
      *ngSwitchCase="isSystemMessage"
      class="system"
      [ngClass]="{
        'system-patient':
          message.type === 'UidMismatch' ||
          message.type === 'PatientDataChanged'
      }"
    >
      {{ message.content }}
    </div>

    <!-- Standard Message -->
    <div
      *ngIf="
        isStandardMessage ||
        isImageMessage ||
        isVideoMessage ||
        isPatientFileMessage ||
        isVideoCallMessage
      "
      class="standard-message-container standard-box"
    >
      <div class="standard-message-container-inside">
        <app-message-forwarded-label
          *ngIf="isForwarded"
          [class.patientFile]="isPatientFileMessage"
        ></app-message-forwarded-label>

        <!-- Text -->
        <div
          *ngIf="isStandardMessage"
          class="text-message-container"
          [class.forwarded]="isForwarded"
        >
          <div
            *ngIf="message.replyToMessage && !message.deletedOnUtc"
            class="pre-wrap quote"
          >
            <app-quoted-message
              [message]="message.replyToMessage"
              [fromMe]="fromMe"
              [fromSender]="fromSender"
              [userAccount]="userAccount"
              [participantAccount]="pMap[message.replyToMessage.sentBy]"
              (findChat)="findChat(message.replyToMessage)"
              [collapsible]="false"
            ></app-quoted-message>
          </div>
          <div
            (click)="clicked($event)"
            class="pre-wrap my-text"
            [ngClass]="{
              emoji: message.isEmoji,
              deleted: !!message.deletedOnUtc
            }"
          >
            <span *ngFor="let block of messageLogicalBlocks">
              <span
                *ngIf="block.type === 'mention'"
                class="mentioned-message"
                (click)="
                  $event.stopPropagation(); handleMentionClicked(block.mention)
                "
                >{{ block.content }}</span
              >
              <span *ngIf="block.type === 'text'">{{ block.content }}</span>
              <a
                *ngIf="block.type === 'link'"
                class="link"
                (click)="
                  handleLinkClick($event, block.link); $event.stopPropagation()
                "
                target="_blank"
                [href]="block.link"
                >{{ block.content }}</a
              >
            </span>
          </div>
        </div>

        <!-- File -->
        <div *ngIf="isPatientFileMessage">
          <div
            class="patient-file-container celo-cursor-pointer"
            (click)="
              viewFile(message.metadata.fileId); $event.stopPropagation()
            "
          >
            <div class="icon">
              <mat-icon>picture_as_pdf</mat-icon>
            </div>
            <div class="info">
              <div class="name celo-elipsis">
                {{
                  message.metadata.fileName
                    ? message.metadata.fileName
                    : "No Filename"
                }}
              </div>
              <div class="description celo-elipsis">
                {{
                  message.metadata.fileDescription
                    ? message.metadata.fileDescription
                    : "No description"
                }}
              </div>
              <div class="action celo-elipsis">Click to open</div>
            </div>
          </div>
        </div>

        <!-- Image -->
        <div *ngIf="isImageMessage" class="img-message" [ngClass]="pic_size">
          <img
            [src]="
              '/api/v2/media/' +
                messageOriginal.metadata.photoId +
                '?size=Medium&conversationId=' +
                conversationId +
                '&messageId=' +
                message.marker
                | clink
                | secure
            "
            (load)="is_image_loaded = true"
            class="appear-m"
            [ngClass]="{ 'appear-now': is_image_loaded }"
            (click)="
              viewPhoto(messageOriginal.metadata.photoId);
              $event.stopPropagation()
            "
          />

          <div
            *ngIf="
              patientId &&
              message.metadata &&
              message.metadata.uid &&
              message.metadata.uid !== patientId
            "
            class="mismatch_template"
          >
            <div class="mismatch_content">
              <div class="icon">
                <i class="material-icons">error</i>
              </div>
              <div class="text">Patient ID Mismatch</div>
            </div>
          </div>
        </div>

        <!-- Video (non-call) -->
        <div *ngIf="isVideoMessage" class="img-message" [ngClass]="pic_size">
          <img
            [src]="
              '/api/v2/media/' +
                messageOriginal.metadata.photoId +
                '?size=Medium&conversationId=' +
                conversationId +
                '&messageId=' +
                message.marker
                | clink
                | secure
            "
            (load)="is_image_loaded = true"
            class="appear-m"
            [ngClass]="{ 'appear-now': is_image_loaded }"
            (click)="
              viewVideo(messageOriginal.metadata.photoId);
              $event.stopPropagation()
            "
          />

          <div class="video_template">
            <div class="video_content">
              <div class="icon">
                <i class="material-icons">play_circle</i>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              patientId &&
              message.metadata &&
              message.metadata.uid &&
              message.metadata.uid !== patientId
            "
            class="mismatch_template"
          >
            <div class="mismatch_content">
              <div class="icon">
                <i class="material-icons">error</i>
              </div>
              <div class="text">Patient ID Mismatch</div>
            </div>
          </div>
        </div>
        <div
          *ngIf="isImageMessage || isVideoMessage"
          [ngClass]="pic_size + '_caption'"
        >
          <div class="media-captian">
            <div
              (click)="clicked($event)"
              class="pre-wrap my-text"
              [ngClass]="{ emoji: message.isEmoji }"
            >
              <span *ngFor="let block of messageLogicalBlocks">
                <span
                  *ngIf="block.type === 'mention'"
                  class="mentioned-message"
                  (click)="
                    $event.stopPropagation();
                    handleMentionClicked(block.mention)
                  "
                  >{{ block.content }}</span
                >
                <span *ngIf="block.type === 'text'">{{ block.content }}</span>
                <a
                  *ngIf="block.type === 'link'"
                  class="link"
                  (click)="
                    handleLinkClick($event, block.content);
                    $event.stopPropagation()
                  "
                  target="_blank"
                  [href]="block.link"
                  >{{ block.content }}</a
                >
              </span>
            </div>
          </div>
        </div>

        <!-- Video Call -->
        <div *ngIf="isVideoCallMessage">
          <div
            class="video-call-container"
            [ngClass]="{
              'celo-cursor-pointer': isVideoCallInProgress,
              actionable: isVideoCallInProgress
            }"
            (click)="joinVideoCall($event)"
          >
            <div class="icon">
              <mat-icon svgIcon="video-on"></mat-icon>
            </div>
            <div class="info">
              <div class="title celo-elipsis">
                {{ message.content }}
              </div>
              <div *ngIf="isVideoCallInProgress" class="action celo-elipsis">
                Click to join
              </div>
              <div *ngIf="callDurationMessage" class="description celo-elipsis">
                {{ callDurationMessage }}
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="message.deletedOnUtc" class="time-stamp"></div>

        <div
          *ngIf="!message.deletedOnUtc"
          class="time-stamp"
          [matTooltip]="message.sentOnUtc | dateFormat: 'medium'"
        >
          {{ message.sentOnUtc | dateFormat: "shortTime" }}
        </div>
      </div>
    </div>
  </div>
</div>
