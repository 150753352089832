import { formatDate } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { saveAs } from "file-saver";
import * as JSZip from "jszip";
import { forkJoin, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SharedService } from "@modules/core";

@Injectable({
  providedIn: "root",
})
export class ZipFilesService {
  constructor(private sharedService: SharedService, private http: HttpClient) {}

  downloadZip(items: { url: string; fileName: string; pp?: any }[]) {
    forkJoin(
      items.map((item) =>
        this.http.get(item.url, { responseType: "blob" }).pipe(
          switchMap((blob: any) => {
            if (item.pp) {
              return <any>item.pp(blob);
            }
            return <any>of(blob);
          }),
          map((blob) => ({
            blob,
            fileName: item.fileName,
          }))
        )
      )
    ).subscribe((blobItems: { blob; fileName }[]) => {
      this.sharedService.isLoading = true;
      const zip = new JSZip();
      const dateStamp = formatDate(new Date(), "HH-mm - d MMM yy", "en");
      const folderName = `Celo export at ${dateStamp}`;
      const img = zip.folder(folderName);
      let uniqueID = 0;
      for (const blobItem of blobItems) {
        let filename;
        filename = blobItem.fileName;
        if (blobItems.length > 1) {
          filename = `${uniqueID}_${blobItem.fileName}`;
        }
        img.file(filename, blobItem.blob);
        uniqueID += 1;
      }
      zip.generateAsync({ type: "blob" }).then((content) => {
        // see FileSaver.js
        this.sharedService.isLoading = false;
        saveAs(content, `${folderName}.zip`);
      });
    });
  }

  createFileName(dateString: string, fileExtension: string, patientData?: any) {
    // If patient info:
    //   Patient ID_FirstName_Lastname_Time/Date
    // If no patient info
    // Time/Date
    const parts = [];
    if (patientData) {
      if (patientData.uid) {
        parts.push(patientData.uid);
      }
      if (patientData.firstName) {
        parts.push(patientData.firstName);
      }
      if (patientData.lastName) {
        parts.push(patientData.lastName);
      }
    }
    const dateStamp = formatDate(dateString, "HH-mm - d MMM yy", "en");
    parts.push(dateStamp);
    return `${parts.join("_")}.${fileExtension}`;
  }

  createFileNameFromFileObject(
    fileName: string,
    fileExtension: string,
    patientData?: any
  ) {
    // If patient info:
    //   Filename_Patient ID
    // If no patient info
    // Filename
    const parts = [];
    if (fileName) {
      parts.push(fileName);
    }
    if (patientData && patientData.uid) {
      parts.push(patientData.uid);
    }
    return `${parts.join("_")}.${fileExtension}`;
  }

  fixFileName(fileName, altExt) {
    if (fileName.indexOf(".") === -1) {
      fileName = fileName + "." + altExt;
    }
    return fileName;
  }
}
