import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SYSTEM_USER_ID } from "@common";
import { VoipService } from "@modules/core/services/voip.service";
import { LibraryLightboxMessageContent } from "@modules/library/library-lightbox/library-lightbox.component";
import { Store } from "@ngrx/store";
import { ConversationsPageActions } from "app/state";
import * as linkify from "linkifyjs";
import { Subscription } from "rxjs";
import {
  FullUserProfileModel,
  MentionModel,
  Message,
  MessageStatuses,
  MessageType,
} from "types";
import {
  ConversationService,
  LinkService,
  MessageService,
  SharedService,
} from "../../modules/core";
import { LibraryService } from "../../modules/library/library.service";

interface WordBlockModel {
  content: string;
  type: WordBlockType;
  mention?: MentionModel;
}
enum WordBlockType {
  text = "text",
  mention = "mention",
  link = "link",
}

@Component({
  selector: "app-message-card",
  templateUrl: "./message-card.component.html",
  styleUrls: ["./message-card.component.scss"],
})
export class MessageCardComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() userAccount: FullUserProfileModel;
  @Input() message: Message;
  private messageLogicalBlocks: WordBlockModel[] = [];
  @Input() refreshCount: number;
  @Input() messageOriginal: any;
  @Input() id: string;
  @Input() isFirstOfGroup = false;
  @Input() searchQuery: string;
  @Input() pMap: { [key: string]: any };
  @Input() conversationId: string;
  @Input() patientId: string;
  @Input() pic_size: string;
  @Input() conversationType: string;
  @Input() public isPreviewMode: boolean = false;

  @Input() fromMe: Boolean;
  @Input() fromSystem: Boolean;
  @Input() fromSender: Boolean;

  public isForwarded: boolean = false;

  is_image_loaded = false;

  // private clicks = new Subject();
  private subscription: Subscription;
  isWelcomeMessage = false;
  isWelcomeMessage2 = false;
  isSystemMessage = false;
  isPatientFileMessage = false;
  isImageMessage = false;
  isVideoMessage = false;
  isStandardMessage = false;
  isVideoCallMessage = false;
  isVideoCallInProgress = false;

  // hasSender: boolean;
  // fromSystem: boolean;
  // fromMe: boolean;

  sendingStatus = MessageStatuses.Sending;
  messageMetaSub: any;
  subFocus: Subscription;
  callDurationMessage: string | null;

  private intersectionObserver: IntersectionObserver | null = null;

  @ViewChild("anchor")
  public elementRef: ElementRef<HTMLDivElement> | null = null;

  constructor(
    public dialog: MatDialog,
    public conversationService: ConversationService,
    public sharedService: SharedService,
    public messageService: MessageService,
    private libraryService: LibraryService,
    private linkService: LinkService,
    private voipService: VoipService,
    private store: Store
  ) {}

  ngAfterViewInit() {
    this.intersectionObserver = new IntersectionObserver(
      (entries, observer) => {
        if (!entries[0]?.isIntersecting) return;
        this.emitMessage();

        this.store.dispatch(
          ConversationsPageActions.viewMessageCard({
            message: this.message,
            user: this.userAccount,
            isPreviewMode: this.isPreviewMode,
            isSentBySelf: this.message.sentBy === this.userAccount.userId,
          })
        );
      }
    );

    this.intersectionObserver.observe(this.elementRef.nativeElement);
  }

  ngOnChanges() {
    this.messageOriginal = this.message;

    this.setContent();
    this.setPatientId();
    this.messageLogicalBlocks = this.formatMessageBlocksOfTextAndMentions(
      this.message.content,
      this.message.mentions
    );
    this.messageLogicalBlocks = this.linkifyLinks(this.messageLogicalBlocks);

    this.isForwarded = this.message.metadata?.forwardFromMessageId != null;
    this.updateCallDurationMessage();
  }

  linkifyLinks(blocks: WordBlockModel[]): WordBlockModel[] {
    const linkifiedBlocks = blocks.map((word) => {
      let content = word.content;
      if (word.type != WordBlockType.text) return word;
      if (content.length <= 1) return word;

      const items = linkify.find(content, {
        defaultProtocol: "https",
      });
      if (items.length === 0) return word;

      const linkedWord = {
        content: content,
        link: items[0].href,
        type: WordBlockType.link,
      };

      return linkedWord;
    });
    return linkifiedBlocks;
  }

  formatMessageBlocksOfTextAndMentions(
    message: string,
    mentions: MentionModel[] = []
  ): WordBlockModel[] {
    let blocks: WordBlockModel[] = [];
    if (!message) return blocks;
    let indexProcessed = false;
    let blockIndex = 0;
    for (let index = 0; index < message.length; index++) {
      indexProcessed = false;
      blocks[blockIndex] = blocks[blockIndex]
        ? blocks[blockIndex]
        : { content: "", type: WordBlockType.text };
      mentions?.forEach((mention) => {
        if (mention.index == index) {
          blocks.push({
            content: mention.name,
            type: WordBlockType.mention,
            mention: mention,
          });
          index += mention.name.length;
          index--;
          indexProcessed = true;
          // blockIndex++;
          blockIndex += 2;
          return;
        }
      });
      if (indexProcessed) continue;
      if (message[index] == " ") {
        blocks.push({
          content: " ",
          type: WordBlockType.text,
        });
        blockIndex += 2;
        continue;
      }
      if (message[index] == "\n") {
        blocks.push({
          content: "\n",
          type: WordBlockType.text,
        });
        blockIndex += 2;
        continue;
      } else {
        blocks[blockIndex].content += message[index];
      }
    }
    return blocks;
  }

  handleLinkClick(event: any, link: string) {
    if (this.linkService.isPotentialInvitationLink(link)) {
      event.preventDefault();
      this.linkService.handleLink(link);
    }
  }

  ngOnInit() {
    this.setPatientId();
    this.subFocus = this.sharedService.onFocusChange.subscribe((foucsed) => {
      if (foucsed) {
        if (this.isScrolledIntoView()) {
          this.emitMessage();
        }
      }
    });
  }

  isScrolledIntoView() {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:

    const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  emitMessage() {
    if (this.messageService && this.messageService.onMessageViewSubject) {
      this.messageService.onMessageViewSubject.next(this.message);
    }
  }

  @HostListener("window:scroll", ["$event"]) // for window scroll events
  onScroll(event) {}

  setPatientId() {
    if (this.messageMetaSub) {
      this.messageMetaSub.unsubscribe();
    }
    if (
      this.message &&
      (this.message.type === MessageType.Photo ||
        this.message.type === MessageType.PatientFile) &&
      this.userAccount.userId === this.message.sentBy
    ) {
      this.messageMetaSub =
        this.libraryService.messageMetaUpdatedEmitted$.subscribe(
          (meta: any) => {
            if (this.message.metadata.photoId == meta.id) {
              this.message.metadata.uid = meta.patientData.uid;
            }
          }
        );
    }
  }

  setContent() {
    // this.is_image_loaded = false;
    this.isWelcomeMessage = false;
    this.isWelcomeMessage2 = false;
    this.isSystemMessage = false;
    this.isPatientFileMessage = false;
    this.isImageMessage = false;
    this.isVideoMessage = false;
    this.isStandardMessage = false;
    this.isVideoCallMessage = false;
    this.fromSystem = false;
    this.fromMe = false;
    this.fromSender = false;

    if (this.message.sentBy === SYSTEM_USER_ID) {
      if (this.message.type === "ConversationStarted") {
        this.isWelcomeMessage = true;
      } else {
        this.isSystemMessage = true;
      }
    } else if (this.message.type === "PatientFile") {
      this.isPatientFileMessage = true;
    } else if (this.message.type === "Photo") {
      this.isImageMessage = true;
    } else if (this.message.type === MessageType.VideoCall) {
      this.isVideoCallMessage = true;
      this.isVideoCallInProgress =
        this.message.metadata.resourceStatus === "InProgress";
    } else {
      this.isStandardMessage = true;
    }

    if (this.message.mediaType == "Video") {
      this.isImageMessage = false;
      this.isVideoMessage = true;
    }

    if (this.message.sentBy === SYSTEM_USER_ID) {
      this.fromSystem = true;
    } else {
      this.fromSystem = false;
      if (this.userAccount.userId === this.message.sentBy) {
        this.fromMe = true;
        this.fromSender = false;
      } else {
        this.fromMe = false;
        this.fromSender = true;
      }
    }
  }

  public viewPhoto(photoId) {
    if (!photoId) {
      return;
    }
    const obj: LibraryLightboxMessageContent = {
      mediaType: "message",
      enableMediaNavigation: true,
      conversationId: this.conversationId,
      messageId: this.message.marker,
      payload: {
        id: photoId,
        mediaType: "photo",
      },
    };
    this.libraryService.openLightbox(obj, false, this.conversationType);
  }

  public viewVideo(id) {
    if (!id) {
      return;
    }
    const obj: LibraryLightboxMessageContent = {
      mediaType: "message",
      enableMediaNavigation: true,
      conversationId: this.conversationId,
      messageId: this.message.marker,
      payload: {
        id,
        mediaType: "video",
      },
    };
    this.libraryService.openLightbox(obj, false, this.conversationType);
  }

  public viewFile(fileId) {
    const obj: LibraryLightboxMessageContent = {
      mediaType: "message",
      conversationId: this.message.conversationId,
      messageId: this.message.marker,
      payload: {
        id: fileId,
        mediaType: "document",
      },
    };
    obj["payload"] = {
      id: fileId,
      mediaType: "document",
    };
    this.libraryService.openLightbox(obj, false, this.conversationType);
  }

  public findChat(message: any) {
    this.conversationService.emitFindChat(message);
  }

  ngOnDestroy() {
    this.intersectionObserver?.disconnect();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.messageMetaSub) {
      this.messageMetaSub.unsubscribe();
    }
    if (this.subFocus) {
      this.subFocus.unsubscribe();
    }
  }

  clicked($event) {
    if ($event.target.className == "link") {
      $event.stopPropagation();
    }
  }

  handleMentionClicked(mention: MentionModel) {
    this.messageService.onMentionClickedSubject.next(mention);
  }

  public joinVideoCall($event: MouseEvent) {
    if (!this.isVideoCallInProgress) return;
    $event.stopPropagation();
    this.voipService.navigateToJoinCall(
      this.message.conversationId,
      this.message.metadata.resourceId
    );
  }

  public updateCallDurationMessage() {
    if (
      this.message?.type !== MessageType.VideoCall ||
      this.message?.metadata?.resourceStatus !== "Ended"
    ) {
      this.callDurationMessage = null;
      return;
    }

    const duration = Number(
      this.message.metadata?.resourceCallDurationInSeconds
    );
    if (!duration) {
      this.callDurationMessage = null;
      return;
    }

    if (duration < 60) {
      this.callDurationMessage = "Less than a min";
      return;
    }

    let durationMinutesRounded = Math.round(duration / 60);
    this.callDurationMessage = `${durationMinutesRounded} min`;
  }
}
