import { Component, OnDestroy, OnInit } from "@angular/core";
import { config } from "configurations/config";
import { environment } from "environments/environment";
import { AuthService, SharedService } from "../modules/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit, OnDestroy {
  apps = [];
  sub: any;
  loading = false;
  version: string;
  production: boolean;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService
  ) {
    this.apps = sharedService.apps;
    this.sharedService.setMeta("robots", "[index,nofollow]");
  }

  ngOnDestroy() {
    this.sharedService.setMeta("robots", "noindex");
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit() {
    this.version = environment.appVersion;
    this.production = environment.production;
    this.loading = this.authService.getUserId() ? true : false;
    setTimeout(() => {
      this.loading = false;
    }, 8000);
    if (this.authService.isAuthenticated()) {
      // this.sharedService.navigateTo('conversations');
      this.sharedService.navigate(["conversations"], {
        queryParamsHandling: "merge",
      });
      return;
    }
  }

  login() {
    this.sharedService.navigateTo("/login");
  }
}
