import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from "@angular/core";
import { UserAccountService } from "../../../core";

/** @deprecated Use `BasicCeloAvatarComponent` */
@Component({
  selector: "app-avatar-with-name",
  templateUrl: "./avatar-with-name.component.html",
  styleUrls: ["./avatar-with-name.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarWithNameComponent implements OnInit {
  @Input() title = "";
  @Input() firstName: string;
  @Input() lastName: string;
  @Input() userId: string;
  @Input() profilePic: string;
  @Input() description: string;
  @Input() onCall: boolean;
  @Input() mustShowName: boolean;
  @Input() refreshCount: number;
  @Input() showBadge: boolean;
  @Input() identityVerificationStatus: string;
  @Input() workspaces = [];

  @Input() userAccount: any = {};
  @Input() user: any = {};

  constructor(private userAccountService: UserAccountService) {}

  ngOnInit() {
    this.userAccountService.accountSubject.subscribe((data) => {
      this.userAccount = data;
    });
  }
}
