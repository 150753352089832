import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AlertComponent } from "../components";

@Injectable()
export class FeatureFlagErrorInterceptor implements HttpInterceptor {
  private matDialog: MatDialog | null = null;

  public constructor(private injector: Injector) {}

  intercept(
    httpRequest: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(httpRequest).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status !== 402 || !errorResponse.error) {
          return throwError(errorResponse);
        }
        let errors = errorResponse.error.errors;
        if (!errors || !Array.isArray(errors) || errors.length === 0) {
          return throwError(errorResponse);
        }
        let firstError = errors[0];
        if (!firstError || !firstError.code || !firstError.message) {
          return throwError(errorResponse);
        }
        if (!this.matDialog) {
          this.matDialog = this.injector.get(MatDialog);
        }

        if (this.matDialog) {
          this.matDialog.closeAll();
          AlertComponent.openDialog(this.matDialog, {
            title: "Something went wrong",
            message: `${firstError.code}: ${firstError.message}`,
            acceptOnly: true,
            acceptButtonText: "OK",
          });
        }
        return;
      })
    );
  }
}
