import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "environments/environment";

// Admin api scope is required for department admins
const scope =
  "celo_api openid offline_access celo_integration_api celo_admin_api";

export const authCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.celoAuthApiEndpoint + "/",
  redirectUri: environment.origin + "/signin-oidc",
  postLogoutRedirectUri: environment.origin + "/signout-callback-oidc",
  clientId: environment.clientId,
  responseType: "code",
  scope,
  silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
  showDebugInformation: !environment.production,
  strictDiscoveryDocumentValidation: false,
  requireHttps: false,
  fallbackAccessTokenExpirationTimeInSec: 300,
};
