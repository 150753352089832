import { Injectable, InjectionToken } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import {
  GetUserPictureRequestOptions,
  GetWorkspacePictureRequestOptions,
} from "types";
import { UsersService } from "./users.service";
import { WorkspacesService } from "./workspaces.service";

type GetPictureRequestOptions =
  | GetUserPictureRequestOptions
  | GetWorkspacePictureRequestOptions;

export const enum Badge {
  None,
  Unverified,
  VerifiedWorkspace,
  VerifiedIdentity,
  VerifiedProfession,
  External,
}

export interface UserVerificationState {
  isWorkspaceVerified?: boolean | null;
  isIdentityVerified?: boolean | null;
  isProfessionVerified?: boolean | null;
  isExternal?: boolean | null;
}

export const AVATAR_SERVICE = new InjectionToken<AvatarServiceProvider>("AVATAR_SERVICE");

export interface AvatarServiceProvider {
  getUserAvatar(options: GetUserPictureRequestOptions): Observable<SafeUrl | null>;
  getWorkspaceAvatar(options: GetWorkspacePictureRequestOptions): Observable<SafeUrl | null>;
  getBadgeForUserVerificationState(state: UserVerificationState): Badge;
  getBadgeForWorkspaceVerificationState(isVerified?: boolean | null): Badge;
}

@Injectable({
  providedIn: "root",
})
export class AvatarService implements AvatarServiceProvider {
  // May want to seperate caching from this service, but for now this is okay #TODO add TTL/invalidation
  private avatars: Map<string, SafeUrl | null> = new Map();

  public constructor(
    private sanitizer: DomSanitizer,
    private usersService: UsersService,
    private workspacesService: WorkspacesService
  ) { }

  private createSafeUrl(blob: Blob): SafeUrl {
    const url = window.URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private createKey(options: GetPictureRequestOptions): string {
    return Object.entries(options)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .reduce((prev, curr) => `${prev}${curr[0]}=${curr[1]}&`, "");
  }

  private getCacheItem(
    options: GetPictureRequestOptions
  ): SafeUrl | null | undefined {
    const key = this.createKey(options);
    return this.avatars.get(key);
  }

  private updateCache(options: GetPictureRequestOptions, url: SafeUrl | null) {
    const key = this.createKey(options);
    this.avatars.set(key, url);
  }

  public getUserAvatar(
    options: GetUserPictureRequestOptions
  ): Observable<SafeUrl | null> {
    const safeUrl = this.getCacheItem(options);
    if (safeUrl !== undefined) return of(safeUrl);
    return this.usersService.getPicture(options).pipe(
      catchError(() => of(null)),
      map((blob) => (blob ? this.createSafeUrl(blob) : blob)),
      tap((url) => this.updateCache(options, url))
    );
  }

  public getWorkspaceAvatar(
    options: GetWorkspacePictureRequestOptions
  ): Observable<SafeUrl | null> {
    const safeUrl = this.getCacheItem(options);
    if (safeUrl !== undefined) return of(safeUrl);
    return this.workspacesService.getPicture(options).pipe(
      catchError(() => of(null)),
      map((blob) => (blob ? this.createSafeUrl(blob) : blob)),
      tap((url) => this.updateCache(options, url))
    );
  }

  /** Returns the type of badge that should be used for the given verification state */
  public getBadgeForUserVerificationState({
    isWorkspaceVerified,
    isIdentityVerified,
    isProfessionVerified,
    isExternal,
  }: UserVerificationState): Badge {
    if (isExternal) return Badge.External;
    if (isProfessionVerified) return Badge.VerifiedProfession;
    if (isIdentityVerified) return Badge.VerifiedIdentity;
    if (isWorkspaceVerified) return Badge.VerifiedWorkspace;
    if (
      isProfessionVerified === null &&
      isIdentityVerified === null &&
      isWorkspaceVerified === null
    ) {
      return Badge.None;
    }
    return Badge.Unverified;
  }

  public getBadgeForWorkspaceVerificationState(
    isVerified: boolean | null = null
  ): Badge {
    if (isVerified) return Badge.VerifiedWorkspace;
    return Badge.None;
  }
}
