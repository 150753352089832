import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "@env";
import {
  LibraryShareImgComponent,
  LibraryShareImgData,
  LibraryShareImgResult,
} from "@modules/library/library-share-img/library-share-img.component";
import { ZipFilesService } from "@modules/shared/zip-files.service";
import { AlertService } from "app/modules/core/old/alert.service";

export interface SharePhotosOptions {
  isFromLibrary?: boolean;
  conversationType?: string | null;
  fromConversationId?: string;
  fromMessageId?: string;
}

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class MediaService {
  downloadPhotos(photos) {
    this.zipFilesService.downloadZip(
      photos.map((photo) => {
        const fileName = this.zipFilesService.createFileNameFromFileObject(
          photo.fileName,
          "jpg",
          photo.patientData
        );
        return {
          url: `${environment.celoApiEndpoint}/api/photos/${photo.id}`,
          fileName,
        };
      })
    );
  }

  constructor(
    public dialog: MatDialog,
    private alert: AlertService,
    private http: HttpClient,
    private router: Router,
    private zipFilesService: ZipFilesService
  ) {}

  public sharePhotos(photos: any[], options?: SharePhotosOptions) {
    const { fromConversationId, fromMessageId } = options;
    const data: LibraryShareImgData = {
      fromConversationId,
      fromMessageId,
    };

    const ref = this.dialog.open<
      LibraryShareImgComponent,
      LibraryShareImgData,
      LibraryShareImgResult
    >(LibraryShareImgComponent, {
      panelClass: "mat-panel-reset",
      data,
    });
    ref.componentInstance.photos = photos;
    ref.componentInstance.fromConversationType = options.conversationType;
    ref.componentInstance.isFromLibrary = options.isFromLibrary ?? false;

    return ref.afterClosed();
  }

  openCreateConvoWithPhotos(photos: any[]) {
    const qp = { isPhotoShare: true };
    let idx = 0;
    for (const photo of photos) {
      qp[`photo${idx}`] = photo.id;
      idx += 1;
    }
    this.router.navigate(["/conversations/new"], { queryParams: qp });
  }

  public deleteMedia(photos, type, callback) {
    this.alert
      .confirm(
        "Confirm",
        "Are you sure you want to remove this " +
          type +
          " from your Celo Library?",
        "REMOVE " + type,
        "No. I will decide later"
      )
      .subscribe((result) => {
        if (result) {
          for (const photo of photos) {
            const url = `${environment.celoApiEndpoint}/api/photos/${photo.id}`;
            this.http.delete(url).subscribe(
              (data) => {
                callback(true);
              },
              (error) => {
                this.alert.alert("Error", "Error delete file", true);
              }
            );
          }
        }
      });
  }

  public getPhotos() {}
}
