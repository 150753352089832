<div class="container">
  <img
    src="../../../../assets/empty_states/empty-state-activity.svg"
    alt="Empty"
  />
  <h3>Having a busy day?</h3>
  <p>
    Celo will help you keep track of the most important events throughout the
    day so you can stay in the loop.
  </p>
</div>
