import { Platform } from "@angular/cdk/platform";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  LuxonDateAdapter,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
} from "@angular/material-luxon-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { UserService } from "@modules/core";
import { getISODateComponent } from "@utils";
import countryCodes from "country-codes-list";
import {
  AsYouType,
  CountryCallingCode,
  CountryCode,
  getCountries,
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
  parsePhoneNumberWithError,
  PhoneNumber,
} from "libphonenumber-js";
import { DateTime } from "luxon";
import { matchSorter } from "match-sorter";
import { Observable, of } from "rxjs";
import { map, startWith, tap } from "rxjs/operators";
import {
  DATE_FORMATS,
  DATE_ADAPTER_OPTIONS,
} from "../edit-patient/edit-patient.component";

const countryNames = countryCodes.customList("countryCode", "{countryNameEn}");

interface CountryCallingCodeOption {
  countryCallingCodeNumber: number;
  countryCallingCodeWithPrefix: string;
  countryCallingCode: CountryCallingCode;
  countryCode: CountryCode;
  countryCodeLowerCase: string;
  countryName: string;
}

const countryCallingCodes: CountryCallingCodeOption[] = getCountries()
  .map((countryCode) => {
    const countryCallingCode = getCountryCallingCode(countryCode);
    const countryName = countryNames[countryCode];
    return {
      countryCallingCodeNumber: Number(countryCallingCode),
      countryCallingCodeWithPrefix: `+${countryCallingCode}`,
      countryCallingCode,
      countryCode,
      countryCodeLowerCase: countryCode.toLowerCase(),
      countryName,
      displayValue: `+${countryCallingCode} ${countryName}`,
    };
  })
  .sort((a, b) => a.countryCallingCodeNumber - b.countryCallingCodeNumber);

/**
 * Only used if no phone number is provided as input (or the provided number is invalid) and we're unable to determine
 * the current users country.
 */
const defaultCountryCode: string = countryCallingCodes[0].countryCode;

const isValidCountryCallingCodeValidator: ValidatorFn = (
  control: AbstractControl
) => {
  const value = control.value;
  if (typeof value !== "string") return { isInvalidCountryCallingCode: true };

  const option = countryCallingCodes.find((c) => c.countryCode === value);
  if (!option) return { isInvalidCountryCallingCode: true };

  return null;
};

const isValidPhoneNumberValidator =
  (getCountryCallingCodeWithPrefix: () => string): ValidatorFn =>
  (control: AbstractControl) => {
    const value = control.value;

    if (
      typeof value !== "string" ||
      !isValidPhoneNumber(getCountryCallingCodeWithPrefix() + value)
    ) {
      return { isInvalidPhoneNumber: true };
    }

    return null;
  };

export interface PatientDetails {
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  /** Phone number is E.164 format. */
  phoneNumber: string;
}

@Component({
  selector: "app-patient-details-form",
  templateUrl: "./patient-details-form.component.html",
  styleUrls: ["./patient-details-form.component.scss"],
  providers: [
    {
      provide: DateAdapter<DateTime>,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS,
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: DATE_ADAPTER_OPTIONS,
    },
  ],
})
export class PatientDetailsFormComponent implements OnInit, OnChanges {
  @Input() public lastName: string = "";
  @Input() public firstName: string = "";
  @Input() public dateOfBirth: string = "";
  @Input() public phoneNumber: string = "";
  @Input() public disabled: boolean = false;

  @Output() public patientDetails: EventEmitter<PatientDetails> =
    new EventEmitter();

  @Output() public isValid: EventEmitter<boolean> = new EventEmitter();

  public maxDate: Date = new Date();
  public minDate: Date = new Date();

  public countryCallingCodes: CountryCallingCodeOption[] = countryCallingCodes;
  public filteredCountryCallingCodes: Observable<CountryCallingCodeOption[]> =
    of(countryCallingCodes);

  private selectedCountryCallingCodeOption: CountryCallingCodeOption | null =
    null;

  public recipientInformationForm = this.fb.group({
    lastName: ["", [Validators.required]],
    firstName: ["", [Validators.required]],
    dateOfBirth: ["", [Validators.required]],
    countryCallingCode: [
      defaultCountryCode,
      [Validators.required, isValidCountryCallingCodeValidator],
    ],
    phoneNumber: [
      "",
      [
        Validators.required,
        isValidPhoneNumberValidator(
          this.getCountryCallingCodeWithPrefix.bind(this)
        ),
      ],
    ],
  });

  public constructor(
    private fb: FormBuilder,
    private userService: UserService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const disabledChange = changes["disabled"];
    if (disabledChange === null || disabledChange === undefined) return;
    const isDisabled = !!disabledChange.currentValue;
    if (isDisabled) {
      this.recipientInformationForm.disable();
    } else {
      this.recipientInformationForm.enable();
    }
  }

  public ngOnInit(): void {
    const countryCallingCodeControl =
      this.recipientInformationForm.controls.countryCallingCode;

    this.filteredCountryCallingCodes =
      countryCallingCodeControl.valueChanges.pipe(
        startWith(""),
        map((callingCode) => {
          if (!callingCode || callingCode.length === 0) {
            return this.countryCallingCodes.slice();
          }

          const results = matchSorter(this.countryCallingCodes, callingCode, {
            keys: [
              "displayValue",
              "countryCallingCodeWithPrefix",
              "countryCallingCode",
              "countryCode",
              "countryName",
            ],
            sorter: (items) =>
              [...items].sort(
                (a, b) =>
                  a.item.countryCallingCodeNumber -
                  b.item.countryCallingCodeNumber
              ),
          });

          return results;
        }),
        tap({
          next: () => {
            mobileControl.updateValueAndValidity();
          },
        })
      );

    const mobileControl = this.recipientInformationForm.controls.phoneNumber;
    mobileControl.valueChanges.subscribe({
      next: (value) => {
        const replacedValue = value.replace(/\D/g, "");

        this.selectedCountryCallingCodeOption =
          this.findCountryCallingCodeOption(
            countryCallingCodeControl.value as CountryCode
          );
        const countryCallingCodeWithPrefix =
          this.selectedCountryCallingCodeOption?.countryCallingCodeWithPrefix;

        const maskedValue = new AsYouType()
          .input(countryCallingCodeWithPrefix + replacedValue)
          .replace(countryCallingCodeWithPrefix, "")
          .trim();

        mobileControl.setValue(maskedValue, { emitEvent: false });
      },
    });

    this.recipientInformationForm.valueChanges.subscribe({
      next: () => {
        this.isValid.emit(this.recipientInformationForm.valid);
      },
    });

    let mobile: PhoneNumber | null = null;
    try {
      mobile = parsePhoneNumberWithError(this.phoneNumber);
    } catch (error) {
      // Invalid mobile number, so we can't prepopulate the country code field
    }

    const userCountryCode = countryCallingCodes.find(
      (c) =>
        c.countryCode.toLowerCase() ===
        this.userService.getCountryCode()?.toLowerCase()
    )?.countryCode;

    this.recipientInformationForm.setValue({
      lastName: this.lastName,
      firstName: this.firstName,
      dateOfBirth: this.dateOfBirth,
      countryCallingCode:
        mobile?.country ?? userCountryCode ?? defaultCountryCode,
      phoneNumber: mobile?.nationalNumber ?? this.phoneNumber,
    });

    this.minDate.setFullYear(this.minDate.getFullYear() - 200);
  }

  private findCountryCallingCodeOption(
    countryCode: CountryCode
  ): CountryCallingCodeOption | null {
    const option = countryCallingCodes.find(
      (c) => c.countryCode === countryCode
    );
    return option ?? null;
  }

  private getCountryCallingCodeWithPrefix(): string {
    return (
      this.selectedCountryCallingCodeOption?.countryCallingCodeWithPrefix ?? ""
    );
  }

  public displayFn(
    countryCode: CountryCallingCodeOption["countryCode"]
  ): string {
    // Note: 'this' refers to MatAutoComplete in this context
    const option = countryCallingCodes.find(
      (c) => c.countryCode === countryCode
    );
    if (!option) return null;
    return option.countryCallingCodeWithPrefix;
  }

  public onSubmit() {
    const value = this.recipientInformationForm.value;
    const dateOfBirth = getISODateComponent(value.dateOfBirth);

    const phoneNumber = parsePhoneNumber(
      this.getCountryCallingCodeWithPrefix() + value.phoneNumber
    );

    this.patientDetails.emit({
      lastName: value.lastName,
      firstName: value.firstName,
      dateOfBirth,
      phoneNumber: phoneNumber.format("E.164"),
    });
  }

  public submit() {
    this.onSubmit();
  }
}
