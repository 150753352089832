<!-- Top right close icon -->
<button mat-icon-button matTooltip="Close" aria-label="Close" id="close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<div *ngIf="isLoading" class="loader">
  <app-loading-logo></app-loading-logo>
</div>

<!-- Left side content -->
<div class="pv-24 pl-24 left vertical" [hidden]="isLoading">
  <div class="controls pr-24">
    <ng-content select="[left-header]"></ng-content>
  </div>

  <div class="scroll-y pr-12 ml-n24 pl-24">
    <ng-content select="[left-content]"></ng-content>
  </div>
</div>

<ng-container *ngIf="!hideRightContent">
  <mat-divider
    id="divider"
    [vertical]="true"
    [hidden]="isLoading"
  ></mat-divider>

  <!-- Right side content -->
  <div class="pv-24 pr-24 right vertical" [hidden]="isLoading">
    <div class="pl-16">
      <ng-content select="[right-header]"></ng-content>
    </div>

    <div class="vertical scroll-y content">
      <div  appScrollToBottomDirective>
        <ng-content select="[right-content]"></ng-content>
      </div>
    </div>
    <div class="controls vertical pt-16">
      <ng-content select="[right-footer]"></ng-content>
    </div>
  </div>
</ng-container>
