import { Component, Inject } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { createDialogConfig } from "@utils";
import { CreateMessageModel } from "types";

export interface ConversationPickerDialogData {
  message?: CreateMessageModel | null;
  header?: string | null;
  createNewConversationButtonText?: string | null;
  sendButtonText?: string | null;
  patientUid?: string;
  patientUids?: string[];
  attachments?: { id?: string }[];
  attachmentType?: "PHOTO" | "MEDIA" | "DOCUMENT";
}

export interface ConversationPickerDialogResult {
  /** @deprecated */
  newConvo: string;
  /** @deprecated Use `conversationId` instead. */
  convoId: string;
  conversationId: string;
}

@Component({
  selector: "app-conversation-picker-dialog",
  templateUrl: "./conversation-picker-dialog.component.html",
  styleUrls: ["./conversation-picker-dialog.component.scss"],
})
export class ConversationPickerDialogComponent {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: ConversationPickerDialogData
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: ConversationPickerDialogData
  ): MatDialogRef<
    ConversationPickerDialogComponent,
    ConversationPickerDialogResult
  > {
    const config = createDialogConfig<ConversationPickerDialogData>(data);
    return matDialog.open<
      ConversationPickerDialogComponent,
      ConversationPickerDialogData,
      ConversationPickerDialogResult
    >(ConversationPickerDialogComponent, config);
  }
}
