import { ErrorResponse } from "@types";

export interface ErrorDescriptor {
  title?: string;
  message: string;
}

export enum ErrorCode {
  VideoCallNotEnabled = "403694",
  ConversationNotFound = "404004",
  CreateCallNotAnActiveConversationMember = "403691",
  CallInProgress = "400209",
  InvalidCallId = "400204",
  CallNotFound = "404017",
  CallEnded = "400206",
  JoinCallNotAnActiveConversationMember = "403692",
  CallsNotFound = "403693",
  SinglePaidWorkspce = "403122",
  WorkspaceUserLimit = "403117",
}

const errorMap: {
  [P in ErrorCode]: ErrorDescriptor | null;
} = {
  [ErrorCode.VideoCallNotEnabled]: {
    title: "Feature Unavailable",
    message: "Please upgrade your plan to use secure video calling.",
  },
  [ErrorCode.ConversationNotFound]: null,
  [ErrorCode.CreateCallNotAnActiveConversationMember]: {
    title: "You're no longer a participant",
    message:
      "You can't create a call in this conversation because you're no longer a participant.",
  },
  [ErrorCode.CallInProgress]: {
    message: "Another call is in progress.",
  },
  [ErrorCode.InvalidCallId]: null,
  [ErrorCode.CallNotFound]: null,
  [ErrorCode.CallEnded]: {
    message: "The video call has ended.",
  },
  [ErrorCode.JoinCallNotAnActiveConversationMember]: {
    title: "You're no longer a participant",
    message:
      "You can't create a call in this conversation because you're no longer a participant.",
  },
  [ErrorCode.CallsNotFound]: null,
  [ErrorCode.SinglePaidWorkspce]: {
    message:
      "Oops! Your Celo account already belongs to an organizational account. To join this workspace, you’ll need to create a new Celo account.",
  },
  [ErrorCode.WorkspaceUserLimit]: {
    title: "Error",
    message:
      "Oops! This workspace has already reached its maximum user count. Please contact the workspace admin to join.",
  },
};

export const getErrorDescriptor = (
  errorResponse: ErrorResponse
): ErrorDescriptor | null => {
  const error = errorResponse.errors?.[0];
  if (!error) return null;
  const descriptor = errorMap[error.code];
  if (!descriptor) {
    return {
      title: "Unknown Error",
      message:
        error.message ?? "An unexpected error occurred. Please try again.",
    };
  }
  return descriptor;
};
