<div>
<button
  mat-icon-button
  aria-label="Close dialog"
  *ngIf="state === states.SelectMedia"
  class="close"
  mat-dialog-close
>
  <mat-icon>close</mat-icon>
</button>

<div [hidden]="state !== states.SelectMedia">
  <div class="font-18">{{ title ?? "Select Media" }}</div>
  <div class="content">
    <div class="library">
      <div class="library-container">
        <div *ngIf="!hasPatientId || onlyShowPatientFiles" style="height: 100%">
          <div *ngIf="loading" style="height: 100%">
            <app-empty-state [isLoading]="true"></app-empty-state>
          </div>
          <app-media-list
            (selsectionChange)="selsectionChange($event)"
            [selectMode]="true"
            (length)="listLength($event)"
            [onlyShowPatientFiles]="onlyShowPatientFiles"
            [disableExportedFiles]="disableExportedFiles"
            [hideVideos]="hideVideos"
            [maxSelectionSize]="isExportFlow ? 20 : 8"
          ></app-media-list>
        </div>
        <mat-tab-group
          *ngIf="hasPatientId && !onlyShowPatientFiles"
          style="overflow-y: hidden; height: 100%"
          (selectedTabChange)="resetSelection()"
        >
          <mat-tab
            *ngIf="hasPatientId && patientId"
            [label]="patientId ? patientId : '(No patient ID)'"
          >
            <div>
              <div *ngIf="loading">
                <app-empty-state [isLoading]="true"></app-empty-state>
              </div>
              <app-media-list
                [acceptedPatientId]="patientId"
                (selsectionChange)="selsectionChange($event)"
                [selectMode]="true"
                (length)="listLength($event)"
                [hideVideos]="hideVideos"
              ></app-media-list>
            </div>
          </mat-tab>
          <mat-tab label="All">
            <div>
              <div *ngIf="loading">
                <app-empty-state [isLoading]="true"></app-empty-state>
              </div>
              <app-media-list
                [acceptedPatientId]="patientId"
                (selsectionChange)="selsectionChange($event)"
                [selectMode]="true"
                [clientPatientFilter]="true"
                (length)="listLength($event)"
                [hideVideos]="hideVideos"
              ></app-media-list>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>

<div [hidden]="state !== states.SelectClinicalRecord" *ngIf="isExportFlow">
  <div class="max-w-400px">
    <h2 class="mat-headline-6 text-black">Export to clinical record</h2>

    <p class="mat-body text-13px text-black">
      You are about to export multiple photos to the clinical record. Please
      make sure the patient details are correct.
    </p>

    <p class="mat-body text-13px text-black">
      Please note this action cannot be undone. Once exported to the clinical
      record, you will need to make any required changes directly in the
      clinical record.
    </p>

    <h4 class="mat-body-strong text-black mb-20px">
      Select where you want to export the photo
    </h4>

    <app-integrations-picker
      [ignoreExportableTo]="true"
      [type]="'PhotoExport'"
      (selectionChange)="handleIntegrationSelection($event)"
    ></app-integrations-picker>
  </div>
</div>

<div
  class="export flex flex-col justify-center"
  *ngIf="state === states.Exporting"
>
  <div class="title">
    {{
      isExported
        ? "Success!"
        : "Exporting photos to the clinical record... Please do not close your browser."
    }}
  </div>
  <div class="progress-bar-container">
    <div class="bar" [style.width.%]="exportProgress"></div>
  </div>
</div>

<mat-dialog-actions>
  <ng-container *ngIf="state !== states.Exporting">
    <button
      mat-button
      class="celo-secondary-button"
      (click)="cancel()"
      [class.flex-1]="isExportFlow && state === states.SelectClinicalRecord"
    >
      {{ state === states.SelectMedia ? "Cancel" : "Back" }}
    </button>
    <button
      mat-button
      class="celo-primary-button"
      (click)="submit()"
      [disabled]="
        state === states.SelectClinicalRecord
          ? selectedIntegration === null ||
            selectedIntegration.integrationId === null ||
            selectedIntegration.serviceCode === null
          : !selected || selected.length === 0
      "
      [class.flex-1]="isExportFlow && state === states.SelectClinicalRecord"
    >
      {{
        isExportFlow
          ? state === states.SelectMedia
            ? "Next"
            : "Export"
          : "Send"
      }}
    </button>
  </ng-container>

  <ng-container *ngIf="state === states.Exporting">
    <button
      mat-button
      class="celo-secondary-button"
      mat-dialog-close
      [disabled]="!isExported"
    >
      Done
    </button>
  </ng-container>
</mat-dialog-actions>
</div>