import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { environment } from "environments/environment";

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  ConversationTypes: { [x: string]: string } = {
    Chat: "chat",
    Group: "group",
    Case: "case",
    SelfChat: "self_chat",
  };

  constructor(private analytics: AngularFireAnalytics) {}

  raiseEvent(event_name: string, params: { [key: string]: any } | undefined) {
    if (params) {
      params["app_version"] = environment.appVersion;
    }
    this.analytics.logEvent(event_name, params);
  }

  raisePageViewEvent(page_name: string, params?: { [x: string]: string }) {
    params = params ? params : {};
    params["page_name"] = page_name;
    this.raiseEvent("celo_page_view", params);
  }

  buttonClickEvent(button_id: any, params?: { [x: string]: any }) {
    params = params ? params : {};
    params["button_id"] = button_id;
    this.raiseEvent("button_click", params);
  }

  raiseLinkEvents(link_type: string, source: string) {
    this.raiseEvent("link_share", {
      link_type,
      source,
    });
  }

  raiseLinkResetEvents(link_type: string, success: boolean) {
    this.raiseEvent("link_reset", {
      link_type,
      success,
    });
  }

  getFormatedConversationType(conversationType: string) {
    if (!this.ConversationTypes[conversationType]) {
      return "";
    }
    return this.ConversationTypes[conversationType];
  }
}
