import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { createDialogConfig } from "@utils";

export interface RoleDetailsReadMoreDialogData {
  description: string;
}

export interface RoleDetailsReadMoreDialogResult {}

@Component({
  selector: "app-role-details-read-more-dialog",
  templateUrl: "./role-details-read-more-dialog.component.html",
  styleUrls: ["./role-details-read-more-dialog.component.scss"],
})
export class RoleDetailsReadMoreDialogComponent implements OnInit {
  public description: string | null = null;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: RoleDetailsReadMoreDialogData
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: RoleDetailsReadMoreDialogData
  ): MatDialogRef<
    RoleDetailsReadMoreDialogComponent,
    RoleDetailsReadMoreDialogResult
  > {
    const config = createDialogConfig<RoleDetailsReadMoreDialogData>(data);
    return matDialog.open<
      RoleDetailsReadMoreDialogComponent,
      RoleDetailsReadMoreDialogData,
      RoleDetailsReadMoreDialogResult
    >(RoleDetailsReadMoreDialogComponent, config);
  }

  public ngOnInit(): void {
    this.description = this.dialogData.description;
  }
}
