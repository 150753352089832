import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { environment } from "@env";
import {
  AlertService,
  AnalyticsService,
  AuthService,
  ConService,
  FileUploadService,
  SharedService,
  TeamsService,
  UserAccountService,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { Team } from "@types";
import { WorkspaceService } from "app/workspace/workspace.service";
import {
  FileItem,
  FileUploader,
  FileUploaderOptions,
  ParsedResponseHeaders,
} from "ng2-file-upload";
import { Observable } from "rxjs";
import { ProfileService } from "../profile.service";

@Component({
  selector: "app-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: [
    "./profile-page.component.scss",
    "../../../directory/network/network-style.scss",
    "../profile/profile.scss",
  ],
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  departments = [];
  profile: any;
  isLoading = false;
  isSaving = false;
  isEdit = false;
  editProfile: any;
  isOnCall = false;

  isProfilePage = false;
  companies: any[];
  refreshCount = 0;
  nocache = false;
  version = "2.0.0";
  subscription: any;
  public uploader: FileUploader;

  public teams$: Observable<Team[]> | null = null;

  constructor(
    private alertService: AlertService,
    private sharedService: SharedService,
    private profileService: ProfileService,
    private workspaceService: WorkspaceService,
    private connectionService: ConService,
    private userAccountService: UserAccountService,
    private dialog: MatDialog,
    private fileUploadService: FileUploadService,
    private authService: AuthService,
    private teamsService: TeamsService,
    private analyticsService: AnalyticsService // Legacy - used in template
  ) {}

  public ngOnInit() {
    this.version = environment.appVersion;
    this.loadProfile(false);
    this.isLoading = true;
    this.loadProfile(true);
    this.teams$ = this.teamsService.userTeams$;
  }

  public ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  fileSelected(event, player) {
    document
      .querySelector("#main-video source")
      .setAttribute("src", URL.createObjectURL(event.target.files[0]));

    // Load the video and show it
    player.load();
    player.style.display = "inline";
  }

  metaloaded(_VIDEO, _CANVAS, thumbnail) {
    const video_duration = _VIDEO.duration;
    let duration_options_html = "";

    // Set options in dropdown at 4 second interval
    for (let i = 0; i < Math.floor(video_duration); i = i + 4) {
      duration_options_html += '<option value="' + i + '">' + i + "</option>";
    }
    document.querySelector("#set-video-seconds").innerHTML =
      duration_options_html;

    // Show the dropdown container
    thumbnail.style.display = "block";

    // Set canvas dimensions same as video dimensions
    _CANVAS.width = _VIDEO.videoWidth;
    _CANVAS.height = _VIDEO.videoHeight;
  }

  private onSuccessItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ) {}

  private onErrorItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
  ) {
    this.alertService.alert("Error", "Error uploading profile picture", true);
  }

  public onCompleteAll() {
    if (this.uploader.queue.filter((f) => f.isError).length == 0) {
      this.alertService.showSnackBar("Profile picture updated successfully", 3);
      this.loadProfile(function () {
        this.nocache = true;
        this.refreshCount++;
        this.userAccountService.profilePicChangeSubject.next(true);
      });
    }
  }

  public onAfterAddingFile(item: FileItem) {
    this.handleNewPhotoSelected();
  }

  closeUpload() {
    this.fileUploadService.uploader.clearQueue();
  }

  setDataFromProfile() {
    this.editProfile = this.profile;
    this.isOnCall = this.profile.isOnCall == "true";
    this.editProfile = this.editProfile ? this.editProfile : {};
    this.editProfile.department = this.editProfile.department
      ? this.editProfile.department
      : {};
    this.editProfile.company = this.editProfile.company
      ? this.editProfile.company
      : {};
    this.companies = this.sharedService.getCompaniesInfo(this.profile);
    this.listenToUserDND(this.profile.userId);
  }

  loadProfile(important, callback?): any {
    const instance = this;
    this.userAccountService.getUserAccount(important, function (profile) {
      instance.handleProfile(profile);
      if (callback) {
        callback();
      }
    });
  }

  handleProfile(profile) {
    this.isLoading = false;
    this.profile = profile;
    this.refreshCount++;
    this.userAccountService.accountSubject.next(profile);
    this.setDataFromProfile();

    //to remove
    // this.profile.identityVerificationStatus = 'Verified'
    // this.profile.identityVerificationStatus = 'Pending'
    // this.profile.workspaces = []
    // this.profile['professions'] =  this.sharedService.dummyProfessions;
  }

  verifyIdentity() {
    this.alertService.verifyIdentityDialog();
  }

  editOrVerifyProfession() {
    if (this.profile.identityVerificationStatus == "Pending") {
      this.alertService.customDialog(
        "We are currently reviewing your identity verification",
        "You cannot add a profession at this time. Please try again once your identity has been verified. If you need help, contact us at support@celoeahlth.com",
        "OK",
        "",
        true,
        "vertical",
        {},
        true
      );
      return;
    } else if (this.profile.identityVerificationStatus != "Verified") {
      const payload = [
        {
          type: "verifyIdentityInfo",
          message:
            "You must first verify your identity before adding a profession. You can verify your identity using the mobile app. Scan the QR codes or click on the buttons below to download the Celo mobile app.",
        },
      ];
      this.alertService.customDialog(
        "Verify your identity",
        "",
        "DONE",
        "",
        true,
        "vertical",
        payload
      );
      return;
    }

    if (this.profile.professions && this.profile.professions[0]) {
      return this.editProfession();
    }
    return this.verifyProfession();
  }

  verifyProfession() {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }

    const payload = [
      {
        type: "addProfession",
        myCategory:
          this.profile.professions && this.profile.professions[0]
            ? this.profile.professions[0].category
            : "",
        myProfession:
          this.profile.professions && this.profile.professions[0]
            ? this.profile.professions[0].profession
            : "",
        status:
          this.profile.professions && this.profile.professions[0]
            ? this.profile.professions[0].verificationStatus
            : "",
        countryCode: this.profile.countryCode,
        message: "",
      },
    ];
    const ref = this.alertService.customDialog(
      "Profession",
      "",
      "NEXT",
      "CANCEL",
      false,
      "",
      payload
    );
    ref.afterClosed().subscribe((result) => {
      if (!result || !result.payload || !result.payload["name"]) {
        return;
      }

      this.handleProfessionInfo(result);
    });
  }

  editProfession() {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }

    const payload = [
      {
        type: "editProfession",
        myCategory:
          this.profile.professions && this.profile.professions[0]
            ? this.profile.professions[0].category
            : "",
        myProfession:
          this.profile.professions && this.profile.professions[0]
            ? this.profile.professions[0].profession
            : "",
        status:
          this.profile.professions && this.profile.professions[0]
            ? this.profile.professions[0].verificationStatus
            : "",
        countryCode: this.profile.countryCode,
        message: "",
      },
    ];
    const ref = this.alertService.customDialog(
      "Profession",
      "",
      "",
      "",
      true,
      "",
      payload
    );
    ref.afterClosed().subscribe((result) => {
      if (!result || !result.payload || !result.payload["name"]) {
        return;
      }

      if (this.profile.professions[0].verificationStatus == "Verified") {
        return this.alertService
          .confirm(
            "Are you sure you want to " +
              (result.type == "remove_profession" ? "remove" : "change") +
              " your profession?",
            "If you continue, you will lose your verified profession badge and you will have to verify your profession again.",
            "CONTINUE",
            "CANCEL",
            false
          )
          .subscribe((res) => {
            if (!res) {
              return;
            }
            if (result.type != "verify_now") {
              if (result.type == "remove_profession") {
                const id = this.profile.professions[0].id;
                this.removeProfession(id);
                return;
              }
              return this.saveProfession(
                result.payload["name"],
                result.payload["specify"],
                "",
                "",
                "",
                function (resp) {
                  if (resp) {
                    this.alertService.showSnackBar("Profession saved", 3);
                  }
                }
              );
            }
            this.startProfessionVerificationFlow(
              result.payload["name"],
              result.payload["specify"]
            );
          });
      } else {
        if (result.type != "verify_now") {
          if (result.type == "remove_profession") {
            const id = this.profile.professions[0].id;
            this.removeProfession(id);
            return;
          }
          return this.saveProfession(
            result.payload["name"],
            result.payload["specify"],
            "",
            "",
            "",
            function (resp) {
              if (resp) {
                this.alertService.showSnackBar("Profession saved", 3);
              }
            }
          );
        }
        this.startProfessionVerificationFlow(
          result.payload["name"],
          result.payload["specify"]
        );
      }
    });
  }

  removeProfession(id) {
    this.deleteProfession(id, function (res) {});
  }

  handleProfessionInfo(result) {
    if (!result.payload.canVerify) {
      this.saveProfession(
        result.payload["name"],
        result.payload["specify"],
        "",
        "",
        "",
        function (resp) {
          if (resp) {
            this.alertService.showSnackBar("Profession saved", 3);
          }
        }
      );
      return;
    }
    this.startProfessionVerificationFlow(
      result.payload["name"],
      result.payload["specify"]
    );
  }

  saveProfession(
    category,
    profession?,
    registrationName?,
    registrationNumber?,
    registrationLink?,
    verify?,
    callback?
  ) {
    const body = {};
    body["category"] = category;
    if (profession) {
      body["profession"] = profession;
    }
    if (
      this.profile.professions &&
      this.profile.professions[0] &&
      this.profile.professions[0].id
    ) {
      body["id"] = this.profile.professions[0].id;
    }

    if (verify) {
      body["verify"] = verify;
      if (registrationName) {
        body["registrationName"] = registrationName;
      }
      if (registrationNumber) {
        body["registrationNumber"] = registrationNumber;
      }
      if (registrationLink) {
        body["registrationLink"] = registrationLink;
      }
    }
    if (body["id"]) {
      this.putProfession(body, callback);
    } else {
      this.postProfession(body, callback);
    }
  }

  postProfession(body, callback) {
    const path = environment.celoApiEndpoint + "/api/v2/account/professions";
    this.sharedService.postObjectById(path, {}, body).subscribe(
      (resp) => {
        this.loadProfile(true);
        callback(resp);
      },
      (err) => {
        let message = this.sharedService.getErrorMessageFromError(err);
        message = message ? message : this.sharedService.STANDARD_ERROR_MESSAGE;
        this.alertService.alert("", message);
      }
    );
  }

  putProfession(body, callback) {
    const path =
      environment.celoApiEndpoint + "/api/v2/account/professions/" + body["id"];
    this.sharedService.putObjectById(path, {}, body).subscribe(
      (resp) => {
        this.loadProfile(true);
        callback(resp);
      },
      (err) => {
        let message = this.sharedService.getErrorMessageFromError(err);
        message = message ? message : this.sharedService.STANDARD_ERROR_MESSAGE;
        this.alertService.alert("", message);
      }
    );
  }

  deleteProfession(id, callback) {
    const path =
      environment.celoApiEndpoint + "/api/v2/account/professions/" + id;
    this.sharedService.deleteObjectById(path).subscribe(
      (resp) => {
        this.alertService.showSnackBar("Profession has been removed", 4);
        this.loadProfile(true);
        callback(resp);
      },
      (err) => {
        let message = this.sharedService.getErrorMessageFromError(err);
        message = message ? message : this.sharedService.STANDARD_ERROR_MESSAGE;
        this.alertService.alert("", message);
      }
    );
  }

  startProfessionVerificationFlow(name, professionSpecify?) {
    const payload = [
      {
        type: "verifyProfession",
        countryCode: this.profile.countryCode,
        myCategory: name,
        myProfession: professionSpecify,
        message: "",
      },
    ];
    const ref = this.alertService.customDialog(
      "Verify profession",
      "",
      "SUBMIT",
      "",
      true,
      "vertical",
      payload
    );
    ref.afterClosed().subscribe((result) => {
      if (!result || !result.payload) {
        return;
      }

      const instance = this;
      let shouldVerify = true;
      if (result.type == "verify_later") {
        shouldVerify = false;
      }
      this.alertService.showSnackBar("Please wait...", 4);
      this.saveProfession(
        result.payload["category"],
        result.payload["profession"],
        result.payload["registrationName"],
        result.payload["registrationNumber"],
        result.payload["registrationLink"],
        shouldVerify,
        function (resp) {
          if (resp) {
            if (shouldVerify) {
              instance.startProfessionVerificationSuccessFlow();
            } else {
              instance.alertService.showSnackBar("Profession saved", 3);
            }
          }
        }
      );
    });
  }

  startProfessionVerificationSuccessFlow() {
    const payload = [
      {
        type: "verifyProfessionSuccess",
        message: "",
      },
    ];
    const ref = this.alertService.customDialog(
      "",
      "",
      "DONE",
      "",
      true,
      "vertical",
      payload
    );
  }

  listenToUserDND(userId: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.connectionService.userDND$.subscribe((userDND) => {
      if (userDND.userId == userId) {
        this.profile["doNotDisturbToUtc"] = userDND.doNotDisturbToUtc;
        // this.setDND()
      }
    });
  }

  joinWorkspace() {
    const instance = this;
    this.workspaceService.joinWorkspaceFlow(function (result) {
      if (!result) {
        return;
      }

      if (result["type"] == "create_workspace") {
        return instance.createWorkspace();
      }

      instance.alertService.showSnackBar(
        "Workspace has been added successfully",
        3
      );

      instance.profile = result;
      instance.companies = instance.sharedService.getCompaniesFromProfile(
        instance.profile
      );
      instance.userAccountService.getUserAccount(true, function (user) {
        instance.profile = user;
      });
    });
  }

  createWorkspace() {
    const instance = this;
    this.workspaceService.createWorkspaceFlow(function (result) {
      if (!result) {
        return;
      }

      if (result["type"] == "join_workspace") {
        return instance.joinWorkspace();
      }
      instance.alertService.showSnackBar(
        "Workspace has been created successfully",
        3
      );
      instance.userAccountService.getUserAccount(true, function (user) {
        instance.profile = user;
        instance.companies = instance.sharedService.getCompaniesFromProfile(
          instance.profile
        );
      });
    });
  }

  shareProfile() {
    this.profileService.shareProfile();
  }

  close() {
    if (this.isEdit) {
      this.isEdit = false;
    }
  }

  editCompany(workspace) {
    const instance = this;
    this.userAccountService.editCompany(
      workspace,
      this.companies,
      function (data) {
        // return;
        if (data) {
          if (data.type == "update_and_leave") {
            instance.getEmail(data.type, data.workspace);
            return;
          } else if (data.type == "delete") {
            const emails =
              instance.workspaceService.getValidEmailsFromProfile(data);
            instance.sharedService.onWorkspaceRemoveSuccess(data, emails);
          }
          instance.profile = data;
          instance.companies = instance.sharedService.getCompaniesFromProfile(
            instance.profile
          );
        } else {
          return;
        }
      }
    );
  }

  getPhone() {
    const dialogRef = this.dialog.open(PhoneChangeDialogComponent, {
      data: { countryCode: "+64" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.getCode(result.countryCode, result.phoneNumber);
    });
  }

  initUploader() {
    this.uploader = this.fileUploadService.uploader;
    const uploadUrl = `${environment.celoApiEndpoint}/api/v2/account`;
    this.uploader = new FileUploader({
      url: uploadUrl,
      queueLimit: 1,
      method: "put",
      allowedMimeType: ["image/png", "image/jpeg"],
    });

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      if (filter?.name !== "mimeType") {
        AlertComponent.openErrorDialog(this.dialog);
        return;
      }
      AlertComponent.openErrorDialog(
        this.dialog,
        "Please select an image in JPG or PNG format."
      );
    };
    this.uploader.onAfterAddingFile = this.onAfterAddingFile.bind(this);
    this.uploader.onCompleteAll = this.onCompleteAll.bind(this);
    this.uploader.onErrorItem = this.onErrorItem.bind(this);
    this.uploader.onSuccessItem = this.onSuccessItem.bind(this);

    const data = {
      isOnCall: this.isOnCall ? "true" : "false",
      title: this.profile.title,
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      email: this.profile.email,
      phoneNumber: this.profile.phoneNumber,
      position: this.profile.position,
      departmentId: this.profile.department
        ? this.profile.department.id
        : undefined,
    };

    const options: FileUploaderOptions = {
      additionalParameter: {
        data: JSON.stringify(data),
      },
      headers: [],
      url: uploadUrl,
    };
    options.headers.push({
      name: "CeloAuthorization",
      value: environment.celoAuth,
    });
    options.headers.push({
      name: "celo-region",
      value: this.authService.getDataRegion(),
    });
    options.headers.push({ name: "AppVersion", value: environment.appVersion });
    this.uploader.setOptions(options);
  }

  handleNewPhotoSelected() {
    this.alertService.showSnackBar(
      "Uploading profile picture. Please wait...",
      3
    );
    this.uploader.authToken = `Bearer ${this.authService.getAccessToken()}`;
    this.uploader.uploadAll();
  }

  getCode(countryCode, phoneNumber) {
    const dialogRef = this.dialog.open(PhoneCodeDialogComponent, {
      data: {
        countryCode,
        phoneNumber,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadProfile(true);
      if (!result) {
        return;
      }
      this.phoneVerified();
    });
  }

  phoneVerified() {
    const dialogRef = this.dialog.open(PhoneVerifiedDialogComponent, {});
  }

  getEmail(mode?, workspace?) {
    const dialogRef = this.dialog.open(EmailChangeDialogComponent, {
      data: { countryCode: "+64" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      this.getEmailCode(
        result.email,
        result["verificationId"],
        mode,
        workspace
      );
    });
  }

  getEmailCode(email, verificationId, mode, workspace) {
    const dialogRef = this.dialog.open(EmailCodeDialogComponent, {
      data: {
        email,
        verificationId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadProfile(true);
      const instance = this;
      if (!result) {
        return;
      }
      if (mode == "update_and_leave") {
        this.userAccountService.deleteWorkspace(
          workspace,
          false,
          function (data) {
            if (!data) {
              // instance.alertService.confirm('',instance.sharedService.STANDARD_ERROR_MESSAGE)
              return;
            }
            // instance.loadProfile(true);
            instance.handleProfile(data);
            const emails =
              this.workspaceService.getValidEmailsFromProfile(data);
            instance.sharedService.onWorkspaceRemoveSuccess(data, emails);
          }
        );
        return;
      }
      this.emailVerified();
    });
  }

  emailVerified() {
    const dialogRef = this.dialog.open(EmailVerifiedDialogComponent, {});
  }

  editPreferredName() {
    const instance = this;
    this.userAccountService.editPreferredName(
      this.profile.firstName,
      function (resp) {
        if (resp) {
          instance.profile = resp;
          instance.handleProfile(instance.profile);
        }
      }
    );
  }

  onEditFullName() {
    const instance = this;
    this.userAccountService.editFullName(
      this.profile.legalFirstName,
      this.profile.lastName,
      function (result) {
        instance.handleNameChangeAction(result);
      }
    );
  }

  getProfessionVerificationStatus() {
    let status = "";
    status = this.profile.professions[0]?.verificationStatus;
    return status;
  }

  handleNameChangeAction(action) {
    if (this.nameChangeAllowed(action)) {
      return this.changeName(action);
    }
    if (this.profile.identityVerificationStatus == "Pending") {
      return this.alertService.confirm(
        "We are currently reviewing your identity verification",
        "You cannot change your name at this time. If you need help, contact us at support@celohealth.com.",
        "OK",
        "",
        true
      );
    }
    if (
      this.profile.identityVerificationStatus == "Verified" ||
      this.getProfessionVerificationStatus() == "Verified" ||
      this.getProfessionVerificationStatus() == "Pending"
    ) {
      return this.alertService
        .confirm(
          "Are you sure you want to change your full name?",
          "If you continue, you will lose your identity verification and any profession associated with that identity. You can verify your identity again later.",
          "CHANGE NAME",
          "CANCEL",
          false
        )
        .subscribe((result) => {
          if (result) {
            this.changeName(action);
          }
        });
    }
    // this.changeName(action);
  }

  changeName(action) {
    const instance = this;
    const payload = {};
    if (action["legalFirstName"]) {
      payload["legalFirstName"] = action["legalFirstName"];
    }
    if (action["lastName"]) {
      payload["lastName"] = action["lastName"];
    }
    this.userAccountService.save(payload, function (resp) {
      if (resp) {
        instance.profile = resp;
        instance.handleProfile(instance.profile);
      }
    });
  }

  nameChangeAllowed(action) {
    if (
      this.profile.identityVerificationStatus != "Pending" &&
      this.profile.identityVerificationStatus != "Verified" &&
      this.getProfessionVerificationStatus() != "Pending" &&
      this.getProfessionVerificationStatus() != "Verified"
    ) {
      return true;
    }
    if (
      (action.legalFirstName &&
        action.legalFirstName.toLowerCase() !=
          action.legalFirstNameOld.toLowerCase()) ||
      (action.lastName &&
        action.lastName.toLowerCase() != action.lastNameOld.toLowerCase())
    ) {
      return false;
    }
    return true;
  }

  editTitle() {
    const instance = this;
    this.userAccountService.editTitle(this.profile.title, function (resp) {
      if (resp) {
        instance.profile = resp;
      }
    });
  }
}

@Component({
  selector: "edit-phone",
  templateUrl: "edit-phone.html",
  styleUrls: [
    "edit-phone.scss",
    "edit.scss",
    "../../../../celo-input.scss",
    "dialog.scss",
  ],
})
export class PhoneChangeDialogComponent {
  submitting: boolean;
  flags = {
    gb: "../../../assets/flag_uk.png",
    au: "../../../assets/flag_au.png",
    nz: "../../../assets/flag_nz.png",
  };

  selected: any;
  countries: any;

  constructor(
    public dialogRef: MatDialogRef<PhoneChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userAccountService: UserAccountService,
    private sharedService: SharedService
  ) {
    let instance = this;
    this.sharedService.getSupportedCountries(false, function (countries) {
      instance.countries = countries;
      instance.selected = instance.countries[0];
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  change(event) {}

  verifyNumber(phoneNumber) {
    this.submitting = true;
    const countryCode = this.selected["callingPrefix"];
    this.userAccountService.verifyNumber(countryCode, phoneNumber, (resp) => {
      this.submitting = false;
      if (resp) {
        this.dialogRef.close({
          countryCode,
          phoneNumber,
        });
      }
    });
  }
}

@Component({
  selector: "code-phone",
  templateUrl: "code-phone.html",
  styleUrls: [
    "code-phone.scss",
    "code.scss",
    "../../../../celo-input.scss",
    "dialog.scss",
  ],
})
export class PhoneCodeDialogComponent {
  submitting: boolean;

  constructor(
    public dialogRef: MatDialogRef<PhoneCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private alertService: AlertService,
    private userAccountService: UserAccountService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  verifyCode(code, countryCode, phoneNumber) {
    this.submitting = true;
    const path = environment.celoApiEndpoint + "/api/Account/Phone/Verify";
    const params = {
      code,
      countryCode,
      phoneNumber,
    };
    this.sharedService.putObjectById(path, {}, params).subscribe(
      (resp) => {
        this.submitting = false;
        this.dialogRef.close(this.data);
      },
      (err) => {
        this.submitting = false;
        let message = "Unable to verify mobile number";
        err = err.error ? err.error : err;
        message = err.message ? err.message : message;
        this.alertService.confirm("", message, "", "Cancel", false);
      }
    );
  }

  verifyNumber(countryCode, phoneNumber) {
    this.userAccountService.verifyNumber(
      countryCode,
      phoneNumber,
      function (resp) {
        if (resp) {
        }
      }
    );
  }
}

@Component({
  selector: "phone-verified",
  templateUrl: "phone-verified.html",
  styleUrls: [
    "phone-verified.scss",
    "verified.scss",
    "../../../../celo-input.scss",
    "dialog.scss",
  ],
})
export class PhoneVerifiedDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PhoneVerifiedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "edit-email",
  templateUrl: "edit-email.html",
  styleUrls: ["edit.scss", "../../../../celo-input.scss", "dialog.scss"],
})
export class EmailChangeDialogComponent {
  submitting: boolean;

  constructor(
    public dialogRef: MatDialogRef<EmailChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userAccountService: UserAccountService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  verifyEmail(email) {
    this.submitting = true;
    const instance = this;
    this.userAccountService.verifyEmail(email, function (resp) {
      instance.submitting = false;
      if (resp) {
        instance.data["verificationId"] = resp["verificationId"];
        instance.dialogRef.close(instance.data);
      }
    });
  }
}

@Component({
  selector: "code-email",
  templateUrl: "code-email.html",
  styleUrls: [
    "code-email.scss",
    "edit.scss",
    "../../../../celo-input.scss",
    "dialog.scss",
  ],
})
export class EmailCodeDialogComponent {
  submitting: boolean;

  constructor(
    public dialogRef: MatDialogRef<EmailCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedService: SharedService,
    private alertService: AlertService,
    private userAccountService: UserAccountService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  verifyCode(code, email) {
    this.submitting = true;
    const path =
      environment.celoApiEndpoint + "/api/Account/EmailVerifications/Check";
    const params = {
      code,
      email,
      verificationId: this.data["verificationId"],
      updatePrimaryEmail: true,
    };
    this.sharedService.postObjectById(path, {}, params).subscribe(
      (resp) => {
        this.submitting = false;
        this.dialogRef.close(this.data);
      },
      (err) => {
        this.submitting = false;
        let message = "Unable to verify email";
        err = err.error ? err.error : err;
        message = err.message ? err.message : message;
        this.alertService.confirm("", message, "", "Cancel", false);
      }
    );
  }

  verifyEmail(email) {
    this.userAccountService.verifyEmail(email, (resp) => {
      if (!resp) return;
      this.alertService.showSnackBar("We’ve sent a code to " + email, 3);
    });
  }
}

@Component({
  selector: "email-verified",
  templateUrl: "email-verified.html",
  styleUrls: [
    "email-verified.scss",
    "verified.scss",
    "../../../../celo-input.scss",
    "dialog.scss",
  ],
})
export class EmailVerifiedDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EmailVerifiedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
