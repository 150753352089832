import { ApplicationInsightsService } from "./../../core/services/application-insights.service";
import { UserService } from "./../../core/services/user.service";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NetworkParams } from "app/directory/network/network/network.component";
import {
  AlertService,
  ConService,
  ConversationService,
  SharedService,
} from "../../core";
import { ProfileService } from "./../profile.service";
import { ICustomProperties } from "@microsoft/applicationinsights-web";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: [
    "./profile.component.scss",
    "../../../directory/network/network-style.scss",
    "./profile.scss",
  ],
})
export class ProfileComponent implements OnInit, OnDestroy, OnChanges {
  @Input() template: string = "type1";

  @Input() contact: any;
  @Input() userAccount: any;
  @Input() contactId: string;
  @Input() isDirectory: boolean;
  @Input() hideSecureMessageButton = false;
  @Output() userDataChanged = new EventEmitter<any>();
  @Output() accept = new EventEmitter<string>();

  @Output() exit = new EventEmitter();
  hasCommanWorkspace = false;

  companiesInfo = [];
  companies = [];
  chatId = "";
  loading = true;
  now: Date;
  dndUntil: any;
  isOnDnd: boolean;
  subscription: any;
  creatingChat: boolean;
  myCompaniesAndPartnerCompanies = [];
  ConnectionChangeSub: any;
  BlockChangeSub: any;
  companiesLoaded: boolean;
  // blocked: boolean;
  public isCurrentUser: boolean = false;

  constructor(
    private conService: ConService,
    private conversationService: ConversationService,
    private sharedService: SharedService,
    private connectionService: ConService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    public profileService: ProfileService,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private appInsightsService: ApplicationInsightsService
  ) {}

  ngOnInit() {
    this.now = new Date();
    this.ConnectionChangeSub = this.conService.ConnectionChange.subscribe(
      (connection) => {
        if (
          connection &&
          connection.connection &&
          (connection.connection.createdBy == this.contactId ||
            connection.connection.userId == this.contactId)
        ) {
          this.getContactData(this.contactId);
        }
      }
    );
    this.BlockChangeSub = this.connectionService.BlockChange.subscribe(
      (block) => {
        if (
          block &&
          (block.blockerId == this.contactId || block.userId == this.contactId)
        ) {
          return this.getContactData(this.contactId);
        }
      }
    );
    this.route.params.subscribe((params: NetworkParams) => {
      if (!params.contactId) {
        return;
      }
      this.contactId = params.contactId;
    });
  }

  ngOnDestroy() {
    if (this.contact && this.contact.userId) {
      this.unsubscribeUserStatus(this.contact.userId);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.ConnectionChangeSub) {
      this.ConnectionChangeSub.unsubscribe();
    }
    if (this.BlockChangeSub) {
      this.BlockChangeSub.unsubscribe();
    }
  }

  ngOnChanges() {
    this.getCompanies();
    if (this.contact) {
      this.init();
    }
    if (this.contactId) {
      this.getContactData(this.contactId);
    }

    this.isCurrentUser =
      (this.contact?.userId ?? this.contactId) === this.userService.getUserId();
  }

  getContactData(contactId) {
    const i = this;
    i.loading = true;
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    this.conversationService.getUserById(contactId, function (contact) {
      i.loading = false;
      if (contact) {
        i.contact = contact;
        i.init();
      }
    });
  }

  private logSecureMessageException(properties?: ICustomProperties) {
    this.appInsightsService.trackException(
      new Error("Failed to create conversation"),
      { ...properties, tag: "secureMessageException" }
    );
  }

  secureMessage(chatId) {
    if (this.isCurrentUser) {
      this.conversationService.navigateToSelfChat();
      return;
    }

    if (!this.contact.userId || !this.conService.userId || !chatId) {
      this.alertService.showSnackBar(
        "Something went wrong, please try again.",
        3
      );
      this.logSecureMessageException({
        "contact.userId": this.contact.userId,
        "conService.userId": this.conService.userId,
        chatId,
      });
      return;
    }

    this.creatingChat = true;
    this.conversationService
      .createChat([this.contact.userId, this.conService.userId], chatId)
      .subscribe(
        (data) => {
          if (data) {
            this.sharedService.navigateByUrl(
              "/conversations/" + chatId + "/messages"
            );
          }
        },
        (err) => {
          this.alertService.showSnackBar(
            "Something went wrong, please try again.",
            3
          );
          this.creatingChat = false;
          this.logSecureMessageException({
            "contact.userId": this.contact.userId,
            "conService.userId": this.conService.userId,
            chatId,
            innerError: err,
          });
        }
      );
  }

  listenToUserDND(userId: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.connectionService.userDND$.subscribe((userDND) => {
      if (userId == userDND.userId) {
        this.contact["doNotDisturbToUtc"] = userDND["doNotDisturbToUtc"];
        this.setDnd();
      }
      if (this.changeDetectorRef) {
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  init() {
    //to remove
    // this.contact.identityVerificationStatus = 'Verified'
    // this.contact.identityVerificationStatus = 'Pending'
    // this.contact['professions'] =  this.sharedService.dummyProfessions;

    this.loading = false;
    this.companies = this.sharedService.getCompaniesInfo(this.contact);
    if (this.contact && this.contact.userId) {
      this.chatId = this.getChatId(this.contact.userId);
      this.addSubscriptionToUserStatus(this.contact.userId);
      this.listenToUserDND(this.contact.userId);
      this.setDnd();
    }
    if (this.contact) {
      this.hasCommanWorkspace = this.sharedService.checkCommonWorkspace(
        this.contact["workplaces"],
        this.myCompaniesAndPartnerCompanies
      );
    }
    if (this.changeDetectorRef) {
      this.changeDetectorRef.detectChanges();
    }
  }

  getCompanies() {
    const instance = this;
    this.sharedService.getPartneredCompanies(function (companies) {
      instance.myCompaniesAndPartnerCompanies = companies.data;
      if (instance.contact) {
        instance.hasCommanWorkspace =
          instance.sharedService.checkCommonWorkspace(
            instance.contact["workplaces"],
            instance.myCompaniesAndPartnerCompanies
          );
      }
      instance.companiesLoaded = true;
    });
  }

  addSubscriptionToUserStatus(userId) {
    this.connectionService.subscribeUserStatus([userId]);
  }

  unsubscribeUserStatus(userId) {
    this.connectionService.unsubscribeUserStatus([userId]);
  }

  setDnd() {
    this.dndUntil = undefined;
    if (this.contact["doNotDisturbToUtc"]) {
      this.dndUntil = new Date(this.contact["doNotDisturbToUtc"]);
    }
  }

  getChatId(receiver_id) {
    return this.conService.getChatId(receiver_id);
  }

  removeConnection(connection) {
    const instance = this;
    this.profileService.deleteConnection(connection.id, function (res) {
      instance.contact["connection"] = null;
      instance.profileService.ConnectionRemovedSubject.next(connection);
    });
  }

  connect() {
    const instance = this;
    this.profileService.sendConnectionRequest(
      this.contact.userId,
      function (res) {
        if (res["connection"]) {
          instance.contact["connection"] = res["connection"];
        }
      }
    );
  }

  acceptConnection(connection) {
    const instance = this;
    this.profileService.acceptConnectionRequest(connection.id, function (res) {
      if (res["connection"]) {
        instance.contact["connection"] = res["connection"];
      }
      instance.accept.emit(connection.id);
    });
  }

  onBlockStatusChange(userId) {
    const instance = this;
    if (this.contact["blockedByMe"] && this.contact["blockedByMe"].isBlocked) {
      this.profileService.unblock(
        this.contact["blockedByMe"].id,
        function (res) {
          instance.contact["blockedByMe"] = {
            id: res.id,
            isBlocked: false,
            lastModifiedOnUtc: res.updatedOnUtc,
          };
          instance.contact["connection"] = undefined;
          //  instance.userDataChanged.emit();
        }
      );
      return;
    }
    this.block(userId);
  }

  block(userId) {
    const instance = this;
    this.alertService
      .confirm(
        "Block " +
          instance.contact.firstName +
          " " +
          instance.contact.lastName +
          "?",
        "Blocked contacts will no longer be able to contact you."
      )
      .subscribe((data) => {
        if (!data) {
          return;
        }
        this.profileService.block(userId, function (res) {
          instance.contact["blockedByMe"] = {
            id: res.id,
            isBlocked: true,
            lastModifiedOnUtc: res.updatedOnUtc,
          };
          instance.contact["connection"] = undefined;
          // instance.userDataChanged.emit();
        });
      });
  }

  userUnavailableDialog() {
    this.sharedService.userUnavailableDialog();
  }

  showSecureMessageButton() {
    if (this.isCurrentUser) return true;
    return (
      this.chatId &&
      !this.hideSecureMessageButton &&
      ((this.contact["connection"] &&
        this.contact["connection"].state == "Accepted") ||
        this.hasCommanWorkspace)
    );
  }
}
