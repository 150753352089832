<mat-sidenav-container class="fh notifications-page">
  <mat-sidenav
    id="sidenavContact"
    
    #sidenavContact
    mode="over"
    (closed)="onSidenavClose()"
    [opened]="profile_id ? true : false"
    position="end"
  >
    <div class="appear_1">
      <app-profile
        *ngIf="userAccount"
        [contactId]="profile_id"
        [isDirectory]="true"
        [userAccount]="userAccount"
        (exit)="sidenavContact.close()"
        (accept)="onAccept($event)"
      >
      </app-profile>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="fh page">
    <div class="page-content">
      <div class="side content"></div>
      <div class="middle content">
        <div class="title">Activity Feed</div>
        <app-activity-feed-list></app-activity-feed-list>
        <div *ngIf="notificationsService.hasNextPage">
          <button
            *ngIf="!isLoadingMore; else loadingMoreActivities"
            class="fw celo-hoverable"
            mat-button
            (click)="loadMoreActivities()"
          >
            Load more
          </button>
          <ng-template #loadingMoreActivities>
            <app-basic-spinner></app-basic-spinner>
          </ng-template>
        </div>
      </div>
      <div class="side content">
        <div class="title">Connection Requests</div>
        <div
          *ngIf="
            profileService.pendingConnections &&
            profileService.pendingConnections.length
          "
        >
          <app-connections-list
            *ngIf="userAccount"
            [userAccount]="userAccount"
            [list]="profileService.pendingConnections"
            [floatRight]="false"
          ></app-connections-list>
          <div
            *ngIf="
              profileService.pendingConnections &&
              profileService.pendingConnections.length &&
              profileService.pendingConnections.length < total
            "
          >
            <button class="fw celo-hoverable" mat-button (click)="loadMore()">
              Load more
            </button>
          </div>
        </div>
        <div
          *ngIf="
            !profileService.pendingConnections ||
            !profileService.pendingConnections.length
          "
          class="subtitle"
        >
          No pending connection requests.
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
