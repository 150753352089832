import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Observable, of } from "rxjs";
import { UserSelectionList } from "../../shared/basic-user-selection-list/basic-user-selection-list.component";
import { SelectionListType, BasicUserPickerService } from "../basic-user-picker.service";
import { UserSelectionListItem } from "./../../shared/basic-user-selection-list/basic-user-selection-list.component";
import { SelectionList } from "../basic-user-picker.service";

@Component({
  selector: "app-user-picker-selection-list",
  templateUrl: "./user-picker-selection-list.component.html",
  styleUrls: ["./user-picker-selection-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPickerSelectionListComponent implements OnInit {
  @Input() public group: SelectionList | null = null;
  @Input() public multiple: boolean = true;
  @Input() public hideLabels: boolean = false;

  public readonly SelectionListType = SelectionListType;

  public hideTeams$: Observable<boolean> = of(false);

  public constructor(private userPickerService: BasicUserPickerService) {}

  public ngOnInit(): void {
    this.hideTeams$ = this.userPickerService.hideTeams$;

    if (this.group && !this.group.isLoaded) {
      this.group.load();
    }
  }

  public handleChange(users: UserSelectionList) {
    this.userPickerService.updateUsers(users);
  }

  public load() {
    if (this.group && !this.group.isLoaded) {
      this.group.load();
    }
  }

  public subgroupTrackBy(index: number, group: SelectionList) {
    return group.id;
  }

  public handleUser(user: UserSelectionListItem) {
    this.userPickerService.onUserSelected(user);
  }
}
