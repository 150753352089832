import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AccountService, AnalyticsService } from "@modules/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { LibraryService } from "../library.service";
import { LibrarySelectImgsComponent } from "./../library-select-imgs/library-select-imgs.component";

@Component({
  selector: "app-library-secondbar-toolbar",
  templateUrl: "./library-secondbar-toolbar.component.html",
  styleUrls: ["./library-secondbar-toolbar.component.scss"],
})
export class LibrarySecondbarToolbarComponent implements OnChanges, OnInit {
  @Input() pageName: string;
  @Input() singleFileId: string;
  @Input() singleFileName: string;
  @Input() singleFileType: string;
  @Input() selectedFileCount: number;
  @Input() hideExportToClinicalRecord: boolean = true;
  singleFileDownloadUrl: string;
  // @Output() startSelect = new EventEmitter();
  // @Output() cancelSelect = new EventEmitter();

  // todo: add outputs for selectall, download, delete

  isSelecting = false;

  public isPhotoExportEnabled$: Observable<boolean> | null = null;

  constructor(
    private libraryService: LibraryService,
    private analyticsService: AnalyticsService,
    private accountService: AccountService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isPhotoExportEnabled$ = this.accountService.userAccount$.pipe(
      map((s) => s?.features?.photoExport ?? false)
    );
  }

  ngOnChanges() {
    this.singleFileDownloadUrl = "";
    if (this.singleFileId) {
      switch (this.singleFileType) {
        case "media":
          this.singleFileDownloadUrl = `${environment.celoApiEndpoint}/api/v2/media/${this.singleFileId}`;
          break;
        case "consent":
          this.singleFileDownloadUrl = `${environment.celoApiEndpoint}/api/consents/${this.singleFileId}`;
          break;
        case "document":
          this.singleFileDownloadUrl = `${environment.celoApiEndpoint}/api/patientfiles/${this.singleFileId}`;
          break;

        default:
          break;
      }
    }
  }

  onAction(action: string) {
    if (this.pageName === "Media") {
      this.libraryService.emitPhotoAction(action);
    } else if (this.pageName === "Documents") {
      this.libraryService.emitDocumentAction(action);
    } else if (this.pageName === "Consents") {
      this.libraryService.emitConsentAction(action);
    }
    if (action == "share" && this.pageName == "Media") {
      const params = {
        flow: "secure_library",
        source: "secure_library_direct",
        number_of_files: this.selectedFileCount,
      };
      this.analyticsService.buttonClickEvent("start_share_media", params);
      if (action == "share" && this.pageName == "Media") {
        // const params = {
        //   flow:'secure_library',
        //   source:'secure_library_direct',
        //   number_of_files:this.selectedFileCount
        // }
        // this.analyticsService.buttonClickEvent('start_share_media',params)
      }
    }
  }

  start() {
    this.isSelecting = true;
    this.libraryService.emitCanSelect(this.isSelecting);
  }

  cancel() {
    this.isSelecting = false;
    this.libraryService.emitCanSelect(this.isSelecting);
    this.libraryService.emitCanSelectReset(true);
  }

  public export() {
    LibrarySelectImgsComponent.openDialog(this.matDialog, {
      disableExportedFiles: true,
      onlyShowPatientFiles: true,
      title: "Select media to export",
      isExportFlow: true,
    })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result) return;
          alert(JSON.stringify(result, null, 2));
        },
      });
  }
}
