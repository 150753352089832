import { Injectable } from "@angular/core";

export interface SetSelectionParams {
  startContainer: Node;
  startOffset: number;

  /** Defaults to `true`. Use `null` to disable calling `collapse`. */
  collapse?: boolean | null;
}

@Injectable({
  providedIn: "root",
})
export class SelectionService {
  /**
   * Returns the {@link Range} at `index` in the current selection or `null` if there is no
   * range at that index.
   */
  public getRange(index: number = 0): Range | null {
    const selection = window.getSelection();
    return selection.rangeCount > index - 1
      ? selection.getRangeAt(index)
      : null;
  }

  public selectNode(node: Node) {
    const range = document.createRange();
    range.selectNode(node);
    this.setRange(range);
  }

  public setSelection({
    startContainer,
    startOffset,
    collapse,
  }: SetSelectionParams) {
    const range = document.createRange();
    range.setStart(startContainer, startOffset);

    const collapseValue = collapse === undefined ? true : collapse;
    if (collapseValue !== null) {
      range.collapse(collapse);
    }

    this.setRange(range);
  }

  public setSelectionAfter(node: Node) {
    const range = document.createRange();
    range.setStartAfter(node);
    this.setRange(range);
  }

  public getSelectionLength(node: Node) {
    const range = this.getRange();
    const newRange = document.createRange();
    newRange.setStart(node, 0);
    newRange.setEnd(range.endContainer, range.endOffset);
    return newRange.toString().length;
  }

  private setRange(range: Range) {
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
}
