import deepEqual from "deep-equal";
import { BehaviorSubject, distinctUntilChanged } from "rxjs";
import { MentionModel } from "types";
import { EditHistory, EditHistoryActionResult } from "utils/EditHistory";
import { InvalidMentionError } from "./InvalidMentionError";

export const isMentionValid = (
  content: string,
  mention: MentionModel
): boolean => {
  if (mention.index < 0) return false;

  const contentEndIndex = content.length - 1;
  if (mention.index > contentEndIndex) return false;

  const mentionEndIndex = mention.index + mention.name.length - 1;
  if (mentionEndIndex > contentEndIndex) return false;

  const substring = content.substring(mention.index, mentionEndIndex + 1);
  if (substring !== mention.name) return false;

  return true;
};


/**
 * Regex that matches any whitespace.
 */
export const hasWhitespaceRegex = /\s/;

/**
 * Regex that matches any string that starts with '@' and optionally has one or more 'letter' unicode characters after
 * it.
 *
 * For example, the following will be matched:
 *  - `@`
 *  - `@label`
 *  - `@テスト`
 *
 * The following will __not__ be matched:
 *  - `@😀`
 *  - `@one two`
 */
export const isValidMentionTextRegex = /^@\p{L}*$/u;

export enum EditMessageModelActionType {
  Mention = "Mention",
}

export interface EditMessageModelActionBase {
  type: EditMessageModelActionType;
}

export interface EditMessageModelMentionAction {
  type: EditMessageModelActionType.Mention;

  /** The text that triggered this mention action, e.g. '@Test' */
  matchedText: string;

  /** Index of where this mention begins */
  index: number;
}

// This is a discimating union (at the moment there is only one type but there may be more in the future)
export type EditMessageModelAction = EditMessageModelMentionAction;

/** Describes what triggered {@link EditMessageModel} to update it's state */
export enum EditMessageModelUpdateReason {
  Undo,
  Redo,
  Reset,
  Update,
  UpdateCaretPosition,
  InsertText,
  InsertMention,
  Clear
}

export interface EditMessageModelState {
  content: string;
  /** Ordered from first mention to last mention (lowest index to highest index) */
  mentions: MentionModel[];
  caretPosition: number;
  action?: EditMessageModelAction | null;
  /** What triggered this state change. This is `null` when the state is being initialized. */
  updateReason: EditMessageModelUpdateReason | null;
}

export interface CreateEditMessageModelParams {
  content?: string | null;
  mentions?: MentionModel[] | null;

  // Initial caret position. Defaults to the end of the given content (if any).
  caretPosition?: number | null;
}

export interface UpdateEditMessageModelParamsA {
  content: string;
  mentions: MentionModel[];
}

export interface UpdateEditMessageModelParams {
  content: string;
  mentions: MentionModel[];
  caretPosition?: number;
}

type UpdateEditMessageModelInternalParams = UpdateEditMessageModelParams & {
  updateReason: EditMessageModelUpdateReason
}

interface InsertEditMessageModelParams {
  startOffset: number;
  endOffset: number;
  text: string;
  mention?: MentionModel | null;
  updateReason: EditMessageModelUpdateReason
}

export class EditMessageModel {
  private stateSubject = new BehaviorSubject<EditMessageModelState>({
    caretPosition: 0,
    content: "",
    mentions: [],
    updateReason: null
  });

  public state$ = this.stateSubject.pipe(
    distinctUntilChanged((previous, current) =>
      deepEqual(previous, current, {
        strict: true,
      })
    )
  );

  private content: string = "";
  private mentions: MentionModel[] = [];
  private caretPosition: number = 0;
  private action: EditMessageModelAction | null = null;

  private editHistory: EditHistory<EditMessageModelState> = new EditHistory<EditMessageModelState>();

  public constructor(params?: CreateEditMessageModelParams) {
    this.content = params?.content ?? "";
    this.mentions = params?.mentions ?? [];

    this.validate(this.content, this.mentions);

    this.caretPosition = params?.caretPosition ?? this.content.length;
    if (this.caretPosition > this.content.length) {
      this.caretPosition = this.content.length;
    }

    this.updateState(null);
  }

  /**
   * Set this model's state to the last saved state from calling {@link save}.
   */
  public undo() {
    const result = this.editHistory.undo();
    this.handleEditHistoryActionResult(result, EditMessageModelUpdateReason.Undo);
  }

  /**
   * Set this model's state to the state it was at before the last call to {@link undo}.
   */
  public redo() {
    const result = this.editHistory.redo()
    this.handleEditHistoryActionResult(result, EditMessageModelUpdateReason.Redo);
  }

  private handleEditHistoryActionResult(result: EditHistoryActionResult<EditMessageModelState>, updateReason: EditMessageModelUpdateReason) {
    const { isChanged, currentState } = result;
    if (!isChanged) return;

    if (currentState) {
      this.updateInternal({
        content: currentState.content,
        mentions: currentState.mentions,
        caretPosition: currentState.caretPosition,
        updateReason
      });
    } else {
      this.clearInternal(updateReason)
    }
  }

  /**
   * Adds the current state to this model's history and clears the redo history.
   */
  public save() {
    const state = this.getState();
    this.editHistory.add(state);
  }

  /**
   * Reset this model.
   *
   * This does the following:
   *
   *  - Clears the edit history
   *  - Calls {@link update} with the provided params. If params are `null` or `undefined` {@link update} is
   *    called with parameters equivalent to calling the constructor with no parameters.
   */
  public reset(updateEditMessageModelParams?: UpdateEditMessageModelParams | null) {
    this.editHistory = new EditHistory();

    let updateParams: UpdateEditMessageModelInternalParams = {
      content: '',
      mentions: [],
      caretPosition: 0,
      updateReason: EditMessageModelUpdateReason.Reset
    };

    if (updateEditMessageModelParams) {
      updateParams = {
        ...updateEditMessageModelParams,
        updateReason: EditMessageModelUpdateReason.Reset
      }
    }

    this.updateInternal(updateParams);
  }

  /**
   * Update this model.
   */
  public update(updateEditMessageModelParams: UpdateEditMessageModelParams) {
    this.updateInternal({ ...updateEditMessageModelParams, updateReason: EditMessageModelUpdateReason.Update });
  }

  private updateInternal({
    content,
    caretPosition,
    mentions,
    updateReason
  }: UpdateEditMessageModelInternalParams) {
    this.validate(content, mentions);

    this.content = content;
    this.mentions = mentions;

    this.updateCaretPositionInternal(caretPosition ?? this.caretPosition, updateReason);
  }

  public updateCaretPosition(caretPosition: number) {
    this.updateCaretPositionInternal(caretPosition, EditMessageModelUpdateReason.UpdateCaretPosition);
  }

  private updateCaretPositionInternal(caretPosition: number, updateReason: EditMessageModelUpdateReason) {
    this.caretPosition = caretPosition;
    if (caretPosition > this.content.length) {
      this.caretPosition = this.content.length;
    }

    this.updateState(updateReason);
  }

  public insertMention(caretPosition: number, mention: MentionModel) {
    // Design requirement is to add an extra space after a mention is inserted
    // and position the cursor after the empty space
    const text = mention.name + ' '

    this.insert({
      startOffset: mention.index,
      endOffset: caretPosition,
      text,
      mention,
      updateReason: EditMessageModelUpdateReason.InsertMention
    });
  }

  public insertText(startOffset: number, endOffset: number, text: string) {
    this.insert({
      startOffset,
      endOffset,
      text,
      updateReason: EditMessageModelUpdateReason.InsertText
    })
  }

  /**
   * Note: `startOffset` and `endOffset` are caret offsets, these are slightly different to the index of characters
   * in a string. Be careful when comparing or using these values together.
   *
   * For example:
   * - "Hello" has characters in indexes from 0 to 4 (inclusive) but it has caret positions from 0 to 5 (inclusive)
   */
  private insert({ startOffset, endOffset, text, mention, updateReason }: InsertEditMessageModelParams) {
    const newContent =
      this.content.substring(0, startOffset) +
      text +
      this.content.substring(endOffset);

    let existingMentions = structuredClone(this.mentions);

    // If this is a text-only insertion remove any mentions that would be invalidated by this insertion
    if (!mention) {
      existingMentions = existingMentions.filter((existingMention => {
        const endIndex = existingMention.index + existingMention.name.length - 1

        // Mention starts before the inserted range ends and ends after the inserted range ends
        if (existingMention.index < endOffset && endIndex >= endOffset) return false;

        // Mention ends within the inserted range
        if (endIndex >= startOffset && endIndex < endOffset) return false;

        return true;
      }))
    }

    const mentions = mention ? [...existingMentions, mention] : existingMentions;

    // Update indexes of existing mentions
    const shift = text.length - (endOffset - startOffset);
    for (const existingMention of existingMentions) {
      if (existingMention.index < startOffset) continue;
      existingMention.index += shift;
    }

    mentions.sort((a, b) => a.index - b.index);

    const newCaretPosition = startOffset + text.length;

    this.updateInternal({
      content: newContent,
      mentions,
      caretPosition: newCaretPosition,
      updateReason
    });
  }

  public clear() {
    this.clearInternal(EditMessageModelUpdateReason.Clear)
  }

  public clearInternal(updateReason: EditMessageModelUpdateReason) {
    this.updateInternal({
      content: '',
      mentions: [],
      caretPosition: 0,
      updateReason
    })
  }

  public getState() {
    return this.stateSubject.value;
  }

  private validate(content: string, mentions: MentionModel[]) {
    for (const mention of mentions) {
      if (!isMentionValid(content, mention)) {
        throw new InvalidMentionError(content, mention);
      }

      const mentionEndIndex = mention.index + mention.name.length - 1;
      const isOverlapping = mentions.some((m) => {
        if (m.id === mention.id) return false;
        const mEndIndex = m.index + m.name.length - 1;
        return (
          Math.max(mention.index, m.index) <=
          Math.min(mentionEndIndex, mEndIndex)
        );
      });

      if (isOverlapping) {
        throw new InvalidMentionError(content, mention);
      }
    }
  }

  private getMentionAtCaretPosition(
    caretPosition: number
  ): MentionModel | null {
    const mention = this.mentions.find((m) => {
      const endIndex = m.index + m.name.length;
      return m.index < caretPosition && endIndex >= caretPosition;
    });
    return mention ?? null;
  }

  private shouldTriggerMentions(): boolean {
    // Check if there's an existing mention at the current caret position
    const existingMention = this.getMentionAtCaretPosition(this.caretPosition);

    if (existingMention) return false;

    // Check if there's an '@' symbol before the current caret position with no spaces inbetween
    const lastMentionSymbolIndex = this.content
      .substring(0, this.caretPosition)
      .lastIndexOf("@");

    if (lastMentionSymbolIndex === -1) return false;

    // Ensure the character before this '@' symbol (if any) is empty space
    if (
      lastMentionSymbolIndex !== 0 &&
      !hasWhitespaceRegex.test(this.content.at(lastMentionSymbolIndex - 1))
    ) {
      return false;
    }

    const mentionText = this.content.substring(
      lastMentionSymbolIndex,
      this.caretPosition
    );

    if (!isValidMentionTextRegex.test(mentionText)) return false;

    return true;
  }

  private getMentionAction(): EditMessageModelMentionAction {
    const lastMentionSymbolIndex = this.content
      .substring(0, this.caretPosition)
      .lastIndexOf("@");

    const matchedText = this.content.substring(lastMentionSymbolIndex, this.caretPosition);

    return {
      type: EditMessageModelActionType.Mention,
      index: lastMentionSymbolIndex,
      matchedText
    }
  }

  private updateState(updateReason: EditMessageModelUpdateReason | null) {
    this.action = null;

    if (this.shouldTriggerMentions()) {
      this.action = this.getMentionAction();
    }

    this.stateSubject.next({
      content: this.content,
      mentions: this.mentions,
      caretPosition: this.caretPosition,
      action: this.action,
      updateReason
    });
  }
}
