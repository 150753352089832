import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { SelectedDirective } from "./selected.directive";

export interface UserSelectionListItem {
  id: string;
  name: string | null;
  description?: string | null;
  isWorkspaceVerified?: boolean | null;
  isIdentityVerified?: boolean | null;
  isProfessionVerified?: boolean | null;
  isSelected: boolean;
  isDisabled: boolean;
  isTeam?: boolean;

  /** Whether to try retrieve this user's image from the cache or the remote server. Defaults to true. */
  fetchImage?: boolean;

  /** Appended to the end of `name` when being displayed. */
  suffix?: string | null;
}

export type UserSelectionList = UserSelectionListItem[];

@Component({
  selector: "app-basic-user-selection-list",
  templateUrl: "./basic-user-selection-list.component.html",
  styleUrls: ["./basic-user-selection-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicUserSelectionListComponent {
  @Input() public multiple: boolean = false;
  @Input() public users: UserSelectionList = [];
  @Input() public alwaysShowSelectedContent: boolean = false;
  @Input() public showDescription: boolean = true;
  @Input() public rippleDisabled: boolean = false;
  @Input() public leftContentMargin: string | null = null;
  @Input() public hoverDisabled: boolean = false;

  /** If true, this selection list will style itself as a group of radio buttons. */
  @Input() public radio: boolean = false;

  /** Emits the selected user when `multiple` is false and a user is selected. */
  @Output() public user: EventEmitter<UserSelectionListItem> =
    new EventEmitter();

  /** Emits the new selection list when `multiple` is true and a user is selected. */
  @Output() public usersChange: EventEmitter<UserSelectionList> =
    new EventEmitter();

  @ContentChild(SelectedDirective)
  public selectedContent!: SelectedDirective;

  public handleUserClick(user: UserSelectionListItem) {
    if (this.multiple) {
      if (user.isDisabled) return;
      const users = this.users.map((u) => ({ ...u }));
      const selectedUser = users.find((u) => u.id === user.id);
      selectedUser.isSelected = !selectedUser.isSelected;
      this.usersChange.emit(users);
    } else {
      if (user.isDisabled) {
        this.user.emit({ ...user });
      } else {
        this.user.emit({ ...user, isSelected: !user.isSelected });
      }
    }
  }

  public userTrackBy(index: number, user: UserSelectionListItem) {
    return user.id;
  }
}
