import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { SharedService } from "@modules/core";
import countryCodes from "country-codes-list";

interface Country {
  name: string;
  shortName: string;
}

const myCountryCodesObject: {
  [x: string]: string;
} = countryCodes.customList("countryCode", "{countryNameEn}");

@Component({
  selector: "app-celo-countries",
  templateUrl: "./celo-countries.component.html",
  styleUrls: ["./celo-countries.component.scss"],
})
export class CeloCountriesComponent implements OnInit, AfterViewInit {
  @Output() selected = new EventEmitter<any>();
  @Input() required: false;
  @Input() autofocus = false;

  @ViewChild("selectRef") selectRef: MatSelect;

  countries: Country[] = [];
  selectedCountries: Country[] = [];
  country: Country | null = null;

  constructor(private sharedService: SharedService) {}

  ngOnInit() {
    for (const key in myCountryCodesObject) {
      if (myCountryCodesObject.hasOwnProperty(key)) {
        this.countries.push({
          name: myCountryCodesObject[key],
          shortName: key.toLowerCase(),
        });
      }
    }

    this.sharedService.sortArrayByField(this.countries, "name");
    const instance = this;

    this.sharedService.getSupportedCountries(
      false,
      function (countries: any[]) {
        countries = countries.reverse();
        for (const country of countries) {
          if (country.isAvailable) {
            instance.sharedService.spliceArrayByFieldValue(
              instance.countries,
              "shortName",
              country.shortName
            );
            instance.countries.unshift(country);
          }
        }
        instance.selectedCountries = instance.countries;
      }
    );
  }

  ngAfterViewInit() {
    if (this.autofocus) {
      this.selectRef.focus();
    }
  }

  onKey(value) {
    this.selectedCountries = this.search(value);
  }

  search(value: string) {
    const filter = value.toLowerCase();
    return this.countries.filter(
      (country_ob) => country_ob.name.toLowerCase().indexOf(filter) != -1
    );
  }
}
