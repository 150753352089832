<ul>
  <div *ngIf="celoTemplate === 1" class="icons">
    <li [routerLinkActive]="['selected']" routerLink="company">
      <mat-icon class="celo-orange-color">home</mat-icon>
      <span>Company</span>
    </li>
  </div>
  <div *ngIf="celoTemplate === 2" class="circle-icons">
    <li [routerLinkActive]="['selected']" mat-ripple routerLink="photos">
      <button mat-icon-button class="photos celo-fab-button">
        <img src="../../../assets/icons/icon-photos-whitev2.svg" alt="" />
      </button>
      <span>Media</span>
    </li>
    <li [routerLinkActive]="['selected']" mat-ripple routerLink="consents">
      <button mat-icon-button class="consents celo-fab-button">
        <img src="../../../assets/icons/icon-consentsv2.svg" alt="" />
      </button>
      <span>Consents</span>
    </li>
    <li [routerLinkActive]="['selected']" mat-ripple routerLink="documents">
      <button mat-icon-button class="documents celo-fab-button">
        <img src="../../../assets/icons/icon-documentsv2.svg" alt="" />
      </button>
      <span>Documents</span>
    </li>
  </div>
  <div *ngIf="celoTemplate === 3" class="icons single-menu">
    <li [routerLinkActive]="['selected']" routerLink="dashboard">
      <mat-icon >dashboard</mat-icon>
      <span>Dashboard</span>
    </li>
  </div>
</ul>
