/**
 * Used to assert at compile time that a switch statement has handled all possible values.
 *
 * Also throws an error at runtime if this is ever called.
 *
 * @example
 * switch (value) {
 *     case "a":
 *         break;
 *     case "b":
 *         break;
 *     default:
 *         return assertIsExhaustive(value);
 * }
 */
export const assertIsExhaustive = (value: never): never => {
  throw new Error(
    "Switch statements must be exhaustive. Unhandled value:",
    value
  );
};
