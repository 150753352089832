<div class="container">
  <button mat-icon-button aria-label="Close" id="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <div matDialogTitle class="title">Description</div>

  <mat-dialog-content [innerHtml]="description | linkify"></mat-dialog-content>

  <mat-dialog-actions class="flex justify-end">
    <button class="celo-primary-button uppercase" mat-button matDialogClose>
      Done
    </button>
  </mat-dialog-actions>
</div>
