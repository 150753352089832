import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ConService,
  ConnectionStatus,
  ConversationsService,
} from "@modules/core";
import { Store } from "@ngrx/store";
import {
  ConversationsPageActions,
  conversationsFeature,
  selectInboxConversations,
  selectUserId,
} from "app/state";
import { config } from "configurations/config";
import {
  Observable,
  combineLatest,
  first,
  map,
  of,
  switchMap,
  tap,
  throttleTime,
} from "rxjs";
import { Conversation, FullUserProfileModel } from "types";
import { SubscriptionContainer, isNotNullOrUndefined } from "utils";
import { ConversationService, SharedService, SnackbarService } from "../core";
import { UserService } from "./../core/services/user.service";

@Component({
  selector: "app-conversations",
  templateUrl: "./conversations.component.html",
  styleUrls: ["./conversations.component.scss"],
})
export class ConversationsComponent implements OnInit, OnDestroy {
  userAccount: FullUserProfileModel;
  subjectFromMessages = null;

  private isInitialConversationsLoaded: boolean = false;

  private subscriptions = new SubscriptionContainer();

  public conversations$: Observable<Conversation[]> = of([]);
  public searchResults$: Observable<Conversation[]> = of([]);

  public isInboxLoading$: Observable<boolean> = of(false);
  public hasMore$: Observable<boolean> = of(false);

  public isSearchResultsLoading$: Observable<boolean> = of(false);
  public hasMoreSearchResults$: Observable<boolean> = of(false);

  public isLoadingMore$: Observable<boolean> = of(false);

  public searchQuery$: Observable<string | null> = of(null);

  constructor(
    private conversationService: ConversationService,
    public sharedService: SharedService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private conService: ConService,
    private store: Store
  ) {}

  ngOnInit() {
    this.isInboxLoading$ = this.store.select(selectUserId).pipe(
      switchMap((userId) => {
        return combineLatest([
          this.store.select(conversationsFeature.selectIsInboxLoading),
          userId ? this.store.select(selectInboxConversations(userId)) : of([]),
        ]);
      }),
      map(([isInboxLoading, conversations]) => {
        // Inbox is only considered loading if there are no conversations already loaded
        return isInboxLoading && !conversations.length;
      }),
      tap((isInboxLoading) => {
        if (isInboxLoading) return;
        this.isInitialConversationsLoaded = true;
      })
    );

    this.isSearchResultsLoading$ = this.store.select(
      conversationsFeature.selectIsInboxSearchLoading
    );

    this.isLoadingMore$ = combineLatest([
      this.store.select(conversationsFeature.selectIsInboxLoadingMore),
      this.store.select(conversationsFeature.selectIsInboxSearchLoadingMore),
    ]).pipe(
      map(
        ([isInboxLoadingMore, isInboxSearchLoadingMore]) =>
          isInboxLoadingMore || isInboxSearchLoadingMore
      )
    );

    this.hasMore$ = this.store
      .select(conversationsFeature.selectInboxPaginationData)
      .pipe(map((data) => data.hasNext));

    this.hasMoreSearchResults$ = this.store
      .select(conversationsFeature.selectInboxSearchResultsPaginationData)
      .pipe(map((data) => data.hasNext));

    this.searchQuery$ = this.store.select(
      conversationsFeature.selectInboxSearchQuery
    );

    this.searchResults$ = this.store.select(
      conversationsFeature.selectInboxSearchResults
    );

    // Load initial conversations after user has been loaded
    this.store
      .select(selectUserId)
      .pipe(first(isNotNullOrUndefined))
      .subscribe({
        next: (userId) => {
          if (!userId) return;

          this.conversations$ = this.store.select(
            selectInboxConversations(this.userService.getUserId(true))
          );

          // #TODO move this into an effect that fires once
          this.conversationService.getOrCreateSelfChat().subscribe();
        },
      });

    this.store.dispatch(ConversationsPageActions.inboxOpened());

    const userAccountSubscription = this.userService.currentUser$.subscribe({
      next: (userAccount) => (this.userAccount = userAccount),
    });

    const connectionStatusSubscription = this.conService.connectionStatus$
      .pipe(
        throttleTime(5000, null, {
          leading: true,
          trailing: true,
        })
      )
      .subscribe({
        next: (connectionStatus) => {
          if (connectionStatus !== ConnectionStatus.Connected) return;
          if (!this.isInitialConversationsLoaded) return;
          this.store.dispatch(ConversationsPageActions.inboxOpened());
        },
      });

    this.subscriptions.add(
      userAccountSubscription,
      connectionStatusSubscription
    );
  }

  search(query: string) {
    if (!query || query.length < config.minSearchLength) {
      this.store.dispatch(ConversationsPageActions.inboxSearchReset());

      const anchor = document.getElementById("topAnchor") as HTMLElement;
      this.scrollTo(anchor);

      // Only trigger if user has input anything
      if (query.length) {
        this.snackbarService.show(
          `Please enter at least ${config.minSearchLength} characters to begin your search.`
        );
      }
      return;
    }
    this.store.dispatch(
      ConversationsPageActions.inboxSearch({
        query,
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

    if (this.subjectFromMessages) {
      this.subjectFromMessages.unsubscribe();
    }
  }

  loadMore(isSearch: boolean) {
    if (!isSearch) {
      this.store.dispatch(ConversationsPageActions.inboxLoadMore());
      return;
    }
    this.store.dispatch(ConversationsPageActions.inboxSearchLoadMore());
  }

  scrollTo(el: HTMLElement) {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
