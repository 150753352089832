import dateFormat from "dateformat";
import { DateTime } from "luxon";

const MINIMUM_DATE_INCREMENT_MILLISECONDS = 1000;

/**
 * Null-safe date comparison with handling for strings representing a data.
 *
 * Items are sorted by most to least recent (descending order).
 *
 * Items that are null are sorted to the end of the list.
 */
export const dateCompareIfNotNull = (
  a: Date | string | null | undefined,
  b: Date | string | null | undefined
): number => {
  if (a && !b) return 1;
  if (b && !a) return -1;
  if (!a && !b) return 0;

  const dateA = new Date(a);
  const dateB = new Date(b);

  return dateA < dateB ? 1 : -1;
};

/**
 * Returns the date component of the given date in ISO 8601 format in the local timezone.
 *
 * This is mostly useful for things like date of birth where only the date component is needed in the users local
 * timezone.
 */
export const getISODateComponent = (
  value: string | Date | DateTime
): string => {
  if (DateTime.isDateTime(value)) {
    return value.toISODate();
  }

  const date =
    typeof value === "string" ? new Date(value) : (value as unknown as Date);
  return dateFormat(date, "yyyy-mm-dd");
};

/**
 * Returns true if given date is in the past and false if the given date is in the future.
 */
export const isPastDate = (date: string | Date) => {
  const until = new Date(date);
  const now = new Date();

  if (until > now) {
    return true;
  }

  return false;
};

/**
 * Returns a computed local time that is guaranteed to be after the given timestamp
 */
export const getComputedLocalTime = (minimumDate?: Date | null): Date => {
  const currentDate = new Date();

  if (!minimumDate) return currentDate;

  if (currentDate > minimumDate) {
    return currentDate;
  }

  const computedCreationTime = new Date(
    minimumDate.getTime() + MINIMUM_DATE_INCREMENT_MILLISECONDS
  );

  return computedCreationTime;
};

/**
 * Returns the minimum (oldest/least recent) date from the given list of `dates`.
 */
export const minDate = (dates: Date[]) => {
  if (dates.length === 0) throw new Error("array must not be empty");
  return dates.reduce((prev, curr) => (prev < curr ? curr : prev), dates[0]);
};

/**
 * Returns the maximum (latest/most recent) date from the given list of `dates`.
 */
export const maxDate = (dates: Date[]) => {
  if (dates.length === 0) throw new Error("array must not be empty");
  return dates.reduce((prev, curr) => (prev > curr ? prev : curr), dates[0]);
};
