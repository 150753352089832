<ng-container
  *ngIf="{
    initiallyExpandedWorkspaceIds: initiallyExpandedWorkspaceIds$ | async
  } as data"
>
  <ng-container
    *ngFor="let group of groups; let i = index; trackBy: groupTrackBy"
  >
    <div [hidden]="maxItems !== null && i >= maxItems">
      <!-- Expandable -->
      <ng-container *ngIf="group.isExpandable; else notExpandable">
        <app-user-picker-expandable-group
          [group]="group"
          [stickyHeaderTop]="38"
          [multiple]="multiple"
          [hideLabels]="hideLabels"
          [isInitiallyExpanded]="
            data.initiallyExpandedWorkspaceIds.has(group.id)
          "
        ></app-user-picker-expandable-group>
      </ng-container>

      <!-- Not Expandable -->
      <ng-template #notExpandable>
        <div
          class="sticky-container"
          *ngIf="(hideTeams$ | async) === false || !group.isTeams"
        >
          <div
            *ngIf="stickyGroupHeaders && (group.name || group.description)"
            class="sticky"
          >
            <h4 class="mat-body-2 name">
              {{ group.name }}
            </h4>
            <div *ngIf="group.description" class="mat-body celo-grey">
              {{ group.description }}
            </div>
            <mat-divider id="divider"></mat-divider>
          </div>

          <app-user-picker-no-suggestions
            *ngIf="
              isNetworkUserPicker &&
                group.id ===
                  UserSelectionListId.PEOPLE_IDENTITY_VERIFICATION_AND_DISCOVERABILITY &&
                group.data?.length === 0;
              else selectionList
            "
            [compact]="true"
          ></app-user-picker-no-suggestions>

          <ng-template #selectionList>
            <app-user-picker-selection-list
              [group]="group"
              [multiple]="multiple"
              [hideLabels]="hideLabels"
            ></app-user-picker-selection-list
          ></ng-template>
        </div>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
