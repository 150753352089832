import { inject } from "@angular/core";
import { AccountService, AuthService } from "@modules/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { mapResponse } from "@ngrx/operators";
import { exhaustMap, merge } from "rxjs";
import { AuthActions } from "../auth";
import { AccountApiActions } from "./user.actions";

const loadUser = createEffect(
  (
    actions$ = inject(Actions),
    accountService = inject(AccountService),
    authService = inject(AuthService)
  ) => {
    return merge(
      actions$.pipe(ofType(AuthActions.loginSuccess)),
      authService.isAuthenticated$.pipe((isAuthenticated) => isAuthenticated)
    ).pipe(
      exhaustMap(() => {
        return accountService.getAccount().pipe(
          mapResponse({
            next: (response) => {
              return AccountApiActions.loadUserSuccess({ user: response });
            },
            error: (error) => {
              return AccountApiActions.loadUserError({
                errors: [
                  {
                    code: "errorCodePlaceholder",
                    message: "errorMessagePlaceholder",
                  },
                ],
              });
            },
          })
        );
      })
    );
  },
  { functional: true }
);

export const userEffects = { loadUser };
