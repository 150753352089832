import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isHttpErrorResponse } from "@utils";
import { Observable, retry, throwError, timer } from "rxjs";

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  private readonly MAX_RETRY_COUNT = 3;

  public constructor() {}

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      // Retry any server errors
      retry({
        count: this.MAX_RETRY_COUNT,
        delay: (error, retryCount) => {
          if (!isHttpErrorResponse(error) || error.status < 500) {
            return throwError(() => error);
          }

          // Exponential backoff with a maximum delay of 5 seconds
          const delay = Math.min(2 ** retryCount * 500, 5000);
          return timer(delay);
        },
      })
    );
  }
}
