<div id="content" mat-dialog-content>
  <ng-container *ngIf="isLoading; else conversationDetails">
    <div id="conversation-details-loading">
      <app-loading-logo></app-loading-logo>
    </div>
  </ng-container>

  <ng-template #conversationDetails>
    <div id="avatar-container" [ngSwitch]="conversationType">
      <app-basic-celo-avatar
        *ngSwitchCase="'Chat'"
        [name]="conversationName"
        [userId]="otherParticipantUserId"
        [size]="84"
      />
      <app-roles-icon
        *ngSwitchCase="'TeamChat'"
        [backgroundSize]="'84px'"
        [size]="'67.2px'"
      />
      <app-celo-avatar
        *ngSwitchDefault
        [width]="84"
        [height]="84"
        [type]="conversationType"
        [conversationPictureUri]="this.conversationPhotoUrl"
      ></app-celo-avatar>
    </div>

    <h2 id="title" class="mat-title">
      {{ conversationName }}
    </h2>
  </ng-template>

  <p id="description">Incoming call</p>
</div>

<mat-dialog-actions align="center">
  <button
    mat-button
    class="celo-secondary-button uppercase"
    (click)="onDecline()"
  >
    Decline
  </button>
  <button
    mat-button
    class="celo-primary-button uppercase"
    (click)="onJoin()"
    [disabled]="isActioned"
  >
    Join
  </button>
</mat-dialog-actions>
