<mat-drawer-container>
  <div class="button-bar" (click)="drawer.opened ? drawer.close() : onClose()">
    <div class="flex items-center">
      <i mat-dialog-close class="material-icons-round m-2 ml-4" (click)="onClose(); $event.stopPropagation()">close</i>
    </div>
    <div class="flex items-center">
      <a *ngIf="type !== 'video' && canDownload" [href]="downloadUrl | secure" [download]="downloadFileName"
        (click)="$event.stopPropagation()">
        <button *ngIf="downloadUrl | secure" class="m-2" mat-icon-button>
          <mat-icon>file_download</mat-icon>
        </button>
      </a>
      <a *ngIf="type === 'video' && !isLoading" [href]="videoPath | secure" [download]="downloadFileName"
        (click)="$event.stopPropagation()">
        <button class="m-2" mat-icon-button>
          <mat-icon>download</mat-icon>
        </button>
      </a>
      <button (click)="onAction('share'); $event.stopPropagation()" *ngIf="type !== 'consent'" class="m-2"
        mat-icon-button>
        <mat-icon>share</mat-icon>
      </button>
      <button (click)="drawer.toggle(); $event.stopPropagation()" class="m-2" mat-icon-button>
        <mat-icon>info</mat-icon>
      </button>
    </div>
  </div>
  <div class="mid-button-bar">
    <button mat-icon-button *ngIf="content.prev" (click)="onMovePivot(false)">
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button mat-icon-button class="right" *ngIf="content.next" (click)="onMovePivot(true)">
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
  <div class="content" (click)="drawer.opened ? drawer.close() : onClose()">
    <div >
      <div class="col content-box px-2 text-center">
        <div *ngIf="type === 'video'">
          <div class="photo-panel" #videoContainer style="height: calc(100vh - 237px)">
            <video *ngIf="videoPath" [style.max-width.px]="videoContainer.offsetWidth" [autoplay]="isFromConversation"
              controls (click)="$event.stopPropagation()" (loadeddata)="isLoading = false" (play)="playing = true"
              (pause)="playing = false" #videoPlayer id="videoPlayer" [src]="videoPath | secure"
              [ngClass]="{ 'video-loading': isLoading }">
              Your browser does not support HTML video.
            </video>
            <div *ngIf="isLoading" class="loading-state">
              <app-empty-state [isLoading]="true" [message]="' '">
              </app-empty-state>
            </div>
            <div *ngIf="!isLoading" class="icon">
              <i *ngIf="!playing" class="material-icons play appear_1">play_arrow</i>
            </div>
            <div class="photo-details">
              <div class="cont" *ngIf="content.payload.creator">
                <div *ngIf="content.payload.description">
                  {{ content.payload.description }}
                </div>
                <div class="line" *ngIf="content.payload.description"></div>
                <div class="taken-by-grid">
                  <div class="left celo-elipsis">
                    <span *ngIf="content.payload.isImported">
                      Imported by:
                    </span>
                    <span *ngIf="!content.payload.isImported">
                      Taken by:
                    </span>
                    <span>
                      {{ content.payload.creator.title }}
                      {{ content.payload.creator.firstName }}
                      {{ content.payload.creator.lastName }}
                    </span>
                  </div>
                  <div class="right">
                    {{ content.payload.createdOn | dateFormat: "medium" }}
                  </div>
                </div>
                <div *ngIf="
                      content.payload.patientData &&
                      content.payload.patientData.uid
                    ">
                  Patient Details:
                  <span>{{ content.payload.patientData.uid }} </span>
                  <span *ngIf="content.payload.patientData.firstName">{{ content.payload.patientData.firstName }}
                  </span>
                  <span *ngIf="content.payload.patientData.lastName">{{ content.payload.patientData.lastName }}
                  </span>
                  <span *ngIf="content.payload.patientData.dateOfBirth">&nbsp;{{
                    content.payload.patientData.dateOfBirth
                    | dateFormat: "longDate"
                    }}</span>
                </div>
                <div class="right">
                  {{ content.payload.createdOn | date: "d/M/yyyy h:mm a" }}
                  (NZT)
                </div>
              </div>
              <div class="index p-1" *ngIf="mediaList && indexes.length">
                <div>{{ indexes.join(",") }} / {{ mediaList.length }}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="type === 'photo' && content.payload">
          <div class="photo-panel" style="height: calc(100vh - 237px)">
            <img [src]="
                  '/api/v2/media/' +
                    content.payload.id +
                    '?size=Full&' +
                    refreshCount
                    | clink
                    | secure
                " class="fh" (click)="$event.stopPropagation()" (load)="isLoading = false" [ngClass]="{
                  'hidden-loading': isLoading,
                  appear_1: !isLoading
                }" />
            <div *ngIf="isLoading" class="loading-state">
              <app-empty-state [isLoading]="true" [message]="' '">
              </app-empty-state>
            </div>
            <div class="photo-details">
              <div class="cont" *ngIf="content.payload.creator">
                <div *ngIf="content.payload.description">
                  {{ content.payload.description }}
                </div>
                <div class="line" *ngIf="content.payload.description"></div>
                <div class="taken-by-grid">
                  <div class="left celo-elipsis">
                    <span *ngIf="content.payload.isImported">
                      Imported by:
                    </span>
                    <span *ngIf="!content.payload.isImported">
                      Taken by:
                    </span>
                    <span>
                      {{ content.payload.creator.title }}
                      {{ content.payload.creator.firstName }}
                      {{ content.payload.creator.lastName }}
                    </span>
                  </div>
                  <div class="right">
                    {{ content.payload.createdOn | dateFormat: "medium" }}
                  </div>
                </div>
                <div *ngIf="
                      content.payload.patientData &&
                      content.payload.patientData.uid
                    ">
                  Patient Details:
                  <span>{{ content.payload.patientData.uid }} </span>
                  <span *ngIf="content.payload.patientData.firstName">{{ content.payload.patientData.firstName }}
                  </span>
                  <span *ngIf="content.payload.patientData.lastName">{{ content.payload.patientData.lastName }}
                  </span>
                  <span *ngIf="content.payload.patientData.dateOfBirth">&nbsp;{{
                    content.payload.patientData.dateOfBirth
                    | dateFormat: "longDate"
                    }}</span>
                </div>
              </div>
              <div class="index p-1" *ngIf="mediaList && indexes.length">
                <div>{{ indexes.join(",") }} / {{ mediaList.length }}</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="type === 'consent'" class="pdf-container">
          <pdf-viewer [src]="consentSource" (click)="$event.stopPropagation()" [original-size]="false"
            [autoresize]="true" [render-text]="false" [zoom]="0.95" style="height: 100%"></pdf-viewer>
          <div *ngIf="isLoading" class="loading-state">
            <app-empty-state [isLoading]="true" [message]="' '">
            </app-empty-state>
          </div>
        </div>
        <div *ngIf="type === 'document'" class="pdf-container">
          <pdf-viewer [src]="documentSource" (click)="$event.stopPropagation()" [original-size]="false"
            [autoresize]="true" [render-text]="false" [zoom]="0.95" style="height: 100%"></pdf-viewer>
          <div *ngIf="isLoading" class="loading-state">
            <app-empty-state [isLoading]="true" [message]="' '">
            </app-empty-state>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-drawer #drawer mode="side" position="end">
    <div class="sidenav-content">
      <div class="sidenav-close">
        <button mat-icon-button (click)="drawer.close()">
          <i class="material-icons-round">close</i>
        </button>
      </div>
      <div>
        <div class="sidenav-title">
          <span class="capitalize">{{ type }}</span> details
        </div>
      </div>
      <div class="scroll-area">
        <app-media-info *ngIf="type === 'photo' || type === 'video'" [mediaContainer]="content"
          [userAccount]="userAccount" [isFromLibrary]="isFromLibrary" [isFromConversation]="isFromConversation"
          [refresh]="photoInfoRefreshCount" [exportDisabled]="type === 'video'" (deleteClicked)="delete()"
          (needRefresh)="reload()">
        </app-media-info>
        <app-consent-info *ngIf="type === 'consent' && userAccount" class="p-2" [userAccount]="userAccount"
          [consent]="content.payload">
        </app-consent-info>
        <app-document-details *ngIf="type === 'document' && document" [document]="document" [userAccount]="userAccount"
          [isFromLibrary]="isFromLibrary" (update)="loadDocumentObject()" (deleteClicked)="delete()">
        </app-document-details>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>