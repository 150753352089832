<div class="title mb-2">Upload Files</div>
<div
  class="box"
  (click)="photoSelect.click()"
  ng2FileDrop
  [uploader]="uploader"
  (fileOver)="dropZoneState($event)"
  (onFileDrop)="handleDrop($event)"
  [ngClass]="{
    valid: dropZoneActive === 'valid'
  }"
  #parent
>
  <div class="drop-highlight">
    <div>
      <i class="material-icons icon-xxl">cloud_upload</i>
      <div>UPLOAD</div>
    </div>
  </div>
  <div class="icon">
    <i class="material-icons">crop_original</i>
  </div>
  <div *ngIf="singleFileOnly" class="text">
    Drag a photo or a document to upload or <a class="celo-link">browse</a>
  </div>
  <div *ngIf="!singleFileOnly" class="text">
    Drag photos or documents to upload or <a class="celo-link">browse</a>
  </div>
  <div *ngIf="!hideSubtitle" class="subtitle">
    ( Up to 20 files in JPG, PNG and PDF )
  </div>
</div>
<input
  [multiple]="!singleFileOnly"
  accept="image/png, image/jpeg, application/pdf"
  #photoSelect
  class="d-none"
  type="file"
  id="celo-textbar"
  ng2FileSelect
  [uploader]="uploader"
  (change)="closeNow()"
/>

<mat-dialog-actions class="buttons" align="end">
  <button class="celo-secondary-button cancel-button" mat-dialog-close>
    Cancel
  </button>
</mat-dialog-actions>
