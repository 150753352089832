import { Injectable } from "@angular/core";
import {
  ExportMediaOptions,
  GetMediaOptions,
  PatientMediaDetailsModel,
  PatientMediaModelPagedResult,
} from "@types";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  public constructor(private apiService: ApiService) {}

  public getMedia(
    options: GetMediaOptions
  ): Observable<PatientMediaModelPagedResult> {
    const path = `/api/v2/Media`;
    return this.apiService.get<PatientMediaModelPagedResult>({
      path,
      queryParams: { ...options },
    });
  }

  public export(
    options: ExportMediaOptions
  ): Observable<PatientMediaDetailsModel> {
    const path = `/api/v2/Media/${options.mediaId}/export`;
    return this.apiService.post<PatientMediaDetailsModel>({
      path,
      body: options.request,
    });
  }
}
