<div class="flex new-chat relative">
  <!-- Close button -->
  <button
    mat-icon-button
    mat-dialog-close
    aria-label="close"
    class="close-button"
  >
    <mat-icon>close</mat-icon>
  </button>

  <!-- Left side content -->
  <div class="chat" #chatWindow>
    <div #toolbar class="toolbar">
      <div class="title">
        {{ PAGE_META[page_type].title }}
      </div>

      <!-- Searchbar -->
      <div *ngIf="PAGE_META[page_type].searchable" class="my-input">
        <i class="material-icons-round mr-2">search</i>
        <input
          matInput
          placeholder="Search network"
          autocomplete="off"
          maxlength="30"
          [(ngModel)]="searchTerm"
          (keyup.enter)="searchNow($event.target.value)"
          (ngModelChange)="onSearchInputChanged($event)"
          keypress
          type="search"
        />
      </div>
    </div>

    <!-- No internet state -->
    <div *ngIf="!isOnline; else content" class="no-internet">
      <app-network-no-results></app-network-no-results>
      <div class="title">Get suggestions</div>
      <div class="subtitle">
        Connect to the internet to get suggestions from the network.
      </div>
    </div>

    <!-- Participant/user selection -->
    <ng-template #content>
      <div
        [style.height.px]="chatWindow.offsetHeight - toolbar.offsetHeight"
        class="contents"
      >
        <div
          class="content"
          [ngClass]="{ hidden: !PAGE_META[page_type].directory }"
        >
          <div [ngClass]="{ hidden: searchMode }">
            <div class="section">
              <div
                *ngIf="
                  (myWorkspaces.length ||
                    partneredWorkspaces.length ||
                    hasConnections) &&
                  !PAGE_META[page_type].hide_other_workspaces
                "
                class="header flex"
              >
                <div class="subtitle">My network</div>
                <div
                  *ngIf="
                    myWorkspaces.length >
                      PAGE_META[page_type].max_visible_workspaces ||
                    partneredWorkspaces.length
                  "
                  (click)="showAll = !showAll; raiseToggleEvents()"
                  class="celo-cursor-pointer show-more"
                >
                  <span *ngIf="!showAll">Show more</span>
                  <span *ngIf="showAll">Show less</span>
                </div>
              </div>
              <div
                *ngIf="!PAGE_META[page_type].hide_connections"
                class="subcontent"
              >
                <app-contacts-expansion-panel
                  [uneditableContactIds]="uneditableContactIds"
                  [isAddingParticipants]="isAddingParticipants"
                  [userAccount]="userAccount"
                  [title]="'Connections'"
                  [hidden]="true"
                  [type]="'connections'"
                  [selectable]="PAGE_META[page_type].select_mode"
                  [selectedContactsMap]="selectedContacts.map"
                  (hasConnections)="hasConnections = true"
                  (contactClicked)="onClick($event)"
                  (contactSelected)="onSelect($event, true, 'connection')"
                  (remove)="onSelect($event, false, 'connection')"
                ></app-contacts-expansion-panel>
              </div>
              <div
                *ngIf="myWorkspaces.length || partneredWorkspaces.length"
                class="subcontent"
              >
                <div *ngFor="let workspace of myWorkspaces; let index = index">
                  <div
                    *ngIf="
                      index < PAGE_META[page_type].max_visible_workspaces ||
                      showAll
                    "
                  >
                    <app-contacts-expansion-panel
                      *ngIf="
                        !workspaceIdToHide || workspaceIdToHide !== workspace.id
                      "
                      [companyInFocus]="workspacesMap[workspace.id]"
                      [companyInFocusId]="workspace.id"
                      [uneditableContactIds]="uneditableContactIds"
                      [isAddingParticipants]="isAddingParticipants"
                      [page_type]="page_type"
                      [userAccount]="userAccount"
                      [noTopMargin]="PAGE_META[page_type].hide_other_workspaces"
                      [disableExpansion]="
                        PAGE_META[page_type].hide_other_workspaces
                      "
                      [title]="workspace.name"
                      [hidden]="PAGE_META[page_type].hidden"
                      [type]="'workspace'"
                      [isWorkspaceVerified]="
                        myVerifiedWorkspaceIds.indexOf(workspace.id) !== -1
                      "
                      [IncludeSelf]="!PAGE_META[page_type].hide_self"
                      [contactsToFilter]="contactsToFilter"
                      [workspaceId]="workspace.id"
                      [selectable]="PAGE_META[page_type].select_mode"
                      [selectedContactsMap]="selectedContacts.map"
                      (contactClicked)="onClick($event)"
                      (contactSelected)="onSelect($event, true, 'colleague')"
                      (remove)="onSelect($event, false, 'colleague')"
                    ></app-contacts-expansion-panel>
                  </div>
                </div>
                <div *ngIf="showAll">
                  <div
                    *ngFor="
                      let workspace of partneredWorkspaces;
                      let index = index
                    "
                  >
                    <app-contacts-expansion-panel
                      *ngIf="
                        !workspaceIdToHide || workspaceIdToHide !== workspace.id
                      "
                      [companyInFocus]="workspacesMap[workspace.id]"
                      [companyInFocusId]="workspace.id"
                      [uneditableContactIds]="uneditableContactIds"
                      [isAddingParticipants]="isAddingParticipants"
                      [page_type]="page_type"
                      [userAccount]="userAccount"
                      [title]="workspace.name"
                      [hidden]="true"
                      [type]="'workspace'"
                      [isWorkspaceVerified]="
                        myVerifiedWorkspceIds.indexOf(workspace.id) !== -1
                      "
                      [workspaceId]="workspace.id"
                      [selectable]="PAGE_META[page_type].select_mode"
                      [selectedContactsMap]="selectedContacts.map"
                      (contactClicked)="onClick($event)"
                      (contactSelected)="onSelect($event, true, 'colleague')"
                      (remove)="onSelect($event, false, 'colleague')"
                    ></app-contacts-expansion-panel>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="!PAGE_META[page_type].hide_suggestions" class="section">
              <div *ngIf="hasSuggestions" class="header header-suggested">
                <div class="subtitle subtitle-suggested">Suggested</div>
                <div class="subsubtitle">
                  Search to find other people on the network
                </div>
              </div>
              <app-contacts-expansion-panel
                [uneditableContactIds]="uneditableContactIds"
                [isAddingParticipants]="isAddingParticipants"
                [userAccount]="userAccount"
                [title]="''"
                [hidden]="false"
                [type]="'suggestions'"
                (hasSuggestions)="hasSuggestions = true"
                [selectable]="PAGE_META[page_type].select_mode"
                [selectedContactsMap]="selectedContacts.map"
                (contactClicked)="onClick($event, 'suggestion')"
                (contactSelected)="onSelect($event, true, 'suggestion')"
                (remove)="onSelect($event, false, 'suggestion')"
              ></app-contacts-expansion-panel>
            </div>
          </div>

          <!-- No suggestions state -->
          <div
            *ngIf="
              !hasConnections &&
              !hasSuggestions &&
              !PAGE_META[page_type].hide_network &&
              isOnline
            "
            class="empty-state"
          >
            <div *ngIf="!discoverable">
              <img src="../../../../assets/network-empty.png" alt="" />
              <div class="title">Join the Celo community</div>
              <div class="subtitle">
                You can get suggestions and search the network by becoming
                discoverable.
              </div>
              <a class="celo-link" [routerLink]="['/edit/privacy']"
                ><button mat-button>Go to settings</button></a
              >
            </div>
            <div *ngIf="discoverable">
              <img src="../../../../assets/network-empty.png" alt="" />
              <div class="title">Get suggestions</div>
              <div class="subtitle">
                Verify your identity or workspace to get suggestions and search
                the network.
              </div>
              <a class="celo-link" (click)="verifyIdentity()"
                ><button mat-button>
                  You can verify your identity using the mobile app.
                </button></a
              >
            </div>
          </div>
        </div>

        <!-- Search results -->
        <div *ngIf="searchMode">
          <div class="section">
            <app-search-result
              [uneditableContactIds]="uneditableContactIds"
              [isAddingParticipants]="isAddingParticipants"
              [searchNow$]="searchNow$"
              [discoverable]="discoverable"
              [userAccount]="userAccount"
              [selectable]="PAGE_META[page_type].select_mode"
              [selectedContactsMap]="selectedContacts.map"
              [page_type]="page_type"
              [hideConnections]="PAGE_META[page_type].hide_connections"
              [hideNetwork]="PAGE_META[page_type].hide_network"
              [showUsersInWorkspaceIdOnly]="
                page_type === 'new_broadcast' ? myWorkspaces[0].id : ''
              "
              [myWorkspacesAndPartnerWorkspaceIds]="
                myWorkspacesAndPartnerWorkspaceIds
              "
              [excludeTeams]="page_type === 'new_broadcast'"
              (contactClick)="onSearchContactClick($event)"
              (contactSelect)="onSearchContactSelection($event)"
              (contactRemove)="onSelect($event, false)"
            ></app-search-result>
          </div>
        </div>

        <div
          class="content"
          *ngIf="page_type === 'group_details' || page_type === 'case_details'"
        >
          <app-new-message-create
            [name]="name"
            (nameChange)="name = $event"
            (nameInvalid)="nameInvalid = $event"
            [patientData]="patientData"
            [type]="PAGE_META[page_type].type"
          ></app-new-message-create>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- Right side content -->
  <div
    [style.height.px]="chatWindow.offsetHeight"
    [ngClass]="{
      'side-displayed': PAGE_META[page_type].show_participants
    }"
    class="side"
  >
    <div class="side-content">
      <!-- Conversation type selection -->
      <div
        *ngIf="
          page_type !== 'new_broadcast' &&
            !isAddingParticipants &&
            page_type === 'new_chat';
          else participants
        "
        class="new-button-bar"
      >
        <div class="button group">
          <button mat-button (click)="newGroup()">
            <div>
              <span class="icon">
                <mat-icon [svgIcon]="'new-group'"></mat-icon>
              </span>
              <span class="button-text">New Group</span>
            </div>
          </button>
        </div>
        <div class="button case">
          <button mat-button (click)="newCase()">
            <div>
              <span class="icon">
                <mat-icon [svgIcon]="'new-case'"></mat-icon>
              </span>
              <span class="button-text">New Case</span>
            </div>
          </button>
        </div>
      </div>

      <ng-template #participants>
        <!-- Selected contacts -->
        <div class="selected_contacts_box">
          <app-celo-selected-contacts
            [empty_message]="
              'Add ' +
              PAGE_META[page_type].item_salutation +
              's by selecting people from the list'
            "
            (search)="search($event)"
            (changeSelection)="changeSelectedContacts($event)"
            [addContact$]="addContact$"
            [maxCapacity]="PAGE_META[page_type].max_capacity"
            [removeContact$]="removeContact$"
            [canRemoveContact$]="canRemoveContact$"
            [groupedContacts$]="groupedContacts$"
            [title]="
              PAGE_META[page_type].selectedContactsTitle
                ? PAGE_META[page_type].selectedContactsTitle
                : 'Participants'
            "
            [removable]="true"
            [salutation]="PAGE_META[page_type].item_salutation"
            [showAddParticipantsButton]="selectionDone"
            [selectedContactsLabelMap]="selectedContactsLabelMap"
            [replaceMyName]="
              userAccount.firstName + ' ' + userAccount.lastName + ' (You)'
            "
            [showMaxCount]="page_type === 'workspace_admin'"
            (editParticipants)="selectionDone = false"
            (capacityReached)="capacityReached()"
          >
          </app-celo-selected-contacts>
        </div>

        <!-- Flow navigation -->
        <div class="side-footer uppercase">
          <div class="button">
            <button
              *ngIf="page_type === 'new_case'"
              mat-button
              class="celo-primary-button"
              (click)="page_type = 'case_details'; exitSearchMode()"
            >
              Continue
            </button>
            <button
              *ngIf="page_type === 'new_group'"
              mat-button
              class="celo-primary-button"
              (click)="page_type = 'group_details'; exitSearchMode()"
            >
              Continue
            </button>
            <button
              *ngIf="
                page_type === 'case_details' || page_type === 'group_details'
              "
              mat-button
              class="celo-primary-button"
              (click)="createConversation()"
              [disabled]="nameInvalid || loading"
            >
              <span
                >Create new
                <span>{{
                  page_type === "case_details" ? "case" : "group"
                }}</span></span
              >
            </button>
            <button
              *ngIf="page_type === 'new_broadcast'"
              mat-button
              class="celo-primary-button"
              (click)="createBroadcast()"
              [disabled]="
                !selectedContacts.list || !selectedContacts.list.length
              "
            >
              <span>Create new broadcast</span>
            </button>
            <button
              *ngIf="page_type === 'department_admin'"
              mat-button
              class="celo-primary-button"
              (click)="addUsers()"
              [disabled]="
                !selectedContacts.list || !selectedContacts.list.length
              "
            >
              <span>Add</span>
            </button>
            <button
              *ngIf="page_type === 'workspace_admin'"
              mat-button
              class="celo-primary-button"
              (click)="addUsers()"
              [disabled]="
                !selectedContacts.list || !selectedContacts.list.length
              "
            >
              <span>Add to workspace</span>
            </button>
            <button
              *ngIf="isAddingParticipants"
              mat-button
              class="celo-primary-button"
              (click)="onSave()"
              [disabled]="selectedContacts.list.length === 0"
            >
              <span>Save</span>
            </button>
          </div>
          <div class="button">
            <button
              *ngIf="page_type === 'new_group' || page_type === 'new_case'"
              mat-button
              (click)="backToNewChat()"
              class="celo-secondary-button"
            >
              Back
            </button>
            <button
              *ngIf="page_type === 'case_details'"
              mat-button
              (click)="page_type = 'new_case'"
              class="celo-secondary-button"
            >
              Back
            </button>
            <button
              *ngIf="page_type === 'group_details'"
              mat-button
              (click)="page_type = 'new_group'"
              class="celo-secondary-button"
            >
              Back
            </button>
            <button
              *ngIf="page_type === 'new_broadcast'"
              [mat-dialog-close]="true"
              class="celo-secondary-button"
            >
              Back
            </button>
            <button
              *ngIf="isAddingParticipants"
              mat-button
              mat-dialog-close
              class="celo-secondary-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<div *ngIf="loading" class="loading">
  <app-empty-state
    [spinner]="false"
    [animation]="true"
    [isLoading]="true"
    [message]="' '"
    [diameter]="30"
  ></app-empty-state>
</div>
