import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-quoted-message",
  templateUrl: "./quoted-message.component.html",
  styleUrls: ["./quoted-message.component.scss"],
})
export class QuotedMessageComponent {
  @Input() message: any;
  @Input() fromMe: Boolean;
  @Input() fromSender: Boolean;
  @Input() userAccount: any;
  @Input() participantAccount: any;
  @Input() collapsible: boolean;

  @Output() findChat = new EventEmitter<number>();
}
