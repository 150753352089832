import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { AnalyticsService, SharedService } from "../../../core";
import { WorkspaceService } from "app/workspace/workspace.service";
import { Subject, Subscription } from "rxjs";
import {
  CompanyBasicModel,
  ContactModel,
  SuggestedType,
  SuggestedUserModel,
  UserProfileModel,
} from "types";

// May want to add a hint of what the original type was so it can be cast
export type Contact = SuggestedUserModel | ContactModel | UserProfileModel;

export enum ContactGroupState {
  Checked = "checked",
  Indeterminate = "indeterminate",
  Unchecked = "unchecked",
  Disabled = "disabled",
}

export interface ContactGroup {
  contacts: Contact[];
  state: ContactGroupState;
}

export interface DepartmentContactGroup extends ContactGroup {
  departmentName: string;
}

@Component({
  selector: "app-contacts-expansion-panel",
  templateUrl: "./contacts-expansion-panel.component.html",
  styleUrls: ["./contacts-expansion-panel.component.scss"],
})
export class ContactsExpansionPanelComponent implements OnInit, DoCheck {
  @Input() public userAccount: any = {};
  @Input() public title: string = "";
  @Input() public hidden: boolean = false;
  @Input() public selectable: boolean = false;
  @Input() public noTopMargin: boolean;
  @Input() public disableExpansion: boolean;
  @Input() public type: string = "";
  @Input() public page_type: string = "";
  @Input() public workspaceId: string = "";
  @Input() public isWorkspaceVerified: boolean = false;
  @Input() public IncludeSelf: boolean = true;
  @Input() public selectedContactsMap: { [key: string]: Contact } = {};
  @Input() public canDeselect = false;
  @Input() public isAddingParticipants = false;
  @Input() public uneditableContactIds: Set<string> = new Set();
  @Input() public selectAllContacts$: Subject<any> = new Subject<any>();
  @Input() public companyInFocus: CompanyBasicModel;
  @Input() public companyInFocusId: string;
  @Input() public contactsToFilter: string[] = [];

  @Output() public hasConnections = new EventEmitter();
  @Output() public hasSuggestions = new EventEmitter();
  @Output() public contactClicked = new EventEmitter<Contact>();
  @Output() public contactSelected = new EventEmitter<Contact>();
  @Output() public remove = new EventEmitter<Contact>();

  public loading: boolean = false;

  private NO_DEPARTMENT = "No Department";
  private subscription: Subscription | null = null;
  public groupedLocalContactsDepartments: DepartmentContactGroup[] = [];
  public contactGroup: ContactGroup = {
    contacts: [],
    state: ContactGroupState.Unchecked,
  };

  constructor(
    private sharedService: SharedService,
    private workspaceService: WorkspaceService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.loadContacts(false, true);
  }

  loadContacts(
    toggleHidden: boolean = true,
    disableSnackbar: boolean = false
  ): void {
    this.sharedService.ifOnline(() => {
      if (toggleHidden) {
        this.hidden = !this.hidden;
      }
      switch (this.type) {
        case "connections":
          this.getConnectionsFirstPage();
          break;
        case "workspace":
          if (this.hidden) {
            return;
          }
          this.getWorkspaceContactsFirstPage();
          break;
        case "suggestions":
          if (this.hidden) {
            return;
          }
          this.getSuggestedContacts();
          break;
      }
    }, disableSnackbar);
  }

  ngDoCheck() {
    if (this.type === "workspace") {
      for (const group of this.groupedLocalContactsDepartments) {
        group.state = this.getState(group.contacts);
      }
    } else {
      this.contactGroup.state = this.getState(this.contactGroup.contacts);
    }
  }

  getWorkspaceContactsFirstPage() {
    this.getWorkspaceContacts(0, 5000, "");
  }

  getSuggestedContacts() {
    this.loading = true;
    this.unsubscribe();
    this.subscription = this.sharedService.getSuggestedContacts().subscribe({
      next: (res) => {
        this.loading = false;
        this.contactGroup.contacts = res;
        if (res && res.length) {
          this.hasSuggestions.emit();
        }
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  getWorkspaceContacts(page: number, pageSize: number, query: string): void {
    this.loading = true;
    const body = {
      page,
      pageSize,
      Search: query ? query : "",
      IncludeSelf: this.IncludeSelf,
    };
    this.unsubscribe();
    this.subscription = this.sharedService
      .getCompanyContacts(this.workspaceId, body)
      .subscribe({
        next: (res) => {
          this.loading = false;
          if (!res || !res.data || !res.data.length) return;

          let filteredContacts = res.data.filter((contact) => {
            if (this.contactsToFilter.indexOf(contact.userId) == -1)
              return contact;
            return false;
          });

          this.contactGroup.contacts = filteredContacts;
          this.localSearchDepartments();
        },
        error: (err) => {
          this.loading = false;
        },
      });
  }

  localSearchDepartments() {
    this.sharedService.sortArrayByField(
      this.contactGroup.contacts,
      "firstName"
    );
    this.formatByField(this.contactGroup.contacts, "isOnCall");
    this.sharedService.pullToTop(
      this.contactGroup.contacts,
      "userId",
      this.userAccount.userId
    );
    const localContacts = [...this.contactGroup.contacts];
    this.groupedLocalContactsDepartments =
      this.groupByDepartment(localContacts);
    this.sharedService.sortArrayByField(
      this.groupedLocalContactsDepartments,
      "departmentName"
    );
    const myWorkspace = this.workspaceService.getMyCompanyByCompanyId(
      this.workspaceId,
      this.userAccount
    );

    // If the user is part of a department move it to the top
    if (myWorkspace) {
      const myDepartmentName = myWorkspace.departmentName;
      if (myDepartmentName) {
        this.sharedService.pullToTop(
          this.groupedLocalContactsDepartments,
          "departmentName",
          myDepartmentName
        );
      }
    }

    // If there's a "No department" department, it should always be placed at the bottom
    const hasNoDepartment = this.groupedLocalContactsDepartments.filter(
      (group) => group.departmentName === this.NO_DEPARTMENT
    ).length;
    if (hasNoDepartment) {
      this.sharedService.pullToBottom(
        this.groupedLocalContactsDepartments,
        "departmentName",
        this.NO_DEPARTMENT
      );
    }
  }

  groupByDepartment(contacts: Contact[]): DepartmentContactGroup[] {
    if (!contacts?.length === null) {
      return [];
    }

    const group: {
      [x: string]: Contact[];
    } = {};
    for (const contact of contacts) {
      const company = this.sharedService.getCompanyById(
        this.workspaceId,
        contact
      );
      const departmentName = company?.departmentName || this.NO_DEPARTMENT;
      group[departmentName] = group[departmentName] || [];
      group[departmentName].push(contact);
    }

    const departmentContactGroups: DepartmentContactGroup[] = [];
    for (const key of Object.keys(group)) {
      const departmentContacts: Contact[] = group[key];
      departmentContactGroups.push({
        departmentName: key,
        contacts: departmentContacts,
        state: this.getState(departmentContacts),
      });
    }

    return departmentContactGroups;
  }

  private getConnectionsFirstPage() {
    this.getConnections(0, 1000);
  }

  private getConnections(page: number, pageSize: number, search: string = "") {
    this.loading = true;
    this.unsubscribe();
    this.subscription = this.sharedService
      .getContactsV2({
        page,
        pageSize,
        search,
      })
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.contactGroup.contacts = res.data || [];
          if (res.data && res.data.length) {
            this.hasConnections.emit();
          }
        },
        error: (err) => {
          this.loading = false;
        },
      });
  }

  unsubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  groupAction(status: boolean, group: ContactGroup) {
    const editableGroupContacts = {
      ...group,
      contacts: group.contacts.filter(
        (c) => !this.uneditableContactIds.has(c.userId)
      ),
    };

    if (status) {
      this.selectAll(editableGroupContacts);
    } else {
      this.unselectAll(editableGroupContacts);
    }
  }

  selectAll(group: ContactGroup) {
    if (!group.contacts || !group.contacts.length) {
      return;
    }
    group.contacts.forEach((contact) => {
      if (
        this.type === "suggestions" &&
        (contact as SuggestedUserModel).type === SuggestedType.Random
      ) {
        return false;
      }
      this.contactSelected.emit(contact);
    });
  }

  unselectAll(group: ContactGroup) {
    if (!group.contacts || !group.contacts.length) {
      return;
    }
    group.contacts.forEach((contact) => {
      this.remove.emit(contact);
    });
  }

  formatByField(array, fieldName) {
    let pivot = 0;
    for (let index = 0; index < array.length; index++) {
      const contact = array[index];
      if (contact[fieldName] == true || contact[fieldName] == "true") {
        array.splice(index, 1);
        array.splice(pivot++, 0, contact);
      }
    }
  }

  getState(contacts: Contact[]): ContactGroupState {
    const editable = contacts.filter(
      (c) => !this.uneditableContactIds.has(c.userId)
    );

    if (editable.length === 0) {
      return ContactGroupState.Disabled;
    }

    const checkCount = editable.reduce(
      (previousValue, contact) =>
        contact.userId in this.selectedContactsMap
          ? previousValue + 1
          : previousValue,
      0
    );
    if (checkCount === 0) {
      return ContactGroupState.Unchecked;
    }
    if (checkCount < editable.length) {
      return ContactGroupState.Indeterminate;
    }
    return ContactGroupState.Checked;
  }

  raiseAcordinActionEvents() {
    let buttonId = "";
    if (this.type == "workspace") {
      buttonId = "workspace_accordion";
    } else if (this.type == "connections") {
      buttonId = "connection_accordion";
    }
    let accordionAction = "open";
    if (this.hidden) {
      accordionAction = "close";
    }
    this.analyticsService.buttonClickEvent(buttonId, {
      flow: "message",
      accordion_action: accordionAction,
    });
  }
}
