import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-country-fail",
  templateUrl: "./country-fail.component.html",
  styleUrls: ["./country-fail.component.scss"],
})
export class CountryFailComponent implements OnInit {
  countryName: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((paramMap) => {
      this.countryName = paramMap.get("country_name");
    });
  }
}
