<div class="profile">
  <div *ngIf="profile" class="outlet-content">
    <div class="network-header header">
      <div class="network-cover">
        <div class="cover">
          <div class="cover-background">
            <div class="cover-image"></div>
          </div>
        </div>
      </div>
      <div class="logo">
        <div class="relative">
          <app-edit-profile-pic
            [width]="120"
            [profile]="profile"
            [nocache]="nocache"
            (edit)="initUploader(); fileSingle.click()"
            [refreshCount]="refreshCount"
          ></app-edit-profile-pic>
          <input
            accept="image/png, image/jpeg"
            #fileSingle
            class="d-none"
            type="file"
            ng2FileSelect
            [uploader]="uploader"
            [multiple]="false"
          />
        </div>
      </div>
      <div class="network-header-content relative">
        <div class="name-line items-center">
          <div class="celo-heading name">
            {{ profile.title }} {{ profile.firstName }} {{ profile.lastName }}
          </div>
          &nbsp;&nbsp;
          <div class="badge-container">
            <app-badge
              [identityVerificationStatus]="profile.identityVerificationStatus"
              [workspaces]="profile.workplaces"
              [profession]="profile.professions[0]"
              [size]="28"
              [showTooltip]="false"
              [clickable]="true"
              [ownProfile]="true"
              [name]="profile.firstName + ' ' + profile.lastName"
              [name_verified_as]="
                profile.legalFirstName + ' ' + profile.lastName
              "
              [title]="profile.title"
              [userId]="profile.userId"
              [hasImage]="!!profile.picture"
            ></app-badge>
          </div>
        </div>
        <div
          *ngIf="profile.professions && profile.professions[0]"
          class="subname celo-elipsis-2"
        >
          <div class="text">
            {{ profile.professions[0].profession }}
            <span
              *ngIf="profile.professions[0].verificationStatus === 'Unverified'"
              >(Unverified)</span
            >
          </div>
        </div>
        <div class="oncallbutton">
          <app-oncall-toggle-button
            [userAccount]="profile"
            [height]="34"
            [isOnCall]="isOnCall"
            (isOnCallChange)="isOnCall = $event"
          ></app-oncall-toggle-button>
        </div>
        <div class="share-profile">
          <button
            mat-button
            (click)="
              shareProfile();
              analyticsService.buttonClickEvent(
                'open_share_profile_from_settings'
              )
            "
          >
            <div>
              <img
                class="svgIcon"
                src="../../assets/icons/ic-share.svg"
                alt=""
              />
              <span class="text">Share profile link</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="flex">
      <!-- Left content -->
      <div class="profile-box">
        <div class="profile-box-content profile-box-content-left personal-info">
          <div class="celo-text-16 card-title">Personal Info</div>
          <div
            mat-ripple
            class="field celo-hoverable celo-cursor-pointer"
            (click)="editPreferredName()"
          >
            <div class="celo-field-title">Preferred Name</div>
            <div class="celo-text-14">
              {{ profile.firstName ? profile.firstName : "-" }}
            </div>
            <div class="edit-button">
              <button mat-button class="celo-tertiary-button">Edit</button>
            </div>
          </div>
          <div
            mat-ripple
            class="field celo-hoverable celo-cursor-pointer"
            (click)="editTitle()"
          >
            <div class="celo-field-title">Title</div>
            <div class="celo-text-14">
              {{ profile.title ? profile.title : "No title" }}
            </div>
            <div class="edit-button">
              <button mat-button class="celo-tertiary-button">Edit</button>
            </div>
          </div>
          <div
            mat-ripple
            class="field celo-hoverable celo-cursor-pointer"
            (click)="onEditFullName()"
          >
            <div class="celo-field-title">Full Name</div>
            <div class="celo-text-14">
              {{ profile.legalFirstName }} {{ profile.lastName }}
            </div>
            <div class="edit-button">
              <button mat-button class="celo-tertiary-button">Edit</button>
            </div>
          </div>
          <div
            class="field celo-hoverable celo-cursor-pointer"
            (click)="verifyIdentity()"
          >
            <div class="celo-field-title">Identity Verification</div>
            <div class="celo-text-14">
              <span *ngIf="profile.identityVerificationStatus === 'Verified'"
                >Verified</span
              >
              <span *ngIf="profile.identityVerificationStatus === 'Pending'"
                >Pending</span
              >
              <span
                *ngIf="
                  !profile.identityVerificationStatus ||
                  profile.identityVerificationStatus === 'Unverified'
                "
                >Unverified</span
              >
            </div>
            <div
              *ngIf="profile.identityVerificationStatus !== 'Verified'"
              class="celo-text-12 celo-grey celo-link"
            >
              You can verify your identity using the mobile app.
            </div>
            <div
              *ngIf="profile.identityVerificationStatus === 'Verified'"
              (click)="$event.stopPropagation()"
              class="celo-text-12 celo-grey"
            >
              Verified full name not correct? Contact us at
              <a href="mailto:support@celohealth.com" class="celo-link"
                >support@celohealth.com</a
              >
              and we can update it for you.
            </div>
          </div>
          <div
            class="field celo-hoverable celo-cursor-pointer profession"
            (click)="editOrVerifyProfession()"
          >
            <button
              *ngIf="
                !profile.professions ||
                !profile.professions[0] ||
                !profile.professions[0].profession
              "
              mat-button
              class="celo-secondary-button"
            >
              ADD PROFESSION
            </button>
            <div
              *ngIf="
                profile.professions &&
                profile.professions[0] &&
                profile.professions[0].profession
              "
              class="celo-text-14"
            >
              <div class="celo-field-title">Profession</div>
              <span>{{ profile.professions[0].profession }}</span>
              <div
                *ngIf="profile.professions[0].verificationStatus === 'Verified'"
                class="badge"
                (click)="$event.stopPropagation()"
              >
                <app-badge
                  [identityVerificationStatus]="'Verified'"
                  [profession]="profile.professions[0]"
                  [workspaces]="profile.workplaces"
                  [size]="20"
                  [showTooltip]="false"
                  [clickable]="true"
                  [ownProfile]="true"
                  [name]="profile.firstName + ' ' + profile.lastName"
                  [name_verified_as]="
                    profile.legalFirstName + ' ' + profile.lastName
                  "
                  [title]="profile.title"
                  [userId]="profile.userId"
                  [hasImage]="!!profile.picture"
                ></app-badge>
              </div>
              <span
                *ngIf="profile.professions[0].verificationStatus === 'Pending'"
                class="celo-field-title"
                >(Pending)</span
              >
              <span
                *ngIf="
                  profile.professions[0].verificationStatus === 'Unverified'
                "
                class="celo-field-title"
                >(Unverified)</span
              >
              <div class="edit-button">
                <button mat-button class="celo-tertiary-button">Edit</button>
              </div>
            </div>
          </div>
        </div>
        <div class="profile-box-content profile-box-content-left">
          <div class="celo-text-16 card-title">Location</div>
          <div class="field">
            <div class="celo-text-14">
              {{
                profile.countryCode
                  ? (profile.countryCode | countryFromCode)
                  : "-"
              }}
            </div>
            <div class="celo-text-12 celo-grey">
              Only you can see your location. To change your location please get
              in touch with us at
              <a href="mailto:support@celohealth.com" class="celo-link"
                >support@celohealth.com</a
              >
            </div>
          </div>
        </div>
        <div class="profile-box-content profile-box-content-left">
          <div class="celo-text-16 card-title">Contact Info</div>
          <div
            mat-ripple
            class="field celo-hoverable celo-cursor-pointer"
            (click)="getPhone()"
          >
            <button
              *ngIf="!profile.phoneNumber"
              mat-button
              class="celo-secondary-button"
            >
              ADD A MOBILE NUMBER
            </button>
            <div *ngIf="profile.phoneNumber">
              <div class="celo-field-title">Mobile number</div>
              <app-info-line [title]="profile.phoneNumber"></app-info-line>
            </div>
            <div *ngIf="profile.phoneNumber" class="edit-button">
              <button mat-button class="celo-tertiary-button">Edit</button>
            </div>
          </div>
          <div
            mat-ripple
            class="field celo-hoverable celo-cursor-pointer"
            (click)="getEmail()"
          >
            <button
              *ngIf="!profile.email"
              mat-button
              class="celo-secondary-button"
            >
              ADD A PRIMARY EMAIL
            </button>
            <div *ngIf="profile.email">
              <div class="celo-field-title">Primary email</div>
              <app-info-line [title]="profile.email"></app-info-line>
            </div>
            <div class="edit-button">
              <button mat-button class="celo-tertiary-button">Edit</button>
            </div>
            <div class="celo-text-12 celo-grey">
              Use a personal email address so you can recover or login to your
              Celo account even if you move workspaces.
            </div>
          </div>
        </div>
      </div>

      <!-- Right content -->
      <div class="profile-box">
        <!-- Workspace info -->
        <div class="profile-box-content profile-box-content-right">
          <div class="celo-text-16 card-title">Workspace Info</div>
          <div>
            <app-companies-list
              [companies]="companies"
              [responsive]="false"
              [editable]="true"
              (edit)="editCompany($event)"
            ></app-companies-list>
          </div>
          <div class="field">
            <a (click)="joinWorkspace()" class="celo-link celo-bold-500"
              >Join existing workspace</a
            >
          </div>
          <div class="field">
            <a (click)="createWorkspace()" class="celo-link celo-bold-500"
              >Create new workspace</a
            >
          </div>
        </div>

        <!-- Roles info -->
        <!-- <ng-container *ngIf="teams$ | async as teams">
          <div
            *ngIf="teams?.length"
            class="profile-box-content profile-box-content-right"
          >
            <div class="celo-text-16 card-title">Roles Info</div>
            <app-profile-roles-list [teams]="teams"></app-profile-roles-list>
          </div>
        </ng-container> -->
      </div>
    </div>
  </div>
  <div *ngIf="!profile">
    <div class="loading-screen">
      <app-empty-state
        [no_message]="true"
        [isLoading]="true"
        [diameter]="40"
      ></app-empty-state>
    </div>
  </div>
  <div class="version celo-text-14 celo-grey">Version {{ version }}</div>
</div>
