/**
 * Sorts elements in `array` into two groups. Elements matching `predicate` are placed into the first group.
 *
 * This operation is stable. The relative ordering of elements is preserved.
 */
export const partition = <T>(
  array: T[],
  predicate: (element: T) => boolean
): [T[], T[]] => {
  const matched: T[] = [];
  const unmatched: T[] = [];

  for (const element of array) {
    if (predicate(element)) {
      matched.push(element);
    } else {
      unmatched.push(element);
    }
  }

  return [matched, unmatched];
};

/**
 * Creates a shallow copy of `array` where the first element matching `predicate` is replaced by `value`, or, if no
 * element matches `predicate`, pushes `value` to the new array.
 */
export const addOrReplaceElement = <T>(
  array: T[],
  value: T,
  predicate: (element: T) => boolean
): T[] => {
  const copy = [...array];
  const index = copy.findIndex(predicate);

  if (index === -1) {
    copy.push(value);
  } else {
    copy[index] = value;
  }

  return copy;
};

/**
 * Creates a shallow copy of `array` where the first element matching `predicate` (if any) is replaced by the result from calling
 * `updateFn` on that element.
 */
export const updateElement = <T>(
  array: T[],
  predicate: (element: T) => boolean,
  updateFn: (element: T) => T
): T[] => {
  const copy = [...array];
  const index = copy.findIndex(predicate);

  if (index !== -1) {
    copy[index] = updateFn(copy[index]);
  }

  return copy;
};

/**
 * Returns a sorted shallow copy of `array`.
 */
export const sorted = <T>(
  array: T[],
  compareFn?: (a: T, b: T) => number
): T[] => {
  return [...array].sort(compareFn);
};

/**
 * Returns a shallow copy of `array` where only the first item of/with a given value is included.
 */
export const distinct = <T>(
  array: T[],
  filter: (element: T) => unknown = (element: T) => element
): T[] => {
  const keys = new Set<unknown>();
  const copy: T[] = [];

  for (const element of array) {
    const key = filter(element);
    if (keys.has(key)) continue;
    keys.add(key);
    copy.push(element);
  }

  return copy;
};

/**
 * Returns a new Map generated from the elements in `array`. Elements where `keySelector` returns null or undefined or
 * ignored.
 */
export const toMap = <TElement, TKey, TValue>(
  array: TElement[],
  keySelector: (element: TElement) => TKey,
  valueSelector: (element: TElement) => TValue,
  options?: Partial<{ ignoreNullOrUndefinedValues: boolean }>
): Map<NonNullable<TKey>, TValue> => {
  const map = new Map<NonNullable<TKey>, TValue>();

  for (const element of array) {
    const key = keySelector(element);
    if (key === null || key === undefined) continue;
    const value = valueSelector(element);
    if (
      (options?.ignoreNullOrUndefinedValues && value === null) ||
      value === undefined
    ) {
      continue;
    }
    map.set(key as NonNullable<TKey>, value);
  }

  return map;
};

/**
 * Returns a shallow copy of `array` containing `value` if it isn't already added. If `value` is already
 * a member of `array` then `array` is returned without any changes.
 */
export const addIfNotFound = <T>(array: T[], value: T): T[] => {
  if (array.find((s) => s === value)) return array;
  return [...array, value];
};

/**
 * Returns a shallow copy of `array` where items that are in `valuesToRemove` are removed.
 *
 * This operation is stable. The relative ordering of elements is preserved.
 */
export const removeRange = <T>(array: T[], valuesToRemove: T[]): T[] => {
  const values = new Set(valuesToRemove);
  const copy = array.filter((s) => !values.has(s));
  return copy;
};
