import { Component, OnInit, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env";
import { tap } from "rxjs/operators";
import { LibraryService } from "@modules/library/library.service";
import { LibraryItemComponent } from "@modules/library/library-item/library-item.component";

@Component({
  selector: "app-consent",
  templateUrl: "./consent.component.html",
  styleUrls: ["./consent.component.scss"],
})
export class ConsentComponent extends LibraryItemComponent implements OnInit {
  // todo: remove parent class, use composition not inheritence with angular
  pdfLink: string;
  source: any;
  loaded = false;
  @Input() ownFile: boolean;

  constructor(private http: HttpClient, libraryService: LibraryService) {
    super(libraryService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.pdfLink = `${environment.celoApiEndpoint}/api/Consents/${this.libItem.payload.id}`;
    this.loadPdf();
  }

  protected getType() {
    return "consent";
  }

  private loadPdf() {
    this.loaded = false;
    this.http
      .get<ArrayBuffer>(this.pdfLink, { responseType: "arraybuffer" as "json" })
      .pipe(
        tap(() => {
          // this.loaded = true
        })
      )
      .subscribe((ab) => {
        this.source = {
          data: new Uint8Array(ab),
        };
      });
  }
}
