import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

/** @deprecated Use `BasicCeloAvatarComponent` */
@Component({
  selector: "app-celo-avatar",
  templateUrl: "./celo-avatar.component.html",
  styleUrls: ["./celo-avatar.component.scss"],
})
export class CeloAvatarComponent implements OnChanges {
  @Input() height = 50;
  @Input() width = 50;
  @Input() badgeSize = 19;
  @Input() hasImage: string;
  @Input() type = "Chat"; //User/Case/Group
  @Input() participant: any;
  @Input() showDNDBadge: boolean;
  @Input() showBadge: boolean;
  @Input() conversationPictureUri: string | null;
  @Input() clickable = false;
  refreshCount = 0;

  public isConversationImageLoaded: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    this.refreshCount++;

    if (changes.conversationPictureUri) {
      this.isConversationImageLoaded = false;
    }
  }

  public handleConversationImageLoaded() {
    this.isConversationImageLoaded = true;
  }
}
