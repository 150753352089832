import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "@env";
import { ConService, SharedService, UserAccountService } from "@modules/core";
import { LibraryService } from "@modules/library/library.service";
import { AlertService } from "app/modules/core/old/alert.service";
import { interval } from "rxjs";
import { ErrorCodes } from "strings/errors/ErrorCodes";

@Component({
  selector: "app-media-info",
  templateUrl: "./media-info.component.html",
  styleUrls: ["./media-info.component.scss"],
})
export class MediaInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() mediaContainer;
  @Input() userAccount: any;
  @Input() isFromLibrary: any;
  @Input() isFromConversation: any;
  @Input() exportDisabled: boolean;
  @Input() refresh = 0;
  @Output() deleteClicked = new EventEmitter();
  @Output() needRefresh = new EventEmitter();
  dialogRef: any;

  formFields = [];
  formSubmitInfo = {};

  hasPatient = false;
  patient: any = {
    uid: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    gender: null,
  };

  originalPatient: any = {
    uid: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    gender: null,
  };

  hasConsent = false;
  consent: any = {
    id: null,
    createdOn: null,
    creator: null,
    patientData: null,
    url: null,
    validBy: null,
  };

  consentLink: string;
  exporting: boolean;
  exported: boolean;
  // history = [];
  canExport: boolean;
  accountIntegrations = [];
  exportedTo = [];
  canBeExportedTo = [];
  generatingSummary: boolean;
  refresh_sub: any;
  canBeExportedToIds: string[] = [];
  detailsChangeRequiredSubscriber: any;
  mediaType: any;

  constructor(
    public dialog: MatDialog,
    public alertService: AlertService,
    public libraryService: LibraryService,
    private userAccountService: UserAccountService,
    private sharedService: SharedService,
    private conService: ConService
  ) {}

  ngOnInit() {
    const instance = this;
    this.userAccountService.getUserAccount(false, function (data) {
      instance.userAccount = data;
      instance.initializeIntegrations();
    });

    this.detailsChangeRequiredSubscriber =
      this.conService.PhotoDetailsUpdateRequired$.subscribe(() => {
        this.needRefresh.emit();
      });
  }

  ngOnChanges() {
    this.patient = {};
    this.consent = {};
    this.originalPatient = {};
    this.hasConsent = false;
    this.generatingSummary = false;
    if (!this.mediaContainer.payload) {
      return;
    }
    if (this.mediaContainer.payload.patientData) {
      this.setPatientData(this.mediaContainer.payload.patientData);
    }
    if (
      this.mediaContainer.mediaDetails &&
      this.mediaContainer.mediaDetails.consent
    ) {
      this.consent = this.mediaContainer.mediaDetails.consent;
      this.hasConsent = true;
    }
    this.mediaType = "media";
    if (this.mediaContainer.mediaDetails) {
      this.mediaType = this.mediaContainer.mediaDetails.mediaType;
    }
    this.setDescriptionFormFields();
    this.formSubmitInfo = {
      photo_id: this.mediaContainer.payload.id,
    };

    this.initializePhotoIntegrations();
  }

  checkNeedRefresh() {
    if (this.generatingSummary) {
      if (!this.refresh_sub) {
        this.refresh_sub = interval(5000).subscribe((val) => {
          this.needRefresh.emit();
        });
      }
    } else if (this.refresh_sub) {
      this.refresh_sub.unsubscribe();
    }
  }

  initializeIntegrations() {
    this.setCanExport();
    if (this.canExport == false) {
      return;
    }
    const instance = this;
    this.getAccountIntegrations(function (integrations) {
      integrations = integrations ? integrations : [];
      instance.accountIntegrations = integrations;
      instance.initializePhotoIntegrations();
    });
  }

  initializePhotoIntegrations() {
    this.exportedTo = this.getIntegrationsPhotoExportedTo();
    this.setExportableIntegrations();
    this.canBeExportedToIds = this.canBeExportedTo.map((a) => a.id);
  }

  setCanExport() {
    if (this.userAccount) {
      if (
        this.userAccount &&
        this.userAccount["features"] &&
        this.userAccount["features"].photoExport
      ) {
        this.canExport = true;
      } else {
        this.canExport = false;
      }
    }
  }

  setExportableIntegrations() {
    const filteredArray = [];
    for (const item of this.accountIntegrations) {
      const search = this.exportedTo.find((o) => {
        if (
          o.metadata &&
          o.metadata.integration &&
          o.metadata.integration.integration
        ) {
          return o.metadata.integration.integration.id == item.id;
        }
      });
      if (!search) {
        filteredArray.push(item);
      }
    }
    this.canBeExportedTo = filteredArray;
  }

  ngOnDestroy() {
    this.closeAllDialogs();
    if (this.refresh_sub) {
      this.refresh_sub.unsubscribe();
    }
    if (this.detailsChangeRequiredSubscriber) {
      this.detailsChangeRequiredSubscriber.unsubscribe();
    }
  }

  closeAllDialogs() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  setPatientData(data) {
    this.originalPatient = data;
    this.patient = { ...this.originalPatient };
    this.hasPatient = true;
  }

  setDescriptionFormFields() {
    this.formFields = [
      {
        value: this.mediaContainer.payload.description,
        type: "textarea",
        disabled: false,
        name: "Description",
        key: "description",
      },
    ];
  }

  getAccountIntegrations(callback) {
    const path = environment.celoApiEndpoint + "/api/integrations";
    this.sharedService
      .getObjectById(path, { type: "PhotoExport" })
      .subscribe((resp) => {
        callback(resp);
      });
  }

  getIntegrationsPhotoExportedTo() {
    const i = this;
    if (
      this.mediaContainer.mediaDetails &&
      this.mediaContainer.mediaDetails["modifiedBy"] &&
      this.mediaContainer.mediaDetails["modifiedBy"].length > 0
    ) {
      const filteredArray = this.mediaContainer.mediaDetails[
        "modifiedBy"
      ].filter(function (item) {
        if (item.type == "Export") {
          // if(item.metadata){
          //   let string = item.metadata;
          //   try {
          //     item.metadata = JSON.parse(string);
          //   } catch (error) {
          //   }
          // }
          if (item.metadata.uri) {
            item.metadata.exportedURI = `${environment.celoApiEndpoint}/${item.metadata.uri}`;
            i.generatingSummary = false;
          } else {
            i.generatingSummary = true;
          }
          return item;
        }
      });
      return filteredArray;
    }
    return [];
  }

  onFieldSaved(event) {
    this.alertService.showSnackBar("Saved", 2, "white");
    this.onPhotoContainerUpdate(event);
  }

  onPhotoContainerUpdate(updatedVersion) {
    this.mediaContainer.payload = updatedVersion;
    this.mediaContainer.mediaDetails = updatedVersion;
    this.setDescriptionFormFields();
    if (updatedVersion.patientData) {
      this.setPatientData(updatedVersion.patientData);
    }
  }

  onPatientSave(data: any) {
    const callback = data.callback;
    const updatePatientPhotoModel: any = {
      patientData: data.patientData,
    };
    updatePatientPhotoModel["description"] =
      this.mediaContainer.payload.description;
    const path =
      environment.celoApiEndpoint +
      "/api/v2/media/{mediaId}".replace(
        "{" + "mediaId" + "}",
        String(this.mediaContainer.payload.id)
      );
    this.sharedService
      .putObjectById(path, {}, updatePatientPhotoModel)
      .subscribe(
        (resp) => {
          callback(true);
          this.onPhotoContainerUpdate(resp);
          this.alertService.showSnackBar("Patient details updated", 4, "white");
          if (this.isFromConversation) {
            this.libraryService.messageMetaUpdated(resp);
          }
        },
        (error) => {
          this.setPatientData(this.mediaContainer.payload.patientData);
          // this.alertService.alert('','Invalid Patient ID. Please try again');
          let errorMessage = "";
          if (error.status == 400) {
            const errBody = error.error;
            if (errBody.uid) {
              errorMessage += " " + errBody.uid[0];
            }
            if (errBody.dateOfBirth) {
              errorMessage += " " + errBody.dateOfBirth[0];
            }
          }
          if (error && error.error) {
            errorMessage = errorMessage
              ? errorMessage
              : this.sharedService.getErrorMessageFromErrorObject(error.error);
          }
          this.alertService.confirm("", errorMessage, "Ok", "");

          callback(false);
        }
      );
  }

  delete() {
    this.deleteClicked.emit();
  }

  export() {
    if (!this.originalPatient.uid) {
      if (
        this.mediaContainer.payload.creator.userId == this.userAccount.userId
      ) {
        this.alertService.confirm(
          "No patient details",
          "Please add patient details.",
          "Ok",
          "",
          true
        );
      } else {
        this.alertService.confirm(
          "No patient details",
          "This photo does not have patient details",
          "Ok",
          "",
          true
        );
      }
      return;
    }
    const title = "Export to clinical record";
    const body =
      "You are about to export this photo to the clinical record. Please confirm the patient details are correct.\n";
    const subtext =
      "Please note this action cannot be undone. Once exported to the clinical record you will need to make any changes required directly in the clinical record.\n";
    const confirmButtonText = "Export";
    const cancelButtonText = "Cancel";
    const payload = [
      {
        type: "patientData",
        data: this.originalPatient,
        consent: this.consent && this.consent.id ? this.consent : "",
      },
      {
        type: "text",
        data: subtext,
      },
      {
        type: "integrations",
        exportableTo: this.canBeExportedToIds,
        integrations: this.accountIntegrations,
        consent: this.consent && this.consent.id ? this.consent : "",
      },
    ];

    this.dialogRef = this.alertService.customDialog(
      title,
      body,
      confirmButtonText,
      cancelButtonText,
      false,
      "horizontal",
      payload
    );
    const i = this;
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result == undefined || result == false) {
        return;
      }
      this.exportToClinicalRecords(result.payload);
    });
  }

  exportToClinicalRecords(record: any) {
    this.exporting = true;
    this.exportToClinicalRecord(
      record.integrationId,
      record.companyId,
      record.serviceCode
    ).subscribe(
      (result) => {
        if (!result) {
          return;
        }
        this.onPhotoContainerUpdate(result);
        this.alertService.showSnackBar(
          "Successfully exported to the clinical record",
          4,
          "white"
        );
      },
      (errors) => {
        const errorCode = this.sharedService.getErrorCode(errors);
        let body =
          "Export failed to the following clinical record. Please try again later.\n";
        if (errorCode == ErrorCodes.EMR_NO_DEPARTMENT.code) {
          body = ErrorCodes.EMR_NO_DEPARTMENT.message;
        }
        const title = "Failed to export to clinical records";
        const confirmButtonText = "Cancel";
        const cancelButtonText = "Cancel";
        this.exporting = false;
        this.needRefresh.emit();

        setTimeout(() => {
          this.dialogRef = this.alertService.customDialog(
            title,
            body,
            confirmButtonText,
            cancelButtonText,
            true,
            "horizontal"
          );
        }, 300);
      },
      () => {
        this.exporting = false;
        this.needRefresh.emit();
        this.initializePhotoIntegrations();
      }
    );
  }

  exportToClinicalRecord(clinicalRecordId, companyId, serviceCode) {
    const path = `${environment.celoApiEndpoint}/api/v2/media/${this.mediaContainer.payload.id}/export`;
    const body: any = {
      integrationId: clinicalRecordId,
      companyId,
      serviceCode,
    };
    return this.sharedService.postObjectById(path, {}, body);
  }
}
