import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { UserSelectionList } from "../../shared/basic-user-selection-list/basic-user-selection-list.component";
import { BasicUserPickerService } from "../basic-user-picker.service";

@Component({
  selector: "app-user-picker-submit-button",
  templateUrl: "./user-picker-submit-button.component.html",
  styleUrls: ["./user-picker-submit-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPickerSubmitButtonComponent implements OnChanges {
  @Input() public text: string = "Submit";
  @Input() public selectedUsers: UserSelectionList | null = null;

  public isDisabled = true;

  public constructor(private userPickerService: BasicUserPickerService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["selectedUsers"]) {
      this.isDisabled =
        this.selectedUsers == null || this.selectedUsers.length === 0;
    }
  }

  public handleSubmit() {
    this.userPickerService.submitSelectedUsers();
  }
}
