<div
  class="celo-cursor-pointer icon_size"
  [ngClass]="{ 'celo-cursor-pointer': clickable }"
  [style.height.px]="size ? size : 'auto'"
  [style.width.px]="size ? size : 'auto'"
  (click)="clickable ? openBadgeInfo() : ''"
>
  <ng-container *ngIf="!isExternal; else external">
    <div *ngIf="identityVerificationStatus === 'Verified'" class="fh fw">
      <img
        *ngIf="
          (!profession ||
            profession.verificationStatus !== 'Verified' ||
            doNotShowProfessionVerificationStatus) &&
          !isProfessionVerified
        "
        class="verified-star"
        [matTooltip]="showTooltip ? 'Identity verified' : ''"
        src="../../../assets/icons/ic-verified.svg"
      />
      <img
        *ngIf="
          (profession &&
            profession.verificationStatus === 'Verified' &&
            !doNotShowProfessionVerificationStatus) ||
          isProfessionVerified
        "
        class="verified-star"
        [matTooltip]="showTooltip ? 'Verified profession' : ''"
        src="../../../assets/icons/ic-verified-red.svg"
      />
    </div>

    <div *ngIf="identityVerificationStatus !== 'Verified'" class="fh fw">
      <div *ngIf="!isWorkspaceVerified && !hideUnverifiedBadge" class="fh fw">
        <img
          class="verified-circle"
          [matTooltip]="showTooltip ? 'Unverified user' : ''"
          src="../../../assets/icons/ic-unverified.svg"
        />
      </div>
      <div *ngIf="isWorkspaceVerified" class="fh fw">
        <img
          class="verified-circle"
          [matTooltip]="showTooltip ? 'Verified workspace' : ''"
          src="../../../assets/icons/ic-verified-workspace.svg"
        />
      </div>
    </div>
  </ng-container>

  <ng-template #external>
    <div class="fh fw">
      <img
        [matTooltip]="showTooltip ? 'External user' : ''"
        src="../../../assets/icons/ic-external.svg"
      />
    </div>
  </ng-template>
</div>
