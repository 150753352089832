<div class="content">
  <app-badge-info
    [ownProfile]="true"
    [userId]="userId"
    [name]="userAccount.firstName + ' ' + userAccount.lastName"
    [name_verified_as]="userAccount.legalFirstName + ' ' + userAccount.lastName"
    [title]="userAccount.title"
    [workspaces]="userAccount.workplaces"
    [identityVerificationStatus]="userAccount.identityVerificationStatus"
    [hideUnverifiedStatus]="true"
    [profession]="userAccount.professions[0]"
    [hasImage]="userAccount.picture"
  ></app-badge-info>
</div>
<hr />
<div
  class="celo-hoverable celo-cursor-pointer share"
  matRipple
  (click)="loading ? '' : share()"
>
  <div>Share your profile to invite your colleagues and connect on Celo.</div>
  <div class="button">
    <app-empty-state
      *ngIf="loading"
      [no_message]="true"
      [isLoading]="true"
      [spinner]="true"
      [animation]="false"
      [diameter]="15"
      [containerHeight]="15"
    ></app-empty-state>
    <div *ngIf="!loading" class="flex items-center">
      <mat-icon>content_copy</mat-icon>
      <span class="text">Copy</span>
    </div>
  </div>
</div>
