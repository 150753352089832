<div class="onboard relative">
  <div class="content">
    <div class="contents">
      <img class="logo" src="../../../assets/logo-v2.svg" alt="" />
      <img class="cover" src="../../../assets/password.svg" alt="" />
      <div class="title">Create your password</div>
      <div class="fields celo-input-outline">
        <mat-form-field appearance="outline" class="fw">
          <mat-label>New Password</mat-label>
          <input
            matInput
            class="fw"
            [type]="showPassword ? 'text' : 'password'"
            autocomplete="off"
            placeholder="New Password"
            required
            [(ngModel)]="password"
            id="passwordField"
            name="passwordField"
            #passwordField="ngModel"
            (ngModelChange)="message = ''"
            (keydown.enter)="password && !loading && submit()"
            appAutofocus
          />
          <button
            class="show-password"
            mat-icon-button
            matSuffix
            (click)="showPassword = !showPassword; toggleShowEvent()"
            [attr.aria-label]="'Show password'"
            [attr.aria-pressed]="showPassword"
          >
            <mat-icon>{{
              showPassword ? "visibility" : "visibility_off"
            }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div *ngIf="message" class="error-message appear_1 text-center mb-8">
        {{ message }}
      </div>
      <div class="subtitle mb-8">Password must include:</div>
      <div class="subtitle">
        <ul>
          <li>1 upper & lower case letter</li>
          <li>1 number & symbol</li>
          <li>8 or more characters</li>
        </ul>
      </div>
      <div class="celo-text-14">
        By creating your Celo account, you confirm you have read and agree to
        Celo’s
        <a
          (click)="tAndCEvent()"
          href="https://www.celohealth.com/legal"
          target="_blank"
          class="celo-link nowrap"
          >Terms and Conditions of Use.</a
        >
      </div>
      <div class="buttons">
        <button
          (click)="back()"
          mat-button
          class="celo-secondary-button button"
        >
          BACK
        </button>
        <button
          type="submit"
          mat-button
          class="celo-primary-button button"
          (click)="submit()"
          [disabled]="!password || loading"
        >
          CREATE ACCOUNT
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="loading">
    <div class="spinner-box">
      <div class="spinner">
        <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
      </div>
    </div>
  </div>
</div>
