<div matDialogTitle class="title">
  <img
    src="../../../../../assets/icons/icon-home.svg"
    alt="Workspace icon"
    width="40px"
    height="40px"
  />
  <h2 class="mat-headline-6">{{ title }}</h2>
</div>

<mat-dialog-content class="mat-body">
  {{ message }}
</mat-dialog-content>

<mat-dialog-actions>
  <button
    [mat-dialog-close]="true"
    mat-button
    class="celo-primary-button uppercase"
  >
    Done
  </button>
</mat-dialog-actions>
