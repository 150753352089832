<div class="celo-text-14 box">
  <div class="pic">
    <app-avatar
      [name]="name"
      [userId]="userId"
      [width]="150"
      [height]="150"
      [hasImage]="hasImage"
    >
    </app-avatar>
  </div>
  <div class="texts">
    <div class="item">
      <div class="name">
        {{ title }} {{ name }}
        <app-badge
          class="inline-block"
          [identityVerificationStatus]="identityVerificationStatus"
          [workspaces]="workspaces"
          [profession]="profession"
          [size]="28"
          [clickable]="false"
        ></app-badge>
      </div>
      <div *ngIf="ownProfile">
        <div
          *ngIf="profession && profession.verificationStatus"
          class="profession"
        >
          {{ profession.profession }}
          <span *ngIf="profession.verificationStatus === 'Pending'">
            (Pending)</span
          >
        </div>
      </div>
      <div *ngIf="!ownProfile">
        <div
          *ngIf="profession && profession.verificationStatus === 'Verified'"
          class="profession"
        >
          {{ profession.profession }}
        </div>
      </div>
    </div>
    <div class="badges-meaning celo-hoverable">
      <div
        *ngIf="profession && profession.verificationStatus === 'Verified'"
        class="item item-left-aligned"
      >
        <div class="badge-title">
          <app-badge
            class="inline-block"
            [identityVerificationStatus]="'Verified'"
            [profession]="{ verificationStatus: 'Verified' }"
            [size]="20"
            [clickable]="false"
          ></app-badge>
          Verified profession
        </div>
        <div
          *ngIf="profession.profession"
          class="badge-subtitle item-left-aligned-subtitle"
        >
          {{ profession.profession }}
        </div>
      </div>
      <div
        *ngIf="identityVerificationStatus === 'Verified'"
        class="item item-left-aligned"
      >
        <div class="badge-title">
          <app-badge
            class="inline-block"
            [identityVerificationStatus]="'Verified'"
            [size]="20"
            [clickable]="false"
          ></app-badge>
          Verified identity
        </div>
        <div class="item-left-aligned-subtitle">
          <span class="badge-subtitle-lite">Verified as </span
          ><span class="badge-subtitle">{{ name_verified_as }}</span>
        </div>
      </div>
      <div *ngIf="isWorkspaceVerified" class="item item-left-aligned">
        <div class="badge-title">
          <app-badge
            class="inline-block"
            [workspaces]="workspaces"
            [size]="20"
            [clickable]="false"
          ></app-badge>
          Verified workspaces
        </div>
        <div
          class="badge-subtitle item-left-aligned-subtitle"
          *ngFor="
            let workspace of verifiedWorkspaces;
            let index = index;
            let last = last
          "
        >
          <div *ngIf="index < maxWorkspacesToShow">
            {{ workspace.companyName }}
          </div>
          <div *ngIf="last && index >= maxWorkspacesToShow">
            +{{ verifiedWorkspaces.length - maxWorkspacesToShow }} other
            workspace{{
              verifiedWorkspaces.length - maxWorkspacesToShow > 1 ? "s" : ""
            }}
          </div>
        </div>
      </div>
      <div *ngIf="!ownProfile && !hideUnverifiedStatus">
        <div
          *ngIf="
            identityVerificationStatus !== 'Verified' && !isWorkspaceVerified
          "
          class="item item-left-aligned"
        >
          <div class="badge-title">
            <app-badge
              class="inline-block"
              [identityVerificationStatus]="'Unverified'"
              [size]="20"
              [clickable]="false"
            ></app-badge>
            Unverified user
          </div>
          <div class="item-left-aligned-subtitle subtext">
            Please use caution when contacting this user.
          </div>
        </div>
      </div>
      <div *ngIf="ownProfile && !hideUnverifiedStatus">
        <div *ngIf="identityVerificationStatus !== 'Verified'" class="item">
          <div *ngIf="identityVerificationStatus === 'Pending'">
            <div class="badge-title">Identity verification pending</div>
            <div class="subtext">
              We're reviewing your request, you will become verified once it has
              been approved by our team.
            </div>
            <div>&nbsp;</div>
            <div class="subtext">
              Contact
              <a class="celo-link" href="mailto:support@celohealth.com"
                >support@celohealth.com</a
              >
              for help.
            </div>
          </div>
          <div *ngIf="identityVerificationStatus === 'Unverified'">
            <div class="badge-title">
              <app-badge
                class="inline-block"
                [identityVerificationStatus]="'Unverified'"
                [size]="20"
                [clickable]="false"
              ></app-badge>
              Unverified user
            </div>
            <!-- <div class="subtext">You can verify your identity using <a class="celo-link" (click)="verifyIdentity()">the mobile app</a></div> -->
            <app-verify-identity-info
              [message]="'You can verify your identity using the mobile app'"
            ></app-verify-identity-info>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
