import {
  ConversationParticipant,
  ConversationParticipantModelV2,
  ConversationParticipantModelV3,
  ParticipantRole,
} from "@types";
import { produce } from "immer";
import { partition, sorted } from "./array-utils";
import { maxDate } from "./date-utils";
import { isNotNullOrUndefined } from "./type-predicate-utils";

const getLatestReadMessageUtc = (
  dates: (string | Date | null | undefined)[]
): Date | null => {
  const values = dates.filter(isNotNullOrUndefined).map((d) => new Date(d));
  if (!values.length) return null;
  return maxDate(values);
};

export class ConversationParticipantUtils {
  public static isConversationParticipant = (
    value: unknown
  ): value is ConversationParticipant => {
    return value["_type"] === "ConversationParticipant";
  };

  public static fromConversationParticipantModelV3 = (
    model: ConversationParticipantModelV3
  ): ConversationParticipant => {
    const userId = model.userId;
    if (!userId) throw new Error("userId is required");

    const conversationParticipant: ConversationParticipant = {
      _type: "ConversationParticipant",
      userId: model.userId,
      email: model.email,
      connection: model.connection,
      blockedByMe: model.blockedByMe,
      blockedMe: model.blockedMe,
      profilePic: model.profilePic,
      title: model.title,
      firstName: model.firstName,
      lastName: model.lastName,
      legalFirstName: model.legalFirstName,
      isOnCall: model.isOnCall,
      professions: model.professions ?? [],
      identityVerificationStatus: model.identityVerificationStatus,
      doNotDisturbToUtc: model.doNotDisturbToUtc,
      workplaces: model.workplaces ?? [],
      leftOnUtc: model.leftOnUtc,
      joinedOnUtc: model.joinedOnUtc,
      mutedToUtc: model.mutedToUtc,
      muteInterval: model.muteInterval,
      isHidden: model.isHidden,
      asReadToUtc: model.asReadToUtc,
      role: model.role,
      profilePicture: model.profilePic,
      pinnedOnUtc: model.pinnedOnUtc,
      latestReadMessageUtc: model.latestReadMessageUtc,
      teamId: model.teamId,
      isActive: model.isActive,
      isExternal: model.isExternal,
      phoneNumber: model.phoneNumber,
    };

    return conversationParticipant;
  };

  public static updateFromConversationParticipantModelV3 = (
    participant: ConversationParticipant,
    model: ConversationParticipantModelV3
  ): ConversationParticipant => {
    return produce(participant, (draft) => {
      draft.email = model.email;
      draft.connection = model.connection;
      draft.blockedByMe = model.blockedByMe;
      draft.blockedMe = model.blockedMe;
      draft.profilePic = model.profilePic;
      draft.title = model.title;
      draft.firstName = model.firstName;
      draft.lastName = model.lastName;
      draft.legalFirstName = model.legalFirstName;
      draft.isOnCall = model.isOnCall;
      draft.professions = model.professions ?? [];
      draft.identityVerificationStatus = model.identityVerificationStatus;
      draft.doNotDisturbToUtc = model.doNotDisturbToUtc;
      draft.workplaces = model.workplaces ?? [];
      draft.leftOnUtc = model.leftOnUtc;
      draft.joinedOnUtc = model.joinedOnUtc;
      draft.mutedToUtc = model.mutedToUtc;
      draft.muteInterval = model.muteInterval;
      draft.isHidden = model.isHidden;
      draft.asReadToUtc = model.asReadToUtc;
      draft.role = model.role;
      draft.profilePicture = model.profilePic;
      draft.pinnedOnUtc = model.pinnedOnUtc;
      draft.latestReadMessageUtc =
        getLatestReadMessageUtc([
          draft.latestReadMessageUtc,
          model.latestReadMessageUtc,
        ])?.toISOString() ?? null;
      draft.teamId = model.teamId;
      draft.isActive = model.isActive;
      draft.isExternal = model.isExternal;
      draft.phoneNumber = model.phoneNumber;
    });
  };

  public static sort = (
    participants: ConversationParticipant[],
    userId: string
  ): ConversationParticipant[] => {
    const [user, others] = partition(participants, (p) => p.userId === userId);

    const sortedOthers = sorted(others, (a, b) => {
      const nameA = a.firstName.toLowerCase();
      const nameB = b.firstName.toLowerCase();
      return nameA > nameB ? 1 : nameB > nameA ? -1 : 0;
    });

    const [admins, nonAdmins] = partition(
      sortedOthers,
      (p) => p.role === ParticipantRole.Administrator
    );

    return [...user, ...admins, ...nonAdmins];
  };

  /**
   * Returns a copy of `conversations` where participants with a first name or
   * last name matching `query` are sorted to the start of the array
   */
  public static sortSearchResultParticipants = (
    conversations: ConversationParticipant[],
    query: string
  ): ConversationParticipant[] => {
    return produce(conversations, (draft) => {
      const lowerCaseQuery = query.toLowerCase();
      for (let i = 0; i < draft.length; i++) {
        if (!draft[i].firstName && !draft[i].lastName) {
          continue;
        }
        const firstName: string = draft[i].firstName;
        const lastName: string = draft[i].lastName;
        if (
          firstName.toLowerCase().indexOf(lowerCaseQuery) !== -1 ||
          lastName.toLowerCase().indexOf(lowerCaseQuery) !== -1
        ) {
          const temp = draft[i];
          draft.splice(i, 1);
          draft.unshift(temp);
        }
      }
    });
  };

  public static findConversationParticipant = (
    participants: ConversationParticipant[],
    userId: string
  ): ConversationParticipant | null => {
    return participants.find((p) => p.userId === userId) ?? null;
  };

  public static findConversationParticipantModelV2 = (
    participants: ConversationParticipantModelV2[],
    userId: string
  ): ConversationParticipantModelV2 | null => {
    return participants.find((p) => p.userId === userId) ?? null;
  };

  public static isLastActiveAdmin(
    participants: ConversationParticipant[],
    userId: string
  ): boolean {
    const activeConversationAdmins = participants.filter(
      (p) => !p.leftOnUtc && p.role === ParticipantRole.Administrator
    );

    return (
      activeConversationAdmins.length === 1 &&
      activeConversationAdmins[0].userId === userId
    );
  }

  public static isLastActiveParticipant(
    participants: ConversationParticipant[],
    userId: string
  ): boolean {
    const activeConversationParticipants = participants.filter(
      (p) => !p.leftOnUtc
    );
    return (
      activeConversationParticipants.length === 1 &&
      activeConversationParticipants[0].userId === userId
    );
  }
}
