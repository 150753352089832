import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AnalyticsService, SharedService } from "../../modules/core";

@Component({
  selector: "app-email",
  templateUrl: "./email.component.html",
  styleUrls: [
    "./email.component.scss",
    "../onboard.scss",
    "../../../celo-input.scss",
  ],
})
export class EmailComponent implements OnInit {
  email = "";
  loading = false;
  countryName: string;
  countryCode: string;
  countryIsAvailable: string;
  dataRegionId: string;

  constructor(
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((paramMap) => {
      this.countryName = paramMap.get("country_name");
      this.countryCode = paramMap.get("country_code");
      this.countryIsAvailable = paramMap.get("country_is_available");
      this.dataRegionId = paramMap.get("data_region_id");
    });

    this.analyticsService.raiseEvent("celo_page_view", {
      flow: "signing_up",
      page_name: "email_input_page",
    });
  }

  sendEmail() {
    this.loading = true;
    const instance = this;
    this.sharedService.sendOnboardEmail(
      this.email,
      this.dataRegionId,
      false,
      function (verification_id) {
        instance.loading = false;
        if (verification_id) {
          instance.sharedService.navigateTo("/onboard/email-confirm", {
            verification_id,
            email: instance.email,
            country_code: instance.countryCode,
            country_name: instance.countryName,
            country_is_available: instance.countryIsAvailable,
            data_region_id: instance.dataRegionId,
          });
        }
      }
    );
  }
}
