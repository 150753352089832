<div class="page">
  <div class="spinner">
    <app-empty-state
      [no_message]="true"
      [isLoading]="true"
      [spinner]="false"
      [animation]="true"
      [diameter]="70"
    ></app-empty-state>
  </div>
</div>
