<div class="box celo-break-word">
  <div *ngIf="title" class="title">{{ title }}</div>
  <div *ngIf="content" class="content alert-content">
    <div class="mx-auto" [innerHtml]="content"></div>
  </div>
  <div class="actions">
    <button
      *ngIf="confirmButtonText"
      [mat-dialog-close]="true"
      mat-button
      class="celo-primary-button uppercase"
    >
      {{ confirmButtonText }}
    </button>
    <button
      *ngIf="!confirmOnly && cancelButtonText"
      [mat-dialog-close]="false"
      mat-button
      class="celo-secondary-button uppercase"
    >
      {{ cancelButtonText }}
    </button>
  </div>
</div>
