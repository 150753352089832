<form *ngIf="!loading" class="fw celo-input-outline" #form [formGroup]="myForm">
  <div *ngIf="!hideCompanyField" class="custom-bottom-form-field">
    <div *ngIf="!companyReadonly" class="fw">
      <mat-form-field appearance="outline" class="no-bottom-form-field">
        <mat-label>Workspace</mat-label>
        <mat-select
          id="company-select"
          [(ngModel)]="company"
          (ngModelChange)="
            onCompanySelected($event); filter.value = ''; onKey('')
          "
          (openedChange)="filter.value = ''; onKey(''); filter.focus()"
          required
          [disabled]="companyReadonly"
          name="company_field"
          #company_field
          formControlName="company_field"
        >
          <mat-form-field class="filter">
            <mat-icon matPrefix>search</mat-icon>
            <input
              id="filter"
              matInput
              #filter
              (keyup)="onKey($event.target.value)"
              (keydown)="$event.stopPropagation()"
              placeholder="Search"
              autocomplete="off"
            />
          </mat-form-field>
          <mat-option
            *ngFor="let company_ob of selectedCompanies"
            [value]="company_ob.companyId"
            >{{ company_ob.companyName }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="companyReadonly" class="fw">
      <mat-form-field appearance="outline" class="no-bottom-form-field">
        <mat-label>Workspace Name</mat-label>
        <input
          matInput
          type="text"
          autocomplete="off"
          placeholder="Workspace"
          [(ngModel)]="company.companyName"
          name="company_field"
          #company_field
          formControlName="company_field"
          readonly
          disabled
          class="celo-disabled"
          id="company-select-readonly"
        />
        <div matSuffix class="badge">
          <app-badge
            class="inline-block"
            [identityVerificationStatus]="false"
            [workspaces]="[workspace]"
            [hideUnverifiedBadge]="true"
            [size]="22"
            [showTooltip]="false"
            [clickable]="false"
          ></app-badge>
        </div>
      </mat-form-field>
    </div>
    <div *ngIf="workspaceSubtitle" class="celo-text-14 celo-grey subtitle">
      {{ workspaceSubtitle }}
    </div>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Department</mat-label>
      <mat-select
        [(ngModel)]="department"
        name="department_field"
        #department_field
        formControlName="department_field"
        id="department-select"
      >
        <mat-option selected [value]="''">No Department</mat-option>
        <mat-option
          [value]="department_ob.id"
          *ngFor="let department_ob of departments"
          >{{ department_ob.name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Position</mat-label>
      <input
        matInput
        type="text"
        autocomplete="off"
        placeholder="Your position"
        [(ngModel)]="position"
        name="position_field"
        #position_field
        formControlName="position_field"
        id="position-select"
      />
    </mat-form-field>
  </div>
  <div *ngIf="!hideEmailField">
    <mat-form-field appearance="outline">
      <mat-label>Work Email</mat-label>
      <input
        class="celo-disabled"
        matInput
        type="text"
        autocomplete="off"
        placeholder="Email"
        required
        readonly
        [(ngModel)]="email"
        name="email_field"
        #email_field
        formControlName="email_field"
        id="email-select"
      />
    </mat-form-field>
  </div>
</form>

<div *ngIf="loading">
  <app-empty-state
    [isLoading]="true"
    [message]="'Please wait'"
  ></app-empty-state>
</div>
