import { Component, Inject, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { CustomDialogComponent } from "@modules/shared/custom-dialog/custom-dialog.component";
import { Observable } from "rxjs";
import { AlertComponent } from "../components/alert-old/alert.component";

export interface DialogOptions {
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  isCancellable?: boolean;
}

/** @deprecated Use the `MatDialog` service directly or create a static method on the component you want to open in a dialog that accepts `MatDialog` as it's first parameter. */
@Injectable()
export class AlertService {
  constructor(
    private dialogService: MatDialog,
    private snackbarService: MatSnackBar
  ) {}

  /**
   * Show a modal dialog with an error message. The returned observable will complete when the dialog has been closed.
   */
  public error(
    message: string = "An unexpected error occurred. Please try again."
  ): Observable<boolean> {
    return this.alert("Error", message);
  }

  /**
   * Show a modal dialog with a title, message, and ok/confirmation button. The returned observable will complete when
   * the dialog has been closed. The value from the returned observable will be true if the user clicked confirm, and
   * false otherwise.
   */
  public dialog(options: DialogOptions): Observable<boolean> {
    const {
      title,
      message,
      confirmButtonText,
      cancelButtonText,
      isCancellable,
    } = options;
    return this.confirm(
      title,
      message,
      confirmButtonText ?? "Confirm",
      cancelButtonText ?? "Cancel",
      !isCancellable
    );
  }

  alert(
    title: string,
    content: string,
    confirmOnly = false,
    confirmButtonText = "Close"
  ): Observable<boolean> {
    content = content;
    return this.confirm(title, content, confirmButtonText, "", confirmOnly);
  }

  connectToInternetSnackbar() {
    this.showSnackBar("Please connect to the internet to generate a link", 4);
  }

  showSnackBar(message: string, durationInSeconds: number, theme?: string) {
    // const snackBarRef = this.snackBar.open(message, '', {
    //   duration: duration
    // });
    theme = theme ? theme : "black";
    const snackBarRef = this.snackbarService.openFromComponent(
      SnackbarComponent,
      {
        duration: durationInSeconds * 1000,
        panelClass: [theme + "_snackbar"],
        data: {
          message,
          theme,
        },
      }
    );
  }

  confirm(
    title: string,
    content: string,
    confirmButtonText = "Confirm",
    cancelButtonText = "Cancel",
    confirmOnly = false
  ): Observable<boolean> {
    const ref = this.dialogService.open(AlertComponent, {
      autoFocus: false,
      panelClass: "dialog-panel",
    });
    const i = ref.componentInstance;
    i.title = title;
    i.content = content;
    i.confirmButtonText = confirmButtonText;
    i.cancelButtonText = cancelButtonText;
    i.confirmOnly = confirmOnly;
    return ref.afterClosed();
  }

  customDialog(
    title: string,
    content: string,
    confirmButtonText = "Confirm",
    cancelButtonText = "Cancel",
    confirmOnly = false,
    alignment = "horizontal",
    payload?: string | any[] | object,
    hideCloseButton?: boolean
  ) {
    const ref = this.dialogService.open(CustomDialogComponent, {
      autoFocus: false,
      panelClass: "alert-panel",
    });
    const i = ref.componentInstance;
    i.title = title;
    i.alignment = alignment;
    i.content = content;
    i.confirmButtonText = confirmButtonText;
    i.cancelButtonText = cancelButtonText;
    i.confirmOnly = confirmOnly;
    i.payload = payload;
    i.hideCloseButton = hideCloseButton;
    return ref;
  }

  verifyIdentityDialog() {
    const payload = [
      {
        type: "verifyIdentityInfo",
        message:
          "You can verify your identity using the mobile app. Scan the QR codes or click on the buttons below to download the Celo mobile app.",
      },
    ];
    this.customDialog(
      "Identity Verification",
      "",
      "DONE",
      "",
      true,
      "vertical",
      payload
    );
  }
}

@Component({
  selector: "snackbar",
  templateUrl: "../components/alert-old/snackbar.html",
  styleUrls: ["../components/alert-old/snackbar.css"],
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {}
}
