<ng-container
  *ngIf="{
    activities: activities$ | async,
    idsOfFirstActivityPerDay: idsOfFirstActivityPerDay$ | async
  } as state"
>
  <div *ngIf="state.activities === null">
    <div class="spinner-container">
      <div class="spinner">
        <mat-progress-spinner
          [strokeWidth]="3"
          [diameter]="30"
          [color]="'primary'"
          [mode]="'indeterminate'"
        >
        </mat-progress-spinner>
      </div>
    </div>
  </div>

  <div *ngIf="state.activities?.length === 0" class="empty-message">
    <app-activity-feed-empty></app-activity-feed-empty>
  </div>

  <div *ngIf="state.activities && state.activities?.length !== 0" class="list">
    <div *ngFor="let activity of state.activities">
      <div
        *ngIf="state.idsOfFirstActivityPerDay?.has(activity.id)"
        class="date"
      >
        {{ activity.timeStampUtc | dateFormat: "relativeDay" }}
      </div>
      <div mat-ripple class="celo-hoverable item">
        <app-activity-feed-list-item
          [activity]="activity"
        ></app-activity-feed-list-item>
      </div>
    </div>
  </div>
</ng-container>
