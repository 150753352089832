<app-basic-user-selection-list
  *ngIf="notRemovableSelectedUsers?.length"
  [(users)]="notRemovableSelectedUsers"
  [rippleDisabled]="true"
  [showDescription]="false"
></app-basic-user-selection-list>

<app-basic-user-selection-list
  *ngIf="selectedUsers?.length"
  [(users)]="selectedUsers"
  [alwaysShowSelectedContent]="true"
  (user)="handleUser($event)"
  [rippleDisabled]="true"
  [showDescription]="false"
>
  <ng-template appSelected>
    <app-deselect-icon></app-deselect-icon>
  </ng-template>
</app-basic-user-selection-list>
