<div class="page">
  <div class="content">
    <div class="title">Oops something went wrong</div>
    <div class="subtitle">
      Something went wrong and we couldn't log you in. If you refresh the page
      or click on Login, you can try again. If this problem persists contact us
      at <a href="mailto:support@celohealth.com">support@celohealth.com</a>
    </div>
    <button class="celo-primary-button" (click)="login()">LOGIN</button>
    <!-- <button class="celo-primary-button" (click)="logout()">LOGIN</button> -->
    <!-- <button class="celo-primary-button" (click)="logout()">TRY AGAIN</button> -->
  </div>
</div>
