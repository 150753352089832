<div class="close-button">
  <button mat-icon-button mat-dialog-close>
    <i class="material-icons">close</i>
  </button>
</div>
<div class="page celo-input-outline">
  <div >
    <div *ngIf="step === 'step1'">
      <div class="content">
        <div class="pic-box">
          <img class="pic" src="../../../assets/workspace-add.svg" alt="" />
        </div>
        <div class="title">Join Workspace</div>
        <div class="celo-grey celo-text-14 subtitle">
          You can join an existing workspace by following a workspace invite
          link or by entering your company email below.
        </div>
        <div class="fields">
          <mat-form-field appearance="outline" class="fw">
            <mat-label>Work Email</mat-label>
            <input
              matInput
              class="fw"
              type="email"
              autocomplete="off"
              placeholder="Email"
              required
              [(ngModel)]="email"
              id="emailField"
              name="emailField"
              #emailField="ngModel"
              pattern="^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"
            />
          </mat-form-field>
        </div>
        <div class="subsubtitle p-2">
          Don’t have a work email?
          <a (click)="createWorkspace()" class="celo-link"
            >Create a workspace</a
          >
        </div>

        <div class="buttons">
          <div class="button">
            <button
              (click)="submitStep1(email)"
              [disabled]="!emailField || emailField.invalid || loading"
              mat-button
              class="celo-primary-button squarebutton"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="step === 'step2'">
      <div class="title">Confirm Email</div>
      <div class="content">
        <div class="pic-box">
          <img class="pic" src="../../../assets/confirm-email.png" alt="" />
        </div>
        <div class="title">We need to confirm your email</div>
        <div class="subtitle p-2">
          We’ve sent a code to <span class="celo-bold">{{ email }}</span
          >. Didn’t receive a code?
          <span
            class="celo-link"
            [ngClass]="{ 'celo-link-disabled': resending }"
            (click)="resendCode(email)"
            >Resend Code</span
          >
        </div>
        <div class="fields custom-bottom-form-field">
          <mat-form-field appearance="outline" class="no-bottom-form-field">
            <mat-label>Enter Code</mat-label>
            <input
              matInput
              type="text"
              autocomplete="off"
              placeholder="Code"
              required
              autofocus
              [(ngModel)]="code"
            />
          </mat-form-field>
        </div>
        <div class="subsubtitle p-2">
          Didn’t receive an email? Please check your spam folder. Sometimes
          organisations block unknown email addresses. If you continue to have
          any issues, please contact us at
          <a class="celo-bold celo-link" href="mailto: support@celohealth.com"
            >support@celohealth.com</a
          >.
        </div>
        <div class="buttons">
          <div class="button">
            <button
              [mat-dialog-close]="false"
              mat-button
              class="celo-secondary-button"
            >
              Cancel
            </button>
          </div>
          <div class="button">
            <button
              (click)="submitStep2(email, code)"
              [disabled]="!code || loading"
              mat-button
              class="celo-primary-button squarebutton"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="step === 'step3'">
      <div class="title">Workspace details</div>
      <hr />
      <div class="content">
        <div class="fields">
          <app-add-company-details
            [email]="email"
            [countryCode]="countryCode"
            [filterOutJoinedWorkspaces]="true"
            [joinedWorkspaces]="userAccount.workplaces"
            (validity)="workspace_details_valid = $event"
            (dataChange)="workspace_details = $event"
            (exit)="closeDialog(undefined)"
            (createWorkspaceEvent)="createWorkspace()"
          ></app-add-company-details>
        </div>
        <div class="buttons">
          <div class="button">
            <button
              #closeButton
              [mat-dialog-close]="false"
              mat-button
              class="celo-secondary-button"
            >
              Cancel
            </button>
          </div>
          <div class="button">
            <button
              (click)="save()"
              [disabled]="!workspace_details_valid || loading"
              mat-button
              class="celo-primary-button squarebutton"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
