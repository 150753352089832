<div class="content">
  <h1 mat-dialog-title>Confirm Email</h1>
  <div mat-dialog-content class="body">
    <div class="img appear_anim_1">
      <img src="../../../assets/confirm-email.png" alt="" />
    </div>
    <div class="title">We need to confirm your email</div>
    <div class="celo-grey text">
      We’ve sent a code to {{ data.email }}. Didn’t receive a code?
      <a (click)="verifyEmail(data.email)" class="celo-link">Resend Code</a>.
    </div>
    <div class="fields celo-input-outline">
      <mat-form-field class="fw" appearance="outline">
        <input
          class="fw"
          matInput
          [(ngModel)]="data.code"
          placeholder="Enter code"
        />
      </mat-form-field>
    </div>
    <div class="celo-grey text">
      Didn’t receive an email? Please check your spam folder. Sometimes
      organisations block unknown email addresses. If you continue to have any
      issues, please contact us at
      <a class="celo-link" href="mailto:support@celohealth.com"
        >support@celohealth.com</a
      >.
    </div>
  </div>
  <div mat-dialog-actions class="buttons">
    <button
      mat-button
      [disabled]="!data.code || submitting"
      class="celo-primary-button fw"
      (click)="verifyCode(data.code, data.email)"
      cdkFocusInitial
    >
      CONFIRM EMAIL
    </button>
  </div>
  <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="false">
      <i class="material-icons">cancel</i>
    </button>
  </div>
</div>
