import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SoundPlayService } from "@modules/shared/sound-play.service";
import { NotificationData } from "@utils";
import { interval, Subject } from "rxjs";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  private notificationsSubject = new Subject();

  private NOTIFICATION_STORAGE_PREFIX = "notification_";
  private NOTIFICATION_GC_DELAY = 10000;
  private NOTIFICATION_GC_INTERVAL = 10000;

  private unreadMessagesNotificationTag: string = "unreadMessagesNotification";
  private unreadMessagesNotification: Notification | null = null;

  private callNotifications = new Map<string, Notification>();
  private dismissedVideoCallIds = new Set<string>();

  constructor(
    private userService: UserService,
    private soundPlayService: SoundPlayService,
    private router: Router
  ) {
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }

    this.notificationsSubject.subscribe({
      next: () => {},
    });

    interval(this.NOTIFICATION_GC_INTERVAL).subscribe({
      next: () => {
        this.clearOldNotificationsFromLocalStorage();
      },
    });
  }

  private clearOldNotificationsFromLocalStorage() {
    const now = Date.now();
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key) break;
      if (!key.startsWith(this.NOTIFICATION_STORAGE_PREFIX)) {
        continue;
      }

      const value = localStorage.getItem(key);
      if (!value) break;
      const timestamp = Number(value);

      if (!isNaN(timestamp) && now - timestamp > this.NOTIFICATION_GC_DELAY) {
        localStorage.removeItem(key);
      }
    }
  }

  private createNotificationKey(notificationId: string) {
    return this.NOTIFICATION_STORAGE_PREFIX + notificationId;
  }

  /**
   * Clears any notification entries from local storage.
   */
  private clearNotificationsFromLocalStorage() {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key) break;
      if (key.startsWith(this.NOTIFICATION_STORAGE_PREFIX)) {
        localStorage.removeItem(key);
      }
    }
  }

  /**
   * Resolves if the caller can go ahead and raise a notification, rejects if another caller
   * has obtained the right to raise a notification for the given id.
   */
  private async tryAcquireNotificationLock(
    notificationId: string,
    milliseconds: number = 1000
  ): Promise<string> {
    // Check if an item with the given id already exists
    if (localStorage.getItem(this.createNotificationKey(notificationId))) {
      return Promise.reject();
    }

    // Save the item with a unique id
    const id = crypto.randomUUID();
    const key = this.createNotificationKey(notificationId);

    try {
      localStorage.setItem(key, id);
    } catch (e) {
      // Local storage may be full
      this.clearNotificationsFromLocalStorage();

      try {
        localStorage.setItem(key, id);
      } catch (e) {
        return Promise.reject();
      }
    }

    // Check if it's changed
    const result: Promise<string> = new Promise((resolve, reject) => {
      setTimeout(() => {
        const value = localStorage.getItem(key);
        if (value !== id) {
          reject();
          return;
        }

        try {
          localStorage.setItem(key, Date.now().toString());
        } catch (e) {}

        resolve(value);
      }, milliseconds);
    });

    return result;
  }

  public dismissVideoCallNotification(callId: string) {
    this.dismissedVideoCallIds.add(callId);
    const notification = this.callNotifications.get(callId);
    if (!notification) return;
    notification.close();
  }

  public showPushNotification(
    notificationData: NotificationData,
    isIgnoreDoNotDisturbEnabled?: boolean
  ) {
    if (this.userService.isDoNotDisturbActive() && !isIgnoreDoNotDisturbEnabled)
      return;

    this.tryAcquireNotificationLock(notificationData.id)
      .then(() => {
        if (
          notificationData.tag === this.unreadMessagesNotificationTag &&
          this.unreadMessagesNotification &&
          notificationData.data.time < this.unreadMessagesNotification.data.time
        ) {
          // Ignore this notification, it's out of date
          return;
        }

        if (
          notificationData.data.isCall &&
          this.dismissedVideoCallIds.has(notificationData.data.callId)
        ) {
          // Video call was dismissed while the lock was being acquired
          return;
        }

        const notification = new Notification(notificationData.title, {
          // All notifications should be silent by default - we have our own audio for notifications
          silent: true,
          ...notificationData,
        });

        if (notification.tag === this.unreadMessagesNotificationTag) {
          this.unreadMessagesNotification = notification;
        } else if (notification.data.isCall) {
          this.callNotifications.set(notification.data.callId, notification);
        }

        this.soundPlayService.playNotificationSound();
        notification.onclick = ($event) => {
          const notification = $event.target as Notification | null | undefined;

          if (notification?.data?.url) {
            this.router.navigateByUrl(notification.data?.url);
          }

          notification.close();
          window.focus();
        };

        notification.onclose = () => {
          this.callNotifications.delete(notification.data.callId);
        };
      })
      .catch(() => {
        // Another tab will raise this notification
      });
  }
}
