<div class="library" #library>
  <div
    *ngIf="uploader.queue.length === 0"
    class="library-container"
    ng2FileDrop
    [uploader]="uploader"
    (fileOver)="dropZoneState($event)"
    (onFileDrop)="handleDrop($event)"
    [ngClass]="{ valid: dropZoneActive === 'valid' }"
  >
    <div class="drop-highlight">
      <div>
        <i class="material-icons icon-xxl">cloud_upload</i>
        <div>UPLOAD</div>
      </div>
    </div>

    <div class="library-tools" #tools>
      <div class="search-bar">
        <div class="topbar-input">
          <app-celo-topbar-search-input
            [placeholder]="'Search by Patient ID or Patient Name'"
            (doSearch)="queryChange($event)"
          >
          </app-celo-topbar-search-input>
        </div>
      </div>
      <div class="get-app-box font-14 celo-cursor-pointer" (click)="app()">
        <div>
          Videos can be uploaded on the
          <span class="celo-link">Celo mobile app</span>
        </div>
      </div>

      <div class="drop-zone">
        <app-drop-zone
          [type]="'media files'"
          [formats]="['jpg', 'png']"
          [uploader]="uploader"
        ></app-drop-zone>
      </div>
      <div class="toolbar">
        <app-library-secondbar-toolbar
          [pageName]="'Media'"
          [singleFileId]="
            selected && selected.length === 1 && selected[0].id
              ? selected[0].id
              : ''
          "
          [singleFileName]="
            'Patient Photo.' +
            (selected && selected[0] && selected[0].mediaType === 'Photo'
              ? 'jpeg'
              : 'mp4')
          "
          [singleFileType]="'media'"
          [selectedFileCount]="selected.length"
          [hideExportToClinicalRecord]="false"
        >
        </app-library-secondbar-toolbar>
      </div>
    </div>

    <div
      class="library-list"
      [style.height.px]="library.offsetHeight - tools.offsetHeight"
    >
      <app-empty-state *ngIf="loading" [isLoading]="true" [message]="'LOADING'">
      </app-empty-state>
      <div *ngIf="length === 0 && !loading">
        <app-empty-state
          *ngIf="!searchTerm"
          [title]="'Patient photos secure and separate from your own'"
          [message]="
            'Safely capture photos using the Celo Camera and keep them separate from your personal camera roll.'
          "
          [isEmpty]="true"
          [image]="'empty_states/empty-state-photos.svg'"
        ></app-empty-state>
        <app-empty-state
          *ngIf="searchTerm"
          [title]="'No results found'"
          [message]="'Try searching by Patient ID or Patient Name'"
          [isEmpty]="true"
          [image]="'no-results-found-directory-v2.svg'"
        ></app-empty-state>
      </div>
      <app-media-list
        [searchQuery]="searchTerm"
        (selsectionChange)="selsectionChange($event)"
        (length)="length = $event; loading = false"
        [maxSelectionSize]="8"
      ></app-media-list>
    </div>
  </div>

  <div *ngIf="uploader.queue.length !== 0">
    <app-file-upload-preview
      (closeMe)="closeUpload()"
      [type]="'Photos'"
      [isInSecureLibrary]="true"
      [uploader]="uploader"
    >
    </app-file-upload-preview>
  </div>
</div>
