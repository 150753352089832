import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  AccountService,
  AuthService,
  SettingsService,
  SharedService,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { ChangePasswordModel } from "@types";

@Component({
  selector: "app-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.scss"],
})
export class SetPasswordComponent implements OnInit {
  tempPassword = "";
  password = "";
  passwordConfirm = "";
  errorMessage = "";
  loading = false;
  defaultHint =
    "The password MUST be a minimum of 8 characters and contain lowercase characters (a-z), uppercase characters (A-Z), digits (0-9) and symbols (ex. !@#$%^&).";

  hint = this.defaultHint;

  constructor(
    private sharedService: SharedService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private settingsService: SettingsService,
    private accountService: AccountService
  ) {}

  ngOnInit() {
    this.settingsService.settings$.subscribe({
      next: (settings) => {
        this.hint = settings?.passwordSettings?.hintMessage ?? this.defaultHint;
      },
    });
  }

  checkIfPasswordsMatch() {
    return this.password.trim() == this.passwordConfirm.trim();
  }

  submit() {
    this.errorMessage = "";

    const params: ChangePasswordModel = {
      oldPassword: this.tempPassword,
      newPassword: this.password.trim(),
      confirmPassword: this.passwordConfirm.trim(),
    };

    if (!this.checkIfPasswordsMatch()) {
      this.errorMessage = "Passwords do not match. Please try again.";
      return;
    }

    this.loading = true;

    this.accountService.changePassword(params).subscribe({
      next: () => {
        this.tempPassword = this.password.trim();

        AlertComponent.openDialog(this.matDialog, {
          acceptOnly: true,
          acceptButtonText: "Login",
          title: "Password changed successfully",
          message:
            "Your password has been changed successfully. Please login using your new password.",
        })
          .afterClosed()
          .subscribe(() => {
            this.authService.logout("Force set password");
          });
      },
      error: (err) => {
        this.errorMessage = this.sharedService.getErrorMessageFromError(err);
        this.loading = false;
      },
    });
  }
}
