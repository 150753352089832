export enum BroadcastStatus {
    Sent = 'sent',
    Delivered = 'delivered',
    Read = 'read'
}

export interface Broadcast {
    content: string;
    createdOnUtc: string;
    deliveredCount: number;
    fileId: string | null;
    id: string;
    metadata: unknown;
    photoId: string | null;
    readCount: number;
    sentBy: string;
    sentCount: number;
    sentOnUtc: string;
    type: unknown;
    isViewStatusEnabled: boolean;
}

// #TODO confirm what this model will look like
export interface BroadcastRecipient {
    user: {
        id: string;
        name: string;
        verificationInfo: {
            isIdentityVerified: boolean;
            isWorkspaceVerified: boolean;
            isProfessionVerified: boolean;
        };
        avatar?: string | null;
    },
    status: BroadcastStatus;

    /** @format date-time */
    statusChangedTime: string;
}

export interface GetBroadcastRecipientsRequest {
    id: string;
    status?: BroadcastStatus;
    search?: string;
}