import { Component } from "@angular/core";
import { WorkspaceService } from "app/workspace/workspace.service";

export enum WorkspaceEmptyStates {
  add_a_workspace = "add_a_workspace",
  add_connections = "add_connections",
}

@Component({
  selector: "app-workspace-empty-state",
  templateUrl: "./workspace-empty-state.component.html",
  styleUrls: ["./workspace-empty-state.component.scss"],
})
export class WorkspaceEmptyStateComponent {
  cards = [
    {
      title: "Add a workspace",
      description: "Join or setup your team's workspace ",
      image:
        "../assets/images/join-workspace/empty-state-what-is-a-workspace.svg",
      id: "add_a_workspace",
    },
    {
      title: "Add connections",
      description:
        "Connect with your colleagues to build your personal network",
      image: "../assets/ctas/cta-add-your-colleagues.svg",
      id: "add_connections",
    },
  ];

  constructor(private workspaceService: WorkspaceService) {}

  cardClick(id) {
    this.workspaceService.workspaceEmptyStateChange.next(id);
  }
}
