<div *ngIf="!sending" class="fh">
  <div>
    <div class="content">
      <div *ngIf="recepients && recepients.length" class="subtext">
        Recipients: {{ recepients.length }}
      </div>
      <div class="textbox">
        <textarea
          [(ngModel)]="message"
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="What’s happening?"
          maxlength="3000"
        ></textarea>
      </div>
      <div class="relative">
        <div class="attachments">
          <div *ngIf="!uploader || !uploader.queue || !uploader.queue[0]">
            <button
              id="attach-files"
              mat-icon-button
              class="celo-hoverable"
              (click)="attachFiles()"
              disableRipple
              matTooltip="Attach photo or document"
            >
              <img src="../../../assets/icons/icon-attach.svg" />
            </button>
          </div>
          <div
            *ngIf="uploader && uploader.queue && uploader.queue[0]"
            class="attached"
          >
            <div class="filename celo-elipsis">
              {{ uploader.queue[0].file.name }}
            </div>
            <div>
              <button
                id="unattach-files"
                mat-icon-button
                class="celo-hoverable"
                disableRipple
                (click)="initializeFileUploader()"
                matTooltip="Attach photo or document"
              >
                <i class="material-icons-round">close</i>
              </button>
            </div>
          </div>
        </div>
        <div class="buttonbox">
          <button
            mat-button
            class="celo-secondary-button uppercase"
            (click)="cancel()"
            disableRipple
          >
            Cancel
          </button>
          <button
            disableRipple
            mat-button
            class="celo-primary-button uppercase"
            (click)="send()"
            [disabled]="!message?.trim().length && !uploader?.queue?.[0]"
          >
            Broadcast
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="sending" class="content">
  <app-empty-state
    [isLoading]="true"
    [message]="'Broadcasting message...'"
    [diameter]="40"
  ></app-empty-state>
</div>
