<div
  *ngIf="
    groupedLocalContactsDepartments &&
    groupedLocalContactsDepartments.length &&
    userAccount
  "
>
  <div *ngFor="let department of groupedLocalContactsDepartments" class="group">
    <app-workspace-list-title
      [title]="department.departmentName"
      [subtitle]="department.subtitle"
    ></app-workspace-list-title>
    <div
      *ngFor="let contact of department.contacts"
      class="celo-hoverable celo-cursor-pointer"
      (click)="onCardClick(contact)"
    >
      <app-celo-user-card
        [companyInFocusId]="workspaceId"
        [userId]="contact.userId"
        [contact]="contact"
        [replaceMyName]="contact.firstName + ' ' + contact.lastName + ' (You)'"
        [profileId]="userAccount.userId"
        [workspaces]="contact.workplaces"
        [title]="contact.title"
        [firstName]="contact.firstName"
        [lastName]="contact.lastName"
        [profilePic]="contact.profilePic"
        [position]="contact.position"
        [hideWorkspace]="false"
        [identityVerificationStatus]="contact.identityVerificationStatus"
        [showBadge]="true"
        [onlyShowPosition]="true"
      >
      </app-celo-user-card>
    </div>
  </div>
</div>
