<div class="close-button">
  <button mat-icon-button mat-dialog-close>
    <i class="material-icons">close</i>
  </button>
</div>
<div
  *ngIf="data && data.invitationObj && data.invitationObj.metadata"
  class="celo-break-word"
>
  <!-- UserSpecificWorkspaceJoin -->
  <div
    *ngIf="
      !joinWorkspaceErrorDescriptor &&
      data.invitationObj.type === 'UserSpecificWorkspaceJoin'
    "
  >
    <div class="title-line">
      <div class="icon">
        <mat-icon [svgIcon]="'home_outline'"></mat-icon>
      </div>
      <span class="title">&nbsp;{{ data.invitationObj.metadata.name }}</span>
    </div>
    <div class="subtitle">You are now a member of this workspace.</div>
    <div class="button">
      <button
        mat-button
        class="celo-primary-button uppercase"
        (click)="viewWorkspace(data.invitationObj.metadata.id)"
        [disabled]="loading"
      >
        View Workspace
      </button>
    </div>
  </div>

  <!-- WorkspaceJoin -->
  <div
    *ngIf="
      !joinWorkspaceErrorDescriptor &&
      data.invitationObj.type === 'WorkspaceJoin'
    "
  >
    <div class="title-line">
      <div class="icon">
        <mat-icon [svgIcon]="'home_outline'"></mat-icon>
      </div>
      <span *ngIf="data.invitationObj.isActive" class="title"
        >&nbsp;{{ data.invitationObj.metadata.name }}</span
      >
    </div>
    <div *ngIf="data.invitationObj.isActive">
      <div *ngIf="!data.invitationObj.isActioned">
        <div class="subtitle">
          Join your colleagues in
          {{ data.invitationObj.metadata.name }} Workspace.
        </div>
        <div class="button">
          <button
            mat-button
            class="celo-primary-button uppercase"
            (click)="action(data.invitationObj.id)"
            [disabled]="loading"
          >
            Join
          </button>
        </div>
      </div>
      <div *ngIf="data.invitationObj.isActioned">
        <div class="subtitle">You are now a member of this workspace.</div>
        <div class="button">
          <button
            mat-button
            class="celo-primary-button uppercase"
            (click)="viewWorkspace(data.invitationObj.metadata.id)"
          >
            View Workspace
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!data.invitationObj.isActive">
      <div class="subtitle">
        The invite link for this workspace has been reset. Please ensure you
        have the latest invite link to join the workspace.
      </div>
    </div>
  </div>

  <!-- Error actioning UserSpecificWorkspaceJoin or WorkspaceJoin -->
  <div *ngIf="joinWorkspaceErrorDescriptor">
    <div class="title-line">
      <div class="icon">
        <mat-icon [svgIcon]="'home_outline'"></mat-icon>
      </div>
      <span class="title">&nbsp;{{ data.invitationObj.metadata.name }}</span>
    </div>
    <div
      class="subtitle error"
      [innerText]="joinWorkspaceErrorDescriptor.message"
    ></div>
    <div class="button">
      <button
        mat-button
        class="celo-primary-button uppercase"
        (click)="close()"
      >
        Done
      </button>
    </div>
  </div>

  <!-- Group/Case -->
  <div
    *ngIf="
      data.invitationObj.type === 'Group' || data.invitationObj.type === 'Case'
    "
  >
    <div class="title-line">
      <div class="icon">
        <mat-icon
          *ngIf="data.invitationObj.type === 'Group'"
          [svgIcon]="'avatar-group'"
        ></mat-icon>
        <mat-icon
          *ngIf="data.invitationObj.type === 'Case'"
          [svgIcon]="'avatar-patient'"
        ></mat-icon>
      </div>
      <span *ngIf="data.invitationObj.isActive" class="title"
        >&nbsp;{{ data.invitationObj.metadata.name }}</span
      >
    </div>
    <div *ngIf="data.invitationObj.isActive">
      <div *ngIf="!data.invitationObj.isActioned">
        <div class="subtitle">
          Join your colleagues in {{ data.invitationObj.metadata.name }}
          <span class="lowercase">{{ data.invitationObj.type }}</span
          >.
        </div>
        <div class="button">
          <button
            mat-button
            class="celo-primary-button uppercase"
            (click)="action(data.invitationObj.id)"
            [disabled]="loading"
          >
            Join {{ data.invitationObj.type }}
          </button>
        </div>
      </div>
      <div *ngIf="data.invitationObj.isActioned">
        <div class="subtitle">
          You are now a member of this
          <span class="lowercase">{{ data.invitationObj.type }}</span
          >.
        </div>
        <div class="button">
          <button
            mat-button
            class="celo-primary-button uppercase"
            (click)="viewConversation(data.invitationObj.metadata.id)"
          >
            View {{ data.invitationObj.type }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- User -->
  <div *ngIf="data.invitationObj.type === 'User'">
    <app-profile-invitation
      [userId]="data.invitationObj.metadata.id"
      [code]="data.invitationObj.id"
      [invitation]="data.invitationObj"
      (action)="action(data.invitationObj.id)"
    ></app-profile-invitation>
  </div>
</div>
