import { AuthService, UserService } from "@modules/core";
import { defer, Observable } from "rxjs";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import {
  ApplicationInsights,
  ICustomProperties,
} from "@microsoft/applicationinsights-web";
import { environment } from "environments/environment";
import { GlobalErrorHandler } from "../global-error-handler";

@Injectable({
  providedIn: "root",
})
export class ApplicationInsightsService {
  private angularPlugin = new AngularPlugin();
  private appInsights = new ApplicationInsights({
    config: {
      connectionString: environment.applicationInsightsConnectionString,
      extensions: [this.angularPlugin],
      extensionConfig: {
        [this.angularPlugin.identifier]: {
          router: this.router,
          errorServices: [new GlobalErrorHandler(this.injector)],
        },
      },
      correlationHeaderExcludedDomains: [
        "*.appsflyer.com",
        "*.onelink.me",
        "firebase.googleapis.com",
        "firebaseinstallations.googleapis.com",
        "*.zoom.us",
      ],
      // See: https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-angular-plugin#route-tracking
      enableAutoRouteTracking: false,
      // See: https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript?tabs=npm#enable-distributed-tracing
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: false,
      enableResponseHeaderTracking: false,
    },
  });

  private userService: UserService | null = null;

  public constructor(private router: Router, private injector: Injector) {}

  public initialize() {
    this.appInsights.loadAppInsights();
    this.userService = this.injector.get(UserService);
  }

  public trackEvent(name: string, properties?: ICustomProperties): void {
    this.appInsights.trackEvent({ name }, this.enrichProperties(properties));
  }

  public trackTrace(message: string, properties?: ICustomProperties): void {
    this.appInsights.trackTrace({ message }, this.enrichProperties(properties));
  }

  public trackException(error: Error, properties?: ICustomProperties) {
    this.appInsights.trackException(
      {
        exception: error,
      },
      this.enrichProperties(properties)
    );
  }

  public flush(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.appInsights.flush();
      subscriber.complete();
    });
  }

  private enrichProperties(properties?: ICustomProperties): ICustomProperties {
    return {
      appVersion: environment.appVersion,
      userId: this.userService?.getUserId() ?? null,
      ...properties,
    };
  }
}
