import { createActionGroup, props } from "@ngrx/store";
import { ErrorResponse, FullUserProfileModel } from "@types";

export const AccountApiActions = createActionGroup({
  source: "Account",
  events: {
    "Load User Success": props<{ user: FullUserProfileModel }>(),
    "Load User Error": props<ErrorResponse>(),
  },
});
