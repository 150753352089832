<div class="content">
  <img class="logo" src="../../../assets/logo-v2.svg" alt="" />
  <img
    class="pic appear_anim_1"
    src="../../../assets/country-unsupported.svg"
    alt=""
  />
  <div class="title appear_anim_2">
    Oops, Celo hasn’t landed in {{ countryName }} yet.
  </div>
  <div class="subtitle appear_anim_3 celo-text-18 celo-grey">
    Celo isn’t available in {{ countryName }} yet, but we’ll make sure you’re
    the first to know when we’ve landed!
  </div>
  <a [routerLink]="['/']"
    ><button mat-button class="celo-primary-button appear_anim_4">
      DONE
    </button></a
  >
</div>
