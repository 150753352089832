/**
 * Restricts `value` to be between `min` and `max`.
 *
 * If `value` is less then `min` then `min` is returned, else if
 * `value` is greater then `max` then `max` is returned, otherwise `value` is returned.
 */
export const clamp = (value: number, min: number, max: number): number => {
    if (min > max) throw new Error(`Invalid range. Min must the <= max. min = ${min}, max = ${max}`);
    if (value > max) return max;
    if (value < min) return min;
    return value;
}