<div *ngIf="!team || isLoading; else content" class="loading-container">
  <app-loading-logo></app-loading-logo>
</div>

<ng-template #content>
  <div class="content">
    <app-roles-icon
      id="roles-icon"
      backgroundSize="100px"
      size="90px"
      [style.margin-top]="marginTop"
    ></app-roles-icon>

    <div class="title truncate">{{ team.name }}</div>

    <div *ngIf="userWorkspace" class="workspace-container">
      <div class="name">{{ userWorkspace.workspace.name }}</div>
      <img
        *ngIf="userWorkspace.workspace.verificationStatus === 'Verified'"
        id="verification-icon"
        src="../../../../assets/icons/ic-verified-workspace.svg"
      />
    </div>

    <ng-container *ngIf="team.isActive; else inactive">
      <div *ngIf="!hidePrimaryAction" class="flex g-10px mb-30">
        <button
          *ngIf="isMember"
          mat-button
          class="celo-secondary-button uppercase"
          (click)="editRoleDetails()"
        >
          Edit Details
        </button>
        <button
          mat-button
          class="celo-secondary-button uppercase"
          (click)="handleClick()"
        >
          {{ isMember ? "View Role Inbox" : "Secure Message" }}
        </button>
      </div>

      <ng-container *ngIf="(isMember && hidePrimaryAction) || team.description">
        <div class="flex justify-between w-full items-center">
          <div class="heading">
            {{ !isMember || !hidePrimaryAction ? "Description" : "Details" }}
          </div>
          <button
            id="add-members-button"
            class="celo-link"
            *ngIf="isMember && hidePrimaryAction"
            mat-button
            (click)="editRoleDetails()"
          >
            Edit
          </button>
        </div>
        <mat-divider [class.mb-10]="!hidePrimaryAction"></mat-divider>
      </ng-container>

      <app-role-details-form
        *ngIf="(isMember && hidePrimaryAction) || team.description"
        [teamId]="team.id"
        [name]="team.name"
        [description]="(team.description | trim) || 'No Description'"
        [readonly]="true"
        [hideName]="!isMember || !hidePrimaryAction"
        [hideDescriptionHeader]="!isMember || !hidePrimaryAction"
      ></app-role-details-form>

      <mat-divider *ngIf="!hidePrimaryAction && team.description"></mat-divider>

      <div class="flex justify-between w-full items-center mt-24">
        <div class="heading">Members</div>
        <button
          id="add-members-button"
          class="celo-link"
          *ngIf="isCurrentUserAdmin"
          mat-button
          (click)="handleAddMembers()"
        >
          Add new members
        </button>
      </div>
      <mat-divider></mat-divider>

      <app-roles-members-list
        id="members-list"
        [style.max-height]="membersListMaxHeight"
        [members]="sortedTeamMembers"
        [team]="team"
      ></app-roles-members-list>

      <mat-divider class="mt-4" *ngIf="!hidePrimaryAction"></mat-divider>

      <button
        id="leave-button"
        class="celo-hoverable celo-cursor-pointer"
        *ngIf="isMember"
        mat-button
        (click)="handleLeaveClick()"
      >
        Leave this role
      </button>
    </ng-container>

    <ng-template #inactive>
      <div class="mat-body mt-20">This role has been deactivated.</div>
    </ng-template>
  </div>
</ng-template>
