<div *ngIf="isLoading" class="fh appear_anim_1">
  <app-empty-state
    [isLoading]="true"
    [message]="'Searching...'"
    [stroke]="1"
    [diameter]="40"
  ></app-empty-state>
</div>
<div *ngIf="!isLoading" class="list">
  <!-- No Result -->
  <div *ngIf="!conversations.length" class="no-results">
    <div class="content">
      <img
        class="appear_anim_1"
        src="../../../assets/search-conversation.svg"
        alt=""
      />
      <div class="appear_anim_2 celo-text-18 celo-bold-500">
        No results found
      </div>
    </div>
  </div>

  <!-- Chats -->
  <div *ngIf="chats.length" class="appear_anim_1">
    <div class="title appear_anim_1">Chats</div>
    <app-conversation-results-group
      (muteStatus)="muteStatusChange($event)"
      (conversation)="onConversationSelected($event)"
      [conversations]="chats"
      [userAccount]="userAccount"
      [inConversationList]="true"
      [hideUnreadCount]="true"
      [isSelectionList]="isSelectionList"
      [selectedConversationId]="selectedConversationId"
    ></app-conversation-results-group>
  </div>

  <!-- Groups -->
  <div *ngIf="groups.length" class="appear_anim_2">
    <div class="title appear_anim_1">Groups</div>
    <app-conversation-results-group
      (muteStatus)="muteStatusChange($event)"
      (conversation)="onConversationSelected($event)"
      [conversations]="groups"
      [userAccount]="userAccount"
      [inConversationList]="true"
      [hideUnreadCount]="true"
      [hideLastMessage]="true"
      [showParticipants]="true"
      [isSelectionList]="isSelectionList"
      [selectedConversationId]="selectedConversationId"
    ></app-conversation-results-group>
  </div>

  <!-- Cases -->
  <div *ngIf="cases.length" class="appear_anim_3">
    <div class="title appear_anim_1">Cases</div>
    <app-conversation-results-group
      (muteStatus)="muteStatusChange($event)"
      (conversation)="onConversationSelected($event)"
      [conversations]="cases"
      [userAccount]="userAccount"
      [inConversationList]="true"
      [hideUnreadCount]="true"
      [hideLastMessage]="true"
      [showParticipants]="true"
      [isSelectionList]="isSelectionList"
      [selectedConversationId]="selectedConversationId"
    ></app-conversation-results-group>
  </div>

  <!-- Archived -->
  <div *ngIf="archived.length" class="appear_anim_4">
    <div class="title appear_anim_1">Archived</div>
    <app-conversation-results-group
      (muteStatus)="muteStatusChange($event)"
      (conversation)="onConversationSelected($event)"
      [conversations]="archived"
      [userAccount]="userAccount"
      [inConversationList]="true"
      [hideUnreadCount]="true"
      [hideLastMessage]="conversation.type !== 'Chat'"
      [showParticipants]="conversation.type !== 'Chat'"
      [hideArchiveOption]="true"
      [isSelectionList]="isSelectionList"
      [selectedConversationId]="selectedConversationId"
    ></app-conversation-results-group>
  </div>

  <div style="padding-left: 1em; padding-bottom: 1em"></div>
  <div *ngIf="canLoadMore && !isLoadingMore" style="padding-top: 1em">
    <div style="padding-top: 1em">
      <button
        class="fw button-hover"
        mat-button
        (click)="loadMore.emit($event)"
      >
        Load More
      </button>
    </div>
  </div>
  <div *ngIf="isLoadingMore" class="spinner-box">
    <div class="spinner">
      <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
    </div>
  </div>
</div>
