import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  MediaService,
  SnackbarService,
  UserAccountService,
} from "@modules/core";
import { LibraryService } from "@modules/library/library.service";
import {
  CreatedByEnum,
  GetMediaOptions,
  PatientMediaModelPagedResult,
} from "@types";

@Component({
  selector: "app-media-list",
  templateUrl: "./media-list.component.html",
  styleUrls: ["./media-list.component.scss"],
})
export class MediaListComponent
  implements OnInit, OnChanges, AfterViewChecked, OnDestroy
{
  @Input() searchQuery: string;
  @Input() selectMode: boolean;
  @Input() clientPatientFilter: boolean;
  @Input() acceptedPatientIds: string[];
  @Input() acceptedPatientId: string;
  @Input() public onlyShowPatientFiles: boolean = false;
  @Input() public disableExportedFiles: boolean = false;
  @Input() public hideVideos: boolean = false;
  @Input() public maxSelectionSize: number | null = null;

  @Output() selsectionChange = new EventEmitter<any[]>();
  @Output() length = new EventEmitter<number>();

  pageSize = 36;
  mediaGroups = [];
  loading: boolean;
  hasMore = true;
  reloadSub: any;
  resetSub: any;
  userAccount: any;
  userId: string;

  public isMaxSelected: boolean = false;

  constructor(
    private libraryService: LibraryService,
    private changeDetectorRef: ChangeDetectorRef,
    private userAccountService: UserAccountService,
    private mediaService: MediaService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {
    const instance = this;
    this.userAccountService.getUserAccount(false, function (data) {
      instance.userAccount = data;
      instance.userId = instance.userAccount.userId;
    });
    if (this.selectMode) {
      this.startSelect();
    }

    this.resetSub = this.libraryService.canSelectResetEmitted$.subscribe(() => {
      this.resetSelected();
    });
    this.reloadSub = this.libraryService.reloadPhotoEmitted$.subscribe(() => {
      this.refreshCurrentPageWithoutChangingPageSize();
    });
  }

  ngOnChanges() {
    this.newSearch();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.cancelSelect();
    if (this.resetSub) {
      this.resetSub.unsubscribe();
    }
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
    }
  }

  startSelect() {
    this.libraryService.emitCanSelect(true);
  }

  cancelSelect() {
    this.libraryService.emitCanSelect(false);
  }

  onNewScrollPosition() {
    if (!this.hasMore) {
      return;
    }
    this.pageSize += 20;
    this.continueSearch();
  }

  newSearch() {
    this.pageSize = 20;
    this.mediaGroups = [];
    this.continueSearch();
    // this.length.emit(null)
  }

  refreshCurrentPageWithoutChangingPageSize() {
    this.mediaGroups = [];
    this.continueSearch();
  }

  continueSearch() {
    if (this.pageSize > 2000) {
      this.pageSize = 2000;
    }

    this.loading = true;

    const options: GetMediaOptions = {
      createdBy: CreatedByEnum.All,
      pageSize: this.pageSize,
      page: 0,
      patientIdIncludeGeneral: this.clientPatientFilter ?? false,
      includeAllPatientFiles: this.onlyShowPatientFiles,
    };

    if (this.searchQuery) {
      options.search = this.searchQuery;
    }
    if (this.acceptedPatientId) {
      options.patientId = this.acceptedPatientId;
    }

    this.mediaService.getMedia(options).subscribe({
      next: (page) => {
        this.processPhotos(page);
      },
      error: () => {
        this.loading = false;
        this.length.emit(0);
      },
    });
  }

  resetSelected() {
    for (const group of this.mediaGroups) {
      const mediaContainers = group.photos;
      for (let i = 0; i < mediaContainers.length; i++) {
        mediaContainers[i].selected = false;
      }
    }
    this.selsectionChange.emit([]);
  }

  processPhotos = (mediaFiles: PatientMediaModelPagedResult) => {
    this.loading = false;
    this.length.emit(mediaFiles.data.length);
    if (mediaFiles.data.length == 0) {
      return;
    }
    // this.length.emit(0);
    if (mediaFiles.total <= mediaFiles.data.length) {
      this.hasMore = false;
    } else {
      this.hasMore = true;
    }
    for (let i = 0; i < mediaFiles.data.length; i++) {
      const media = mediaFiles.data[i];

      const createdOn = new Date(media.createdOn);

      const month = createdOn.getMonth();
      const year = createdOn.getFullYear();
      const key = year + "_" + month;
      let group = this.mediaGroups.find((g) => g.key === key);
      const mediaContainer = {
        payload: media,
        prev: null,
        next: null,
        selected: false,
      };
      if (!group) {
        const mediaContainers = [];
        mediaContainers.push(mediaContainer);
        group = {
          key,
          date: media.createdOn,
          photos: [mediaContainer],
        };
        this.mediaGroups.push(group);
      } else {
        const mediaContainers = group.photos;
        const existing = mediaContainers.find(
          (pc) => pc.payload.id === media.id
        );
        if (!existing) {
          mediaContainers.push(mediaContainer);
        }
      }
    }
    for (const group of this.mediaGroups) {
      const mediaContainers = group.photos;
      for (let i = 0; i < mediaContainers.length; i++) {
        const a = mediaContainers[i];
        const b = mediaContainers[(i + 1) % mediaContainers.length];
        a.next = b;
        b.prev = a;
      }
    }
  };

  public onSelected(isSelected: boolean) {
    const selected = this.getSelected();
    const isMaxSelected =
      this.maxSelectionSize && selected.length >= this.maxSelectionSize;

    if (isMaxSelected && this.isMaxSelected) {
      this.snackbarService.show(
        `You can only send ${this.maxSelectionSize} files at a time`
      );
      return;
    }

    this.isMaxSelected = isMaxSelected;
    this.libraryService.emitShowAction(this.anySelected());
    this.selsectionChange.emit(selected);
  }

  private getSelected(): any[] {
    const selectedMedia: any[] = [];
    for (const group of this.mediaGroups) {
      for (const media of group.photos) {
        if (media.selected) {
          selectedMedia.push(media.payload);
        }
      }
    }
    return selectedMedia;
  }

  private anySelected(): boolean {
    for (const group of this.mediaGroups) {
      for (const photo of group.photos) {
        if (photo.selected) {
          return true;
        }
      }
    }
    return false;
  }
}
