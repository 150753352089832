import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AnalyticsService,
  SharedService,
  UserAccountService,
} from "@modules/core";
import { environment } from "environments/environment";

declare function signupEvent(userId): any;

@Component({
  selector: "app-password-set",
  templateUrl: "./password-set.component.html",
  styleUrls: [
    "./password-set.component.scss",
    "../onboard.scss",
    "../../../celo-input.scss",
  ],
})
export class PasswordSetComponent implements OnInit {
  password = "";
  showPassword = false;

  legalFirstName = "";
  title = "";
  firstName = "";
  lastName = "";
  verificationId: string;
  email: string;
  countryCode: string;
  token: string;
  loading: boolean;
  message: string;
  dataRegionId: string;

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private userAccountService: UserAccountService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((paramMap) => {
      this.verificationId = paramMap.get("verification_id");
      this.email = paramMap.get("email");
      this.token = paramMap.get("token");
      this.countryCode = paramMap.get("country_code");
      this.legalFirstName = paramMap.get("legalFirstName");
      this.title = paramMap.get("title");
      this.firstName = paramMap.get("firstName");
      this.lastName = paramMap.get("lastName");
      this.dataRegionId = paramMap.get("data_region_id");
    });

    this.analyticsService.raiseEvent("celo_page_view", {
      flow: "signing_up",
      page_name: "password_page",
    });
  }

  back() {
    this.sharedService.navigateBack();
  }

  submit() {
    // var encodedToken = btoa(this.token);

    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
    }

    const data = {
      email: this.email,
      verificationId: this.verificationId,
      token: this.token,
      countryCode: this.countryCode,
      legalFirstName: this.legalFirstName,
      title: this.title,
      firstName: this.firstName,
      lastName: this.lastName,
      password: this.password,
    };
    const path = environment.celoApiEndpoint + "/api/Onboard/RegisterUser";
    this.loading = true;

    //to remove
    // this.userAccountService.onAccountCreated(data,{});

    this.sharedService.postObjectById(path, {}, data).subscribe(
      (response) => {
        this.analyticsService.raiseEvent("register_user", {
          flow: "signing_up",
          success: "true",
        });
        this.loading = false;
        this.userAccountService.onAccountCreated(
          data,
          response ? response : {}
        );
        this.sharedService.navigateTo("/onboard/welcome", {
          email: this.email,
          data_region_id: this.dataRegionId,
        });
        this.loading = false;
        if (response) {
          signupEvent(response["userId"]);
        }
      },
      (err) => {
        this.analyticsService.raiseEvent("register_user", {
          flow: "signing_up",
          success: "false",
        });
        this.loading = false;
        const defaultMessage = this.sharedService.STANDARD_ERROR_MESSAGE;
        this.message = this.sharedService.getErrorMessageFromError(err);
        this.message = this.message
          ? this.message
          : this.sharedService.getOnboardingErrorMessage(err);
        this.message = this.message
          ? this.message
          : this.sharedService.getPasswordErrorMessage(err);
        this.message = this.message ? this.message : defaultMessage;
      }
    );
  }

  public tAndCEvent() {
    this.analyticsService.buttonClickEvent("t_and_c_link", {
      flow: "signing_up",
      page_name: "password_page",
    });
  }

  public toggleShowEvent() {
    this.analyticsService.buttonClickEvent("show_hide_input", {
      flow: "signing_up",
    });
  }
}
