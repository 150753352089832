<i *ngIf="icon" class="material-icons" (click)="input.focus()">{{ icon }}</i>

<input
  #input
  [(ngModel)]="value"
  (keydown.enter)="handleEnter()"
  [type]="type"
  [placeholder]="placeholder"
  [attr.maxlength]="maxLength ? maxLength : null"
  autocomplete="off"
  autofocus
  [disabled]="disabled"
/>
