import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { environment } from "@env";
import {
  ConversationsService,
  SnackbarService,
  UserService,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { ProfileService } from "@modules/profile/profile.service";
import { NetworkUserPickerMode } from "@modules/user-picker/network-user-picker.service";
import {
  UserPickerService,
  UserPickerSubmitCallback,
} from "@modules/user-picker/user-picker.service";
import {
  ConversationModelV2,
  ConversationParticipant,
  ParticipantRole,
} from "@types";
import { ConversationParticipantUtils, isNotNullOrUndefined } from "@utils";
import { mergeMap } from "rxjs/operators";
import {
  ConversationPartcipantPopupMenuActionType,
  ConversationParticipantPopupMenuAction,
} from "../conversation-participant-popup-menu/conversation-participant-popup-menu.component";
import {
  EditPatientDetailsData,
  EditPatientDetailsDialogComponent,
  EditPatientDetailsResult,
  EditPatientDetailsUpdateFn,
} from "../edit-patient-details-dialog/edit-patient-details-dialog.component";
import { PatientDetails } from "../patient-details-form/patient-details-form.component";

@Component({
  selector: "app-external-chat-details",
  templateUrl: "./external-chat-details.component.html",
  styleUrls: ["./external-chat-details.component.scss"],
})
export class ExternalChatDetailsComponent implements OnChanges {
  @Input() public externalUserId: string = "";
  @Input() public externalLastName: string = "";
  @Input() public externalFirstName: string = "";
  @Input() public externalDateOfBirth: string = "";
  @Input() public externalPhoneNumber: string = "";
  @Input() public externalUpdateFn: EditPatientDetailsUpdateFn | null = null;
  @Input() public participants: ConversationParticipant[] = [];
  @Input() public addParticipantsFn: UserPickerSubmitCallback | null = null;
  @Input() public conversationId: string;

  @Output() public externalDetails: EventEmitter<PatientDetails> =
    new EventEmitter();

  @Output() public addParticipantIds: EventEmitter<string[]> =
    new EventEmitter();

  @Output() public conversationChange: EventEmitter<ConversationModelV2> =
    new EventEmitter();

  @Output() public closeClick: EventEmitter<void> = new EventEmitter();

  public get externalFullName() {
    return `${this.externalFirstName} ${this.externalLastName}`;
  }

  public get currentUserId() {
    return this.userService.getUserId();
  }

  public isCurrentUserAdmin: boolean = false;
  public showLeaveButton: boolean = false;

  public constructor(
    private matDialogService: MatDialog,
    private userService: UserService,
    private conversationsService: ConversationsService,
    private profileService: ProfileService,
    private userPickerService: UserPickerService,
    private snackbarService: SnackbarService,
    private matDialog: MatDialog
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["participants"]) {
      const currentUserParticipant = this.participants.find(
        (p) => p.userId === this.currentUserId
      );
      this.isCurrentUserAdmin =
        currentUserParticipant?.role === ParticipantRole.Administrator ?? false;
      this.showLeaveButton =
        currentUserParticipant?.leftOnUtc === undefined ? false : true;
    }
  }

  public editExternalDetails() {
    if (this.externalUpdateFn === null && !environment.production)
      throw new Error("externalUpdateFn must not be null");

    this.openEditPatientDetailsDialog({
      patientDetails: {
        lastName: this.externalLastName,
        firstName: this.externalFirstName,
        dateOfBirth: this.externalDateOfBirth,
        phoneNumber: this.externalPhoneNumber,
      },
      updateFn: this.externalUpdateFn,
    })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result) return;
          this.externalDetails.emit(result.patientDetails);
        },
      });
  }

  public addParticipants() {
    if (this.addParticipantsFn === null && !environment.production)
      throw new Error("addParticipantsFn must not be null");

    const currentlySelectedUsersIds = this.participants
      .map((p) => p.userId)
      .filter(isNotNullOrUndefined);

    this.userPickerService.openNetworkUserPicker({
      header: "Add Participants",
      selectedHeader: "Participants",
      searchPlaceholder: "Search network",
      selectedQuantityLabels: {
        one: "participant",
        plural: "participants",
        zero: "participants",
      },
      initiallySelectedUserIds: currentlySelectedUsersIds,
      disabledUserIds: currentlySelectedUsersIds,
      excludeTeams: true,
      mode: NetworkUserPickerMode.MULTIPLE_SELECT,
      submitCallback: this.addParticipantsFn,
    });
  }

  public handleParticipantAction(
    action: ConversationParticipantPopupMenuAction
  ) {
    const { participant, type } = action;
    switch (type) {
      case ConversationPartcipantPopupMenuActionType.SecureMessage:
        this.conversationsService.createAndNavigateToChatConversation(
          participant?.userId
        );
        break;

      case ConversationPartcipantPopupMenuActionType.ViewProfile:
        this.profileService.openProfile(participant?.userId);
        break;

      case ConversationPartcipantPopupMenuActionType.MakeRoleAdmin:
        this.assignParticipantRole(
          participant?.userId,
          ParticipantRole.Administrator
        );
        break;

      case ConversationPartcipantPopupMenuActionType.RemoveFromRole:
        this.removeParticipant(participant?.userId, participant?.firstName);
        break;

      case ConversationPartcipantPopupMenuActionType.RemoveRoleAdmin:
        this.assignParticipantRole(
          participant?.userId,
          ParticipantRole.Contributor
        );
        break;

      default:
        throw new Error("Action type not supported");
    }
  }

  private assignParticipantRole(userId: string, role: ParticipantRole) {
    this.conversationsService
      .updateConversationRole(userId, this.conversationId, role)
      .subscribe({
        next: (conversation) => {
          this.conversationChange.emit(conversation);
        },
      });
  }

  private removeParticipant(userId: string, userFirstName) {
    AlertComponent.openDialog(this.matDialogService, {
      title: "Remove participant",
      message: `Are you sure you want to remove ${userFirstName}`,
      acceptOnly: false,
      acceptButtonText: "Remove",
      reverseButtonOrder: true,
    })
      .afterClosed()
      .pipe(
        mergeMap((isConfirm) => {
          if (!isConfirm) return;
          return this.conversationsService.removeParticipant(
            userId,
            this.conversationId
          );
        })
      )
      .subscribe((conversation) => {
        this.conversationChange.emit(conversation);
      });
  }

  public leaveExternalChat() {
    const isLastActiveAdmin = ConversationParticipantUtils.isLastActiveAdmin(
      this.participants,
      this.currentUserId
    );
    const isLastActiveParticipant =
      ConversationParticipantUtils.isLastActiveParticipant(
        this.participants,
        this.currentUserId
      );

    if (isLastActiveAdmin && !isLastActiveParticipant) {
      AlertComponent.openDialog(this.matDialogService, {
        title: "Assign a new admin",
        message: "Please assign a new admin before leaving this conversation.",
        acceptOnly: true,
        acceptButtonText: "OK",
      });
      return;
    }

    AlertComponent.openDialog(this.matDialogService, {
      title: "Leave conversation",
      message:
        "If you choose to leave, you will no longer be part of this conversation and will not receive further messages.",
      acceptOnly: false,
      acceptButtonText: "Leave",
      reverseButtonOrder: true,
    })
      .afterClosed()
      .pipe(
        mergeMap((isConfirm) => {
          if (!isConfirm) return;
          return this.conversationsService.leaveConversation(
            this.conversationId
          );
        })
      )
      .subscribe((conversation) => {
        this.conversationChange.emit(conversation);
      });
  }

  private openEditPatientDetailsDialog(
    data: EditPatientDetailsData
  ): MatDialogRef<EditPatientDetailsDialogComponent, EditPatientDetailsResult> {
    const config: MatDialogConfig<EditPatientDetailsData> = {
      role: "dialog",
      restoreFocus: true,
      panelClass: ["mat-panel-reset", "w-500px"],
      data,
    };

    return this.matDialogService.open<
      EditPatientDetailsDialogComponent,
      EditPatientDetailsData,
      EditPatientDetailsResult
    >(EditPatientDetailsDialogComponent, config);
  }

  onExportToPdf(): void {
    const ref = AlertComponent.openDialog(this.matDialog, {
      title: "Warning",
      message: "Sharing content outside of Celo is your responsibility",
      acceptButtonText: "Confirm Export",
      closeButtonText: "Cancel",
      acceptOnly: false,
    });

    ref.afterClosed().subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.conversationsService
        .exportToPdf(this.conversationId)
        .subscribe(({ operationId }) => {
          this.snackbarService.show(
            "Exporting PDF... Your download will start automatically",
            10
          );
          console.log("Operation ID:", operationId);
        });
    });
  }
}
