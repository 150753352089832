import { inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CanDeactivateFn } from "@angular/router";
import { AlertComponent } from "@modules/core/components";
import { CallComponent } from "./call/call.component";

/**
 * Requires the user to confirm they would like to leave the call screen. This is only shown if the call has not
 * already ended.
 */
export const callDeactivateGuard: CanDeactivateFn<CallComponent> = (
  component,
  currentRoute,
  currentState,
  nextState
) => {
  if (component.isCallEnded) return true;

  const matDialog = inject(MatDialog);
  return AlertComponent.openDialog(matDialog, {
    title: "Leave Call",
    message: "Are you sure you want to leave the call?",
    acceptOnly: false,
    reverseButtonOrder: true,
    acceptButtonText: "Leave",
    closeButtonText: "Cancel",
  }).afterClosed();
};
