import { Component, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  SelectionList,
  BasicUserPickerService,
  UserPickerState,
  UserSelectionListId,
} from "..";
import {
  NetworkUserPickerService,
  NetworkUserPickerState,
} from "./../network-user-picker.service";

@Component({
  selector: "app-network-user-picker-content",
  templateUrl: "./network-user-picker-content.component.html",
  styleUrls: ["./network-user-picker-content.component.scss"],
})
export class NetworkUserPickerContentComponent implements OnInit {
  public connections$: Observable<SelectionList[]> | null = null;
  public workspaces$: Observable<SelectionList[]> | null = null;
  public suggestions$: Observable<SelectionList[]> | null = null;

  public UserPickerState = UserPickerState;
  public state$: Observable<UserPickerState> = this.userPickerService.state$;

  public connectionsCount: number | null = null;
  public suggestionsCount: number | null = null;
  public workspaceGroupsCount: number | null = null;
  public collapsedWorkspaceCount: number = 2;
  public showAll: boolean = false;

  public multiple: boolean = false;

  public hideSuggestions$: Observable<boolean> = of(false);

  public get isEmptyState() {
    return (
      this.connectionsCount === 0 &&
      this.workspaceGroupsCount === 0 &&
      this.suggestionsCount === 0
    );
  }

  public constructor(
    private userPickerService: BasicUserPickerService,
    private networkUserPickerService: NetworkUserPickerService
  ) {}

  public ngOnInit(): void {
    this.networkUserPickerService.state$.subscribe({
      next: this.handleState.bind(this),
    });

    this.connections$ = this.userPickerService.userGroups$.pipe(
      map((groups) =>
        groups.filter((group) => group.id === UserSelectionListId.CONNECTIONS)
      ),
      tap({
        next: (groups) => (this.connectionsCount = groups.length),
      })
    );

    this.workspaces$ = this.userPickerService.userGroups$.pipe(
      map((groups) =>
        groups.filter(
          (group) =>
            group.id !== UserSelectionListId.COLLEAGUES &&
            group.id !== UserSelectionListId.CONNECTIONS &&
            group.id !== UserSelectionListId.SUGGESTIONS &&
            group.id !== UserSelectionListId.TEAMS
        )
      ),
      tap({
        next: (groups) => (this.workspaceGroupsCount = groups.length),
      })
    );

    this.suggestions$ = this.userPickerService.userGroups$.pipe(
      map((groups) =>
        groups.filter((group) => group.id === UserSelectionListId.SUGGESTIONS)
      ),
      tap({
        next: (groups) => (this.suggestionsCount = groups.length),
      })
    );

    this.hideSuggestions$ = this.networkUserPickerService.hideSuggestions$;
  }

  public handleShowAll() {
    this.showAll = !this.showAll;
  }

  private handleState(state: NetworkUserPickerState) {
    this.multiple = state !== NetworkUserPickerState.CREATE_CHAT;
  }
}
