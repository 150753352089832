<div class="content">
  <h1 mat-dialog-title>Mobile Number</h1>
  <div mat-dialog-content class="body">
    <div class="img appear_anim_1">
      <img src="../../../assets/phone-number.svg" alt="" />
    </div>
    <div class="text celo-grey appear_anim_2">
      We will send you a text message with a verification code that you’ll need
      to enter on the next screen.
    </div>
    <div
      class="fields celo-input-outline celo-input-outline-no-title celo-combained-mat-form-field appear_anim_3"
    >
      <mat-form-field class="code" appearance="outline">
        <mat-select
          #mySelect
          [(ngModel)]="selected"
          (click)="$event.preventDefault(); $event.stopPropagation()"
        >
          <mat-select-trigger>
            {{ selected["callingPrefix"] }}
          </mat-select-trigger>
          <mat-option *ngFor="let country of countries" [value]="country"
            >&nbsp;{{ country.callingPrefix }} &nbsp;{{
              country.name
            }}</mat-option
          >
        </mat-select>
        <div class="input">
          <input
            #myInput
            matInput
            [(ngModel)]="data.phoneNumber"
            type="tel"
            placeholder="Enter your mobile number"
            (click)="$event.preventDefault(); $event.stopPropagation()"
          />
        </div>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      [disabled]="!data.phoneNumber || submitting"
      class="celo-primary-button fw appear_anim_4"
      (click)="verifyNumber(data.phoneNumber)"
      cdkFocusInitial
    >
      SEND CODE
    </button>
  </div>
  <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="false">
      <i class="material-icons">cancel</i>
    </button>
  </div>
</div>
