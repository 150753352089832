<div *ngIf="connectionStatus$ | async as conStatus">
  <div
    mat-ripple
    role="button"
    class="connection-status-grid open-slow"
    [class.reconnecting]="conStatus === 'reconnecting'"
    [style.height.px]="conStatus !== 'connected' ? 75 : 0"
    (click)="reconnect()"
  >
    <div class="flag">
      <!-- Icon -->
      <div class="icon">
        <div *ngIf="conStatus === 'disconnected'" class="wifi-icon-circle">
          <mat-icon>wifi_off</mat-icon>
        </div>
        <div
          *ngIf="conStatus === 'connecting' || conStatus === 'reconnecting'"
          class="ml-2"
        >
          <mat-spinner [diameter]="20"></mat-spinner>
        </div>
      </div>

      <!-- Description -->
      <div class="content pl-3">
        <div class="connection-status" *ngIf="conStatus === 'disconnected'">
          <div>Computer not connected</div>
          <div class="text">Click here to reconnect</div>
        </div>

        <div
          class="connection-status"
          *ngIf="conStatus === 'connecting' || conStatus === 'reconnecting'"
        >
          <div class="text">Connecting...</div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="search-container">
  <div class="celo-page-title pt-2 pb-2 heading">
    <div class="truncate">{{ heading }}</div>
    <ng-content select="[end]"></ng-content>
  </div>
  <div class="my-input">
    <i class="material-icons mr-2">search</i>
    <input
      class="truncate"
      matInput
      [placeholder]="searchPlaceholder"
      autocomplete="off"
      maxlength="30"
      type="search"
      [(ngModel)]="searchTerm"
      (keyup.enter)="searchNow($event.target.value)"
      (ngModelChange)="searchShouldExit($event)"
      keypress
    />
    <i
      *ngIf="searchTerm"
      class="material-icons celo-cursor-pointer"
      matSuffix
      aria-label="Clear"
      (click)="searchTerm = ''; searchShouldExit()"
      >cancel</i
    >
  </div>
</div>
<div>
  <div (click)="newChat()" *ngIf="isCreateChatEnabled">
    <mat-divider></mat-divider>
    <app-create-new-conversation-button
      [buttonText]="'New Chat'"
    ></app-create-new-conversation-button>
    <mat-divider></mat-divider>
  </div>
</div>
