<div
  #input
  class="input"
  [attr.data-placeholder]="placeholder"
  [style.max-height]="maxHeight"
  contenteditable="plaintext-only"
  autocomplete="off"
  spellcheck="true"
  role="textbox"
  (input)="handleInput($event)"
  (paste)="handlePaste($event)"
  (keyup)="handleKeyup($event)"
  (keydown)="handleKeydown($event)"
  (click)="handleClick($event)"
></div>
