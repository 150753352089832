import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { UserSelectionList } from "../../shared/basic-user-selection-list/basic-user-selection-list.component";
import { BasicUserPickerService } from "../basic-user-picker.service";
import { UserSelectionListItem } from "./../../shared/basic-user-selection-list/basic-user-selection-list.component";

@Component({
  selector: "app-user-picker-selected-users",
  templateUrl: "./user-picker-selected-users.component.html",
  styleUrls: ["./user-picker-selected-users.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPickerSelectedUsersComponent {
  @Input() public notRemovableSelectedUsers: UserSelectionList | null = null;
  @Input() public selectedUsers: UserSelectionList | null = null;

  public constructor(private userPickerService: BasicUserPickerService) {}

  public handleUser(user: UserSelectionListItem) {
    this.userPickerService.updateUser(user);
  }
}
