<a
  class="item"
  [routerLink]="routerLink"
  (click)="itemClicked()"
  [queryParams]="
    activity.type === 'Mention' ? { message_id: activity.metadata.id } : {}
  "
>
  <div class="left">
    <app-avatar
      *ngIf="activity.type === 'Mention' || activity.type === 'TeamOnOffCall'"
      [name]="
        activity.metadata.user.firstName + ' ' + activity.metadata.user.lastName
      "
      [userId]="activity.metadata.user.userId"
      [width]="50"
      [height]="50"
      [hasImage]="!!activity.metadata.user.profilePic"
      [showBadge]="true"
      [identityVerificationStatus]="
        activity.metadata.user.identityVerified ? 'Verified' : 'Unverified'
      "
      [isWorkspaceVerified]="activity.metadata.user.workspaceVerified"
      [isProfessionVerified]="activity.metadata.user.professionVerified"
      [user]="activity.metadata.user"
    >
    </app-avatar>

    <div *ngIf="activity.type === 'WorkspaceJoined'">
      <app-avatar
        *ngIf="!!activity.metadata.profilePic"
        [name]="'Workspace'"
        [width]="50"
        [height]="50"
        [hasImage]="!!activity.metadata.profilePic"
        [picUrl]="activity.metadata.profilePic"
        [showBadge]="false"
      >
      </app-avatar>
      <div *ngIf="!activity.metadata.profilePic" class="icon">
        <img src="../../../../assets/icons/ic-workplace.svg" alt="" />
      </div>
    </div>
  </div>

  <div class="content celo-elipsis-2">
    <div *ngIf="activity.type === 'WorkspaceJoined'">
      <div class="title">{{ activity.metadata.content }}</div>
      <div class="subtitle">{{ activity.metadata.name }}</div>
    </div>

    <div [ngClass]="{ unread: !activity.metadata.read }">
      <div
        *ngIf="activity.type === 'Mention' || activity.type === 'TeamOnOffCall'"
      >
        <div class="title">
          <span>{{ activity.metadata.user.firstName }}</span
          >&nbsp;
          <span>{{ activity.metadata.user.lastName }}</span>
        </div>

        <div class="subsubtitle" *ngIf="activity.type === 'Mention'">
          Mentioned you
          <span
            *ngIf="
              activity.metadata.conversationType !== 'Chat' &&
              activity.metadata.conversationType !== 'SelfChat'
            "
            >in {{ activity.metadata.name }}</span
          >
        </div>

        <div class="subsubtitle" *ngIf="activity.type === 'TeamOnOffCall'">
          {{ activity.metadata.name }}
        </div>

        <div
          class="subtitle"
          [ngClass]="{
            deleted:
              activity.metadata.deletedOnUtc !== null &&
              activity.metadata.deletedOnUtc !== undefined
          }"
        >
          {{ activity.metadata.content }}
        </div>
      </div>
    </div>
  </div>

  <div class="right">
    <span class="time">{{
      activity.timeStampUtc | dateFormat: "shortTime"
    }}</span>
    <div *ngIf="activity.metadata.read === false" class="icon"></div>
  </div>
</a>
