<div
  *ngIf="isOnCall || isAdmin"
  class="statuses"
  [style.justifyContent]="justifyContent"
>
  <div class="on-call" *ngIf="isOnCall" [style.color]="onCallColor">
    On Call
  </div>
  <div class="admin" *ngIf="isAdmin">Admin</div>
</div>
