<app-expansion-panel-header
  (click)="handleClick(); isTouched = true"
  [expanded]="expanded"
  [sticky]="stickyHeader"
  [stickyTop]="stickyHeaderTop"
>
  <ng-content header select="[header]"></ng-content>
</app-expansion-panel-header>

<!-- Only initialize content the first time this expansion panel is opened -->
<div *ngIf="content && isTouched" [hidden]="isTouched && !expanded">
  <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
</div>
