import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { Team } from "types";
import { TeamsService, UserService } from "../../core";

@Component({
  selector: "app-roles-home",
  templateUrl: "./roles-home.component.html",
  styleUrls: ["./roles-home.component.scss"],
})
export class RolesHomeComponent implements OnInit {
  public teamId: string | null = null;
  public team$: Observable<Team | null> | null = null;

  public isLoading: boolean = true;
  public isCurrentUserMember: boolean = false;

  public constructor(
    private route: ActivatedRoute,
    private teamsService: TeamsService,
    private userService: UserService
  ) {}

  public ngOnInit(): void {
    this.route.params
      .pipe(map((p) => (p.teamId ?? null) as string | null))
      .subscribe({
        next: (teamId) => {
          this.teamId = teamId;
          this.loadTeam();
        },
      });
  }

  private loadTeam() {
    this.isLoading = true;

    if (!this.teamId) {
      this.team$ = null;
      this.isCurrentUserMember = false;
      return;
    }

    this.team$ = this.userService.userId$.pipe(
      switchMap((userId) => {
        if (!this.teamId || !userId) {
          this.isLoading = true;
          this.isCurrentUserMember = false;
          return of(null);
        }
        return this.teamsService.getTeam({ teamId: this.teamId }).pipe(
          tap({
            next: (team) => {
              this.isLoading = false;
              this.isCurrentUserMember = this.teamsService.isMember(
                userId,
                team
              );
            },
          })
        );
      })
    );
  }
}
