import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CeloMaterialModule } from "app/celo-material/celo-material.module";
import { SharedModule } from "../shared/shared.module";
import { ConversationsModule } from "./../conversations/conversations.module";
import { RolesModule } from "./../roles/roles.module";
import { AsTeamHeaderComponent } from "./as-team-header/as-team-header.component";
import { BasicUserPickerComponent } from "./basic-user-picker/basic-user-picker.component";
import { ConversationTypeButtonsComponent } from "./conversation-type-buttons/conversation-type-buttons.component";
import { DeselectIconComponent } from "./deselect-icon/deselect-icon.component";
import { NetworkUserPickerContentComponent } from "./network-user-picker-content/network-user-picker-content.component";
import { NetworkUserPickerComponent } from "./network-user-picker/network-user-picker.component";
import { UserPickerExpandableGroupComponent } from "./user-picker-expandable-group/user-picker-expandable-group.component";
import { UserPickerGroupedSelectionListsComponent } from "./user-picker-grouped-selection-lists/user-picker-grouped-selection-lists.component";
import { UserPickerLayoutComponent } from "./user-picker-layout/user-picker-layout.component";
import { UserPickerNoSearchResultsComponent } from "./user-picker-no-search-results/user-picker-no-search-results.component";
import { UserPickerNoSuggestionsComponent } from "./user-picker-no-suggestions/user-picker-no-suggestions.component";
import { UserPickerSearchbarComponent } from "./user-picker-searchbar/user-picker-searchbar.component";
import { UserPickerSelectedUsersComponent } from "./user-picker-selected-users/user-picker-selected-users.component";
import { UserPickerSelectionCountComponent } from "./user-picker-selection-count/user-picker-selection-count.component";
import { UserPickerSelectionListComponent } from "./user-picker-selection-list/user-picker-selection-list.component";
import { UserPickerSubmitButtonComponent } from "./user-picker-submit-button/user-picker-submit-button.component";
import { UserPickerService } from "./user-picker.service";
import { WorkspaceUserPickerContentComponent } from "./workspace-user-picker-content/workspace-user-picker-content.component";
import { WorkspaceUserPickerComponent } from "./workspace-user-picker/workspace-user-picker.component";

@NgModule({
  declarations: [
    BasicUserPickerComponent,
    DeselectIconComponent,
    UserPickerSelectedUsersComponent,
    UserPickerSelectionListComponent,
    UserPickerSelectionCountComponent,
    UserPickerLayoutComponent,
    UserPickerSearchbarComponent,
    UserPickerGroupedSelectionListsComponent,
    UserPickerNoSearchResultsComponent,
    UserPickerSubmitButtonComponent,
    UserPickerExpandableGroupComponent,
    NetworkUserPickerComponent,
    NetworkUserPickerContentComponent,
    WorkspaceUserPickerComponent,
    WorkspaceUserPickerContentComponent,
    ConversationTypeButtonsComponent,
    AsTeamHeaderComponent,
    UserPickerNoSuggestionsComponent,
  ],
  exports: [NetworkUserPickerComponent, WorkspaceUserPickerComponent],
  imports: [
    CommonModule,
    SharedModule,
    CeloMaterialModule,
    ReactiveFormsModule,
    ConversationsModule,
    RolesModule,
    RouterModule.forChild([]),
  ],
  providers: [UserPickerService],
})
export class UserPickerModule {}
