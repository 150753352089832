<div class="container">
  <button mat-icon-button aria-label="Close" id="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="mat-h2">Edit Details</h2>

  <app-patient-details-form
    [lastName]="data?.patientDetails?.lastName ?? ''"
    [firstName]="data?.patientDetails?.firstName ?? ''"
    [dateOfBirth]="data?.patientDetails?.dateOfBirth ?? ''"
    [phoneNumber]="data?.patientDetails?.phoneNumber ?? ''"
    [disabled]="isSubmitting"
    (patientDetails)="onSubmit($event)"
    (isValid)="isPatientDetailsValid = $event"
    #patientDetailsForm
  >
    <div class="flex justify-end g-10px">
      <button
        mat-button
        class="celo-secondary-button uppercase"
        type="button"
        mat-dialog-close=""
      >
        Cancel
      </button>

      <button
        mat-button
        class="celo-primary-button uppercase"
        type="submit"
        [disabled]="!isPatientDetailsValid || isSubmitting"
      >
        <ng-container *ngIf="!isSubmitting; else loading">Save</ng-container>
        <ng-template #loading>
          <mat-icon>
            <app-basic-spinner
              diameter="20"
              strokeWidth="2"
            ></app-basic-spinner>
          </mat-icon>
        </ng-template>
      </button>
    </div>
  </app-patient-details-form>
</div>
