<div class="card-container">
  <ng-content select="[start]"></ng-content>

  <div class="content-container" [style.margin-left]="leftContentMargin">
    <div class="content" [style.maxWidth]="contentWidth">
      <div *ngIf="name" class="name" [style.font-size]="nameFontSize">
        {{ name }}{{ suffix ? suffix : "" }}
      </div>
      <div
        *ngIf="description"
        class="description"
        [style.font-size]="descriptionFontSize"
        [style.max-width]="descriptionMaxWidth"
      >
        {{ description }}
      </div>
      <div
        *ngFor="let item of descriptionItems"
        class="description"
        [style.font-size]="descriptionFontSize"
        [style.max-width]="descriptionMaxWidth"
      >
        {{ item.content }}
      </div>
    </div>

    <ng-content select="[end]"></ng-content>
  </div>
</div>
