<div class="network-outlet">
  <div class="network-header-margin">
    <div *ngIf="userContact" class="appear_1">
      <app-profile
        [contact]="userContact"
        [isDirectory]="true"
        [template]="'type2'"
      >
      </app-profile>
    </div>
    <div *ngIf="!userContact" class="loading">
      <app-empty-state [isLoading]="true" [message]="' '"></app-empty-state>
    </div>
  </div>
</div>
