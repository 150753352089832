import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { Team, TeamMember } from "types";
import { TeamsService } from "./../../core/services/teams.service";

interface TeamWithMemberOnCall extends Team {
  memberOnCall: TeamMember | null;
  isCurrentUserOnCall: boolean;
}

@Component({
  selector: "app-roles-menu",
  templateUrl: "./roles-menu.component.html",
  styleUrls: ["./roles-menu.component.scss"],
})
export class RolesMenuComponent implements OnInit {
  @Output() public team: EventEmitter<Team> = new EventEmitter<Team>();

  @ViewChild(MatMenuTrigger) public menuTrigger: MatMenuTrigger | null = null;

  public teams$: Observable<TeamWithMemberOnCall[]> | null = null;

  public constructor(private teamsService: TeamsService) {}

  public ngOnInit(): void {
    this.teams$ = this.teamsService.userTeams$.pipe(
      map((teams) => {
        if (!teams) return [];
        const activeTeams = this.teamsService.filterOutInactiveTeams(teams);
        const sortedTeams = this.teamsService.sortTeams(activeTeams);
        return sortedTeams.map((team) => {
          const memberOnCall = this.teamsService.findFirstMemberOnCall(team);
          const isCurrentUserOnCall =
            this.teamsService.isCurrentUserOnCall(team);
          return { ...team, memberOnCall, isCurrentUserOnCall };
        });
      })
    );
  }

  public toggle() {
    this.menuTrigger?.toggleMenu();
  }

  public teamTrackBy(index: number, team: Team) {
    return team.id;
  }

  public handleTeamClicked(team: Team) {
    this.team.emit(team);
  }
}
