import { Directive, HostListener, ElementRef, OnDestroy } from "@angular/core";

@Directive({
  selector: "[appScrollToBottomDirective]",
})
export class ScrollToBottomDirectiveDirective implements OnDestroy {
  private maxScrollDelta = 100;
  private mutationObserver: MutationObserver;

  public constructor(private element: ElementRef<HTMLElement>) {
    if (!element.nativeElement) return;

    this.mutationObserver = new MutationObserver((mutations) =>
      this.handleMutation(mutations)
    );

    // this.mutationObserver.observe(element.nativeElement, {
    //   subtree: true,
    //   childList: true,
    // });
  }

  public ngOnDestroy(): void {
    this.mutationObserver.disconnect();
  }

  private handleMutation(mutations: MutationRecord[]) {
    const { scrollTop, scrollHeight, offsetHeight } =
      this.element.nativeElement;
    const scrollBottom = scrollHeight - offsetHeight;
    const isScrolledToBottom =
      Math.abs(scrollTop - scrollBottom) < this.maxScrollDelta;

    if (!isScrolledToBottom) return;

    this.element.nativeElement.scrollTop = scrollBottom;
  }
}
