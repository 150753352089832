import { Pipe, PipeTransform } from "@angular/core";
import linkifyStr from "linkify-string";
import { isNullOrUndefined } from "utils";

@Pipe({
  name: "linkify",
})
export class LinkifyPipe implements PipeTransform {
  public transform(
    value: unknown,
    ...args: unknown[]
  ): string | null | undefined {
    if (isNullOrUndefined(value)) return value;
    if (typeof value !== "string")
      throw new Error("linkify pipe input must be a string");

    // Note: 'linkify-string' escapes HTML, use 'linkify-html' if HTML in the input should be preserved
    return linkifyStr(value, {
      className: "celo-link",
      target: "_blank",
      rel: "noreferrer",
      defaultProtocol: "https",
    });
  }
}
