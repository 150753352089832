<div class="pic" [style.height.px]="width" [style.width.px]="width">
  <app-avatar
    *ngIf="profile.userId"
    [refreshCount]="refreshCount"
    [nocache]="true"
    [name]="profile.firstName + ' ' + profile.lastName"
    [userId]="profile.userId"
    [width]="width"
    [height]="width"
    [image_size]="300"
    [hasImage]="!!profile.picture"
    [doNotDisturbToUtc]="doNotDisturbToUtc"
    [dndBadgeSize]="'45'"
    [badgeMargin]="'4'"
    [badgeBorder]="'4'"
    [dndMessage]="'You are on do not disturb'"
  ></app-avatar>
  <div class="pic-changer" (click)="edit.emit($event)">
    <div class="overlay celo-text">Edit photo</div>
  </div>
</div>
