import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  AlertService,
  AnalyticsService,
  AuthService,
  SharedService,
  UserAccountService,
} from "@modules/core";
import { environment } from "environments/environment";

@Component({
  selector: "app-profile-share",
  templateUrl: "./profile-share.component.html",
  styleUrls: ["./profile-share.component.scss"],
})
export class ProfileShareComponent implements OnInit {
  userId = "";
  userAccount: any = {};
  loading = false;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private alertService: AlertService,
    private userAccountService: UserAccountService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.userId = this.authService.getUserId();
    this.userAccount = this.userAccountService.userAccount;
  }

  generate(callback) {
    // setTimeout(() => {
    //   callback('TODO: generated link')
    // }, 3000);

    const path = environment.celoApiEndpoint + "/api/v2/user/invitation";
    this.sharedService.postObjectById(path).subscribe(
      (res) => {
        if (res && res.uri) {
          return callback(res.uri);
        }

        return callback(null);
      },
      (err) => callback(null)
    );
  }

  share() {
    this.analyticsService.buttonClickEvent("share_profile");
    if (this.userAccount["invitation"] && this.userAccount["invitation"].uri) {
      this.copy(this.userAccount["invitation"].uri);
    } else {
      this.loading = true;
      const instance = this;
      this.generate(function (link) {
        if (!link) {
          instance.alertService.customDialog(
            "",
            this.sharedService.STANDARD_ERROR_MESSAGE,
            "Ok",
            "",
            true
          );
        }
        instance.loading = false;
        instance.copy(link);
      });
    }
  }

  copy(link) {
    const instance = this;
    this.sharedService.copy(
      "Let’s connect on Celo - a secure messenger for healthcare. Follow the link to view my profile \n" +
        link,
      function () {
        instance.alertService.showSnackBar("Link copied", 3);
      }
    );
  }
}
