import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Team } from "types";

@Component({
  selector: "app-roles-list",
  templateUrl: "./roles-list.component.html",
  styleUrls: ["./roles-list.component.scss"],
})
export class RolesListComponent {
  @Input() public teams: Team[] = [];
  @Input() public itemPadding: string | null = null;
  @Input() public avatarBackgroundSize: string = "40px";
  @Input() public avatarSize: string = "32px";

  @Output() public role: EventEmitter<Team> = new EventEmitter();

  constructor() {}

  public handleRoleClicked(role: Team) {
    this.role.emit(role);
  }
}
