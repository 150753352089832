import { AlertService } from "../../core";
import { NetworkUserPickerService } from "./../network-user-picker.service";
import { Observable, of } from "rxjs";
import { Component, OnInit, Optional, Input } from "@angular/core";

@Component({
  selector: "app-user-picker-no-suggestions",
  templateUrl: "./user-picker-no-suggestions.component.html",
  styleUrls: ["./user-picker-no-suggestions.component.scss"],
})
export class UserPickerNoSuggestionsComponent implements OnInit {
  @Input() public compact: boolean = false;

  public isDiscoverable$: Observable<boolean> = of(false);
  public isVerified$: Observable<boolean> = of(false);

  public constructor(
    @Optional()
    private networkUserPickerService: NetworkUserPickerService | null,
    private alertService: AlertService
  ) {}

  public ngOnInit(): void {
    if (this.networkUserPickerService === null) {
      throw new Error(
        "UserPickerNoSuggestionsComponent should only be used by a network user picker"
      );
    }

    this.isDiscoverable$ =
      this.networkUserPickerService?.isDiscoverable$ ?? of(false);
    this.isVerified$ = this.networkUserPickerService?.isVerified$ ?? of(false);
  }

  public verifyIdentity() {
    this.alertService.verifyIdentityDialog();
  }
}
