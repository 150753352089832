import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AlertComponent } from "@modules/core/components";
import { NetworkUserPickerMode } from "@modules/user-picker/network-user-picker.service";
import { UserPickerService } from "@modules/user-picker/user-picker.service";
import { Store } from "@ngrx/store";
import {
  conversationsFeature,
  ConversationsPageActions,
  selectRolesInboxConversations,
  selectUser,
  selectUserId,
} from "app/state";
import { config } from "configurations/config";
import { Observable, of } from "rxjs";
import { first, map, switchMap, tap } from "rxjs/operators";
import { Conversation, FullUserProfileModel, Team, TeamMember } from "types";
import { isNotNullOrUndefined } from "utils";
import { RoleDetailsDialogComponent } from "../role-details-dialog/role-details-dialog.component";
import { RolesClockOutComponent } from "../roles-clock-out/roles-clock-out.component";
import {
  ConversationsService,
  SnackbarService,
  TeamsService,
} from "./../../core";

@Component({
  selector: "app-roles-home-sidenav",
  templateUrl: "./roles-home-sidenav.component.html",
  styleUrls: ["./roles-home-sidenav.component.scss"],
})
export class RolesHomeSidenavComponent implements OnInit, OnChanges {
  @Input() public team: Team | null = null;
  @Input() public conversationsPageSize: number = 20;

  public userAccount$: Observable<FullUserProfileModel | null> | null = null;

  public conversations$: Observable<Conversation[]> = of([]);
  public isLoading$: Observable<boolean> = of(true);
  public isLoadingMore$: Observable<boolean> = of(false);
  public hasMore$: Observable<boolean> = of(false);

  public searchResults$: Observable<Conversation[]> = of([]);
  public isSearchLoading$: Observable<boolean> = of(false);
  public isSearchLoadingMore$: Observable<boolean> = of(false);
  public hasMoreSearchResults$: Observable<boolean> = of(false);
  public isSearching$: Observable<boolean> = of(false);

  public memberOnCall: TeamMember | null = null;
  public isCurrentUserOnCall: boolean = false;

  private previousTeamId: string | null = null;

  public constructor(
    private conversationsService: ConversationsService,
    private teamsService: TeamsService,
    private router: Router,
    private snackbarService: SnackbarService,
    private userPickerService: UserPickerService,
    private matDialog: MatDialog,
    private store: Store
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    const team: Team | null = changes?.team.currentValue ?? null;
    if (!team) return;

    if (!this.teamsService.isCurrentUserMember(team)) {
      this.router.navigate(["conversations"]);
      return;
    }

    this.updateMemberOnCall();

    if (team?.id && team?.id !== this.previousTeamId) {
      this.previousTeamId = team.id;
      this.loadInitialConversations();
    }
  }

  public ngOnInit(): void {
    this.conversations$ = this.store.select(selectUserId).pipe(
      first(isNotNullOrUndefined),
      switchMap((userId) =>
        this.store.select(selectRolesInboxConversations(userId))
      )
    );

    this.searchResults$ = this.store.select(
      conversationsFeature.selectRolesInboxSearchResults
    );

    this.userAccount$ = this.store.select(selectUser).pipe(
      tap((value) => {
        this.updateMemberOnCall();
      })
    );

    this.isLoading$ = this.store.select(
      conversationsFeature.selectIsRolesInboxLoading
    );

    this.isLoadingMore$ = this.store.select(
      conversationsFeature.selectIsRolesInboxLoadingMore
    );

    this.hasMore$ = this.store
      .select(conversationsFeature.selectRolesInboxPaginationData)
      .pipe(map((data) => data.hasNext));

    this.isSearchLoading$ = this.store.select(
      conversationsFeature.selectIsRolesInboxSearchLoading
    );

    this.isSearchLoadingMore$ = this.store.select(
      conversationsFeature.selectIsRolesInboxSearchLoadingMore
    );

    this.hasMoreSearchResults$ = this.store
      .select(conversationsFeature.selectRolesInboxSearchResultsPaginationData)
      .pipe(map((data) => data.hasNext));

    this.isSearching$ = this.store
      .select(conversationsFeature.selectRolesInboxSearchQuery)
      .pipe(map((query) => query && query.length >= config.minSearchLength));
  }

  private updateMemberOnCall() {
    if (!this.team) {
      this.memberOnCall = null;
      this.isCurrentUserOnCall = false;
      return;
    }
    this.memberOnCall = this.teamsService.findFirstMemberOnCall(this.team);
    this.isCurrentUserOnCall = this.teamsService.isCurrentUserOnCall(this.team);
  }

  private loadInitialConversations() {
    this.store.dispatch(
      ConversationsPageActions.rolesInboxOpened({
        teamId: this.team.id,
      })
    );
  }

  public handleSearch(query: string) {
    if (query.length < config.minSearchLength) {
      this.store.dispatch(ConversationsPageActions.rolesInboxSearchReset());

      // Only trigger if user has input anything
      if (query.length) {
        this.snackbarService.show(
          `Please enter at least ${config.minSearchLength} characters to begin your search.`
        );
      }

      return;
    }

    this.store.dispatch(
      ConversationsPageActions.rolesInboxSearch({
        query,
      })
    );
  }

  public handleRoleDetailsClicked() {
    if (!this.team || !this.team.workspace?.id) return;
    RoleDetailsDialogComponent.openDialog(this.matDialog, {
      team: this.team,
      workspaceId: this.team.workspace.id,
      hidePrimaryAction: true,
    })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result?.isLeft) return;
          this.router.navigate(["conversations"]);
        },
      });
  }

  public handleClockInOrOut() {
    if (!this.team?.id) return;

    const otherMembersCount = this.teamsService.filterOutLeftTeamMembers(
      this.team.members ?? [],
      true
    ).length;

    if (this.isCurrentUserOnCall) {
      if (otherMembersCount > 0) {
        // If there are any other members, ask if user wants to clock in someone to replace them
        RolesClockOutComponent.openDialog(this.matDialog, {
          team: this.team,
        });
      } else {
        // If there are no other members immediately clock this user out without any prompts
        this.teamsService.clockOut(this.team.id).subscribe();
      }
    } else if (this.memberOnCall) {
      // Confirm user wants to take over for the currently clocked in user
      const name = this.memberOnCall.user?.firstName;
      AlertComponent.openDialog(this.matDialog, {
        title: "Clock in for this role",
        message: `${
          name ?? "Someone"
        } is currently on call for this role. Would you like to take over from ${
          name ?? "them"
        }?`,
        acceptButtonText: "Clock in",
        acceptOnly: false,
        reverseButtonOrder: true,
      })
        .afterClosed()
        .subscribe({
          next: (isConfirmed) => {
            if (!this.team) return;
            if (!isConfirmed || !this.team.id) return;
            this.teamsService.clockIn(this.team.id).subscribe();
          },
        });
    } else {
      // No one is clocked in so clock this user in without any prompts
      this.teamsService.clockIn(this.team.id).subscribe();
    }
  }

  public handleLoadMoreConversations(isSearching: boolean) {
    if (isSearching) {
      this.store.dispatch(ConversationsPageActions.rolesInboxSearchLoadMore());
      return;
    }
    this.store.dispatch(ConversationsPageActions.rolesInboxLoadMore());
  }

  public handleCreateChat() {
    const workspaceId = this.team?.workspace?.id;
    if (!workspaceId) throw new Error("Team has no workspace id");

    this.userPickerService.openNetworkUserPicker({
      header: "New Chat",
      selectedHeader: "Participants",
      selectedQuantityLabels: {
        one: "participant",
        plural: "participants",
        zero: "participants",
      },
      searchPlaceholder: "Search",
      mode: NetworkUserPickerMode.CREATE,
      excludeTeams: true,
      excludeConnections: true,
      excludeSuggestions: true,
      workspaceId: workspaceId,
      excludeSelf: true,
      initiallyExpandedWorkspaceIds: [workspaceId],
      asTeamId: this.team?.id,
    });
  }
}
