import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-celo-pick-participants",
  templateUrl: "./celo-pick-participants.component.html",
  styleUrls: ["./celo-pick-participants.component.scss"],
})
export class CeloPickParticipantsComponent implements OnInit {
  @Input() title = "Participants";
  @Input() showBadge: boolean;
  @Input() participants: any[] = [];
  participantsToShow: any[] = [];
  @Input() selectedParticipantIds: string[] = [];
  @Input() removedParticipantIds: string[] = [];
  @Output() pick = new EventEmitter<any>();
  @Output() preview = new EventEmitter<string>();
  @Input() hideProfession = true;
  @Input() hideSelectionInfo = true;
  @Input() noResultsMessage: any = "- No results found -";

  ngOnInit() {
    this.participantsToShow = this.participants;
  }

  pickParticipant(participant: any) {
    const index = this.selectedParticipantIds.indexOf(participant.userId);
    if (index == -1) {
      this.selectedParticipantIds.push(participant.userId);
    } else {
      this.selectedParticipantIds.splice(index, 1);
      this.removedParticipantIds.push(participant.userId);
    }
    const packet = {
      toRemove: this.removedParticipantIds,
      selected: this.selectedParticipantIds,
    };
    this.pick.emit(packet);
  }

  filter(q: string) {
    if (!q) {
      this.participantsToShow = this.participants;
      return;
    }
    q = q.toLowerCase();
    this.participantsToShow = this.participants.filter(
      (p) =>
        (p.legalFirstName && p.legalFirstName.toLowerCase().indexOf(q) != -1) ||
        (p.firstName && p.firstName.toLowerCase().indexOf(q) != -1) ||
        (p.lastName && p.lastName.toLowerCase().indexOf(q) != -1) ||
        (p.firstName + " " + p.lastName).toLowerCase().indexOf(q) != -1
    );
  }
}
