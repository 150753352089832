<app-basic-user-card
  [name]="name"
  [description]="description"
  [descriptionItems]="descriptionItems"
  [suffix]="suffix"
  [contentWidth]="contentWidth"
  [descriptionMaxWidth]="descriptionMaxWidth"
  [leftContentMargin]="leftContentMargin"
  [padding]="padding"
>
  <div start class="start">
    <ng-content select="[start]"></ng-content>

    <ng-container *ngIf="isTeam; else userAvatar">
      <app-roles-icon></app-roles-icon>
    </ng-container>

    <ng-template #userAvatar>
      <app-basic-celo-avatar
        *ngIf="isAvatarEnabled"
        size="36"
        [name]="name"
        [userId]="userId"
        [isIdentityVerified]="isIdentityVerified"
        [isWorkspaceVerified]="isWorkspaceVerified"
        [isProfessionVerified]="isProfessionVerified"
        [isExternal]="isExternal"
        [fetchImage]="fetchImage"
      ></app-basic-celo-avatar>
    </ng-template>
  </div>

  <ng-content end select="[end]"></ng-content>
</app-basic-user-card>
