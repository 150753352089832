import { Component } from "@angular/core";

export interface InvitationInvalidDialogData {}

export interface InvitationInvalidDialogResult {}

@Component({
  selector: "app-invitation-invalid-dialog",
  templateUrl: "./invitation-invalid-dialog.component.html",
  styleUrls: ["./invitation-invalid-dialog.component.scss"],
})
export class InvitationInvalidDialogComponent {
  constructor() {}
}
