<h2 *ngIf="title" class="mat-headline-6" mat-dialog-title>{{ title }}</h2>

<mat-dialog-content *ngIf="message" class="mat-body">
  {{ message }}
</mat-dialog-content>

<mat-dialog-actions>
  <button
    *ngIf="acceptButtonText && !reverseButtonOrder"
    [mat-dialog-close]="true"
    mat-button
    class="celo-primary-button uppercase"
  >
    {{ acceptButtonText }}
  </button>

  <button
    *ngIf="!acceptOnly"
    [mat-dialog-close]="false"
    mat-button
    class="celo-secondary-button uppercase"
  >
    {{ closeButtonText }}
  </button>

  <button
    *ngIf="acceptButtonText && reverseButtonOrder"
    [mat-dialog-close]="true"
    mat-button
    class="celo-primary-button uppercase"
  >
    {{ acceptButtonText }}
  </button>
</mat-dialog-actions>
