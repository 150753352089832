<div *ngIf="consent" class="consent-box">
  <div class="content">
    <div class="icon">
      <img src="/assets/consent-icon.svg" (click)="viewConsent()" />
    </div>
    <div class="concent-info font-14">
      <div *ngIf="consent.patientData.uid" class="font-16">
        {{ consent.patientData.uid }}
      </div>
      <div class="concent-info font-14">
        <div *ngIf="consent.patientData.uid" class="font-16">
          {{ consent.patientData.uid }}
        </div>
        <div *ngIf="consent.creator" class="font-grey">
          {{
            (consent.creator.title ? consent.creator.title + " " : "") +
              consent.creator.firstName +
              " " +
              consent.creator.lastName
          }}
        </div>
        <div class="font-grey font-12">
          {{ consent.createdOn | dateFormat: "medium" }}
        </div>
      </div>
      <div class="font-grey font-12">
        {{ consent.createdOn | date: "medium" }}
      </div>
    </div>
    <div class="concent-button">
      <a [href]="consentLink | secure" download>
        <button mat-icon-button>
          <mat-icon>file_download</mat-icon>
        </button>
      </a>
    </div>
  </div>
</div>
