import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { SYSTEM_USER_ID } from "@common";
import { MessageModel } from "@types";
import { Subscription } from "rxjs";
import { ConversationService, SharedService } from "../../modules/core";

export interface SearchSelect {
  message: MessageModel;
  searchQuery: string;
}

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss", "../../../celo-input.scss"],
})
export class SearchComponent implements OnInit, OnChanges, OnDestroy {
  // @Input()
  messageSink: any[];
  @Input()
  conversationId: string;

  @Input() pMap: { [key: string]: any };
  participantAccount: any;
  @Input() userAccount: any;
  @Input() type: string;

  queryOriginal: string;
  query: string;
  results: any[];

  minResultsCount = 10;
  singleFetchCount = 300;
  maxIterationsWithResults = 4;
  maxIterationsWithNoResults = 5;

  @Output()
  selected = new EventEmitter<SearchSelect>();

  @ViewChild("input") serchfield: ElementRef;
  searching: boolean;
  searched: boolean;
  endReached: boolean;

  color = "rgba(0,0,0,0.03)";
  no_internet: boolean;
  quit: boolean;
  oldestMessage: any;
  canShow = false;

  subscription: Subscription;

  constructor(
    private conversationService: ConversationService,
    private sharedService: SharedService
  ) {
    this.results = [];
    this.messageSink = [];

    this.resetSearched();
  }

  resetSearched() {
    this.searched = true;
    this.oldestMessage = null;
    setTimeout(() => {
      this.searched = false;
      this.focus();
    }, 50);
  }

  ngOnInit() {
    setTimeout(() => {
      this.canShow = true; // this is required to stop the jumping of attach buttons when search is opened
    }, 100);
  }

  ngOnChanges() {
    this.type =
      this.type === "Chat" || this.type === "TeamChat"
        ? "Conversation"
        : this.type;
    this.focus();
  }

  ngOnDestroy() {
    this.quit = true;
  }

  focus() {
    if (this.serchfield) {
      this.serchfield.nativeElement.focus();
    }
  }

  searchNow() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.results = [];
    this.messageSink = [];
    this.resetSearched();
    this.loadMore();
  }

  loadMore() {
    this.no_internet = false;
    this.searching = true;
    this.endReached = false;
    if (this.sharedService.isOnline()) {
      this.doSearchCycles(this.minResultsCount, 1, 1);
    } else {
      this.no_internet = true;
    }
  }

  doSearchCycles(requiredResults?, iterations?, iterationsWithResults?) {
    this.searching = true;
    requiredResults = requiredResults ? requiredResults : this.minResultsCount;
    iterationsWithResults = iterationsWithResults ? iterationsWithResults : 1;
    iterations = iterations ? iterations : 1;
    const i = this;
    this.search(function (resultLength) {
      i.searching = false;
      if (resultLength == -1) {
        i.endReached = true;
      } else {
        iterations++;
        requiredResults = requiredResults - resultLength;
        if (requiredResults != i.minResultsCount) {
          // keep on fetching until atlease 1 result if found
          iterationsWithResults++;
        }
        if (
          requiredResults > 0 &&
          iterationsWithResults < i.maxIterationsWithResults &&
          !i.endReached &&
          iterations <= i.maxIterationsWithNoResults
        ) {
          i.doSearchCycles(requiredResults, iterations, iterationsWithResults);
        } else {
          i.searched = true;
          i.searching = false;
        }
      }
      i.searched = true;
      return;
    });
  }

  search(callback) {
    if (this.quit) {
      return;
    }
    if (!this.queryOriginal) {
      return;
    } else {
      this.query = this.queryOriginal.toLowerCase();
    }
    const i = this;
    this.fetchMessages(function (messages) {
      if (messages.length == 0) {
        return callback(-1); // return -1 if fetched messages is 0 in length ie finished searching the conversation
      }
      i.oldestMessage = messages[messages.length - 1];
      const filtered = i.filter(messages);
      i.results = i.results.concat(filtered);
      callback(filtered.length); // return the length of results found for this search
      if (messages.length < i.singleFetchCount) {
        i.endReached = true; // return -1 if fetched messages is below minResultsCount ie finished searching the conversation
        i.searched = true;
        i.searching = false;
      }
    });
  }

  filter(messages: MessageModel[]) {
    const filtered = messages.filter((message) => {
      if (
        message.sentBy !== SYSTEM_USER_ID &&
        !message.deletedOnUtc &&
        message.content &&
        message.content.toLowerCase().indexOf(this.query) != -1
      ) {
        return message;
      }
    });
    return filtered;
  }

  change() {
    if (this.results && this.results.length > 0) {
      this.resetSearched();
    } else {
      this.searched = false;
    }

    this.results = [];
    this.endReached = false;
  }

  select(result: MessageModel) {
    this.selected.emit({
      message: result,
      searchQuery: this.query,
    });
  }

  fetchMessages(callback) {
    let lastMessage: any;
    lastMessage = {};
    if (this.messageSink.length > 0) {
      lastMessage = this.messageSink[this.messageSink.length - 1];
    }
    let before;
    if (lastMessage && lastMessage.sentOnUtc) {
      const beforeDate = new Date(lastMessage.sentOnUtc);
      beforeDate.setMilliseconds(beforeDate.getMilliseconds() - 10);
      before = beforeDate.toISOString();
    }

    this.subscription = this.conversationService
      .getMessages(
        this.conversationId,
        this.singleFetchCount,
        before,
        undefined
      )
      .subscribe(
        (res) => {
          this.messageSink = this.messageSink.concat(res);
          callback(res);
        },
        (err) => {
          this.searching = false;
        }
      );
  }
}
