<div
  *ngIf="{
    isOnline: isOnline$ | async,
    text: text$ | async
  } as state"
>
  <span *ngIf="state.isOnline" class="online">
    <i class="material-icons online_bubble">fiber_manual_record</i>
    <span class="online_text" [textContent]="state.text"></span>
  </span>

  <span *ngIf="!state.isOnline" [textContent]="state.text"></span>
</div>
