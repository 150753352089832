import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { updateElement } from "../../../utils/array-utils";
import {
  UserSelectionList,
  UserSelectionListItem,
} from "../shared/basic-user-selection-list/basic-user-selection-list.component";

export interface BasicUserPickerData {
  provider: UserPickerProvider;
  /** Specify a header to override the header that is shown based on the user picker's current state. */
  header?: string;
  subheader?: string | null;
  searchPlaceholder?: string | null;
  submitButtonText?: string | null;
  cancelButtonText?: string | null;
  selectedHeader: string;
  selectedQuantityLabels: {
    zero: string;
    one: string;
    plural: string;
  };
  variant?: "network-user-picker";
}

export interface BasicUserPickerResult {
  isSubmitted?: boolean;
}

export enum SelectionListType {
  SINGLE_GROUP = "SINGLE_GROUP",
  SUBGROUPED = "SUBGROUPED",
}

export type LoadableSelectionList<TType, TData> =
  | LoadedSelectionList<TType, TData>
  | UnloadedSelectionList<TType, TData>;

export interface LoadableSelectionListBase<TType, TData> {
  id: string;
  type: TType;
  name?: string;

  /** Used instead of `name` if specified to label an expandable group's control */
  expandableControlName?: string;

  /** Used instead of `name` if specified to label a group's select all control */
  selectAllControlName?: string;
  description?: string | null;
  isExpandable?: boolean;
  isLoaded: boolean;
  load?: () => void;
  data: TData | null;
  isTeams?: boolean;
}

export interface LoadedSelectionList<TType, TData>
  extends LoadableSelectionListBase<TType, TData> {
  isLoaded: true;
  data: TData;
}

export interface UnloadedSelectionList<TType, TData>
  extends LoadableSelectionListBase<TType, TData> {
  isLoaded: false;
  data: null;
  load: () => void;
}

export type SingleLoadableSelectionList = LoadableSelectionList<
  SelectionListType.SINGLE_GROUP,
  UserSelectionList
>;
export type SubgroupedLoadableSelectionList = LoadableSelectionList<
  SelectionListType.SUBGROUPED,
  LoadedSelectionList<SelectionListType.SINGLE_GROUP, UserSelectionList>[]
>;

export type SelectionList =
  | SingleLoadableSelectionList
  | SubgroupedLoadableSelectionList;

export enum UserPickerState {
  DEFAULT,
  SUBMIT_LOADING,
  SEARCH_LOADING,
  SEARCH_RESULTS,
  NO_RESULTS,
}

export interface UserPickerProvider {
  userGroup$: Observable<SelectionList>;
  getUserGroups(
    selectedUsers: UserSelectionList,
    isInit: boolean
  ): Observable<SelectionList[]>;
  searchUsers(
    query: string,
    selectedUsers: UserSelectionList
  ): Observable<SelectionList[]>;
  submitSelectedUsers(selectedUsers: UserSelectionList): Observable<void>;
  setData(data: unknown): void;
}

export enum UserSelectionListId {
  CONNECTIONS = "CONNECTIONS",
  COLLEAGUES = "COLLEAGUES",
  TEAMS = "TEAMS",
  SUGGESTIONS = "SUGGESTIONS",
  PEOPLE = "PEOPLE",
  PEOPLE_IDENTITY_VERIFICATION_AND_DISCOVERABILITY = "PEOPLE_IDENTITY_VERIFICATION_AND_DISCOVERABILITY",
  WORKSPACE_MEMBERS = "WORKSPACE_MEMBERS",
}

@Injectable()
export class BasicUserPickerService {
  private provider: UserPickerProvider | null = null;

  private usersGroupsSubject = new BehaviorSubject<SelectionList[]>([]);
  public userGroups$ = this.usersGroupsSubject.asObservable();

  private userSubject = new BehaviorSubject<UserSelectionListItem | null>(null);
  public user$ = this.userSubject.asObservable();

  private selectedUsersSubject = new BehaviorSubject<UserSelectionList>([]);
  public selectedUsers$ = this.selectedUsersSubject.asObservable();

  private submitSubject = new Subject<{
    error?: Error;
    isSubmitted?: boolean;
  }>();

  public submit$ = this.submitSubject.asObservable();

  private searchResultsSubject = new BehaviorSubject<SelectionList[]>([]);
  public searchResults$ = this.searchResultsSubject.asObservable();

  private resetSubject = new Subject<void>();
  public reset$ = this.searchResultsSubject.asObservable();

  private stateSubject = new BehaviorSubject<UserPickerState>(
    UserPickerState.SEARCH_LOADING
  );

  public state$ = this.stateSubject.asObservable();

  private hideTeamsSubject = new BehaviorSubject<boolean>(false);
  public hideTeams$ = this.hideTeamsSubject.asObservable();

  private headerSubject = new BehaviorSubject<string>("");
  public header$ = this.headerSubject.asObservable();

  private searchSubscription: Subscription | null = null;

  private unfilteredSearchResults: SelectionList[] = [];
  private searchResultsWithoutTeams: SelectionList[] = [];

  public setHeader(header: string) {
    this.headerSubject.next(header);
  }

  public initialiseFromDialogData({ header, provider }: BasicUserPickerData) {
    this.provider = provider;

    this.provider.userGroup$.subscribe({
      next: (group) => this.updateUserGroup(group),
    });

    if (header) {
      this.setHeader(header);
    }

    this.loadInitialData();
  }

  private loadInitialData() {
    let groups: SelectionList[] = [];
    this.provider
      ?.getUserGroups(this.selectedUsersSubject.value, true)
      .subscribe({
        next: (result) => (groups = groups.concat(result)),
        complete: () => {
          this.initialiseUsers(groups);
          this.stateSubject.next(UserPickerState.DEFAULT);
        },
      });
  }

  private initialiseUsers(groups: SelectionList[]) {
    const users = groups.flatMap((group) => {
      if (group.type === SelectionListType.SINGLE_GROUP) {
        return group.data ?? [];
      } else if (group.type === SelectionListType.SUBGROUPED) {
        return group.data?.flatMap((subgroup) => subgroup?.data ?? []) ?? [];
      }
      return [];
    });

    this.selectedUsersSubject.next(this.getUpdatedSelectedUsers(users));
    this.usersGroupsSubject.next(groups);
  }

  private updateUserGroup(group: SelectionList) {
    const newGroup: SelectionList = { ...group };
    newGroup.data = this.updateUserGroupSelectionStatuses(newGroup);

    const newGroups: SelectionList[] = updateElement(
      this.usersGroupsSubject.value,
      (g) => g.id === newGroup.id,
      () => newGroup
    );

    this.usersGroupsSubject.next(newGroups);
  }

  private updateUserGroupSelectionStatuses(group: SelectionList) {
    if (group.isLoaded) {
      switch (group.type) {
        case SelectionListType.SINGLE_GROUP:
          return this.updateUserSelectionStatuses(
            group.data,
            this.selectedUsersSubject.value
          );
        case SelectionListType.SUBGROUPED:
          return group.data.map((subgroup) => ({
            ...subgroup,
            data: this.updateUserSelectionStatuses(
              subgroup.data,
              this.selectedUsersSubject.value
            ),
          }));
        default:
          const _exhaustiveCheck: never = group;
          return _exhaustiveCheck;
      }
    }
    return group.data;
  }

  public reset() {
    const newGroups: SelectionList[] = this.resetSelectionLists(
      this.usersGroupsSubject.value
    );

    const newSearchResults: SelectionList[] = this.resetSelectionLists(
      this.searchResultsSubject.value
    );

    this.resetSubject.next();
    this.usersGroupsSubject.next(newGroups);
    this.selectedUsersSubject.next([]);
    this.searchResultsSubject.next(newSearchResults);
  }

  private resetSelectionLists(selectionLists: SelectionList[]) {
    return selectionLists.map((s) => {
      if (!s.isLoaded) {
        return { ...s };
      }
      if (s.type === SelectionListType.SINGLE_GROUP) {
        return { ...s, data: this.resetUserSelectionList(s.data) };
      } else {
        return {
          ...s,
          data: s.data.map((subgroup) => ({
            ...subgroup,
            data: this.resetUserSelectionList(subgroup.data),
          })),
        };
      }
    });
  }

  private resetUserSelectionList(
    userSelectionList: UserSelectionList
  ): UserSelectionList {
    return userSelectionList.map((s) => {
      if (s.isDisabled) {
        return { ...s };
      } else {
        return { ...s, isSelected: false };
      }
    });
  }

  /**
   * Notifies this service that the selection state of the users in the given selection list may have changed and
   * that any observers should be notified.
   */
  public updateUsers(users: UserSelectionList) {
    const newSelectedUsers = this.getUpdatedSelectedUsers(
      users,
      this.selectedUsersSubject.value
    );
    const newUsers = this.getUpdatedUserGroups(
      users,
      this.usersGroupsSubject.value
    );
    const newSearchResults = this.getUpdatedUserGroups(
      users,
      this.searchResultsSubject.value
    );

    this.selectedUsersSubject.next(newSelectedUsers);
    this.usersGroupsSubject.next(newUsers);
    this.searchResultsSubject.next(newSearchResults);
  }

  /**
   * Notifies this service that the selection state of the given user may have changed and that any observers should be
   * notified.
   */
  public updateUser(user: UserSelectionListItem) {
    this.updateUsers([user]);
  }

  public onUserSelected(user: UserSelectionListItem) {
    this.userSubject.next(user);
  }

  public searchUsers(query: string) {
    this.searchSubscription?.unsubscribe();
    this.stateSubject.next(UserPickerState.SEARCH_LOADING);
    this.searchSubscription =
      this.provider
        ?.searchUsers(query, this.selectedUsersSubject.value)
        .subscribe({
          next: (results) => {
            this.unfilteredSearchResults = results;
            this.searchResultsWithoutTeams = results.filter(
              (s) => s.id !== UserSelectionListId.TEAMS
            );
            this.updateSearchResultsState();
          },
        }) ?? null;
  }

  private updateSearchResultsState() {
    const filteredResults = this.hideTeamsSubject.value
      ? this.searchResultsWithoutTeams
      : this.unfilteredSearchResults;

    this.searchResultsSubject.next(filteredResults);

    if (filteredResults.length) {
      this.stateSubject.next(UserPickerState.SEARCH_RESULTS);
    } else {
      this.stateSubject.next(UserPickerState.NO_RESULTS);
    }
  }

  public clearSearch() {
    this.searchSubscription?.unsubscribe();
    this.searchResultsSubject.next([]);
    this.stateSubject.next(UserPickerState.DEFAULT);
  }

  public submitSelectedUsers(minimumSelectionSize: number = 1) {
    if (!this.provider?.submitSelectedUsers) {
      this.submitSubject.next({ error: new Error("Submit method undefined") });
    } else if (this.selectedUsersSubject.value.length < minimumSelectionSize) {
      this.submitSubject.next({
        error: new Error(
          "Number of selected users is less than minimum required"
        ),
      });
    } else {
      this.stateSubject.next(UserPickerState.SUBMIT_LOADING);
      this.provider
        .submitSelectedUsers(this.selectedUsersSubject.value)
        .subscribe({
          error: (error) => {
            this.stateSubject.next(UserPickerState.DEFAULT);
            this.submitSubject.next({ error });
          },
          complete: () => this.submitSubject.next({ isSubmitted: true }),
        });
    }
  }

  public getSelectedUsers() {
    return this.selectedUsersSubject.value;
  }

  private getUpdatedUserGroups(
    users: UserSelectionList,
    groups: SelectionList[]
  ): SelectionList[] {
    const newGroups: SelectionList[] = groups.map((group) => {
      if (!group.isLoaded) {
        return { ...group };
      }

      switch (group.type) {
        case SelectionListType.SINGLE_GROUP:
          const newSingleList: SingleLoadableSelectionList = {
            ...group,
            data: this.getUpdatedUsers(users, group.data),
          };
          return newSingleList;
        case SelectionListType.SUBGROUPED:
          const newSubgroupedList: SubgroupedLoadableSelectionList = {
            ...group,
            data: group.data.map((subgroup) => ({
              ...subgroup,
              data: this.getUpdatedUsers(users, subgroup.data),
            })),
          };
          return newSubgroupedList;
        default:
          // See: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking
          const _exhaustiveCheck: never = group;
          return _exhaustiveCheck;
      }
    });
    return newGroups;
  }

  /**
   * Returns a copy of `currentUsers` where items in `currentUsers` are replaced by items in `users` if they have the
   * same id.
   */
  private getUpdatedUsers(
    users: UserSelectionList,
    currentUsers: UserSelectionList
  ): UserSelectionList {
    const map = new Map(users.map((user) => [user.id, user]));
    const copy = [...currentUsers];

    for (let i = 0; i < copy.length; i++) {
      const user = copy[i];
      const updatedUser = map.get(user.id);
      if (updatedUser) {
        copy[i] = updatedUser;
      }
    }

    return copy;
  }

  /**
   * Returns an updated list of selected users while maintaining the order of any users that are currently selected.
   */
  private getUpdatedSelectedUsers(
    users: UserSelectionList,
    currentlySelectedUsers: UserSelectionList = []
  ): UserSelectionList {
    const selectedUserIds = new Set<string>(
      currentlySelectedUsers.map((u) => u.id)
    );
    const newlySelectedUsers: UserSelectionList = [];
    const deselectedUserIds: Set<string> = new Set();

    for (const user of users) {
      if (user.isSelected && !selectedUserIds.has(user.id)) {
        newlySelectedUsers.push(user);
      } else if (!user.isSelected) {
        deselectedUserIds.add(user.id);
      }
    }

    const selectedUsers = currentlySelectedUsers
      .filter((u) => !deselectedUserIds.has(u.id))
      .concat(newlySelectedUsers);

    return selectedUsers;
  }

  /**
   * Returns a copy of `users` where any users that are selected in `currentlySelectedUsers` have their selection status
   * set to true.
   */
  private updateUserSelectionStatuses(
    users: UserSelectionList,
    currentlySelectedUsers: UserSelectionList = []
  ): UserSelectionList {
    const selectedUserIds = new Set<string>(
      currentlySelectedUsers.map((u) => u.id)
    );

    const updatedUsers = users.map((u) => {
      if (selectedUserIds.has(u.id)) {
        return { ...u, isSelected: true };
      }
      return { ...u };
    });

    return updatedUsers;
  }

  /** Set provider specific data */
  public setData(data: unknown) {
    this.provider?.setData(data);
  }

  private isSearching() {
    const state = this.stateSubject.value;
    return [
      UserPickerState.SEARCH_LOADING,
      UserPickerState.SEARCH_RESULTS,
      UserPickerState.NO_RESULTS,
    ].includes(state);
  }

  public setTeamsVisibility(isVisible: boolean) {
    this.hideTeamsSubject.next(!isVisible);

    if (this.isSearching()) {
      this.updateSearchResultsState();
    }
  }
}
