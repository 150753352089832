import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "app-basic-celo-checkbox",
  templateUrl: "./basic-celo-checkbox.component.html",
  styleUrls: ["./basic-celo-checkbox.component.scss"],
})
export class BasicCeloCheckboxComponent {
  @Input() public checked: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public radio: boolean = false;

  @HostBinding("class.disabled")
  private get klass() {
    return this.disabled;
  }
}
