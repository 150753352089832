import { Subscription } from "rxjs";

/**
 * Barebones container that allows you to unsubscribe from multiple subscriptions with a single call.
 */
export class SubscriptionContainer {
  private subscriptions: Subscription[] = [];

  public add(...subscriptions: Subscription[]) {
    this.subscriptions.push(...subscriptions);
  }

  /** Unsubscribes from all subcriptions in this container. */
  public unsubscribe() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = [];
  }
}
