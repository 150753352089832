import { forkJoin, Observable, Subject } from "rxjs";
import { map, toArray } from "rxjs/operators";
import {
  ApiRequestOptions,
  ContactModel,
  ContactModelPagedResult,
  GetCompanyContactsRequestOptions,
  IdentityVerificationStatus,
  ProfessionVerificationStatus,
  VerificationStatus,
} from "types";
import { concatNotNull, distinct, sorted, updateElement } from "utils";
import { CompaniesService, WorkspacesService } from "../core";
import { UserSelectionList } from "../shared/basic-user-selection-list/basic-user-selection-list.component";
import { UserService } from "./../core/services/user.service";
import { UserSelectionListItem } from "./../shared/basic-user-selection-list/basic-user-selection-list.component";
import {
  SelectionList,
  SelectionListType,
  SingleLoadableSelectionList,
  UserPickerProvider,
  UserSelectionListId,
} from "./basic-user-picker.service";
import { UserPickerSubmitCallback } from "./user-picker.service";

export interface WorkspaceUserPickerProviderOptions {
  workspaceId: string;
  companiesService: CompaniesService;
  workspaceService: WorkspacesService;
  userService: UserService;
  submitCallback: UserPickerSubmitCallback;
  disabledUserIds?: string[];
  initiallySelectedUserIds?: string[];
}

export class WorkspaceUserPickerProvider implements UserPickerProvider {
  private workspaceId: string;
  private companiesService: CompaniesService;
  private workspaceService: WorkspacesService;
  private userService: UserService;
  private submitCallback: UserPickerSubmitCallback;
  private disabledUserIds: Set<string>;
  private initiallySelectedUserIds: Set<string>;
  private currentUserId: string | null;

  private userGroupSubject = new Subject<SelectionList>();
  public userGroup$ = this.userGroupSubject.asObservable();

  public constructor(options: WorkspaceUserPickerProviderOptions) {
    this.workspaceId = options.workspaceId;
    this.companiesService = options.companiesService;
    this.workspaceService = options.workspaceService;
    this.userService = options.userService;
    this.submitCallback = options.submitCallback;
    this.disabledUserIds = new Set(options.disabledUserIds);
    this.initiallySelectedUserIds = new Set(options.initiallySelectedUserIds);
    this.currentUserId = this.userService.getUserId();
  }

  public setData(data: unknown): void {}

  public getUserGroups(
    selectedUsers: UserSelectionList,
    isInit: boolean
  ): Observable<SelectionList[]> {
    const selectedUserIds = isInit
      ? this.initiallySelectedUserIds
      : new Set<string>(selectedUsers.map((u) => u.id));
    this.userService.getUserId();
    return this.companiesService
      .getContacts({
        companyId: this.workspaceId,
        fetchAll: true,
        includeSelf: true,
      })
      .pipe(
        toArray(),
        map((pages) => pages.flatMap((page) => page.data ?? [])),
        map((contacts) => sorted(contacts, this.compareContact.bind(this))),
        map((contacts) => this.mapContacts(contacts, selectedUserIds)),
        map((users) =>
          updateElement(
            users,
            (u) => u.id === this.currentUserId,
            (u) => ({ ...u, suffix: " (You)" })
          )
        ),
        map((data) => {
          const workspaceMembers: SingleLoadableSelectionList = {
            id: UserSelectionListId.WORKSPACE_MEMBERS,
            type: SelectionListType.SINGLE_GROUP,
            data,
            isLoaded: true,
          };
          return [workspaceMembers];
        })
      );
  }

  private mapContacts(
    data: ContactModel[],
    selectedUserIds: Set<string>
  ): UserSelectionList {
    // #TODO refactor
    return data.map((contact) => this.mapContact(contact, selectedUserIds));
  }

  private mapContact(
    contact: ContactModel,
    selectedUserIds: Set<string>
  ): UserSelectionListItem {
    return {
      id: contact.userId ?? "",
      name: `${contact.firstName} ${contact.lastName}`,
      description: this.getContactDescription(contact),
      isWorkspaceVerified:
        contact.workplaces?.some(
          (w) => w.verificationStatus === VerificationStatus.Verified
        ) ?? false,
      isIdentityVerified:
        contact.identityVerificationStatus ===
        IdentityVerificationStatus.Verified,
      isProfessionVerified:
        contact.professions?.some(
          (p) => p.verificationStatus === ProfessionVerificationStatus.Verified
        ) ?? false,
      isSelected: contact.userId ? selectedUserIds.has(contact.userId) : false,
      isDisabled: contact.userId
        ? this.disabledUserIds.has(contact.userId)
        : false,
      fetchImage: contact.profilePic != null,
    };
  }

  private getContactDescription(contact: ContactModel) {
    const workspace = contact.workplaces?.find(
      (w) => w.companyId === this.workspaceId
    );
    if (!workspace) return null;
    return [workspace.position, workspace.departmentName]
      .map((value) => value?.trim())
      .filter((value) => value)
      .join(", ");
  }

  private compareContact(a: ContactModel, b: ContactModel): number {
    return concatNotNull([a.firstName, a.lastName]).localeCompare(
      concatNotNull([b.firstName, b.lastName])
    );
  }

  public searchUsers(
    query: string,
    selectedUsers: UserSelectionList
  ): Observable<SelectionList[]> {
    const getContactsConfig: GetCompanyContactsRequestOptions &
      ApiRequestOptions = {
      companyId: this.workspaceId,
      fetchAll: true,
    };

    const extractContacts = (pages: ContactModelPagedResult[]) => {
      return pages.flatMap(({ data }) => data ?? []);
    };

    const nameSearch = this.companiesService
      .getContacts({
        ...getContactsConfig,
        fullName: query,
      })
      .pipe(toArray(), map(extractContacts));
    const departmentSearch = this.companiesService
      .getContacts({
        ...getContactsConfig,
        department: query,
      })
      .pipe(toArray(), map(extractContacts));
    const positionSearch = this.companiesService
      .getContacts({
        ...getContactsConfig,
        position: query,
      })
      .pipe(toArray(), map(extractContacts));

    const joined = forkJoin({
      nameSearch,
      departmentSearch,
      positionSearch,
    });

    const groupedSearchResults = joined.pipe(
      map(({ nameSearch, departmentSearch, positionSearch }) => {
        const selectedUserIds = new Set<string>(selectedUsers.map((u) => u.id));

        const distinctResults = distinct(
          nameSearch.concat(positionSearch),
          (c) => c.userId
        );
        const sortedDistinctResults = sorted(
          distinctResults,
          this.compareContact.bind(this)
        );
        const colleagues = this.mapContacts(
          sortedDistinctResults,
          selectedUserIds
        );

        const sortedDepartmentResults = sorted(
          departmentSearch,
          this.compareContact.bind(this)
        );
        const departmentGroups = this.groupContactsByDepartment(
          sortedDepartmentResults,
          selectedUserIds
        );

        const groups: SingleLoadableSelectionList[] = [];

        if (colleagues.length) {
          groups.push({
            id: UserSelectionListId.COLLEAGUES,
            name: "Colleagues",
            description: "Found in this workspace",
            data: colleagues,
            type: SelectionListType.SINGLE_GROUP,
            isLoaded: true,
          });
        }

        groups.push(...departmentGroups);

        return groups;
      })
    );

    return groupedSearchResults;
  }

  private groupContactsByDepartment(
    contacts: ContactModel[],
    selectedUserIds: Set<string>
  ): SingleLoadableSelectionList[] {
    const groups = new Map<string, SingleLoadableSelectionList>();

    for (const contact of contacts) {
      const workspace = this.workspaceService.findWorkspace(
        contact,
        this.workspaceId
      );

      if (!workspace || !workspace.departmentId || !workspace.departmentName) {
        continue;
      }

      const selectionListItem = this.mapContact(contact, selectedUserIds);
      const group = groups.get(workspace.departmentId);

      if (group) {
        group.data?.push(selectionListItem);
      } else {
        groups.set(workspace.departmentId, {
          id: workspace.departmentId,
          name: workspace.departmentName,
          data: [selectionListItem],
          type: SelectionListType.SINGLE_GROUP,
          isLoaded: true,
        });
      }
    }

    return Array.from(groups.values());
  }

  public submitSelectedUsers(
    selectedUsers: UserSelectionList
  ): Observable<void> {
    return this.submitCallback(selectedUsers);
  }
}
