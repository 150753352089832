<div class="parent">
  <div *ngIf="hasImage && !isLoading" class="avatar">
    <img
      [src]="imageUrl"
      [style.height.px]="height"
      [style.width.px]="width"
      alt=""
    />
  </div>
  <div *ngIf="!hasImage || isLoading">
    <ngx-avatars
      [name]="name"
      [initialsSize]="3"
      [size]="width"
      [bgColor]="'#bebebe'"
      [fgColor]="'#FFFFFF'"
    ></ngx-avatars>
  </div>
  <div
    *ngIf="isOnDND"
    [style.bottom.px]="badgeMargin"
    [style.left.px]="badgeMargin"
    class="badge_container"
    [matTooltip]="dndMessage ? dndMessage : ''"
  >
    <div
      class="red_badge"
      [style.border-width.px]="badgeBorder"
      [style.height.px]="dndBadgeSize"
      [style.width.px]="dndBadgeSize"
    ></div>
  </div>
  <div *ngIf="showBadge" class="verified-badge" [style.right.px]="marginRight">
    <app-badge
      [identityVerificationStatus]="identityVerificationStatus"
      [workspaces]="workspaces"
      [isWorkspaceVerified]="isWorkspaceVerified"
      [profession]="
        user && user.professions && user.professions[0]
          ? user.professions[0]
          : null
      "
      [isProfessionVerified]="isProfessionVerified"
      [size]="badgeSize"
      [showTooltip]="showTooltip"
      [clickable]="clickable"
      [name]="name"
      [name_verified_as]="name_identity_verified_as"
      [title]="title"
      [hasImage]="hasImage"
      [userId]="userId"
      [isExternal]="isExternal"
    ></app-badge>
  </div>
</div>
