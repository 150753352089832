import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class SoundPlayService {
  recievedInConvoSound: HTMLAudioElement | null = null;
  receivedInOutAppSound: HTMLAudioElement | null = null;
  sentSound: HTMLAudioElement | null = null;

  constructor() {
    setTimeout(() => {
      this.receivedInOutAppSound = new Audio(
        "/assets/sounds/received_in_out_app.wav"
      );
    }, 0);
    setTimeout(() => {
      this.recievedInConvoSound = new Audio(
        "/assets/sounds/received_in_conversation.wav"
      );
    }, 0);
    setTimeout(() => {
      this.sentSound = new Audio("/assets/sounds/sent.wav");
    }, 0);
  }

  playReceivedSound() {
    if (this.recievedInConvoSound) {
      this.recievedInConvoSound.play().catch();
    }
  }

  playSentSound() {
    if (this.sentSound) {
      this.sentSound.play().catch();
    }
  }

  playNotificationSound() {
    if (this.receivedInOutAppSound) {
      this.receivedInOutAppSound.play().catch();
    }
  }
}
