import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { SelectionList } from "../basic-user-picker.service";

@Component({
  selector: "app-user-picker-expandable-group",
  templateUrl: "./user-picker-expandable-group.component.html",
  styleUrls: ["./user-picker-expandable-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPickerExpandableGroupComponent implements OnInit {
  @Input() public group: SelectionList | null = null;
  @Input() public stickyHeaderTop: number = 0;
  @Input() public multiple: boolean = true;
  @Input() public hideLabels: boolean = false;
  @Input() public isInitiallyExpanded: boolean = false;

  public expanded: boolean = false;

  public ngOnInit() {
    this.expanded = this.isInitiallyExpanded;
  }
}
