import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import {
  AddAWorkspaceComponent,
  AddAWorkspaceSelection,
} from "@modules/shared/add-a-workspace/add-a-workspace.component";
import { WorkspaceService } from "app/workspace/workspace.service";
import { environment } from "environments/environment";
import { Subject } from "rxjs";
import {
  BasicWorkplaceWithClaimsModel,
  CompanyBasicModel,
  FullUserProfileModel,
  Team,
} from "types";
import { ContactSelection } from "../../../modules/conversations/new-conversations/search-result/search-result.component";
import {
  AccountService,
  AlertService,
  AnalyticsService,
  SharedService,
  UserAccountService,
} from "../../../modules/core";
import { NetworkService } from "../../network.service";
import { WorkspaceEmptyStates } from "../workspace-empty-state/workspace-empty-state.component";

const MAX_WORKSPACES_TO_SHOW = 3;
export interface NetworkParams {
  workspaceId: string;
  contactId: string;
}

@Component({
  selector: "app-network",
  templateUrl: "./network.component.html",
  styleUrls: ["./network.component.scss"],
  providers: [NetworkService],
})
export class NetworkComponent implements OnInit, OnDestroy {
  connectionsCount: number;
  allWorkspaces: CompanyBasicModel[];
  userAccount: FullUserProfileModel;
  myCompaniesIds: any[];
  partneredWorkspaces: CompanyBasicModel[];
  myWorkspaces: CompanyBasicModel[];
  visibleWorkspaceCount = MAX_WORKSPACES_TO_SHOW;
  showAllWorkspaces: boolean;
  searchTerm: string;
  searchNow$: Subject<any> = new Subject<any>();
  workspaceChangeSub: any;
  workspaceEmptyStateSub: any;
  userHasNoWorkpaces: boolean;
  workspaceInfoLoaded: boolean;
  discoverable: boolean = true;
  myWorkspacesAndPartnerWorkspaceIds: string[];
  navSubscriber: any;
  isLive = true;
  isNetworkPage: boolean;

  canCreateWorkspace: boolean;
  userFeatureFlagChangeSub: any;

  constructor(
    private userAccountService: UserAccountService,
    private workspaceService: WorkspaceService,
    private alertService: AlertService,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private networkService: NetworkService,
    private accountService: AccountService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadUserInfo(true);
    this.isNetworkPage = false;
    this.navSubscriber = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (this.router.url == "/network") {
          this.isNetworkPage = true;
          this.userHasNoWorkpaces = false;
          this.loadUserInfo(true);
        }
      }
    });

    this.workspaceChangeSub = this.workspaceService.workspaceChange.subscribe(
      (res) => {
        this.router.navigate(["/network"]);
      }
    );
    this.workspaceEmptyStateSub =
      this.workspaceService.workspaceEmptyStateChange.subscribe(
        (res: string) => {
          switch (res) {
            case WorkspaceEmptyStates.add_connections:
              this.addConnections();
              break;
            case WorkspaceEmptyStates.add_a_workspace:
              this.onAddAWorspaceClicked();
              break;
            default:
              break;
          }
        }
      );
    this.loadPrivacy();
    this.userFeatureFlagChangeSub =
      this.accountService.userFeatureFlags$.subscribe({
        next: (featureFlag) => {
          if (!featureFlag) return;
          this.canCreateWorkspace = featureFlag.canCreateWorkspace;
        },
      });
  }

  ngOnDestroy(): void {
    if (this.workspaceChangeSub) {
      this.workspaceChangeSub.unsubscribe();
    }
    if (this.workspaceEmptyStateSub) {
      this.workspaceEmptyStateSub.unsubscribe();
    }
    if (this.navSubscriber) {
      this.navSubscriber.unsubscribe();
    }
    if (this.userFeatureFlagChangeSub) {
      this.userFeatureFlagChangeSub.unsubscribe();
    }
    this.isLive = false;
  }

  loadUserInfo(noCache: boolean) {
    this.userAccountService.getUserAccount(noCache, (userAccount) => {
      this.userAccount = userAccount;
      this.setMyWorkspaceIds(userAccount.workplaces);
      this.loadAllWorkspaces();
    });
  }

  onAllWorkspacesLoaded() {
    if (this.myWorkspaces && this.myWorkspaces.length) {
      this.setRecentlySelectedWorkspaceToTop(this.myWorkspaces);
    }
    if (!this.myWorkspaces || !this.myWorkspaces.length) {
      this.userHasNoWorkpaces = true;
      return;
    }
    if (!this.isLive) return;
    if (!this.isNetworkPage) return;

    //Auto Forwarding
    this.router.navigate(["/network/workspace", this.myWorkspaces[0].id]);
  }

  setRecentlySelectedWorkspaceToTop(workspaces) {
    const recentlySelectedCompanyId = this.getRecentlySelectedCompanyId();
    if (!recentlySelectedCompanyId) {
      return;
    }
    this.sharedService.pullToTop(workspaces, "id", recentlySelectedCompanyId);
  }

  getRecentlySelectedCompanyId() {
    return localStorage.getItem("company_selected");
  }

  saveSelectedCompanyId(id) {
    localStorage.setItem("company_selected", id);
  }

  loadAllWorkspaces() {
    this.workspaceService.getAllWorkspaces().subscribe((workspacesResult) => {
      this.workspaceInfoLoaded = true;
      if (!workspacesResult.data) return;
      this.allWorkspaces = workspacesResult.data;
      this.myWorkspacesAndPartnerWorkspaceIds = this.allWorkspaces.map(
        (w) => w.id
      );
      this.setPartneredWorkspaces(this.allWorkspaces);
      this.setMyWorkspaces(this.allWorkspaces);
      this.onAllWorkspacesLoaded();
    });
  }

  setMyWorkspaceIds(workspaces: BasicWorkplaceWithClaimsModel[]) {
    this.myCompaniesIds = [];
    if (!workspaces) {
      return;
    }
    for (const workspace of workspaces) {
      this.myCompaniesIds.push(workspace.companyId);
    }
  }

  setPartneredWorkspaces(workspaces: CompanyBasicModel[]) {
    this.partneredWorkspaces = this.workspaceService.getPartnerWorkspaces(
      workspaces,
      this.myCompaniesIds
    );
  }

  setMyWorkspaces(workspaces: CompanyBasicModel[]) {
    this.myWorkspaces = this.workspaceService.getMyWorkspacesOnly(
      workspaces,
      this.myCompaniesIds
    );
  }

  toggleShowAll() {
    this.showAllWorkspaces = !this.showAllWorkspaces;
    this.analyticsService.buttonClickEvent(
      this.showAllWorkspaces ? "show_more" : "show_less",
      { flow: "network" }
    );
  }

  raiseWorkspaceClickEvent(isHiddenWorkspace: boolean) {
    this.analyticsService.buttonClickEvent("workspace_selected", {
      hidden_workspace: isHiddenWorkspace,
      flow: "network",
    });
  }

  search(event) {
    this.searchTerm = event;
    setTimeout(() => {
      this.searchNow$.next(event);
    }, 100);
  }

  onSearchContactClick(ContactSelection: ContactSelection) {
    this.router.navigate(["/network/contact", ContactSelection.contact.userId]);
  }

  onAddAWorspaceClicked() {
    let ref = AddAWorkspaceComponent.openDialog(this.matDialog);
    ref.afterClosed().subscribe((response) => {
      switch (response) {
        case AddAWorkspaceSelection.create:
          this.createWorkspace();
          break;
        case AddAWorkspaceSelection.join:
          this.joinWorkspace();
          break;
        case AddAWorkspaceSelection.link:
          this.learnMoreLink();
          break;
        default:
          break;
      }
    });
    this.analyticsService.buttonClickEvent("add_a_workspace", {
      flow: "network",
    });
  }

  learnMoreLink() {
    const payload = [
      {
        type: "innerHTML",
        data: "Workspace members can invite their colleagues using the workspace link.",
      },
      {
        type: "innerHTML",
        data: "Anyone can join the workspace by following this link.",
      },
      {
        type: "innerHTML",
        data: "<b>Note:</b> Not all workspaces have an invitation link. To join a workspace without a link, select <b>Verify your work email to join</b> and follow the steps to join that workspace.",
      },
    ];
    this.alertService.customDialog(
      "Join a workspace via link",
      "",
      "DONE",
      "",
      true,
      "",
      payload
    );
  }

  joinWorkspace() {
    const instance = this;
    this.workspaceService.joinWorkspaceFlow(function (result) {
      if (!result) {
        return;
      }
      if (result["type"] == "create_workspace") {
        return instance.createWorkspace();
      }
      instance.router.navigate(["/network"]);
      instance.alertService.showSnackBar(
        "Workspace has been added successfully",
        3
      );
      instance.loadUserInfo(true);
    });
  }

  createWorkspace() {
    const instance = this;
    this.workspaceService.createWorkspaceFlow(function (result) {
      if (!result) {
        return;
      }

      if (result["type"] == "join_workspace") {
        return instance.joinWorkspace();
      }
      instance.alertService.showSnackBar(
        "Workspace has been created successfully",
        4
      );
      instance.saveSelectedCompanyId(result.id);
      instance.router.navigate(["/network"]);
      instance.loadUserInfo(true);
    }, true);
  }

  addConnections() {
    this.sharedService.invite("user");
    this.analyticsService.buttonClickEvent("add_connections", {
      flow: "network",
    });
  }

  loadPrivacy() {
    const path =
      environment.celoApiEndpoint + "/api/v2/Account/Privacy/Settings";
    this.sharedService.getObjectById(path).subscribe(
      (data) => {
        if (!data || !data.length) {
          return;
        }
        this.discoverable = this.userAccountService.getPrivacy(
          data,
          "discoverability",
          "network"
        );
      },
      (err) => {
        this.discoverable = false;
      }
    );
  }

  public handleRoleClicked(team: Team) {
    if (!team.workspace?.id || !team.id) return;
    this.networkService.selectTeam(team);
  }
}
