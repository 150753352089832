import { Component, Input } from "@angular/core";

@Component({
  selector: "app-workspace-create-role-button",
  templateUrl: "./workspace-create-role-button.component.html",
  styleUrls: ["./workspace-create-role-button.component.scss"],
})
export class WorkspaceCreateRoleButtonComponent {
  @Input() public text: string = "Add a new role";
  @Input() public fontSize: string | null = null;
  @Input() public fontWeight: string | null = null;
  @Input() public padding: string | null = null;
}
