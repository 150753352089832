import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "../../../environments/environment";

@Pipe({
  name: "clink",
})
export class ClinkPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return environment.celoApiEndpoint + value;
  }
}
