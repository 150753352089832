<div class="content">
  <h1 mat-dialog-title>Primary Email</h1>
  <div mat-dialog-content class="body">
    <div class="img appear_anim_1">
      <img src="../../../assets/email-address.png" alt="" />
    </div>
    <div class="title appear_anim_2">Primary email address</div>
    <div class="text celo-grey appear_anim_2">
      Use a personal email address so you can recover or login to your Celo
      account even if you move workspaces or lose access to your work email.
    </div>
    <div class="fields celo-input-outline appear_anim_3">
      <mat-form-field class="fw" appearance="outline">
        <input
          class="fw"
          matInput
          [(ngModel)]="data.email"
          type="email"
          placeholder="Email Address"
        />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions class="appear_anim_4">
    <button
      mat-button
      [disabled]="!data.email || submitting"
      class="fw celo-primary-button"
      (click)="verifyEmail(data.email)"
      cdkFocusInitial
    >
      NEXT
    </button>
  </div>
  <div class="dialog-close">
    <button mat-icon-button [mat-dialog-close]="false">
      <i class="material-icons">cancel</i>
    </button>
  </div>
</div>
