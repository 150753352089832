import { Component, Inject, Input, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { createDialogConfig } from "@utils";

export interface CompanyErrorData {
  title: string;
  content: string;
}

export type CompanyErrorResult = boolean;

@Component({
  selector: "app-company-error",
  templateUrl: "./company-error.component.html",
  styleUrls: ["./company-error.component.scss"],
})
export class CompanyErrorComponent implements OnInit {
  @Input() public title: string;
  @Input() public content: string;

  public constructor(@Inject(MAT_DIALOG_DATA) private data: CompanyErrorData) {}

  public ngOnInit(): void {
    this.title = this.data.title;
    this.content = this.data.content;
  }

  public static openDialog(
    matDialog: MatDialog,
    data: CompanyErrorData
  ): MatDialogRef<CompanyErrorComponent, CompanyErrorResult> {
    const config = createDialogConfig<CompanyErrorData>(data);
    return matDialog.open<
      CompanyErrorComponent,
      CompanyErrorData,
      CompanyErrorResult
    >(CompanyErrorComponent, config);
  }
}
