<div class="onboard">
  <div class="content">
    <div class="contents">
      <img class="logo" src="../../../assets/logo-v2.svg" alt="" />
      <img class="cover" src="../../../assets/email-address.png" alt="" />
      <div class="title">Enter your email address</div>
      <div class="subtitle">
        Use your work email to easily find and join your workspace.
      </div>
      <div class="fields celo-input-outline">
        <mat-form-field appearance="outline" class="fw">
          <mat-label>Email Address</mat-label>
          <input
            matInput
            class="fw"
            type="email"
            autocomplete="off"
            placeholder="Email"
            required
            [(ngModel)]="email"
            id="emailField"
            name="emailField"
            #emailField="ngModel"
            pattern="^(([^<>()\[\]\\.,;:\s@]+(\.[^<>()\[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$"
            (ngModelChange)="loading = false"
            (keydown.enter)="
              emailField && !emailField.invalid && !loading && sendEmail()
            "
            appAutofocus
          />
        </mat-form-field>
        <div
          *ngIf="email && emailField && emailField.invalid"
          class="error-message appear_1 text-center"
        >
          Please enter a valid email address.
        </div>
      </div>
      <div class="buttons">
        <a [routerLink]="['/onboard/country']" class="button">
          <button mat-button class="celo-secondary-button">BACK</button>
        </a>
        <button
          mat-button
          class="celo-primary-button button"
          (click)="sendEmail()"
          [disabled]="!emailField || emailField.invalid || loading"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</div>
