import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AlertService } from "app/modules/core/old/alert.service";
import { Observable, Subject } from "rxjs";
import { share, switchMap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { SharedService } from "../core/old/shared.service";
import { UserAccountService } from "../core/old/user-account.service";
import { ProfileDialogComponent } from "./profile-dialog/profile-dialog.component";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  public pendingConnections: any[] = [];
  // public unseenNotifications: boolean;
  public ConnectionRemovedSubject = new Subject<any>();
  public ConnectionRemoved = this.ConnectionRemovedSubject.asObservable().pipe(
    share()
  );

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private userAccountService: UserAccountService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) { }

  // loadProfile(): Observable<any> {
  //   const path = `${environment.celoApiEndpoint}/api/v2/account`;
  //   return this.http.get<any>(path).pipe(retry(3));
  // }

  safeUpdateOnCall(isOnCall: boolean): Observable<any> {
    // temporary while we wait for backend to allow for patching / putting properly.
    return this.userAccountService.getAccount().pipe(
      switchMap((p) => {
        p.isOnCall = isOnCall ? "true" : "false";
        return this.update(p);
      })
    );
  }

  shareProfile() {
    this.userAccountService.shareProfile();
  }

  update(profile: any): Observable<any> {
    if (!profile) {
      alert("error no profile");
    }
    const payload = {
      isOnCall: profile.isOnCall,
      title: profile.title,
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: profile.email,
      phoneNumber: profile.phoneNumber,
      position: profile.position,
      departmentId: profile.department ? profile.department.id : undefined,
    };
    const formData = new FormData();

    formData.append("data", JSON.stringify(payload));

    const path = `${environment.celoApiEndpoint}/api/v2/account`;
    return this.http.put(path, formData);
  }

  unblock(blockId: string, callback) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    const path = environment.celoApiEndpoint + "/api/blocks/" + blockId;
    this.sharedService.deleteObjectById(path).subscribe(
      (res) => {
        callback(res);
      },
      (err) => {
        let errorMessage;
        errorMessage = this.sharedService.getErrorMessageFromError(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      }
    );
  }

  block(userId: string, callback) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    const path = environment.celoApiEndpoint + "/api/blocks";
    const body = {
      userId,
    };
    this.sharedService.postObjectById(path, {}, body).subscribe(
      (res) => {
        callback(res);
      },
      (err) => {
        let errorMessage;
        errorMessage = this.sharedService.getErrorMessageFromError(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      }
    );
  }

  getPendingConnectionRequest(pageSize, page, callback) {
    const path = environment.celoApiEndpoint + "/api/Connections";
    const body = {
      page,
      PageSize: pageSize,
    };
    this.sharedService.getObjectById(path, body).subscribe(
      (res) => {
        callback(res);
      },
      (err) => {
        let errorMessage;
        errorMessage = this.sharedService.getErrorMessageFromError(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      }
    );
  }

  getNotificationStatuses(callback) {
    const path = environment.celoApiEndpoint + "/api/v2/user/seenStatuses";
    this.sharedService.getObjectById(path).subscribe((res) => {
      callback(res);
    });
  }

  updateNotificationStatuses(
    activityFeedSeen,
    connectionRequestSeen,
    callback
  ) {
    const path = environment.celoApiEndpoint + "/api/v2/user/seenStatuses";
    const body = {
      activityFeedSeen,
      connectionRequestSeen,
    };
    this.sharedService.putObjectById(path, {}, body).subscribe(
      (res) => {
        callback(true);
      },
      (err) => {
        callback(false);
      }
    );
  }

  // getUnseenConnections(callback) {
  //   const path =
  //     environment.celoApiEndpoint + "/api/Account/ConnectionRequests/status";
  //   this.sharedService.getObjectById(path).subscribe((res) => {
  //     callback(res);
  //   });
  // }

  // markReadConnectionNotifications(callback) {
  //   this.unseenNotifications = false;
  //   const path =
  //     environment.celoApiEndpoint + "/api/Account/ConnectionRequests/status";
  //   const body = {
  //     seenOnUtc: new Date().toISOString(),
  //   };
  //   this.sharedService.postObjectById(path, {}, body).subscribe((res) => {
  //     callback(res);
  //   });
  // }

  sendConnectionRequest(userId: string, callback) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    const path = environment.celoApiEndpoint + "/api/Connections";
    const body = {
      userId,
    };
    this.sharedService.postObjectById(path, {}, body).subscribe(
      (res) => {
        callback(res);
      },
      (err) => {
        let errorMessage;
        errorMessage = this.sharedService.getErrorMessageFromError(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      }
    );
  }

  rejectConnectionRequest(connectionId: string, callback) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    const path =
      environment.celoApiEndpoint + "/api/Connections/" + connectionId;
    const patch = [
      {
        value: "Rejected",
        path: "/State",
        op: "add",
      },
    ];
    this.sharedService.patchObjectById(path, patch).subscribe((res) => {
      callback(res);
    });
  }

  acceptConnectionRequest(connectionId: string, callback) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    const path =
      environment.celoApiEndpoint + "/api/Connections/" + connectionId;
    const patch = [
      {
        value: "Accepted",
        path: "/State",
        op: "add",
      },
    ];
    this.sharedService.patchObjectById(path, patch).subscribe((res) => {
      callback(res);
    });
  }

  deleteConnection(connectionId: string, callback) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    const path =
      environment.celoApiEndpoint + "/api/Connections/" + connectionId;
    this.sharedService.deleteObjectById(path).subscribe((res) => {
      callback(res);
    });
  }

  openProfile(profileId, callback?) {
    const config = new MatDialogConfig();
    config.autoFocus = false;
    config.panelClass = "profile-dialog";
    config.width = '100%';
    config.maxWidth = '550px';
    config.data = {
      contactId: profileId,
    };
    const dialogRef = this.dialog.open(ProfileDialogComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      if (result) {
        if (callback) {
          callback(result);
        }
      }
    });
  }
}
