import { Injectable } from "@angular/core";
import { MessageComposeModel } from "app/messages/message-input/message-input.component";
import { UploadServiceFileItem } from "./file-upload.service";

export type UploadMemoryModel = Map<string, UploadModel>;

export interface UploadModel {
  items: UploadServiceFileItem[];
  patient: UploadPatientModel;
  selectedItemIndex: number;
}

export interface UploadPatientModel {
  uid: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string | null;
  gender: "Male" | "Female" | null;
}

export interface MessageComposeMemoryModel {
  [x: string]: MessageComposeModel;
}

export interface MemoryModel {
  messageDrafts?: MessageComposeMemoryModel;
  uploadDrafts: UploadMemoryModel;
}

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class MemoryService {
  public memory: MemoryModel = {
    uploadDrafts: new Map(),
    messageDrafts: {}
  };
}
