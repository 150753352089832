import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { BehaviorSubject, Subject } from "rxjs";
import {
  LibraryLightBoxDataContent,
  LibraryLightboxComponent,
} from "./library-lightbox/library-lightbox.component";
import { BlockScrollStrategy } from "@angular/cdk/overlay";

@Injectable({
  providedIn: "root",
})
export class LibraryService {
  public messageMetaUpdatedEmitted$ = new Subject();
  public reloadPhotoEmitted$ = new Subject();

  private reloadDocumentSource = new Subject<boolean>();
  public reloadDocumentEmitted$ = this.reloadDocumentSource.asObservable();

  private canSelectSource = new BehaviorSubject<boolean>(false);
  public canSelectEmitted$ = this.canSelectSource.asObservable();

  private canSelectSourceReset = new BehaviorSubject<boolean>(false);
  public canSelectResetEmitted$ = this.canSelectSourceReset.asObservable();

  private showActionSource = new BehaviorSubject<boolean>(false);
  public showActionEmitted$ = this.showActionSource.asObservable();

  private searchPhotoSource = new Subject<string>();
  public searchPhotoEmitted$ = this.searchPhotoSource.asObservable();

  private photoActionSource = new Subject<string>();
  public photoActionEmitted$ = this.photoActionSource.asObservable();

  private searchConsentSource = new BehaviorSubject<string>("");
  public searchConsentEmitted$ = this.searchConsentSource.asObservable();

  private consentActionSource = new Subject<string>();
  public consentActionEmitted$ = this.consentActionSource.asObservable();

  private searchDocumentSource = new BehaviorSubject<string>("");
  public searchDocumentEmitted$ = this.searchDocumentSource.asObservable();

  private documentActionSource = new Subject<string>();
  public documentActionEmitted$ = this.documentActionSource.asObservable();

  public messageMetaUpdated(meta: any) {
    this.messageMetaUpdatedEmitted$.next(meta);
  }

  public emitReloadPhoto(reload: boolean) {
    this.reloadPhotoEmitted$.next(reload);
  }

  public emitReloadDocument(reload: boolean) {
    this.reloadDocumentSource.next(reload);
  }

  public emitCanSelect(canSelect: boolean) {
    this.canSelectSource.next(canSelect);
  }

  public emitCanSelectReset(canSelect: boolean) {
    this.canSelectSourceReset.next(canSelect);
  }

  public emitShowAction(show: boolean) {
    this.showActionSource.next(show);
  }

  public emitSearchPhoto(term: string) {
    this.searchPhotoSource.next(term);
  }

  public emitPhotoAction(action: string) {
    this.photoActionSource.next(action);
  }

  public emitSearchConsent(term: string) {
    this.searchConsentSource.next(term);
  }

  public emitConsentAction(action: string) {
    this.consentActionSource.next(action);
  }

  public emitSearchDocument(term: string) {
    this.searchDocumentSource.next(term);
  }

  public emitDocumentAction(action: string) {
    this.documentActionSource.next(action);
  }

  public constructor(public dialog: MatDialog) {}

  public openLightbox(
    data: LibraryLightBoxDataContent,
    isFromLibrary?: boolean,
    conversationType?: string
  ) {
    const config = new MatDialogConfig();
    config.panelClass = "lightbox-panel";
    config.data = {
      content: data,
      isFromLibrary,
      conversationType,
      hasBackdrop: true,
      scrollStrategy: BlockScrollStrategy,
      backdropClass: "library-lightbox"
    };

    if (data.mediaType === "message") {
      config.data.isFromConversation = data.enableMediaNavigation;
      config.data.conversationId = data.conversationId;
    }

    const dialogRef = this.dialog.open(LibraryLightboxComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      if (result.success) {
        if (result.refreshLibrary) {
          this.emitReloadPhoto(true);
          this.emitReloadDocument(true);
        }
      }
    });
  }
}
