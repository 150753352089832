import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AnalyticsService } from "../../../modules/core/old/analytics.service";

export enum AddAWorkspaceSelection {
  create = "create",
  join = "join",
  link = "link",
}

@Component({
  selector: "app-add-a-workspace",
  templateUrl: "./add-a-workspace.component.html",
  styleUrls: ["./add-a-workspace.component.scss"],
})
export class AddAWorkspaceComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<AddAWorkspaceComponent>,
    private analyticsService: AnalyticsService
  ) {}

  public static openDialog(matDialog: MatDialog) {
    return matDialog.open(AddAWorkspaceComponent, {
      autoFocus: false,
      panelClass: "add-workspace-dialog",
    });
  }

  ngOnInit(): void {
    this.analyticsService.raisePageViewEvent("celo_page_view", {
      flow: "add_a_workspace",
    });
  }

  createWorkspace() {
    this.dialogRef.close(AddAWorkspaceSelection.create);
    this.analyticsService.buttonClickEvent("create_a_workspace", {
      flow: "add_a_workspace",
    });
  }

  joinWorkspace() {
    this.dialogRef.close(AddAWorkspaceSelection.join);
    this.analyticsService.buttonClickEvent("join_workspace_via_email", {
      flow: "add_a_workspace",
    });
  }

  learnMore() {
    this.dialogRef.close(AddAWorkspaceSelection.link);
    this.analyticsService.buttonClickEvent("join_workspace_via_link", {
      flow: "add_a_workspace",
    });
  }
}
