<div class="celo-grid">
  <div class="celo-left-side-nav">
    <div class="convo-inner-grid">
      <div>
        <app-conversations-header
          [isOnCall]="userAccount && userAccount.isOnCall === 'true'"
          (query)="search($event)"
        >
        </app-conversations-header>
      </div>

      <div
        id="conversations-scroll"
        class="celo-scrollable"
        *ngIf="{
          conversations: conversations$ | async,
          isInboxLoading: isInboxLoading$ | async,
          isSearchResultsLoading: isSearchResultsLoading$ | async,
          isLoadingMore: isLoadingMore$ | async,
          searchQuery: searchQuery$ | async
        } as state"
      >
        <div id="topAnchor"></div>

        <div *ngIf="!state.searchQuery" class="fh">
          <app-conversation-list
            *ngIf="!state.isSearchResultsLoading && !state.isInboxLoading"
            (loadMore)="loadMore()"
            [userAccount]="userAccount ? userAccount : {}"
            [conversations]="state.conversations"
            [canLoadMore]="hasMore$ | async"
            [loading]="state.isLoadingMore"
          ></app-conversation-list>

          <div
            class="loadingcontainer"
            *ngIf="state.isInboxLoading; else emptyInbox"
          >
            <app-empty-state
              [isLoading]="true"
              [message]="'Conversations'"
              [stroke]="1"
              [diameter]="40"
            ></app-empty-state>
          </div>

          <div
            #emptyInbox
            *ngIf="!state.conversations.length"
            class="no-messages"
          >
            <img src="../../assets/empty_states/empty-state-inbox.svg" alt="" />
            <div class="no-results celo-text-18 celo-bold-500">
              Start Messaging
            </div>
            <div class="no-results celo-text-14 celo-grey">
              Securely message your colleagues and mute conversations on days
              off.
            </div>
          </div>
        </div>

        <div *ngIf="state.searchQuery" class="fh">
          <app-conversation-results
            *ngIf="userAccount"
            [conversations]="searchResults$ | async"
            [userAccount]="userAccount"
            [searchKeyword]="state.searchQuery"
            [canLoadMore]="hasMoreSearchResults$ | async"
            [isLoading]="state.isSearchResultsLoading"
            [isLoadingMore]="state.isLoadingMore"
            (loadMore)="loadMore(true)"
          ></app-conversation-results>
        </div>
      </div>
    </div>
  </div>

  <router-outlet></router-outlet>
</div>
