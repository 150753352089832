import { Component, Inject, Input, SimpleChanges } from "@angular/core";
import {
  CONVERSATIONS_SERVICE,
  ConversationsServiceProvider,
  USER_SERVICE,
  UserServiceProvider,
} from "@modules/core";
import {
  ConversationModelV3,
  ConversationType,
  IdentityVerificationStatus,
  ProfessionVerificationStatus,
  VerificationStatus
} from "@types";

@Component({
  selector: "app-voip-conversation-avatar",
  templateUrl: "./voip-conversation-avatar.component.html",
  styleUrls: ["./voip-conversation-avatar.component.scss"],
})
export class VoipConversationAvatarComponent {
  @Input() public conversation: ConversationModelV3;
  @Input() public size: number = 80;

  public conversationType: ConversationType;
  public conversationName: string;
  public otherParticipantUserId: string;
  public conversationPhotoUrl: string;

  public isWorkspaceVerified: boolean | null = null;
  public isIdentityVerified: boolean | null = null;
  public isProfessionVerified: boolean | null = null;

  public badgeSize: number = 27;
  public rolesIconSize: number = 64;

  private badgeSizeScaleFactor: number = 27 / 80;
  private rolesIconSizeScaleFactor: number = 0.8;

  constructor(
    @Inject(USER_SERVICE) private userService: UserServiceProvider,
    @Inject(CONVERSATIONS_SERVICE)
    private conversationsService: ConversationsServiceProvider
  ) {}

  ngOnInit(): void {
    if (this.conversation != null) {
      this.handleConversationLoaded();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.badgeSize = this.size * this.badgeSizeScaleFactor;
    this.rolesIconSizeScaleFactor = this.size * this.rolesIconSizeScaleFactor;

    if (Object.hasOwn(changes, "conversation")) {
      if (this.conversation != null) {
        this.handleConversationLoaded();
      }
    }
  }

  private handleConversationLoaded() {
    this.conversationType = this.conversation.type;
    this.conversationName = this.conversationsService.getConversationName(
      this.conversation
    );
    const currentUserId = this.userService.getUserId(true);

    switch (this.conversation.type) {
      case ConversationType.Chat:
        // Use user avatar
        const participant = this.conversation.participants.find(
          (p) => p.userId !== currentUserId
        );
        if (!participant) throw new Error("Missing other participant");

        this.isWorkspaceVerified = participant.workplaces?.some(
          (w) =>
            !w.leftOnUtc && w.verificationStatus === VerificationStatus.Verified
        );

        this.isIdentityVerified =
          participant.identityVerificationStatus ===
          IdentityVerificationStatus.Verified;

        this.isProfessionVerified = participant.professions?.some(
          (p) => p.verificationStatus === ProfessionVerificationStatus.Verified
        );

        this.otherParticipantUserId = participant.userId;
        break;
      case ConversationType.Group:
        // Use group avatar
        if (!this.conversation.photoId) break;
        this.conversationPhotoUrl =
          this.conversationsService.getConversationProfileUri(
            this.conversation.id,
            this.conversation.photoId
          );
        break;
      case ConversationType.Case:
      case ConversationType.TeamChat:
        this.conversationPhotoUrl = null;
        break;
      default:
        throw new Error(
          `Unsupported conversation type: ${this.conversationType}`
        );
    }
  }
}
