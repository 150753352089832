<ng-container *ngIf="{ hideTeams: hideTeams$ | async } as state">
  <app-basic-user-selection-list-group
    *ngIf="
      group.isLoaded &&
      group.type === SelectionListType.SINGLE_GROUP &&
      (!state.hideTeams || !group.isTeams)
    "
    (user)="handleUser($event)"
    [users]="group.data"
    (usersChange)="handleChange($event)"
    [multiple]="multiple"
    [hideLabels]="hideLabels"
    [label]="group.selectAllControlName || group.name"
  >
  </app-basic-user-selection-list-group>

  <ng-container
    *ngIf="group.isLoaded && group.type === SelectionListType.SUBGROUPED"
  >
    <ng-container *ngFor="let subgroup of group.data; trackBy: subgroupTrackBy">
      <app-basic-user-selection-list-group
        *ngIf="!state.hideTeams || !subgroup.isTeams"
        (user)="handleUser($event)"
        [users]="subgroup.data"
        (usersChange)="handleChange($event)"
        [multiple]="multiple"
        [hideLabels]="hideLabels"
        [label]="subgroup.selectAllControlName || subgroup.name"
      >
      </app-basic-user-selection-list-group>
    </ng-container>
  </ng-container>

  <div *ngIf="group.isLoaded && !group.data?.length" class="mat-body">
    No results
  </div>
</ng-container>

<app-basic-spinner
  *ngIf="!group.isLoaded"
  class="spinner"
  diameter="15"
></app-basic-spinner>
