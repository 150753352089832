<div class="content">
  <button mat-icon-button aria-label="Close" id="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

  <h2 class="mat-h2">Clock Out</h2>

  <app-basic-textfield
    id="search"
    placeholder="Search members"
    icon="search"
    (enter)="handleEnter()"
    [formControl]="query"
  ></app-basic-textfield>

  <div class="mat-body-strong">Assign the role</div>

  <mat-divider></mat-divider>

  <div class="members-container">
    <ng-container *ngIf="filteredUsers.length; else noResults">
      <app-basic-user-selection-list
        [users]="filteredUsers"
        (user)="handleUser($event)"
        [radio]="true"
      ></app-basic-user-selection-list>
    </ng-container>

    <ng-template #noResults>
      <div class="mat-caption">No results found</div>
    </ng-template>
  </div>

  <button
    id="assign-button"
    class="celo-primary-button celo-uppercase"
    mat-button
    (click)="handleAssignAndClockOut()"
    [disabled]="selectedMember === null"
  >
    Assign and clock out
  </button>

  <button
    id="clock-out-button"
    class="celo-link"
    mat-button
    (click)="handleClockOut()"
  >
    Skip and clock out
  </button>
</div>
