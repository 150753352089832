import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
} from "@angular/core";
import { AnalyticsService } from "../modules/core";
import { CarouselItemDirective } from "./carousel-item/carousel-item.directive";

const ANIMATION_DELAY = 300;

type AnimationState =
  | "left-exit"
  | "right-exit"
  | "left-enter"
  | "right-enter"
  | "inactive";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
  animations: [
    trigger("slideInOut", [
      state(
        "left-exit",
        style({
          transform: "translateX(-100%)",
          visibility: "hidden",
        })
      ),
      state(
        "right-exit",
        style({
          transform: "translateX(100%)",
          visibility: "hidden",
        })
      ),
      state(
        "inactive",
        style({
          visibility: "hidden",
        })
      ),
      transition("void => left-enter", [
        style({ transform: "translateX(0%)" }),
      ]),
      transition("* => left-exit", [
        style({ transform: "translateX(0%)" }),
        animate(
          `${ANIMATION_DELAY}ms`,
          style({ transform: "translateX(-100%)" })
        ),
      ]),
      transition("* => left-enter", [
        style({ transform: "translateX(100%)", visibility: "visible" }),
        animate(`${ANIMATION_DELAY}ms`, style({ transform: "translateX(0%)" })),
      ]),
      transition("* => right-exit", [
        style({ transform: "translateX(0%)" }),
        animate(
          `${ANIMATION_DELAY}ms`,
          style({ transform: "translateX(100%)" })
        ),
      ]),
      transition("* => right-enter", [
        style({ transform: "translateX(-100%)", visibility: "visible" }),
        animate(`${ANIMATION_DELAY}ms`, style({ transform: "translateX(0%)" })),
      ]),
    ]),
  ],
})
export class CarouselComponent implements OnInit, OnDestroy {
  @Input() autoScrollDelay = 10000;

  @ContentChildren(CarouselItemDirective)
  carouselItems!: QueryList<CarouselItemDirective>;

  previousSlideIndex = -1;
  currentSlideIndex = 0;
  slideLeft = true;
  isAnimating = false;
  animationTimeout: NodeJS.Timeout = null;
  autoScrollTimeout: NodeJS.Timeout = null;

  constructor(private analyticsService: AnalyticsService) {}

  ngOnInit(): void {
    this.initAutoScroll();
  }

  ngOnDestroy(): void {
    if (this.animationTimeout) {
      clearTimeout(this.animationTimeout);
    }

    if (this.autoScrollTimeout) {
      clearInterval(this.autoScrollTimeout);
    }
  }

  public previous(): void {
    if (this.isAnimating) {
      return;
    }
    this.previousSlideIndex = this.currentSlideIndex;
    const previous = this.currentSlideIndex - 1;
    this.currentSlideIndex =
      previous < 0 ? this.carouselItems.length - 1 : previous;
    this.slideLeft = false;
    this.restartAutoScrollInterval();
    this.startAnimationTimer();
    this.raiseEvent("swipe_left");
  }

  public next(isAutoScroll: boolean = false): void {
    if (this.isAnimating) {
      return;
    }
    this.previousSlideIndex = this.currentSlideIndex;
    const next = this.currentSlideIndex + 1;
    this.currentSlideIndex = next === this.carouselItems.length ? 0 : next;
    this.slideLeft = true;
    this.restartAutoScrollInterval();
    this.startAnimationTimer();

    if (!isAutoScroll) {
      this.raiseEvent("swipe_right");
    }
  }

  public raiseEvent(action): void {
    this.analyticsService.raiseEvent("carousel_engagement", {
      flow: "signing_up",
      action,
    });
  }

  public scrollTo(index: number): void {
    if (this.isAnimating) {
      return;
    }
    if (this.currentSlideIndex === index) {
      return;
    }
    this.previousSlideIndex = this.currentSlideIndex;
    this.currentSlideIndex = index;

    if (index > this.previousSlideIndex) {
      this.slideLeft = true;
    } else {
      this.slideLeft = false;
    }

    this.restartAutoScrollInterval();
    this.startAnimationTimer();
  }

  private getState(index: number): AnimationState {
    if (index !== this.previousSlideIndex && index !== this.currentSlideIndex) {
      return "inactive";
    }

    if (this.slideLeft) {
      if (index === this.previousSlideIndex) {
        return "left-exit";
      } else {
        return "left-enter";
      }
    } else {
      if (index === this.previousSlideIndex) {
        return "right-exit";
      } else {
        return "right-enter";
      }
    }
  }

  private startAnimationTimer(): void {
    this.isAnimating = true;
    this.animationTimeout = setTimeout(
      () => (this.isAnimating = false),
      ANIMATION_DELAY + 10
    );
  }

  private initAutoScroll(): void {
    this.autoScrollTimeout = setInterval(
      this.scroll.bind(this),
      this.autoScrollDelay
    );
  }

  private restartAutoScrollInterval(): void {
    if (this.autoScrollTimeout) {
      clearInterval(this.autoScrollTimeout);
    }
    this.initAutoScroll();
  }

  private scroll(): void {
    this.next(true);
  }
}
