import { Component, Input } from "@angular/core";
import { Team } from "types";

@Component({
  selector: "app-roles-home-empty-state",
  templateUrl: "./roles-home-empty-state.component.html",
  styleUrls: ["./roles-home-empty-state.component.scss"],
})
export class RolesHomeEmptyStateComponent {
  @Input() public team: Team | null = null;
}
