<div *ngIf="pendingConnections.length" class="container">
  <div class="notification-header">
    <div class="head">
      <div class="title">Connection Requests</div>
      <a routerLink="/notifications"
        ><div class="button celo-link">See all</div></a
      >
    </div>
    <hr />
  </div>
  <div class="content">
    <app-connections-list
      [list]="pendingConnections"
      [userAccount]="userAccount"
    ></app-connections-list>
  </div>
</div>
<div class="container">
  <div class="notification-header">
    <div class="head">
      <div class="title">Activity Feed</div>
      <a routerLink="/notifications"
        ><div class="button celo-link">See all</div></a
      >
    </div>
    <hr />
  </div>
  <div class="content">
    <app-activity-feed-list></app-activity-feed-list>
  </div>
</div>
