<div *ngIf="!list">
  <app-empty-state [isLoading]="true" [message]="' Loading '"></app-empty-state>
</div>
<div *ngIf="list && list.length === 0" class="empty-message">
  <div class="text">- No pending connection requests -</div>
</div>
<div *ngIf="list && list.length !== 0">
  <div *ngFor="let request of list" class="celo-hoverable">
    <div mat-ripple>
      <div class="item">
        <div
          class="icon celo-cursor-pointer"
          (click)="openProfile(request.userId)"
        >
          <app-avatar
            [name]="request.firstName + ' ' + request.lastName"
            [userId]="request.userId"
            [width]="50"
            [height]="50"
            [hasImage]="!!request.profilePic"
            [showBadge]="true"
            [badgeSize]="18"
            [workspaces]="request.workplaces"
            [identityVerificationStatus]="request.identityVerificationStatus"
            [showTooltip]="false"
            [user]="request"
          ></app-avatar>
        </div>
        <div class="content">
          <span class="text"
            ><span
              class="name celo-cursor-pointer celo-hoverable"
              (click)="openProfile(request.userId)"
              >{{ request.firstName }} {{ request.lastName }}
            </span>
            <span *ngIf="!request.accepted"
              >would like to connect with you.</span
            >
            <span *ngIf="request.accepted" class="appear_2"
              >is now a connection.</span
            >
          </span>
          <div
            *ngIf="
              !request.accepted &&
              request.connection &&
              request.connection.state === 'Pending' &&
              request.connection.createdBy !== userAccount.userId
            "
            class="buttons"
            [ngClass]="{ 'float-right': floatRight }"
          >
            <div>
              <button
                mat-button
                class="celo-primary-button celo-button-sm"
                (click)="
                  acceptConnection(request.connection); $event.stopPropagation()
                "
              >
                <span>ACCEPT</span>
              </button>
            </div>
            <div>
              <button
                mat-button
                class="celo-secondary-button celo-button-sm"
                (click)="deleteConnection(request); $event.stopPropagation()"
              >
                <span>DECLINE</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
