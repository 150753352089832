<div matDialogTitle class="title">
  <img
    src="../../../../../assets/icons/icon-home.svg"
    alt="Workspace icon"
    width="40px"
    height="40px"
  />
  <h2 class="mat-headline-6">{{ title }}</h2>
</div>

<mat-dialog-content class="mat-body">
  <span>You are invited to join the {{ title }} Workspace.</span>
  <br />
  <span>
    By joining this workspace's Celo license, your account will gain access to
    Celo Enterprise features.
  </span>
  <br />
  <span>
    {{ title }} will be able to manage your Celo account, which includes editing
    your workspace profile and/or deleting your Celo account.
  </span>
  <br />
  <form [formGroup]="consentForm">
    <mat-checkbox formControlName="hasAcceptedInvitation" color="primary">
      I accept the workspace's invitation to manage my Celo account.
    </mat-checkbox>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    (click)="onCancel()"
    mat-button
    class="celo-secondary-button uppercase"
  >
    Cancel
  </button>

  <button
    (click)="onJoin()"
    mat-button
    class="celo-primary-button uppercase"
    [disabled]="!consentForm.valid"
  >
    Join
  </button>
</mat-dialog-actions>
