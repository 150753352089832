import { Component, Input, OnChanges } from "@angular/core";
import { AlertService, SharedService } from "../../../core";
import { WorkspaceService } from "app/workspace/workspace.service";

@Component({
  selector: "app-badge-info",
  templateUrl: "./badge-info.component.html",
  styleUrls: ["./badge-info.component.scss"],
})
export class BadgeInfoComponent implements OnChanges {
  @Input() identityVerificationStatus;
  @Input() hideUnverifiedStatus = false;
  @Input() profession;
  @Input() workspaces = [];
  @Input() isWorkspaceVerified = false;
  activeWorkspaces = [];
  verifiedWorkspaces = [];
  maxWorkspacesToShow = 3;
  @Input() userId;
  @Input() hasImage;
  @Input() title;
  @Input() name;
  @Input() name_verified_as;

  @Input() ownProfile = false;

  constructor(
    private sharedService: SharedService,
    private workspaceService: WorkspaceService,
    private alertService: AlertService
  ) {}

  ngOnChanges() {
    this.isWorkspaceVerified = false;
    this.activeWorkspaces = this.sharedService.getActiveWorkspaces(
      this.workspaces
    );
    this.verifiedWorkspaces = this.sharedService.getVerifiedWorkspaces(
      this.activeWorkspaces
    );
    if (this.workspaces && this.workspaces.length) {
      this.isWorkspaceVerified = this.workspaceService.isWorkspaceVerified(
        this.workspaces
      );
    }
  }

  verifyIdentity() {
    this.alertService.verifyIdentityDialog();
  }
}
