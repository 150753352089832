import { FormBuilder, Validators } from "@angular/forms";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InvitationModel } from "types";
import { FormGroup } from "@angular/forms";

export interface ActionUserInvitationDialogData {
  invitation: InvitationModel;
}

export interface ActionUserInvitationDialogResult {
  isConfirmed?: boolean;
}

@Component({
  selector: "app-action-user-invitation-dialog",
  templateUrl: "./action-user-invitation-dialog.component.html",
  styleUrls: ["./action-user-invitation-dialog.component.scss"],
})
export class ActionUserInvitationDialogComponent implements OnInit {
  public title: string = "";
  public consentForm: FormGroup = this.formBuilder.group({
    hasAcceptedInvitation: this.formBuilder.control(
      false,
      Validators.requiredTrue
    ),
  });

  public constructor(
    @Inject(MAT_DIALOG_DATA) private data: ActionUserInvitationDialogData,
    private matDialogRef: MatDialogRef<
      ActionUserInvitationDialogComponent,
      ActionUserInvitationDialogResult
    >,
    private formBuilder: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.title = this.data.invitation.metadata?.name ?? "Invitation";
  }

  public onCancel() {
    this.matDialogRef.close({
      isConfirmed: false,
    });
  }

  public onJoin() {
    this.matDialogRef.close({
      isConfirmed: true,
    });
  }
}
