import { Component, Input } from "@angular/core";

@Component({
  selector: "app-info-line",
  templateUrl: "./info-line.component.html",
  styleUrls: ["./info-line.component.scss"],
})
export class InfoLineComponent {
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() title: string;
  @Input() subtitles: string[];
  @Input() showBadge: boolean;
  @Input() workspaces = [];
}
