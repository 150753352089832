import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import { PatientDataModel, Team } from "types";
import {
  NetworkUserPickerService,
  NetworkUserPickerState,
} from "./../network-user-picker.service";

@Component({
  selector: "app-network-user-picker",
  templateUrl: "./network-user-picker.component.html",
  styleUrls: ["./network-user-picker.component.scss"],
  providers: [NetworkUserPickerService],
})
export class NetworkUserPickerComponent implements OnInit {
  public isCreatingGroup: boolean = false;
  public isCreatingCase: boolean = false;
  public isMultipleSelect: boolean = false;
  public isEnteringDetails: boolean = false;
  public submitButtonText: string | null = null;
  public cancelButtonText: string | null = null;
  public conversationName: string = "";
  public isConversationNameInvalid: boolean = true;
  public patientData: PatientDataModel = {};
  public multiple: boolean = false;
  public asTeam$: Observable<Team | null> | null = null;

  private state: NetworkUserPickerState = NetworkUserPickerState.CREATE_CASE;

  public constructor(
    private matDialogRef: MatDialogRef<NetworkUserPickerComponent>,
    private networkUserPickerService: NetworkUserPickerService
  ) {}

  public ngOnInit(): void {
    this.resetDetails();
    this.networkUserPickerService.state$.subscribe({
      next: this.handleState.bind(this),
    });
    this.asTeam$ = this.networkUserPickerService.asTeam$;
  }

  public handleState(state: NetworkUserPickerState) {
    this.state = state;
    this.multiple = state !== NetworkUserPickerState.CREATE_CHAT;
    this.isCreatingGroup = state === NetworkUserPickerState.CREATE_GROUP;
    this.isCreatingCase = state === NetworkUserPickerState.CREATE_CASE;
    this.isMultipleSelect = state === NetworkUserPickerState.MULTIPLE_SELECT;

    if (this.isMultipleSelect) {
      this.submitButtonText = "Save";
      this.cancelButtonText = "Cancel";
    }
  }

  public handleSubmit() {
    if (this.isMultipleSelect) {
      this.networkUserPickerService.save();
      return;
    }

    if (this.isEnteringDetails) {
      if (this.state === NetworkUserPickerState.CREATE_GROUP) {
        this.networkUserPickerService.createGroup(this.conversationName);
      } else if (this.state === NetworkUserPickerState.CREATE_CASE) {
        this.networkUserPickerService.createCase(
          this.conversationName,
          this.patientData
        );
      }
      return;
    }

    this.isEnteringDetails = true;
    if (this.state === NetworkUserPickerState.CREATE_GROUP) {
      this.submitButtonText = "Create New Group";
    } else if (this.state === NetworkUserPickerState.CREATE_CASE) {
      this.submitButtonText = "Create New Case";
    }
  }

  public handleBack() {
    if (this.isMultipleSelect) {
      this.matDialogRef.close();
      return;
    }

    if (this.isEnteringDetails) {
      this.resetDetails();
      this.isEnteringDetails = false;
      this.submitButtonText = null;
    } else {
      this.networkUserPickerService.onBack();
    }
  }

  private resetDetails() {
    this.conversationName = "";
    this.isConversationNameInvalid = true;
    this.patientData = {
      uid: "",
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      gender: null ?? undefined,
    };
  }
}
