import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CeloMaterialModule } from "./../../celo-material/celo-material.module";
import { SharedModule } from "./../shared/shared.module";
import { ConversationCardComponent } from "./conversation-list/conversation-card/conversation-card.component";
import { ConversationListComponent } from "./conversation-list/conversation-list.component";
import { MuteMenuComponent } from "./conversation-list/mute-menu/mute-menu.component";
import { ConversationPickerDialogComponent } from "./conversation-picker-dialog/conversation-picker-dialog.component";
import { ConversationPickerComponent } from "./conversation-picker/conversation-picker.component";
import { ConversationResultsGroupComponent } from "./conversation-results-group/conversation-results-group.component";
import { ConversationResultsComponent } from "./conversation-results/conversation-results.component";
import { ConversationsHeaderComponent } from "./conversations-header/conversations-header.component";
import { ConversationsHomeComponent } from "./conversations-home/conversations-home.component";
import { ConversationsRoutingModule } from "./conversations-routing.module";
import { ConversationsComponent } from "./conversations.component";
import { CreateNewConversationButtonComponent } from "./create-new-conversation-button/create-new-conversation-button.component";
import { CeloSelectedContactsComponent } from "./new-conversations/celo-selected-contacts/celo-selected-contacts.component";
import { ContactListComponent } from "./new-conversations/contact-list/contact-list.component";
import { ContactsExpansionPanelComponent } from "./new-conversations/contacts-expansion-panel/contacts-expansion-panel.component";
import { NewChatComponent } from "./new-conversations/new-chat/new-chat.component";
import { NewMessageCreateComponent } from "./new-conversations/new-message-create/new-message-create.component";
import { SearchResultComponent } from "./new-conversations/search-result/search-result.component";
import { ConversationPictureComponent } from './conversation-picture/conversation-picture.component';

@NgModule({
  declarations: [
    ConversationListComponent,
    ConversationCardComponent,
    MuteMenuComponent,
    ConversationPickerComponent,
    ConversationResultsComponent,
    ConversationsHeaderComponent,
    CreateNewConversationButtonComponent,
    ConversationsHomeComponent,
    ContactListComponent,
    ContactsExpansionPanelComponent,
    NewChatComponent,
    SearchResultComponent,
    ConversationsComponent,
    CeloSelectedContactsComponent,
    NewMessageCreateComponent,
    ConversationPickerDialogComponent,
    ConversationResultsGroupComponent,
    ConversationPictureComponent,
  ],
  imports: [
    CommonModule,
    CeloMaterialModule,
    FormsModule,
    RouterModule,
    SharedModule,
    ConversationsRoutingModule,
    ReactiveFormsModule,
  ],
  exports: [
    ConversationsHeaderComponent,
    ConversationPickerComponent,
    ConversationsComponent,
    ConversationListComponent,
    ConversationPictureComponent,
    SearchResultComponent,
    NewMessageCreateComponent,
    CreateNewConversationButtonComponent,
  ],
})
export class ConversationsModule {}
