import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PatientService } from "../../core";

@Component({
  selector: "app-patientid-lookup",
  templateUrl: "./patientid-lookup.component.html",
  styleUrls: ["./patientid-lookup.component.scss"],
})
export class PatientIdLookupComponent implements OnInit {
  data: any[];
  loading = true;
  selectedIndex = undefined;
  error: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public input: any,
    private ref: MatDialogRef<PatientIdLookupComponent>,
    private patientService: PatientService
  ) {}

  ngOnInit() {
    this.getData(this.input.query);
  }

  onNoClick(): void {
    this.ref.close();
  }

  getData(id) {
    const i = this;
    this.patientService.patientIdLookup(id, function (resp) {
      i.loading = false;
      if (!resp) {
        i.data = [];
        i.error = "Sorry, this lookup is not valid.";
      } else {
        i.data = resp;
        if (i.data.length == 0) {
          i.error = "Could not find details for " + id;
          i.ref.close({ success: false });
        }
      }
    });
  }

  onConfirm() {
    this.data[this.selectedIndex]["success"] = true;
    this.ref.close(this.data[this.selectedIndex]);
  }
}
