import { Injectable } from "@angular/core";

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class MiscService {
  constructor() {}

  scrollTo(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      // this.enableScrollDown=false;
    }, 100);
  }

  getParamsFromUrl(urlString: string, paramName: string) {
    let url = new URL(urlString);
    return url.searchParams.get(paramName);
  }
}
