import { Component, Inject, Input, OnInit, Optional } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { AlertComponent } from "@modules/core/components";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  BasicUserPickerData,
  BasicUserPickerResult,
  BasicUserPickerService,
  SelectionList,
  UserPickerState,
  UserSelectionListId,
} from "../basic-user-picker.service";
import { NetworkUserPickerData } from "../network-user-picker.service";
import { UserSelectionList } from "./../../shared/basic-user-selection-list/basic-user-selection-list.component";
import { NetworkUserPickerService } from "./../network-user-picker.service";

@Component({
  selector: "app-basic-user-picker",
  templateUrl: "./basic-user-picker.component.html",
  styleUrls: ["./basic-user-picker.component.scss"],
  providers: [BasicUserPickerService],
})
export class BasicUserPickerComponent implements OnInit {
  @Input() public showAlternateRightHeader: boolean = false;
  @Input() public showAlternateRightContent: boolean = false;
  @Input() public showAlternateRightFooter: boolean = false;
  @Input() public hideSearch: boolean = false;
  @Input() public showDisabledSelectedUsers: boolean = false;
  @Input() public multiple: boolean = true;

  public header$: Observable<string> | null = null;
  public subheader?: string | null = "";
  public searchPlaceholder?: string | null = null;
  public submitButtonText: string = "Submit";
  public cancelButtonText: string = "Cancel";
  public isLoading: boolean = true;
  public selectedHeader: string = "Participants";
  public selectedQuantityLabels: {
    zero: string;
    one: string;
    plural: string;
  } = {
    zero: "Participants",
    one: "Participant",
    plural: "Participants",
  };

  public isInitializing: boolean = true;

  public userGroups$: Observable<SelectionList[]> | null = null;
  public searchResults$: Observable<SelectionList[]> | null = null;
  public selectedUsers$: Observable<UserSelectionList> | null = null;

  public UserPickerState = UserPickerState;
  public state$: Observable<UserPickerState> = of(
    UserPickerState.SEARCH_LOADING
  );

  public hideRightContent: boolean = false;

  public constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: BasicUserPickerData,
    private matDialogRef: MatDialogRef<
      BasicUserPickerComponent,
      BasicUserPickerResult
    >,
    @Optional() private networkUserPickerService: NetworkUserPickerService,
    private userPickerService: BasicUserPickerService,
    private matDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    const {
      subheader,
      searchPlaceholder,
      submitButtonText,
      cancelButtonText,
      selectedHeader,
      selectedQuantityLabels,
    } = this.dialogData;

    this.header$ = this.userPickerService.header$;
    this.subheader = subheader ?? null;
    this.searchPlaceholder = searchPlaceholder ?? null;
    this.submitButtonText = submitButtonText ?? this.submitButtonText;
    this.cancelButtonText = cancelButtonText ?? this.cancelButtonText;
    this.selectedHeader = selectedHeader ?? this.selectedHeader;
    this.selectedQuantityLabels =
      selectedQuantityLabels ?? this.selectedQuantityLabels;

    this.state$ = this.userPickerService.state$.pipe(
      tap((state) => {
        if (state == UserPickerState.SEARCH_LOADING) return;
        this.isInitializing = false;
      })
    );

    this.userGroups$ = this.userPickerService.userGroups$.pipe(
      map((groups) =>
        groups.filter((group) => group.id !== UserSelectionListId.SUGGESTIONS)
      )
    );
    this.searchResults$ = this.userPickerService.searchResults$;

    this.selectedUsers$ = this.userPickerService.selectedUsers$.pipe(
      map((users) =>
        users.filter(
          (user) => this.showDisabledSelectedUsers || !user.isDisabled
        )
      )
    );

    this.userPickerService.submit$.subscribe({
      next: ({ isSubmitted, error }) => {
        if (error) {
          AlertComponent.openErrorDialog(this.matDialog);
          return;
        }
        if (!isSubmitted) return;
        this.matDialogRef.close({ isSubmitted });
      },
    });

    this.userPickerService.initialiseFromDialogData(this.dialogData);

    if (this.isBasicNetworkUserPickerData(this.dialogData)) {
      this.hideRightContent = this.dialogData.asTeamId != null;
      this.networkUserPickerService?.initialise(
        this.userPickerService,
        this.dialogData
      );
    }
  }

  private isBasicNetworkUserPickerData(
    data: BasicUserPickerData
  ): data is NetworkUserPickerData {
    return data.variant === "network-user-picker";
  }
}
