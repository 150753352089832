import {
  ConversationType,
  CreatedByEnum,
  ExportPhotoRequest,
  MediaType,
  MessageStatuses,
  ParticipantRole,
  PinnedMode,
  TeamMemberRole,
} from "./api-v2";

export interface ConversationRoleUpdate {
  userId: string;
  role: ParticipantRole;
}

export interface PagedRequestOptions {
  page?: number;
  pageSize?: number;
}

export interface TokenPagedRequestOptions {
  token?: string;
  pageSize?: number;
}

export interface ApiRequestOptions {
  /** If true, all pages will fetched. This can result in multiple requests being made. */
  fetchAll?: boolean;
}

export interface ConversationsV3RequestOptions extends PagedRequestOptions {
  before?: string;
  after?: string;
  since?: string;
  count?: number;
  search?: string;
  generalOnly?: boolean;
  filterByNhi?: string;
  sortBy?: string;
  teamId?: string;
  isExternal?: boolean;
  pinnedMode?: PinnedMode;
}

export interface MessagesV2RequestOptions extends PagedRequestOptions {
  conversationId: string;
  marker?: string;
  before?: string;
  after?: string;
  count?: number;
  search?: string;
  generalOnly?: boolean;
  filterByNhi?: string;
  sortBy?: string;
}

export interface ContactsRequestOptions extends PagedRequestOptions {
  before?: string;
  after?: string;
  since?: string;
  count?: number;
  search?: string;
  generalOnly?: boolean;
  filterByNhi?: string;
  sortBy?: string;
  teamId?: string;
}

export interface ContactsV1RequestOptions extends PagedRequestOptions {
  title?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  department?: string;
  fullName?: string;
  since?: string;
  position?: string;
  phoneNumber?: string;
  isOnCall?: string;
  includeSelf?: boolean;
  companyId?: string;
}

export interface CompanyContactsRequestOptions
  extends ContactsV1RequestOptions {}

export interface GetCompanyContactsRequestOptions
  extends CompanyContactsRequestOptions {
  companyId: string;
}

export interface GetCompaniesRequestOptions extends PagedRequestOptions {
  emailDomain?: string;
}

export interface ContactsV2RequestOptions extends PagedRequestOptions {
  search?: string;
}

export interface UsersV2RequestOptions extends PagedRequestOptions {
  search?: string;
}

export interface NofificationSeenStatusModel {
  activityFeedSeen: true | false;
  connectionRequestSeen: true | false;
}

export interface TeamsRequestOptions extends TokenPagedRequestOptions {
  teamId?: string;
  search?: string;
  isActive?: boolean;
}

export interface WorkspaceTeamsRequestOptions extends TeamsRequestOptions {
  workspaceId: string;
}

export interface UserTeamsRequestOptions extends TeamsRequestOptions {}

export interface PatchTeamRequestOptions {
  teamId: string;
  name?: string;
  description?: string | null;
}

export interface UpdateTeamMemberRoleItem {
  userId: string;
  role: TeamMemberRole;
}

export interface UpdateTeamMemberRolesRequestOptions {
  teamId: string;
  updates: UpdateTeamMemberRoleItem[];
}

export interface AddOrRemoveTeamMembersRequestOptions {
  teamId: string;
  userIds: string[];
}

export interface CreateTeamRequestOptions {
  workspaceId: string;
  name: string;
  description: string;
}

export type CreateConversationParticipant =
  | { userId: string }
  | { teamId: string };

export interface CreateConversationRequestOptions {
  id: string;
  type: ConversationType;
  participants: CreateConversationParticipant;
}

export enum PictureSize {
  Small = "Small",
  Medium = "Medium",
  Full = "Full",
}

export interface GetUserPictureRequestOptions {
  userId: string;
  width?: number;
  height?: number;
  size?: PictureSize;
}

export interface GetWorkspacePictureRequestOptions {
  workspaceId: string;
  size?: PictureSize;
}

export interface GetSuggestionsRequestOptions {
  includeChat?: boolean;
  includeConnections?: boolean;
  includeDepartment?: boolean;
  includeColleagues?: boolean;
  includeRandom?: boolean;
}

export interface GetTeamRequestOptions {
  teamId: string;
  ignoreCache?: boolean;
  includeUnreadConversationIds?: boolean;
  includePartnerWorkspaceIds?: boolean;
}

export interface ActionInvitationRequestOptions {
  invitationId: string;
  isConfirmed?: boolean;
}

export interface GetUserWorkspacesOptions extends PagedRequestOptions {
  includePartners?: boolean;
}

export interface GetMediaOptions extends PagedRequestOptions {
  includeTypes?: MediaType[];
  mediaIds?: string[];
  from?: string;
  search?: string;
  patientId?: string;
  includeAllPatientFiles?: boolean;
  patientIdIncludeGeneral?: boolean;
  createdBy?: CreatedByEnum;
}

export interface ExportMediaOptions {
  mediaId: string;
  request: ExportPhotoRequest;
}

export interface ChangePasswordOptions {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface GetUnreadConversationRequestOptions {
  conversationTypes?: ConversationType[];
  isNonTeamParticipant?: boolean;
}

export interface MessageStatusUpdate {
  /** This is the legacy message ID, not the marker. */
  MessageId: number;
  Status: MessageStatuses.Delivered | MessageStatuses.Read;
}

export interface GetUnreadSkeletonConversationRequestOptions
  extends PagedRequestOptions {
  conversationTypeFilters?: ConversationType[];
}
