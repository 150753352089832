import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { environment } from "@env";
import { AnalyticsService, SharedService } from "@modules/core";
import * as XRegExp from "xregexp";

@Component({
  selector: "app-profile-create",
  templateUrl: "./profile-create.component.html",
  styleUrls: [
    "./profile-create.component.scss",
    "../onboard.scss",
    "../../../celo-input.scss",
  ],
})
export class ProfileCreateComponent implements OnInit {
  preferredName = "";
  title = "";
  firstName = "";
  lastName = "";
  titles = [];
  verificationId: string;
  email: string;
  countryCode: string;
  countryIsAvailable: string;
  token: string;
  change_sub: any;
  countryName: string;

  regex = XRegExp("^[\\p{L} ,.'-]*$");
  myForm: UntypedFormGroup;
  dataRegionId: string;

  constructor(
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.getTitles();
    this.route.queryParamMap.subscribe((paramMap) => {
      this.verificationId = paramMap.get("verification_id");
      this.email = paramMap.get("email");
      this.token = paramMap.get("token");
      this.countryCode = paramMap.get("country_code");
      this.countryName = paramMap.get("country_name");
      this.countryIsAvailable = paramMap.get("country_is_available");
      this.dataRegionId = paramMap.get("data_region_id");
    });

    let group = {};
    group = {
      titleField: { value: "", disabled: false },
      preferredNameField: { value: "", disabled: false, required: true },
      firstNameField: { value: "", disabled: false, required: true },
      lastNameField: { value: "", disabled: false, required: true },
    };
    this.myForm = this.formBuilder.group(group);

    this.analyticsService.raiseEvent("celo_page_view", {
      flow: "signing_up",
      page_name: "profile_page",
    });
  }

  onSubmit() {
    if (!this.countryIsAvailable || this.countryIsAvailable != "true") {
      this.registerInterest();
      return;
    }
    this.sharedService.navigateTo("/onboard/password-set", {
      title: this.title,
      firstName: this.firstName,
      lastName: this.lastName,
      legalFirstName: this.firstName,
      verification_id: this.verificationId,
      email: this.email,
      country_code: this.countryCode,
      country_is_available: this.countryIsAvailable,
      data_region_id: this.dataRegionId,
      token: this.token,
    });
  }

  registerInterest() {
    const body = {
      email: this.email,
      title: this.title,
      firstName: this.firstName,
      lastName: this.lastName,
      legalFirstName: this.firstName,
      countryCode: this.countryCode,
      countryName: this.countryName,
      token: this.token,
      VerificationId: this.verificationId,
    };
    const path = environment.celoApiEndpoint + "/api/Onboard/InterestedUser";
    this.sharedService.postObjectById(path, {}, body).subscribe(
      (response) => {},
      (err) => {}
    );
    this.analyticsService.raiseEvent("register_user_interest", {
      flow: "signing_up",
      selected_country: this.countryCode,
      data_region: this.dataRegionId,
    });
    this.sharedService.navigateTo("/onboard/country-unsupported", {
      country_name: this.countryName,
    });
  }

  getTitles() {
    const instance = this;
    this.sharedService.getTitles(function (titles) {
      if (titles) {
        instance.titles = titles;
      }
    });
  }
}
