import { ConversationType, MessageModel, PatientDataModel } from "./api-v2";
import { VideoCallStatus } from "./video-api";

export interface MessageUpdate extends MessageModel {}

export type NotificationPayloadType = "WorkspaceNewMember" | "TeamOnOffCall";

export interface NotificationPayload<T = unknown> {
  id: string;
  userId: string;
  title: string;
  text: string;
  type: NotificationPayloadType;
  sender: {
    id: string;
    profilePic: string;
    firstName: string;
    fullName: string;
  };
  resource: {
    id: string;
    name: string;
    metadata: T;
  };
}

export interface PinnedConversationUpdate {
  pinnedOnUtc: string | null;
  updatedOnUtc: string;
  conversationId: string;
  userId: string;
}

export interface ConversationProfilePhotoUpdate {
  conversationId: string;
  photoId: string;
}

export enum ConversationUpdateAction {
  Full = "Full",
  Data = "Data",
  Participants = "Participants",
}

export interface ConversationUpdate {
  id: string;
  name: string;
  type: ConversationType;
  /** @format date-time */
  lastModifiedOnUtc: string;
  patientData?: PatientDataModel | null;
  action: ConversationUpdateAction;
}

export interface CaseExportEntry {
  exportId: string;
  conversationId: string;
  operationId: string;
  exportedAt: string;
  exportedBy: string;
}

export interface VideoCallUpdate {
  /** Video call ID */
  id?: string | null;
  conversationId?: string | null;
  createdBy?: string | null;
  createdOn?: string | null;
  status?: VideoCallStatus | null;
  maxCallLength?: number | null;
  /** Max ring length in seconds */
  maxRingLength?: number | null;
  startedOn?: string | null;
  endedOn?: string | null;
}

export interface DoNotDisturbUpdate {
  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  userId: string;
  updatedOnUtc: string;
  type: "DoNotDisturb";
}

export interface ConversationMuteUpdate {
  userId: string;
  conversationId: string;
  muteToUtc: string;
  muteInterval: number;
}
