<button mat-button class="group" (click)="handleGroupClick()">
  <div>
    <mat-icon svgIcon="new-group"></mat-icon>
    <span>New Group</span>
  </div>
</button>

<button mat-button class="case" (click)="handleCaseClick()">
  <div>
    <mat-icon svgIcon="new-case"></mat-icon>
    <span>New Case</span>
  </div>
</button>
