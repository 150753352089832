/*
 * API Version 1
 */

import {
  CompanyBasicModel,
  CompanyModel,
  ConnectionModel,
  ConnectionUserModel,
  CreatorModel,
  DepartmentModel,
  Gender,
  IdentityVerificationStatus,
  MessageStatuses,
  ParticipantRole,
  PatientConsentModel,
  PatientDataModel,
  PhotoEditorsHistoryModel,
  ProfessionVerificationStatus,
  UserFeatureModel,
  UserProfileInvitationModel,
  WorkplaceModel,
} from "./api-v2";

export interface UserProfessionModelV1 {
  id?: string | null;
  verificationStatus?: ProfessionVerificationStatus;
  category?: string | null;
  profession?: string | null;
  registrationName?: string | null;
  registrationNumber?: string | null;
  registrationLink?: string | null;
}

export interface ViewMyProfileModel {
  userId?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  identityVerificationStatus?: IdentityVerificationStatus;
  isPhoneNumberConfirmed?: boolean;
  picture?: string | null;
  beenLoggedIn?: string | null;
  isOnCall?: string | null;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  countryCode?: string | null;
  features?: UserFeatureModel;
  invitation?: UserProfileInvitationModel;
  isWorkspaceNotificationEnabled?: boolean;
  company?: CompanyModel;
  department?: DepartmentModel;
  position?: string | null;
  phoneNumberVerificationAction?: string | null;
  workplaces?: WorkplaceModel[] | null;
  professions?: UserProfessionModelV1[] | null;
}

export interface OperationV1 {
  value?: any;
  path?: string | null;
  op?: string | null;
  from?: string | null;
}

export enum PrivacyType {
  Phonenumber = "phonenumber",
  Email = "email",
}

export enum PrivacyLevel {
  Self = "Self",
  Department = "Department",
  Company = "Company",
  Partner = "Partner",
  All = "All",
}

export interface PrivacyModel {
  type?: PrivacyType;
  level?: PrivacyLevel;
}

export interface ViewMyPrivacyModel {
  privacySettings?: PrivacyModel[] | null;
}

export interface UpdatePrivacyModel {
  userPrivacy?: PrivacyModel[] | null;
}

export interface CreatePasswordModel {
  /** @format email */
  email: string;
  newPassword: string;
  confirmPassword: string;
  token: string;
  termsUrl?: string | null;
  dataRegionId?: string | null;
}

export interface DecodeTokenModel {
  token?: string | null;
  dataRegionId?: string | null;
}

export interface ResetPasswordModel {
  /** @format email */
  email: string;
  isResetSuccessed?: boolean;
}

export interface ChangePasswordModel {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface PhoneVerificationRequest {
  countryCode?: string | null;
  phoneNumber?: string | null;
}

export interface PhoneCheckRequest {
  code?: string | null;
  countryCode?: string | null;
  phoneNumber?: string | null;
}

export interface SearchDomainModel {
  domain?: string | null;
  email?: string | null;
  found?: boolean;
  isEnterprise?: boolean;
}

export interface RegisterCompanyModel {
  companyName: string;
  position: string;
  department: string;
  firstName?: string | null;
  lastName: string;
  legalFirstName: string;
  phoneNumber?: string | null;

  /** @format email */
  email: string;
  title?: string | null;
  country: string;
  region: string;
  available?: string[] | null;
  comingSoon?: string[] | null;
  titles?: string[] | null;
  id?: string | null;
}

export interface RegistrationCheckRequest {
  /** @format email */
  email: string;
}

export interface CreateWorkplaceModelV1 {
  /** @format email */
  email: string;
  verificationId: string;
  companyId: string;
  departmentId?: string | null;
  position?: string | null;
}

export interface EmailVerificationRequest {
  /** @format email */
  email: string;
}

export interface EmailVerificationResponse {
  verificationId?: string | null;
}

export interface EmailVerificationCheck {
  /** @format email */
  email: string;
  code: string;
  verificationId: string;
  updatePrimaryEmail?: boolean;
}

export interface UserDoNotDisturbModel {
  /** @format int32 */
  doNotDisturbInterval?: number;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  userId?: string | null;
}

export interface UpdateUserConnectionSeenStatusModel {
  /** @format date-time */
  seenOnUtc: string;
}

export interface ViewMyConnectionStatusModel {
  connectionRequestSeen?: boolean;
}

export interface UserBlockContactModel {
  blockId?: string | null;
  isBlocked?: boolean;

  /** @format date-time */
  lastModifiedOnUtc?: string;
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  userUri?: string | null;
  workplaces?: WorkplaceModel[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;
  professions?: UserProfessionModelV1[] | null;
}

export interface UserBlocklistModel {
  userId?: string | null;
  blockedByMe?: UserBlockContactModel[] | null;
}

export interface BlockUserModel {
  userId: string;
  isBlocked?: boolean;
}

export interface UserBlockUpdatedModel {
  id?: string | null;
  userId?: string | null;
  blockerId?: string | null;

  /** @format date-time */
  updatedOnUtc?: string;
  isBlocked?: boolean;
}

export interface CompanyModelPagedResult {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: CompanyModel[] | null;

  /** @format date-time */
  pageProcessedOn?: string;
}

export interface UserBlockModelV1 {
  id: string;
  isBlocked?: boolean;

  /** @format date-time */
  lastModifiedOnUtc?: string;
}

export interface ContactModel {
  companyName?: string | null;
  companyId?: string | null;
  department?: string | null;
  departmentId?: string | null;
  position?: string | null;
  isTrimmed?: boolean;
  phoneNumber?: string | null;
  email?: string | null;
  connection?: ConnectionModel;
  blockedByMe?: UserBlockModelV1;
  blockedMe?: UserBlockModelV1;
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  isOnCall?: string | null;
  professions?: UserProfessionModelV1[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  workplaces?: WorkplaceModel[] | null;
}

export interface ContactModelPagedResult {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: ContactModel[] | null;

  /** @format date-time */
  pageProcessedOn?: string;
}

export interface DepartmentModelPagedResult {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: DepartmentModel[] | null;

  /** @format date-time */
  pageProcessedOn?: string;
}

export interface CreateConnectionModel {
  userId?: string | null;
}

export interface ConnectionUserModelPagedResult {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: ConnectionUserModel[] | null;

  /** @format date-time */
  pageProcessedOn?: string;
}

export interface PatientConsentModelPagedResult {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: PatientConsentModel[] | null;

  /** @format date-time */
  pageProcessedOn?: string;
}

export interface FullContactModel {
  companyName?: string | null;
  companyId?: string | null;
  department?: string | null;
  departmentId?: string | null;
  position?: string | null;
  isTrimmed?: boolean;
  phoneNumber?: string | null;
  email?: string | null;
  connection?: ConnectionModel;
  blockedByMe?: UserBlockModelV1;
  blockedMe?: UserBlockModelV1;
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  isOnCall?: string | null;
  professions?: UserProfessionModelV1[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  workplaces?: WorkplaceModel[] | null;
}

export interface FullContactModelPagedResult {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: FullContactModel[] | null;

  /** @format date-time */
  pageProcessedOn?: string;
}

export interface AddRemoveParticipantModel {
  userId: string;
}

export interface UpdateParticipantRoleModel {
  userId: string;
  role: ParticipantRole;
}

export interface CreateMessagePhotoModel {
  photoId?: string | null;
  content?: string | null;
  marker?: string | null;

  /** @format date-time */
  createdOnUtc?: string | null;
  replyTo?: string | null;
  allowDelivery?: boolean;
}

export interface CreateMessagePatientFileModel {
  fileId?: string | null;
  content?: string | null;
  marker?: string | null;

  /** @format date-time */
  createdOnUtc?: string | null;
  replyTo?: string | null;
  allowDelivery?: boolean;

  fromConversationId?: string | null;
  fromMessageId?: string | null;
}

export interface UpdateMessageStatusModel {
  /** @format int64 */
  messageId?: number;
  status: MessageStatuses;
}

export interface SyncMessagesModel {
  messageIds?: number[] | null;

  /** @format date-time */
  from?: string;

  /** @format date-time */
  to?: string;
}

export interface SynchronizeMessageModel {
  /** @format int64 */
  id?: number;

  /** @format int32 */
  statusCount?: number;
}

export interface SynchronizeMessagesModel {
  messages?: SynchronizeMessageModel[] | null;

  /** @format date-time */
  from?: string;

  /** @format date-time */
  to?: string | null;
}

export interface MuteConversationModel {
  conversationId?: string | null;

  /** @format int32 */
  muteInterval?: number;

  /** @format date-time */
  mutedToUtc?: string | null;
  userId?: string | null;
}

export interface ClearUnreadConversationModel {
  conversationId?: string | null;

  /** @format date-time */
  asReadToUtc?: string | null;
}

export interface ViewMyWorkplaceIntegrationModel {
  company?: CompanyBasicModel;
  id?: string | null;
  name: string;
  description?: string | null;
  type?: string | null;
}

export interface IntegrationServiceConfirmationModel {
  title?: string | null;
  content?: string | null;
}

export interface IntegrationServiceModel {
  name?: string | null;
  code?: string | null;
  confirmation?: IntegrationServiceConfirmationModel | null;
}

export interface ViewMyWorkplaceIntegrationDetailModel
  extends ViewMyWorkplaceIntegrationModel {
  services?: IntegrationServiceModel[] | null;
}

export enum NotificationStatus {
  Sent = "Sent",
  Delivered = "Delivered",
}

export enum NotificationType {
  Push = "Push",
  PushPull = "PushPull",
  RemoteWipe = "RemoteWipe",
  UserConnectionEvent = "UserConnectionEvent",
  WorkspaceNewMember = "WorkspaceNewMember",
}

export interface UpdateNotificationModel {
  uid?: string | null;

  /** @format int64 */
  messageId?: number | null;
  conversationId?: string | null;

  /** @format date-time */
  deliveredOnUtc?: string | null;
  type?: NotificationType;
}

export interface EmailRequest {
  email?: string | null;
}

export interface EmailVerificationCode {
  email?: string | null;
  code?: string | null;
  verificationId?: string | null;
  updatePrimaryEmail?: boolean;
}

export interface RegisterUserModel {
  password?: string | null;
  title?: string | null;
  legalFirstName?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  token?: string | null;
  countryCode?: string | null;
}

export interface RegistrationResponse {
  userId?: string | null;
  tempAuthRegionId?: string | null;
}

export interface InterestedUserModel {
  title?: string | null;
  legalFirstName?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  token?: string | null;
  countryCode?: string | null;
}

export interface AddWorkplaceModel {
  email?: string | null;
  verificationId?: string | null;
  companyId?: string | null;
  departmentId?: string | null;
  position?: string | null;
}

export interface PatientFileModel {
  id?: string | null;
  patientData?: PatientDataModel;
  consentId?: string | null;
  url?: string | null;
  fileName?: string | null;
  fileDescription?: string | null;

  /** @format date-time */
  createdOn?: string;
  creator?: CreatorModel;
}

export interface PatientFileModelPagedResult {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: PatientFileModel[] | null;

  /** @format date-time */
  pageProcessedOn?: string;
}

export interface UpdatePatientFileModel {
  patientData?: PatientDataModel;
  fileDescription?: string | null;
  fileNameAlias?: string | null;
  consentId?: string | null;
}

export interface PatientFileEditorsHistoryModel {
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  fileName?: string | null;
  fileDescription?: string | null;

  /** @format date-time */
  timestamp?: string;
}

export interface PatientFileDetailsModel {
  id?: string | null;
  patientData?: PatientDataModel;
  consent?: PatientConsentModel;
  url?: string | null;
  fileName?: string | null;
  fileDescription?: string | null;

  /** @format int64 */
  fileSize?: number;

  /** @format date-time */
  createdOn?: string;
  creator?: CreatorModel;
  modifiedBy?: PatientFileEditorsHistoryModel[] | null;
}

export interface SourceModel {
  id?: string | null;
  name?: string | null;
}

export interface PatientDataSourceModel {
  source?: SourceModel;
  uid?: string | null;
  firstName?: string | null;
  lastName?: string | null;

  /** @format date-time */
  dateOfBirth?: string | null;
  gender?: Gender;
}

export interface PatientPhotoModel {
  id?: string | null;
  patientData?: PatientDataModel;
  consentId?: string | null;
  url?: string | null;

  /** @format date-time */
  createdOn?: string;
  creator?: CreatorModel;
  hasOverlay?: boolean;
  isImported?: boolean;
  isDeleted?: boolean;

  /** @format date-time */
  deletedOnUtc?: string | null;

  /** @format date-time */
  sharedOnUtc?: string | null;
  fileName?: string | null;
  description?: string | null;
}

export interface PatientPhotoModelPagedResult {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: PatientPhotoModel[] | null;

  /** @format date-time */
  pageProcessedOn?: string;
}

export interface PatientPhotoDetailsModel {
  id?: string | null;
  patientData?: PatientDataModel;
  consent?: PatientConsentModel;
  hasOverlay?: boolean;
  isImported?: boolean;
  url?: string | null;
  shareUrl?: string | null;
  description?: string | null;

  /** @format date-time */
  createdOn?: string;
  creator?: CreatorModel;
  modifiedBy?: PhotoEditorsHistoryModel[] | null;
}

export interface FeatureSettings {
  name?: string | null;
  enabled?: boolean;
  settings?: Record<string, string>;
}

export interface PasswordSettings {
  hintMessage?: string | null;
}

export interface CountrySettings {
  name?: string | null;
  shortName?: string | null;
  code?: string | null;
  isAvailable?: boolean;
  callingPrefix?: string | null;
  dataRegionId?: string | null;
}

export interface ClientAppSettings {
  /** @format int32 */
  inactivityPeriod?: number;

  /** @format int32 */
  lockoutPeriod?: number;
  feedbackEmail?: string | null;

  /** @format int32 */
  consentFormValidDays?: number;
  features?: FeatureSettings[] | null;
  passwordSettings?: PasswordSettings;

  /** @format int32 */
  phoneMatchingBatchSize?: number;
  supportedCountries?: CountrySettings[] | null;
}

export interface CountryModel {
  name?: string | null;
  shortName?: string | null;
  code?: string | null;
  isAvailable?: boolean;
  callingPrefix?: string | null;
  dataRegionId?: string | null;
}

export interface ProfessionCategoryModel {
  name?: string | null;

  /** @format int32 */
  displayOrder?: number;
  allowFreeformProfession?: boolean;
  canVerify?: boolean;
}

export interface NhiModel {
  uid?: string | null;
}

export enum ProfilePictureSizeV1 {
  Full = "Full",
  Small = "Small",
  Medium = "Medium",
}

export interface UserPresenceModel {
  userId?: string | null;

  /** @format date-time */
  updatedOnUtc?: string;
}

export enum UserDevicePlatform {
  Unknown = "Unknown",
  Android = "Android",
  IOS = "iOS",
}

export interface CreateUserDeviceModel {
  platform?: UserDevicePlatform;
  token?: string | null;
  osVersion?: string | null;
  appVersion?: string | null;
  deviceModel?: string | null;
}

export interface UserDeviceModel {
  id?: string | null;
  platform?: UserDevicePlatform;
  token?: string | null;
  hasPin?: boolean;
  osVersion?: string | null;
  appVersion?: string | null;
}

export interface UpdateUserDeviceModel {
  token?: string | null;
  osVersion?: string | null;
  appVersion?: string | null;
  deviceModel?: string | null;
}

export interface CreateUserDevicePinModel {
  pin: string;
}

export interface ParticipantModel {
  company?: string | null;
  companyName?: string | null;
  companyId?: string | null;
  department?: string | null;
  departmentId?: string | null;
  position?: string | null;
  isTrimmed?: boolean;
  profilePicture?: string | null;
  phoneNumber?: string | null;
  email?: string | null;
  connection?: ConnectionModel;
  blockedByMe?: UserBlockModelV1;
  blockedMe?: UserBlockModelV1;
  userId?: string | null;
  profilePic?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  legalFirstName?: string | null;
  isOnCall?: string | null;
  professions?: UserProfessionModelV1[] | null;
  identityVerificationStatus?: IdentityVerificationStatus;

  /** @format date-time */
  doNotDisturbToUtc?: string | null;
  workplaces?: WorkplaceModel[] | null;

  /** @format date-time */
  leftOnUtc?: string | null;

  /** @format date-time */
  joinedOnUtc?: string | null;
  isActive?: boolean;
}

export interface CallModel {
  id?: string | null;
  createdBy?: string | null;
  type?: string | null;
  status?: string | null;

  /** @format date-time */
  createdOnUtc?: string;

  /** @format int32 */
  duration?: number | null;
  participants?: ParticipantModel[] | null;
}

export interface CallModelPagedResult {
  /** @format int32 */
  page?: number;

  /** @format int32 */
  pageSize?: number;

  /** @format int32 */
  total?: number;

  /** @format int32 */
  totalPages?: number;
  hasPrevious?: boolean;
  hasNext?: boolean;

  /** @format int32 */
  previousPage?: number | null;

  /** @format int32 */
  nextPage?: number | null;
  data?: CallModel[] | null;

  /** @format date-time */
  pageProcessedOn?: string;
}
