import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserActivityModel } from "@types";
import { SubscriptionContainer } from "@utils";
import { Observable, map } from "rxjs";
import { NotificationsService } from "../notifications.service";

@Component({
  selector: "app-activity-feed-list",
  templateUrl: "./activity-feed-list.component.html",
  styleUrls: ["./activity-feed-list.component.scss"],
})
export class ActivityFeedListComponent implements OnInit, OnDestroy {
  public activities$: Observable<UserActivityModel[] | null> = null;
  public idsOfFirstActivityPerDay$: Observable<Set<string> | null> = null;

  private subscriptions = new SubscriptionContainer();

  constructor(private notificationService: NotificationsService) {}

  public ngOnInit(): void {
    this.activities$ = this.notificationService.activities$;

    this.idsOfFirstActivityPerDay$ = this.notificationService.activities$.pipe(
      map((activities) => this.updateIdsOfFirstActivityPerDay(activities))
    );
  }

  /**
   * Activities are grouped by day. This updates a set which stores the id
   * of the first activity each day which is used to determine when a header
   * needs to be inserted into the list of activity feed items in the UI.
   */
  private updateIdsOfFirstActivityPerDay(
    activities: UserActivityModel[]
  ): Set<string> {
    const ids = new Set<string>();
    if (!activities) return ids;

    let prevDay: Date | null = null;
    for (const activity of activities) {
      const day = new Date(activity.timeStampUtc);
      if (
        !prevDay ||
        day.getDate() != prevDay.getDate() ||
        day.getMonth() != prevDay.getMonth() ||
        day.getFullYear() != prevDay.getFullYear()
      ) {
        ids.add(activity.id);
      }
      prevDay = day;
    }

    return ids;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
