import { Observable } from "rxjs";

export const createInterruptListenerObservable = (): Observable<void> => {
  const documentEvents: (keyof DocumentEventMap)[] = [
    "mousemove",
    "keydown",
    "mousedown",
    "touchstart",
    "touchmove",
    "scroll", // This event doesn't seem to trigger
  ];

  const windowEvents: (keyof WindowEventMap)[] = [
    "storage",
    "DOMContentLoaded",
  ];

  const observable = new Observable<void>((subscriber) => {
    const listener = () => {
      subscriber.next();
    };

    documentEvents.forEach((eventType) => {
      document.addEventListener(eventType, listener);
    });

    windowEvents.forEach((eventType) => {
      window.addEventListener(eventType, listener);
    });

    return () => {
      documentEvents.forEach((eventType) => {
        document.removeEventListener(eventType, listener);
      });

      windowEvents.forEach((eventType) => {
        window.removeEventListener(eventType, listener);
      });
    };
  });

  return observable;
};
