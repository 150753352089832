import { Component, Input, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { TeamMember } from "types";
import { RoleDetailsService } from "./../role-details/role-details.service";

export enum RoleMemberPopupMenuActionType {
  SecureMessage,
  ViewProfile,
  MakeRoleAdmin,
  RemoveRoleAdmin,
  RemoveFromRole,
}

export interface RoleMemberPopupMenuAction {
  member: TeamMember;
  type: RoleMemberPopupMenuActionType;
}

@Component({
  selector: "app-role-member-popup-menu",
  templateUrl: "./role-member-popup-menu.component.html",
  styleUrls: ["./role-member-popup-menu.component.scss"],
})
export class RoleMemberPopupMenuComponent {
  @Input() public member: TeamMember | null = null;
  @Input() public isAdmin: boolean = false;
  @Input() public isCurrentUserAdmin: boolean = false;
  @Input() public disabled: boolean = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | null = null;

  public menuX = 0;
  public menuY = 0;

  public constructor(private roleDetailsService: RoleDetailsService) {}

  public onParticipantClick(mouseEvent: MouseEvent): void {
    this.menuX = mouseEvent.offsetX;
    this.menuY = mouseEvent.offsetY + 10;

    this.trigger?.menuClosed.subscribe(() => {
      this.menuX = 0;
      this.menuY = 0;
    });

    this.trigger?.openMenu();
  }

  public emitActionType(type: RoleMemberPopupMenuActionType) {
    if (!this.member) return;
    this.roleDetailsService.emitPopupMenuAction({ member: this.member, type });
  }

  public onSecureMessage(): void {
    this.emitActionType(RoleMemberPopupMenuActionType.SecureMessage);
  }

  public onViewProfileClick(): void {
    this.emitActionType(RoleMemberPopupMenuActionType.ViewProfile);
  }

  public onMakeAdminClick(): void {
    this.emitActionType(RoleMemberPopupMenuActionType.MakeRoleAdmin);
  }

  public onRemoveAdminClick(): void {
    this.emitActionType(RoleMemberPopupMenuActionType.RemoveRoleAdmin);
  }

  public onRemovePartipantClick(): void {
    this.emitActionType(RoleMemberPopupMenuActionType.RemoveFromRole);
  }
}
