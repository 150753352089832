<div [hidden]="(state$ | async) !== UserPickerState.DEFAULT">
  <div class="mat-body-strong subheader">
    {{ subheader }}
  </div>

  <app-user-picker-grouped-selection-lists
    [stickyGroupHeaders]="true"
    [groups]="userGroups$ | async"
  ></app-user-picker-grouped-selection-lists>
</div>
