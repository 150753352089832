import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MatOptionSelectionChange } from "@angular/material/core";
import { Observable, Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
  tap,
} from "rxjs/operators";
import { AlertService, MiscService, UserService } from "@modules/core";

export interface ContactGroup {
  departmentName: string;
  departmentContacts: any[];
}

export interface SelectedContacts {
  map: { [key: string]: any };
  list: any[];
}

@Component({
  selector: "app-celo-selected-contacts",
  templateUrl: "./celo-selected-contacts.component.html",
  styleUrls: ["./celo-selected-contacts.component.scss"],
})
export class CeloSelectedContactsComponent implements OnInit, OnDestroy {
  visible = true;
  selectable = true;
  @Input() removable = true;
  @Input() replaceMyName: string;
  @ViewChild("listScroll") private listScroll: ElementRef;
  selectedContacts: any[] = [];
  selectedContactsAsMap: { [key: string]: any } = {};

  contactsForm: UntypedFormGroup = this.fb.group({
    contactsGroup: "",
  });

  contactCtrl = this.contactsForm.get("contactsGroup");

  @Input() groupedContacts$: Observable<ContactGroup[]>;
  @Input() addContact$: Observable<any>;
  // @Input() addContactSelection$: Observable<any>;
  @Input() canRemoveContact$: Observable<any>;
  @Input() removeContact$: Subject<any>;
  @Input() title = "Contacts";
  @Input() isAdmin: boolean;
  // @Input() disabled: boolean;
  @Input() showAddParticipantsButton: boolean;
  @Input() empty_message = "No contacts selected";
  @Input() salutation = "participants";
  @Input() edit_mode = false;
  @Input() showMaxCount = false;
  @Output() search = new EventEmitter<string>();
  @Output() editParticipants = new EventEmitter();
  @Output() changeSelection = new EventEmitter<SelectedContacts>(); // user-id: any
  contactsGroupOptions: Observable<ContactGroup[]>;

  @ViewChild("contactInput") contactInput: ElementRef<HTMLInputElement>;
  @ViewChild("autoGroup") matAutocomplete: MatAutocomplete;
  modelChanged: Subject<number> = new Subject<number>();

  @Input() maxCapacity: number;
  @Output() capacityReached = new EventEmitter();
  loggedInUserId: string;
  @Input() selectedContactsLabelMap: { [key: string]: string } = {};
  @Input() canDeselect = false;
  addContactSub: any;
  removeContactSub: any;

  constructor(
    private fb: UntypedFormBuilder,
    public alertService: AlertService,
    public userService: UserService,
    public miscService: MiscService
  ) {
    this.modelChanged
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((list) => {
        let anchor = document.getElementById("bottom") as HTMLElement;
        this.scrollIntoView(anchor);
      });
  }

  ngOnInit() {
    this.loggedInUserId = this.userService.getUserId(true);
    this.contactsGroupOptions = this.contactsForm
      .get("contactsGroup")
      .valueChanges.pipe(
        startWith(""),
        tap((searchTerm) => {
          if (typeof searchTerm === "string") {
            this.search.emit(searchTerm);
          } else {
            this.search.emit("");
          }
        }),
        switchMap(() => this.groupedContacts$)
      );

    this.addContactSub = this.addContact$.subscribe((contact: any) => {
      this.add(contact);
    });
    this.removeContactSub = this.canRemoveContact$.subscribe((contact: any) => {
      let contactSelection = this.selectedContacts.find(
        (c) => c["userId"] === contact["userId"]
      );
      if (contactSelection.disableRemove) {
        return;
      }
      this.remove(contact);
    });
  }

  removeClick(contact) {}

  ngOnDestroy() {
    if (this.addContactSub) this.addContactSub.unsubscribe();
    if (this.removeContactSub) this.removeContactSub.unsubscribe();
  }

  checkAndRemove(contact: any) {
    this.removeContact$.next(contact);
  }

  add(contact: any) {
    if (this.maxCapacity && this.selectedContacts.length >= this.maxCapacity) {
      return this.capacityReached.emit();
    }
    this.onAdd();
    this.remove(contact);
    this.selectedContacts.push(contact);
    this.selectedContactsAsMap[contact.userId] = contact;
    this.emitSelectionChange();
  }

  remove(contact: any): void {
    this.selectedContacts = this.selectedContacts.filter(
      (c) => c.userId !== contact.userId
    );
    if (this.selectedContactsAsMap[contact.userId]) {
      delete this.selectedContactsAsMap[contact.userId];
      this.emitSelectionChange();
    }
    if (this.selectedContactsLabelMap[contact.userId]) {
      delete this.selectedContactsLabelMap[contact.userId];
    }
  }

  onAdd() {
    if (this.selectedContacts) {
      this.modelChanged.next(this.selectedContacts.length);
    }
  }

  scrollTo(el: any) {
    try {
      el.nativeElement.scrollTop = el.nativeElement.scrollHeight;
    } catch (err) {}
  }

  scrollIntoView(el: HTMLElement) {
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }

  isSelected(contact: any): boolean {
    return !!this.selectedContactsAsMap[contact.userId];
  }

  contactSelect(event: MatOptionSelectionChange, contact: any) {
    if (event.source.selected) {
      this.contactInput.nativeElement.value = "";
      this.contactCtrl.setValue(null);
      this.add(contact);
    }
  }

  emitSelectionChange() {
    this.changeSelection.emit({
      map: this.selectedContactsAsMap,
      list: this.selectedContacts,
    });
  }
}
