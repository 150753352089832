import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, Subscriber } from "rxjs";

@Injectable()
export class UrlHelperService {
  cache = {};
  constructor(private http: HttpClient) {}

  get(url: string): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      let objectUrl: string = null;

      let obs = this.http.get(url, { responseType: "blob" });
      if (this.cache[url]) {
        obs = of(this.cache[url]);
      }

      obs.subscribe((m) => {
        this.cache[url] = m;
        objectUrl = window.URL.createObjectURL(m);
        observer.next(objectUrl);
      });

      return () => {
        if (objectUrl) {
          URL.revokeObjectURL(objectUrl);
          objectUrl = null;
        }
      };
    });
  }
}
