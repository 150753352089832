import { Component } from "@angular/core";
import { UserAccountService } from "../../modules/core";

@Component({
  selector: "app-roles-panel",
  templateUrl: "./roles-panel.component.html",
  styleUrls: ["./roles-panel.component.scss"],
})
export class RolesPanelComponent {
  userAccount: any;

  constructor(private userAccountService: UserAccountService) {
    const instance = this;
    this.userAccountService.getUserAccount(false, function (data) {
      instance.userAccount = data;
    });
  }
}
