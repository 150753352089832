<ng-container
  *ngIf="{
    state: state$ | async,
    connections: connections$ | async,
    workspaces: workspaces$ | async,
    suggestions: suggestions$ | async
  } as data"
>
  <div [hidden]="data.state !== UserPickerState.DEFAULT">
    <ng-container *ngIf="this.isEmptyState; else nonEmptyState">
      <app-user-picker-no-suggestions></app-user-picker-no-suggestions>
    </ng-container>

    <ng-template #nonEmptyState>
      <div class="mb-40">
        <div id="my-network" class="subheader">
          <div class="mat-body-strong">My Network</div>

          <button
            mat-button
            [disableRipple]="true"
            class="celo-link"
            (click)="handleShowAll()"
            *ngIf="workspaceGroupsCount > collapsedWorkspaceCount"
          >
            Show {{ showAll ? "less" : "more" }}
          </button>
        </div>

        <app-user-picker-grouped-selection-lists
          [multiple]="multiple"
          [hideLabels]="!multiple"
          [stickyGroupHeaders]="true"
          [groups]="data.connections"
        ></app-user-picker-grouped-selection-lists>

        <app-user-picker-grouped-selection-lists
          [multiple]="multiple"
          [stickyGroupHeaders]="true"
          [groups]="data.workspaces"
          [maxItems]="showAll ? null : collapsedWorkspaceCount"
        ></app-user-picker-grouped-selection-lists>
      </div>

      <div *ngIf="(hideSuggestions$ | async) === false">
        <div class="subheader">
          <div class="mat-body-strong">Suggested</div>
          <small class="mat-small celo-grey">
            Search to find other people on the network
          </small>
        </div>

        <app-user-picker-grouped-selection-lists
          [multiple]="multiple"
          [stickyGroupHeaders]="true"
          [groups]="data.suggestions"
          [hideLabels]="!multiple"
        ></app-user-picker-grouped-selection-lists>
      </div>
    </ng-template>
  </div>
</ng-container>
