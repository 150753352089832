<!-- <div class="font-18">Consent Details</div> -->
<div class="my-4" *ngIf="consent.patientData">
  <div class="font-14">Patient Details</div>
  <app-edit-patient
    [patient]="consent.patientData"
    [canSave]="false"
    [editMode]="false"
    [alignment]="'vertical'"
  ></app-edit-patient>
</div>
<div *ngIf="!consent.patientData">This Consent has no Patient</div>
