import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AccountService } from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { ProfileService } from "app/modules/profile/profile.service";
import { Subject } from "rxjs";
import {
  CompanyBasicModel,
  ContactModel,
  FullUserProfileModel,
  SuggestedType,
  SuggestedUserModel,
  Team,
  VerificationStatus,
} from "types";
import { localeCompareIfNotNull, sorted } from "utils";
import {
  AlertService,
  AnalyticsService,
  SharedService,
  TeamsService,
} from "../../../core";
import {
  Contact,
  ContactGroup,
  ContactGroupState,
} from "../contacts-expansion-panel/contacts-expansion-panel.component";
import { PageType, SelectionLabel } from "../new-chat/new-chat.component";
import { concatNotNull } from "./../../../../../utils/string-utils";
import { DepartmentContactGroup } from "./../contacts-expansion-panel/contacts-expansion-panel.component";

export interface WorkspaceDepartmentContactGroup
  extends DepartmentContactGroup {
  departmentId: string;
  companyId: string;
  workspaceName: string;
  workspaceVerificationStatus: VerificationStatus;
}
export interface ContactSelection {
  contact: ContactModel;
  label: SelectionLabel;
}

@Component({
  selector: "app-search-result",
  templateUrl: "./search-result.component.html",
  styleUrls: ["./search-result.component.scss"],
})
export class SearchResultComponent implements OnInit, DoCheck, OnDestroy {
  searchTerm = "";
  @Input() searchNow$: Subject<any> = new Subject<any>();

  @Input() selectable = false;
  @Input() type = "";
  @Input() page_type = "";
  @Input() selectedContactsMap: { [key: string]: any } = {};
  @Input() canDeselect = false;
  @Input() selectAllContacts$: Subject<any> = new Subject<any>();
  @Input() userAccount: FullUserProfileModel = {};
  @Input() discoverable: boolean;
  @Input() isAddingParticipants = false;
  @Input() uneditableContactIds: Set<string> = new Set();
  @Input() hideConnections: boolean = false;
  @Input() hideNetwork: boolean = false;
  @Input() showUsersInWorkspaceIdOnly: string;
  @Input() myWorkspacesAndPartnerWorkspaceIds: string[];
  @Input() public workspaces: CompanyBasicModel[] | null = null;
  @Input() public excludeTeams: boolean = false;

  @Output() contactClick = new EventEmitter<ContactSelection>();
  @Output() contactSelect = new EventEmitter<ContactSelection>();
  @Output() contactRemove = new EventEmitter<Contact>();
  @Output() roleClick = new EventEmitter<Team>();

  public teamDescriptions: Map<string, string> = new Map();

  connections: ContactGroup;
  connectionContacts: Contact[];
  connectionsPageSize: number = 20;
  connectionsPage: number;
  connections_sub: any;
  totalConnections = 0;
  isLoadingConnections: boolean;
  connectionContactsIds: string[];

  network: ContactGroup;
  networkContacts: Contact[];
  networkPageSize: any = 20;
  networkPage: number = 0;
  network_sub: any;
  totalNetwork: any;
  isLoadingNetwork: boolean;

  public teams: Team[] = [];
  public isLoadingTeams: boolean = false;

  isLoadingDepartment: boolean = true;
  isLoadingColleagues: boolean = true;
  position_sub: any;

  colleagues: ContactGroup;
  colleaguesList: Contact[];
  colleagues_sub: any;

  departmentContactsList: Contact[];
  departments_sub: any;
  groupedLocalContactsDepartments: WorkspaceDepartmentContactGroup[] = [];
  NO_DEPARTMENT = <any>{
    name: "No Department",
    id: "",
  };

  isProfessionVerified: boolean = false;
  searchSub: any;

  public isDiscoverable: boolean = false;
  public isVerified: boolean = false;

  constructor(
    private sharedService: SharedService,
    private alertService: AlertService,
    private profileService: ProfileService,
    private teamsService: TeamsService,
    private analyticsService: AnalyticsService,
    private accountService: AccountService,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.searchSub = this.searchNow$.subscribe((res) => {
      this.searchTerm = res;
      if (!res) {
        return;
      }
      this.searchNow();
    });
  }

  ngOnDestroy() {
    if (this.searchSub) this.searchSub.unsubscribe();
  }

  ngDoCheck() {
    const contactGroups = [
      this.connections,
      this.colleagues,
      this.network,
      ...this.groupedLocalContactsDepartments,
    ].filter((c) => c);
    for (const contactGroup of contactGroups) {
      contactGroup.state = this.getState(contactGroup.contacts);
    }
  }

  private getTeamDescriptionMap(teams: Team[]): Map<string, string> {
    const teamDescriptions = new Map<string, string>();
    const workspacesMap = new Map<string, CompanyBasicModel>();

    if (this.workspaces) {
      this.workspaces.forEach((workspace) => {
        if (!workspace.id) return;
        workspacesMap.set(workspace.id, workspace);
      });
    }

    teams.forEach((team) => {
      if (!team.id) return;
      const workspace = workspacesMap.get(team.workspace?.id);
      if (!workspace) return;
      teamDescriptions.set(team.id, workspace.name);
    });

    return teamDescriptions;
  }

  searchNow() {
    this.unsubscribeGetDepartments();
    this.getDepartment(this.showUsersInWorkspaceIdOnly);
    this.colleaguesList = [];
    this.unsubscribeGetPosition();
    this.getColleaguesByPosition(this.showUsersInWorkspaceIdOnly);
    this.unsubscribeGetColleagues();
    this.getColleagues(this.showUsersInWorkspaceIdOnly);
    this.unsubscribeGetConnections();
    this.getConnectionsPageStart();
    this.getNetworkPageStart();

    if (!this.excludeTeams) {
      this.getTeams(this.searchTerm);
    }
  }

  getColleagues(companyId?: string) {
    this.isLoadingColleagues = true;
    this.networkPage = 0;
    let params = {
      page: this.networkPage,
      pageSize: 5000,
    };
    if (this.searchTerm) {
      params["fullName"] = this.searchTerm;
    }

    this.colleagues_sub = this.sharedService.getContacts(params).subscribe({
      next: (res) => {
        this.isLoadingColleagues = false;
        let list = res.data || [];
        if (companyId) {
          list = this.filterByCompanyId(list, companyId);
        }
        this.colleaguesList = this.colleaguesList.concat(list);
        this.colleaguesList = this.deduplicateListByUserId(this.colleaguesList);
        this.colleaguesList.sort((a, b) =>
          localeCompareIfNotNull(
            concatNotNull([a.firstName, a.lastName]),
            concatNotNull([b.firstName, b.lastName])
          )
        );

        this.colleagues = {
          contacts: this.colleaguesList,
          state: ContactGroupState.Unchecked,
        };
      },
      error: (err) => {
        this.isLoadingColleagues = false;
        let errorMessage;
        errorMessage = this.sharedService.getErrorMessageFromError(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      },
    });
  }

  filterHighlightPosition(list: ContactModel[]) {
    list.forEach((contact) => {
      let workspaces = contact.workplaces;
      this.sharedService.pullToTop(
        workspaces,
        "position",
        this.searchTerm,
        true
      );
    });
  }

  filterByCompanyId(array: ContactModel[], companyId) {
    array = array.filter((contact) => {
      for (const workspace of contact.workplaces) {
        if (workspace["companyId"] == companyId && workspace["isActive"]) {
          return true;
        }
      }
      return false;
    });
    return array;
  }

  getColleaguesByPosition(companyId?: string) {
    this.isLoadingColleagues = true;
    this.networkPage = 0;
    let params = {
      page: this.networkPage,
      pageSize: 5000,
      position: this.searchTerm ? this.searchTerm : "",
    };

    this.position_sub = this.sharedService.getContacts(params).subscribe({
      next: (res) => {
        this.isLoadingColleagues = false;
        let list = res.data || [];
        if (companyId) {
          list = this.filterByCompanyId(list, companyId);
        }
        this.filterHighlightPosition(list);
        this.colleaguesList = this.colleaguesList.concat(list);
        this.colleaguesList = this.deduplicateListByUserId(this.colleaguesList);
        this.colleaguesList.sort((a, b) =>
          localeCompareIfNotNull(
            concatNotNull([a.firstName, a.lastName]),
            concatNotNull([b.firstName, b.lastName])
          )
        );

        this.colleagues = {
          contacts: this.colleaguesList,
          state: ContactGroupState.Unchecked,
        };
      },
      error: (err) => {
        this.isLoadingColleagues = false;
        let errorMessage;
        errorMessage = this.sharedService.getErrorMessageFromError(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      },
    });
  }

  deduplicateListByUserId(array: Contact[]) {
    let a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i].userId === a[j].userId) a.splice(j--, 1);
      }
    }

    return a;
  }

  getDepartment(companyId?: string) {
    this.isLoadingDepartment = true;
    this.networkPage = 0;
    let params = {
      page: this.networkPage,
      pageSize: 5000,
      department: this.searchTerm ? this.searchTerm : "",
    };
    this.departmentContactsList = [];

    this.departments_sub = this.sharedService.getContacts(params).subscribe({
      next: (res) => {
        this.isLoadingDepartment = false;
        let list = res.data || [];
        if (companyId) {
          list = this.filterByCompanyId(list, companyId);
        }
        this.departmentContactsList = this.departmentContactsList.concat(list);
        // this.departmentContactsList = this.filterConnections(this.departmentContactsList);
        this.localSearchDepartments(
          this.searchTerm,
          this.myWorkspacesAndPartnerWorkspaceIds
        );
      },
      error: (err) => {
        this.isLoadingDepartment = false;
        let errorMessage = this.sharedService.getErrorMessageFromError(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      },
    });
  }

  filterConnections(contacts: ContactModel[]) {
    return contacts.filter((contact) => {
      if (this.connectionContactsIds.indexOf(contact.userId) == -1) return true;
      return false;
    });
  }

  localSearchDepartments(query: string, validWorkspaceIds: string[]) {
    query = query.toLowerCase();
    const localContacts = sorted(this.departmentContactsList, (a, b) =>
      localeCompareIfNotNull(
        concatNotNull([a.firstName, a.lastName]),
        concatNotNull([b.firstName, b.lastName])
      )
    );

    this.groupedLocalContactsDepartments = this.format(
      localContacts,
      validWorkspaceIds
    );
    this.sharedService.sortArrayByField(
      this.groupedLocalContactsDepartments,
      "departmentName"
    );
    if (query) {
      this.sharedService.pullToTop(
        this.groupedLocalContactsDepartments,
        "departmentName",
        query
      );
    }
  }

  format(
    contacts: Contact[],
    validWorkspacesIds: string[]
  ): WorkspaceDepartmentContactGroup[] {
    let group = {};
    for (const contact of contacts) {
      const workspaces = contact["workplaces"];
      for (const workspace of workspaces) {
        if (validWorkspacesIds.indexOf(workspace.companyId) == -1) continue;
        if (contact.userId !== this.userAccount.userId) {
          const departmentId = workspace.departmentId
            ? workspace.departmentId
            : this.NO_DEPARTMENT.id;
          const departmentName = workspace.departmentName
            ? workspace.departmentName
            : this.NO_DEPARTMENT.name;
          const workspaceName = workspace.companyName
            ? workspace.companyName
            : "";
          const workspaceVerificationStatus = workspace.verificationStatus
            ? workspace.verificationStatus
            : "";
          group = group || {};
          group[departmentId] = group[departmentId] || {};
          group[departmentId].companyId = workspace.companyId;
          group[departmentId].departmentName = departmentName;
          group[departmentId].workspaceName = group[departmentId].workspaceName
            ? group[departmentId].workspaceName
            : workspaceName;
          group[departmentId].workspaceVerificationStatus = group[departmentId]
            .workspaceVerificationStatus
            ? group[departmentId].workspaceVerificationStatus
            : workspaceVerificationStatus;
          group[departmentId].contacts = group[departmentId].contacts || [];
          group[departmentId].contacts.push(contact);
        }
      }
    }
    const pairs: WorkspaceDepartmentContactGroup[] = [];
    for (const k in group) {
      const departmentName = group[k].departmentName.toLowerCase();
      if (k && departmentName.indexOf(this.searchTerm.toLowerCase()) != -1) {
        pairs.push({
          departmentId: k,
          contacts: group[k].contacts,
          departmentName: group[k].departmentName,
          workspaceName: group[k].workspaceName,
          workspaceVerificationStatus: group[k].workspaceVerificationStatus,
          companyId: group[k].companyId,
          state: ContactGroupState.Unchecked,
        });
      }
    }
    return pairs;
  }

  setNetworkAnchor() {
    const anchor = document.getElementById("networkAnchor") as HTMLElement;
    if (!anchor) {
      return;
    }
    const i = this;
    const observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          i.loadMoreNetwork();
        }
      },
      { threshold: [0] }
    );
    observer.observe(anchor);
  }

  getNetworkPageStart() {
    this.isDiscoverable = this.accountService.isDiscoverableOnNetwork();
    this.isVerified = this.accountService.isVerified();
    if (!this.isVerified) return;
    this.network = {
      contacts: [],
      state: ContactGroupState.Unchecked,
    };
    this.networkContacts = [];
    this.getNetwork(this.networkPage, this.networkPageSize, this.searchTerm);
  }

  loadMoreNetwork() {
    if (
      this.isLoadingNetwork ||
      !this.networkContacts ||
      !this.networkContacts.length ||
      this.networkContacts.length >= this.totalNetwork
    ) {
      return;
    }
    this.networkPage++;
    this.getNetwork(this.networkPage, this.networkPageSize, this.searchTerm);
  }

  getNetwork(page, pageSize, query) {
    if (
      !this.discoverable ||
      this.page_type == "new_group" ||
      this.page_type == "new_case" ||
      this.page_type == PageType.NewBroadcast
    ) {
      return;
    }
    this.isLoadingNetwork = true;
    this.networkPage = page;
    let params = {
      page: this.networkPage,
      pageSize: pageSize,
      Search: query ? query : "",
    };
    this.unsubscribeGetNetwork();
    this.network_sub = this.sharedService.getUsersV2(params).subscribe({
      next: (res) => {
        this.isLoadingNetwork = false;
        this.totalNetwork = res.total;
        this.networkContacts = this.networkContacts.concat(res.data || []);
        this.networkContacts = this.filterConnections(this.networkContacts);
        this.networkContacts.sort((a, b) =>
          localeCompareIfNotNull(
            concatNotNull([a.firstName, a.lastName]),
            concatNotNull([b.firstName, b.lastName])
          )
        );
        this.network.contacts = this.networkContacts;
        if (this.networkPage == 0) {
          setTimeout(() => {
            this.setNetworkAnchor();
          }, 200);
        }
      },
      error: (err) => {
        this.isLoadingNetwork = false;
        let errorMessage;
        errorMessage = this.sharedService.getErrorMessageFromError(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      },
    });
  }

  unsubscribeGetColleagues() {
    if (this.colleagues_sub) {
      this.colleagues_sub.unsubscribe();
    }
  }

  unsubscribeGetDepartments() {
    if (this.departments_sub) {
      this.departments_sub.unsubscribe();
    }
  }

  unsubscribeGetPosition() {
    if (this.position_sub) {
      this.position_sub.unsubscribe();
    }
  }

  unsubscribeGetNetwork() {
    if (this.network_sub) {
      this.network_sub.unsubscribe();
    }
  }

  getConnectionsPageStart() {
    this.connections = undefined;
    this.connectionContacts = [];
    this.connectionContactsIds = [];
    this.getConnections(0, this.connectionsPageSize, this.searchTerm);
  }

  unsubscribeGetConnections() {
    if (this.connections_sub) {
      this.connections_sub.unsubscribe();
    }
  }

  // TODO refactor duplicated code
  groupAction(status: boolean, group: ContactGroup, label?: SelectionLabel) {
    const editableGroupContacts = {
      ...group,
      contacts: group.contacts.filter(
        (c) => !this.uneditableContactIds.has(c.userId)
      ),
    };

    if (status) {
      this.selectAll(editableGroupContacts, label);
    } else {
      this.unselectAll(editableGroupContacts);
    }
  }

  selectAll(group: ContactGroup, label: SelectionLabel) {
    if (!group.contacts || !group.contacts.length) {
      return;
    }
    group.contacts.forEach((contact) => {
      if (
        this.type === "suggestions" &&
        (contact as SuggestedUserModel).type === SuggestedType.Random
      ) {
        return false;
      }
      this.emaitContact(contact, label);
    });
  }

  emaitContact(contact, label?) {
    const selection = {
      contact,
      label,
    };
    this.contactSelect.emit(selection);
  }

  emitClick(contact, label?) {
    let selection = {
      contact: contact,
      label: label,
    };
    this.contactClick.emit(selection);
  }

  unselectAll(group: ContactGroup) {
    if (!group.contacts || !group.contacts.length) {
      return;
    }
    group.contacts.forEach((contact) => {
      this.contactRemove.emit(contact);
    });
  }

  getConnections(page, pageSize, query) {
    if (this.page_type == PageType.NewBroadcast) {
      return;
    }
    this.isLoadingConnections = true;
    this.connectionsPage = page;
    let params = {
      page: this.connectionsPage,
      pageSize: pageSize,
      search: query ? query : "",
    };
    this.connections_sub = this.sharedService.getContactsV2(params).subscribe({
      next: (res) => {
        this.isLoadingConnections = false;
        this.totalConnections = res.total;
        this.connectionContacts = this.connectionContacts.concat(
          res.data || []
        );
        this.connectionContacts.sort((a, b) =>
          localeCompareIfNotNull(
            concatNotNull([a.firstName, a.lastName]),
            concatNotNull([b.firstName, b.lastName])
          )
        );
        this.connectionContactsIds = this.connectionContacts.map(
          (contact) => contact.userId
        );

        this.connections = {
          contacts: this.connectionContacts,
          state: ContactGroupState.Unchecked,
        };
      },
      error: (err) => {
        this.isLoadingConnections = false;
        let errorMessage;
        errorMessage = this.sharedService.getErrorMessageFromError(err);
        if (errorMessage) {
          this.alertService.confirm("", errorMessage, "Close", "", true);
        }
      },
    });
  }

  private getTeams(search: string) {
    this.isLoadingTeams = true;
    this.teams = [];
    this.teamsService.searchTeams({ search, fetchAll: true }).subscribe({
      next: (teams) => {
        const activeTeams = this.teamsService.filterOutInactiveTeams(teams);
        this.teamDescriptions = this.getTeamDescriptionMap(activeTeams);
        this.teams = activeTeams;
      },
      error: (error) => {
        this.isLoadingTeams = false;
        AlertComponent.openErrorDialog(this.matDialog);
      },
      complete: () => (this.isLoadingTeams = false),
    });
  }

  formatByField(array, fieldName) {
    let pivot = 0;
    for (let index = 0; index < array.length; index++) {
      const contact = array[index];
      if (contact[fieldName] == true || contact[fieldName] == "true") {
        array.splice(index, 1);
        array.splice(pivot++, 0, contact);
      }
    }
  }

  getState(contacts: Contact[]): ContactGroupState {
    const editable = contacts.filter(
      (c) => !this.uneditableContactIds.has(c.userId)
    );

    if (editable.length === 0) {
      return ContactGroupState.Disabled;
    }

    const checkCount = editable.reduce(
      (previousValue, contact) =>
        contact.userId in this.selectedContactsMap
          ? previousValue + 1
          : previousValue,
      0
    );
    if (checkCount === 0) {
      return ContactGroupState.Unchecked;
    }
    if (checkCount < editable.length) {
      return ContactGroupState.Indeterminate;
    }
    return ContactGroupState.Checked;
  }

  copyProfileLinkButtonClick() {
    this.profileService.shareProfile();
  }

  verifyIdentity() {
    this.alertService.verifyIdentityDialog();
  }

  public handleRoleClicked(team: Team) {
    this.roleClick.emit(team);
    this.analyticsService.buttonClickEvent("user_card", {
      flow: "network",
      card_type: "searched_team",
    });
  }
}
