import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  CONVERSATIONS_SERVICE,
  ConversationsServiceProvider,
} from "@modules/core";
import { ZoomService } from "@modules/core/services/zoom.service";
import { ConversationModelV3 } from "@types";

@Component({
  selector: "app-voip-overlay",
  templateUrl: "./voip-overlay.component.html",
  styleUrls: ["./voip-overlay.component.scss"],
})
export class VoipOverlayComponent implements OnInit, OnChanges {
  @Input() public conversation: ConversationModelV3;
  @Input() public isCallEnded: boolean;
  @Input() public isNewCall: boolean;
  @Input() public disableMessage: boolean = false;
  @Input() public disableBackground: boolean = false;

  public message: string = "";
  public conversationName: string;

  public isCapturingVideo$ = this.zoom.isCapturingVideo$;

  constructor(
    private zoom: ZoomService,
    @Inject(CONVERSATIONS_SERVICE)
    private conversationsService: ConversationsServiceProvider
  ) {}

  ngOnInit(): void {
    this.updateMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      Object.hasOwn(changes, "isCallEnded") ||
      Object.hasOwn(changes, "isNewCall")
    ) {
      this.updateMessage();
    }

    if (this.conversation) {
      this.conversationName = this.conversationsService.getConversationName(
        this.conversation
      );
    }
  }

  private updateMessage = () => {
    if (this.isCallEnded) {
      this.message = "Call ended";
    } else if (!this.isNewCall) {
      this.message = "Joining...";
    } else if (!this.isCallEnded) {
      this.message = "Calling...";
    } else {
      this.message = "Call ended";
    }
  };
}
