import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-new-message-create",
  templateUrl: "./new-message-create.component.html",
  styleUrls: [
    "./new-message-create.component.scss",
    "../../../../../celo-input.scss",
  ],
})
export class NewMessageCreateComponent {
  @Input() type = "";
  @Input() name: string;
  @Output() nameChange = new EventEmitter<string>();
  @Output() nameInvalid = new EventEmitter<string>();

  @Input() patientData: any = {
    uid: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    gender: null,
  };
}
