<mat-form-field class="fw" appearance="outline">
  <mat-label>Clinical Record</mat-label>
  <mat-select
    id="integrationSelect"
    #integrationSelect
    [formControl]="selectedControl"
    (selectionChange)="onChange($event)"
  >
    <mat-select-trigger *ngIf="selected">
      {{ selected["name"] }}
      <span *ngIf="selected['company']">
        - {{ selected["company"]["name"] }}</span
      >
    </mat-select-trigger>
    <div *ngFor="let integration of integrations" class="clinical-records">
      <mat-option
        [value]="integration"
        [disabled]="
          exportableTo.indexOf(integration.id) === -1 && !ignoreExportableTo
        "
      >
        <div class="ml-2 texts">
          <div class="option_title">
            {{ integration.name
            }}<span
              *ngIf="
                exportableTo.indexOf(integration.id) === -1 &&
                !ignoreExportableTo
              "
            >
              (Already exported)</span
            >
          </div>
          <div class="option_subtitle">{{ integration.company.name }}</div>
        </div>
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
<mat-form-field class="fw" appearance="outline">
  <mat-label>Select a service</mat-label>
  <mat-select
    id="serviceSelect"
    #serviceSelect
    [formControl]="serviceControl"
    (selectionChange)="onServiceChange($event)"
  >
    <div *ngFor="let service of services">
      <mat-option [value]="service">
        <div class="ml-2 texts">
          <div class="option_title">{{ service.name }}</div>
        </div>
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
