<div class="onboard">
  <div class="content">
    <div class="contents">
      <img class="logo" src="../../../assets/logo-v2.svg" alt="" />
      <img class="cover" src="../../../assets/profile.svg" alt="" />
      <div class="title">Your Celo Profile</div>
      <form class="fields celo-input-outline" #form [formGroup]="myForm">
        <mat-form-field appearance="outline" class="fw">
          <mat-label>First Name</mat-label>
          <input
            matInput
            class="fw"
            type="text"
            autocomplete="off"
            placeholder="First Name"
            [(ngModel)]="firstName"
            id="firstNameField"
            name="firstNameField"
            #firstNameField
            formControlName="firstNameField"
            required
            [pattern]="regex"
            maxlength="45"
            appAutofocus
          />
        </mat-form-field>
        <mat-form-field appearance="outline" class="fw">
          <mat-label>Last Name</mat-label>
          <input
            matInput
            class="fw"
            type="text"
            autocomplete="off"
            placeholder="Last Name"
            [(ngModel)]="lastName"
            id="lastNameField"
            name="lastNameField"
            #lastNameField
            formControlName="lastNameField"
            required
            [pattern]="regex"
            maxlength="45"
            (keydown.enter)="myForm.valid && onSubmit()"
          />
        </mat-form-field>
        <div class="subtitle">
          Please use your full legal name to help verify your identity.
        </div>
      </form>
      <div class="buttons">
        <a
          [routerLink]="['/onboard/email']"
          [queryParams]="{
            country_code: countryCode,
            country_name: countryName,
            country_is_available: countryIsAvailable,
            data_region_id: dataRegionId
          }"
          class="button"
        >
          <button mat-button class="celo-secondary-button">BACK</button>
        </a>
        <button
          mat-button
          class="celo-primary-button button"
          (click)="onSubmit()"
          [disabled]="!myForm.valid"
        >
          NEXT
        </button>
      </div>
    </div>
  </div>
</div>
