<form
  *ngIf="editMode && userAccount"
  #form
  [formGroup]="myForm"
  [ngClass]="{ vertical: alignment === 'vertical' }"
>
  <div class="edit-form celo-input-outline">
    <mat-form-field *ngIf="canLookup" [hidden]="true" class="form-field">
      <input
        matInput
        placeholder="patientidValid"
        name="patientidValid"
        #patientidValid
        formControlName="patientidValid"
      />
    </mat-form-field>
    <div class="fw">
      <mat-form-field class="form-field patientid-field" appearance="outline">
        <mat-label>Patient ID</mat-label>
        <input
          matInput
          formControlName="uid"
          placeholder="Patient ID"
          name="uid"
          #patient_id
          [required]="!optionalId"
          (ngModelChange)="patientidEdited()"
        />
        <mat-hint *ngIf="patient_id.value === '' && patientIdFocused"
          >Required</mat-hint
        >
        <mat-hint
          *ngIf="patient_id.value && myForm.get('uid').errors?.maxlength"
          >Too Long</mat-hint
        >
        <button
          mat-icon-button
          matSuffix
          *ngIf="canLookup"
          class="lookup-button"
          (click)="patientIdLookup(patient_id.value)"
          matTooltip="Lookup Patient ID"
        >
          <mat-icon svgIcon="lookup-button"></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="fw">
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          placeholder="Last Name"
          name="lastName"
          formControlName="lastName"
        />
      </mat-form-field>
      <mat-form-field class="form-field" appearance="outline">
        <mat-label>First Name</mat-label>
        <input
          matInput
          placeholder="First Name"
          name="firstName"
          formControlName="firstName"
        />
      </mat-form-field>
    </div>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Date of Birth</mat-label>
      <input
        matInput
        [max]="today"
        [matDatepicker]="picker"
        placeholder="Date of Birth"
        name="dateOfBirth"
        formControlName="dateOfBirth"
      />
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Gender</mat-label>
      <mat-select name="gender" formControlName="gender">
        <mat-option *ngFor="let gender of genders" [value]="gender.value">
          {{ gender.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <app-celo-cancel-save-buttons
    *ngIf="canSave"
    [showNow]="hasChanged && !saving"
    (cancel)="cancel()"
    (save)="save()"
  >
  </app-celo-cancel-save-buttons>
</form>
<div *ngIf="!editMode && myForm">
  <div *ngIf="myForm.controls['uid'].value">
    <div *ngIf="selectMode" class="itemtitle font-14">
      <div >Patient details for {{ patient.uid }}</div>
      <div *ngIf="patient.source && patient.source.name">
        {{ patient.source.name }}
      </div>
    </div>
    <div *ngFor="let field of fields">
      <div
        *ngIf="myForm.controls[field.key].value"
        class="patient-field"
        [ngClass]="{ horizontal: alignment === 'horizontal' }"
      >
        <div class="font-14 font-grey fieldname">{{ field.name }}</div>
        <div class="font-14" [ngClass]="{ uppercase: field.key === 'uid' }">
          {{
            myForm.controls[field.key].value
              ? field.type !== "date"
                ? myForm.controls[field.key].value
                : (myForm.controls[field.key].value | toDisplayDate)
              : "Not Given"
          }}
        </div>
      </div>
    </div>
    <div *ngIf="showConsent && consent" class="font-14 horizontal">
      <div class="font-grey fieldname">Consent</div>
      <div *ngIf="consent">
        <div>
          {{
            consent.company && consent.company.name
              ? consent.company.name
              : "Consent attached"
          }}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!myForm.controls['uid'].value" class="font-grey font-14">
    No Patient Details
  </div>
</div>
