<div class="icon-row">
  <div *ngIf="icon || svgIcon" class="icon">
    <i *ngIf="icon" class="material-icons">{{ icon }}</i>
    <mat-icon *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
  </div>
  <div class="info">
    <div class="info-title fw flex items-center g-2">
      {{ title }}
      <app-badge
        *ngIf="showBadge"
        class="inline-block"
        [identityVerificationStatus]="false"
        [workspaces]="workspaces"
        [hideUnverifiedBadge]="true"
        [size]="22"
        [showTooltip]="false"
      ></app-badge>
    </div>
    <div *ngIf="subtitles">
      <div *ngFor="let subtitle of subtitles">
        <div class="info-subtitle">{{ subtitle }}</div>
      </div>
    </div>
  </div>
</div>
