<div
  *ngFor="let contact of connections"
  [routerLink]="['/network/contact', contact.userId]"
  [routerLinkActive]="['selected-contact']"
  class="celo-cursor-pointer list"
>
  <div class="sidenav-padding celo-hoverable">
    <a (click)="raiseCardClickEvent()">
      <app-celo-user-card
        [userId]="contact.userId"
        [contact]="contact"
        [workspaces]="contact.workplaces"
        [title]="contact.title"
        [firstName]="contact.firstName"
        [lastName]="contact.lastName"
        [profilePic]="contact.profilePic"
        [position]="contact.position"
        [showCompany]="showCompany"
        [showAllWorkspaces]="showAllWorkspaces"
        [department]="contact.department"
        [showDepartment]="showDepartment"
        [hideWorkspace]="hideWorkspace"
        [identityVerificationStatus]="contact.identityVerificationStatus"
        [showBadge]="true"
        [hideProfession]="hideProfession"
        [profession]="
          contact.professions &&
          contact.professions[0] &&
          contact.professions[0] &&
          contact.professions[0].category
            ? contact.professions[0].profession
            : ''
        "
        [professionVerified]="
          contact.professions &&
          contact.professions[0] &&
          contact.professions[0].verificationStatus === 'Verified'
        "
      >
      </app-celo-user-card>
    </a>
  </div>
</div>
<div *ngIf="loading" class="loading">
  <app-empty-state
    [spinner]="true"
    [animation]="false"
    [isLoading]="true"
    [message]="' '"
    [diameter]="16"
    [stroke]="2"
  ></app-empty-state>
</div>
