<div class="titlesection">
  <div class="content">
    <div>
      <div class="title">{{ title }}</div>
      <div *ngIf="subtitle" class="subtitle">{{ subtitle }}</div>
    </div>

    <ng-content select="[end]"></ng-content>
  </div>

  <hr />
</div>
