import { defaultEnvironment, Environment } from "./environment.default";

export const environment: Environment = {
  ...defaultEnvironment,
  production: false,
  celoAuth: "59ac8c4bb45f5b0001e38d913d00e8f4a95b45a46ab694d4b8fbdef3",
  celoApiEndpoint: "https://celo-api-staging.celohealth.com",
  celoBroadcastApiEndpoint: "https://celo-broadcast-staging.celohealth.com",
  celoAuthApiEndpoint: "https://login-staging.celohealth.com",
  celoSocketLocation:
    "https://celo-messaging-staging.celohealth.com/messagingHub",
  origin: "https://stagingapp.celohealth.com",
  invitationOrigin: "link-staging.celohealth.com",

  clientId: "celo.desktop.6CC9BEAE2341",
  onboardLink: "https://stagingapp.celohealth.com/onboard",
  emailThrottleInSeconds: 30,
  env: "staging",

  workspaceAdminUrl: "https://workspace-admin-staging.celohealth.com/",
  applicationInsightsConnectionString:
    "InstrumentationKey=b3828d2a-d852-4d27-8f54-d68e6e51c6c1;IngestionEndpoint=https://australiaeast-1.in.applicationinsights.azure.com/;LiveEndpoint=https://australiaeast.livediagnostics.monitor.azure.com/",

  firebaseConfig: {
    apiKey: "AIzaSyDw5uexXjgdmQ3IVdzzWhqmVnyI3tDYx2M",
    authDomain: "celo-1470698531705.firebaseapp.com",
    databaseURL: "https://celo-1470698531705.firebaseio.com",
    projectId: "celo-1470698531705",
    storageBucket: "celo-1470698531705.appspot.com",
    messagingSenderId: "564504357537",
    appId: "1:564504357537:web:f1b11015adb64027c31ee0",
    measurementId: "G-MS32MM395T",
  },
};
