<div
  *ngIf="mediaGroups.length > 0"
  class="photo-list"
  infiniteScroll
  [scrollWindow]="false"
  [infiniteScrollDistance]="2"
  [infiniteScrollUpDistance]="1.5"
  [infiniteScrollThrottle]="50"
  (scrolled)="onNewScrollPosition()"
>
  <div class="content">
    <div *ngFor="let group of mediaGroups" class="group">
      <div class="font-14">
        {{ group.date | date: "MMMM yyyy" }}
      </div>
      <div class="list row">
        <ng-container *ngFor="let mediaContainer of group.photos">
          <div
            #tile
            class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 item"
            *ngIf="!hideVideos || mediaContainer.payload.mediaType !== 'Video'"
            [style.height.px]="tile.offsetWidth"
          >
            <div class="grow-shadow card">
              <app-media-details
                [libItem]="mediaContainer"
                (selected)="onSelected($event)"
                [ownFile]="userId === mediaContainer.payload.creator.userId"
                [type]="mediaContainer.payload.mediaType"
                [disabled]="mediaContainer.payload.isExported ?? false"
                [exported]="mediaContainer.payload.isExported ?? false"
                [ignoreStateChange]="isMaxSelected"
              ></app-media-details>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div *ngIf="loading && hasMore">
      <app-empty-state [isLoading]="true" [message]="'Loading Photos'">
      </app-empty-state>
    </div>
    <div *ngIf="!hasMore" class="list-end">- That's all -</div>
  </div>
</div>
