<ng-container *ngIf="multiple; else nonMultipleLabel">
  <mat-checkbox
    (click)="handleClick($event)"
    [checked]="isChecked"
    [disabled]="isDisabled"
    [indeterminate]="isIndeterminate"
    [color]="isChecked ? 'primary' : 'accent'"
  >
    <div *ngIf="!hideLabels" class="mat-body">
      {{ label || "Select All" }}
    </div>
  </mat-checkbox>
</ng-container>

<ng-template #nonMultipleLabel>
  <div *ngIf="!hideLabels" class="mat-body mb-8">
    {{ label || "Select All" }}
  </div>
</ng-template>

<app-basic-user-selection-list
  [multiple]="multiple"
  (user)="handleUser($event)"
  [(users)]="users"
  (usersChange)="handleUsersChange($event)"
>
  <!-- #TODO Figure out how to project a template through this component -->
  <ng-template *ngIf="selectedLabelText" appSelected>
    <app-basic-label [text]="selectedLabelText"></app-basic-label>
  </ng-template>
</app-basic-user-selection-list>
