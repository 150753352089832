<div *ngIf="mediaContainer.payload && userAccount">
  <div>
    <div *ngIf="mediaContainer.payload.creator && userAccount">
      <div class="fields-box" *ngIf="formFields">
        <app-edit-fields
          [formFields]="formFields"
          [patient]="originalPatient"
          [formSubmitInfo]="formSubmitInfo"
          [type]="'photo'"
          [editMode]="
            mediaContainer.payload.creator.userId === userAccount.userId
              ? true
              : false
          "
          (update)="onFieldSaved($event)"
        ></app-edit-fields>
      </div>
      <div class="patient-details">
        <div class="title">Patient Details</div>
        <app-edit-patient
          [patient]="patient"
          [canSave]="true"
          [editMode]="
            mediaContainer.payload.creator.userId === userAccount.userId
              ? true
              : false
          "
          [alignment]="'vertical'"
          [userAccount]="userAccount"
          (patientSave)="onPatientSave($event)"
        >
        </app-edit-patient>
      </div>

      <div
        *ngIf="
          canExport &&
          canBeExportedToIds &&
          canBeExportedToIds.length &&
          !exportDisabled &&
          (mediaContainer.payload.creator.userId === userAccount.userId ||
            this.originalPatient.uid)
        "
        class="mt-4"
      >
        <button
          mat-button
          class="export fw"
          (click)="export()"
          [disabled]="exporting"
        >
          <div>
            <mat-icon svgIcon="ic-emr"></mat-icon>
            <span class="pl-2">Export to clinical record</span>
          </div>
        </button>
      </div>
      <div class="mt-4 font-14">
        <div *ngIf="exportedTo.length > 0">
          <div>Clinical Record</div>
          <div
            *ngFor="let item of exportedTo; let i = index"
            class="mt-1 celo-hoverable relative"
          >
            <div class="font-grey">Exported to</div>
            <div class="font-16">
              {{ item.metadata?.integration?.integration?.description }}
            </div>
            <div>
              <div class="font-grey mt-2">Exported by</div>
              <app-avatar-with-name
                [title]="item.title"
                [firstName]="item.firstName"
                [lastName]="item.lastName"
                [description]="item.timestamp | dateFormat: 'medium'"
                [userId]="item.uid"
                [profilePic]="item.picture"
                [mustShowName]="true"
              >
              </app-avatar-with-name>
            </div>
            <div class="m-1 mt-3 summary">
              <a
                *ngIf="item.metadata?.exportedURI"
                [href]="item.metadata.exportedURI | secure"
                [download]="
                  'Celo - ' +
                  item.metadata?.integration?.integration?.description +
                  ' ' +
                  originalPatient.uid +
                  ' ' +
                  (item.timestamp | dateFormat: 'medium')
                "
              >
                <button class="celo-simple-button" mat-button>
                  <mat-icon> print </mat-icon>
                  <span> Download Export Summary </span>
                </button>
              </a>
              <div *ngIf="!item.metadata?.exportedURI">
                <app-empty-state
                  [isLoading]="true"
                  [message]="'Generating export summary'"
                  [diameter]="30"
                ></app-empty-state>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="exporting">
          <div #anchor></div>
          <app-empty-state
            [diameter]="30"
            [isLoading]="true"
            message="In Progress"
            (init)="miscService.scrollTo(anchor)"
          ></app-empty-state>
        </div>
      </div>
    </div>
    <app-empty-state
      *ngIf="!userAccount"
      [isLoading]="true"
      [message]="'LOADING'"
    >
    </app-empty-state>

    <div *ngIf="hasConsent" class="my-4">
      <div class="font-14">Consent Form</div>
      <app-consent-download [consent]="consent"></app-consent-download>
    </div>

    <div
      *ngIf="
        mediaContainer.payload && mediaContainer.payload.creator && userAccount
      "
      class="owner-box"
    >
      <hr />
      <div
        *ngIf="!mediaContainer.payload.isImported"
        class="font-14 mb-1 celo-bold-500"
      >
        Taken By
      </div>
      <div
        *ngIf="mediaContainer.payload.isImported"
        class="font-14 mb-1 celo-bold-500"
      >
        Imported By
      </div>
      <app-avatar-with-name
        [title]="mediaContainer.payload.creator.title"
        [firstName]="mediaContainer.payload.creator.firstName"
        [lastName]="mediaContainer.payload.creator.lastName"
        [description]="mediaContainer.payload.createdOn | dateFormat: 'medium'"
        [userId]="mediaContainer.payload.creator.userId"
        [profilePic]="mediaContainer.payload.creator.profilePic"
        [hasImage]="mediaContainer.payload.creator.profilePic"
      >
      </app-avatar-with-name>
    </div>
  </div>
  <div class="bottom-bar">
    <button
      *ngIf="isFromLibrary && mediaContainer.mediaDetails"
      mat-button
      class="delete"
      (click)="delete()"
    >
      <i class="material-icons">delete</i>
      Remove {{ mediaType ? mediaType.toLowerCase() : "media" }} from my library
    </button>
  </div>
</div>

<app-empty-state
  *ngIf="!mediaContainer.payload || !userAccount"
  [isLoading]="true"
  [message]="'LOADING'"
>
</app-empty-state>
