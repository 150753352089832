import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CustomDialogComponent } from "@modules/shared/custom-dialog/custom-dialog.component";
import { SharedService } from "../../modules/core/old/shared.service";

@Component({
  selector: "app-create-workspace",
  templateUrl: "./create-workspace.component.html",
  styleUrls: ["./create-workspace.component.scss", "../../../celo-input.scss"],
})
export class CreateWorkspaceComponent {
  name: string;
  loading: boolean;

  constructor(
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<CustomDialogComponent>
  ) {}

  createWorkspace() {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }

    this.loading = true;
    this.sharedService.createWorkspace(this.name).subscribe(
      (data) => {
        this.loading = false;
        this.closeDialog(data);
      },
      (err) => {
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  closeDialog(data) {
    this.dialogRef.close(data);
  }

  joinWorkspace() {
    this.dialogRef.close({ type: "join_workspace" });
  }
}
