import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class RoutingService {
  constructor(private router: Router) {}

  route(routes: any[], extras?: NavigationExtras) {
    this.router.navigate(routes, extras);
  }

  routeToProfilePage() {
    this.route(["/profile"]);
  }
}
