import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { Team } from "types";

export interface NetworkNavigationState {
  selectedTeam?: Team;
}

@Injectable()
export class NetworkService {
  private teamSubject = new Subject<Team | null>();
  public team$ = this.teamSubject.asObservable();

  public constructor(private router: Router) {}

  public selectTeam(team: Team) {
    const state: NetworkNavigationState = {
      selectedTeam: team,
    };
    if (!team.workspace?.id) throw new Error("Invalid workspace id");
    this.router.navigate(["network", "workspace", team.workspace.id], {
      state,
    });
    this.teamSubject.next(team);
  }
}
