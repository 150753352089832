<div
  class="icon-container"
  [style.width]="backgroundSize"
  [style.height]="backgroundSize"
>
  <img
    src="../../../../assets/icons/ic-roles.svg"
    [style.width]="size"
    [style.height]="size"
  />
</div>
