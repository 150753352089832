import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AnalyticsService } from "../../modules/core";

// declare function signupEvent():any;

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent implements OnInit {
  email: any;
  dataRegionId: any;

  constructor(
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((paramMap) => {
      this.email = paramMap.get("email");
      this.dataRegionId = paramMap.get("data_region_id");
    });

    this.analyticsService.raiseEvent("celo_page_view", {
      flow: "signing_up",
      page_name: "welcome_screen_page",
    });
  }
}
