import { Injectable, Injector } from "@angular/core";
import { IErrorService } from "@microsoft/applicationinsights-angularplugin-js";
import { AlertComponent } from "./components";
import { MatDialog } from "@angular/material/dialog";

const isChunkLoadError = (error: Error) => {
  return /ChunkLoadError/.test(error.message);
};

@Injectable()
export class GlobalErrorHandler implements IErrorService {
  private matDialog: MatDialog | null = null;

  public constructor(private injector: Injector) {}

  public handleError(error: unknown): void {
    // eslint-disable-next-line no-console
    console.error(error);

    if (!(error instanceof Error)) return;

    if (!isChunkLoadError(error)) return;

    // eslint-disable-next-line no-console
    console.log("Forcing page refresh due to ChunkLoadError");

    if (!this.matDialog) {
      this.matDialog = this.injector.get(MatDialog);
    }

    if (!this.matDialog) {
      // Failed to get service, fallback to forcing the user to reload
      window?.location?.reload();
      return;
    }

    AlertComponent.openDialog(this.matDialog, {
      title: "Update Required",
      message:
        "To continue using this application, you'll need to refresh the page.",
      acceptOnly: true,
      acceptButtonText: "OK",
    })
      .afterClosed()
      .subscribe({
        next: () => {
          window?.location?.reload();
        },
      });
  }
}
