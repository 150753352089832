import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { formatDate } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class ImgOverlayService {
  constructor() {}

  addOverlayToBlob(
    blob: Blob,
    patientPhoto: any,
    fileType: string
  ): Observable<any> {
    const that = this;
    return from(
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result.toString();
          if (fileType == "Photo") {
            that.overlay(base64data, patientPhoto).then((blob: any) => {
              <any>resolve(blob);
            });
          } else {
            <any>resolve(blob);
          }
          // <any>resolve(blob);
          that.overlay(base64data, patientPhoto).then((blob: any) => {
            <any>resolve(blob);
          });
        };
      })
    );
  }

  overlay(base64: string, patientPhoto: any): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const canvas: HTMLCanvasElement = <HTMLCanvasElement>(
        document.createElement("CANVAS")
      );
      const imageObj = new Image();
      imageObj.onload = () => {
        const fontsize = 12;
        const pad = 4;
        const maxLen = 80;
        let dh = 100;
        if (
          patientPhoto.description &&
          patientPhoto.description.length > maxLen
        ) {
          dh +=
            (fontsize + 3 * pad) *
            (Math.ceil(patientPhoto.description.length / maxLen) - 1);
        }
        const w = Math.max(imageObj.width, 500);
        const h = imageObj.height + dh;
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, w, h);
        // ctx.fillStyle = 'red';
        // ctx.fillRect(0, 0, w, h);
        ctx.drawImage(imageObj, (w - imageObj.width) / 2, 0);
        ctx.fillStyle = "black";
        ctx.font = `${fontsize}pt Roboto`;
        const cx = 10;
        let cy = h - dh + 2 * pad + fontsize;

        const drawText = (txt) => {
          const lines = [];
          const numLines = Math.ceil(txt.length / maxLen);
          for (let i = 0; i < numLines; i++) {
            const elipsis = i + 1 === numLines ? "" : "...";
            lines.push(txt.substr(i * maxLen, maxLen) + elipsis);
          }
          for (const line of lines) {
            ctx.fillText(line, cx, cy);
            cy += fontsize + 3 * pad;
          }
        };

        const drawLine = () => {
          cy -= fontsize;
          ctx.beginPath();
          ctx.moveTo(pad, cy);
          ctx.lineTo(w - pad, cy);
          ctx.stroke();
          cy += pad + fontsize;
        };

        if (patientPhoto.description) {
          drawText(patientPhoto.description);
          drawLine();
        }

        if (patientPhoto.creator) {
          const parts = [];
          if (patientPhoto.creator.title) {
            parts.push(patientPhoto.creator.title);
          }
          if (patientPhoto.creator.firstName) {
            parts.push(patientPhoto.creator.firstName);
          }
          if (patientPhoto.creator.lastName) {
            parts.push(patientPhoto.creator.lastName);
          }
          if (!parts.length) {
            parts.push("Unknown");
          }

          if (patientPhoto.createdOn) {
            // todo: confirm formats that use (NZT) are always NZT
            const date = formatDate(
              patientPhoto.createdOn,
              "d/M/yyyy h:mm a",
              "en",
              "+1200"
            );
            parts.push(`      ${date} (NZT)`);
          }

          const pfx = patientPhoto.isImported ? "Imported" : "Taken";
          const by = `${pfx} by: ${parts.join(" ")}`;
          drawText(by);
        }

        if (patientPhoto.patientData) {
          // Patient Details: {{content.photo.patientData.uid}}, {{content.photo.patientData.firstName}}
          //                     {{content.photo.patientData.lastName}}
          const parts = [];
          if (patientPhoto.patientData.uid) {
            parts.push(patientPhoto.patientData.uid + ",");
          }
          if (patientPhoto.patientData.firstName) {
            parts.push(patientPhoto.patientData.firstName);
          }
          if (patientPhoto.patientData.lastName) {
            parts.push(patientPhoto.patientData.lastName);
          }
          if (!parts.length) {
            parts.push("None");
          }
          const by = `Patient Details: ${parts.join(" ")}`;
          drawText(by);
        }
        // resolve(canvas.toDataURL('png', 1));
        canvas.toBlob((blob: Blob) => {
          resolve(blob);
        });
      };
      imageObj.src = base64;
    });
  }
}
