import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {
  ConversationsService
} from "./modules/core";

@Injectable()
export class ConversationResolver {
  constructor(private conversationsService: ConversationsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.conversationsService.getConversation(route.params.id);
  }
}
