import { Component, OnDestroy, OnInit } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { AuthService, SharedService, UserAccountService } from "@modules/core";
import { Store } from "@ngrx/store";
import { AuthActions } from "app/state/auth";
import { Subscription } from "rxjs";

@Component({
  selector: "app-logged-in-oidc",
  templateUrl: "./logged-in-oidc.component.html",
  styleUrls: ["./logged-in-oidc.component.scss"],
})
export class LoggedInOidcComponent implements OnInit, OnDestroy {
  subscription: Subscription | null = null;

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private userAccountService: UserAccountService,
    private analytics: AngularFireAnalytics,
    private store: Store
  ) {}

  ngOnInit() {
    this.store.dispatch(AuthActions.loginSuccess());

    this.subscription = this.authService.userId$.subscribe({
      next: (userId) => {
        if (userId === null) return;
        this.userAccountService.userFirstInsideTheApp(userId);
        this.analytics.logEvent("login");
        this.analytics.logEvent("login_custom");
        this.analytics.logEvent("sign_in");
        this.analytics.logEvent("login");
      },
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
