import { Platform } from "@angular/cdk/platform";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import {
  DateAdapter,
  MatDateFormats,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from "@angular/material/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { getISODateComponent } from "@utils";
import { AlertService } from "app/modules/core/old/alert.service";
import dateFormat from "dateformat";
import { UserAccountService } from "../../core";
import { PatientIdLookupComponent } from "../patientid-lookup/patientid-lookup.component";
import {
  LuxonDateAdapter,
  MatLuxonDateAdapterOptions,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
} from "@angular/material-luxon-adapter";
import { DateTime } from "luxon";

export const DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: "d MMMM yyyy",
  },
  display: {
    dateInput: "d MMMM yyyy",
    monthYearLabel: "MMM yyyy",
    dateA11yLabel: "d MMMM yyyy",
    monthYearA11yLabel: "MMM yyyy",
  },
};

export const DATE_ADAPTER_OPTIONS: MatLuxonDateAdapterOptions = {
  useUtc: true,
  firstDayOfWeek: 0,
  defaultOutputCalendar: "gregory",
};

@Component({
  selector: "app-edit-patient",
  templateUrl: "./edit-patient.component.html",
  styleUrls: ["./edit-patient.component.scss", "../../../../celo-input.scss"],
  providers: [
    {
      provide: DateAdapter<DateTime>,
      useClass: LuxonDateAdapter,
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS,
    },
    {
      provide: MAT_LUXON_DATE_ADAPTER_OPTIONS,
      useValue: DATE_ADAPTER_OPTIONS,
    },
  ],
})
export class EditPatientComponent implements OnInit, OnChanges, OnDestroy {
  @Input() patient: any;
  @Input() consent: any;
  // @Input() source: any;
  original: any;
  @Input() userAccount: any;
  @Input() selectMode: boolean;
  @Input() alignment: string;
  @Input() optionalId: boolean;
  canLookup: boolean;
  // @Input() mustLookup: boolean;   // cant save patient details without looking up
  hasChanged = false;
  @Input() canSave = false; //  weather this component should show 'save' button when a value is chanded
  @Input() editMode = true; //  weather the current mode shows is editable fields or display them
  @Input() showConsent = false; //  weather the current mode shows is editable fields or display them
  @Output() dataChange = new EventEmitter<any>();
  @Output() patientSave = new EventEmitter<any>();
  @Output() validity = new EventEmitter<boolean>();

  dialogRef: any;

  myForm: UntypedFormGroup;
  today = new Date();

  genders = [
    { value: "NotSpecified", viewValue: "Not Specified" },
    { value: "Male", viewValue: "Male" },
    { value: "Female", viewValue: "Female" },
  ];

  fields = [
    {
      key: "uid",
      name: "Patient ID",
      type: "text",
    },
    {
      key: "lastName",
      name: "Last Name",
      type: "text",
    },
    {
      key: "firstName",
      name: "First Name",
      type: "text",
    },
    {
      key: "dateOfBirth",
      name: "Date of birth",
      type: "date",
    },
    {
      key: "gender",
      name: "Gender",
      type: "text",
    },
  ];

  private change_sub: any;
  patientidValid: boolean;
  saving: boolean;
  // userAccount: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public userAccountService: UserAccountService,
    private alertService: AlertService
  ) {
    // this.mustLookup = true;
  }

  ngOnInit() {
    // if(!this.userAccount){        // if account info is not already received from parent
    //   this.userAccountService.accountSubject.subscribe(data=>{
    //     this.userAccount = data;
    //     this.setPatientIdLookup();
    //     this.initialize();
    //   })
    // }

    const instance = this;
    this.userAccountService.getUserAccount(false, function (data) {
      instance.userAccount = data;
      instance.setPatientIdLookup();
      instance.initialize();
    });
  }

  // active members of workspaces will be able to lookup no matter what
  setPatientIdLookup() {
    this.canLookup = false;

    if (!this.editMode || !this.userAccount) {
      return;
    }
    if (
      this.userAccount &&
      this.userAccount["features"] &&
      this.userAccount["features"].patientLookup
    ) {
      this.canLookup = true;
    }
  }

  ngOnChanges() {
    this.setPatientIdLookup();
    this.initialize();
  }

  initialize() {
    if (this.change_sub) {
      this.change_sub.unsubscribe();
    }
    this.hasChanged = false;
    this.original = { ...this.patient };
    this.applyPatientData(this.patient);
    this.onChanges();
    this.closeDialog(this.dialogRef);
  }

  ngOnDestroy() {
    this.closeDialog(this.dialogRef);
  }

  closeDialog(dialogRef) {
    if (dialogRef) {
      dialogRef.close();
    }
  }

  onChanges(): void {
    this.change_sub = this.myForm.valueChanges.subscribe((val) => {
      this.saving = false;
      let utcDate = null;
      if (val.dateOfBirth) {
        utcDate = getISODateComponent(val.dateOfBirth);
      }
      if (this.myForm.valid) {
        this.patient.uid = val.uid;
        this.patient.dateOfBirth = utcDate;
        this.patient.gender = val.gender;
        this.patient.uid = val.uid;
        this.patient.firstName = val.firstName;
        this.patient.lastName = val.lastName;
        this.dataChange.emit(this.patient);
        this.hasChanged = true;
      } else {
        this.hasChanged = false;
      }
      this.validity.emit(this.myForm.valid);
    });
  }

  save() {
    if (!this.myForm.valid) {
      this.alertService.showSnackBar(
        "Please provide valid patient details",
        4,
        "white"
      );
      return;
    }
    const i = this;
    const data = {
      patientData: this.patient,
      callback(status) {
        i.hasChanged = !status;
      },
    };
    this.saving = true;
    this.patientSave.emit(data);
  }

  cancel() {
    this.hasChanged = false;
    this.patient = { ...this.original };
    this.ngOnChanges();
  }

  applyPatientData(patient: any) {
    let group = {};
    group = {
      uid: { value: patient.uid, disabled: false },
      lastName: { value: patient.lastName, disabled: false },
      firstName: { value: patient.firstName, disabled: false },
      gender: { value: patient.gender, disabled: false },
      dateOfBirth: { value: patient.dateOfBirth, disabled: false },
    };
    this.patientidValid = undefined;

    if (this.canLookup) {
      let initiallyValid;
      if (patient.uid) {
        initiallyValid = true;
        this.patientidValid = true;
      }
      group["patientidValid"] = { value: initiallyValid, disabled: false };
    }

    this.myForm = this.formBuilder.group(group);
  }

  applyNewPatientData(patient: any) {
    this.myForm.controls["uid"].setValue(patient.uid);
    this.myForm.controls["firstName"].setValue(patient.firstName);
    this.myForm.controls["lastName"].setValue(patient.lastName);
    this.myForm.controls["dateOfBirth"].setValue(patient.dateOfBirth);
    this.myForm.controls["gender"].setValue(patient.gender);
  }

  patientidEdited() {
    if (this.canLookup) {
      this.myForm.controls["patientidValid"].setValue(undefined);
      this.patientidValid = false;
    }
  }

  patientIdLookup(query) {
    if (!query) {
      this.alertService.confirm(
        "",
        "Please enter a Patient ID",
        "Ok",
        "",
        true
      );
      return;
    }

    const config = new MatDialogConfig();
    const data = { query };
    config.data = data;
    this.dialogRef = this.dialog.open(PatientIdLookupComponent, config);
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        if (data.success) {
          this.mergeAndSave(data);
        } else if (data.success == false) {
          this.alertService.confirm(
            "Patient lookup is not available",
            "Please try again or contact your workspace’s IT support. You can still enter the patient details manually.",
            "Ok",
            "",
            true
          );
        }
      }
    });
  }

  mergeAndSave(newPatientData) {
    this.applyNewPatientData(newPatientData);
    if (this.myForm.controls["patientidValid"]) {
      this.myForm.controls["patientidValid"].setValue(true);
    }
    this.patientidValid = true;
    this.save();
  }
}
