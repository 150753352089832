<div class="buttons-container">
  <ng-container
    *ngIf="{
      isCapturingVideo: isCapturingVideo$ | async,
      isAudioMuted: isAudioMuted$ | async
    } as state"
  >
    <button
      mat-icon-button
      color="black"
      (click)="toggleVideo()"
      [disabled]="!isVideoStarted"
    >
      <mat-icon *ngIf="state.isCapturingVideo" svgIcon="video-on"> </mat-icon>
      <mat-icon *ngIf="!state.isCapturingVideo" svgIcon="video-off"> </mat-icon>
    </button>
    <div class="audio-buttons">
      <div class="media-list" *ngIf="isIODeviceListOpen" #mediaInput>
        <span class="header">Select a Microphone</span>
        <div class="mic-container">
          <ng-container *ngFor="let microphone of microphoneList">
            <div class="media-button-container">
              <button
                mat-button
                class="media-button"
                (click)="setMicrophoneDevice($event, microphone.deviceId)"
              >
                {{ microphone.label }}
              </button>
              <mat-icon
                color="black"
                *ngIf="microphone.deviceId === activeMicrophoneDevice"
                >check</mat-icon
              >
            </div>
          </ng-container>
        </div>
        <span class="header">Select a Speaker</span>
        <div class="speaker-container">
          <ng-container *ngFor="let speaker of speakerList">
            <div class="media-button-container">
              <button
                mat-button
                class="media-button"
                (click)="setSpeakerDevice($event, speaker.deviceId)"
              >
                {{ speaker.label }}
              </button>
              <mat-icon
                color="black"
                *ngIf="speaker.deviceId === activeSpeakerDevice"
                >check</mat-icon
              >
            </div>
          </ng-container>
        </div>
      </div>
      <button
        mat-icon-button
        color="black"
        (click)="toggleAudio()"
        [disabled]="!isAudioStarted"
      >
        <mat-icon *ngIf="state.isAudioMuted">mic_off</mat-icon>
        <mat-icon *ngIf="!state.isAudioMuted">mic</mat-icon>
      </button>
      <button
        mat-icon-button
        color="grey"
        (click)="toggleIOList($event)"
        [disabled]="!isAudioStarted"
        class="expand-button"
      >
        <mat-icon *ngIf="!isIODeviceListOpen">expand_more</mat-icon>
        <mat-icon *ngIf="isIODeviceListOpen">expand_less</mat-icon>
      </button>
    </div>
  </ng-container>

  <button mat-mini-fab color="warn" (click)="leaveCall()">
    <mat-icon>call_end</mat-icon>
  </button>
</div>
