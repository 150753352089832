<div
  *ngIf="document && userAccount"
  [style.padding-bottom.px]="bottom_bar.offsetHeight"
>
  <div class="mt-4 font-14">
    <div>File Details</div>
    <table class="font-grey">
      <tr>
        <td>File Type</td>
        <td>PDF</td>
      </tr>
      <tr>
        <td>Size</td>
        <td>{{ document?.fileSize / 1000 | number: "1.0-0" }}KB</td>
      </tr>
    </table>
  </div>
  <div class="fields-box" *ngIf="formFields">
    <app-edit-fields
      [formFields]="formFields"
      [patient]="patient"
      [formSubmitInfo]="formSubmitInfo"
      [type]="'document'"
      [file_id]="document.id"
      [editMode]="document.creator.userId === userAccount.userId ? true : false"
      (update)="onDocumentDataUpdate($event)"
    ></app-edit-fields>
  </div>
  <div class="mt-4">
    <div class="font-14">Patient Details</div>
    <app-edit-patient
      [patient]="patient"
      [canSave]="true"
      [editMode]="document.creator.userId === userAccount.userId ? true : false"
      [alignment]="'vertical'"
      (patientSave)="onPatientSave($event)"
    ></app-edit-patient>
  </div>
  <div class="mt-4">
    <div class="font-14 mb-1">Shared By</div>
    <app-avatar-with-name
      [title]="document?.creator?.title"
      [firstName]="document?.creator?.firstName"
      [lastName]="document?.creator?.lastName"
      [description]="document.createdOn | dateFormat: 'medium'"
      [userId]="document?.creator?.userId"
      [profilePic]="document?.creator?.profilePicture"
    >
    </app-avatar-with-name>
  </div>
  <div class="bottom-bar" #bottom_bar>
    <button *ngIf="isFromLibrary" mat-button class="delete" (click)="delete()">
      <i class="material-icons">delete</i>
      Remove document from my library
    </button>
  </div>
</div>
