<div *ngFor="let contact of contactGroup.contacts" class="celo-hoverable">
  <div
    *ngIf="
      type !== 'suggestions' || contact['type'] !== 'Random' || !selectable
    "
    class="singlecardstyle"
    [ngClass]="{
      disabled: uneditableContactIds.has(contact.userId),
      selectable: !uneditableContactIds.has(contact.userId)
    }"
    matRipple
    [matRippleDisabled]="uneditableContactIds.has(contact.userId)"
    aria-disabled="uneditableContactIds.has(contact.userId)"
    (click)="click(contact)"
  >
    <div
      *ngIf="isAddingParticipants && uneditableContactIds.has(contact.userId)"
      class="added"
    >
      Added
    </div>

    <div
      *ngIf="selectable"
      class="selector"
      [ngClass]="{ select_mode: selectable }"
    >
      <i
        *ngIf="
          !uneditableContactIds.has(contact.userId) &&
          !selectedContactsMap[contact.userId]
        "
        class="material-icons"
        >check_box_outline_blank</i
      >
      <i
        *ngIf="
          uneditableContactIds.has(contact.userId) ||
          !!selectedContactsMap[contact.userId]
        "
        class="material-icons"
        >check_box</i
      >
    </div>

    <div
      *ngIf="
        type !== 'suggestions' && type !== 'workspace' && type !== 'colleagues'
      "
      class="contact"
    >
      <app-celo-user-card
        [userId]="contact.userId"
        [contact]="contact"
        [replaceMyName]="contact.firstName + ' ' + contact.lastName + ' (You)'"
        [profileId]="userAccount.userId"
        [workspaces]="contact.workplaces"
        [title]="contact.title"
        [firstName]="contact.firstName"
        [lastName]="contact.lastName"
        [profilePic]="contact.profilePic"
        [position]="contact.position"
        [showCompany]="showCompany"
        [showAllWorkspaces]="showAllWorkspaces"
        [department]="contact.department"
        [showDepartment]="showDepartment"
        [hideWorkspace]="hideWorkspace"
        [identityVerificationStatus]="contact.identityVerificationStatus"
        [showBadge]="true"
        [hideProfession]="hideProfession"
        [profession]="
          contact.professions &&
          contact.professions[0] &&
          contact.professions[0] &&
          contact.professions[0].category
            ? contact.professions[0].profession
            : ''
        "
        [professionVerified]="
          contact.professions &&
          contact.professions[0] &&
          contact.professions[0].verificationStatus === 'Verified'
        "
      >
      </app-celo-user-card>
    </div>

    <!-- Workspaces in search results -->
    <div *ngIf="type === 'workspace' && !companyInFocus" class="contact">
      <app-celo-user-card
        [companyInFocus]="companyInFocusMap[contact.userId]"
        [companyInFocusId]="companyInFocusMap[contact.userId]?.id"
        [userId]="contact.userId"
        [contact]="contact"
        [replaceMyName]="contact.firstName + ' ' + contact.lastName + ' (You)'"
        [profileId]="userAccount.userId"
        [workspaces]="contact.workplaces"
        [title]="contact.title"
        [firstName]="contact.firstName"
        [lastName]="contact.lastName"
        [profilePic]="contact.profilePic"
        [position]="contact.position"
        [hideWorkspace]="hideWorkspace"
        [identityVerificationStatus]="contact.identityVerificationStatus"
        [showBadge]="true"
      >
      </app-celo-user-card>
    </div>

    <!-- Workspaces on the new-chat screen -->
    <div *ngIf="type === 'workspace' && companyInFocus" class="contact">
      <app-celo-user-card
        [companyInFocus]="companyInFocusMap[contact.userId]"
        [companyInFocusId]="companyInFocusMap[contact.userId]?.id"
        [userId]="contact.userId"
        [contact]="contact"
        [replaceMyName]="contact.firstName + ' ' + contact.lastName + ' (You)'"
        [profileId]="userAccount.userId"
        [workspaces]="contact.workplaces"
        [title]="contact.title"
        [firstName]="contact.firstName"
        [lastName]="contact.lastName"
        [profilePic]="contact.profilePic"
        [position]="contact.position"
        [hideWorkspace]="hideWorkspace"
        [identityVerificationStatus]="contact.identityVerificationStatus"
        [showBadge]="true"
        [onlyShowPosition]="true"
      >
      </app-celo-user-card>
    </div>

    <div *ngIf="type === 'colleagues'" class="contact">
      <app-celo-user-card
        [companyInFocus]="companyInFocusMap[contact.userId]"
        [companyInFocusId]="companyInFocusMap[contact.userId]?.id"
        [userId]="contact.userId"
        [contact]="contact"
        [replaceMyName]="contact.firstName + ' ' + contact.lastName + ' (You)'"
        [profileId]="userAccount.userId"
        [workspaces]="contact.workplaces"
        [title]="contact.title"
        [firstName]="contact.firstName"
        [lastName]="contact.lastName"
        [profilePic]="contact.profilePic"
        [hideWorkspace]="hideWorkspace"
        [identityVerificationStatus]="contact.identityVerificationStatus"
        [showBadge]="true"
      >
      </app-celo-user-card>
    </div>

    <div *ngIf="type === 'suggestions'" class="contact">
      <app-celo-user-card
        [userId]="contact.userId"
        [contact]="contact"
        [workspaces]="contact.workplaces"
        [title]="contact.title"
        [firstName]="contact.firstName"
        [lastName]="contact.lastName"
        [profilePic]="contact.profilePic"
        [position]="contact.position"
        [showCompany]="showCompany"
        [showAllWorkspaces]="showAllWorkspaces"
        [department]="contact.department"
        [showDepartment]="showDepartment"
        [hideWorkspace]="hideWorkspace"
        [identityVerificationStatus]="
          contact.identityVerified ? 'Verified' : 'Unverified'
        "
        [showBadge]="true"
        [hideProfession]="hideProfession"
        [profession]="contact.profession"
        [professionVerified]="contact.professionVerified"
        [isWorkspaceVerified]="contact.workspaceVerified"
      >
      </app-celo-user-card>
    </div>
  </div>
</div>
