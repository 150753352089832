<ng-container
  *ngIf="{
    recipients: recipients$ | async,
    viewState: viewState$ | async
  } as data"
>
  <div class="container" cdkVirtualScrollingElement>
    <div class="header">
      <div class="flex items-center w-full justify-between pl-16 pr-4 mb-2">
        <h2 class="title">Broadcast Status</h2>
        <button mat-icon-button (click)="back.emit()">
          <mat-icon class="back">arrow_back</mat-icon>
        </button>
      </div>

      <form
        id="search-form"
        class="flex item-center w-full gap-12px px-16"
        [formGroup]="searchForm"
        (ngSubmit)="submit()"
      >
        <input
          class="w-full"
          [class.submitted]="isSearchSubmitted$ | async"
          id="search"
          type="text"
          formControlName="search"
          placeholder="Search by name"
          (focus)="onSearchFocus()"
          (blur)="onSearchBlur()"
        />
        <button
          *ngIf="searchForm.controls.search.dirty"
          mat-button
          type="reset"
          class="celo-tertiary-button"
          (click)="reset()"
        >
          Cancel
        </button>
      </form>

      <div
        *ngIf="isFiltersEnabled$ | async"
        class="flex item-center justify-between filters-container"
      >
        <form
          id="filter-form"
          class="flex item-center w-full g-2 flex-wrap flex-1 items-center"
          [formGroup]="filterForm"
        >
          <div *ngFor="let status of statuses" class="tab">
            <input
              [id]="status"
              name="status"
              type="radio"
              formControlName="status"
              [value]="status"
            />
            <label
              [for]="status"
              [class.selected]="filterForm.controls.status.value === status"
              >{{ status }}</label
            >
          </div>
        </form>

        <button
          mat-button
          class="celo-tertiary-button refresh"
          (click)="refresh()"
        >
          Refresh
        </button>
      </div>

      <!-- Empty state -->
      <div
        *ngIf="
          data.viewState === ViewState.SearchFocused ||
          (data.viewState !== ViewState.Loading && !data.recipients?.length)
        "
        class="w-full flex items-center justify-center flex-col empty"
      >
        <img
          [src]="
            data.viewState === ViewState.FilterResults
              ? '../../../assets/broadcast-empty.png'
              : '../../../assets/search-conversation.svg'
          "
          alt="No results"
          width="300"
          height="300"
        />
        <div class="message">
          {{
            data.viewState === ViewState.FilterResults
              ? filterEmptyStateMessages[filterForm.controls.status.value]
              : data.viewState === ViewState.SearchFocused
                ? "Search broadcast status"
                : "No results"
          }}
        </div>
        <div
          *ngIf="data.viewState === ViewState.SearchFocused"
          class="description"
        >
          You can search for people by their name.
        </div>
      </div>
    </div>

    <div
      *ngIf="data.viewState == ViewState.Loading"
      class="spinner-container fade-in"
    >
      <app-basic-spinner diameter="20" strokeWidth="2"></app-basic-spinner>
    </div>

    <div
      *ngIf="
        data.viewState === ViewState.SearchFocused ||
        data.viewState === ViewState.SearchResults ||
        data.viewState === ViewState.FilterResults
      "
      class="loaded flex-1"
      [class.hidden]="data.viewState === ViewState.SearchFocused"
    >
      <!-- Results -->
      <cdk-virtual-scroll-viewport
        *ngIf="data.recipients?.length"
        itemSize="54"
        minBufferPx="300"
        maxBufferPx="600"
        class="h-full"
      >
        <div
          *cdkVirtualFor="let recipient of data.recipients"
          class="celo-hoverable"
        >
          <app-popup-menu #popupMenu>
            <app-basic-user-avatar-card
              popup-menu-trigger
              [userId]="recipient.user.id"
              [name]="recipient.user.name"
              [description]="
                recipient.statusChangedTime
                  | dateFormat: 'medium-uppercase-period'
              "
              [isIdentityVerified]="
                recipient.user.verificationInfo.isIdentityVerified
              "
              [isWorkspaceVerified]="
                recipient.user.verificationInfo.isWorkspaceVerified
              "
              [isProfessionVerified]="
                recipient.user.verificationInfo.isProfessionVerified
              "
            >
              <div
                *ngIf="data.viewState === ViewState.SearchResults"
                end
                class="status flex items-center"
              >
                {{
                  (recipient.status.toLowerCase() === "read"
                    ? "Seen"
                    : recipient.status
                  ) | titlecase
                }}
              </div>
            </app-basic-user-avatar-card>

            <ng-container popup-menu-content>
              <div class="menu-title">
                {{ recipient.user.name }}
              </div>

              <mat-divider></mat-divider>

              <button mat-menu-item (click)="handleSecureMessage(recipient)">
                Secure Message
              </button>
              <button mat-menu-item (click)="handleViewProfile(recipient)">
                View Profile
              </button>
            </ng-container>
          </app-popup-menu>
        </div>

        <!-- Load more button -->
        <div class="w-full flex items-center justify-center">
          <button
            *ngIf="hasMore$ | async"
            mat-button
            class="celo-tertiary-button load-more"
            (click)="loadMore()"
            [disabled]="isLoadingMore$ | async"
          >
            <ng-container *ngIf="!(isLoadingMore$ | async); else loadingMore">
              Load more
            </ng-container>

            <ng-template #loadingMore>
              <div class="spinner-container">
                <app-basic-spinner
                  diameter="20"
                  strokeWidth="2"
                ></app-basic-spinner>
              </div>
            </ng-template>
          </button>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</ng-container>
