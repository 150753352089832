import { Component, Input } from "@angular/core";

@Component({
  selector: "app-basic-label",
  templateUrl: "./basic-label.component.html",
  styleUrls: ["./basic-label.component.scss"],
})
export class BasicLabelComponent {
  @Input() public text: string | null = null;
}
