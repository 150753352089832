import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatExpansionPanel } from "@angular/material/expansion";
import { AlertComponent } from "@modules/core/components";
import { VoipService } from "@modules/core/services/voip.service";
import { ProfileService } from "app/modules/profile/profile.service";
import { PinscreenService } from "app/pinscreen/pinscreen.service";
import { environment } from "environments/environment";
import { finalize } from "rxjs/operators";
import {
  AlertService,
  AuthService,
  SharedService,
  UserAccountService,
} from "../modules/core";
import { AccountService } from "./../modules/core/services/account.service";

@Component({
  selector: "app-setting",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss", "../../celo-input.scss"],
})
export class SettingsComponent implements OnInit, OnChanges {
  // @HostBinding('class.celo-500-host') celo500Host: Host = true;
  @Input()
  userAccount: any;

  blocked = [];
  @Input()
  type = "privacy";

  image_quality: boolean;
  workspaceNotificationEnabled: boolean;
  mentionNotificationEnabled: boolean;

  @Output() openUser = new EventEmitter<string>();

  passcode = "";
  privacy_mobile = "";
  privacy_original = "";
  currentPassword = "";
  newPassword = "";
  confirmPassword = "";

  config = {
    general: {
      title: "General",
    },
    privacy: {
      title: "Privacy",
    },
    security: {
      title: "Security",
    },
    notifications: {
      title: "Notifications",
    },
  };

  privacy_settings = [
    {
      title: "Celo Network Discoverability",
      type: "discoverability",
      subtitle: "To search the Celo Network, you must have this turned on.",
      options: [
        {
          title: "Let users find me via the Celo Network  ",
          type: "network",
          default: true,
        },
      ],
    },
    {
      title: "Mobile Number",
      type: "phone",
      subtitle: "Who can see my mobile number?",
      options: [
        {
          title: "My Connections",
          type: "myConnections",
          default: true,
        },
        {
          title: "People from my department(s)",
          type: "myDepartments",
          default: false,
        },
        {
          title: "Other departments in my workspace(s)",
          type: "otherDepartments",
          default: false,
        },
      ],
    },
    {
      title: "Email Address",
      type: "email",
      subtitle: "Who can see my primary and work email addresses?",
      options: [
        {
          title: "My Connections",
          type: "myConnections",
          default: true,
        },
        {
          title: "People from my department(s)",
          type: "myDepartments",
          default: false,
        },
        {
          title: "Other departments in my workspace(s)",
          type: "otherDepartments",
          default: false,
        },
      ],
    },
  ];

  privacy: any = {
    phone: {
      myConnections: true,
      myDepartments: false,
      otherDepartments: false,
      otherWorkplaces: false,
    },
    email: {
      myConnections: true,
      myDepartments: false,
      otherDepartments: false,
      otherWorkplaces: false,
    },
    discoverability: {
      network: true,
    },
  };

  privacy_mobile_default = "Self";
  saving: boolean;
  privacy_url: any =
    environment.celoApiEndpoint + "/api/v2/Account/Privacy/Settings";

  @ViewChild("pinPanel") pannel?: MatExpansionPanel;

  constructor(
    private pinService: PinscreenService,
    private alertService: AlertService,
    private sharedService: SharedService,
    private userAccountService: UserAccountService,
    private profileService: ProfileService,
    private authService: AuthService,
    private http: HttpClient,
    private accountService: AccountService,
    private matDialog: MatDialog,
    private voipService: VoipService
  ) {}

  ngOnInit() {
    this.sharedService.onBlocklistSubject.subscribe(() => this.getBlocked());
    this.getImageQuality();
  }

  ngOnChanges() {
    if (this.type == "privacy") {
      this.getPrivacy();
      this.getBlocked();
    } else if (this.type == "notifications") {
      this.loadNotificationSetting();
    }
  }

  getBlocked() {
    const instance = this;
    const path = environment.celoApiEndpoint + "​/api/blocks";
    this.sharedService.getObjectById(path).subscribe((data) => {
      instance.blocked = [];
      if (!data || !data.blockedByMe || !data.blockedByMe.length) {
        return;
      }
      instance.blocked = data.blockedByMe;
    });
  }

  getPrivacy() {
    const path = this.privacy_url;
    this.sharedService.getObjectById(path).subscribe((data) => {
      if (!data || !data.length) {
        return;
      }
      this.setPrivacy(data);
    });
  }

  setPrivacy(privacy) {
    if (privacy[0].settings) {
      this.privacy["phone"]["myConnections"] = false;
      this.privacy["phone"]["myDepartments"] = false;
      this.privacy["phone"]["otherDepartments"] = false;
      this.privacy["phone"]["otherWorkplaces"] = false;

      if (
        privacy[0].settings["myConnections"] == true ||
        privacy[0].settings["myConnections"] == "true"
      ) {
        this.privacy["phone"]["myConnections"] = true;
      }
      if (
        privacy[0].settings["myDepartments"] == true ||
        privacy[0].settings["myDepartments"] == "true"
      ) {
        this.privacy["phone"]["myDepartments"] = true;
      }
      if (
        privacy[0].settings["otherDepartments"] == true ||
        privacy[0].settings["otherDepartments"] == "true"
      ) {
        this.privacy["phone"]["otherDepartments"] = true;
      }
      if (
        privacy[0].settings["otherWorkplaces"] == true ||
        privacy[0].settings["otherWorkplaces"] == "true"
      ) {
        this.privacy["phone"]["otherWorkplaces"] = true;
      }
    }

    if (privacy[1].settings) {
      this.privacy["email"]["myConnections"] = false;
      this.privacy["email"]["myDepartments"] = false;
      this.privacy["email"]["otherDepartments"] = false;
      this.privacy["email"]["otherWorkplaces"] = false;

      if (
        privacy[1].settings["myConnections"] == true ||
        privacy[1].settings["myConnections"] == "true"
      ) {
        this.privacy["email"]["myConnections"] = true;
      }
      if (
        privacy[1].settings["myDepartments"] == true ||
        privacy[1].settings["myDepartments"] == "true"
      ) {
        this.privacy["email"]["myDepartments"] = true;
      }
      if (
        privacy[1].settings["otherDepartments"] == true ||
        privacy[1].settings["otherDepartments"] == "true"
      ) {
        this.privacy["email"]["otherDepartments"] = true;
      }
      if (
        privacy[1].settings["otherWorkplaces"] == true ||
        privacy[1].settings["otherWorkplaces"] == "true"
      ) {
        this.privacy["email"]["otherWorkplaces"] = true;
      }
    }
    if (privacy[2].settings) {
      this.privacy["discoverability"]["network"] = false;
      if (
        privacy[2].settings["network"] == true ||
        privacy[2].settings["network"] == "true"
      ) {
        this.privacy["discoverability"]["network"] = true;
      }
    }
  }

  settingChange(setting) {
    this.savePrivacy(setting);
  }

  savePrivacy(setting) {
    const userPrivacy = [];
    const patch = {
      name: setting.type,
      settings: {
        myConnections: this.privacy[setting.type]["myConnections"],
        myDepartments: this.privacy[setting.type]["myDepartments"],
        otherDepartments: this.privacy[setting.type]["otherDepartments"],
        otherWorkplaces: this.privacy[setting.type]["otherWorkplaces"],
        network: this.privacy[setting.type]["network"],
      },
    };
    userPrivacy.push(patch);
    const path = this.privacy_url;

    this.sharedService.patchObjectById(path, userPrivacy).subscribe(
      (result) => {
        if (!result) {
          this.alertService.confirm(
            "",
            this.sharedService.STANDARD_ERROR_MESSAGE,
            "Ok",
            "",
            true
          );
          return;
        }

        // TODO refactor settings to be modified through new AccountService in CoreModule
        // This a temporary solution to sync the settings in AccountService when they're updated
        this.accountService.getPrivacySettings().subscribe();

        this.alertService.showSnackBar("Privacy settings updated", 2);
        this.setPrivacy(result);
      },
      (error) => {
        this.privacy_mobile = this.privacy_original;
        this.alertService.confirm(
          "",
          this.sharedService.STANDARD_ERROR_MESSAGE,
          "Ok",
          "",
          true
        );
      }
    );
  }

  getImageQuality() {
    this.image_quality = this.sharedService.getImageQuality();
  }

  qualitySettingChange() {
    if (this.image_quality) {
      this.sharedService.removeImageQuality();
    } else {
      this.sharedService.setImageQuality();
    }
    this.getImageQuality();
  }

  workspaceNotificationSettingChange() {
    const path =
      environment.celoApiEndpoint + "/api/v2/user/notification/settings";
    const payload = {};
    payload["workspaceNotificationEnabled"] = this.workspaceNotificationEnabled
      ? false
      : true;

    this.workspaceNotificationEnabled = payload["workspaceNotificationEnabled"];
    this.sharedService.putObjectById(path, {}, payload).subscribe(
      (res) => {
        // this.workspaceNotificationEnabled = payload['workspaceNotificationEnabled'];
        this.loadNotificationSetting();
      },
      (err) => {
        this.workspaceNotificationEnabled = payload[
          "workspaceNotificationEnabled"
        ]
          ? false
          : true;
      }
    );
  }

  mentionNotificationSettingChange() {
    const path =
      environment.celoApiEndpoint + "/api/v2/user/notification/settings";
    const payload = {};
    payload["mentionNotificationEnabled"] = this.mentionNotificationEnabled
      ? false
      : true;

    this.mentionNotificationEnabled = payload["mentionNotificationEnabled"];
    this.sharedService.putObjectById(path, {}, payload).subscribe(
      (res) => {
        // this.mentionNotificationEnabled = payload['mentionNotificationEnabled'];
        this.loadNotificationSetting();
      },
      (err) => {
        this.mentionNotificationEnabled = payload["mentionNotificationEnabled"]
          ? false
          : true;
      }
    );
  }

  loadNotificationSetting() {
    const instance = this;
    // this.workspaceNotificationEnabled = false;
    this.userAccountService.getUserAccount(true, function (userAccount) {
      instance.workspaceNotificationEnabled = false;
      if (userAccount["isWorkspaceNotificationEnabled"]) {
        instance.workspaceNotificationEnabled = true;
      }
      if (userAccount["isMentionNotificationEnabled"]) {
        instance.mentionNotificationEnabled = true;
      }
    });
  }

  savePin() {
    this.pinService.setOrUpdatePin(this.passcode).subscribe({
      next: () => {
        this.pannel.expanded = false;
        this.passcode = "";
      },
      error: (e) => {
        AlertComponent.openErrorDialog(
          this.matDialog,
          e instanceof Error ? e.message : null
        );
      },
    });
  }

  changePassword() {
    this.voipService.openLeaveCallDialogIfRequired().subscribe({
      next: (isCallInProgress) => {
        if (isCallInProgress) return;

        if (this.newPassword !== this.confirmPassword) {
          this.alertService.alert(
            "Failed to change password",
            "Passwords do not match. Please try again.",
            true
          );
          return;
        }

        if (this.newPassword === this.currentPassword) {
          this.alertService.error(
            "New Password should not be same as current password"
          );
          return;
        }

        const path = `${environment.celoApiEndpoint}/api/Account/Password/Change`;
        const data = {
          oldPassword: this.currentPassword,
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword,
        };
        // this.sharedService.isLoading = true;
        this.saving = true;
        this.http
          .post(path, data)
          .pipe(finalize(() => (this.sharedService.isLoading = false)))
          .subscribe(
            () => {
              this.authService.logout("Change password in settings");
            },
            (err) => {
              const error = err["error"];
              this.saving = false;
              let errorText = "Error changing password";
              if (
                error &&
                error.errors &&
                error.errors[0] &&
                error.errors[0]["message"]
              ) {
                errorText = error.errors[0]["message"];
              }
              this.alertService.alert(
                "Failed to change password",
                errorText,
                true
              );
            }
          );
      },
    });
  }

  inviteColleagues() {
    this.profileService.shareProfile();
  }
}
