<div [style.height.px]="height" [style.width.px]="width">
  <img
    class="appear_2"
    *ngIf="type === 'Case'"
    src="../../../assets/icons/icon-case.svg"
    alt=""
  />

  <div id="conversation-picture-container" *ngIf="type === 'Group'">
    <img class="appear_2" src="../../../assets/icons/icongroup.svg" alt="" />

    <img
      *ngIf="!!conversationPictureUri"
      class="image overlay"
      [class.hidden]="!isConversationImageLoaded"
      [src]="conversationPictureUri | clink | secure"
      (load)="handleConversationImageLoaded()"
    />
  </div>

  <div
    class="appear_2"
    *ngIf="
      (type === 'User' ||
        type === 'Chat' ||
        type === 'SelfChat' ||
        type === 'TeamChat' ||
        type === 'External') &&
      participant
    "
  >
    <app-avatar
      [name]="participant.firstName + ' ' + participant.lastName"
      [userId]="participant.userId"
      [width]="width"
      [height]="height"
      [hasImage]="!!participant.profilePic && type !== 'External'"
      [picUrl]="participant.profilePic"
      [refreshCount]="refreshCount"
      [doNotDisturbToUtc]="showDNDBadge ? participant.doNotDisturbToUtc : ''"
      [dndBadgeSize]="'16'"
      [badgeMargin]="'-1'"
      [badgeBorder]="'1'"
      [dndMessage]="participant.firstName + ' is set to do not disturb'"
      [showBadge]="showBadge"
      [badgeSize]="badgeSize"
      [workspaces]="participant.workplaces"
      [user]="participant"
      [identityVerificationStatus]="participant.identityVerificationStatus"
      [showTooltip]="false"
      [clickable]="clickable"
      [name_identity_verified_as]="
        participant.legalFirstName + ' ' + participant.lastName
      "
      [isExternal]="participant.isExternal ?? false"
    ></app-avatar>
  </div>
</div>
