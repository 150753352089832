import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { environment } from "@env";
import { LibraryItemComponent } from "@modules/library/library-item/library-item.component";
import { LibraryService } from "@modules/library/library.service";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-document",
  templateUrl: "./document.component.html",
  styleUrls: ["./document.component.scss"],
})
export class DocumentComponent extends LibraryItemComponent implements OnInit {
  // todo: remove parent class, use composition not inheritence with angular
  link: string;
  source: any;
  loaded = false;
  @Input() ownFile: boolean;

  constructor(private http: HttpClient, libraryService: LibraryService) {
    super(libraryService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.link = `${environment.celoApiEndpoint}/api/PatientFiles/${this.libItem.payload.id}`;
    this.load();
  }

  protected getType() {
    return "document";
  }

  private load() {
    this.loaded = false;
    this.http
      .get<ArrayBuffer>(this.link, { responseType: "arraybuffer" as "json" })
      .pipe(
        tap(() => {
          // this.loaded = true
        })
      )
      .subscribe((ab) => {
        this.source = {
          data: new Uint8Array(ab),
        };
      });
  }
}
