import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SharedService } from "../modules/core/old/shared.service";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";
import {
  CompanyBasicModel,
  CompanyBasicModelApiPagedResult,
  UserWorkspaceModel,
  WorkplaceModel,
  WorkspaceModel,
} from "types";
import { AddComponent } from "./add/add.component";
import { CreateWorkspaceComponent } from "./create-workspace/create-workspace.component";

/** @deprecated Use the new `WorkspacesService` in `CoreModule` instead. */
@Injectable({
  providedIn: "root",
})
export class WorkspaceService {
  public workspaceChange = new Subject<any>();
  workspaceChangeObs = this.workspaceChange.asObservable();

  public workspaceEmptyStateChange = new Subject<any>();
  workspaceEmptyStateChangeObs = this.workspaceEmptyStateChange.asObservable();

  constructor(
    private dialog: MatDialog,
    private sharedService: SharedService
  ) {}

  /** @deprecated Use `getUserWorkspace` in `UserService` instead. */
  loadWorkspace(id): Observable<UserWorkspaceModel> {
    const path = environment.celoApiEndpoint + "/api/v2/user/workspaces/" + id;
    return this.sharedService.getObjectById(path);
  }

  loadWCompany(id): Observable<WorkspaceModel> {
    const path = environment.celoApiEndpoint + `/api/v2/workspaces/${id}`;
    return this.sharedService.getObjectById(path);
  }

  /** @deprecated Use `getUserWorkspace` in `UserService` instead. */
  getWorkspace(id, callback) {
    const path = environment.celoApiEndpoint + "/api/v2/user/workspaces/" + id;
    this.sharedService.getObjectById(path).subscribe(
      (workspace) => {
        if (callback) {
          return callback(workspace);
        }
      },
      (err) => {
        if (callback) {
          return callback(null);
        }
      }
    );
  }

  getAllWorkspaces(
    email?: string
  ): Observable<CompanyBasicModelApiPagedResult> {
    const page = "0";
    const pageSize = "1000";
    const url = `${environment.celoApiEndpoint}/api/v2/Companies`;
    const params = {
      Page: page,
      PageSize: pageSize,
    };
    if (email) {
      params["EmailDomain"] = email;
    }

    return this.sharedService.getObjectById(url);
  }

  getWorkspaces(callback) {
    const instance = this;
    const path = environment.celoApiEndpoint + "/api/v2/user/workspaces";
    this.sharedService.getObjectById(path).subscribe(
      (workspaces) => {
        if (callback) {
          return callback(workspaces);
        }
      },
      (err) => {
        if (callback) {
          return callback(null);
        }
      }
    );
  }

  joinWorkspaceFlow(callback) {
    const dialogRef = this.dialog.open(AddComponent, {
      autoFocus: false,
      panelClass: "alert-panel",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      if (result) {
        if (callback) {
          callback(result);
        }
      }
    });
  }

  createWorkspaceFlow(callback, hideAutoInviteToWorkspace?: boolean) {
    const instance = this;
    const dialogRef = this.dialog.open(CreateWorkspaceComponent, {
      autoFocus: false,
      panelClass: "alert-panel",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }
      if (result) {
        if (result.type == "Workspace" && !hideAutoInviteToWorkspace) {
          instance.sharedService.invite(
            "workspace",
            result.invitationUri,
            result.id,
            null,
            result.name
          );
        }
        if (callback) {
          callback(result);
        }
      }
    });
  }

  getValidEmailsFromProfile(profile: any) {
    const emails = [];
    if (profile.email) {
      emails.push(profile.email);
    }
    for (const workspace of profile.workplaces) {
      if (workspace.email && emails.indexOf(workspace.email) == -1) {
        emails.push(workspace.email);
      }
    }
    return emails;
  }

  getPartnerWorkspaces(
    allWorkspaces: CompanyBasicModel[],
    myWorkspaceIds: string[]
  ) {
    return this.sharedService.filterCompaniesByIds(
      allWorkspaces,
      myWorkspaceIds,
      false
    );
  }

  getMyWorkspacesOnly(
    allWorkspaces: CompanyBasicModel[],
    myWorkspaceIds: string[]
  ) {
    return this.sharedService.filterCompaniesByIds(
      allWorkspaces,
      myWorkspaceIds
    );
  }

  getMyCompanyByCompanyId(id, userAccount) {
    if (!userAccount) {
      return null;
    }
    const companies = userAccount["workplaces"]; // required
    if (!companies) {
      return null;
    }
    for (const company of companies) {
      if (company.companyId == id) {
        return company;
      }
    }
    return null;
  }

  isWorkspaceVerified(workspaces: any[]): boolean {
    if (!workspaces) return false;
    for (const workspace of workspaces) {
      if (
        workspace.isActive != false &&
        workspace.verificationStatus == "Verified"
      ) {
        return true;
      }
    }
    return false;
  }

  getDepartmentFromWorkspaceList(workplaces: WorkplaceModel[], id: any) {
    let departmentName;
    workplaces.forEach((workplace) => {
      if (workplace.companyId == id) {
        departmentName = workplace.departmentName;
      }
    });
    return departmentName;
  }
}
