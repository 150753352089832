import { createReducer, createSelector, on } from "@ngrx/store";
import { FullUserProfileModel } from "@types";
import { produce } from "immer";
import { AccountApiActions } from "./user.actions";
import { RootState } from "..";

export type UserState = FullUserProfileModel | null;

export const userInitialState: UserState = null;

export const userReducer = createReducer(
  userInitialState,
  on(AccountApiActions.loadUserSuccess, (state, action) =>
    produce(state, (draft) => {
      return action.user;
    })
  ),
  on(AccountApiActions.loadUserError, (state, action) =>
    produce(state, (draft) => {
      // #TODO
    })
  )
);

export const selectUser = (state: RootState) => state.user;
export const selectUserId = (state: RootState) => state.user?.userId ?? null;

export const selectisExternalConversationsEnabled = createSelector(
  selectUser,
  (user) => {
    return (
      user.workplaces?.some(
        (w) => w.claims?.workspaceAllowExternalConversations === "true"
      ) ?? false
    );
  }
);
