import { HttpClient, HttpParams } from "@angular/common/http";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "@env";
import { UserAccountService } from "@modules/core";
import { AlertComponent } from "@modules/core/components";
import { AlertService } from "app/modules/core/old/alert.service";
import { FileUploadService } from "app/modules/core/old/file-upload.service";
import { config } from "configurations/config";
import { FileUploader } from "ng2-file-upload";
import { retry, tap } from "rxjs/operators";
import { LibraryLightboxDocumentContent } from "../library-lightbox/library-lightbox.component";
import { LibraryService } from "../library.service";
import { DocumentsService } from "./documents.service";

export interface DocumentsListItem {
  payload: any;
  selected: boolean;
  prev?: any;
  next?: any;
}

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.scss"],
})
export class DocumentsComponent implements OnInit, OnDestroy, AfterViewChecked {
  documents: DocumentsListItem[] = [];
  intervalSub = null;

  loading = true;
  searchTerm = "";
  actionSub = null;
  reloadSub = null;
  hideLoadMore = false;
  pageSize = 20;

  isSelecting = false;
  public uploader: FileUploader;
  dropZoneActive = "";
  userAccount: any;
  userId: string;

  constructor(
    private http: HttpClient,
    private libraryService: LibraryService,
    private documentsService: DocumentsService,
    private alertService: AlertService,
    private fileUploadService: FileUploadService,
    private _changeDetectionRef: ChangeDetectorRef,
    private userService: UserAccountService,
    private matDialog: MatDialog
  ) {
    const type = "Documents";
    const url = `${environment.celoApiEndpoint}/api/patientfiles/`;
    const data = {
      type,
      queueLimit: config.max_file_upload_limit + 1,
      allowPatient: true,
      url,
    };
    const instance = this;
    this.fileUploadService.initialize(data, function () {
      instance.reSearch();
      // instance.libraryService.emitReloadDocument(true)
    });
    this.uploader = this.fileUploadService.uploader;
  }

  ngOnInit() {
    this.reSearch();
    this.intervalSub = this.libraryService.reloadDocumentEmitted$.subscribe(
      () => {
        if (!this.isSelecting) {
          this.reSearch();
        }
      }
    );

    const instance = this;
    this.userService.getUserAccount(false, function (acc) {
      instance.userAccount = acc;
      instance.userId = instance.userAccount.userId;
    });
    this.actionSub = this.libraryService.documentActionEmitted$.subscribe(
      this.onAction.bind(this)
    );
  }

  ngAfterViewChecked(): void {
    this._changeDetectionRef.detectChanges();
  }

  fileDropped() {}
  dropZoneState(state: boolean) {
    this.dropZoneActive = state ? "valid" : "";
  }

  handleDrop(files: FileList) {
    this.cancelSelect();
  }

  handleFilesSelection(files: FileList) {
    this.cancelSelect();
  }

  closeUpload() {
    this.fileUploadService.uploader.clearQueue();
  }

  ngOnDestroy() {
    if (this.actionSub) {
      this.actionSub.unsubscribe();
    }
    if (this.reloadSub) {
      this.reloadSub.unsubscribe();
    }
    this.libraryService.emitCanSelect(false);
  }

  startSelect() {
    this.isSelecting = true;
    this.libraryService.emitCanSelect(this.isSelecting);
  }

  cancelSelect() {
    this.isSelecting = false;
    this.libraryService.emitCanSelect(this.isSelecting);
    this.libraryService.emitCanSelectReset(true);
  }

  onNewScrollPosition() {
    this.pageSize += 20;
    this.doSearch();
  }

  queryChange(searchTerm: string) {
    if (typeof searchTerm != "string") {
      return;
    }
    this.searchTerm = searchTerm;
    this.documents = [];
    this.doSearch();
  }

  doSearch() {
    this.loading = true;
    const path = `${environment.celoApiEndpoint}/api/patientFiles`;
    const params = new HttpParams()
      .set("Page", "0")
      .set("PageSize", String(this.pageSize))
      .set("Search", this.searchTerm);

    this.http
      .get<any>(path, { params })
      .pipe(
        retry(3),
        tap(() => (this.loading = false))
      )
      .subscribe({
        next: (data) => {
          if (data.total < this.pageSize) {
            this.hideLoadMore = true;
          } else {
            this.hideLoadMore = false;
          }
          const documents = data.data.map((item) => ({
            payload: item,
            selected: false,
            prev: null,
            next: null,
          }));
          for (let i = 0; i < documents.length; i++) {
            const a = documents[i];
            const b = documents[(i + 1) % documents.length];
            a.next = b;
            b.prev = a;
            if (!this.documents.find((d) => d.payload.id === a.payload.id)) {
              this.documents.push(a);
            }
          }
        },
        error: (err) => {
          AlertComponent.openErrorDialog(
            this.matDialog,
            "Error loading Documents"
          );
          this.loading = false;
        },
      });
  }

  private onAction(action: string) {
    if (action === "preview") {
      this.preview();
    } else if (action === "download") {
      this.download();
    } else if (action === "share") {
      this.share();
    } else if (action === "delete") {
      this.delete();
    }
  }

  private share() {
    const documents = this.getSelected();
    if (!documents.length) {
      this.noneSelected();
      return;
    }
    if (documents && documents.length) {
      this.documentsService.share(documents);
    }
  }

  onSelected() {
    this.libraryService.emitShowAction(this.anySelected());
  }

  noneSelected() {
    this.alertService.showSnackBar("No documents selected !", 2);
    return;
  }

  private anySelected(): boolean {
    for (const document of this.documents) {
      if (document.selected) {
        return true;
      }
    }
    return false;
  }

  public getSelectedCount(): number {
    let count = 0;
    for (const document of this.documents) {
      if (document.selected) {
        count++;
      }
    }
    return count;
  }

  private preview() {
    const document = this.getFirstSelected();
    const data: LibraryLightboxDocumentContent = {
      ...document,
      mediaType: "document",
    };
    if (document) {
      this.libraryService.openLightbox(data);
    }
  }

  public getFirstSelected() {
    for (const document of this.documents) {
      if (document.selected) {
        return document;
      }
    }
    return null;
  }

  private download() {
    const documents = this.getSelected();
    if (!documents.length) {
      this.noneSelected();
      return;
    }
    this.documentsService.downloadDocs(documents);
  }

  public delete() {
    const documents = this.getSelected();
    if (!documents.length) {
      this.alertService.showSnackBar("No document selected !", 2);
      return;
    }
    const i = this;
    let count = 0;
    this.documentsService.deleteDocuments(documents, function (status) {
      count++;
      if (count >= documents.length) {
        i.reload();
      }
    });
  }

  private getSelected(): any[] {
    return this.documents.filter((d) => d.selected).map((d) => d.payload);
  }

  public reSearch() {
    this.documents = [];
    this.loading = true;
    this.doSearch();
  }

  public reload() {
    this.documents = [];
    this.loading = true;
    this.pageSize = 20;
    this.libraryService.emitReloadDocument(true);
  }
}
