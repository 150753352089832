import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AlertService } from "app/modules/core/old/alert.service";

@Component({
  selector: "app-link-share",
  templateUrl: "./link-share.component.html",
  styleUrls: ["./link-share.component.scss"],
})
export class LinkShareComponent {
  @Input() link = "";
  @Input() linkFallback = "";
  @Input() title = "";
  @Input() subtitle = "";
  @Input() copyButttonText = "";
  @Input() canReset = true;
  @Input() shareBody = true;
  @Input() hideLink;
  @Input() disableEmailInvite = false;
  @Output() copyClick = new EventEmitter<any>();
  @Output() linkClick = new EventEmitter<any>();
  @Output() shareClick = new EventEmitter<any>();
  @Output() resetLink = new EventEmitter<any>();

  constructor(private alertService: AlertService) {}

  error() {
    this.alertService.showSnackBar("Connect to internet to generate a link", 3);
    return;
  }
}
