import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { SharedService, UserAccountService } from "../../core";

@Component({
  selector: "app-add-company-details",
  templateUrl: "./add-company-details.component.html",
  styleUrls: [
    "./add-company-details.component.scss",
    "../../../../celo-input.scss",
  ],
})
export class AddCompanyDetailsComponent implements OnInit, OnChanges {
  @Input()
  company: any;

  @Input()
  workspace: any;

  @Input()
  filterOutJoinedWorkspaces = false;

  @Input()
  joinedWorkspaces = [];

  @Input()
  workspaceSubtitle: string;

  @Input()
  companyReadonly = false;

  @Input()
  department = "";

  @Input()
  position = "";

  @Input()
  email = "";

  @Input()
  hideEmailField = false;

  @Input()
  countryCode = "";

  @Input()
  autoCreateWorkspaceOnNoWorkspaces = true;

  @Input()
  hideCompanyField = false;

  departments = [];

  myForm: UntypedFormGroup;
  private change_sub: any;

  @Output() dataChange = new EventEmitter<any>();
  @Output() validity = new EventEmitter<boolean>();
  @Output() exit = new EventEmitter<any>();
  @Output() companiesLoaded = new EventEmitter<any>();
  @Output() createWorkspaceEvent = new EventEmitter<any>();

  companies: any;
  selectedCompany: any = {};
  hasChanged: boolean;
  data: any = {};
  originalCompany: string;
  protected basePath = environment.celoApiEndpoint;
  profile: any;
  myCompanies: any;
  myCompaniesIds = [];
  selectedCompanies = [];
  loading = false;
  canEmitChange = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private userAccountService: UserAccountService
  ) {}

  ngOnInit() {
    let group = {};
    group = {
      company_field: { value: "", disabled: false },
      department_field: { value: "", disabled: false },
      position_field: { value: "", disabled: false },
      email_field: { value: "", disabled: false },
    };
    this.myForm = this.formBuilder.group(group);
    this.onChanges();

    this.getCompanies(this.email);
    if (this.workspace && this.workspace.companyId) {
      this.getDepartments(this.workspace.companyId);
    }
    this.getMyCompanies();
  }

  ngOnChanges() {
    this.originalCompany = this.company;
  }

  initialize() {
    if (this.change_sub) {
      this.change_sub.unsubscribe();
    }
  }

  getMyCompanies() {
    const instance = this;
    this.userAccountService.getUserAccount(false, function (acc) {
      instance.myCompanies = instance.sharedService.getCompaniesInfo(acc);
      instance.myCompaniesIds = [];
      for (const company of instance.myCompanies) {
        if (company.companyId) {
          instance.myCompaniesIds.push(company.companyId);
        }
      }
    });
  }

  getCompanies(email?) {
    this.loading = true;
    const instance = this;
    this.userAccountService.getCompanies(email, function (companies) {
      instance.loading = false;
      instance.companiesLoaded.emit(companies);
      if (companies) {
        // non-recogonized email
        if (companies.length == 0) {
          if (
            !instance.companyReadonly &&
            instance.autoCreateWorkspaceOnNoWorkspaces
          ) {
            // instance.registerWorkspaceEvent.emit(email);
            instance.createWorkspaceEvent.emit(email);
          } //
          return;
        }

        let filteredCompanies = companies;

        if (
          instance.filterOutJoinedWorkspaces &&
          instance.joinedWorkspaces &&
          instance.joinedWorkspaces.length
        ) {
          filteredCompanies =
            instance.getFilteredActiveCompaniesByJoinedWorkspaces(
              companies,
              instance.joinedWorkspaces
            );
        }
        instance.companies =
          instance.sharedService.formatCompanies(filteredCompanies);
        if (!instance.companies.length) {
          instance.createWorkspaceEvent.emit();
          return;
        }
        instance.selectedCompanies = instance.companies;
        if (instance.company && instance.company.companyId) {
          instance.onCompanySelected(instance.company.companyId);
        }
      }
      // }
    });
  }

  getDepartments(companyId) {
    if (!companyId) {
      return;
    }
    const instance = this;
    const params = {
      Page: 0,
      PageSize: 10000,
    };
    const path =
      environment.celoApiEndpoint +
      "/api/Companies/" +
      companyId +
      "/Departments";
    this.sharedService.getObjectById(path, params).subscribe((departments) => {
      if (departments && departments.data) {
        instance.departments = departments.data;
      }
    });
  }

  getFilteredActiveCompaniesByJoinedWorkspaces(
    companies: any[],
    joinedWorkspaces
  ) {
    companies = companies.filter((company) => {
      for (const joinedWorkspace of joinedWorkspaces) {
        if (company.id == joinedWorkspace.companyId || !company.isActive) {
          return 0;
        }
      }
      return 1;
    });
    return companies;
  }

  onCompanySelected(companyId) {
    this.selectedCompany = this.getCompanyById(companyId);
    // this.department = '';
    this.getDepartments(companyId);
    if (!this.selectedCompany) {
      this.selectedCompany = {};
    }
    if (this.company != this.originalCompany) {
      this.department = "";
      this.originalCompany = "";
    }
  }

  getCompanyById(company) {
    if (!this.companyReadonly) {
      if (this.companies && this.companies.length) {
        for (const companyob of this.companies) {
          if (companyob.id == company) {
            return companyob;
          }
        }
      }
    } else {
      return company;
    }
    return null;
  }

  onChanges(): void {
    this.data = {};
    this.change_sub = this.myForm.valueChanges.subscribe((val) => {
      if (this.myForm.valid) {
        this.data.companyId =
          this.company && this.company.companyId
            ? this.company.companyId
            : val.company_field;
        this.data.company = this.companies.find(
          (c) => c.companyId === this.data.companyId
        );
        this.data.departmentId = val.department_field;
        this.data.position = val.position_field;
        this.data.email = val.email_field;
        // this.dataChange.emit(this.data);
        this.hasChanged = true;
        if (this.canEmitChange) {
          this.dataChange.emit(this.data);
        }
      } else {
        this.hasChanged = false;
      }
      this.validity.emit(this.myForm.valid);
    });
    setTimeout(() => {
      this.canEmitChange = true;
    }, 1000);
  }

  onKey(value) {
    this.selectedCompanies = this.search(value);
  }

  search(value: string) {
    const filter = value.toLowerCase();
    if (!this.companies) {
      return;
    }
    return this.companies.filter(
      (company_ob) => company_ob.companyName.toLowerCase().indexOf(filter) != -1
    );
  }
}
