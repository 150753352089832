import { Component, Inject, OnInit } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBar } from "@angular/material/snack-bar";
import { Call } from "@types";
import { DateTime } from "luxon";
import { Observable, distinctUntilChanged, map, timer } from "rxjs";

export interface VoipReminderSnackbarData {
  call: Call;
}

@Component({
  selector: "app-voip-reminder-snackbar",
  templateUrl: "./voip-reminder-snackbar.component.html",
  styleUrls: ["./voip-reminder-snackbar.component.scss"],
})
export class VoipReminderSnackbarComponent implements OnInit {
  public message$: Observable<string> | null = null;

  public constructor(
    @Inject(MAT_SNACK_BAR_DATA) private data: VoipReminderSnackbarData
  ) {}

  public ngOnInit(): void {
    const call = this.data.call;
    const callCreatedOn = DateTime.fromISO(call.createdOn);

    this.message$ = timer(0, 1000).pipe(
      map(() => {
        const minutesElapsed = Math.abs(callCreatedOn.diffNow().as("minutes"));

        // We never allow this to be less than 1 minute as the UI requirement is to show less than N minutes and it
        // would not make sense to show "less than 0 minutes".
        const minutesRemaining = Math.max(
          1,
          Math.ceil(call.maxCallLength - minutesElapsed)
        );
        return `Your call will end in less than ${minutesRemaining} minute${minutesRemaining === 1 ? "" : "s"}.`;
      }),
      distinctUntilChanged()
    );
  }

  public static openSnackbar(
    matSnackbar: MatSnackBar,
    data: VoipReminderSnackbarData
  ) {
    return matSnackbar.openFromComponent<
      VoipReminderSnackbarComponent,
      VoipReminderSnackbarData
    >(VoipReminderSnackbarComponent, {
      verticalPosition: "top",
      panelClass: ["voip-reminder-snackbar"],
      data,
    });
  }
}
