import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Call } from "@types";

@Component({
  selector: "app-messages-call-bar",
  templateUrl: "./messages-call-bar.component.html",
  styleUrls: ["./messages-call-bar.component.scss"],
})
export class MessagesCallBarComponent {
  @Input() public call: Call;

  @Output() public join = new EventEmitter<Call>();

  public onJoin() {
    this.join.emit(this.call);
  }
}
