import {
  ContactModel,
  GetWorkspacePictureRequestOptions,
  WorkplaceModel,
} from "types";
import { ApiService } from "./api.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class WorkspacesService {
  public constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {}

  public getPicture({
    workspaceId,
    ...options
  }: GetWorkspacePictureRequestOptions): Observable<Blob> {
    const timestamp = new Date().toISOString();
    const path = `/api/v2/Workspaces/${workspaceId}/picture/timestamp/${timestamp}`;
    return this.apiService.request(
      "get",
      {
        path,
        queryParams: { ...options },
      },
      {
        responseType: "blob",
      }
    );
  }

  public findWorkspace(
    contact: ContactModel,
    workspaceId: string
  ): WorkplaceModel | null {
    return contact.workplaces?.find((w) => w.companyId === workspaceId) ?? null;
  }

  /**
   * Returns a shallow copy of `contacts` containing only users who are colleague. The rules for filtering are:
   *
   * - User is a member of the workspace with `workspaceId`
   * - User's name or position (for the workspace being filtered) contains `query`
   * - User is not the currently authenticated user
   */
  public filterContacts(
    query: string,
    workspaceId: string,
    contacts: ContactModel[]
  ) {
    const currentUserId = this.userService.getUserId();
    const lowercaseQuery = query.toLowerCase();
    return contacts.filter((contact) =>
      this.filterContact(lowercaseQuery, workspaceId, contact, currentUserId)
    );
  }

  private filterContact(
    query: string,
    workspaceId: string,
    contact: ContactModel,
    currentUserId: string | null
  ): boolean {
    if (contact.userId === currentUserId) return false;

    const workspace = this.findWorkspace(contact, workspaceId);
    if (!workspace) return false;

    if (workspace.position?.toLowerCase().includes(query)) return true;

    const fullName = [contact.firstName, contact.lastName]
      .filter((value) => value !== null && value !== undefined)
      .join(" ");

    if (fullName.toLowerCase().includes(query)) return true;

    return false;
  }
}
