import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { environment } from "@env";
import {
  AccountService,
  ConService,
  ConversationService,
  ConversationsService,
  SharedService,
  SnackbarService,
  UserService,
} from "@modules/core";
import { VoipService } from "@modules/core/services/voip.service";
import {
  Call,
  ConversationModelV2,
  ConversationType,
  UserPresenceModel,
} from "@types";
import { Observable } from "rxjs";

@Component({
  selector: "app-messages-subject-line",
  templateUrl: "./messages-subject-line.component.html",
  styleUrls: ["./messages-subject-line.component.scss"],
})
export class MessagesSubjectLineComponent
  implements OnChanges, OnDestroy, OnInit
{
  @Input() conversation: ConversationModelV2;
  @Input() userAccount: any;
  @Input() isOpened: boolean;
  @Input() allowedToShowTyping: boolean;
  @Input() allowedToShowLastOnline: boolean;
  @Input() typing: any[] = [];
  @Input() public isRolesTab: boolean = false;
  @Input() public inProgressCall: Call | null = null;
  @Output() infoClick = new EventEmitter<boolean>();
  @Output() closeClick = new EventEmitter<boolean>();
  @Output() searchClick = new EventEmitter<boolean>();
  @Input() participantToShow: any;

  type = "Group";
  startupExpired: boolean;
  previousConversationId: string;
  previousUserId: any;
  subscription: any;
  public userStatus: UserPresenceModel | null = null;
  connectionSubscription: any;
  getStatusTimeout: NodeJS.Timeout;
  sub: any;
  canShowStatus: boolean;
  name: string;
  conversationPictureUri: string;
  leftChat: boolean;
  isCallAvailable: boolean = false;
  isStartVideoCallUiEnabled$: Observable<boolean>;

  get subtitle() {
    switch (this.type) {
      case "Chat":
      case "SelfChat":
        return "Click here for contact info";
      case "External":
        return "Click here for details";
      case "TeamChat":
        return `Click here for ${this.isRolesTab ? "contact" : "role"} info `;
      default:
        return `Click here for ${this.type.toLowerCase()} info`;
    }
  }

  constructor(
    private conversationService: ConversationService,
    private conversationsService: ConversationsService,
    private connectionService: ConService,
    private sharedService: SharedService,
    private voipService: VoipService,
    private snackbarService: SnackbarService,
    private accountService: AccountService,
    private userService: UserService
  ) {
    this.connectionSubscription =
      this.connectionService.connectionStatus$.subscribe((status) => {
        if (status == "connected" && this.participantToShow) {
          this.addSubscriptionToUserStatus(this.participantToShow.userId);
        }
      });
  }

  ngOnInit(): void {
    this.isStartVideoCallUiEnabled$ =
      this.accountService.isStartVideoCallUiEnabled$;
  }

  ngOnChanges() {
    this.userStatus = this.userStatus ? this.userStatus : undefined;
    if (this.previousConversationId != this.conversation.id) {
      this.userStatus = undefined;
      this.onNewConversation(this.conversation);
    }
    this.previousConversationId = this.conversation.id;
    if (this.type == "Chat") {
      const participant = this.conversationService.getChatParticipant(
        this.conversation
      );
      if (participant) {
        this.previousUserId = participant.userId;
      }
    }
    this.setName();

    this.conversationPictureUri =
      this.conversationsService.getConversationProfileUri(
        this.conversation.id,
        this.conversation.photoId
      );
    this.leftChat = !this.conversation.participants.find(
      (cp) => cp.userId === this.userAccount.userId
    ).leftOnUtc;

    this.isCallAvailable = this.getIsCallAvailable();
  }

  onNewConversation(conversation: ConversationModelV2) {
    if (this.getStatusTimeout) {
      clearTimeout(this.getStatusTimeout);
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.userStatus = undefined;
    this.type = this.conversationService.getConversationType(conversation);
    if (this.type == "Chat") {
      this.participantToShow =
        this.conversationService.getChatParticipant(conversation);
    }
    if (this.type == "SelfChat") {
      this.participantToShow =
        this.conversationService.getLoggedInParticipant(conversation);
    }
    if (this.type == "TeamChat") {
      this.participantToShow =
        this.conversationService.getTeamChatParticipant(conversation);
    }
    if (this.type == "External") {
      this.participantToShow =
        this.conversationService.getExternalChatParticipant(conversation);
    }
    if (this.previousUserId) {
      this.unsubscribeUserStatus(this.previousUserId);
    }
    this.userStatus = undefined;
    if (this.type == "Chat" && this.participantToShow) {
      this.addSubscriptionToUserStatus(this.participantToShow.userId);
      this.updateUserStatus(this.participantToShow.userId);

      this.canShowStatus = false;
      this.getStatusTimeout = setTimeout(() => {
        this.canShowStatus = true;
      }, 2000);
    }
  }

  updateUserStatus(userId) {
    const path =
      environment.celoApiEndpoint + "/api/User/" + userId + "/presence";

    this.sub = this.sharedService
      .getObjectById(path, {})
      .subscribe((status) => {
        if (userId == this.participantToShow.userId) {
          this.userStatus = status;
        }
      });
  }

  addSubscriptionToUserStatus(userId) {
    this.connectionService.subscribeUserStatus([userId]);
    this.listenAndUpdateUserStatus();
  }

  unsubscribeUserStatus(userId) {
    this.connectionService.unsubscribeUserStatus([userId]);
    this.stopListening();
  }

  listenAndUpdateUserStatus() {
    this.subscription = this.connectionService.userStatus$.subscribe(
      (userStatus) => {
        if (
          this.participantToShow &&
          userStatus["userId"] == this.participantToShow.userId
        ) {
          this.userStatus = userStatus;
        }
      }
    );
  }

  stopListening() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  openInfo() {
    this.infoClick.next(null);
  }

  onClick() {
    if (this.isOpened) {
      this.closeClick.next(null);
    } else {
      this.infoClick.next(null);
    }
  }

  close() {
    this.closeClick.next(null);
  }

  search() {
    this.searchClick.emit();
  }

  call() {
    if (this.inProgressCall) {
      this.snackbarService.show("Another call is in progress.");
    } else {
      this.voipService.navigateToStartCall(this.conversation.id);
    }
  }

  private getIsCallAvailable(): boolean {
    // leftChat seems to be inverted in this component
    if (!this.leftChat) return false;

    // Disable calling in one-on-one chats if this user is blocked or has blocked the other user
    if (this.type === ConversationType.Chat) {
      const currentUserId = this.userService.getUserId();
      if (!currentUserId) return true;

      const otherParticipant = this.conversation.participants.find(
        (p) => p.userId !== currentUserId
      );
      if (!otherParticipant) return true;

      const isBlocked =
        otherParticipant.blockedByMe?.isBlocked ||
        otherParticipant.blockedMe?.isBlocked;

      return !isBlocked;
    }

    return (
      this.type === ConversationType.Case ||
      this.type === ConversationType.Chat ||
      this.type === ConversationType.Group ||
      this.type === ConversationType.TeamChat
    );
  }

  setName() {
    this.name = this.conversationService.getName(
      this.conversation,
      this.type == "SelfChat",
      this.isRolesTab
    );
  }

  ngOnDestroy() {
    this.unsubscribeUserStatus(this.previousUserId);
  }
}
