import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { MentionModel, MentionType, ParticipantModel } from "@types";
import { concatNotNull } from "@utils";
import { Subject } from "rxjs";
import {
  EditMessageModelAction,
  EditMessageModelActionType,
  EditMessageModelMentionAction,
} from "../message-input/EditMessageModel";
import { ValidationError } from "../message-input/ValidationError";
import {
  MessageComposeModel,
  MessageInputComponent
} from "../message-input/message-input.component";

@Component({
  selector: "app-message-compose-v2",
  templateUrl: "./message-compose-v2.component.html",
  styleUrls: ["./message-compose-v2.component.scss"],
})
export class MessageComposeV2Component {
  @Input()
  public initialValue: MessageComposeModel | null = null;

  @Input()
  public userId: string;

  @Input()
  public participants: ParticipantModel[] = [];

  @Input()
  public maxLength: number = 3000;

  @Output()
  public submitEvent = new EventEmitter<MessageComposeModel>();

  @Output()
  public changeEvent = new EventEmitter<MessageComposeModel>();

  @Output()
  public validationEvent = new EventEmitter<ValidationError | null>();

  @Output()
  public pasteEvent = new EventEmitter<ClipboardEvent>();

  @ViewChild("messageInput")
  public messageInput: MessageInputComponent | null = null;

  private mentionsSearchResultsSubject = new Subject<ParticipantModel[] | null>();
  public mentionsSearchResults$ = this.mentionsSearchResultsSubject.asObservable();

  private mentionAction: EditMessageModelMentionAction | null = null;

  public handleSubmit(message: MessageComposeModel) {
    this.submitEvent.emit(message)
  }

  public handleChange(message: MessageComposeModel) {
    this.changeEvent.emit(message);
  }

  public handleAction(action: EditMessageModelAction | null) {
    if (action?.type !== EditMessageModelActionType.Mention) {
      this.mentionsSearchResultsSubject.next(null);
      this.mentionAction = null;
      return;
    };

    // Remove '@' from the start of the matched text
    const query = action.matchedText.substring(1).toLocaleLowerCase();

    const searchResults = this.participants.filter(p => {
      const firstName = p.firstName?.toLocaleLowerCase() ?? '';
      const lastName = p.lastName?.toLocaleLowerCase() ?? '';
      const fullName = concatNotNull([firstName, lastName]);
      return firstName.startsWith(query) || lastName.startsWith(query) || fullName.startsWith(query);
    });

    this.mentionsSearchResultsSubject.next(searchResults);
    this.mentionAction = action;
  }

  public handleMention(participant: ParticipantModel) {
    const mention: MentionModel = {
      id: participant.userId,
      index: this.mentionAction.index,
      name: '@' + concatNotNull([participant.firstName, participant.lastName]),
      type: MentionType.User
    };
    this.messageInput?.insertMention(mention);
  }

  public handleValidationError(validationError: ValidationError | null) {
    this.validationEvent.emit(validationError);
  }

  public submit() {
    this.messageInput?.submit();
  }

  public setValue(message?: MessageComposeModel | null) {
    this.messageInput?.reset(message);
  }

  public insertText(text: string) {
    this.messageInput?.insertText(text);
  }

  public handlePaste(event: ClipboardEvent) {
    this.pasteEvent.emit(event);
  }

  public restoreFocus() {
    this.messageInput.restoreFocus();
  }
}
