import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { DropBoxComponent } from "@modules/library/drop-box/drop-box.component";
import { FileUploadService } from "app/modules/core/old/file-upload.service";
import { environment } from "environments/environment";
import { FileUploader } from "ng2-file-upload";
import { SharedService } from "../../modules/core/old/shared.service";
import { BroadcastService } from "../broadcast.service";

@Component({
  selector: "app-broadcast",
  templateUrl: "./broadcast.component.html",
  styleUrls: ["./broadcast.component.scss"],
})
export class BroadcastComponent implements OnInit, OnDestroy {
  message = "";
  sending = false;
  recepients = [];
  public uploader: FileUploader;
  recipients: any[];
  sub: any;

  constructor(
    private broadcastService: BroadcastService,
    private sharedService: SharedService,
    private fileUploadService: FileUploadService,
    private dialog: MatDialog,
    private dialogReference: MatDialogRef<BroadcastComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public static openCreateNewBroadcastDialog(
    matDialog: MatDialog,
    recipients: any // Typed as any to maintain compatibility with legacy code
  ) {
    return matDialog.open(BroadcastComponent, {
      autoFocus: false,
      panelClass: "new-chat",
      data: { recepients: recipients },
    });
  }

  ngOnInit() {
    this.recepients = [];
    if (this.data) {
      if (this.data.recepients) {
        this.recepients = this.data.recepients;
      }
    }
  }

  send() {
    this.recipients = [];
    this.recepients.forEach((element) => {
      this.recipients.push({
        userId: element.userId,
      });
    });
    this.sending = true;
    if (this.uploader && this.uploader.queue && this.uploader.queue[0]) {
      this.fileUploadService.upload();
    } else if (this.message) {
      this.broadcastTextMessage();
    }
  }

  broadcastAttachmentMessage(attachedObject) {
    const instance = this;
    this.broadcastService.sendAttachment(
      attachedObject,
      this.recipients,
      function (resp) {
        if (resp) {
          instance.broadcastTextMessage();
        }
      }
    );
  }

  broadcastTextMessage() {
    if (!this.message) {
      this.backToDashboardWithDelay();
      return;
    }
    this.broadcastService.sendMessage(this.message, this.recipients, (resp) => {
      if (resp) {
        this.backToDashboardWithDelay();
      } else {
        this.dialogReference.close({ success: false });
      }
    });
  }

  initialMessage(id, createMessage, callback) {
    const path =
      environment.celoApiEndpoint +
      "/api/Conversations/{conversationId}/SendMessage".replace(
        "{" + "conversationId" + "}",
        String(id)
      );
    this.sharedService.postObjectById(path, {}, createMessage).subscribe(
      (resp) => {
        callback();
      },
      (err) => {}
    );
  }

  initializeFileUploader() {
    // let type='Photos';
    const type = "Multi";
    const url = `${environment.celoApiEndpoint}/api/photos/`;
    const data = {
      type,
      queueLimit: 1,
      allowPatient: false,
      url,
    };
    const instance = this;
    this.sub = this.fileUploadService.initialize(data, function (result) {
      if (
        result.success &&
        result.uploadedFiles &&
        result.uploadedFiles.length > 0
      ) {
        instance.broadcastAttachmentMessage(result.uploadedFiles[0]);
      }
    });
    this.uploader = this.fileUploadService.uploader;
  }

  attachFiles() {
    this.initializeFileUploader();
    const config = new MatDialogConfig();
    config.data = this.uploader;
    config.data["hideSubtitle"] = true;
    config.data["singleFileOnly"] = true;

    this.dialog
      .open(DropBoxComponent, config)
      .afterClosed()
      .subscribe((result: any) => {
        if (result && result.length) {
        }
      });
  }

  backToDashboardWithDelay() {
    setTimeout(() => {
      this.dialogReference.close({ success: true });
    }, 1000);
  }

  cancel() {
    this.dialogReference.close();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
