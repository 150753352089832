<div
  *ngIf="this.canShowOnCall"
  [style.height.px]="height"
  [style.width.px]="width ? width : 'auto'"
>
  <button
    (click)="toggleOnCall()"
    matRipple
    class="celo-button-sm fh"
    [ngClass]="{
      'celo-primary-button': isOnCall,
      'celo-secondary-button': !isOnCall
    }"
    [disabled]="isSavingOnCall"
    [matTooltip]="isOnCall ? 'You are on call now' : ''"
  >
    <span *ngIf="isSavingOnCall" class="appear_1">Updating</span>
    <span *ngIf="!isSavingOnCall">ON CALL</span>
  </button>
</div>
