import { enableProdMode } from "@angular/core";
import { platformBrowser } from "@angular/platform-browser";
import "zone.js";

import { defineCustomElements } from "@teamhive/lottie-player/loader";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
defineCustomElements(window);

if (environment.production) {
  enableProdMode();
}

platformBrowser().bootstrapModule(AppModule);
