import { FullContactModelPagedResult } from "./../../../../types/api-v1";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ApiRequestOptions,
  ContactsRequestOptions,
  ContactsV1RequestOptions,
  FullContactModel,
  UserProfileModelApiPagedResult,
} from "types";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class ContactsService {
  public constructor(private apiService: ApiService) {}

  public getContacts({
    fetchAll,
    ...options
  }: ContactsRequestOptions &
    ApiRequestOptions): Observable<UserProfileModelApiPagedResult> {
    const path = `/api/v2/Contacts`;
    const defaultOptions: ContactsRequestOptions = {
      pageSize: 100,
    };
    if (fetchAll) {
      return this.apiService.getAllByOffset({
        path,
        queryParams: { ...defaultOptions, ...options },
      });
    }
    return this.apiService.get({
      path,
      queryParams: { ...defaultOptions, ...options },
    });
  }

  public getContactsV1({
    fetchAll,
    ...options
  }: ContactsV1RequestOptions &
    ApiRequestOptions): Observable<FullContactModelPagedResult> {
    const path = `/api/Contacts`;
    const defaultOptions: ContactsV1RequestOptions = {
      pageSize: 100,
    };
    if (fetchAll) {
      return this.apiService.getAllByOffset({
        path,
        queryParams: { ...defaultOptions, ...options },
      });
    }
    return this.apiService.get({
      path,
      queryParams: { ...defaultOptions, ...options },
    });
  }
}
