import { Clipboard as CdkClipboard } from "@angular/cdk/clipboard";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "@env";
import { ConversationPickerDialogComponent } from "@modules/conversations/conversation-picker-dialog/conversation-picker-dialog.component";
import {
  AccountService,
  AlertService,
  AnalyticsService,
  ConService,
  ConnectionStatus,
  ConversationService,
  ConversationsService,
  FileUploadService,
  MemoryService,
  MessageService,
  SharedService,
  TeamsService,
  UploadModel,
  UploadServiceFileItem,
  UploadedFile,
  UserService,
} from "@modules/core";
import { VoipService } from "@modules/core/services/voip.service";
import { DropBoxComponent } from "@modules/library/drop-box/drop-box.component";
import {
  LibrarySelectImgsComponent,
  SelectedPhoto,
} from "@modules/library/library-select-imgs/library-select-imgs.component";
import { SoundPlayService } from "@modules/shared/sound-play.service";
import { UserPickerSubmitCallback } from "@modules/user-picker/user-picker.service";
import { Store } from "@ngrx/store";
import {
  Call,
  Conversation,
  ConversationModelV2,
  ConversationParticipant,
  ConversationParticipantModel,
  ConversationParticipantModelV2,
  ConversationType,
  CreateMessageMediaModel,
  CreateMessageModel,
  CreateMessagePatientFileModel,
  FullUserProfileModel,
  MediaType,
  Message,
  MessageModel,
  MessageStatuses,
  MessageType,
  ParticipantRole,
  Team,
  TeamMember
} from "@types";
import {
  ConversationUtils,
  dateCompareIfNotNull,
  getComputedLocalTime,
  getScrollDistanceToBottom,
  isNotNullOrUndefined,
  isPastDate,
} from "@utils";
import {
  ConversationsPageActions,
  conversationsFeature,
  selectConversation,
  selectSelectedConversation,
} from "app/state";
import { config } from "configurations/config";
import { FileUploader } from "ng2-file-upload";
import {
  Observable,
  Subscription,
  combineLatest,
  interval,
  of,
  throwError,
  timer,
} from "rxjs";
import {
  filter,
  first,
  map,
  mapTo,
  pairwise,
  shareReplay,
  takeUntil,
  takeWhile,
  throttleTime,
} from "rxjs/operators";
import { isScrolledToBottom } from "./../../utils/element-utils";
import { EditPatientDetailsUpdateFn } from "./../modules/shared/edit-patient-details-dialog/edit-patient-details-dialog.component";
import { PatientDetails } from "./../modules/shared/patient-details-form/patient-details-form.component";
import { MessageDetailOpenWindowType } from "./message-detail/message-detail.component";
import { MessageComposeModel } from "./message-input/message-input.component";
import { SearchSelect } from "./search/search.component";

interface LoadDataParams {
  conversationId: string;
  /** This should be a message's legacy integer ID to support legacy code. */
  messageId?: number | null;
  forceReload?: boolean | null;
}

enum Order {
  Ascending,
  Descending,
}

interface AddMessageOptions {
  disableScroll?: boolean;
  enableAppending?: boolean;
}

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
})
export class MessagesComponent
  implements OnInit, AfterViewChecked, OnDestroy, OnChanges
{
  @ViewChild("scrollMe", { static: true })
  private scrollElement: ElementRef<HTMLDivElement>;

  @ViewChild("bottom") private bottomAnchor: ElementRef<HTMLElement>;
  @ViewChild("sidenav", { static: true }) private sidenav: MatSidenav;

  /**
   * Ordered from oldest to newest by `createdOnUtc` (if the current user sent the message), and then by `sentOnUtc`
   */
  messages: MessageModel[];
  public quotedMessage$: Observable<Message | null> = of(null);
  newMessage = "";
  id: string = "";
  conversation: Conversation | null = null;
  isNearBottom = false;
  initialLoading = true;
  pMap: { [x: string]: ConversationParticipantModelV2 };
  // messageStreamSub = null;
  leftChat: boolean;
  isAdmin = false;
  focused = true;
  userAccount: FullUserProfileModel | null = null;
  showConversationInfo = false;
  showMessageStatus = false;
  selectedMessage: any;
  dropZoneActive = "";
  public uploader: FileUploader;
  scrollFetchMessageCount = 50;
  loadingMore: boolean;
  findChatSub: any;
  previousLastMessageId: number;
  searching = false;
  menu: string;
  searchQuery: string;
  loaded: boolean;
  refreshCount = 0;
  type: ConversationType | "" = "";
  chatParticipantId: string;
  chatParticipant: any;
  patientId: string;
  activeOtherParticipants: any[];
  connectionSubscription: any;
  typing: any[];
  typingFilter: number;
  isOnDND: boolean;

  allowedToShowLastOnline: boolean;
  allowedToShowTyping: boolean;

  // savedMessage: string;
  // saved = {};

  subscription: any;
  uploadRefreshCount = 0;
  messageRetryGap = 4000;
  messageId: any;
  bottomReached: boolean;
  loadingMoreBottom: boolean;
  messageFetchingSub: any;
  gettingMessagesByMarker: boolean;
  gettingMessagesByMarkerSub: any;
  sendMessageSub: any;
  retryMessageTimer: NodeJS.Timeout;

  newMessageIds = [];
  BlockChangeSub: any;
  myCompaniesAndPartnerCompanies: any = [];
  hasCommanWorkspace: boolean;
  conversationSub: any;
  focusSub: any;
  subUserAction: any;
  subMessageView: any;
  subMentionClick: any;
  updatedConversationEmittedSub: any;
  loadMessagesSub: any;
  name: string;
  participants: ConversationParticipant[];
  activeAdminList: any[];
  profileInFocus: string;
  messageToHighlight: any;
  conversationProfileUri: string;

  public currentUserOnCallName: string | null = null;
  public team: Team | null = null;
  public isTeamInactive: boolean = false;
  public isCurrentUserTeamMember: boolean = false;
  public teamChatParticipant: ConversationParticipantModelV2 | null = null;
  private teamSubscription: Subscription | null = null;
  public isRolesTab: boolean = false;
  public isTeamChat: boolean = false;

  public externalChatParticipant: ConversationParticipantModelV2 | null = null;

  public inProgressCall$: Observable<Call | null> | null = null;

  public isInitialPageOfMessagesLoading$ = of(false);
  public isNextPageOfMessagesLoading$ = of(false);
  public isPreviousPageOfMessagesLoading$ = of(false);
  public messages$: Observable<Message[]> = of([]);

  private uploadDraft: UploadModel = {
    items: [],
    patient: {
      uid: "",
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      gender: null,
    },
    selectedItemIndex: 0,
  };

  public isConversationAdmin: boolean = false;
  public isActiveParticipant: boolean = false;

  private nearBottomDistance: number = 300;
  private nearTopDistance: number = 600;

  private routeSubscription: Subscription | null;

  private isScrollToBottomRequired: boolean = true;

  private initialScrollSubscription: Subscription | null = null;

  public get isBlocked() {
    return (
      this.chatParticipant?.blockedByMe?.isBlocked ||
      this.chatParticipant?.blockedMe?.isBlocked
    );
  }

  constructor(
    private dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    private connectionService: ConService,
    private soundPlayService: SoundPlayService,
    public changeDetectorRef: ChangeDetectorRef,
    private fileUploadService: FileUploadService,
    private conversationService: ConversationService,
    private sharedService: SharedService,
    private messageService: MessageService,
    private accountService: AccountService,
    public alertService: AlertService,
    public analyticsService: AnalyticsService,
    private memoryService: MemoryService,
    private teamsService: TeamsService,
    private router: Router,
    private conversationsService: ConversationsService,
    private userService: UserService,
    private matDialog: MatDialog,
    private clipboard: CdkClipboard,
    private voipService: VoipService,
    private store: Store
  ) {}

  handleUserAction(action) {
    action["actionTime"] = new Date();
    action["firstName"] = this.conversationService.getParticipantNameById(
      this.conversation,
      action["userId"]
    );
    // this.typing = []
    let hitIndex = -1;
    for (let i = 0; i < this.typing.length; i++) {
      const item = this.typing[i];
      if (item["userId"] == action["userId"]) {
        hitIndex = i;
        break;
      }
    }
    if (hitIndex == -1) {
      if (action.isActioning) {
        this.typing.unshift(action);
      }
    } else if (action.isActioning) {
      this.typing[hitIndex] = action;
    } else {
      this.typing.splice(hitIndex, 1);
    }
  }

  insertNewMessage(id) {
    this.newMessageIds.push(id);
  }

  clearNewMessages() {
    if (this.focused) {
      this.newMessageIds = [];
    }
  }

  clearNewMessage(id) {
    this.newMessageIds = this.newMessageIds.filter((i) => i !== id);
  }

  public updatePatientDetails: EditPatientDetailsUpdateFn = (
    patientDetails
  ) => {
    if (!this.conversation?.id)
      return throwError(new Error("Invalid conversation id"));
    return this.conversationsService
      .updateConversationPatientData(this.conversation.id, patientDetails)
      .pipe(mapTo(null));
  };

  public onNewPatientDetails(patientData: PatientDetails) {
    const newPatientData = {
      ...this.conversation.patientData,
      ...patientData,
    };
    this.store.dispatch(
      ConversationsPageActions.patientUpdated({
        conversationId: this.conversation.id,
        patientData: newPatientData,
      })
    );
    // this.onConversationDataUpdated();
  }

  onNewConversationInfo(conversation) {
    if (!conversation) {
      return;
    }
    this.conversation = conversation;
    this.onConversationDataUpdated();
  }

  filterTyping(delay) {
    const instance = this;
    if (!this.typingFilter) {
      this.typingFilter = window.setInterval(function () {
        if (instance.typing && instance.typing.length > 0) {
          instance.removeExpiredOnes();
        }
      }, delay * 1000);
    }
  }

  removeExpiredOnes() {
    const duration = 3;
    let actionTime;
    const time = new Date();
    this.typing = this.typing.filter((element) => {
      actionTime = element.actionTime;
      const dif = time.getTime() - actionTime.getTime();
      const seconds = dif / 1000;
      if (seconds < duration) {
        return true;
      }
      return false;
    });
  }

  initializeFileUploader(targetConversation: string) {
    // let type='Photos';
    const type = "Multi";
    const url = `${environment.celoApiEndpoint}/api/photos/`;
    const data = {
      type,
      queueLimit: config.max_file_upload_limit + 1,
      allowPatient: true,
      url,
      conversationId: targetConversation,
    };
    this.fileUploadService.initialize(data, (result) => {
      const conversationId = result.conversationId;

      if (
        result.success &&
        result.uploadedFiles &&
        result.uploadedFiles.length > 0
      ) {
        const photos = result.uploadedFiles.filter(
          (file) => file.type === "Photo"
        );
        const documents = result.uploadedFiles.filter(
          (file) => file.type === "Document"
        );

        this.sendUploadedPics(photos, conversationId);
        this.sendUploadedDocuments(documents, conversationId);
      }
    });
    this.uploader = this.fileUploadService.uploader;
  }

  fileDropped() {}
  dropZoneState(state: boolean) {
    this.dropZoneActive = state ? "valid" : "";
  }

  handleDrop(files: FileList) {}
  handleFilesSelection(files: FileList) {
    this.uploadRefreshCount++;
  }

  private loadUploadDraft() {
    const draft = this.memoryService.memory.uploadDrafts.get(
      this.conversation.id
    );

    if (!draft) return;

    const { items } = draft;
    // rawFile is incorrectly typed. See: https://github.com/valor-software/ng2-file-upload/issues/1049
    const files = items.map((item) => item.file.rawFile as unknown as File);

    this.uploadDraft = draft;
    this.uploader.addToQueue(files);

    for (const item of items) {
      const uploadServiceFileItem = this.uploader.queue.find(
        (f) => f.file.rawFile === item.file.rawFile
      ) as UploadServiceFileItem;

      if (!uploadServiceFileItem) continue;

      uploadServiceFileItem.filename = item.filename;
      uploadServiceFileItem.description = item.description;
    }
  }

  public handleItemsChange(items: UploadServiceFileItem[]) {
    const selectedItemIndex = Math.min(
      items.length - 1,
      this.uploadDraft.selectedItemIndex
    );
    this.uploadDraft = {
      ...this.uploadDraft,
      items,
      selectedItemIndex,
    };
    this.memoryService.memory.uploadDrafts.set(
      this.conversation.id,
      this.uploadDraft
    );
  }

  public handleSelectedItemsIndexChange(selectedItemIndex: number) {
    this.uploadDraft = {
      ...this.uploadDraft,
      selectedItemIndex,
    };
    this.memoryService.memory.uploadDrafts.set(
      this.conversation.id,
      this.uploadDraft
    );
  }

  private removeUploadDraft() {
    this.memoryService.memory.uploadDrafts.delete(this.conversation.id);
  }

  public handleUploadSubmitted() {
    this.removeUploadDraft();
  }

  public handleUploadClose() {
    this.removeUploadDraft();
    this.closeUpload();
  }

  closeUpload() {
    this.uploadDraft = {
      items: [],
      patient: {
        uid: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        gender: null,
      },
      selectedItemIndex: 0,
    };
    this.fileUploadService.uploader.clearQueue();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setName();
    this.setConversationProfileUri();
  }

  setConversationData(conversation: Conversation) {
    if (!conversation) return;
    this.conversation = conversation;
    this.loadUploadDraft();
    this.onConversationDataUpdated();
    if (this.type === ConversationType.Chat) {
      this.setParticipantId();
      this.addSubscriptionToUserStatus(this.chatParticipantId);
      this.listenToUserDND(this.chatParticipantId);
      this.getChatParticipant(this.chatParticipantId);
      this.setDND();
    } else if (this.type === ConversationType.SelfChat) {
      this.chatParticipantId = this.userAccount.userId;
      this.getChatParticipant(this.chatParticipantId);
    } else if (this.type === ConversationType.External) {
      this.externalChatParticipant =
        this.conversationService.getExternalChatParticipant(conversation);
    }

    this.isTeamChat = this.type === ConversationType.TeamChat;

    if (this.isTeamChat && this.conversation?.id) {
      const teamId = this.conversation.teamIds?.[0];

      if (teamId) {
        this.teamSubscription?.unsubscribe();
        this.teamSubscription = this.teamsService
          .getTeam({ teamId })
          .subscribe((team) => {
            this.team = team;
            this.currentUserOnCallName =
              this.teamsService.findFirstMemberOnCall(team)?.user?.fullName ??
              null;
            this.isTeamInactive = !team.isActive;
            this.isCurrentUserTeamMember =
              this.teamsService.isCurrentUserMember(team);
            this.teamChatParticipant =
              this.conversationService.getTeamChatParticipant(
                this.conversation
              );
          });
      } else {
        // Assume team is inactive as if we're unable to find any team ids
        this.isTeamInactive = true;
        this.isCurrentUserTeamMember = false;
        this.teamChatParticipant = null;

        // Fallback to using a placeholder team
        this.team = {
          name: this.conversation.name,
          isActive: false,
        };
      }
    } else {
      this.isTeamInactive = false;
      this.isCurrentUserTeamMember = false;
      this.teamChatParticipant = null;
    }

    this.unsubscribeConversationAction(conversation.id);
    this.subscribeConversationAction(conversation.id);
    this.allowedToShowLastOnline = false;
    this.allowedToShowTyping = false;
    this.setAccessControlValues();
    this.initialLoading = true;
  }

  setAccessControlValues() {
    this.allowedToShowLastOnline = false;
    this.allowedToShowTyping = false;
    if (this.canSendMessagesToTheGroup()) {
      this.allowedToShowLastOnline = true;
      this.allowedToShowTyping = true;
    }
  }

  ngOnInit() {
    this.initializeFileUploader("");
    this.subUserAction = this.connectionService.userAction$.subscribe(
      (userAction) => {
        if (
          userAction["conversationId"] === this.id &&
          userAction["userId"] !== this.userAccount.userId
        ) {
          this.handleUserAction(userAction);
        }
      }
    );

    this.quotedMessage$ = this.store.select(
      conversationsFeature.selectSelectedReplyMessage
    );

    combineLatest([
      this.store.select(conversationsFeature.selectSelectedConversationId),
      this.store.select(
        conversationsFeature.selectSelectedConversationMessages
      ),
    ])
      .pipe(pairwise())
      .subscribe({
        next: ([
          [prevConversationId, prevMessages],
          [currConversationId, currMessages],
        ]) => {
          this.messages = currMessages;

          // Scroll to bottom if the conversation changes
          if (prevConversationId !== currConversationId) {
            this.isScrollToBottomRequired = true;

            // This is a bandaid to scroll a conversation to the bottom automatically
            // when it is opened directly (by refreshing the page)
            this.bandaidScrollToBottom();
          }

          // Scroll to bottom if the messages change
          const prevLatestMessage = prevMessages?.at(-1);
          const currLatestMessage = currMessages?.at(-1);
          const isLatestMessageEqual =
            prevLatestMessage?.marker === currLatestMessage?.marker;
          const isLatestMessageSentByCurrentUser =
            currLatestMessage?.sentBy === this.userAccount.userId;

          if (
            !isLatestMessageEqual &&
            (isLatestMessageSentByCurrentUser || this.isNearBottom)
          ) {
            this.isScrollToBottomRequired = true;
            return;
          }
        },
      });

    this.isInitialPageOfMessagesLoading$ = this.store.select(
      conversationsFeature.selectIsInitialPageOfMessagesLoading
    );

    this.isNextPageOfMessagesLoading$ = this.store.select(
      conversationsFeature.selectIsNextPageOfMessagesLoading
    );

    this.isPreviousPageOfMessagesLoading$ = this.store.select(
      conversationsFeature.selectIsPreviousPageOfMessagesLoading
    );

    this.connectionSubscription = this.connectionService.connectionStatus$
      .pipe(
        throttleTime(5000, null, {
          leading: true,
          trailing: true,
        })
      )
      .subscribe((status) => {
        if (status !== ConnectionStatus.Connected) return;

        if (this.id) {
          this.subscribeConversationAction(this.id);
        }

        // Reload messages and conversation data when SignalR reconnects
        if (this.id && this.loaded) {
          this.loadData({
            conversationId: this.id,
            forceReload: true,
          });
        }
      });

    this.filterTyping(3);
    this.focused = document.hasFocus();

    this.subMessageView = this.messageService.onMessageView.subscribe(
      (message) => {
        if (!this.focused) {
          return;
        }

        this.clearNewMessage(message.id);
      }
    );

    this.subMentionClick = this.messageService.onMentionClicked.subscribe(
      (mention: any) => {
        if (this.userService.getUserId() === mention.id) return;
        this.openProfileInfo(mention.id);
      }
    );

    this.detectHasLeft(this.userAccount);

    this.routeSubscription = combineLatest([
      this.activeRoute.params,
      this.activeRoute.queryParams,
      this.activeRoute.data,
      this.accountService.userAccount$
        .pipe(filter(isNotNullOrUndefined))
        .pipe(first()),
    ])
      .pipe(
        map((results) => ({
          conversationId: results[0].id,
          messageId: results[1].message_id,
          isRolesTab: results[2].isRolesTab,
          userAccount: results[3],
        }))
      )
      .subscribe((results) => {
        const conversationId = results.conversationId;
        this.isRolesTab = results.isRolesTab || false;
        this.messageId = null;
        this.userAccount = results.userAccount;

        this.loadData({
          conversationId: results.conversationId,
          messageId: results.messageId,
        });

        this.store.dispatch(
          ConversationsPageActions.conversationOpened({ conversationId })
        );
      });

    this.store
      .select(selectSelectedConversation)
      .pipe(filter(isNotNullOrUndefined))
      .subscribe({
        next: (conversation) => {
          if (this.conversation && this.conversation.id != conversation.id)
            return;
          this.refreshCount++;
          this.conversation = conversation;
          this.setConversationData(conversation);
          this.onConversationDataUpdated();
        },
      });

    this.focusSub = this.sharedService.onFocusChange.subscribe((foucsed) => {
      if (foucsed) {
        this.focused = true;
      } else {
        this.focused = false;
      }
    });

    if (!this.findChatSub) {
      this.findChatSub = this.conversationService.findChatEmitted$.subscribe(
        (message) => {
          if (this.messageId != message["id"]) {
            this.findChat(message);
          }
        }
      );
    }

    this.BlockChangeSub = this.connectionService.BlockChange.subscribe(
      (block) => {
        if (
          block &&
          (block.blockerId == this.chatParticipantId ||
            block.userId == this.chatParticipantId)
        ) {
          this.getChatParticipantApi();
          // #TODO connect block change to store via SignalR event
          // this.refreshConversationInfo();
        }
      }
    );
    this.getCompanies();

    this.setConversationProfileUri();
  }

  private loadData({ conversationId, messageId, forceReload }: LoadDataParams) {
    if (
      !forceReload &&
      conversationId == this.id &&
      this.messageId == messageId
    )
      return;
    if (
      !forceReload &&
      conversationId == this.id &&
      this.messageId != messageId
    ) {
      const m = this.messages.find((message) => message.id == messageId);
      if (m) {
        const messageElement = this.findMessageElement(m);
        if (messageElement) {
          this.messageToHighlight = m;
        } else {
          this.findChat(m);
        }
        return;
      }
    }
    this.reset(conversationId);
    this.messageId = messageId;

    this.store
      .select(conversationsFeature.selectSelectedConversationMessages)
      .pipe(filter(isNotNullOrUndefined), first())
      .subscribe({
        next: (messages) => {
          if (!forceReload && messageId && conversationId) {
            this.enterSearchView(messageId, conversationId);
            this.getMessageById(this.messageId, this.id, (message) => {
              setTimeout(() => {
                const messageElement = this.findMessageElement(message);
                if (messageElement) {
                  this.messageToHighlight = message;
                } else {
                  this.findChat(message);
                }
              }, 600);
              this.searching = false;
            });
          }
          this.loaded = true;
        },
      });

    this.inProgressCall$ = this.voipService
      .getInProgressCall(conversationId)
      .pipe(shareReplay());

    this.bottomReached = false;
    this.loadingMoreBottom = false;
    this.searching = false;
    this.initializeFileUploader(conversationId);
  }

  sortParticipantsByFullname(
    participants: ConversationParticipantModel[]
  ): void {
    if (!participants || !participants.length) {
      return;
    }
    participants.sort((a, b) => {
      const keyA = a.firstName.toLowerCase() + a.lastName.toLowerCase();
      const keyB = b.firstName.toLowerCase() + b.lastName.toLowerCase();
      return keyA > keyB ? 1 : keyB > keyA ? -1 : 0;
    });
  }

  sortAdmins(
    participants: ConversationParticipantModel[],
    adminUserIds: string[]
  ): void {
    const admins = new Set(adminUserIds);
    participants.sort((a, b) => {
      const isAdminA = admins.has(a.userId);
      const isAdminB = admins.has(b.userId);
      if (isAdminA && isAdminB) {
        return 0;
      }
      if (isAdminA) {
        return -1;
      }
      return 1;
    });
  }

  getActiveParticipants(
    conversation: Conversation,
    loggedInUserId?: string
  ): ConversationParticipant[] {
    const participants: ConversationParticipant[] = [];
    const loggedInUser = ConversationUtils.findConversationParticipant(
      conversation,
      loggedInUserId
    );
    let loggedInUserLeftTime;
    let loggedInUserJoinedTime;
    if (loggedInUser && loggedInUser.leftOnUtc) {
      loggedInUserLeftTime = new Date(loggedInUser.leftOnUtc);
    }
    if (loggedInUser && loggedInUser.joinedOnUtc) {
      loggedInUserJoinedTime = new Date(loggedInUser.joinedOnUtc);
    }

    for (const p of conversation.participants) {
      let participantJoinedTime;
      if (p.joinedOnUtc) {
        participantJoinedTime = new Date(p.joinedOnUtc);
      }
      let participantLeftTime;
      if (p.leftOnUtc) {
        participantLeftTime = new Date(p.leftOnUtc);
      }

      const participantsHasNotLeft =
        !loggedInUserLeftTime && p.leftOnUtc == null;

      const participantNotLoggedInUser = p.userId != loggedInUserId;

      const participantJoinTimeLessThanLoggedInUserLeftTime =
        participantJoinedTime < loggedInUserLeftTime;

      const participantIsNotLoggedInUserAndJoinedBeforeLoggedInUserLeft =
        loggedInUserLeftTime &&
        participantNotLoggedInUser &&
        participantJoinTimeLessThanLoggedInUserLeftTime;

      const participantJoinedAfterLoggedInUser =
        participantJoinedTime > loggedInUserJoinedTime;

      const participantLeftAfterLoggedInUser =
        participantLeftTime > loggedInUserLeftTime;

      const participantJoinedAfterLoggedInUserAndLeftBeforeLoggedInUser =
        participantJoinedAfterLoggedInUser && participantLeftAfterLoggedInUser;

      if (
        participantsHasNotLeft ||
        (participantIsNotLoggedInUserAndJoinedBeforeLoggedInUserLeft &&
          (participantJoinedAfterLoggedInUserAndLeftBeforeLoggedInUser ||
            !participantLeftTime))
      ) {
        participants.push(p);
      }
    }
    return participants;
  }

  getActiveAdminParticipantIds(
    conversation: Conversation,
    loggedInUserId?: string
  ) {
    let participantIds = [];
    const adminIds = [];
    participantIds = this.getActiveParticipants(conversation, loggedInUserId);
    for (const p of participantIds) {
      if (p["role"] == "Administrator") {
        adminIds.push(p.userId);
      }
    }
    return adminIds;
  }

  initializeParticipants() {
    const loggedInUserId = this.userService.getUserId();

    this.activeAdminList = this.getActiveAdminParticipantIds(
      this.conversation,
      loggedInUserId
    );
    this.participants = this.getActiveParticipants(
      this.conversation,
      loggedInUserId
    );

    this.sortParticipantsByFullname(this.participants);
    this.sortAdmins(this.participants, this.activeAdminList);
    this.sharedService.pullToTop(this.participants, "userId", loggedInUserId);
  }

  sortMessages() {
    //TODO: Work out a secondary sorting key for messages, this will cause issues if the timestamps are the same
    this.messages.sort((a, b) => {
      const dateA = this.getMessageTimestamp(a);
      const dateB = this.getMessageTimestamp(b);
      return dateA < dateB ? -1 : 1;
    });
  }

  public handleMessageDetailOpenWindow(evt: MessageDetailOpenWindowType): void {
    this.menu = evt;

    if (evt === MessageDetailOpenWindowType.Invite) {
      this.store.dispatch(
        ConversationsPageActions.invitationDetailsOpened({
          conversationId: this.conversation.id,
        })
      );
    }
  }

  subscribeConversationAction(conversationId: string) {
    if (!this.canSendMessagesToTheGroup()) {
      return;
    }
    this.connectionService.subscribeConversationActions([conversationId]);
  }

  unsubscribeConversationAction(conversationId) {
    this.connectionService.unsubscribeConversationActions([conversationId]);
  }

  setPatientId(conversation: any) {
    if (conversation.patientData && conversation.patientData.uid) {
      this.patientId = conversation.patientData.uid;
    }
  }

  setConversationType(conversation: ConversationModelV2 | null) {
    this.type = "";
    if (conversation["type"]) {
      this.type = conversation["type"];
    } else if (conversation.patientData) {
      this.type = ConversationType.Case;
    } else if (!conversation.patientData) {
      this.type = ConversationType.Group;
    }
  }

  canSendMessagesToTheGroup() {
    if (this.leftChat) {
      return false;
    }
    if (this.type == "Chat") {
      if (
        (this.chatParticipant["blockedByMe"] &&
          this.chatParticipant["blockedByMe"].isBlocked) ||
        (this.chatParticipant["blockedMe"] &&
          this.chatParticipant["blockedMe"].isBlocked)
      ) {
        return false;
      }
      if (
        !this.hasCommanWorkspace &&
        (!this.chatParticipant["connection"] ||
          (this.chatParticipant["connection"].state != "Accepted" &&
            this.conversation.isReal == false))
      ) {
        return false;
      }
    }
    return true;
  }

  setParticipantId() {
    if (!this.pMap) {
      return;
    }

    const loggedInUserId = this.userService.getUserId();

    for (const id in this.pMap) {
      if (id !== loggedInUserId) {
        this.chatParticipantId = id;
        this.chatParticipant = this.pMap[id];
        break;
      }
    }
  }

  setDND() {
    this.isOnDND = false;
    if (!this.chatParticipant["doNotDisturbToUtc"]) {
      return;
    }
    this.isOnDND = isPastDate(this.chatParticipant["doNotDisturbToUtc"]);
  }

  setParticipantList() {
    this.activeOtherParticipants = [];
    this.pMap = this.pMap ? this.pMap : {};

    for (const p of this.conversation.participants) {
      this.pMap[p.userId] = p;
      if (!p.leftOnUtc && p.userId !== this.userAccount.userId) {
        this.activeOtherParticipants.push(p);
      }
    }
  }

  addSubscriptionToUserStatus(userId) {
    this.connectionService.subscribeUserStatus([userId]);
  }

  unsubscribeUserStatus(userId) {
    this.connectionService.unsubscribeUserStatus([userId]);
  }

  private bandaidScrollToBottom(): void {
    this.scrollToBottom();
    this.initialScrollSubscription?.unsubscribe();
    this.initialScrollSubscription = interval(5)
      .pipe(
        takeUntil(timer(1000)),
        takeWhile(() => {
          const element = this.scrollElement.nativeElement;
          const hasScollbar = element.scrollHeight > element.clientHeight;
          return !hasScollbar || !isScrolledToBottom(element);
        })
      )
      .subscribe({
        next: () => {
          this.scrollToBottom();
        },
      });
  }

  ngAfterViewChecked() {
    if (this.isScrollToBottomRequired) {
      this.scrollToBottom();

      const element = this.scrollElement.nativeElement;
      const isAtBottom = isScrolledToBottom(element);
      this.isScrollToBottomRequired = !isAtBottom;

      if (isAtBottom) {
        this.clearNewMessages();
      }
    }

    if (this.messageToHighlight) {
      const messageElement = this.findMessageElement(this.messageToHighlight);

      const message = this.messageToHighlight;
      if (messageElement) {
        this.messageToHighlight = null;
      }

      setTimeout(() => {
        if (messageElement) {
          this.scrollAndHighlight(messageElement, message);
        }
      }, 600);
    }
  }

  ngOnDestroy() {
    this.initialScrollSubscription?.unsubscribe();
    this.teamSubscription?.unsubscribe();

    if (this.chatParticipantId) {
      this.unsubscribeUserStatus(this.chatParticipantId);
    }
    // this.messageStreamSub.unsubscribe();
    this.searching = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.BlockChangeSub) {
      this.BlockChangeSub.unsubscribe();
    }
    if (this.focusSub) {
      this.focusSub.unsubscribe();
    }
    if (this.connectionSubscription) {
      this.connectionSubscription.unsubscribe();
    }
    if (this.subUserAction) {
      this.subUserAction.unsubscribe();
    }
    if (this.subMessageView) {
      this.subMessageView.unsubscribe();
    }
    if (this.subMentionClick) {
      this.subMentionClick.unsubscribe();
    }
    if (this.updatedConversationEmittedSub) {
      this.updatedConversationEmittedSub.unsubscribe();
    }

    this.routeSubscription?.unsubscribe();
  }

  getMessageById(id, conversationId, callback) {
    const path =
      environment.celoApiEndpoint +
      "/api/Conversations/" +
      conversationId +
      "/GetMessage/" +
      id;
    this.sharedService.getObjectById(path).subscribe(
      (data) => {
        callback(data);
      },
      (err) => {
        callback(null);
      }
    );
  }

  public findChat(message: MessageModel) {
    const anchor = document.querySelector(`[data-marker="${message.marker}"]`);
    if (!anchor) {
      if (!this.sharedService.isOnline()) {
        this.sharedService.noInternetSnackbar();
        return;
      }
      if (this.messageFetchingSub) {
        this.messageFetchingSub.unsubscribe();
      }
      this.searching = true;
      this.enterSearchView(message["id"], this.id);
      this.fetchAndHighlight(message);
    } else {
      this.scrollAndHighlight(anchor, message);
    }
  }

  findMessageElement(message: MessageModel): Element | null {
    const anchor = document.querySelector(`[data-marker=${message.marker}`);
    return anchor ?? null;
  }

  searchSelect(searchResult: SearchSelect) {
    let message: MessageModel;
    message = searchResult.message;
    this.searchQuery = searchResult.searchQuery;

    if (
      message.type === MessageType.VideoCall &&
      message.metadata.resourceStatus === "InProgress"
    ) {
      this.voipService.navigateToJoinCall(
        message.conversationId,
        message.metadata.resourceId
      );
      return;
    }

    if (message.id != this.messageId) {
      this.enterSearchView(message.id, this.id);
    }
    this.findChat(message);
  }

  fetchAndHighlight(message: any) {
    const count = 30;
    const i = this;
    this.loadingMore = true;
    this.getMessagesByMarker(
      this.id,
      message.marker,
      count,
      function (messages) {
        i.loadingMore = false;
        if (messages && messages.length) {
          i.messages = [];
          i.insertIncomingSet(messages);
          i.messageToHighlight = message;
          // setTimeout(() => {
          //   i.checkAgain(message);
          // }, 500);
        }
      }
    );
  }

  getMessagesByMarker(conversationId, marker, count, callback) {
    const path =
      environment.celoApiEndpoint +
      "/api/Conversations/" +
      conversationId +
      "/Messages";
    const params = {
      marker,
      count,
    };

    const i = this;

    this.gettingMessagesByMarkerSub = this.sharedService
      .getObjectById(path, params)
      .subscribe(
        (data) => {
          callback(data);
        },
        (err) => {
          callback([]);
        }
      );
  }

  scrollAndHighlight(anchor, message: any) {
    this.searching = false;
    this.scrollTo(anchor);

    setTimeout(() => {
      this.conversationService.emitFoundChat(message, this.searchQuery);
    }, 500);
  }

  reset = (id: string) => {
    this.type = "";
    this.isAdmin = false;
    this.typing = [];
    this.patientId = null;
    this.chatParticipant = undefined;
    this.chatParticipantId = "";
    this.hasCommanWorkspace = true;
    this.messageToHighlight = null;

    this.loaded = false;
    this.conversationService.messages = this.messages;
    this.id = id;
    this.isNearBottom = false;
    this.initialLoading = false;

    this.store.select(selectConversation(id)).subscribe((c) => {
      this.conversation = c;
    });

    this.leftChat = false;
    this.pMap = undefined;
    this.resetQuote();
    this.closeConversationInfo();
    this.closeMessageStatus();
    this.closeUpload();
    this.clearNewMessages();
    this.sidenav.close();

    this.loadingMore = false;
    this.loadingMoreBottom = false;

    this.scrollElement.nativeElement.scrollTop = 0;
    this.isOnDND = false;
    if (this.conversationSub) {
      this.conversationSub.unsubscribe();
    }
  };

  resetQuote() {
    this.store.dispatch(ConversationsPageActions.replyToMessageReset());
  }

  goToLatest() {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    this.exitSearchView();
  }

  sidenavClosed() {
    this.menu = "";
  }

  exitSearchView() {
    this.bottomReached = false;
    this.loadingMoreBottom = false;
    if (this.messageId) {
      this.unsubscribeAll();
      const url = this.router.url.split("?", 1)[0];
      this.sharedService.redirectTo(url);
    }
  }

  unsubscribeAll() {
    if (this.findChatSub) {
      this.findChatSub.unsubscribe();
    }
    if (this.messageFetchingSub) {
      this.messageFetchingSub.unsubscribe();
    }
  }

  enterSearchView(messageId, convoId) {
    this.bottomReached = false;
    this.loadingMoreBottom = false;
    if (!messageId) {
      return;
    }
    this.messageId = messageId;

    const ignoredUrls = ["roles", "external"];
    if (
      ignoredUrls.some((url) =>
        this.router.isActive(url, {
          paths: "subset",
          queryParams: "subset",
          fragment: "ignored",
          matrixParams: "ignored",
        })
      )
    ) {
      return;
    }

    this.sharedService.navigate(["/", "conversations", convoId, "messages"], {
      queryParams: { message_id: messageId },
    });
  }

  getSeenBy(message: any) {
    const peeps = [];
    for (const status of message.statuses) {
      if (status.status == MessageStatuses.Read) {
        const p = this.getParticipant(status.createdBy);
        if (p != null) {
          peeps.push({
            name: p.title + " " + p.firstName + " " + p.lastName,
            profilePicture: p.profilePicture,
            datetime: status.createdOnUtc,
            firstName: p.firstName,
          });
        }
      }
    }
    return peeps;
  }

  public getParticipant(userId): any {
    if (!this.pMap) {
      return null;
    }
    return this.pMap[userId] || null;
  }

  public detectHasLeft(userAccount: any) {
    if (!userAccount || !userAccount.userId) {
      return;
    }
    const p = this.getParticipant(userAccount.userId);
    if (p) {
      this.leftChat = !!p.leftOnUtc;
    }
  }

  private updateMessageInPlace(
    existingMessage: MessageModel,
    updatedMessage: MessageModel
  ) {
    for (const key in updatedMessage) {
      if (!updatedMessage.hasOwnProperty(key)) continue;
      if (key === "content" || key === "statuses") continue;
      existingMessage[key] = updatedMessage[key];
    }

    if (
      updatedMessage.deletedOnUtc ||
      updatedMessage.type === MessageType.VideoCall
    ) {
      existingMessage.content = updatedMessage.content;
      existingMessage.metadata.resourceCallDurationInSeconds =
        updatedMessage.metadata.resourceCallDurationInSeconds;
    } else {
      // Don't replace statuses with ones from deleted messages as they don't include statuses
      existingMessage.statuses = updatedMessage.statuses;
    }
  }

  private getMessageTimestamp(
    message: MessageModel,
    defaultValue: Date | null = null
  ): Date | null {
    /**
     * For ordering rules see:
     * https://celohealth.atlassian.net/wiki/spaces/CH/pages/98324/Chat+message+display+order+and+timestamps
     */

    if (message.type === MessageType.ConversationStarted)
      return new Date(message.createdOnUtc);

    if (!message.sentOnUtc && !message.createdOnUtc) return defaultValue;

    if (message.sentBy === this.userAccount.userId)
      return new Date(message.createdOnUtc ?? message.sentOnUtc);

    return new Date(message.sentOnUtc ?? message.createdOnUtc);
  }

  addMessage(message: MessageModel, options?: AddMessageOptions) {
    const newMessageTime = this.getMessageTimestamp(message, new Date());

    if (!message.sentOnUtc && !message.createdOnUtc) {
      message.sentOnUtc = newMessageTime.toISOString();
    }

    // If this is the first message in the conversation, add it and do nothing else
    if (!this.messages || this.messages.length === 0) {
      this.appendMessageToConversation(message);
      return;
    }

    const oldestMessageDate = this.getMessageTimestamp(this.messages[0]);
    const latestMessageDate = this.getMessageTimestamp(
      this.messages[this.messages.length - 1]
    );

    if (!oldestMessageDate || !latestMessageDate) {
      // Something's gone wrong. Every message should always have a sentOnUtc value
      // or a createdOnUtc value. If we couldn't find either of these fallback
      // to appending the message to the bottom of the conversation.
      this.appendMessageToConversation(message);
      console.error("Failed to find timestamp for message");
      return;
    }

    if (newMessageTime < oldestMessageDate) {
      // If appending is disabled and this message is older than the oldest message we currently have
      // then do nothing, the client will load it when they scroll up.
      // WARNING: Appending the message to the end list of messages can break the existing
      // pagination system. See: https://celohealth.atlassian.net/browse/CELO-11021
      if (!options?.enableAppending) return;
      this.appendMessageToConversationTop(message);
      return;
    }

    // If this message is older than the latest message we currently have
    // insert it before the latest message it is older than
    if (newMessageTime < latestMessageDate) {
      const insertionPosition =
        1 +
        this.messages.findLastIndex(
          (m) =>
            dateCompareIfNotNull(
              m.sentOnUtc ?? m.createdOnUtc,
              message.sentOnUtc ?? message.createdOnUtc
            ) > 0
        );
      this.appendMessageToConversationAt(message, insertionPosition);
      return;
    }

    // Appending all other messages to the bottom
    if (this.messages.length > 0) {
      this.appendMessageToConversation(message);
      return;
    }
  }

  appendMessageToConversation(message: MessageModel) {
    if (this.id === message.conversationId) {
      this.messages.push(message);
    }
  }

  appendMessageToConversationTop(message: MessageModel) {
    if (this.id === message.conversationId) {
      this.messages.unshift(message);
    }
  }

  appendMessageToConversationAt(message: MessageModel, index: number) {
    if (this.id === message.conversationId) {
      this.messages.splice(index, 0, message);
    }
  }

  listenToUserDND(userId: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.connectionService.userDND$.subscribe((userDND) => {
      if (userId == userDND.userId) {
        this.chatParticipant["doNotDisturbToUtc"] =
          userDND["doNotDisturbToUtc"];
        this.setDND();
      }
      if (this.changeDetectorRef) {
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  getChatParticipantApi() {
    this.getChatParticipant(this.chatParticipantId);
  }

  getChatParticipant(id) {
    const instance = this;
    this.sharedService.getContact(id, function (contact: any) {
      if (contact) {
        if (instance.chatParticipantId == contact.userId) {
          instance.chatParticipant = contact;
          instance.chatParticipant["doNotDisturbToUtc"] =
            contact["doNotDisturbToUtc"];
          instance.setDND();
          if (instance.myCompaniesAndPartnerCompanies) {
            instance.hasCommanWorkspace =
              instance.sharedService.checkCommonWorkspace(
                instance.chatParticipant["workplaces"],
                instance.myCompaniesAndPartnerCompanies
              );
          }
        }
      }
    });
  }

  getCompanies() {
    const instance = this;
    this.sharedService.getPartneredCompanies(function (companies) {
      instance.myCompaniesAndPartnerCompanies = companies.data;
      if (instance.chatParticipant) {
        instance.hasCommanWorkspace =
          instance.sharedService.checkCommonWorkspace(
            instance.chatParticipant["workplaces"],
            instance.myCompaniesAndPartnerCompanies
          );
      }
    });
  }

  handleSubjectLineClick() {
    this.openConversationInfo();
  }

  private getFirstMessageBySentOnUtc(order: Order) {
    if (!this.messages?.length) return null;
    return this.messages.reduce((prev, curr) => {
      if (!curr.sentOnUtc) return prev;
      if (!prev) return curr;

      if (prev.sentOnUtc === curr.sentOnUtc) return prev;

      if (order === Order.Descending) {
        return prev.sentOnUtc > curr.sentOnUtc ? prev : curr;
      }
      return prev.sentOnUtc < curr.sentOnUtc ? prev : curr;
    }, null);
  }

  loadMorePrevious() {
    this.store.dispatch(ConversationsPageActions.loadMoreOlderMessages());
  }

  loadMoreBottom() {
    this.store.dispatch(ConversationsPageActions.loadMoreNewerMessages());
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  send(message: MessageComposeModel, count, delay, index) {
    if (index < count) {
      this.submitMessage({
        ...message,
        content: message.content + " " + (index + 1),
      });
    } else {
      return;
    }
    setTimeout(() => this.send(message, count, delay, ++index), delay);
  }

  private getLatestMessage(): MessageModel | null {
    const hasMessages = !!this.messages?.length;
    if (!hasMessages && !this.conversation) return null;
    if (!hasMessages) return this.conversation.lastMessage ?? null;
    return this.messages.at(-1);
  }

  private getCreatedOnUtc(): Date {
    const latestMessage = this.getLatestMessage();

    const latestMessageDate = latestMessage
      ? new Date(latestMessage.sentOnUtc ?? latestMessage.createdOnUtc)
      : null;

    const latestGlobalDate =
      this.conversationService.getLatestConversationModifiedTimeOrMessageSentOnUtc();

    // This is done because the message the latest message in the current conversation may be very
    // old compared to the latest message across all conversations we have loaded
    const maxDate =
      latestMessageDate > latestGlobalDate
        ? latestMessageDate
        : latestGlobalDate;

    return getComputedLocalTime(maxDate);
  }

  public submitMessage(newMessage: MessageComposeModel) {
    this.messageService.onMessageSubmitSubject.next();
    this.newMessage = newMessage.content;
    this.focused = true;

    if (!this.newMessage.trim()) {
      return;
    }

    const createMessage: CreateMessageModel = {
      content: this.newMessage,
      mentions: newMessage.mentions,
      marker: crypto.randomUUID(),
    };

    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
    }

    this.store
      .select(conversationsFeature.selectSelectedReplyMessage)
      .pipe(first())
      .subscribe({
        next: (replyToMessage) => {
          createMessage.replyTo = replyToMessage?.marker ?? null;

          this.store.dispatch(
            ConversationsPageActions.sendMessage({
              conversationId: this.id,
              message: createMessage,
              user: this.userService.getUser(),
            })
          );
        },
      });
  }

  private isNearTop() {
    const element = this.scrollElement?.nativeElement;
    if (!element) return false;
    return element.scrollTop < this.nearTopDistance;
  }

  public onScroll() {
    const element = this.scrollElement.nativeElement;
    const scrollDistanceToBottom = getScrollDistanceToBottom(element);
    this.isNearBottom = scrollDistanceToBottom < this.nearBottomDistance;

    if (!this.messages?.length) return;

    const hasConversationStartedMessage =
      this.messages?.some((s) => s.type === MessageType.ConversationStarted) ??
      false;
    if (hasConversationStartedMessage) return;

    if (!this.messageId && this.isNearTop()) {
      this.store.dispatch(ConversationsPageActions.messagesScrolledToTop());
    }
  }

  insertIncomingSet(messages: MessageModel[]) {
    if (!messages?.length) return;

    for (const message of messages) {
      this.addMessage(message, {
        disableScroll: true,
        enableAppending: true,
      });
    }

    this.conversationService.messages = this.messages;
  }

  public scrollToBottom(): void {
    const element = this.bottomAnchor?.nativeElement;
    if (!element) return;
    element.scrollIntoView({
      behavior: "instant",
    });
  }

  private scrollTo(el: HTMLElement): void {
    el.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  openConversationInfo() {
    this.store.dispatch(
      ConversationsPageActions.conversationDetailsOpened({
        conversationId: this.conversation.id,
      })
    );

    if (this.type === "SelfChat") {
      this.sharedService.navigateToProfile();
      return;
    }
    this.menu = "";
    setTimeout(() => {
      if (this.conversation.type === ConversationType.TeamChat) {
        if (this.isRolesTab) {
          const chatParticipant =
            this.conversationService.getTeamChatParticipant(this.conversation);
          this.chatParticipant = chatParticipant;
          this.chatParticipantId = chatParticipant.userId;
          this.menu = "message_details";
        } else {
          this.menu = "role_details";
        }
      } else if (this.conversation.type === ConversationType.External) {
        this.menu = "patient_details";
      } else {
        this.menu = "message_details";
      }
    }, 10);
  }

  openProfileInfo(id: string) {
    this.profileInFocus = id;
    this.menu = "";
    setTimeout(() => {
      this.menu = "profile_details";
      this.sidenav.open();
    }, 10);
  }

  closeConversationInfo() {
    this.menu = "";
  }

  closeInfoBox() {
    this.menu = "";
  }

  wave() {
    this.submitMessage({ content: "👋" });
    this.analyticsService.buttonClickEvent("wave");
  }

  openMessageStatus(message: any) {
    if (!message.id) {
      this.sidenav.close();
      return;
    }
    this.sidenav.open();
    this.showConversationInfo = false;
    this.selectedMessage = message;
    this.showMessageStatus = true;
    this.refreshCount++;
    this.menu = "message_status";
  }

  closeMessageStatus() {
    this.showMessageStatus = false;
    this.selectedMessage = null;
    this.menu = "";
  }

  quoteMessage(message: Message) {
    this.messageService.messageQuoted.next();
    this.store.dispatch(
      ConversationsPageActions.replyToMessage({
        message,
      })
    );
  }

  public copyMessage(message: MessageModel) {
    // Based on https://material.angular.io/cdk/clipboard/overview#programmatically-copy-a-string
    const pending = this.clipboard.beginCopy(message.content);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        pending.destroy();
      }
    };
    attempt();
  }

  public deleteMessage(message: MessageModel) {
    this.store.dispatch(
      ConversationsPageActions.deleteMessage({
        conversationId: message.conversationId,
        marker: message.marker,
      })
    );
  }

  public forwardMessage(message: MessageModel) {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }

    const createMessageModel: CreateMessageModel = {
      marker: this.uuidv4(),
      createdOnUtc: this.getCreatedOnUtc().toISOString(),
      forwardFromMessageId: message.marker,
      forwardFromConversationId: this.conversation.id,
    };

    ConversationPickerDialogComponent.openDialog(this.matDialog, {
      message: createMessageModel,
      header: "Forward",
      createNewConversationButtonText: "Forward to a new chat",
      sendButtonText: "Forward Message",
    })
      .afterClosed()
      .pipe(filter((result) => result != null))
      .subscribe({
        next: ({ conversationId }) => {
          this.store.dispatch(
            ConversationsPageActions.forwardMessage({
              conversationId,
              message: createMessageModel,
              user: this.userAccount,
            })
          );
        },
      });
  }

  addPhotos() {
    LibrarySelectImgsComponent.openDialog(this.matDialog, {
      conversation: this.conversation,
    })
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result) return;
          if (result.photos?.length) {
            this.sendPic(this.conversation.id, result.photos);
          }
        },
      });
  }

  attachFiles() {
    const config = new MatDialogConfig();
    config.data = this.uploader;

    this.dialog
      .open(DropBoxComponent, config)
      .afterClosed()
      .subscribe((result: any) => {
        if (result && result.length) {
        }
      });
  }

  sendUploadedDocuments(uploadedFiles: UploadedFile[], conversationId: string) {
    if (!uploadedFiles || !uploadedFiles.length) return;

    const patientFiles: Omit<CreateMessagePatientFileModel, "createdOnUtc">[] =
      uploadedFiles.map((file) => {
        return {
          marker: this.uuidv4(),
          fileId: file.id,
        };
      });

    this.store.dispatch(
      ConversationsPageActions.sendPatientFiles({
        conversationId,
        user: this.userAccount,
        patientFiles,
      })
    );
  }

  sendUploadedPics(files: UploadedFile[], conversationId: string) {
    if (!files || !files.length) return;
    const photos: SelectedPhoto[] = [];
    for (const file of files) {
      photos.push({
        photoId: file.id,
        marker: this.uuidv4(),
        content: file.fileDescription,
        mediaType: MediaType.Photo,
        type: MediaType.Photo,
      });
    }
    this.sendPic(conversationId, photos);
  }

  private sendPic(conversationId: string, photos: SelectedPhoto[]) {
    const media: Omit<CreateMessageMediaModel, "createdOnUtc">[] = photos.map(
      (photo) => {
        return {
          marker: photo.marker,
          photoId: photo.photoId,
        };
      }
    );

    this.store.dispatch(
      ConversationsPageActions.sendMedia({
        conversationId,
        user: this.userAccount,
        media,
      })
    );
  }

  private hasImportantChanges() {
    return this.messages.some((m) => !m.sentOnUtc);
  }

  public canDeactivate(ask) {
    // called by route candeactivate guard
    if (this.hasImportantChanges()) {
      return ask(); // ask first
    }
    return true;
  }

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener("window:beforeunload", ["$event"])
  unloadNotification($event: any) {
    if (this.hasImportantChanges()) {
      $event.returnValue = "Are you sure?";
    }
  }

  userUnavailableDialog() {
    this.sharedService.userUnavailableDialog();
  }

  onConversationDataUpdated() {
    this.setPatientId(this.conversation);
    this.setParticipantList();
    this.initializeParticipants();
    this.setConversationType(this.conversation);
    this.detectHasLeft(this.userAccount);
    this.setName();
    this.setConversationProfileUri();
    this.updateCurrentUserFlags();
    this.setAccessControlValues();

    if (this.leftChat) {
      this.unsubscribeConversationAction(this.conversation.id);
    }
  }

  private updateCurrentUserFlags() {
    if (!this.userAccount?.userId || !this.pMap) {
      this.isConversationAdmin = false;
      return;
    }

    const participant = this.pMap[this.userAccount.userId];

    if (!participant) {
      this.isConversationAdmin = false;
      this.isActiveParticipant = false;
      return;
    }

    this.isConversationAdmin =
      participant.role === ParticipantRole.Administrator;
    this.isActiveParticipant = !participant.leftOnUtc;
  }

  copy(content) {
    this.sharedService.copyConversationLink(
      this.conversation,
      this.type,
      content
    );
  }

  copyEvent(source) {
    this.analyticsService.raiseLinkEvents(this.type.toLowerCase(), source);
  }

  shareViaEmail(link) {
    this.sharedService.invite(
      this.type.toLowerCase(),
      link,
      this.id,
      this.conversation
    );
  }

  linkEvent(source) {
    this.analyticsService.raiseLinkEvents(this.type.toLowerCase(), source);
  }

  handleResetInvitationLink() {
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }

    this.store.dispatch(
      ConversationsPageActions.resetInvitation({
        conversationId: this.conversation.id,
      })
    );
  }

  toggleInvitationAllowAll() {
    this.store.dispatch(
      ConversationsPageActions.toggleInvitationAllowAll({
        conversationId: this.conversation.id,
      })
    );
  }

  setName() {
    this.name = this.conversationService.getName(
      this.conversation,
      this.type == "SelfChat"
    );
  }

  setConversationProfileUri() {
    if (!this.conversation) return;
    this.conversationProfileUri =
      this.conversationsService.getConversationProfileUri(
        this.conversation.id,
        this.conversation.photoId
      );
  }

  public handleShowTeamMemberProfile(member: TeamMember) {
    if (!member.user?.userId) return;
    this.openProfileInfo(member.user?.userId);
  }

  public handleLeaveTeam(team: Team) {
    if (!team.id) throw new Error("Invalid team id");
    this.teamsService.leaveTeam(team.id);
  }

  public addParticipants: UserPickerSubmitCallback = (users) => {
    const participantUserIds = users
      .filter((u) => !u.isDisabled)
      .map((u) => u.id);

    return this.conversationsService
      .addParticipants(this.conversation.id, participantUserIds)
      .pipe(
        map((conversation) => {
          this.onNewConversationInfo(conversation);
        })
      );
  };

  public handleJoinCall(call: Call) {
    this.voipService.navigateToJoinCall(call.conversationId, call.id);
  }
}
