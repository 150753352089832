import { Component, HostBinding, Input } from "@angular/core";

export interface DescriptionItem {
  content: string;
}

@Component({
  selector: "app-basic-user-card",
  templateUrl: "./basic-user-card.component.html",
  styleUrls: ["./basic-user-card.component.scss"],
})
export class BasicUserCardComponent {
  @Input() public name: string | null = null;
  @Input() public description: string | null = null;
  @Input() public descriptionItems: DescriptionItem[] = [];

  /** Text that will be appended to the end of `name`. */
  @Input() public suffix: string | null = null;

  @Input() public leftContentMargin: string | null = null;

  @Input() public nameFontSize: string | null = null;
  @Input() public descriptionFontSize: string | null = null;
  @Input() public descriptionMaxWidth: string | null = null;

  @Input() public contentWidth: string | null = null;

  @Input() public padding: string | null = null;

  @HostBinding("style.padding")
  public get hostPadding() {
    return this.padding;
  }
}
