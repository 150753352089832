import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "app-celo-contact-card",
  templateUrl: "./celo-contact-card.component.html",
  styleUrls: ["./celo-contact-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CeloContactCardComponent {
  @Input() contact: any;
  @Input() companyInFocus: any;
  @Input() showCompany: boolean;
  @Input() showAllWorkspaces: boolean;
  @Input() showDepartment: boolean;
  @Input() hideWorkspace: boolean;
  @Input() hideOnCall: boolean;
  @Input() showBadge: boolean;
  @Input() hideProfession: boolean;
}
