import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-workspace-list-title",
  templateUrl: "./workspace-list-title.component.html",
  styleUrls: ["./workspace-list-title.component.scss"],
})
export class WorkspaceListTitleComponent {
  @Input() public title: string | null = null;
  @Input() public subtitle: string | null = null;

  constructor() {}
}
