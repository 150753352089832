import { Component, HostListener } from "@angular/core";
import { AuthService } from "@modules/core";

@Component({
  selector: "app-unauthorized",
  templateUrl: "./unauthorized.component.html",
  styleUrls: ["./unauthorized.component.scss"],
})
export class UnauthorizedComponent {
  constructor(private authService: AuthService) {}

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout("UnauthorizedComponent");
  }

  @HostListener("document:mouseenter", ["$event"])
  onMouseEnter(e) {
    this.authService.login();
  }
}
