import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "isValidString",
})
export class IsValidStringPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let valid = false;
    if (value && /\S/.test(value)) {
      valid = true;
    }
    return valid;
  }
}
