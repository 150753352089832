import { inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TitleStrategy } from "@angular/router";
import { CALL_ROUTE_PATH } from "@common";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tapResponse } from "@ngrx/operators";
import { routerNavigatedAction } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { combineLatest, of, switchMap } from "rxjs";
import { config } from "../../configurations/config";
import { selectUnreadMessageCount } from "./conversations";

export const setTitle = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    title = inject(Title),
    titleStrategy = inject(TitleStrategy)
  ) => {
    return combineLatest({
      unreadMessageCount: store.select(selectUnreadMessageCount),
      action: actions$.pipe(ofType(routerNavigatedAction)),
    }).pipe(
      switchMap(({ unreadMessageCount, action }) => {
        // This is done because typescript doesn't properly infer the action type without it
        return of({ unreadMessageCount, action }).pipe(
          tapResponse({
            next: ({ unreadMessageCount, action }) => {
              const pageTitle =
                titleStrategy.buildTitle(action.payload.routerState) ??
                config.title;

              const firstSegment = action.payload.event.urlAfterRedirects
                .split("/")
                .filter((s) => s.length)
                .at(0)
                ?.toLowerCase();

              const isCallRoute =
                firstSegment === CALL_ROUTE_PATH.toLowerCase();

              if (isCallRoute) {
                title.setTitle(pageTitle);
                return;
              }

              let newTitle = `${unreadMessageCount ? `(${unreadMessageCount}) ` : ""}${pageTitle}`;

              // Add ' | Celo' to the title if it doesn't already contain it
              if (!newTitle.endsWith(config.title)) {
                newTitle += ` | ${config.title}`;
              }

              title.setTitle(newTitle);
            },
            error: () => {},
          })
        );
      })
    );
  },
  {
    functional: true,
    dispatch: false,
  }
);

export const appEffects = {
  setTitle,
};
