<ng-container
  *ngIf="{
    isDiscoverable: isDiscoverable$ | async,
    isVerified: isVerified$ | async
  } as data"
>
  <!-- #TODO refactor -->
  <div [class.compact]="compact" class="empty" *ngIf="!data.isVerified">
    <img
      src="../../../../assets/network-empty.png"
      alt="No results"
      width="128"
      height="128"
    />

    <div *ngIf="compact; else nonCompact">
      <h4 class="mat-body-2">Get suggestions</h4>
      <p class="mat-body">
        Verify your identity or workspace to get suggestions and search the
        network.
      </p>
      <a class="celo-link" (click)="verifyIdentity()">
        <button mat-button>Verify Identity</button>
      </a>
    </div>

    <ng-template #nonCompact>
      <h4 class="mat-body-2">Get suggestions</h4>
      <p class="mat-body">
        Verify your identity or workspace to get suggestions and search the
        network.
      </p>
      <a class="celo-link" (click)="verifyIdentity()">
        <button mat-button>Verify Identity</button>
      </a>
    </ng-template>
  </div>

  <div
    [class.compact]="compact"
    class="empty"
    *ngIf="data.isVerified && !data.isDiscoverable"
  >
    <img
      src="../../../../assets/network-empty.png"
      alt="No results"
      width="128"
      height="128"
    />

    <div *ngIf="compact; else nonCompact">
      <h4 class="mat-body-2">Join the Celo community</h4>
      <p class="mat-body">
        You can get suggestions and search the network by becoming discoverable.
      </p>
      <a class="celo-link" routerLink="/edit/privacy">
        <button mat-button>Go to settings</button>
      </a>
    </div>

    <ng-template #nonCompact>
      <h4 class="mat-body-2">Join the Celo community</h4>
      <p class="mat-body">
        You can get suggestions and search the network by becoming discoverable.
      </p>
      <a class="celo-link" routerLink="/edit/privacy">
        <button mat-button>Go to settings</button>
      </a>
    </ng-template>
  </div>
</ng-container>
