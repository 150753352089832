<ng-container [ngSwitch]="conversationType">
  <app-basic-celo-avatar
    *ngSwitchCase="'Chat'"
    [name]="conversationName"
    [userId]="otherParticipantUserId"
    [size]="size"
    [badgeSize]="badgeSize"
    [isWorkspaceVerified]="isWorkspaceVerified"
    [isIdentityVerified]="isIdentityVerified"
    [isProfessionVerified]="isProfessionVerified"
  />

  <app-roles-icon
    *ngSwitchCase="'TeamChat'"
    [backgroundSize]="size + 'px'"
    [size]="rolesIconSize + 'px'"
  />

  <app-celo-avatar
    *ngSwitchDefault
    [width]="size"
    [height]="size"
    [type]="conversationType"
    [conversationPictureUri]="this.conversationPhotoUrl"
  ></app-celo-avatar>
</ng-container>
