import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ConversationResolver } from "app/conversation.resolver";
import { CanDeactivateGuard } from "app/deactivate.guard";
import { AuthGuard } from "app/guards/auth.guard";
import { ForceCreatePasswordGuard } from "app/guards/force-create-password.guard";
import { PinGuard } from "app/guards/pin.guard";
import { MessagesComponent } from "app/messages/messages.component";
import { ConversationsHomeComponent } from "./conversations-home/conversations-home.component";
import { ConversationsComponent } from "./conversations.component";

const routes: Routes = [
  {
    path: "",
    component: ConversationsComponent,
    canActivate: [AuthGuard, ForceCreatePasswordGuard, PinGuard],
    children: [
      {
        path: "",
        component: ConversationsHomeComponent,
      },
      {
        title: "Messages",
        path: ":id/messages",
        component: MessagesComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        title: "Messages",
        path: ":id/messages/:message_id",
        component: MessagesComponent,
        resolve: {
          conversation: ConversationResolver,
        },
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, PinGuard, ConversationResolver, CanDeactivateGuard],
})
export class ConversationsRoutingModule {}
