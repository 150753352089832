import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { SnackbarService } from "./../../core/services/snackbar.service";
import { BasicUserPickerService } from "../basic-user-picker.service";

@Component({
  selector: "app-user-picker-searchbar",
  templateUrl: "./user-picker-searchbar.component.html",
  styleUrls: ["./user-picker-searchbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPickerSearchbarComponent implements OnInit {
  @Input() public placeholder: string | null = null;
  @Input() public minLength: number = 3; // #TODO use validator
  public query = new UntypedFormControl("");

  private valueChangeSubscription: Subscription | null = null;

  public constructor(
    private userPickerService: BasicUserPickerService,
    private snackbarService: SnackbarService
  ) {}

  public ngOnInit(): void {
    this.observeQueryValue();

    // this.userPickerService.reset$.subscribe({
    //   next: () =>
    //     this.query.reset(null, {
    //       emitEvent: false,
    //     }),
    // });
  }

  private observeQueryValue() {
    // Cancel any pending debounced function calls
    this.valueChangeSubscription?.unsubscribe();
    this.valueChangeSubscription = this.query.valueChanges
      .pipe(
        tap(this.clearIfEmpty.bind(this)),
        distinctUntilChanged(),
        debounceTime(500)
      )
      .subscribe({ next: this.search.bind(this) });
  }

  public clearIfEmpty(value: string) {
    if (value) return;
    this.observeQueryValue();
    this.userPickerService.clearSearch();
  }

  public handleEnter() {
    this.observeQueryValue();
    this.search(this.query.value);
  }

  private search(value: string) {
    if (value.length >= this.minLength) {
      this.userPickerService.searchUsers(value);
    } else if (value.length === 0) {
      this.userPickerService.clearSearch();
    } else {
      this.snackbarService.show(
        `Please enter at least ${this.minLength} characters to begin your search.`
      );
    }
  }
}
