import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-companies-list",
  templateUrl: "./companies-list.component.html",
  styleUrls: ["./companies-list.component.scss"],
})
export class CompaniesListComponent {
  @Input() companies = [];
  @Input() responsive = false;
  @Input() editable = false;
  @Output() edit = new EventEmitter<any>();
}
