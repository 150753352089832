import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, combineLatest, EMPTY, Observable, pipe } from "rxjs";
import { bufferTime, filter, map, tap } from "rxjs/operators";
import {
  AddOrRemoveTeamMembersRequestOptions,
  ApiRequestOptions,
  CreateTeamRequestOptions,
  GetTeamRequestOptions,
  MessageModel,
  NotificationPayload,
  Operation,
  PatchTeamRequestOptions,
  Team,
  TeamApiPagedResult,
  TeamApiTokenPagedResult,
  TeamMember,
  TeamMemberRole,
  TeamsRequestOptions,
  UpdateTeamMemberRolesRequestOptions,
  UserTeamsRequestOptions,
  WorkspaceTeamsRequestOptions,
} from "types";
import {
  addOrReplaceElement,
  distinct,
  flatMap,
  isNotNullOrUndefined,
  localeCompareIfNotNull,
  partition,
  sorted,
  toMap,
} from "utils";
import { ConService } from "../old/conn.service";
import { MessageService } from "../old/message.service";
import { SubscriptionContainer } from "./../../../../utils/subscription-container";
import { ApiService } from "./api.service";
import { ConversationsService } from "./conversations.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class TeamsService implements OnDestroy {
  private teamsSubject = new BehaviorSubject<Team[] | null>(null);
  public teams$: Observable<Team[] | null> = this.teamsSubject.asObservable();

  private userTeamsSubject = new BehaviorSubject<Team[] | null>(null);
  public userTeams$: Observable<Team[] | null> =
    this.userTeamsSubject.asObservable();

  private subscriptions = new SubscriptionContainer();
  private getTeamsSubscriptions = new SubscriptionContainer();

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private conService: ConService
  ) {
    // Reload teams when current user's state changes
    const userIdSubscription = this.userService.userId$.subscribe({
      next: (userId) => {
        this.getTeamsSubscriptions.unsubscribe();

        if (!userId) {
          this.teamsSubject.next(null);
          this.userTeamsSubject.next(null);
          return;
        }

        const teamsSubscription = this.getTeams({ fetchAll: true }).subscribe();

        const userTeamsSubscriptions = this.getUserTeams({
          fetchAll: true,
        }).subscribe();

        this.getTeamsSubscriptions.add(
          teamsSubscription,
          userTeamsSubscriptions
        );
      },
    });

    const notificationsSubscription = this.conService.notifications$
      .pipe(filter((s) => s.type === "TeamOnOffCall"))
      .subscribe(this.handleNotification.bind(this));

    this.subscriptions.add(userIdSubscription, notificationsSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private handleNotification(payload: NotificationPayload) {
    // Refresh team
    const subscription = this.getTeamFromApi({
      teamId: payload.resource.id,
    }).subscribe();
    this.subscriptions.add(subscription);
  }

  public getLocalTeam(teamId: string): Team | null {
    const teams = [
      ...(this.teamsSubject.value ?? []),
      ...(this.userTeamsSubject.value ?? []),
    ];
    return teams.find((t) => t.id === teamId) ?? null;
  }

  public isUserAdmin(team: Team, userId: string): boolean {
    return (
      team.members?.find(
        (member) => member.user?.userId === userId && member.leftOnUtc === null
      )?.role === TeamMemberRole.Administrator
    );
  }

  public isCurrentUserAdmin(team: Team): boolean {
    const userId = this.userService.getUserId();
    if (!userId) return false;
    return this.isUserAdmin(team, userId);
  }

  public isCurrentUserOnlyAdmin(team: Team): boolean {
    const adminstrators =
      team.members?.filter(
        (member) => member.role === TeamMemberRole.Administrator
      ) ?? [];
    return adminstrators.length === 1 && this.isCurrentUserAdmin(team);
  }

  public findMember(userId: string, team: Team): TeamMember | null {
    return (
      team.members?.find(
        (member) => member.user?.userId === userId && member.leftOnUtc == null
      ) ?? null
    );
  }

  public isMemberOnCall(member: TeamMember): boolean {
    // User is on call if they haven't left the role and one of the following is true:
    // - they've started being on call and the time they've ended is null
    // - the time they started being on call is greater than the time they ended

    const onCallStartOnUtc = member.onCallStartOnUtc
      ? new Date(member.onCallStartOnUtc)
      : null;
    const onCallEndOnUtc = member.onCallEndOnUtc
      ? new Date(member.onCallEndOnUtc)
      : null;

    if (!onCallStartOnUtc) {
      return false;
    }
    return !onCallEndOnUtc || onCallStartOnUtc > onCallEndOnUtc;
  }

  public isAnyMemberOnCall(team: Team): boolean {
    return this.findFirstMemberOnCall(team) != null;
  }

  public isOnCall(userId: string, team: Team): boolean {
    const member = this.findMember(userId, team);
    if (!member) return false;
    return this.isMemberOnCall(member);
  }

  public isCurrentUserOnCall(team: Team): boolean {
    const userId = this.userService.getUserId();
    if (!userId) return false;
    return this.isOnCall(userId, team);
  }

  public findFirstMemberOnCall(team: Team): TeamMember | null {
    return team.members?.find((member) => this.isMemberOnCall(member)) ?? null;
  }

  public isMember(userId: string, team: Team): boolean {
    return (
      team.members?.findIndex((member) => {
        const user = member.user;
        return user?.userId === userId && member?.leftOnUtc == null;
      }) !== -1
    );
  }

  public isCurrentUserMember(team: Team): boolean {
    const userId = this.userService.getUserId();
    if (!userId) return false;
    return this.isMember(userId, team);
  }

  /**
   * Sort team members using the following ordering rules (from first to last)
   *
   * 1. Current User
   * 2. On-call for this role, alphabetical
   * 3. Role admins, alphabetical
   * 4. Non-admins, alphabetical
   */
  public sortTeamMembers(members: TeamMember[]): TeamMember[] {
    const userId = this.userService.getUserId();

    const sortedMembers = sorted(members, (a, b) =>
      localeCompareIfNotNull(a.user?.fullName, b.user?.fullName)
    );

    // Partition into groups
    const [currentUser, notCurrentUser] = partition(
      sortedMembers,
      (m) => m.user?.userId === userId
    );
    const [onCall, notOnCall] = partition(notCurrentUser, this.isMemberOnCall);
    const [administors, notAdministors] = partition(
      notOnCall,
      (m) => m.role === TeamMemberRole.Administrator
    );

    return currentUser
      .concat(onCall)
      .concat(administors)
      .concat(notAdministors);
  }

  private sortTeamsAlphabetically(teams: Team[]): Team[] {
    return sorted(teams, (a, b) => localeCompareIfNotNull(a.name, b.name));
  }

  /**
   * Sort teams using the following ordering rules (from first to last)
   *
   * 1. Alphabetical
   */
  public sortWorkspaceTeams(teams: Team[]): Team[] {
    return this.sortTeamsAlphabetically(teams);
  }

  /**
   * Sort teams using the following ordering rules (from first to last)
   *
   * 1. Teams where current user is on-call, alphabetical
   * 2. Teams where any other user is on call, alphabetical
   * 3. Teams where no one is on call, alphabetical
   */
  public sortTeams(teams: Team[]): Team[] {
    const sortedTeams = this.sortTeamsAlphabetically(teams);

    const [currentUserOnCall, currentUserNotOnCall] = partition(
      sortedTeams,
      this.isCurrentUserOnCall.bind(this)
    );

    const [onCall, notOnCall] = partition(
      currentUserNotOnCall,
      this.isAnyMemberOnCall.bind(this)
    );

    return [...currentUserOnCall, ...onCall, ...notOnCall];
  }

  public filterTeamsByName(teams: Team[], query: string): Team[] {
    return teams.filter((team) =>
      team.name?.toLowerCase().includes(query.toLowerCase())
    );
  }

  public filterOutInactiveTeams(teams: Team[]): Team[] {
    return teams.filter((t) => t.isActive);
  }

  public filterOutLeftTeamMembers(
    members: TeamMember[],
    filterOutCurrentUser: boolean = false
  ): TeamMember[] {
    const currentUserId = this.userService.getUserId();
    return (
      members.filter(
        (m) =>
          m.leftOnUtc == null &&
          (!filterOutCurrentUser || m.user?.userId !== currentUserId)
      ) ?? []
    );
  }

  /**
   * Replace any teams currently being tracked by this service with any teams in `teams` and add any teams that aren't
   * already being tracked.
   */
  private updateTeams(
    teams: Team[],
    replaceUnreadConversationIds: boolean = false
  ) {
    const [userTeams, nonUserTeams] = partition(teams, (team) =>
      this.isCurrentUserMember(team)
    );

    this.updateTeamsForSubject(
      this.userTeamsSubject,
      this.teamsSubject,
      userTeams,
      replaceUnreadConversationIds
    );
    this.updateTeamsForSubject(
      this.teamsSubject,
      this.userTeamsSubject,
      nonUserTeams,
      replaceUnreadConversationIds
    );
  }

  private updateTeamsForSubject(
    subjectToUpdate: BehaviorSubject<Team[] | null>,
    subjectToFilter: BehaviorSubject<Team[] | null>,
    teams: Team[],
    replaceUnreadConversationIds: boolean = false
  ) {
    const teamsMap = toMap(
      teams,
      (t) => t.id,
      (t) => t
    );

    const updatedTeams =
      subjectToUpdate.value?.map((team) => {
        const newTeam = team.id ? teamsMap.get(team.id) ?? team : team;
        if (replaceUnreadConversationIds) return newTeam;
        return {
          ...newTeam,
          unreadConversationIds: team.unreadConversationIds,
        };
      }) ?? [];

    const newTeams: Team[] = distinct([...updatedTeams, ...teams], (t) => t.id);

    this.removeTeamsFromSubject(subjectToFilter, teams);
    subjectToUpdate.next(newTeams);
  }

  private updateTeam(team: Team) {
    const isUserTeam = this.isCurrentUserMember(team);

    const currentTeams =
      (isUserTeam ? this.userTeamsSubject.value : this.teamsSubject.value) ??
      [];

    const newTeams = addOrReplaceElement(
      currentTeams,
      team,
      (t) => t.id === team.id
    );

    if (isUserTeam) {
      this.removeTeamsFromSubject(this.teamsSubject, [team]);
      this.userTeamsSubject.next(newTeams);
    } else {
      this.removeTeamsFromSubject(this.userTeamsSubject, [team]);
      this.teamsSubject.next(newTeams);
    }
  }

  private removeTeamsFromSubject(
    subject: BehaviorSubject<Team[] | null>,
    teams: Team[]
  ) {
    const teamIds = new Set(teams.map((t) => t.id));
    if (subject.value?.every((t) => !teamIds.has(t.id))) return;
    const newTeams = subject.value?.filter((t) => !teamIds.has(t.id)) ?? null;
    subject.next(newTeams);
  }

  private updateTeamsTap(isUserTeams: boolean = false) {
    const pages: TeamApiTokenPagedResult[] = [];
    return pipe(
      tap<TeamApiTokenPagedResult>({
        next: (page) => pages.push(page),
        complete: () =>
          this.updateTeams(
            pages.flatMap((page) => page.data ?? [], isUserTeams)
          ),
      })
    );
  }

  private updateTeamTap() {
    return pipe(
      tap<Team>({
        next: (team) => this.updateTeam(team),
      })
    );
  }

  private getTokenPagedTeamsFromApi(
    { fetchAll, ...options }: TeamsRequestOptions & ApiRequestOptions,
    path: string,
    isUserTeams: boolean = false
  ): Observable<TeamApiTokenPagedResult> {
    if (fetchAll) {
      return this.apiService
        .getAllByToken<TeamApiTokenPagedResult>({
          path,
          queryParams: { ...options },
        })
        .pipe(this.updateTeamsTap(isUserTeams));
    }
    return this.apiService
      .get<TeamApiTokenPagedResult>({
        path,
        queryParams: { ...options },
      })
      .pipe(this.updateTeamsTap(isUserTeams));
  }

  private getPagedTeamsFromApi(
    { fetchAll, ...options }: TeamsRequestOptions & ApiRequestOptions,
    path: string,
    isUserTeams: boolean = false
  ): Observable<TeamApiPagedResult> {
    if (fetchAll) {
      return this.apiService
        .getAllByOffset<TeamApiPagedResult>({
          path,
          queryParams: { ...options },
        })
        .pipe(this.updateTeamsTap(isUserTeams));
    }
    return this.apiService
      .get<TeamApiPagedResult>({
        path,
        queryParams: { ...options },
      })
      .pipe(this.updateTeamsTap(isUserTeams));
  }

  public searchTeams(
    options: TeamsRequestOptions & ApiRequestOptions
  ): Observable<Team[]> {
    return this.getTeams(options).pipe(flatMap((page) => page.data));
  }

  public getTeams(
    options: TeamsRequestOptions & ApiRequestOptions
  ): Observable<TeamApiTokenPagedResult> {
    const path = `/api/v2/Teams`;
    return this.getTokenPagedTeamsFromApi(options, path);
  }

  public getWorkspaceTeams({
    workspaceId,
    ...options
  }: WorkspaceTeamsRequestOptions &
    ApiRequestOptions): Observable<TeamApiPagedResult> {
    const path = `/api/v2/Workspaces/${workspaceId}/teams`;
    return this.getPagedTeamsFromApi(options, path);
  }

  public getUserTeams(
    options: UserTeamsRequestOptions & ApiRequestOptions
  ): Observable<TeamApiPagedResult> {
    const path = `/api/v2/User/teams`;
    return this.getPagedTeamsFromApi(options, path, true);
  }

  private getTeamFromApi(
    { teamId, ignoreCache, ...options }: GetTeamRequestOptions,
    ...observables: Observable<Team[] | null>[]
  ): Observable<Team> {
    const path = `/api/v2/Teams/${teamId}`;
    const team$ = this.apiService
      .get<Team>({ path, queryParams: { ...options } })
      .pipe(this.updateTeamTap());

    if (ignoreCache) return team$;

    return combineLatest([
      ...observables,
      team$.pipe(map((team) => [team])),
    ]).pipe(
      map((s) => {
        return s.filter(isNotNullOrUndefined).flatMap((x) => x);
      }),
      map((teams) => teams?.find((t) => t?.id === teamId) ?? null),
      filter(isNotNullOrUndefined)
    );
  }

  public getTeam(options: GetTeamRequestOptions): Observable<Team> {
    return this.getTeamFromApi(
      options,
      this.teamsSubject,
      this.userTeamsSubject
    );
  }

  public getUserTeam(options: GetTeamRequestOptions): Observable<Team> {
    return this.getTeamFromApi(options, this.userTeamsSubject);
  }

  public patchTeam(options: PatchTeamRequestOptions): Observable<Team> {
    const { teamId, ...other } = options;

    if (other.name == null && other.description == null) return EMPTY;

    const patches: Operation[] = Object.entries(other).map(([key, value]) => ({
      path: `/${key}`,
      op: "replace",
      value,
    }));

    const path = `/api/v2/Teams/${teamId}`;
    return this.apiService
      .patch<Team>({ path, body: patches })
      .pipe(this.updateTeamTap());
  }

  public leaveTeam(teamId: string): Observable<Team> {
    const path = `/api/v2/Teams/${teamId}/leave`;
    return this.apiService.post<Team>({ path }).pipe(this.updateTeamTap());
  }

  public updateMemberRoles({
    teamId,
    updates,
  }: UpdateTeamMemberRolesRequestOptions): Observable<Team> {
    const path = `/api/v2/Teams/${teamId}/members/roles`;
    return this.apiService
      .post<Team>({ path, body: updates })
      .pipe(this.updateTeamTap());
  }

  private addOrRemoveMembers(
    { teamId, userIds }: AddOrRemoveTeamMembersRequestOptions,
    isAdding: boolean = false
  ): Observable<Team> {
    const path = isAdding
      ? `/api/v2/Teams/${teamId}/members`
      : `/api/v2/Teams/${teamId}/members/remove`;
    const body = userIds.map((userId) => ({ userId }));
    return this.apiService
      .post<Team>({ path, body })
      .pipe(this.updateTeamTap());
  }

  public removeMembers(
    options: AddOrRemoveTeamMembersRequestOptions
  ): Observable<Team> {
    return this.addOrRemoveMembers(options);
  }

  public addMembers(
    options: AddOrRemoveTeamMembersRequestOptions
  ): Observable<Team> {
    return this.addOrRemoveMembers(options, true);
  }

  public createTeam({
    workspaceId,
    ...body
  }: CreateTeamRequestOptions): Observable<Team> {
    const path = `/api/v2/Workspaces/${workspaceId}/teams`;
    return this.apiService
      .post<Team>({ path, body })
      .pipe(this.updateTeamTap());
  }

  public clockIn(teamId: string): Observable<Team> {
    const path = `/api/v2/Teams/${teamId}/clockin`;
    return this.apiService.post<Team>({ path }).pipe(this.updateTeamTap());
  }

  public clockOut(
    teamId: string,
    clockInUserIds: string[] = []
  ): Observable<Team> {
    const path = `/api/v2/Teams/${teamId}/clockout`;
    return this.apiService
      .post<Team>({
        path,
        body: { clockInUserIds },
      })
      .pipe(this.updateTeamTap());
  }
}
