import { HttpClient, HttpParams } from "@angular/common/http";
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { environment } from "@env";
import { AlertService, UserAccountService } from "@modules/core";
import { FileUploader } from "ng2-file-upload";
import { retry, tap } from "rxjs/operators";
import { LibraryService } from "../library.service";
import { ConsentService } from "./consent.service";
import { LibraryLightboxConsentContent } from "../library-lightbox/library-lightbox.component";
import { MatDialog } from "@angular/material/dialog";
import { AlertComponent } from "@modules/core/components";

@Component({
  selector: "app-consents",
  templateUrl: "./consents.component.html",
  styleUrls: ["./consents.component.scss"],
})
export class ConsentsComponent implements OnInit, OnDestroy, AfterViewChecked {
  consents: Array<ConsentViewModel> = [];
  loading = true;
  searchTerm = "";
  // searchSub = null
  actionSub = null;
  hideLoadMore = false;
  pageSize = 12;
  isSelecting = false;
  public uploader: FileUploader;
  dropZoneActive = "";
  tile_width: number;
  userAccount: any;
  userId: string;
  createdBy = "0";

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private libraryService: LibraryService,
    private consentService: ConsentService,
    private _changeDetectionRef: ChangeDetectorRef,
    private userAccountService: UserAccountService,
    private matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.actionSub = this.libraryService.consentActionEmitted$.subscribe(
      this.onAction.bind(this)
    );
    this.loading = true;
    this.doSearch();

    const instance = this;
    this.userAccountService.getUserAccount(false, function (data) {
      instance.userAccount = data;
      instance.userId = instance.userAccount.userId;
    });
  }

  ngAfterViewChecked(): void {
    this._changeDetectionRef.detectChanges();
  }

  noneSelected() {
    this.alertService.showSnackBar("No consents selected !", 2);
    return;
  }

  app() {
    const payload = [
      {
        type: "downloadMobileAppInfo",
        message: "Please download the Celo mobile app to create consents.",
      },
    ];
    this.alertService.customDialog(
      "Celo mobile app ",
      "",
      "DONE",
      "",
      true,
      "vertical",
      payload
    );
  }

  ngOnDestroy() {
    this.actionSub.unsubscribe();
    this.libraryService.emitCanSelect(false);
  }

  startSelect() {
    this.isSelecting = true;
    this.libraryService.emitCanSelect(this.isSelecting);
  }

  cancelSelect() {
    this.isSelecting = false;
    this.libraryService.emitCanSelect(this.isSelecting);
  }

  onNewScrollPosition() {
    this.pageSize += 20;
    this.doSearch();
  }

  queryChange(searchTerm: string) {
    if (typeof searchTerm != "string") {
      return;
    }

    this.searchTerm = searchTerm;
    if (this.searchTerm.length > 0 && this.searchTerm.length < 6) {
      // todo: should we warn? what about for patient ids?
      return;
    }
    this.consents = [];
    this.resetPageSize();
    this.loading = true;
    this.doSearch();
  }

  resetPageSize() {
    this.pageSize = 10;
  }

  filterChange() {
    this.resetPageSize();
    this.consents = [];
    this.loading = true;
    this.doSearch();
  }

  doSearch() {
    const path = `${environment.celoApiEndpoint}/api/Consents`;
    const params = new HttpParams()
      .set("Page", "0")
      .set("PageSize", String(this.pageSize))
      .set("createdBy", this.createdBy ? this.createdBy : "0")
      .set("Uid", this.searchTerm);

    this.http
      .get<any>(path, { params })
      .pipe(
        retry(3),
        tap(() => (this.loading = false))
      )
      .subscribe({
        next: (data) => {
          this.loading = false;
          if (data.total < this.pageSize) {
            this.hideLoadMore = true;
          } else {
            this.hideLoadMore = false;
          }
          const consentsData = data.data.map((item) => {
            const vm = new ConsentViewModel();
            vm.payload = item;
            vm.selected = false;
            return vm;
          });
          for (let i = 0; i < consentsData.length; i++) {
            const a = consentsData[i];
            const b = consentsData[(i + 1) % consentsData.length];
            a.next = b;
            b.prev = a;
            const existing = this.consents.find(
              (c) => a.data && c.payload && c.payload.id == a.data.id
            );
            if (!existing) {
              this.consents.push(a);
            }
          }
        },
        error: (err) => {
          this.loading = false;
          AlertComponent.openErrorDialog(
            this.matDialog,
            "Error loading consent forms."
          );
        },
      });
  }

  onSelected() {
    this.libraryService.emitShowAction(this.anySelected());
  }

  private anySelected(): boolean {
    for (const consent of this.consents) {
      if (consent.selected) {
        return true;
      }
    }
    return false;
  }

  public getSelectedCount(): number {
    let count = 0;
    for (const document of this.consents) {
      if (document.selected) {
        count++;
      }
    }
    return count;
  }

  public getFirstSelected(): ConsentViewModel {
    for (const consent of this.consents) {
      if (consent.selected) {
        return consent;
      }
    }
    return null;
  }

  onAction(action: string) {
    if (action == "preview") {
      this.preview();
    } else if (action == "download") {
      this.download();
    } else if (action == "share") {
      // share consent ?
    }
  }

  private getSelected(): any {
    const selected = [];
    for (const consent of this.consents) {
      if (consent.selected) {
        selected.push(consent);
      }
    }

    return selected;
  }

  private preview() {
    const consent = this.getFirstSelected();
    const data: LibraryLightboxConsentContent = {
      ...consent,
      mediaType: "consent",
    };
    if (consent) {
      this.libraryService.openLightbox(data);
    }
  }

  private download() {
    const consents = this.getSelected();
    if (!consents.length) {
      this.noneSelected();
      return;
    }
    this.consentService.downloadDocs(consents);
  }

  public reload() {
    this.consents = [];
    this.loading = true;
  }
}

export class ConsentViewModel {
  payload: any;
  selected: boolean;
  next: ConsentViewModel;
  prev: ConsentViewModel;
}
