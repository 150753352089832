<mat-drawer-container class="fh">
  <mat-drawer
    #drawer
    mode="over"
    position="end"
    class="drawer"
    (closed)="handleDrawerClosed()"
  >
    <!-- Note: this component needs to be re-mounted when the drawer is opened again -->
    <app-broadcast-status
      *ngIf="drawer.open && selectedBroadcastId"
      (back)="drawer.close()"
      [broadcastId]="selectedBroadcastId"
    />
  </mat-drawer>

  <mat-drawer-content>
    <div #parent class="fh celo-page">
      <div #toolbar class="toolbar appear_anim_1">
        <div class="left">
          <a
            *ngIf="
              dataSource &&
              dataSource.filteredData &&
              dataSource.filteredData.length
            "
            (click)="newBroadcast()"
          >
            <button mat-button class="celo-primary-button">
              New broadcast
            </button>
          </a>
        </div>
        <div class="right">
          <form action="" class="flex justify-end items-center">
            <input
              type="text"
              name="search"
              placeholder="Search by keyword"
              [(ngModel)]="keyword"
              (keyup.enter)="search()"
              (ngModelChange)="change()"
              autocomplete="off"
            />
            <button
              *ngIf="keyword"
              type="button"
              class="close"
              mat-icon-button
              (click)="cancelSearch()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </form>
        </div>
      </div>

      <div class="appear_anim_2">
        <div
          *ngIf="
            dataSource &&
            dataSource.filteredData &&
            dataSource.filteredData.length &&
            !loading
          "
          class="fh mat-elevation-z1"
        >
          <div
            class="example-table-container"
            [style.max-height.px]="
              parent.offsetHeight - toolbar.offsetHeight - 120
            "
          >
            <table mat-table [dataSource]="dataSource" matSort>
              <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef>Broadcast Message</th>
                <td mat-cell *matCellDef="let element">
                  <div class="profile">
                    <div *ngIf="userAccount && userAccount.userId" class="icon">
                      <app-basic-celo-avatar
                        [name]="
                          userAccount.firstName + ' ' + userAccount.lastName
                        "
                        [userId]="userAccount.userId"
                        [size]="40"
                      ></app-basic-celo-avatar>
                    </div>
                    <div *ngIf="userAccount" class="name">
                      <div class="text">
                        {{ userAccount.firstName }} {{ userAccount.lastName }}
                      </div>
                      <div class="subtext">
                        {{ element.createdOnUtc | dateFormat: "shortTime" }} -
                        {{ element.createdOnUtc | dateFormat: "fullDate" }}
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="element.type === 'Photo' || element.type === 'File'"
                    class="attachments"
                  >
                    <div
                      *ngIf="element.type === 'Photo'"
                      class="attachment-item"
                    >
                      <i class="material-icons">image</i>
                      <div>Photo</div>
                    </div>
                    <div
                      *ngIf="element.type === 'File'"
                      class="attachment-item"
                    >
                      <i class="material-icons">insert_drive_file</i>
                      <div>Document</div>
                    </div>
                  </div>
                  <div class="message">
                    {{ element.content }}
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="sent">
                <th mat-header-cell *matHeaderCellDef>Sent</th>
                <td mat-cell *matCellDef="let element" class="numbers">
                  {{ element.sentCount ? element.sentCount : 0 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="delivered">
                <th mat-header-cell *matHeaderCellDef>Delivered</th>
                <td mat-cell *matCellDef="let element" class="numbers">
                  {{ element.deliveredCount ? element.deliveredCount : 0 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="seen">
                <th mat-header-cell *matHeaderCellDef>Seen</th>
                <td mat-cell *matCellDef="let element" class="numbers">
                  {{ element.readCount ? element.readCount : 0 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="seenpercent">
                <th mat-header-cell *matHeaderCellDef>Seen %</th>
                <td mat-cell *matCellDef="let element" class="numbers">
                  {{
                    element.readCount && element.sentCount
                      ? ((element.readCount / element.sentCount) * 100
                        | number: "1.1-1")
                      : 0
                  }}
                </td>
              </ng-container>

              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <!-- This element is hidden instead of removed as we need it to determine the width of the column itself -->
                  <button
                    [class.invisible]="!element.isViewStatusEnabled"
                    class="view-status"
                    (click)="handleViewStatus(element)"
                  >
                    View status
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                class="celo-hoverable"
              ></tr>
            </table>
            <div id="bottomAnchor" #bottomAnchor></div>
          </div>
          <div class="pagination">
            <div *ngIf="!keyword">
              Showing {{ dataSource.filteredData.length }} out of
              {{ total }} broadcasts
            </div>
            <button
              *ngIf="dataSource.filteredData.length < total"
              mat-button
              (click)="loadMore()"
              [disabled]="loading"
              class="celo-tertiary-button"
            >
              Load More >>
            </button>
          </div>
        </div>
        <!-- {{loading}} {{dataSource.filteredData.length}} -->

        <div
          *ngIf="
            !dataSource ||
            !dataSource.filteredData ||
            !dataSource.filteredData.length
          "
          class="fh"
        >
          <div class="empty">
            <div *ngIf="!loading" class="content">
              <div
                *ngIf="
                  !broadcastService.history || !broadcastService.history.length
                "
              >
                <img src="../../../assets/empty_states/broadcast.svg" alt="" />
                <div class="subtext m-3">
                  Create a new broadcast by clicking below.
                </div>
                <div class="m-3">
                  <a (click)="newBroadcast()">
                    <button mat-button class="celo-primary-button">
                      NEW BROADCAST
                    </button>
                  </a>
                </div>
              </div>
              <div
                *ngIf="
                  broadcastService.history && broadcastService.history.length
                "
              >
                <app-empty-state
                  [isLoading]="false"
                  [isEmpty]="true"
                  [message]="'No results found'"
                  [noImage]="true"
                ></app-empty-state>
              </div>
            </div>
            <div *ngIf="loading" class="content">
              <app-empty-state [isLoading]="true"></app-empty-state>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
