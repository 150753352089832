<div class="search-directory">
  <i class="material-icons" (click)="search.focus()">search</i>
  <input
    type="text"
    [(ngModel)]="searchTerm"
    #search
    maxlength="30"
    autocomplete="off"
    autofocus
    name="search"
    placeholder="{{ placeholder }}"
    (ngModelChange)="changed($event)"
    (keydown.enter)="onSearch(searchTerm)"
  />
  <!-- (ngModelChange)="onSearch()" -->
</div>
