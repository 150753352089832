import { Component, Input } from "@angular/core";

@Component({
  selector: "app-basic-spinner",
  templateUrl: "./basic-spinner.component.html",
  styleUrls: ["./basic-spinner.component.scss"],
})
export class BasicSpinnerComponent {
  @Input() public diameter: number = 30;
  @Input() public strokeWidth: number = 2;
}
