import { Injectable } from "@angular/core";
import { ApiService, ConversationService } from "@modules/core";
import {
  Broadcast,
  BroadcastRecipient,
  GetBroadcastRecipientsRequest,
  TokenPagedRequestOptions,
  TokenPagedResponse,
} from "@types";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";
import { share } from "rxjs/operators";
import { AlertService } from "../modules/core/old/alert.service";
import { ConService } from "../modules/core/old/conn.service";
import { SharedService } from "../modules/core/old/shared.service";
import { getComputedLocalTime } from "@utils";

@Injectable({
  providedIn: "root",
})
export class BroadcastService {
  history: Broadcast[] = [];

  private BroadcastChangedSubject = new Subject<any>();
  public BroadcastChange$ =
    this.BroadcastChangedSubject.asObservable().pipe(share());

  constructor(
    private conService: ConService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private apiService: ApiService,
    private conversationService: ConversationService
  ) {
    this.watchMessagesToUpdateConvos();
  }

  private getCreatedOnUtc(): Date {
    const latestDate =
      this.conversationService.getLatestConversationModifiedTimeOrMessageSentOnUtc();
    return getComputedLocalTime(latestDate);
  }

  sendAttachment(attachment, recipients, callback) {
    const createMessage: any = {
      content: null,
      createdOnUtc: this.getCreatedOnUtc(),
      recipients,
    };
    if (attachment["type"] == "Document") {
      createMessage["type"] = "File";
      createMessage["fileId"] = attachment["id"];
    } else if (attachment["type"] == "Photo") {
      createMessage["type"] = "Photo";
      createMessage["photoId"] = attachment["id"];
    }
    createMessage["photoId"] = attachment["id"];
    const path = environment.celoBroadcastApiEndpoint + "/api/Messages";
    this.sharedService.postObjectById(path, {}, createMessage).subscribe(
      (resp) => {
        callback(resp);
      },
      (err) => {
        const message = this.sharedService.getErrorMessageFromError(err);
        this.alertService.confirm("", message, "Close", "", true);
        callback(null);
      }
    );
  }

  sendMessage(content, recipients, callback) {
    const createMessage: any = {
      content,
      createdOnUtc: this.getCreatedOnUtc(),
      recipients,
    };
    const path = environment.celoBroadcastApiEndpoint + "/api/Messages";
    this.sharedService.postObjectById(path, {}, createMessage).subscribe(
      (resp) => {
        callback(resp);
      },
      (err) => {
        const message = this.sharedService.getErrorMessageFromError(err);

        if (message) {
          this.alertService.confirm("", message, "Close", "", true);
        } else {
          this.alertService.error();
        }

        callback(null);
      }
    );
  }

  watchMessagesToUpdateConvos() {
    this.conService.Broadcast$.subscribe((broadcasts: any[]) => {
      broadcasts.forEach((broadcast) => {
        this.checkAndUpdateStatus(broadcast);
      });
    });
  }

  checkAndUpdateStatus(newBroadcast: any) {
    if (!newBroadcast["id"]) {
      return;
    }
    this.history.forEach((existingBroadcast) => {
      if (existingBroadcast["id"] == newBroadcast["id"]) {
        // existingBroadcast = newBroadcast;
        existingBroadcast["deliveredCount"] = newBroadcast["deliveredCount"];
        existingBroadcast["readCount"] = newBroadcast["readCount"];
        existingBroadcast["sentCount"] = newBroadcast["sentCount"];
        existingBroadcast["sentOnUtc"] = newBroadcast["sentOnUtc"];
        existingBroadcast["type"] = newBroadcast["type"];
      } else {
      }
    });
  }

  mergeData(broadcastInfo) {
    this.history.forEach((element) => {
      if (element["coalseceId"] == broadcastInfo["coalseceId"]) {
        element = broadcastInfo;
        return;
      }
    });
  }

  getBroadcasts(pageSize, callback) {
    const path = environment.celoBroadcastApiEndpoint + "/api/messages";
    const instance = this;
    const params = {
      pageSize,
    };

    this.sharedService.getObjectById(path, params).subscribe(
      (data) => {
        instance.history = [];
        if (data && data.data) {
          instance.history = data.data;
        }
        callback(data.total);
      },
      (err) => {
        callback(null);
      }
    );
  }

  getRecipients({
    id,
    ...params
  }: TokenPagedRequestOptions & GetBroadcastRecipientsRequest): Observable<
    TokenPagedResponse<BroadcastRecipient>
  > {
    // This is done as we don't want to include any additional properties that may be on 'params'
    const queryParams: TokenPagedRequestOptions &
      Omit<GetBroadcastRecipientsRequest, "id"> = {
      status: params.status,
      pageSize: params.pageSize,
      search: params.search,
      token: params.token,
    };

    return this.apiService.get<TokenPagedResponse<BroadcastRecipient>>({
      basePath: environment.celoBroadcastApiEndpoint,
      path: `/api/Messages/${id}/recipients`,
      queryParams: { ...queryParams },
    });
  }
}
