import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { AlertService } from "./modules/core/old/alert.service";

export interface CanComponentDeactivate {
  canDeactivate?(ask: any): Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard
  
{
  constructor(private alertService: AlertService) {}

  canDeactivate(component: CanComponentDeactivate) {
    // note: you need to add your own listener for window:beforeunload, see directory-company.component.ts

    const ask = () => {
      const title = "Are you sure you want to leave this page?";
      const content = "All unsaved changes will be lost.";
      const yesButtonText = "Leave this page";
      const noButtonText = "Stay on this page";
      return this.alertService.confirm(
        title,
        content,
        yesButtonText,
        noButtonText
      );
    };
    // the component can ask if it wants, or we can ask here.
    if (component) {
      return typeof component.canDeactivate === "function"
        ? component.canDeactivate(ask)
        : ask();
    }
  }
}
