<!-- #TODO May want to refactor, but not a high priority -->
<p
  class="mat-body"
  [ngPlural]="count"
  *ngIf="selectedUserCount | async as count; else fallback"
>
  <ng-template ngPluralCase="=1">{{ count }} {{ one }} selected</ng-template>
  <ng-template ngPluralCase="other"
    >{{ count }} {{ plural }} selected
  </ng-template>
</p>

<ng-template #fallback>
  <p class="mat-body">0 {{ zero }} selected</p>
</ng-template>
