import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Subject } from "rxjs";
import { CompanyBasicModel, ContactModel } from "types";
import {
  Contact,
  ContactGroup,
  ContactGroupState,
} from "../contacts-expansion-panel/contacts-expansion-panel.component";
import { CeloUserCardCompanyInput } from "./../../../../modules/shared/celo-user-card/celo-user-card.component";

export type ContactListType =
  | "connections"
  | "colleagues"
  | "workspace"
  | "network"
  | "suggestions";

@Component({
  selector: "app-contact-list",
  templateUrl: "./contact-list.component.html",
  styleUrls: ["./contact-list.component.scss"],
})
export class ContactListComponent implements OnInit, OnChanges {
  @Input() selectable: boolean;
  @Input() contactGroup: ContactGroup = {
    contacts: [],
    state: ContactGroupState.Unchecked,
  };

  @Input() isAddingParticipants = false;
  @Input() uneditableContactIds: Set<string> = new Set();
  @Input() selectedContactsMap: { [key: string]: Contact } = {};
  @Input() canDeselect = false;
  @Input() selectAllContacts$: Subject<any> = new Subject<any>();
  @Input() userAccount: any = {};
  @Input() type: ContactListType;
  @Input() companyInFocus: CompanyBasicModel;
  @Input() companyInFocusId: string;

  @Output() contactClicked = new EventEmitter<Contact>();
  @Output() contactSelected = new EventEmitter<Contact>();
  @Output() remove = new EventEmitter<Contact>();

  public companyInFocusMap: {
    [x: string]: CeloUserCardCompanyInput;
  } = {};

  constructor() {}

  ngOnInit(): void {
    this.UpdateSubtext();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.UpdateSubtext();
  }

  click(contact: Contact) {
    if (this.uneditableContactIds.has(contact.userId)) {
      return;
    }

    if (this.selectable) {
      if (!!this.selectedContactsMap[contact.userId]) {
        this.remove.emit(contact);
      } else {
        this.contactSelected.emit(contact);
      }
    } else {
      this.contactClicked.emit(contact);
    }
  }

  private UpdateSubtext(): void {
    switch (this.type) {
      case "connections":
        break;
      case "colleagues":
        this.UpdateColleaguesSubtext();
        break;
      case "workspace":
        this.UpdateWorkspaceSubtext();
        break;
      case "network":
        break;
      case "suggestions":
        break;
      default:
        throw new Error(`Unhandled contact list type '${this.type}'`);
    }
  }

  private UpdateColleaguesSubtext(): void {
    const contacts: ContactModel[] = this.contactGroup
      .contacts as ContactModel[];

    for (const contact of contacts) {
      const items: string[] = [];
      const workspace = contact.workplaces?.[0];

      if (!workspace) {
        continue;
      }

      this.companyInFocusMap[contact.userId] = {
        id: workspace.companyId,
      };
    }
  }

  private UpdateWorkspaceSubtext(): void {
    const contacts: ContactModel[] = this.contactGroup
      .contacts as ContactModel[];

    for (const contact of contacts) {
      const workspace = contact.workplaces.filter(
        (w) => w.companyId === this.companyInFocusId
      )?.[0];
      if (!workspace) {
        continue;
      }
      this.companyInFocusMap[contact.userId] = {
        id: workspace.companyId,
      };
    }
  }
}
