<div
  class="messages"
  ng2FileDrop
  [uploader]="uploader"
  (fileOver)="dropZoneState($event)"
  (onFileDrop)="handleDrop($event)"
  [ngClass]="{
    valid: dropZoneActive === 'valid',
    gone: !leftChat && uploader && uploader.queue.length !== 0
  }"
>
  <div class="drop-highlight">
    <div>
      <i class="material-icons icon-xxl">cloud_upload</i>
      <div>UPLOAD</div>
    </div>
  </div>

  <div class="messages-box fh">
    <div
      [ngClass]="{ 'my-backdrop-on': sidenav.opened }"
      (click)="sidenav.close()"
      class="my-backdrop"
    ></div>
    <mat-sidenav-container class="fh">
      <mat-sidenav #sidenav mode="over" position="end">
        <div
          class="header"
          [class.patient-details]="menu === 'patient_details'"
        >
          <div class="title">
            <span *ngIf="menu === 'message_status'">Message status</span>
            <span *ngIf="menu === 'message_details'">Details</span>
            <span *ngIf="menu === 'role_details'">Role Details</span>
            <span *ngIf="menu === 'profile_details'">Profile</span>
            <span *ngIf="menu === 'invite'"
              >Invite to <span class="lowercase">{{ type }}</span></span
            >
            <span *ngIf="menu === 'search'">Search</span>
            <span *ngIf="menu === 'patient_details'" class="patient-details"
              >Details</span
            >
          </div>
          <div class="close-button">
            <button
              mat-icon-button
              class="celo-hoverable"
              (click)="sidenav.close()"
            >
              <i class="material-icons-round">close</i>
            </button>
          </div>
        </div>

        <div
          *ngIf="menu === 'message_status' && selectedMessage"
          class="sidenav-status"
        >
          <app-message-status
            [userAccount]="userAccount"
            [id]="selectedMessage.id"
            [message]="selectedMessage"
            [refreshCount]="refreshCount"
            [conversationType]="type"
            [pMap]="pMap"
            (updateMessage)="updateMessage($event, true)"
          >
          </app-message-status>
        </div>

        <div *ngIf="menu === 'message_details'" class="sidenav-content">
          <app-message-detail
            [(conversation)]="conversation"
            [participants]="participants"
            [activeAdminList]="activeAdminList"
            (closeClick)="sidenav.close()"
            (leftChatEvent)="leftChat = true"
            (openWindow)="handleMessageDetailOpenWindow($event)"
            (isAdmin)="isAdmin = $event"
            [leftChat]="leftChat"
            [type]="type"
            [chatParticipant]="chatParticipant"
            [profileInFocus]="chatParticipantId"
            [userAccount]="userAccount"
            [refreshCount]="refreshCount"
            (userDataChanged)="getChatParticipantApi()"
            (conversationChange)="onNewConversationInfo($event)"
          >
          </app-message-detail>
        </div>
        <div *ngIf="menu === 'role_details'" class="sidenav-content">
          <app-role-details
            [team]="team"
            [hidePrimaryAction]="true"
            (leaveTeam)="handleLeaveTeam($event)"
            (showProfile)="handleShowTeamMemberProfile($event)"
          ></app-role-details>
        </div>
        <div *ngIf="menu === 'profile_details'" class="sidenav-content">
          <app-message-detail
            [(conversation)]="conversation"
            [showParticipantProfile]="true"
            [profileInFocus]="profileInFocus"
            [userAccount]="userAccount"
            (closeClick)="sidenav.close()"
            (openWindow)="menu = $event; refreshConversationInfo()"
            [type]="type"
          >
          </app-message-detail>
        </div>
        <div *ngIf="menu === 'invite' && type" class="sidenav-content">
          <div>
            <div class="section">
              <app-link-share
                [link]="conversation.invitation.uri"
                [linkFallback]="'Connect to internet to generate a link'"
                [title]=""
                [subtitle]="
                  'Invite your team to this ' +
                  type.toLowerCase() +
                  ' by sharing the ' +
                  type.toLowerCase() +
                  ' invite link. Anyone with the link can join, so share it with people you trust. '
                "
                [hideLink]="true"
                [canReset]="false"
                [copyButttonText]="
                  'Copy ' + type.toLowerCase() + ' invite link'
                "
                [shareBody]="
                  'Follow this link to join the ' +
                  conversation.name +
                  ' ' +
                  type.toLowerCase() +
                  ' on Celo \n' +
                  conversation.invitation.uri
                "
                (linkClick)="
                  conversation.invitation.uri
                    ? copy(conversation.invitation.uri)
                    : alertService.connectToInternetSnackbar();
                  linkEvent('details_link')
                "
                (copyClick)="
                  conversation.invitation.uri
                    ? copy(conversation.invitation.uri)
                    : alertService.connectToInternetSnackbar();
                  copyEvent('copy_from_details_page')
                "
                (shareClick)="
                  shareViaEmail(conversation.invitation.uri);
                  linkEvent('share_button')
                "
              >
              </app-link-share>
            </div>
            <div *ngIf="isAdmin" class="section">
              <div class="section-title">Admin Settings</div>
              <div
                *ngIf="conversation.invitation"
                class="invite-toggle celo-cursor-pointer celo-hoverable"
                matRipple
                (click)="toggleInvitationAllowAll()"
              >
                <div class="icon">
                  <mat-icon svgIcon="ic-settings"></mat-icon>
                </div>
                <div class="texts">
                  <div class="title">Allow all participants to invite</div>
                  <div class="subtitle">
                    <span *ngIf="!conversation.invitation.allowAll"
                      >Only admins can invite via link</span
                    >
                    <span *ngIf="conversation.invitation.allowAll"
                      >All participants can invite via link</span
                    >
                  </div>
                </div>
                <div class="toggle">
                  <mat-slide-toggle
                    #slider
                    hideIcon
                    [checked]="conversation.invitation.allowAll"
                  ></mat-slide-toggle>
                </div>
              </div>
              <div class="reset-link">
                <button
                  mat-button
                  class="celo-hoverable"
                  (click)="
                    conversation.invitation.uri
                      ? handleResetInvitationLink()
                      : alertService.connectToInternetSnackbar()
                  "
                >
                  <div class="content">
                    <mat-icon svgIcon="ic-reset"></mat-icon>
                    <div>Reset link</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="menu === 'search'" class="fh fw">
          <app-search
            [conversationId]="id"
            [pMap]="pMap"
            [type]="type === 'SelfChat' ? 'Chat' : type"
            [userAccount]="userAccount"
            (selected)="searchSelect($event)"
          >
          </app-search>
        </div>
        <div *ngIf="menu === 'patient_details'" class="sidenav-content p-0">
          <app-external-chat-details
            [externalUserId]="externalChatParticipant?.userId ?? ''"
            [externalLastName]="conversation?.patientData?.lastName ?? ''"
            [externalFirstName]="conversation?.patientData?.firstName ?? ''"
            [externalDateOfBirth]="conversation?.patientData?.dateOfBirth ?? ''"
            [externalPhoneNumber]="conversation?.patientData?.phoneNumber ?? ''"
            [externalUpdateFn]="updatePatientDetails"
            (externalDetails)="onNewPatientDetails($event)"
            [participants]="participants"
            [addParticipantsFn]="addParticipants"
            [conversationId]="conversation?.id"
            (conversationChange)="onNewConversationInfo($event)"
            (closeClick)="sidenav.close()"
          ></app-external-chat-details>
        </div>
      </mat-sidenav>

      <mat-sidenav-content class="relative fh">
        <div class="sidenav-content-container">
          <div class="subject">
            <app-messages-subject-line
              *ngIf="userAccount && userAccount.userId && conversation && type"
              [userAccount]="userAccount"
              [conversation]="conversation"
              [participantToShow]="chatParticipant"
              [typing]="typing"
              [isOpened]="sidenav.opened"
              [allowedToShowTyping]="allowedToShowTyping"
              [allowedToShowLastOnline]="allowedToShowLastOnline"
              [isRolesTab]="isRolesTab"
              [inProgressCall]="inProgressCall$ | async"
              (infoClick)="openConversationInfo(); sidenav.open()"
              (searchClick)="menu = 'search'; sidenav.open()"
              (closeClick)="sidenav.close(); sidenavClosed()"
            >
            </app-messages-subject-line>
          </div>
          <div
            *ngIf="inProgressCall$ | async as inProgressCall"
            class="incoming-call-bar"
          >
            <app-messages-call-bar
              [call]="inProgressCall"
              (join)="handleJoinCall($event)"
            />
          </div>
          <div class="message-box relative">
            <div *ngIf="searching" class="search-message appear_anim_1">
              <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
            <div *ngIf="messageId" class="preview-banner appear_anim_1">
              <div class="text">This is a preview</div>
              <div class="button">
                <button
                  mat-button
                  class="celo-hoverable"
                  (click)="goToLatest()"
                >
                  Go to latest message
                </button>
              </div>
            </div>

            <div #scrollMe (scroll)="onScroll()" class="scroll">
              <div
                *ngIf="
                  chatParticipant &&
                  type === 'Chat' &&
                  isOnDND &&
                  !chatParticipant?.blockedMe?.isBlocked
                "
                class="dnd-warning appear_1"
              >
                <div class="content">
                  {{ chatParticipant.firstName }} is set to 'Do not disturb'.
                  This user will still receive your message but will not be
                  notified.
                </div>
              </div>
              <div *ngIf="type === 'Case'" class="case-container">
                <div class="case-info appear_1">
                  <div class="content">
                    <span>Patient Case</span
                    >{{
                      conversation?.patientData?.uid
                        ? ": " + conversation?.patientData?.uid
                        : ""
                    }}{{
                      conversation?.patientData?.uid &&
                      (conversation?.patientData?.firstName ||
                        conversation?.patientData?.lastName)
                        ? ","
                        : ""
                    }}
                    {{ conversation?.patientData?.firstName }}
                    {{ conversation?.patientData?.lastName }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  messageId &&
                  !(isPreviousPageOfMessagesLoading$ | async) &&
                  messages[0] &&
                  messages[0].type !== 'ConversationStarted'
                "
                class="load-more-buttons"
              >
                <button mat-button (click)="loadMorePrevious()">
                  Load more...
                </button>
              </div>
              <div
                *ngIf="userAccount?.userId && conversation && pMap && messages"
                class="appear_0"
              >
                <div
                  *ngIf="isPreviousPageOfMessagesLoading$ | async"
                  class="more"
                >
                  <div class="spinner">
                    <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
                  </div>
                </div>
                <div>
                  <div
                    *ngIf="
                      messages[0] && messages[0].type === 'ConversationStarted'
                    "
                  >
                    <div
                      *ngIf="team && type === 'TeamChat'"
                      class="conversation-cta appear_1"
                    >
                      <div
                        class="icon celo-cursor-pointer"
                        (click)="openConversationInfo(); sidenav.open()"
                      >
                        <ng-container *ngIf="isRolesTab; else rolesIcon">
                          <app-celo-avatar
                            [width]="90"
                            [height]="90"
                            [type]="type"
                            [participant]="teamChatParticipant"
                            [showBadge]="false"
                            [badgeSize]="30"
                            [clickable]="true"
                            [conversationPictureUri]="
                              !!conversation.photoId && !leftChat
                                ? conversationProfileUri
                                : null
                            "
                          ></app-celo-avatar>
                        </ng-container>

                        <ng-template #rolesIcon>
                          <app-roles-icon
                            backgroundSize="90px"
                            size="72px"
                          ></app-roles-icon>
                        </ng-template>
                      </div>

                      <div
                        class="name celo-cursor-pointer mb-8"
                        (click)="openConversationInfo(); sidenav.open()"
                      >
                        <div class="name-text underline-on-hover">
                          {{
                            isRolesTab && teamChatParticipant
                              ? teamChatParticipant.firstName +
                                " " +
                                teamChatParticipant.lastName
                              : conversation.name
                          }}
                        </div>
                      </div>

                      <ng-container *ngIf="isRolesTab; else nonRolesTabContent">
                        <div class="subtitle mb-8" *ngIf="team?.workspace">
                          Incoming request for {{ conversation.name }}
                        </div>
                      </ng-container>

                      <ng-template #nonRolesTabContent>
                        <div class="subtitle mb-8" *ngIf="team?.workspace">
                          {{ team.workspace.name }}
                        </div>

                        <div class="subtitle mb-8" *ngIf="team">
                          On Call: {{ currentUserOnCallName || "Unstaffed" }}
                        </div>
                      </ng-template>
                    </div>

                    <div
                      *ngIf="type === 'Chat' || type === 'SelfChat'"
                      class="conversation-cta appear_1"
                    >
                      <div
                        class="icon celo-cursor-pointer"
                        (click)="openConversationInfo(); sidenav.open()"
                      >
                        <app-celo-avatar
                          [width]="90"
                          [height]="90"
                          [type]="type"
                          [conversationPictureUri]="
                            !!conversation.photoId && !leftChat
                              ? conversationProfileUri
                              : null
                          "
                          [participant]="chatParticipant ? chatParticipant : ''"
                          [showBadge]="false"
                          [badgeSize]="30"
                          [clickable]="true"
                        ></app-celo-avatar>
                      </div>
                      <div
                        class="name celo-cursor-pointer"
                        (click)="openConversationInfo(); sidenav.open()"
                      >
                        <div class="name-text underline-on-hover">
                          {{ name
                          }}<span *ngIf="type === 'SelfChat'"> (You)</span>
                        </div>
                        <div *ngIf="chatParticipant" class="badge-icon">
                          &nbsp;<app-badge
                            class="inline-block"
                            [identityVerificationStatus]="
                              chatParticipant.identityVerificationStatus
                            "
                            [workspaces]="chatParticipant.workplaces"
                            [profession]="chatParticipant.professions[0]"
                            [size]="26"
                            [showTooltip]="false"
                            [clickable]="false"
                            [title]="chatParticipant.title"
                            [name]="
                              chatParticipant.firstName +
                              ' ' +
                              chatParticipant.lastName
                            "
                            [name_verified_as]="
                              chatParticipant.legalFirstName +
                              ' ' +
                              chatParticipant.lastName
                            "
                            [userId]="chatParticipant.userId"
                            [hasImage]="!!chatParticipant.profilePic"
                          ></app-badge>
                        </div>
                      </div>
                      <div
                        *ngIf="type === 'Chat' && !isBlocked"
                        class="wave-cta celo-cursor-pointer"
                        role="button"
                        (click)="wave()"
                      >
                        <div class="subtitle underline-on-hover">
                          Start a conversation with a wave
                        </div>
                        <div class="wave">👋</div>
                        <button
                          mat-button
                          class="celo-secondary-button uppercase mb-2"
                        >
                          WAVE
                        </button>
                      </div>
                      <div *ngIf="type === 'SelfChat'" class="self-chat">
                        <button
                          mat-button
                          class="celo-secondary-button uppercase mb-2"
                          (click)="openConversationInfo()"
                        >
                          EDIT PROFILE
                        </button>
                        <div class="subtitle self-chat-subtitle">
                          Welcome to your private space. Take notes, draft
                          messages, keep links and images or just talk to
                          yourself 😉
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="
                        type !== 'Chat' &&
                        type !== 'SelfChat' &&
                        type !== 'TeamChat'
                      "
                      class="conversation-cta appear_1"
                    >
                      <div
                        class="icon celo-cursor-pointer"
                        (click)="openConversationInfo(); sidenav.open()"
                      >
                        <app-celo-avatar
                          [width]="90"
                          [height]="90"
                          [badgeSize]="30"
                          [type]="type"
                          [showBadge]="externalChatParticipant ? false : true"
                          [participant]="externalChatParticipant"
                          [conversationPictureUri]="
                            !!conversation.photoId && !leftChat
                              ? conversationProfileUri
                              : null
                          "
                        ></app-celo-avatar>
                      </div>
                      <div
                        class="name celo-cursor-pointer"
                        (click)="openConversationInfo(); sidenav.open()"
                      >
                        <div class="name-text underline-on-hover">
                          {{ name }}
                        </div>
                        <div *ngIf="externalChatParticipant" class="badge-icon">
                          &nbsp;<app-badge
                            class="inline-block"
                            [size]="26"
                            [isExternal]="true"
                          ></app-badge>
                        </div>
                      </div>
                      <div
                        class="subtitle celo-cursor-pointer underline-on-hover"
                        (click)="openConversationInfo(); sidenav.open()"
                      >
                        {{
                          activeOtherParticipants.length + (leftChat ? 0 : 1)
                        }}
                        participant<span
                          *ngIf="
                            activeOtherParticipants.length +
                              (leftChat ? 0 : 1) !==
                            1
                          "
                          >s</span
                        >
                      </div>
                      <div
                        *ngIf="
                          conversation.invitation &&
                          conversation.invitation.uri &&
                          conversation.type !== 'External'
                        "
                        class="invitation-cta"
                        (click)="
                          copy(conversation.invitation.uri);
                          copyEvent('chat_header')
                        "
                      >
                        <button
                          mat-button
                          class="celo-secondary-button uppercase mb-2"
                        >
                          copy {{ type }} invite link
                        </button>
                        <div
                          class="subtitle underline-on-hover celo-cursor-pointer"
                        >
                          Share the invite link to invite others to this
                          <span class="lowercase">{{ type }}</span
                          >.
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- TODO: this should be virtualized to improve performance. -->
                  <app-messages-list
                    (selectMessage)="openMessageStatus($event)"
                    (quote_clicked)="quoteMessage($event)"
                    (forward)="forwardMessage($event)"
                    (copyMessage)="copyMessage($event)"
                    (delete)="deleteMessage($event)"
                    [messages]="messages"
                    [forceChangeDetection]="messages.length"
                    [conversationId]="id"
                    [patientId]="patientId"
                    [pMap]="pMap"
                    [userAccount]="userAccount"
                    [conversationType]="type"
                    [isOnDND]="isOnDND"
                    [isBlocked]="isBlocked"
                    [isConversationAdmin]="isConversationAdmin"
                    [isActiveParticipant]="isActiveParticipant"
                    [isPreviewMode]="!!messageId"
                  ></app-messages-list>
                </div>
                <div *ngIf="isNextPageOfMessagesLoading$ | async" class="more">
                  <div class="spinner">
                    <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
                  </div>
                </div>
                <div #bottom></div>
                <div
                  *ngIf="!messageId && !isNearBottom"
                  class="bottom-buttons"
                  [style.bottom.px]="30 + bottom_bar.offsetHeight"
                >
                  <button
                    mat-mini-fab
                    (click)="scrollToBottom()"
                    class="grow_0 scroll-to-bottom pointer_events"
                  >
                    <mat-icon>keyboard_arrow_down</mat-icon>
                  </button>
                  <div
                    *ngIf="newMessageIds.length"
                    class="new-messages-container"
                  >
                    <button
                      mat-button
                      (click)="scrollToBottom()"
                      class="new-messages appear_1 pointer_events"
                    >
                      <mat-icon>keyboard_arrow_down</mat-icon>
                      <span
                        >{{ newMessageIds.length }} new message<span
                          *ngIf="newMessageIds.length > 1"
                          >s</span
                        ></span
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div
                *ngIf="
                  !userAccount?.userId ||
                  !conversation ||
                  !pMap ||
                  !messages ||
                  (isInitialPageOfMessagesLoading$ | async)
                "
                class="fh loading"
              >
                <div class="spinner">
                  <app-empty-state
                    [isLoading]="true"
                    [no_message]="true"
                    [diameter]="60"
                  ></app-empty-state>
                </div>
              </div>
              <div
                *ngIf="
                  messageId &&
                  !(isNextPageOfMessagesLoading$ | async) &&
                  !bottomReached
                "
                class="load-more-buttons"
              >
                <button mat-button (click)="loadMoreBottom()">
                  Load more...
                </button>
              </div>
            </div>

            <div #bottom_bar class="bottom_bar">
              <div
                *ngIf="
                  conversation &&
                  !leftChat &&
                  !isTeamInactive &&
                  (isTeamChat ||
                    (!chatParticipant?.blockedByMe?.isBlocked &&
                      !chatParticipant?.blockedMe?.isBlocked)) &&
                  (type !== 'Chat' ||
                    conversation.isReal !== false ||
                    chatParticipant?.connection?.state === 'Accepted' ||
                    hasCommanWorkspace)
                "
                class="appear_1"
              >
                <app-messages-bottom-text-bar
                  [pMap]="pMap"
                  [userAccount]="userAccount"
                  [conversationId]="id"
                  [conversation]="conversation"
                  [quotedMessage]="quotedMessage$ | async"
                  [uploader]="uploader"
                  [conversationType]="type"
                  [participants]="participants"
                  [userAccount]="userAccount"
                  (addPhotos)="addPhotos()"
                  (attachFiles)="attachFiles()"
                  (submitMessage)="submitMessage($event)"
                  (changeMessage)="changeMessage($event)"
                  (cancelQuote)="resetQuote()"
                >
                </app-messages-bottom-text-bar>
              </div>

              <div
                *ngIf="leftChat || isTeamInactive"
                class="left-chat appear_1"
              >
                <ng-container *ngIf="isTeamInactive; else leftChatContent">
                  This role is unavailable to contact on Celo.
                </ng-container>

                <ng-template #leftChatContent>
                  You can't send messages in this
                  <span class="lowercase">{{
                    type === "TeamChat"
                      ? "chat"
                      : type === "External"
                        ? "conversation"
                        : type
                  }}</span>
                  because you're no longer a participant.</ng-template
                >
              </div>

              <ng-container *ngIf="!isTeamChat">
                <ng-container
                  *ngIf="
                    chatParticipant?.blockedByMe?.isBlocked;
                    else notBlockedByCurrentUserContent
                  "
                >
                  <div class="blocked-chat appear_1">
                    You need to unblock this user before contacting them.
                  </div>
                </ng-container>

                <ng-template #notBlockedByCurrentUserContent>
                  <div
                    *ngIf="
                      conversation?.isReal === false &&
                      chatParticipant?.connection?.state !== 'Accepted' &&
                      !hasCommanWorkspace
                    "
                    class="blocked-chat appear_1"
                  >
                    Connect with this user to contact them.
                  </div>

                  <div
                    *ngIf="
                      chatParticipant?.blockedMe?.isBlocked &&
                      !(conversation?.isReal === false && !hasCommanWorkspace)
                    "
                    class="blocked-chat appear_1"
                  >
                    This user is unavailable to contact on Celo.
                    <a class="celo-link" (click)="userUnavailableDialog()"
                      >Learn more.</a
                    >
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<div
  *ngIf="!leftChat && uploader && uploader.queue.length !== 0"
  class="container-grid appear_1"
>
  <app-file-upload-preview
    (closeMe)="handleUploadClose()"
    (uploaded)="handleUploadSubmitted()"
    [type]="'Multi'"
    [uploader]="uploader"
    [isInSecureLibrary]="false"
    [refreshCount]="uploadRefreshCount"
    [patient]="uploadDraft.patient"
    (items)="handleItemsChange($event)"
    [selectedItemIndex]="uploadDraft.selectedItemIndex"
    (selectedItemIndexChange)="handleSelectedItemsIndexChange($event)"
  >
  </app-file-upload-preview>
</div>
