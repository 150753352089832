import { COMMA, ENTER, SPACE } from "@angular/cdk/keycodes";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { AlertService, AnalyticsService, SharedService } from "@modules/core";
import { ProfileService } from "app/modules/profile/profile.service";
import { environment } from "environments/environment";

const MAX = 20;

@Component({
  selector: "app-emailer",
  templateUrl: "./emailer.component.html",
  styleUrls: ["./emailer.component.scss", "../../../../celo-input.scss"],
})
export class EmailerComponent {
  emails = [
    // "a@a.com","b@b.com"
  ];

  valid = true;
  @Input() type = "";
  @Input() link = "";
  @Input() payload: any = {};
  @Input() workspace_name = "";
  @Input() id = "";
  @Input() hideTertiaryButton: boolean;
  @Input() sideMode: boolean;
  @Output() completed = new EventEmitter();
  saving: boolean;
  separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  emailInput: string;

  config = {
    user: {
      subtitle: "Invite your colleagues by entering their email addresses.",
      button: "CONNECT",
      copy_button: "Share profile link",
    },
    group: {
      subtitle: "Invite your colleagues by entering their email addresses.",
      button: "INVITE",
      copy_button: "Copy group invite link",
    },
    case: {
      subtitle: "Invite your colleagues by entering their email addresses.",
      button: "INVITE",
      copy_button: "Copy case invite link",
    },
    workspace: {
      subtitle: "Invite your colleagues by entering their email addresses.",
      button: "SEND INVITE",
      copy_button: "Copy workspace invite link",
    },
  };

  constructor(
    public sharedService: SharedService,
    private profileService: ProfileService,
    public analyticsService: AnalyticsService,
    public alertService: AlertService
  ) {}

  secondaryButtonClick() {
    if (this.type == "user") {
      this.profileService.shareProfile();
      this.analyticsService.buttonClickEvent(
        "open_share_profile_from_add_colleagues"
      );
      return;
    }
    this.copyLink();
  }

  copyLink() {
    this.analyticsService.raiseLinkEvents(
      this.type.toLowerCase(),
      "copy_from_email_page"
    );
    switch (this.type) {
      case "group":
        this.sharedService.copyConversationLink(
          this.payload,
          "group",
          this.link
        );
        break;
      case "case":
        this.sharedService.copyConversationLink(
          this.payload,
          "case",
          this.link
        );
        break;
      case "workspace":
        this.sharedService.copyWorkspaceLink(this.workspace_name, this.link);
        break;
    }
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  add(event: MatChipInputEvent): void {
    this.setValidity();
    if (this.emails.length >= MAX) {
      this.emailInput = "";
      return;
    }
    const value = (event.value || "").trim();
    if (!this.isValidEmail(value)) {
      return;
    }
    if (value) {
      this.emails.push(value);
    }
    this.emailInput = "";
    this.setValidity();
  }

  sendInvite() {
    const contacts = [];
    const emails = this.emails;
    const uniqueEmails = emails.filter(function (item, pos) {
      return emails.indexOf(item) == pos;
    });
    for (const email of uniqueEmails) {
      if (this.isValidEmail(email)) {
        contacts.push({
          userEmails: [email],
        });
      }
    }
    let path = environment.celoApiEndpoint + "/api/v2/invitations";

    switch (this.type) {
      case "user":
        path = environment.celoApiEndpoint + "/api/v2/user/invite";
        break;
      case "group":
        path =
          environment.celoApiEndpoint +
          "/api/v2/conversations/" +
          this.id +
          "/invite";
        break;
      case "case":
        path =
          environment.celoApiEndpoint +
          "/api/v2/conversations/" +
          this.id +
          "/invite";
        break;
      case "workspace":
        path =
          environment.celoApiEndpoint +
          "/api/v2/workspaces/" +
          this.id +
          "/invite";
        break;
    }
    const body = {
      contacts,
    };
    this.saving = true;
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    this.analyticsService.buttonClickEvent("invite_via_email", {
      link_type: this.type,
      number_of_added_colleagues: uniqueEmails.length,
    });
    this.sharedService.postObjectById(path, "", body).subscribe(
      (res) => {
        this.saving = false;
        if (res && res.statusCode != "Accepted") {
          return;
        }
        this.alertService.showSnackBar("Invitation sent successfully", 3);
        this.resetEmailField();
        this.completed.emit();
      },
      (err) => {
        this.saving = false;
        this.alertService.showSnackBar(
          "Something went wrong, please try again.",
          3
        );
      }
    );
  }

  resetEmailField() {
    this.emailInput = "";
    this.emails = [];
  }

  onPaste() {
    setTimeout(() => {
      this.onPostPaste(this.emailInput);
    }, 200);
  }

  onPostPaste(email) {
    const list = this.extractEmails(email);
    if (!list) {
      return;
    }
    this.emailInput = "";
    this.emails = this.emails.concat(list);
    if (this.emails.length > MAX) {
      this.emails = this.emails.splice(0, 20);
    }
    this.setValidity();
  }

  setValidity() {
    this.valid = true;
    if (this.emailInput) {
      this.valid = false;
    }
    for (const email of this.emails) {
      if (!email && this.emails.length > 1) {
        continue;
      }
      if (!this.isValidEmail(email)) {
        this.valid = false;
        return;
      }
    }
  }

  // isValidEmail(value){
  //   return this.emailRegex.test(value);
  // }
  isValidEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  extractEmails(content): string[] {
    let list: string[] = [];
    list = content.match(
      /([a-zA-Z0-9._'+'-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
    );
    return list;
  }
}
class EmailField {
  constructor(email) {
    return { email };
  }
}
