/** Returns a new Set containing the intersection of `a` and `b`. */
export const intersection = <T>(a: Set<T>, b: Set<T>): Set<T> => {
  const intersectingElements = new Set<T>();

  for (const element of a) {
    if (b.has(element)) {
      intersectingElements.add(element);
    }
  }

  return intersectingElements;
};

/** Returns a new Set containing the union of `a` and `b`. */
export const union = <T>(a: Set<T>, b: Set<T>): Set<T> => {
  const elements = new Set<T>(a);

  for (const element of b) {
    elements.add(element);
  }

  return elements;
};

/**
 * Returns a new Set containing the difference between `a` and `b`
 *
 * The new Set will contains values that are in `a` but not in `b`
 * */
export const difference = <T>(a: Set<T>, b: Set<T>): Set<T> => {
  const differentElements = new Set<T>();

  for (const element of a) {
    if (b.has(element)) continue;
    differentElements.add(element);
  }

  return differentElements;
};
