<div
  *ngIf="isLoading || isEmpty"
  class="outer"
  [ngClass]="{ light: theme === 'light', dark: theme === 'dark' }"
>
  <div
    *ngIf="isLoading"
    class="loading-box"
    [style.min-height.px]="containerHeight"
  >
    <div class="spinner">
      <div class="spinner-container">
        <div
          class="spinner"
          [style.height.px]="diameter"
          [style.width.px]="diameter"
        >
          <mat-progress-spinner
            *ngIf="spinner"
            [strokeWidth]="stroke"
            [diameter]="diameter"
            [color]="'primary'"
            [mode]="'indeterminate'"
          >
          </mat-progress-spinner>
          <lottie-player
            *ngIf="animation"
            slot="end"
            autoplay
            loop
            src="../../../../assets/logo-anim.json"
          >
          </lottie-player>
        </div>
      </div>
      <div *ngIf="!no_message">
        <div class="message">{{ message ? message : "LOADING" }}</div>
      </div>
    </div>
  </div>
  <div *ngIf="!isLoading && isEmpty" class="empty-box appear_1">
    <img
      *ngIf="!noImage"
      [src]="image ? '/assets/' + image : '/assets/empty' + random + '.svg'"
      
    />
    <div class="celo-text-18 celo-bold title_text">{{ title }}</div>
    <div class="celo-text-14 celo-grey text">{{ message }}</div>
  </div>
</div>

<img src="../../assets/no-internet.png" class="hidden" />

<div *ngIf="type === 'no_internet'">
  <div class="empty-box no-internet appear_1">
    <img src="../../assets/no-internet.png"  />
    <div class="celo-text-18 celo-bold">{{ title }}</div>
    <div class="subtitle">{{ message }}</div>
  </div>
</div>
