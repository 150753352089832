import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchHighlight",
})
export class SearchHighlightPipe implements PipeTransform {
  transform(value: string, args: string): any {
    if (!args) return value;
    const re = new RegExp(args, "gi"); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
    return value.replace(re, (match) => {
      return "<span class='marked'>" + match + "</span>";
    });
  }
}
