import { isDevMode } from "@angular/core";
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { userReducer, UserState } from "./user/user.reducer";

export * from "./app.effects";
export * from "./conversations";
export * from "./signalr.actions";
export * from "./user";

export type RootState = {
  user: UserState;
};

export const reducers: ActionReducerMap<RootState> = {
  user: userReducer,
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log("[debug] [reducer]", action.type, { state, action });
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<RootState>[] = isDevMode()
  ? [debug]
  : [];
