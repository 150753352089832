<app-expansion-panel
  *ngIf="group"
  [(expanded)]="expanded"
  [stickyHeader]="true"
  [stickyHeaderTop]="stickyHeaderTop"
>
  <div header class="mat-body-strong break-all">
    {{ group.expandableControlName || group.name }}
  </div>

  <ng-template appExpansionPanelContent>
    <app-user-picker-selection-list
      [group]="group"
      [multiple]="multiple"
      [hideLabels]="hideLabels"
    ></app-user-picker-selection-list>
  </ng-template>
</app-expansion-panel>
