import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { AlertComponent } from "@modules/core/components";
import { Observable } from "rxjs";
import { PatientDetails } from "./../patient-details-form/patient-details-form.component";

export type EditPatientDetailsUpdateFn = (
  patientDetails: PatientDetails
) => Observable<void>;

export interface EditPatientDetailsData {
  patientDetails: PatientDetails;
  updateFn: EditPatientDetailsUpdateFn;
}

export interface EditPatientDetailsResult {
  patientDetails: PatientDetails;
}

@Component({
  selector: "app-edit-patient-details-dialog",
  templateUrl: "./edit-patient-details-dialog.component.html",
  styleUrls: ["./edit-patient-details-dialog.component.scss"],
})
export class EditPatientDetailsDialogComponent implements OnInit {
  public isSubmitting: boolean = false;
  public isPatientDetailsValid: boolean = false;

  public constructor(
    private matDialogRef: MatDialogRef<
      EditPatientDetailsDialogComponent,
      EditPatientDetailsResult
    >,
    @Inject(MAT_DIALOG_DATA) public data: EditPatientDetailsData,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {}

  public onSubmit(patientDetails: PatientDetails) {
    this.isSubmitting = true;

    this.data.updateFn(patientDetails).subscribe({
      next: () => {
        this.matDialogRef.close({ patientDetails });
      },
      error: () => {
        AlertComponent.openErrorDialog(this.matDialog)
          .afterClosed()
          .subscribe({
            next: () => {
              this.isSubmitting = false;
            },
          });
      },
    });
  }
}
