import { Component, Input, OnInit } from "@angular/core";
import { FullUserProfileModel } from "types";
import { NotificationsService } from "../notifications.service";

@Component({
  selector: "app-notifications-dropdown",
  templateUrl: "./notifications-dropdown.component.html",
  styleUrls: ["./notifications-dropdown.component.scss"],
})
export class NotificationsDropdownComponent implements OnInit {
  @Input() userAccount: FullUserProfileModel;
  @Input() pendingConnections: any = [];
  constructor() {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {}
}
