<ng-container *ngIf="{ state: state$ | async } as variables">
  <app-user-picker-layout
    [isLoading]="variables.state === UserPickerState.SUBMIT_LOADING"
    [hideRightContent]="hideRightContent"
  >
    <!-- Left Header -->
    <ng-container left-header>
      <div id="header">
        <h2 class="mat-h2">{{ header$ | async }}</h2>
        <ng-content select="[left-header]"></ng-content>
      </div>
      <app-user-picker-searchbar
        [hidden]="hideSearch || isInitializing"
        [placeholder]="searchPlaceholder"
      ></app-user-picker-searchbar>
    </ng-container>

    <!-- Left content -->
    <ng-container left-content>
      <app-basic-spinner
        *ngIf="
          variables.state === UserPickerState.SEARCH_LOADING && !hideSearch
        "
        class="spinner"
        [diameter]="16"
      ></app-basic-spinner>

      <ng-container *ngIf="variables.state !== UserPickerState.SEARCH_LOADING">
        <ng-content select="[left-content]"></ng-content>
      </ng-container>

      <app-user-picker-grouped-selection-lists
        [multiple]="multiple"
        [stickyGroupHeaders]="true"
        [hidden]="
          variables.state !== UserPickerState.SEARCH_RESULTS || hideSearch
        "
        [groups]="searchResults$ | async"
        [hideLabels]="!multiple"
        [isSearchResults]="true"
      ></app-user-picker-grouped-selection-lists>

      <app-user-picker-no-search-results
        id="no-results"
        *ngIf="variables.state === UserPickerState.NO_RESULTS && !hideSearch"
      ></app-user-picker-no-search-results>
    </ng-container>

    <!-- Right header -->
    <ng-container
      right-header
      *ngIf="!showAlternateRightHeader && !isInitializing"
    >
      <h2 class="mat-h2">{{ selectedHeader }}</h2>
      <app-user-picker-selection-count
        [countDisabled]="showDisabledSelectedUsers"
        [zero]="selectedQuantityLabels.zero"
        [one]="selectedQuantityLabels.one"
        [plural]="selectedQuantityLabels.plural"
      ></app-user-picker-selection-count>
    </ng-container>

    <!-- Alternate right header -->
    <ng-container
      right-header
      *ngIf="showAlternateRightHeader && !isInitializing"
    >
      <ng-content select="[right-header]"></ng-content>
    </ng-container>

    <!-- Right content -->
    <ng-container
      right-content
      *ngIf="!showAlternateRightContent && !isInitializing"
    >
      <app-user-picker-selected-users
        [selectedUsers]="selectedUsers$ | async"
      ></app-user-picker-selected-users>
    </ng-container>

    <!-- Alternate right content -->
    <ng-container
      right-content
      *ngIf="showAlternateRightContent && !isInitializing"
    >
      <ng-content select="[right-content]"></ng-content>
    </ng-container>

    <!-- Right footer -->
    <ng-container
      right-footer
      *ngIf="!showAlternateRightFooter && !isInitializing"
    >
      <app-user-picker-submit-button
        [text]="submitButtonText"
        [selectedUsers]="selectedUsers$ | async"
      ></app-user-picker-submit-button>

      <button
        mat-button
        class="celo-secondary-button uppercase"
        mat-dialog-close
      >
        {{ cancelButtonText }}
      </button>
    </ng-container>

    <!-- Alternate right footer -->
    <ng-container
      right-footer
      *ngIf="showAlternateRightFooter && !isInitializing"
    >
      <ng-content select="[right-footer]"></ng-content>
    </ng-container>
  </app-user-picker-layout>
</ng-container>
