<div
  class="avatar-container"
  [class.speakingBorder]="isLastActiveSpeaker"
  [class.no-video]="!isVideoOn"
>
  <div class="picture-container" *ngIf="!isVideoOn">
    <div class="avatar-wrapper">
      <app-basic-celo-avatar
        [userId]="this.userId"
        [size]="100"
        [badgeSize]="27"
        [name]="name"
        [isWorkspaceVerified]="isWorkspaceVerified"
        [isIdentityVerified]="isIdentityVerified"
        [isProfessionVerified]="isProfessionVerified"
      />
    </div>
  </div>

  <div *ngIf="!disableDetails" class="details-container">
    <div *ngIf="!disableName" class="name">
      {{ name }}{{ isYou ? " (You)" : "" }}
    </div>
    <mat-icon *ngIf="isMuted" class="mute-status">mic_off</mat-icon>
  </div>
</div>
