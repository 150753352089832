import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class PatientService {
  count = -1;

  constructor(private http: HttpClient) {}

  getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  patientIdLookup(patientId: string, callback: any) {
    const url = `${environment.celoApiEndpoint}/api/Patients`;
    const params = new HttpParams().set("PatientId", patientId);
    this.http.get(url, { params }).subscribe(
      (data) => {
        callback(data);
      },
      (error) => {
        callback(false);
      }
    );
  }
}
