import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  AccountService,
  CompaniesService,
  ContactsService,
  TeamsService,
  UserService,
  UsersService,
  WorkspacesService,
} from "@modules/core";
import { UserSelectionList } from "@modules/shared/basic-user-selection-list/basic-user-selection-list.component";
import { createDialogConfig } from "@utils";
import { Observable } from "rxjs";
import {
  NetworkUserPickerComponent,
  WorkspaceUserPickerComponent,
  WorkspaceUserPickerProvider,
} from ".";
import {
  BasicUserPickerData,
  BasicUserPickerResult,
} from "./basic-user-picker.service";
import { NetworkUserPickerProvider } from "./network-user-picker-provider";
import { NetworkUserPickerData } from "./network-user-picker.service";

export type UserPickerSubmitCallback = (
  users: UserSelectionList
) => Observable<void>;

export interface WorkspaceUserPickerData
  extends Omit<BasicUserPickerData, "provider"> {
  workspaceId: string;
  disabledUserIds?: string[];
  initiallySelectedUserIds?: string[];
  submitCallback: UserPickerSubmitCallback;
}

@Injectable({
  providedIn: "root",
})
export class UserPickerService {
  public constructor(
    private teamsService: TeamsService,
    private contactsService: ContactsService,
    private usersService: UsersService,
    private accountService: AccountService,
    private companiesService: CompaniesService,
    private userService: UserService,
    private workspacesService: WorkspacesService,
    private matDialog: MatDialog
  ) {}

  public openNetworkUserPicker({
    disabledUserIds,
    initiallySelectedUserIds,
    submitCallback,
    ...data
  }: Omit<NetworkUserPickerData, "variant" | "provider">): MatDialogRef<
    NetworkUserPickerComponent,
    BasicUserPickerResult
  > {
    const config = createDialogConfig<NetworkUserPickerData>(
      {
        variant: "network-user-picker",
        ...data,
        provider: new NetworkUserPickerProvider({
          teamsService: this.teamsService,
          contactsService: this.contactsService,
          usersService: this.usersService,
          accountService: this.accountService,
          companiesService: this.companiesService,
          userService: this.userService,
          excludeTeams: data.excludeTeams,
          excludeConnections: data.excludeConnections,
          excludeSuggestions: data.excludeSuggestions,
          asTeamId: data.asTeamId,
          workspaceId: data.workspaceId,
          excludeSelf: data.excludeSelf,
          mode: data.mode,
          initiallySelectedUserIds,
          disabledUserIds,
          submitCallback,
        }),
      },
      {
        panelClasses: ["user-picker"],
      }
    );
    return this.matDialog.open<
      NetworkUserPickerComponent,
      BasicUserPickerData,
      BasicUserPickerResult
    >(NetworkUserPickerComponent, config);
  }

  public openWorkspaceUserPicker({
    workspaceId,
    disabledUserIds,
    initiallySelectedUserIds,
    submitCallback,
    ...data
  }: WorkspaceUserPickerData): MatDialogRef<
    WorkspaceUserPickerComponent,
    BasicUserPickerResult
  > {
    const config = createDialogConfig<BasicUserPickerData>(
      {
        ...data,
        provider: new WorkspaceUserPickerProvider({
          companiesService: this.companiesService,
          workspaceService: this.workspacesService,
          userService: this.userService,
          submitCallback,
          initiallySelectedUserIds,
          disabledUserIds,
          workspaceId,
        }),
      },
      {
        panelClasses: ["user-picker"],
      }
    );
    return this.matDialog.open<
      WorkspaceUserPickerComponent,
      BasicUserPickerData,
      BasicUserPickerResult
    >(WorkspaceUserPickerComponent, config);
  }
}
