import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UserProfileModel } from "types";
import { AnalyticsService, SharedService } from "../../../modules/core";

@Component({
  selector: "app-connections-list",
  templateUrl: "./connections-list.component.html",
  styleUrls: ["./connections-list.component.scss"],
})
export class ConnectionsListComponent implements OnInit {
  connections: UserProfileModel[];
  sub: any;
  loading: boolean;
  @Output() connectionsLoaded = new EventEmitter<number>();

  constructor(
    private sharedService: SharedService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.getConnections();
  }

  getConnections(query?) {
    this.loading = true;
    const body = {
      page: 0,
      pageSize: 1000,
      Search: query ? query : "",
    };
    this.sub = this.sharedService.getContactsV2(body).subscribe({
      next: (res) => {
        this.loading = false;
        this.connections = res.data || [];
        this.connectionsLoaded.emit(this.connections.length);
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  raiseCardClickEvent() {
    this.analyticsService.buttonClickEvent("user_card", {
      flow: "network",
      card_type: "connection",
    });
  }
}
