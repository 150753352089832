<!-- <div class="box celo-cursor-pointer" (click)="fileSingle.click()"> -->
<div class="box celo-cursor-pointer" (click)="fileSingle.click()">
  <div>
    Drag {{ type }} here to upload or
    <div class="inline-block">
      <button mat-button class="celo-primary-button celo-button-sm">
        BROWSE
      </button>
    </div>
    <input
      [multiple]="true"
      [accept]="
        type !== 'documents' ? 'image/png, image/jpeg' : 'application/pdf'
      "
      #fileSingle
      class="d-none"
      type="file"
      ng2FileSelect
      [uploader]="uploader"
    />
  </div>
  <div class="subtitle">
    (<span *ngFor="let format of formats; let index = index" class="uppercase"
      >{{ format }}
      <span class="lowercase">{{
        index < formats.length - 1 ? " or " : ""
      }}</span>
    </span>
    <span>Format) </span>
  </div>
</div>
