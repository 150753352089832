<div class="title">
  <div>
    <span>Patient Lookup Results </span>
  </div>
  <button class="celo-light-button" mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>

<hr />
<div class="content">
  <div *ngIf="!data" class="fh">
    <app-empty-state
      [isLoading]="true"
      [diameter]="30"
      [message]="'Looking up patient data'"
    ></app-empty-state>
  </div>
  <div *ngIf="data">
    <div *ngIf="data.length > 0">
      <div class="patient-info">
        <div *ngFor="let patient of data; let i = index" class="p-2">
          <div
            matRipple
            class="celo-cursor-pointer patientdata p-2 celo-hoverable"
            (click)="
              selectedIndex === i
                ? (selectedIndex = undefined)
                : (selectedIndex = i)
            "
          >
            <div>
              <app-edit-patient
                [patient]="patient"
                [canSave]="false"
                [editMode]="false"
                [alignment]="'horizontal'"
                [selectMode]="true"
              ></app-edit-patient>
            </div>
            <div class="checkbox">
              <mat-checkbox
                class="no-pointer-events"
                [checked]="selectedIndex === i"
              ></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <mat-dialog-actions align="end">
        <button class="celo-secondary-button" mat-button (click)="onNoClick()">
          Cancel
        </button>
        <button
          [disabled]="!(selectedIndex >= 0)"
          class="celo-primary-button"
          mat-button
          (click)="onConfirm()"
        >
          Confirm
        </button>
      </mat-dialog-actions>
      <div cdkFocusInitial></div>
    </div>
    <div *ngIf="data.length === 0">
      <app-empty-state [isEmpty]="true" [message]="error"></app-empty-state>
    </div>
  </div>
</div>
