<div *ngIf="!loading && contact" class="content">
  <app-badge-info
    [ownProfile]="true"
    [userId]="userId"
    [name]="contact.firstName + ' ' + contact.lastName"
    [name_verified_as]="contact.legalFirstName + ' ' + contact.lastName"
    [title]="contact.title"
    [workspaces]="contact.workplaces"
    [identityVerificationStatus]="contact.identityVerificationStatus"
    [hideUnverifiedStatus]="true"
    [profession]="contact.professions[0]"
    [hasImage]="contact.profilePic"
  ></app-badge-info>
  <div class="profile-buttons appear_anim_2">
    <div
      *ngIf="
        (!contact['blockedByMe'] || !contact['blockedByMe'].isBlocked) &&
        (!contact['blockedMe'] || !contact['blockedMe'].isBlocked) &&
        userId !== loggedInuserId &&
        (!contact['connection'] ||
          !contact['connection'].state ||
          contact['connection'].state === 'Disconnected' ||
          contact['connection'].state === 'Rejected' ||
          (contact['connection'] && contact['connection'].state === 'Pending'))
      "
      class="button"
    >
      <button
        *ngIf="
          !contact['connection'] ||
          !contact['connection'].state ||
          contact['connection'].state === 'Disconnected' ||
          contact['connection'].state === 'Rejected'
        "
        mat-button
        class="celo-primary-button"
        [disabled]="actioning"
        (click)="actionNow()"
      >
        <div class="button-content pending-connect-button">
          <span>Connect</span>
        </div>
      </button>
      <div
        *ngIf="
          contact['connection'] && contact['connection'].state === 'Pending'
        "
        
      >
        <button
          *ngIf="contact['connection']['createdBy'] === contact.userId"
          mat-button
          [disabled]="actioning"
          (click)="actionNow()"
          class="celo-primary-button"
        >
          <div class="button-content">
            <span>Accept connection</span>
          </div>
        </button>
        <button
          *ngIf="contact['connection']['createdBy'] !== contact.userId"
          mat-button
          disabled
          class="celo-primary-button"
        >
          <div class="button-content pending-connect-button">
            <span>Pending</span>
          </div>
        </button>
      </div>
    </div>
    <div class="button">
      <a (click)="viewProfile(userId)">
        <button mat-button class="celo-secondary-button">
          <div class="button-content">
            <span>View Profile</span>
          </div>
        </button>
      </a>
    </div>
  </div>
</div>

<div *ngIf="loading || !contact">
  <app-empty-state [isLoading]="true"></app-empty-state>
</div>
