import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ConversationType, UserActivityModel } from "types";
import {
  AnalyticsService,
  ConversationsService,
  UserService,
} from "../../core";

@Component({
  selector: "app-activity-feed-list-item",
  templateUrl: "./activity-feed-list-item.component.html",
  styleUrls: ["./activity-feed-list-item.component.scss"],
})
export class ActivityFeedListItemComponent implements OnChanges {
  @Input() activity: UserActivityModel;

  public routerLink: string[] = [];

  constructor(
    private analyticsService: AnalyticsService,
    private conversationsService: ConversationsService,
    private userService: UserService
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.activity) return;
    const activity = changes.activity.currentValue as UserActivityModel;

    switch (activity.type) {
      case "WorkspaceJoined":
        this.routerLink = ["/network/workspace", this.activity.metadata.id];
        break;
      case "TeamOnOffCall":
        this.routerLink = ["/roles", this.activity.metadata.id];
        break;
      case "Mention":
        const currentUserId = this.userService.getUserId();

        // Navigate to roles tab if this mention is for a role the current user is a member of
        if (
          currentUserId &&
          activity.metadata.conversationType === ConversationType.TeamChat &&
          this.conversationsService.isUserMemberOfTeamForTeamChat(
            currentUserId,
            activity.metadata.conversationId
          )
        ) {
          const teamId = this.conversationsService.tryParsePotentialTeamId(
            this.activity.metadata.conversationId
          );
          this.routerLink = [
            "/roles",
            teamId,
            "conversations",
            this.activity.metadata.conversationId,
            "messages",
          ];

          break;
        }

        if (
          this.activity.metadata.conversationType === ConversationType.External
        ) {
          this.routerLink = [
            "/external",
            this.activity.metadata.conversationId,
            "messages",
          ];
          break;
        }

        this.routerLink = [
          "/conversations",
          this.activity.metadata.conversationId,
          "messages",
        ];
        break;
      default:
        this.routerLink = [];
        break;
    }
  }

  itemClicked = () => {
    this.analyticsService.buttonClickEvent("activity_feed_item", {
      type: this.activity.type,
      read: this.activity.metadata.read,
    });
  };
}
