import { Component, Input } from "@angular/core";

@Component({
  selector: "app-celo-side-menu-sub-list",
  templateUrl: "./celo-side-menu-sub-list.component.html",
  styleUrls: ["./celo-side-menu-sub-list.component.scss"],
})
export class CeloSideMenuSubListComponent {
  @Input() celoTemplate;
}
