import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { AlertService, SharedService } from "@modules/core";

@Component({
  selector: "app-celo-topbar-search-input",
  templateUrl: "./celo-topbar-search-input.component.html",
  styleUrls: ["./celo-topbar-search-input.component.scss"],
})
export class CeloTopbarSearchInputComponent {
  @Input() searchTerm = "";
  @Input() autoSearch = true;
  @Input() characterLimit = true;
  @Output() doSearch = new EventEmitter<string>();
  @Input() placeholder = "Search by Name or Department";

  modelChanged: Subject<string> = new Subject<string>();
  searchPending: boolean;

  // constructor() { }
  constructor(
    private sharedService: SharedService,
    private alertService: AlertService
  ) {
    this.modelChanged
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((model) => {
        if (!this.searchPending) {
          return;
        }
        if (!this.searchTerm || this.searchTerm.length < 3) {
          return;
        }
        if (model) {
          this.searchTerm = model;
          this.onSearch(this.searchTerm);
        }
      });
  }

  changed(text: string) {
    if (!this.autoSearch && text) {
      return;
    }
    this.searchPending = true;
    if (text) {
      this.modelChanged.next(text);
    } else {
      this.modelChanged.next("");
      setTimeout(() => {
        this.searchTerm = "";
        this.onSearch("");
      }, 100);
    }
  }

  onSearch(searchTerm) {
    if (!searchTerm) {
      this.searchPending = false;
      this.doSearch.emit(searchTerm);
      return;
    }
    if (searchTerm.length < 3 && this.characterLimit) {
      this.alertService.showSnackBar(
        "Please use at least 3 characters to begin your search.",
        3
      );
      return;
    }
    if (!this.sharedService.isOnline()) {
      this.sharedService.noInternetSnackbar();
      return;
    }
    this.searchPending = false;
    this.doSearch.emit(searchTerm);
  }
}
