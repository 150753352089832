export interface ErrorDetail {
  code: string;
  message: string;
}

export interface ErrorResponse {
  errors?: ErrorDetail[] | null;
}

/**
 * Constants for {@link DOMException} names.
 *
 * This does not define every exception type - only the ones that are needed at the moment.
 */
export enum DOMExceptionName {
  InvalidStateError = "InvalidStateError",
  SecurityError = "SecurityError"
}