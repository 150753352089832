<div class="page">
  <div class="menu">
    <div *ngIf="userAccount" class="name-box">
      <app-avatar-with-name
        [title]="userAccount.title"
        [firstName]="userAccount.firstName"
        [lastName]="userAccount.lastName"
        description="Admin"
        [userId]="userAccount.userId"
        [profilePic]="userAccount.picture"
        [onCall]="userAccount.isOnCall === 'true'"
      >
      </app-avatar-with-name>
    </div>
    <app-celo-side-menu-sub-list [celoTemplate]="3">
    </app-celo-side-menu-sub-list>
  </div>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
