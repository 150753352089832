import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { Conversation, ConversationType, Message } from "@types";
import {
  addOrReplaceElement,
  assertIsExhaustive,
  ConversationUtils,
  MessageUtils,
} from "@utils";
import {
  ConversationsApiActions,
  ConversationsPageActions,
} from "app/state/conversations/conversations.actions";
import { produce } from "immer";
import { SignalRActions } from "../signalr.actions";
import { ConversationsPreprocessingActions } from "./conversations.actions";
import { ConversationsState } from "./types";
import { updateStateFromSentMessages } from "./utils";

export const conversationsInitialState: ConversationsState = {
  skeletonConversations: [],

  isInboxLoading: true,
  isInboxLoadingMore: false,
  conversations: [],
  inboxPaginationData: {
    nextPageNumber: 0,
    totalPages: 1,
    hasNext: true,
  },

  isInboxSearchLoading: false,
  isInboxSearchLoadingMore: false,
  isExternalInboxSearchLoadingMore: false,
  inboxSearchResults: [],
  inboxSearchQuery: "",
  inboxSearchResultsPaginationData: {
    nextPageNumber: 0,
    totalPages: 1,
    hasNext: true,
  },

  isExternalInboxLoading: true,
  isExternalSearchInboxLoading: false,
  isExternalInboxLoadingMore: false,
  externalInboxPaginationData: {
    nextPageNumber: 0,
    totalPages: 1,
    hasNext: true,
  },
  externalInboxSearchQuery: "",
  externalInboxSearchResults: [],
  externalSearchResultsPaginationData: {
    nextPageNumber: 0,
    totalPages: 1,
    hasNext: true,
  },

  selectedTeamId: null,
  isRolesInboxLoading: true,
  isRolesInboxLoadingMore: false,
  rolesInboxPaginationData: {
    nextPageNumber: 0,
    totalPages: 1,
    hasNext: true,
  },
  isRolesInboxSearchLoading: false,
  isRolesInboxSearchLoadingMore: false,
  rolesInboxSearchQuery: "",
  rolesInboxSearchResults: [],
  rolesInboxSearchResultsPaginationData: {
    nextPageNumber: 0,
    totalPages: 1,
    hasNext: true,
  },

  isSelectedConversationLoading: false,
  selectedConversationId: null,
  isInitialPageOfMessagesLoading: true,
  isNextPageOfMessagesLoading: false,
  isPreviousPageOfMessagesLoading: false,
  selectedConversationMessages: null,

  isSaving: false,

  selectedReplyMessage: null,
};

export const conversationsFeature = createFeature({
  name: "conversations",
  reducer: createReducer(
    conversationsInitialState,
    on(ConversationsPageActions.inboxOpened, (state, action) =>
      produce(state, (draft) => {
        draft.isInboxLoading = true;
      })
    ),
    on(ConversationsPageActions.externalInboxOpened, (state, action) =>
      produce(state, (draft) => {
        draft.isExternalInboxLoading = true;
      })
    ),
    on(ConversationsPageActions.rolesInboxOpened, (state, action) =>
      produce(state, (draft) => {
        draft.isRolesInboxLoading = true;
        draft.selectedTeamId = action.teamId;
      })
    ),
    on(ConversationsPageActions.inboxLoadMore, (state, action) =>
      produce(state, (draft) => {
        draft.isInboxLoadingMore = true;
      })
    ),
    on(ConversationsPageActions.externalInboxLoadMore, (state, action) =>
      produce(state, (draft) => {
        draft.isExternalInboxLoadingMore = true;
      })
    ),
    on(ConversationsPageActions.rolesInboxLoadMore, (state, action) =>
      produce(state, (draft) => {
        draft.isRolesInboxLoadingMore = true;
      })
    ),
    on(ConversationsPageActions.inboxSearch, (state, action) =>
      produce(state, (draft) => {
        draft.isInboxSearchLoading = true;
        draft.inboxSearchQuery = action.query;
        draft.inboxSearchResults = [];
      })
    ),
    on(ConversationsPageActions.externalInboxSearch, (state, action) =>
      produce(state, (draft) => {
        draft.isExternalSearchInboxLoading = true;
        draft.externalInboxSearchQuery = action.query;
        draft.externalInboxSearchResults = [];
      })
    ),
    on(ConversationsPageActions.rolesInboxSearch, (state, action) =>
      produce(state, (draft) => {
        draft.isRolesInboxSearchLoading = true;
        draft.rolesInboxSearchQuery = action.query;
        draft.rolesInboxSearchResults = [];
      })
    ),
    on(ConversationsPageActions.inboxSearchLoadMore, (state, action) =>
      produce(state, (draft) => {
        draft.isInboxSearchLoadingMore = true;
      })
    ),
    on(ConversationsPageActions.externalInboxSearchLoadMore, (state, action) =>
      produce(state, (draft) => {
        draft.isExternalInboxSearchLoadingMore = true;
      })
    ),
    on(ConversationsPageActions.rolesInboxSearchLoadMore, (state, action) =>
      produce(state, (draft) => {
        draft.isRolesInboxSearchLoadingMore = true;
      })
    ),
    on(ConversationsPageActions.inboxSearchReset, (state, action) =>
      produce(state, (draft) => {
        draft.inboxSearchQuery = "";
      })
    ),
    on(ConversationsPageActions.externalInboxSearchReset, (state, action) =>
      produce(state, (draft) => {
        draft.externalInboxSearchQuery = "";
      })
    ),
    on(ConversationsPageActions.rolesInboxSearchReset, (state, action) =>
      produce(state, (draft) => {
        draft.rolesInboxSearchQuery = "";
      })
    ),
    on(ConversationsPageActions.conversationOpened, (state, action) =>
      produce(state, (draft) => {
        draft.isSelectedConversationLoading = true;
        draft.isInitialPageOfMessagesLoading = true;
        draft.selectedConversationId = action.conversationId;
        draft.selectedConversationMessages = [];
        draft.selectedReplyMessage = null;
      })
    ),
    on(ConversationsApiActions.loadConversationsSuccess, (state, action) =>
      produce(state, (draft) => {
        const { response, isSearch } = action.data;

        let updatedConversations = draft.conversations;
        response.data.forEach((conversation) => {
          updatedConversations = addOrReplaceElement(
            updatedConversations,
            ConversationUtils.fromConversationModelV3(
              conversation,
              action.user
            ),
            (c) => c.id === conversation.id
          );
        });

        draft.conversations = ConversationUtils.sort(updatedConversations);

        if (isSearch) {
          let updatedSearchResults = draft.inboxSearchResults;
          response.data.forEach((conversation) => {
            updatedSearchResults = addOrReplaceElement(
              updatedSearchResults,
              ConversationUtils.fromConversationModelV3(
                conversation,
                action.user
              ),
              (c) => c.id === conversation.id
            );
          });

          draft.inboxSearchResults =
            ConversationUtils.sort(updatedSearchResults);

          draft.isInboxSearchLoading = false;
          draft.inboxSearchResultsPaginationData = {
            nextPageNumber: response.nextPage,
            totalPages: response.totalPages,
            hasNext: response.hasNext,
          };

          // Note: it's important we read the current state here and not the draft that we've modified
          if (state.inboxSearchResultsPaginationData.nextPageNumber > 0) {
            draft.isInboxSearchLoadingMore = false;
          }
        } else {
          draft.isInboxLoading = false;
          draft.inboxPaginationData = {
            nextPageNumber: response.nextPage,
            totalPages: response.totalPages,
            hasNext: response.hasNext,
          };

          // Note: it's important we read the current state here and not the draft that we've modified
          if (state.inboxPaginationData.nextPageNumber > 0) {
            draft.isInboxLoadingMore = false;
          }
        }
      })
    ),
    on(
      ConversationsApiActions.loadExternalConversationsSuccess,
      (state, action) =>
        produce(state, (draft) => {
          const { response, isSearch } = action.data;

          let updatedConversations = draft.conversations;
          response.data.forEach((conversation) => {
            updatedConversations = addOrReplaceElement(
              updatedConversations,
              ConversationUtils.fromConversationModelV3(
                conversation,
                action.user
              ),
              (c) => c.id === conversation.id
            );
          });

          draft.conversations = ConversationUtils.sort(updatedConversations);
          draft.isExternalInboxLoading = false;
          draft.isExternalInboxLoadingMore = false;
          draft.externalInboxPaginationData = {
            nextPageNumber: response.nextPage,
            totalPages: response.totalPages,
            hasNext: response.hasNext,
          };

          if (isSearch) {
            let updatedSearchResults = draft.externalInboxSearchResults;
            response.data.forEach((conversation) => {
              updatedSearchResults = addOrReplaceElement(
                updatedSearchResults,
                ConversationUtils.fromConversationModelV3(
                  conversation,
                  action.user
                ),
                (c) => c.id === conversation.id
              );
            });

            draft.externalInboxSearchResults =
              ConversationUtils.sort(updatedSearchResults);
            draft.isExternalSearchInboxLoading = false;
            draft.isExternalInboxSearchLoadingMore = false;
            draft.externalSearchResultsPaginationData = {
              nextPageNumber: response.nextPage,
              totalPages: response.totalPages,
              hasNext: response.hasNext,
            };
          }
        })
    ),
    on(ConversationsApiActions.loadRolesConversationsSuccess, (state, action) =>
      produce(state, (draft) => {
        const { response, isSearch } = action.data;

        let updatedConversations = draft.conversations;
        response.data.forEach((conversation) => {
          updatedConversations = addOrReplaceElement(
            updatedConversations,
            ConversationUtils.fromConversationModelV3(
              conversation,
              action.user
            ),
            (c) => c.id === conversation.id
          );
        });

        draft.conversations = ConversationUtils.sort(updatedConversations);
        draft.isRolesInboxLoading = false;
        draft.isRolesInboxLoadingMore = false;
        draft.rolesInboxPaginationData = {
          nextPageNumber: response.nextPage,
          totalPages: response.totalPages,
          hasNext: response.hasNext,
        };

        if (isSearch) {
          let updatedSearchResults = draft.rolesInboxSearchResults;
          response.data.forEach((conversation) => {
            updatedSearchResults = addOrReplaceElement(
              updatedSearchResults,
              ConversationUtils.fromConversationModelV3(
                conversation,
                action.user
              ),
              (c) => c.id === conversation.id
            );
          });

          draft.rolesInboxSearchResults =
            ConversationUtils.sort(updatedSearchResults);
          draft.isRolesInboxSearchLoading = false;
          draft.isRolesInboxSearchLoadingMore = false;
          draft.rolesInboxSearchResultsPaginationData = {
            nextPageNumber: response.nextPage,
            totalPages: response.totalPages,
            hasNext: response.hasNext,
          };
        }
      })
    ),
    on(ConversationsApiActions.loadConversationsError, (state, action) =>
      produce(state, (draft) => {
        if (action.isSearch) {
          draft.isInboxSearchLoading = false;

          // Note: it's important we read the current state here and not the draft that we've modified
          if (state.inboxSearchResultsPaginationData.nextPageNumber > 0) {
            draft.isInboxSearchLoadingMore = false;
          }
        } else {
          draft.isInboxLoading = false;

          // Note: it's important we read the current state here and not the draft that we've modified
          if (state.inboxPaginationData.nextPageNumber > 0) {
            draft.isInboxLoadingMore = false;
          }
        }
      })
    ),
    on(
      ConversationsApiActions.loadConversationSuccess,
      ConversationsApiActions.pinConversationSuccess,
      ConversationsApiActions.unpinConversationSuccess,
      ConversationsApiActions.editConversationPhotoSuccess,
      ConversationsApiActions.removeConversationPhotoSuccess,
      ConversationsApiActions.editConversationDetailsSuccess,
      (state, action) =>
        produce(state, (draft) => {
          if (action.data.conversation.id === state.selectedConversationId) {
            draft.isSelectedConversationLoading = false;
          }

          const existingConversationIndex = draft.conversations.findIndex(
            (c) => c.id === action.data.conversation.id
          );

          if (existingConversationIndex === -1) {
            draft.conversations.push(
              ConversationUtils.fromConversationModelV3(
                action.data.conversation,
                action.user
              )
            );
            return draft;
          }

          const existingConversation =
            state.conversations[existingConversationIndex];

          const updatedConversation =
            ConversationUtils.updateFromConversationModelV3(
              existingConversation,
              action.data.conversation,
              action.user
            );

          draft.conversations[existingConversationIndex] = updatedConversation;
          draft.conversations = ConversationUtils.sort(draft.conversations);
        })
    ),
    on(ConversationsApiActions.loadConversationError, (state, action) =>
      produce(state, (draft) => {
        if (action.conversationId !== state.selectedConversationId) return;
        draft.isSelectedConversationLoading = false;
      })
    ),
    on(
      ConversationsPageActions.messagesScrolledToTop,
      ConversationsPageActions.loadMoreOlderMessages,
      (state, action) =>
        produce(state, (draft) => {
          draft.isPreviousPageOfMessagesLoading = true;
        })
    ),
    on(ConversationsPageActions.loadMoreNewerMessages, (state, action) =>
      produce(state, (draft) => {
        draft.isNextPageOfMessagesLoading = true;
      })
    ),
    on(ConversationsApiActions.loadMessagesSuccess, (state, action) =>
      produce(state, (draft) => {
        const messages = action.data.messages.map(
          MessageUtils.fromMessageModel
        );

        // Very inneficient, should be optimized in the future
        for (const message of messages) {
          draft.selectedConversationMessages = MessageUtils.upsert(
            draft.selectedConversationMessages,
            message,
            action.user,
            { isAppendingEnabled: true }
          );
        }

        draft.selectedConversationMessages = MessageUtils.sort(
          draft.selectedConversationMessages,
          action.user
        );

        const pageType = action.data.pageType;
        switch (pageType) {
          case "initial":
            draft.isInitialPageOfMessagesLoading = false;
            break;
          case "next":
            draft.isNextPageOfMessagesLoading = false;
            break;
          case "previous":
            draft.isPreviousPageOfMessagesLoading = false;
            break;
          default:
            return assertIsExhaustive(pageType);
        }
      })
    ),
    on(ConversationsApiActions.loadMessagesError, (state, action) =>
      produce(state, (draft) => {
        const pageType = action.pageType;
        switch (pageType) {
          case "initial":
            draft.isInitialPageOfMessagesLoading = false;
            break;
          case "next":
            draft.isNextPageOfMessagesLoading = false;
            break;
          case "previous":
            draft.isPreviousPageOfMessagesLoading = false;
            break;
          default:
            return assertIsExhaustive(pageType);
        }
      })
    ),
    on(
      ConversationsApiActions.loadConversationsError,
      ConversationsApiActions.loadConversationError,
      ConversationsApiActions.pinConversationError,
      ConversationsApiActions.unpinConversationError,
      ConversationsApiActions.editConversationPhotoError,
      ConversationsApiActions.removeConversationPhotoError,
      (state, action) =>
        produce(state, (draft) => {
          // Currently we do nothing here, we may want an effect to show an error dialog
        })
    ),
    on(ConversationsPageActions.patientUpdated, (state, action) =>
      produce(state, (draft) => {
        const conversation = draft.conversations.find(
          (c) => c.id === action.conversationId
        );
        if (!conversation) return;
        conversation.patientData = action.patientData;
      })
    ),
    on(SignalRActions.conversationPinnedUpdated, (state, action) =>
      produce(state, (draft) => {
        const existingConversationIndex = draft.conversations.findIndex(
          (c) => c.id === action.conversationId
        );

        if (existingConversationIndex === -1) {
          // Todo: move this to an effect to load the relevant conversation if it isn't already loaded
          return;
        }

        const existingConversation =
          state.conversations[existingConversationIndex];

        const updatedConversation =
          ConversationUtils.updateFromPinnedConversationUpdate(
            existingConversation,
            action
          );

        draft.conversations[existingConversationIndex] = updatedConversation;
        draft.conversations = ConversationUtils.sort(draft.conversations);
      })
    ),
    on(ConversationsPageActions.editConversationDetails, (state, action) =>
      produce(state, (draft) => {
        draft.isSaving = true;
      })
    ),
    on(
      ConversationsApiActions.editConversationDetailsSuccess,
      ConversationsApiActions.editConversationDetailsError,
      (state, action) =>
        produce(state, (draft) => {
          draft.isSaving = false;
        })
    ),
    on(
      ConversationsApiActions.muteConversationSuccess,
      ConversationsApiActions.unmuteConversationSuccess,
      (state, action) =>
        produce(state, (draft) => {
          const conversation = draft.conversations.find(
            (c) => c.id === action.conversationId
          );
          const participant = ConversationUtils.findConversationParticipant(
            conversation,
            action.participant.userId
          );

          // Something has gone very wrong if this happens. A conversation should always
          // have the current user in it's participant array
          if (!participant) throw new Error("Failed to find participant");

          participant.muteInterval = action.participant.muteInterval;
          participant.mutedToUtc = action.participant.mutedToUtc;
        })
    ),
    on(
      ConversationsApiActions.messageUpdate,
      ConversationsApiActions.videoCallUpdate,
      (state, action) =>
        produce(state, (draft) => {
          const actionConversation = action.data.conversation;

          let newConversation: Conversation;
          let newMessage: Message;
          if (action.type === "[Conversations API] Message Update") {
            newConversation = ConversationUtils.updateFromMessageUpdate(
              actionConversation,
              action.data.update,
              action.user,
              action.data.participant
            );
            newMessage = MessageUtils.fromMessageModel(action.data.update);
          } else {
            const result = ConversationUtils.updateFromVideoCallUpdate(
              actionConversation,
              action.data.update,
              action.user,
              action.data.participant
            );
            newConversation = result.conversation;
            newMessage = result.message;
          }

          // Remove quoted message (the message being replied to) if it's deleted
          if (
            draft.selectedReplyMessage?.marker === newMessage.marker &&
            newMessage.deletedOnUtc
          ) {
            draft.selectedReplyMessage = null;
          }

          draft.conversations = addOrReplaceElement(
            draft.conversations,
            newConversation,
            (c) => c.id === newConversation.id
          );
          draft.conversations = ConversationUtils.sort(draft.conversations);

          if (action.data.conversation.id !== draft.selectedConversationId) {
            return;
          }

          draft.selectedConversationMessages = MessageUtils.upsert(
            draft.selectedConversationMessages,
            newMessage,
            action.user
          );
        })
    ),
    on(
      ConversationsPreprocessingActions.sendMessagePreprocessed,
      ConversationsPreprocessingActions.sendMediaPreprocessed,
      ConversationsPreprocessingActions.sendPatientFilesPreprocessed,
      (state, action) =>
        produce(state, (draft) => {
          const existingConversationIndex = draft.conversations.findIndex(
            (c) => c.id === action.conversationId
          );

          if (existingConversationIndex === -1) {
            // This should never happen when sending a message
            throw new Error("Failed to find conversation");
          }

          let newMessages: Message[];

          const actionType = action.type;
          switch (actionType) {
            case "[Conversations Preprocessing] Send Message Preprocessed":
              newMessages = [
                MessageUtils.fromCreateMessageModel(
                  action.message,
                  action.conversationId,
                  action.user,
                  state.selectedReplyMessage
                ),
              ];
              break;
            case "[Conversations Preprocessing] Send Media Preprocessed":
              newMessages = action.media.map((media) =>
                MessageUtils.fromCreateMessageMediaModel(
                  media,
                  action.conversationId,
                  action.user,
                  state.selectedReplyMessage
                )
              );
              break;
            case "[Conversations Preprocessing] Send Patient Files Preprocessed":
              newMessages = action.patientFiles.map((patientFile) =>
                MessageUtils.fromCreateMessagePatientFileModel(
                  patientFile,
                  action.conversationId,
                  action.user,
                  state.selectedReplyMessage
                )
              );
              break;
            default:
              return assertIsExhaustive(actionType);
          }

          const existingConversation =
            state.conversations[existingConversationIndex];

          let updatedConversation: Conversation;
          for (const newMessage of newMessages) {
            updatedConversation = ConversationUtils.updateFromMessage(
              existingConversation,
              newMessage,
              action.user
            );
          }

          // Sending a message to a conversation unarchives it
          updatedConversation.isArchived = false;

          draft.selectedReplyMessage = null;
          draft.conversations[existingConversationIndex] = updatedConversation;
          draft.conversations = ConversationUtils.sort(draft.conversations);

          if (draft.selectedConversationId !== action.conversationId) {
            return;
          }

          draft.selectedReplyMessage = null;

          for (const newMessage of newMessages) {
            draft.selectedConversationMessages = MessageUtils.upsert(
              draft.selectedConversationMessages,
              newMessage,
              action.user
            );
          }
        })
    ),
    on(
      ConversationsApiActions.sendMessageSuccess,
      ConversationsApiActions.deleteMessageSuccess,
      (state, action) => {
        const messages = [action.data.message];
        const newState = updateStateFromSentMessages(
          messages,
          action.user,
          state
        );
        return newState;
      }
    ),
    on(
      ConversationsApiActions.sendMediaSuccess,
      ConversationsApiActions.sendPatientFilesSuccess,
      (state, action) => {
        const messages = action.data.messages;
        const newState = updateStateFromSentMessages(
          messages,
          action.user,
          state
        );
        return newState;
      }
    ),
    on(ConversationsApiActions.sendMessageError, (state, action) =>
      produce(state, (draft) => {
        // TODO update selected conversation messages as unread
      })
    ),
    on(ConversationsPageActions.viewMessageCard, (state, action) =>
      produce(state, (draft) => {
        if (action.isPreviewMode || action.isSentBySelf) return;

        const conversationId = action.message.conversationId;

        // Optimistically update the conversation's localAsReadToUtc
        const conversation = draft.conversations.find(
          (c) => c.id === conversationId
        );
        if (!conversation) return;

        const newConversation = ConversationUtils.updateFromViewedMessage(
          conversation,
          action.message,
          action.user
        );

        draft.conversations = addOrReplaceElement(
          draft.conversations,
          newConversation,
          (c) => c.id === newConversation.id
        );

        draft.conversations = ConversationUtils.sort(draft.conversations);
      })
    ),
    on(ConversationsApiActions.readMessagesSuccess, (state, action) =>
      produce(state, (draft) => {
        if (draft.selectedConversationId !== action.data.conversationId) return;
        for (const messageModel of action.data.messages) {
          const message = MessageUtils.fromMessageModel(messageModel);
          draft.selectedConversationMessages = MessageUtils.upsert(
            draft.selectedConversationMessages,
            message,
            action.user
          );
        }
      })
    ),
    on(ConversationsApiActions.readMessagesError, (state, action) =>
      produce(state, (draft) => {
        // TODO update selected conversation messages as unread
      })
    ),
    on(
      ConversationsPageActions.archiveConversation,
      ConversationsApiActions.archiveConversationSuccess,
      (state, action) =>
        produce(state, (draft) => {
          const conversation = draft.conversations.find(
            (c) => c.id === action.conversationId
          );
          if (!conversation) return;
          conversation.isArchived = true;
        })
    ),
    on(
      ConversationsPageActions.undoArchiveConversation,
      ConversationsApiActions.archiveConversationError,
      (state, action) =>
        produce(state, (draft) => {
          const conversation = draft.conversations.find(
            (c) => c.id === action.conversationId
          );
          if (!conversation) return;
          conversation.isArchived = false;
        })
    ),
    on(
      ConversationsApiActions.createInvitationSuccess,
      ConversationsApiActions.resetInvitationSuccess,
      ConversationsApiActions.toggleInvitationAllowAllSuccess,
      (state, action) =>
        produce(state, (draft) => {
          const conversation = draft.conversations.find(
            (c) => c.id === action.conversationId
          );
          if (!conversation) return;

          const actionType = action.type;
          switch (actionType) {
            case "[Conversations API] Create Invitation Success":
            case "[Conversations API] Toggle Invitation Allow All Success":
              conversation.invitation = action.invitation;
              break;
            case "[Conversations API] Reset Invitation Success":
              conversation.invitation.uri =
                action.resetInvitationModel.invitationUri;
              break;
            default:
              return assertIsExhaustive(actionType);
          }
        })
    ),
    on(ConversationsApiActions.markConversationAsReadSuccess, (state, action) =>
      produce(state, (draft) => {
        const existingConversationIndex = draft.conversations.findIndex(
          (c) => c.id === action.data.conversationId
        );
        if (existingConversationIndex === -1) return;

        const existingConversation =
          draft.conversations[existingConversationIndex];

        const updatedConversation =
          ConversationUtils.updateFromClearUnreadConverationModelV3(
            existingConversation,
            action.data.clearUnreadConversationModel,
            action.user
          );

        draft.conversations[existingConversationIndex] = updatedConversation;
      })
    ),
    on(
      ConversationsApiActions.loadUnreadSkeletonConversationsSuccess,
      (state, action) =>
        produce(state, (draft) => {
          const skeletonConversations = action.data.response.data;

          for (const skeletonConversation of skeletonConversations) {
            draft.skeletonConversations = addOrReplaceElement(
              draft.skeletonConversations,
              skeletonConversation,
              (c) => c.id === skeletonConversation.id
            );
          }
        })
    ),
    on(ConversationsPageActions.replyToMessage, (state, action) =>
      produce(state, (draft) => {
        draft.selectedReplyMessage = action.message;
      })
    ),
    on(ConversationsPageActions.replyToMessageReset, (state, action) =>
      produce(state, (draft) => {
        draft.selectedReplyMessage = null;
      })
    )
  ),
});

export const selectConversation = (conversationId: string) =>
  createSelector(conversationsFeature.selectConversations, (conversations) => {
    return (
      conversations.find(
        (c) => c.id.toLowerCase() === conversationId.toLowerCase()
      ) ?? null
    );
  });

export const selectInboxConversations = (userId: string) =>
  createSelector(conversationsFeature.selectConversations, (conversations) => {
    return conversations.filter((c) => {
      // Filter out external conversations
      if (c.type === ConversationType.External) return;

      // Filter out archived conversations
      if (c.isArchived) return false;

      // Filter out chats with no messages that are not created by the current user
      if (
        c.type === ConversationType.Chat &&
        c.createdBy !== userId &&
        !c.lastMessage
      )
        return false;

      const currentUserParticipant =
        ConversationUtils.findConversationParticipant(c, userId);

      // Filter out team chats where the user is a team participant
      if (currentUserParticipant.teamId) return false;

      // Filter out team chats the user has left
      if (c.type !== ConversationType.TeamChat) return true;
      if (!c.participants) return false;

      if (currentUserParticipant.leftOnUtc) return false;
      return true;
    });
  });

export const selectExternalInboxConversations = (userId: string) =>
  createSelector(conversationsFeature.selectConversations, (conversations) => {
    return ConversationUtils.filterExternalConversations(conversations, userId);
  });

export const selectRolesInboxConversations = (userId: string) =>
  createSelector(
    conversationsFeature.selectConversations,
    conversationsFeature.selectSelectedTeamId,
    (conversations, teamId) => {
      const currentUserTeamChats = ConversationUtils.filterTeamChats(
        conversations,
        teamId,
        userId
      );

      const conversationsWithMessages = currentUserTeamChats.filter(
        (c) => c.lastMessage != null
      );

      return conversationsWithMessages;
    }
  );

export const selectSelectedConversation = createSelector(
  conversationsFeature.selectConversations,
  conversationsFeature.selectSelectedConversationId,
  (conversations, selectedConversationId) => {
    if (!selectedConversationId) return null;
    return (
      conversations.find(
        (c) => c.id.toLowerCase() === selectedConversationId.toLowerCase()
      ) ?? null
    );
  }
);

export const selectUnreadConversations = createSelector(
  conversationsFeature.selectConversations,
  conversationsFeature.selectSkeletonConversations,
  (conversations, skeletonConversations) => {
    const conversationIds = new Set(conversations.map((c) => c.id));

    const unreadConversations = conversations.filter(
      (c) => c.unreadMessageCount
    );

    // Skeleton conversations are considered unread if we haven't fullyloaded the corresponding conversation
    const unreadSkeletonConversations = skeletonConversations.filter(
      (c) => !conversationIds.has(c.id)
    );

    return {
      conversations: unreadConversations,
      skeletonConversations: unreadSkeletonConversations,
    };
  }
);

export const selectInboxUnreadConversationCount = (userId: string) =>
  createSelector(
    selectUnreadConversations,
    ({ conversations, skeletonConversations }) => {
      const conversationTypes: Set<ConversationType> = new Set([
        ConversationType.Chat,
        ConversationType.Case,
        ConversationType.Group,
        ConversationType.SelfChat,
        ConversationType.TeamChat,
      ]);

      const filteredConversations = conversations.filter((c) => {
        if (!conversationTypes.has(c.type)) return false;

        // Only include team chats where the user is a non-team participant
        if (c.type === ConversationType.TeamChat) {
          const participant = ConversationUtils.findConversationParticipant(
            c,
            userId
          );
          if (!participant) throw new Error("Failed to find participant");
          if (participant.teamId) return false;
        }

        return true;
      });

      const filteredSkeletonConversations = skeletonConversations.filter(
        (c) => {
          if (!conversationTypes.has(c.type)) return false;

          // Only include team chats where the user is a non-team participant
          if (c.type === ConversationType.TeamChat) {
            const isNonTeamParticipant =
              c.teamDetails.isNonTeamParticipant ?? false;
            if (!isNonTeamParticipant) return false;
          }

          return true;
        }
      );

      return (
        filteredConversations.length + filteredSkeletonConversations.length
      );
    }
  );

export const selectRolesInboxUnreadRoleCount = (userId: string) =>
  createSelector(
    selectUnreadConversations,
    ({ conversations, skeletonConversations }) => {
      // Note: the roles inbox shows the number of teams that have
      // any unread conversations

      const filteredConversations = conversations.filter((c) => {
        if (c.type !== ConversationType.TeamChat) return false;

        // Only count team chats where the user is a team participant
        const participant = ConversationUtils.findConversationParticipant(
          c,
          userId
        );

        if (!participant) throw new Error("Failed to find participant");
        if (!participant.teamId) return false;

        return true;
      });

      const filteredSkeletonConversations = skeletonConversations.filter(
        (c) => {
          if (c.type !== ConversationType.TeamChat) return false;

          // Only count team chats where the user is a member
          const isMember = c.teamDetails?.teams.some((team) => team.isMember);
          return isMember;
        }
      );

      return (
        filteredConversations.length + filteredSkeletonConversations.length
      );
    }
  );

export const selectExternalInboxUnreadConversationCount = (userId: string) =>
  createSelector(
    selectUnreadConversations,
    ({ conversations, skeletonConversations }) => {
      const filteredConversations = conversations.filter(
        (c) => c.type === ConversationType.External
      );

      const filteredSkeletonConversations = skeletonConversations.filter(
        (c) => c.type === ConversationType.External
      );

      return (
        filteredConversations.length + filteredSkeletonConversations.length
      );
    }
  );

export const selectUnreadMessageCount = createSelector(
  selectUnreadConversations,
  ({ conversations }) => {
    return conversations.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.unreadMessageCount,
      0
    );
  }
);
