import { Component, Input } from "@angular/core";

@Component({
  selector: "app-create-new-conversation-button",
  templateUrl: "./create-new-conversation-button.component.html",
  styleUrls: ["./create-new-conversation-button.component.scss"],
})
export class CreateNewConversationButtonComponent {
  @Input() buttonText: string;
}
