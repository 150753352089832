import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SharedModule } from "app/modules/shared/shared.module";
import { ProfileModule } from "../profile/profile.module";
import { ActivityFeedEmptyComponent } from "./activity-feed-empty/activity-feed-empty.component";
import { ActivityFeedListItemComponent } from "./activity-feed-list-item/activity-feed-list-item.component";
import { ActivityFeedListComponent } from "./activity-feed-list/activity-feed-list.component";
import { ConnectionsListComponent } from "./connections-list/connections-list.component";
import { NotificationsDropdownComponent } from "./notifications-dropdown/notifications-dropdown.component";
import { NotificationsService } from "./notifications.service";
import { NotificationsComponent } from "./notifications/notifications.component";

const routes: Routes = [
  {
    path: "",
    component: NotificationsComponent,
  },
];

@NgModule({
  declarations: [
    ConnectionsListComponent,
    NotificationsComponent,
    ActivityFeedListComponent,
    NotificationsDropdownComponent,
    ActivityFeedListItemComponent,
    ActivityFeedEmptyComponent,
  ],
  exports: [NotificationsDropdownComponent, NotificationsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    ProfileModule,
  ],
  providers: [NotificationsService],
})
export class NotificationsModule {}
