<button mat-icon-button aria-label="Close" id="close" mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>

<img src="../../../../assets/in-app-roles.svg" alt="Roles" />

<h3 class="mat-h3 title">Set up a new role</h3>

<div class="mat-body subtitle">
  A role allows people to contact a team without the need to know the person on
  call for the role.
</div>

<form [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <mat-label>Role Name</mat-label>
    <input
      matInput
      name="name"
      formControlName="name"
      required
      cdkFocusInitial
    />
  </mat-form-field>
</form>

<div class="mat-body description">
  <p>Anyone in the workspace can message the role.</p>

  <p>
    Members of the role can mark themselves as <b>On Call</b> for the role, so
    that they are notified of any new messages.
  </p>

  <p>All role members can respond to messages on behalf of the role.</p>
</div>

<button
  id="submit"
  mat-button
  class="celo-primary-button uppercase"
  (click)="handleSubmit()"
  [disabled]="!formGroup.valid || isLoading"
>
  <ng-container *ngIf="!isLoading; else loading"> Create role </ng-container>
  <ng-template #loading>
    <mat-icon>
      <app-basic-spinner diameter="20" strokeWidth="2"></app-basic-spinner>
    </mat-icon>
  </ng-template>
</button>
