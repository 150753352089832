import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-basic-avatar",
  templateUrl: "./basic-avatar.component.html",
  styleUrls: ["./basic-avatar.component.scss"],
})
export class BasicAvatarComponent {
  @Input() public name: string = "";
  @Input() public size: number = 24;
  @Input() public src: string | null = null;
  @Input() public badgeSrc: string | null = null;
  @Input() public badgeSize: number = 17;

  @Input() public fallback: string | null = null;
  @Output() public imageError: EventEmitter<void> = new EventEmitter();

  public srcError: boolean = false;
  public fallbackError: boolean = false;

  public onError($event: Event) {
    this.imageError.emit();
    if (this.srcError) {
      this.fallbackError = true;
    } else {
      this.srcError = true;
    }
  }
}
