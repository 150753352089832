<form #form class="celo-input-outline">
  <div class="conversation-details">
    <div class="title">{{ type ? type : "Message" }} Details</div>
    <mat-form-field appearance="outline">
      <mat-label>Name this {{ type | lowercase }}</mat-label>
      <input
        matInput
        [placeholder]="'Name this ' + (type | lowercase)"
        id="nameField"
        name="nameField"
        #nameField="ngModel"
        required
        [(ngModel)]="name"
        (ngModelChange)="
          nameChange.emit(name); nameInvalid.emit(nameField.invalid)
        "
        pattern="\s*\S+.*"
        autofocus
        maxLength="200"
      />
    </mat-form-field>
  </div>
  <div *ngIf="type === 'Case'" class="patient-details">
    <div class="title">Patient Details</div>
    <div class="box">
      <div class="boxcontent">
        <app-edit-patient [patient]="patientData" [optionalId]="true">
        </app-edit-patient>
      </div>
    </div>
    <div class="subtitle">
      You can add patient details if you wish to discuss a specific patient
      case. You can add/edit this information later.
    </div>
  </div>
</form>
