// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const config = {
  title: "Celo",
  meta: "The Celo Health app is a secure messaging and healthcare collaboration tool used by top healthcare teams. Download the app for free to get started.",
  max_file_upload_limit: 20,
  removeWorkspaceUserFeatureEnabled: true,
  addWorkspaceUserFeatureEnabled: false,
  minSearchLength: 3,
};
