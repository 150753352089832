<form [formGroup]="recipientInformationForm" (ngSubmit)="onSubmit()">
  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Last Name</mat-label>
    <input
      matInput
      placeholder="Last Name"
      name="lastName"
      formControlName="lastName"
      type="text"
    />
  </mat-form-field>

  <mat-form-field class="form-field" appearance="outline">
    <mat-label>First Name</mat-label>
    <input
      matInput
      placeholder="First Name"
      name="firstName"
      formControlName="firstName"
      type="text"
    />
  </mat-form-field>

  <mat-form-field class="form-field" appearance="outline">
    <mat-label>Date of Birth</mat-label>
    <input
      matInput
      [max]="maxDate"
      [min]="minDate"
      [matDatepicker]="picker"
      placeholder="Date of Birth"
      name="dateOfBirth"
      formControlName="dateOfBirth"
    />
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <div class="flex g-10px">
    <mat-form-field class="form-field" appearance="outline" id="calling-code">
      <input
        type="text"
        placeholder="Country Calling Code"
        aria-label="Country Calling Code"
        matInput
        [formControl]="recipientInformationForm.controls.countryCallingCode"
        [matAutocomplete]="auto"
      />
      <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
      <mat-autocomplete
        autoActiveFirstOption
        autoSelectActiveOption
        #auto="matAutocomplete"
        panelWidth="fit-content"
        [displayWith]="displayFn"
      >
        <mat-option
          class="option"
          *ngFor="let countryCallingCode of filteredCountryCallingCodes | async"
          [value]="countryCallingCode.countryCode"
        >
          <div class="flag-option">
            <img
              class="flag"
              [attr.src]="
                '../../../../assets/flags/' +
                countryCallingCode.countryCodeLowerCase +
                '.svg'
              "
              [attr.alt]="countryCallingCode.countryName"
              loading="lazy"
              width="18px"
              height="13.5px"
            />
            <div class="label">
              +{{ countryCallingCode.countryCallingCode }}
              {{ countryCallingCode.countryName }}
            </div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field class="form-field flex-auto" appearance="outline">
      <mat-label>Mobile</mat-label>
      <input
        matInput
        placeholder="Mobile"
        name="phoneNumber"
        formControlName="phoneNumber"
        type="tel"
      />
    </mat-form-field>
  </div>

  <ng-content></ng-content>
</form>
