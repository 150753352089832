import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  AnalyticsService,
  FileUploadService,
  UploadPatientModel,
  UploadServiceFileItem,
} from "@modules/core";
import { FileUploader } from "ng2-file-upload";

@Component({
  selector: "app-file-upload-preview",
  templateUrl: "./file-upload-preview.component.html",
  styleUrls: ["./file-upload-preview.component.scss"],
})
export class FileUploadPreviewComponent implements OnInit, OnChanges {
  @Output() closeMe = new EventEmitter();
  @Output() public items: EventEmitter<UploadServiceFileItem[]> =
    new EventEmitter<UploadServiceFileItem[]>();

  @Output() public uploaded: EventEmitter<void> = new EventEmitter<void>();

  @Input() noUpload: boolean;
  @Input() type: string;
  @Input() isInSecureLibrary: string;
  pageName: string;
  @Input() uploader: FileUploader;
  @Input() refreshCount: number;
  patientValidity = true;

  @Input() public patient: UploadPatientModel = {
    uid: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    gender: null,
  };

  private _selectedItemIndex: number = 0;
  @Input() public get selectedItemIndex(): number {
    return this._selectedItemIndex;
  }

  public set selectedItemIndex(index: number) {
    this._selectedItemIndex = index;
    this.selectedItemIndexChange.emit(index);
  }

  @Output() public selectedItemIndexChange: EventEmitter<number> =
    new EventEmitter<number>();

  // hasPatient:boolean;
  dropZoneActive: string;

  constructor(
    private uploadService: FileUploadService,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.refresh();
  }

  @HostListener("window:keydown", ["$event"])
  public keyboardInput(event: KeyboardEvent) {
    if (event.code === "ArrowRight") {
      this.onNext(1);
    } else if (event.code === "ArrowLeft") {
      this.onNext(-1);
    }
  }

  onNext(direction) {
    this.selectedItemIndex += direction;
    if (this.selectedItemIndex >= this.uploader.queue.length) {
      this.selectedItemIndex = this.uploader.queue.length - 1;
    } else if (this.selectedItemIndex < 0) {
      this.selectedItemIndex = 0;
    }
  }

  dropZoneState(state: boolean) {
    this.dropZoneActive = state ? "valid" : "";
  }

  scrollTo(el: HTMLElement) {
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 100);
  }

  upload() {
    this.uploaded.emit();
    if (this.noUpload) {
      this.closeMe.emit();
      return;
    }
    this.uploadService.patient = this.patient;
    this.uploadService.upload();
    this.raiseEventOnUploadButtonClick();
  }

  raiseEventOnUploadButtonClick() {
    this.analyticsService.raiseEvent("button_click", {
      flow: this.isInSecureLibrary ? "secure_library" : "message",
      button_id: this.isInSecureLibrary ? "save_media" : "save_and_send_media",
      number_of_files: this.uploader.queue.length,
    });
  }

  setpageName() {
    if (!this.uploader || !this.uploader.queue) {
      return this.type;
    }
    let pageName = "";
    let photosCount = 0;
    let documentsCount = 0;
    this.uploader.queue.forEach((element) => {
      if (element.file.type.startsWith("image/")) {
        // type = 'Photos';
        photosCount++;
      } else if (element.file.type.startsWith("application/pdf")) {
        // type = 'Documents';
        documentsCount++;
      }
    });
    if (photosCount && !documentsCount) {
      pageName = "Photos";
    } else if (!photosCount && documentsCount) {
      pageName = "Documents";
    } else if (photosCount && documentsCount) {
      pageName = "Files";
    }
    return pageName;
  }

  refresh() {
    if (!this.uploader || !this.uploader.queue) {
      return;
    }
    this.setPreviews(this.uploader.queue);
    this.pageName = this.setpageName();
    this.items.emit([...this.uploader.queue] as UploadServiceFileItem[]);
  }

  handleNewFiles() {
    this.refresh();
  }

  ngOnChanges() {
    this.pageName = this.setpageName();
  }

  setPreviews(files) {
    for (let i = 0; i < files.length; i++) {
      if (this.uploadService.isPhoto(files[i])) {
        this.readURL(files[i]);
      } else if (this.uploadService.isDocument(files[i])) {
        this.loadDocumentData(files[i]);
      }
    }
  }

  readURL(file: any) {
    if (file._file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        file.pic_url = e.target["result"];
        // file.description='';
      };
      reader.readAsDataURL(file._file);
    }
  }

  private loadDocumentData(file: any) {
    const reader = new FileReader();
    const i = this;
    reader.onload = function (e) {
      if (!e || !e.target) {
        return;
      }
      file["preview"] = {
        // data: new Uint8Array(e.target['result'])
        data: new Uint8Array(e.target["result"] as ArrayBuffer),
      };
    };

    reader.readAsArrayBuffer(file._file);
  }

  // togglePatient(){
  //   this.hasPatient=!this.hasPatient;
  //   if(!this.hasPatient){
  //     this.patient={};
  //   }
  // }

  removeFile(index: number) {
    this.uploader.queue.splice(index, 1);
    if (this.selectedItemIndex >= this.uploader.queue.length) {
      this.selectedItemIndex--;
    }
    this.closeIfEmpty();
    this.refresh();
  }

  closeIfEmpty() {
    if (this.uploader.queue.length == 0) {
      this.closeMe.emit();
    }
  }
}
