import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  ConversationParticipantModelV2,
  FullUserProfileModel,
  ConversationModelV2,
} from "types";

export interface MuteStatusEvent {
  participant: ConversationParticipantModelV2;
  conversation: ConversationModelV2;
}

@Component({
  selector: "app-conversation-results-group",
  templateUrl: "./conversation-results-group.component.html",
  styleUrls: ["./conversation-results-group.component.scss"],
})
export class ConversationResultsGroupComponent {
  @Input() public conversations: ConversationModelV2[] = [];
  @Input() public userAccount: FullUserProfileModel = {};
  @Input() public refreshCount: number;
  @Input() public hideBorder: boolean;
  @Input() public inConversationList: boolean;
  @Input() public activeOnly = false;
  @Input() public hideUnreadCount: boolean;
  @Input() public hideArchiveOption: boolean;
  @Input() public hideLastMessage: boolean;
  @Input() public showParticipants: boolean;
  @Input() public isSelectionList: boolean;
  @Input() public selectedConversationId: string | null = null;

  @Output() public muteStatus = new EventEmitter<MuteStatusEvent>();
  @Output() public conversation = new EventEmitter<ConversationModelV2>();

  public onMuteStatusChange(
    participant: ConversationParticipantModelV2,
    conversation: ConversationModelV2
  ) {
    this.muteStatus.emit({
      participant,
      conversation,
    });
  }

  public onConversationSelected(conversation: ConversationModelV2) {
    this.conversation.emit(conversation);
  }
}
