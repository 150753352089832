<mat-sidenav-container [hasBackdrop]="false">
  <!-- Participants drawer/sidenav -->
  <mat-sidenav
    #drawer
    mode="over"
    position="end"
    id="drawer"
    [fixedInViewport]="true"
  >
    <div id="drawer-container" class="flex flex-col">
      <div class="flex justify-between items-center ph">
        <h3 class="header mat-h3">Call participants</h3>
        <button
          id="close"
          mat-icon-button
          aria-label="close"
          (click)="drawer.toggle()"
          class="mat-blue"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>

      <mat-divider />

      <div id="participants-container">
        <ng-container
          *ngIf="
            !isCallEnded && conversationParticipants?.length;
            else participantsEmpty
          "
        >
          <app-basic-user-selection-list
            [users]="conversationParticipants"
            [hoverDisabled]="true"
            [rippleDisabled]="true"
            leftContentMargin="20px"
          ></app-basic-user-selection-list
        ></ng-container>

        <ng-template #participantsEmpty>
          <div class="participants-empty">
            <ng-container *ngIf="isCallEnded; else participantsLoading">
              Call Ended
            </ng-container>

            <ng-template #participantsLoading>
              <app-basic-spinner></app-basic-spinner>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </mat-sidenav>

  <!-- Main content-->
  <mat-sidenav-content>
    <div class="call-container">
      <!-- Header -->
      <div #callHeader class="call-header">
        <!-- Conversation icon and name -->
        <div class="title-container">
          <app-voip-conversation-avatar
            [conversation]="conversation"
            [size]="36"
          ></app-voip-conversation-avatar>
          <div *ngIf="conversationName" class="title">
            {{ conversationName }}
          </div>
        </div>

        <!-- Participants drawer button -->
        <button
          mat-icon-button
          aria-label="Info"
          (click)="drawer.toggle()"
          class="mat-white participant-button"
        >
          <mat-icon>info_outline</mat-icon>
        </button>
      </div>

      <!-- Video -->
      <div #videoContainer class="video-container">
        <!-- Other participants video -->
        <canvas
          #canvas
          id="participant-videos-canvas"
          [ngStyle]="{
            width: dimensions.width + 'px',
            height: dimensions.height + 'px'
          }"
          [hidden]="!participants.length || isCallEnded"
        ></canvas>

        <!-- Current user video and avatar -->
        <div
          #currentUserVideoCanvasContainer
          class="current-user-video-canvas-container"
          (mousedown)="handleCurrentUserVideoMouseDown($event)"
          [ngStyle]="{
            right: currentUserVideoPositions.right + 'px',
            bottom: currentUserVideoPositions.bottom + 'px'
          }"
          [hidden]="!currentUserAvatar || isCallEnded"
        >
          <canvas
            #currentUserVideoCanvas
            class="current-user-video-canvas"
            [width]="currentUserVideoCanvasContainer.clientWidth"
            [height]="currentUserVideoCanvasContainer.clientHeight"
          ></canvas>

          <mat-icon
            *ngIf="currentUserAvatar?.isMuted"
            class="mat-white microphone"
          >
            mic_off
          </mat-icon>

          <app-voip-avatar
            *ngIf="currentUserAvatar"
            [ngStyle]="{
              width: '100%',
              height: '100%',
              left: currentUserAvatar.xPos + 'px',
              top: currentUserAvatar.yPos + 'px'
            }"
            [userId]="currentUserAvatar.userId"
            [name]="currentUserAvatar.name"
            [isVideoOn]="currentUserAvatar.isVideoOn"
            [isMuted]="currentUserAvatar.isMuted"
            [isLastActiveSpeaker]="
              currentUserAvatar.zoomUserId === lastActiveSpeakerUserId
            "
            [isYou]="currentUserAvatar.isYou"
            [isWorkspaceVerified]="currentUserAvatar.isWorkspaceVerified"
            [isIdentityVerified]="currentUserAvatar.isIdentityVerified"
            [isProfessionVerified]="currentUserAvatar.isProfessionVerified"
            [disableDetails]="true"
          />
        </div>

        <!-- Other participant avatars -->
        <ng-container *ngIf="participants.length">
          <ng-container *ngFor="let avatar of avatars">
            <app-voip-avatar
              [ngStyle]="{
                width: avatar.width + 'px',
                height: avatar.height + 'px',
                left: avatar.xPos + 'px',
                top: avatar.yPos + 'px'
              }"
              [userId]="avatar.userId"
              [name]="avatar.name"
              [isVideoOn]="avatar.isVideoOn"
              [isMuted]="avatar.isMuted"
              [isLastActiveSpeaker]="
                avatar.zoomUserId === lastActiveSpeakerUserId
              "
              [isYou]="avatar.isYou"
              [isWorkspaceVerified]="avatar.isWorkspaceVerified"
              [isIdentityVerified]="avatar.isIdentityVerified"
              [isProfessionVerified]="avatar.isProfessionVerified"
            />
          </ng-container>
        </ng-container>
      </div>

      <!-- Footer -->
      <div
        *ngIf="!isCallEnded"
        class="call-footer"
        [ngClass]="{
          paged: showLeftPaginationButton || showRightPaginationButton
        }"
      >
        <div class="pagination-container">
          <button
            *ngIf="showLeftPaginationButton"
            mat-button
            class="button previous"
            (click)="navigateLeft()"
          >
            Previous
          </button>
          <div class="mat-white pagination-text">{{ paginationText }}</div>
          <button
            *ngIf="showRightPaginationButton"
            mat-button
            class="button next"
            (click)="navigateRight()"
          >
            Next
          </button>
        </div>

        <app-voip-buttons
          *ngIf="!isCallEnded"
          (notifyCallPage)="endCall(true)"
          [participantId]="participantId"
          [callId]="callId"
          [isAudioStarted]="isAudioStarted"
          [isVideoStarted]="isVideoStarted"
        />
      </div>

      <!-- Overlay, shown before any video streams have loaded -->
      <app-voip-overlay
        *ngIf="showVoipOverlay"
        [conversation]="conversation"
        [isCallEnded]="isCallEnded"
        [isNewCall]="isNewCall"
        [disableMessage]="
          hasOtherParticipantJoinedPreviously && !participants.length
        "
        [disableBackground]="
          hasOtherParticipantJoinedPreviously && !participants.length
        "
      />
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
