import { Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { saveAs } from "file-saver";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  ApiRequestOptions,
  CaseExportEntry,
  ConversationInvitationModel,
  ConversationModel,
  ConversationParticipantModel,
  ConversationParticipantModelV2,
  ConversationType,
  ConversationsV3RequestOptions,
  CreateConversationModel,
  CreateMessageMediaModel,
  CreateMessageModel,
  CreateMessagePatientFileModel,
  CreateParticipantModel,
  GetUnreadSkeletonConversationRequestOptions,
  MessageModel,
  MessageStatusUpdate,
  MessagesV2RequestOptions,
  ParticipantRole,
  PatientDataModel,
  UpdateConversationInvitationModel,
} from "types";
import {
  ClearUnreadConversationModelV3,
  ConversationModelV3,
  ConversationParticipantModelV3,
  ConversationReadToUtcUpdateResponseModel,
  ConversationSkeletonModelV3,
} from "types/api-v3";
import { getConversationName } from "utils/conversation-utils";
import { v4 as uuidv4 } from "uuid";
import { ConService } from "../old/conn.service";
import { ApiPagedResult } from "./../../../../types/api-v3";
import { SubscriptionContainer } from "./../../../../utils/subscription-container";
import { ApiService } from "./api.service";
import { SnackbarService } from "./snackbar.service";
import { UserService } from "./user.service";

export interface ConversationServiceMetadata {
  /**
   * If this conversation was returned by making a search request to the backend, this is the query that resulted in
   * this conversation being returned.
   */
  query?: string | null;
}

export interface ConversationModelV3WithMetadata extends ConversationModelV3 {
  conversationServiceMetadata?: ConversationServiceMetadata;
}

export type ConversationModelV3WithMetadataApiPagedResult =
  ApiPagedResult<ConversationModelV3WithMetadata>;

export const CONVERSATIONS_SERVICE =
  new InjectionToken<ConversationsServiceProvider>("CONVERSATIONS_SERVICE");

export interface ConversationsServiceProvider {
  getConversation(conversationId: string);
  getConversations(
    options: ConversationsV3RequestOptions & ApiRequestOptions
  ): Observable<ConversationModelV3WithMetadataApiPagedResult>;
  getTeamConversations(
    options: ConversationsV3RequestOptions &
      ApiRequestOptions & { teamId: string }
  ): Observable<ConversationModelV3WithMetadataApiPagedResult>;
  getExternalConversations(
    options?: Omit<ConversationsV3RequestOptions, "isExternal"> &
      ApiRequestOptions
  ): Observable<ConversationModelV3WithMetadataApiPagedResult>;
  createChat(otherUserId: string): Observable<ConversationModelV3>;
  createTeamChat(
    teamId: string,
    userId?: string
  ): Observable<ConversationModelV3>;
  createGroup(
    name: string,
    participantUserIds?: string[]
  ): Observable<ConversationModelV3>;
  createCase(
    name: string,
    participantUserIds?: string[],
    patientData?: PatientDataModel
  ): Observable<ConversationModelV3>;
  createExternalChat(
    patientData: PatientDataModel
  ): Observable<ConversationModelV3>;
  updateConversationPatientData(
    conversationId: string,
    patientData: PatientDataModel
  ): Observable<ConversationModel>;
  leaveConversation(conversationId: string): Observable<ConversationModelV3>;
  pinConversation(conversationId: string): Observable<void>;
  unpinConversation(conversationId: string): Observable<void>;
  getMessage(
    conversationId: string,
    messageId: string
  ): Observable<MessageModel>;
  getMessages(options: MessagesV2RequestOptions): Observable<MessageModel[]>;
  deleteMessage(
    conversationId: string,
    messageId: string
  ): Observable<MessageModel>;
  exportToPdf(conversationId: string): Observable<{ operationId: string }>;
  downloadExportedPdf(conversationId: string): void;
  updateConversationRole(
    userId: string,
    conversationId: string,
    role: ParticipantRole
  ): Observable<ConversationModelV3>;
  addParticipants(
    conversationId: string,
    participantUserIds: string[]
  ): Observable<ConversationModelV3>;
  removeParticipant(
    userId: string,
    conversationId: string
  ): Observable<ConversationModelV3>;
  getConversationProfileUri(conversationId: string, photoId: string): string;
  isConversationMutedForParticipant(
    participant: ConversationParticipantModelV2
  ): boolean;
  createAndNavigateToChatConversation(recipientUserId: string): void;
  navigateToConversation(conversationId: string, teamId?: string | null): void;
  navigateToExternalConversation(conversationId: string): void;
  tryParsePotentialTeamId(conversationId: string): string | null;
  isUserMemberOfTeamForTeamChat(
    userId: string,
    conversationId: string
  ): boolean;
  sendMessage(
    conversationId: string,
    message: CreateMessageModel
  ): Observable<MessageModel>;
  sendMedia(
    conversationId: string,
    media: CreateMessageMediaModel[]
  ): Observable<MessageModel[]>;
  sendPatientFiles(
    conversationId: string,
    patientFiles: CreateMessagePatientFileModel[]
  ): Observable<MessageModel[]>;
  getConversationName(conversation: ConversationModelV3): string;
}

@Injectable({
  providedIn: "root",
})
export class ConversationsService
  implements OnDestroy, ConversationsServiceProvider
{
  private subscriptions = new SubscriptionContainer();

  private lastOpIds: Set<string> = new Set();

  public constructor(
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
    private conService: ConService,
    private snackbarService: SnackbarService
  ) {
    const exportedPdfSubscription =
      this.conService.conversationExportEntrySubjectDetails$.subscribe(
        (caseExportEntry: CaseExportEntry) => {
          if (this.lastOpIds.has(caseExportEntry.operationId)) {
            this.snackbarService.show(
              "Case Exported! Your download will begin shortly...",
              10
            );
            this.downloadExportedPdf(caseExportEntry.conversationId);
          }
        }
      );

    this.subscriptions.add(exportedPdfSubscription);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getConversation(
    conversationId: string
  ): Observable<ConversationModelV3> {
    const path = `/api/v3/Conversations/${conversationId}`;
    return this.apiService.get<ConversationModelV3>({ path });
  }

  public getConversations(
    options: ConversationsV3RequestOptions & ApiRequestOptions
  ): Observable<ConversationModelV3WithMetadataApiPagedResult> {
    const path = `/api/v3/Conversations`;
    return this.apiService.get<ApiPagedResult<ConversationModelV3>>({
      path,
      queryParams: { ...options },
    });
  }

  public search(
    options: ConversationsV3RequestOptions & ApiRequestOptions
  ): Observable<ConversationModelV3WithMetadataApiPagedResult> {
    const path = `/api/v3/Conversations`;
    return this.apiService.get<ApiPagedResult<ConversationModelV3>>({
      path,
      queryParams: { ...options },
    });
  }

  public getTeamConversations({
    teamId,
    ...options
  }: ConversationsV3RequestOptions &
    ApiRequestOptions & {
      teamId: string;
    }): Observable<ConversationModelV3WithMetadataApiPagedResult> {
    const path = `/api/v3/Teams/${teamId}/conversations`;
    return this.apiService.get<ApiPagedResult<ConversationModelV3>>({
      path,
      queryParams: { ...options },
    });
  }

  public getExternalConversations({
    ...options
  }: Omit<ConversationsV3RequestOptions, "isExternal"> &
    ApiRequestOptions = {}): Observable<ConversationModelV3WithMetadataApiPagedResult> {
    const path = `/api/v3/Conversations`;

    const mergedOptions: ConversationsV3RequestOptions & ApiRequestOptions = {
      ...options,
      isExternal: true,
    };

    return this.apiService.get<ApiPagedResult<ConversationModelV3>>({
      path,
      queryParams: { ...mergedOptions },
    });
  }

  private createChatId(participantId: string) {
    return [participantId, this.userService.getUserId(true)].sort().join("_");
  }

  public createTeamChatId(teamId: string, userId?: string) {
    return [userId ?? this.userService.getUserId(true), teamId].join("_");
  }

  /**
   * Returns a string if a potential team id is found in `conversationId`, otherwise returns null.
   *
   * Note: if a string is returned this does *not* guarantee the id is a team id, only that it may be.
   */
  public tryParsePotentialTeamId(conversationId: string): string | null {
    const parts = conversationId.split("_");
    if (parts.length !== 2) return null;
    return parts[1];
  }

  /**
   * Returns true if the user with `userId` is a member of the team associated with `conversationId`.
   *
   * Note: this method assumes the given conversationId is a TeamChat
   */
  public isUserMemberOfTeamForTeamChat(
    userId: string,
    conversationId: string
  ): boolean {
    const parts = conversationId.split("_");
    if (parts.length !== 2) throw Error("Invalid TeamChat conversationId");
    return parts[0] !== userId;
  }

  private mapUserIdsToParticipants(
    userIds: string[]
  ): CreateParticipantModel[] {
    return userIds.map((userId) => ({ userId }));
  }

  private mapTeamIdsToParticipants(
    teamIds: string[]
  ): CreateParticipantModel[] {
    return teamIds.map((teamId) => ({ teamId }));
  }

  private getCreateChatModel(
    participantId: string,
    type: ConversationType.Chat | ConversationType.SelfChat
  ): CreateConversationModel {
    return {
      id: this.createChatId(participantId),
      participants: this.mapUserIdsToParticipants([participantId]),
      type,
    };
  }

  private getCreateTeamChatModel(
    teamId: string,
    userId?: string
  ): CreateConversationModel {
    return {
      id: this.createTeamChatId(teamId, userId),
      participants: [
        ...this.mapTeamIdsToParticipants([teamId]),
        ...this.mapUserIdsToParticipants([
          userId ?? this.userService.getUserId(true),
        ]),
      ],
      type: ConversationType.TeamChat,
    };
  }

  private createConversation(
    createConversationModel: CreateConversationModel
  ): Observable<ConversationModelV3> {
    const path = "/api/v2/Conversations";

    const body: CreateConversationModel = { ...createConversationModel };
    if (!body.createOnUtc) {
      body.createOnUtc = new Date().toISOString();
    }

    return this.apiService.post<ConversationModelV3>({ path, body });
  }

  public createChat(otherUserId: string): Observable<ConversationModelV3> {
    const currentUserId = this.userService.getUserId(true);
    let type =
      otherUserId === currentUserId
        ? ConversationType.SelfChat
        : ConversationType.Chat;
    const createConversationModel = this.getCreateChatModel(otherUserId, type);
    return this.createConversation(createConversationModel);
  }

  /**
   * Create a team chat. If no `userId` is specified then a chat between the current user and the specified `teamId`
   * will be created.
   */
  public createTeamChat(
    teamId: string,
    userId?: string
  ): Observable<ConversationModelV3> {
    const createConversationModel = this.getCreateTeamChatModel(teamId, userId);
    return this.createConversation(createConversationModel);
  }

  public createGroup(
    name: string,
    participantUserIds: string[] = []
  ): Observable<ConversationModelV3> {
    return this.createConversation({
      id: uuidv4(),
      name,
      participants: this.mapUserIdsToParticipants(participantUserIds),
      type: ConversationType.Group,
    });
  }

  public createCase(
    name: string,
    participantUserIds: string[] = [],
    patientData?: PatientDataModel
  ): Observable<ConversationModelV3> {
    return this.createConversation({
      id: uuidv4(),
      name,
      patientData,
      participants: this.mapUserIdsToParticipants(participantUserIds),
      type: ConversationType.Case,
    });
  }

  public createExternalChat(
    patientData: PatientDataModel
  ): Observable<ConversationModelV3> {
    return this.createConversation({
      id: uuidv4(),
      patientData,
      type: ConversationType.External,
    });
  }

  public updateConversationPatientData(
    conversationId: string,
    patientData: PatientDataModel
  ) {
    return this.updateConversationDetails(conversationId, { patientData });
  }

  public updateConversationDetails(
    conversationId: string,
    details: Partial<
      Pick<ConversationModel, "subject" | "name" | "patientData">
    >
  ) {
    const path = `/api/v3/Conversations/${conversationId}`;
    return this.apiService.patch<ConversationModel>({ path, body: details });
  }

  public isAlreadyAtConversationRoute(conversationId: string) {
    const path = this.router.url;
    return path.includes(`/conversations/${conversationId}`);
  }

  public navigateToConversation(
    conversationId: string,
    teamId: string | null = null
  ) {
    if (teamId) {
      this.router.navigate([
        "roles",
        teamId,
        "conversations",
        conversationId,
        "messages",
      ]);
    } else {
      this.router.navigate(["conversations", conversationId, "messages"]);
    }
  }

  public navigateToExternalConversation(conversationId: string) {
    this.router.navigate(["external", conversationId, "messages"]);
  }

  public uploadProfilePhoto(
    formData: FormData,
    conversationId: string
  ): Observable<string> {
    return this.apiService.request<string>("put", {
      body: formData,
      path: `/api/v3/conversations/${conversationId}/profilePhoto`,
    });
  }

  public removeProfilePhoto(conversationId: string): Observable<void> {
    return this.apiService.request("delete", {
      path: `/api/v3/conversations/${conversationId}/profilePhoto`,
    });
  }

  public sendMedia(
    conversationId: string,
    media: CreateMessageMediaModel[]
  ): Observable<MessageModel[]> {
    const path = `/api/v2/Conversations/${conversationId}/media/send`;
    return this.apiService.post({ path, body: media });
  }

  public sendPatientFiles(
    conversationId: string,
    patientFiles: CreateMessagePatientFileModel[]
  ): Observable<MessageModel[]> {
    const path = `/api/Conversations/${conversationId}/SendPatientFile`;
    return this.apiService.post({ path, body: patientFiles });
  }

  public sendMessage(conversationId: string, message: CreateMessageModel) {
    const path = `/api/v2/Conversations/${conversationId}/message`;
    return this.apiService.post<MessageModel>({ path, body: message });
  }

  public getUnreadConversationSkeletons(
    options?: GetUnreadSkeletonConversationRequestOptions
  ): Observable<ApiPagedResult<ConversationSkeletonModelV3>> {
    const path = `/api/v3/Conversations/unread`;
    return this.apiService.get({ path, queryParams: { ...options } });
  }

  public clearUnread(
    conversationId: string,
    options: ClearUnreadConversationModelV3
  ): Observable<ConversationReadToUtcUpdateResponseModel> {
    const path = `/api/v3/Conversations/${conversationId}/clearunread`;
    return this.apiService.post({ path, body: options });
  }

  public updateConversationRole(
    userId: string,
    conversationId: string,
    role: ParticipantRole
  ) {
    const path = `/api/Conversations/${conversationId}/Participants/Roles`;

    const roleUpdateArray = [
      {
        userId,
        role,
      },
    ];

    return this.apiService.post<ConversationModelV3>({
      path,
      body: roleUpdateArray,
    });
  }

  public getParticipant(
    conversationId: string,
    userId: string
  ): Observable<ConversationParticipantModelV3> {
    const path = `/api/v3/conversations/${conversationId}/participants/${userId}`;
    return this.apiService.post<ConversationParticipantModelV3>({ path });
  }

  public addParticipants(conversationId: string, participantUserIds: string[]) {
    const path = `/api/Conversations/${conversationId}/Participants`;
    const payload = participantUserIds.map((userId) => ({ userId }));

    return this.apiService.post<ConversationModelV3>({ path, body: payload });
  }

  public removeParticipant(userId: string, conversationId: string) {
    const path = `/api/Conversations/${conversationId}/Participants`;
    const userIds = [{ userId }];

    return this.apiService.delete<ConversationModelV3>({ path, body: userIds });
  }

  public getConversationProfileUri(
    conversationId: string,
    photoId: string
  ): string {
    return `/api/v2/Conversations/${conversationId}/media/${photoId}`;
  }

  public leaveConversation(conversationId: string) {
    const path = `/api/v3/Conversations/${conversationId}/Leave`;
    return this.apiService.post<ConversationModelV3>({ path });
  }

  public createAndNavigateToChatConversation(recipientUserId: string) {
    this.createChat(recipientUserId).subscribe({
      next: (data) => {
        this.router.navigateByUrl(`/conversations/${data?.id}/messages`);
      },
    });
  }

  public pinConversation(conversationId: string) {
    const path = `/api/v2/Conversations/${conversationId}/pin`;
    return this.apiService.post<void>({ path });
  }

  public unpinConversation(conversationId: string) {
    const path = `/api/v2/Conversations/${conversationId}/unpin`;
    return this.apiService.post<void>({ path });
  }

  public archiveConversation(conversationId: string): Observable<void> {
    const path = `/api/Conversations/${conversationId}/Hide`;
    return this.apiService.post<void>({ path });
  }

  public getMessage(conversationId: string, messageId: string) {
    const path = `/api/v2/Conversations/${conversationId}/messages/${messageId}`;
    return this.apiService.get<MessageModel>({
      path,
    });
  }

  public getMessages({ conversationId, ...options }: MessagesV2RequestOptions) {
    const path = `/api/v2/Conversations/${conversationId}/messages`;
    return this.apiService.get<MessageModel[]>({
      path,
      queryParams: { ...options },
    });
  }

  public deleteMessage(conversationId: string, messageId: string) {
    const path = `/api/v2/Conversations/${conversationId}/messages/${messageId}`;
    return this.apiService.delete<MessageModel>({
      path,
    });
  }

  public updateMessageStatuses(
    conversationId: string,
    statuses: MessageStatusUpdate[]
  ) {
    const path = `/api/v3/Conversations/${conversationId}/messages/statuses`;
    return this.apiService.post<MessageModel[]>({
      path,
      body: statuses,
    });
  }

  public exportToPdf(
    conversationId: string
  ): Observable<{ operationId: string }> {
    const path = `/api/v3/Conversations/${conversationId}/export`;
    let res = this.apiService.post<{ operationId: string }>({ path }).pipe(
      tap({
        next: (r) => {
          this.lastOpIds.add(r.operationId);
        },
      })
    );

    return res;
  }

  public downloadExportedPdf(conversationId: string) {
    const path = `/api/v3/Conversations/${conversationId}/export`;
    this.apiService
      .request("get", { path }, { responseType: "blob" })
      .subscribe((b) => {
        saveAs(b, conversationId);
      });
  }

  public isConversationMutedForParticipant(
    participant: ConversationParticipantModelV2
  ): boolean {
    if (!participant.mutedToUtc) return false;
    return new Date(participant.mutedToUtc) >= new Date();
  }

  public getConversationName(conversation: ConversationModelV3): string {
    return getConversationName(conversation, this.userService.getUserId());
  }

  public muteConversation(
    conversationId: string,
    muteInterval: number
  ): Observable<ConversationParticipantModel> {
    const path = "/api/conversations/mute";

    // This returns an array of conversation models, but there is always only one conversation, and all properties are
    // null/default except the participants field which is populated with a single item (the current user)
    return this.apiService
      .post<ConversationModel[]>({
        path,
        body: [{ conversationId, muteInterval }], // Note: yes, this does need to be an array.
      })
      .pipe(map((conversations) => conversations[0].participants[0]));
  }

  public unmuteConversation(
    conversationId: string
  ): Observable<ConversationParticipantModel> {
    return this.muteConversation(conversationId, 0);
  }

  public createInvitation(
    conversationId: string
  ): Observable<ConversationInvitationModel> {
    const path = `/api/v2/conversations/${conversationId}/invitation`;
    return this.apiService.post({
      path,
    });
  }

  public updateInvitation(
    conversationId: string,
    update: UpdateConversationInvitationModel
  ): Observable<ConversationInvitationModel> {
    const path = `/api/v2/conversations/${conversationId}/invitation`;
    return this.apiService.put({
      path,
      body: update,
    });
  }
}
