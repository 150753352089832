import { MessageInputErrorCode } from "./message-input.component";

export class ValidationError extends Error {
    code: MessageInputErrorCode

    public constructor(code: MessageInputErrorCode) {
        super(`ValidationError: ${code}`);
        this.code = code;
    }
}
