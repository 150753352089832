import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  GetSuggestionsRequestOptions,
  GetUserPictureRequestOptions,
  SuggestedUserModel,
  UserProfileWithAllWorkspacesModel,
  UserPublicProfileModel,
  BasicContactModelPagedResult,
  UsersV2RequestOptions,
  ApiRequestOptions,
} from "types";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  public constructor(private apiService: ApiService) {}

  public getPicture({
    userId,
    ...options
  }: GetUserPictureRequestOptions): Observable<Blob> {
    const path = `/api/User/${userId}/Picture`;
    return this.apiService.request(
      "get",
      {
        path,
        queryParams: { ...options },
      },
      {
        responseType: "blob",
      }
    );
  }

  public getUser(
    userId: string
  ): Observable<UserProfileWithAllWorkspacesModel> {
    const path = `/api/v2/Users/${userId}`;
    return this.apiService.get({ path });
  }

  public getUserProfile(userId: string): Observable<UserPublicProfileModel> {
    const path = `/api/v2/Users/${userId}/profile`;
    return this.apiService.get({ path });
  }

  public getSuggestions(
    options: GetSuggestionsRequestOptions = {}
  ): Observable<SuggestedUserModel[]> {
    const path = `/api/v2/Users/suggestions`;
    return this.apiService.get({
      path,
      queryParams: { ...options },
    });
  }

  public getUsers({
    fetchAll,
    ...options
  }: UsersV2RequestOptions &
    ApiRequestOptions = {}): Observable<BasicContactModelPagedResult> {
    const path = `/api/v2/Users`;
    if (fetchAll) {
      return this.apiService.getAllByOffset({
        path,
        queryParams: { ...options },
      });
    }
    return this.apiService.get({
      path,
      queryParams: { ...options },
    });
  }
}
