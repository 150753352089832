import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { Observable, ReplaySubject } from "rxjs";
import { environment } from "@env";
import {
  CompanyBasicModel,
  CompanyBasicModelApiPagedResult,
  ContactModel,
  FullUserProfileModel,
} from "@types";
import { AlertService } from "./alert.service";
import { AuthService } from "../services/auth.service";
import { SharedService } from "./shared.service";

/** @deprecated Use the new `AccountService` in `CoreModule`. */
@Injectable()
export class UserAccountService {
  public profilePicChangeSubject = new ReplaySubject<boolean>(1);
  public accountSubject = new ReplaySubject<FullUserProfileModel>(1);
  public userAccount: FullUserProfileModel | null = null;
  dialogRef: any;
  titles = [
    {
      display: "Mr.",
      value: "Mr.",
    },
    {
      display: "Mrs.",
      value: "Mrs.",
    },
    {
      display: "Miss.",
      value: "Miss.",
    },
    {
      display: "Ms.",
      value: "Ms.",
    },
    {
      display: "Dr.",
      value: "Dr.",
    },
    {
      display: "Prof.",
      value: "Prof.",
    },
    {
      display: "No title",
      value: null,
    },
  ];

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private analytics: AngularFireAnalytics
  ) {
    this.authService.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.getAccount().subscribe((account) => {
          this.accountSubject.next(account);
        });
      } else {
        this.clearMemory();
      }
    });
  }

  clearMemory() {
    this.userAccount = null;
  }

  shareProfile() {
    const payload = [
      {
        type: "shareMyProfile",
      },
    ];
    this.alertService.customDialog("", "", "", "", false, "", payload);
  }

  doNotDisturbChange(interval, callback) {
    const path = environment.celoApiEndpoint + "/api/Account/DoNotDisturb";
    const params = {
      doNotDisturbInterval: interval,
    };
    this.sharedService.postObjectById(path, {}, params).subscribe((resp) => {
      if (resp) {
        this.userAccount["doNotDisturbToUtc"] = resp["doNotDisturbToUtc"];
        this.userAccount["doNotDisturb"] = this.isOnDND(this.userAccount);
      }
      callback(resp);
      // this.doNotDisturb = false;
    });
  }

  isOnDND(userAccount) {
    if (userAccount && userAccount["doNotDisturbToUtc"]) {
      const until = new Date(userAccount["doNotDisturbToUtc"]);
      const now = new Date();
      if (until > now) {
        return true;
      }
      return null;
    }
    return null;
  }

  isMentionNotificationEnabled() {
    if (this.userAccount && this.userAccount["isMentionNotificationEnabled"]) {
      return true;
    }
    return false;
  }

  getPrivacy(privacy: any[], privacyName, settingName): any {
    let result = false;
    for (const item of privacy) {
      if (item["name"] == privacyName) {
        const setting = item["settings"][settingName];
        result = !setting || setting == "false" ? false : true;
      }
    }
    return result;
  }

  isOnDNDIndefinite(untilTime?) {
    if (!this.userAccount && !untilTime) {
      return false;
    }
    untilTime = untilTime ? untilTime : this.userAccount["doNotDisturbToUtc"];
    if (untilTime) {
      const until = new Date(untilTime);
      const max = new Date(33142190097000); // 1000 years in the future
      if (until > max) {
        return true;
      }
      return null;
    }
    return false;
  }

  getAccount(): Observable<FullUserProfileModel> {
    const path = `${environment.celoApiEndpoint}/api/v2/user`;
    return this.sharedService.getObjectById(path);
  }

  getUserAccount(
    important?: boolean,
    callback: (userAccount: FullUserProfileModel | null) => void = () => {}
  ): void {
    if (!this.authService.isAuthenticated()) {
      callback(null);
      return;
    }

    if (this.userAccount && this.userAccount.userId && !important) {
      callback(this.userAccount);
      return;
    }

    this.getAccount().subscribe({
      next: (userAccount) => {
        this.userAccount = userAccount;
        if (callback) callback(userAccount);
      },
      error: (e) => callback(null),
    });
  }

  getValidLoginEmails(profile: any) {
    const validEmails = [];
    if (profile) {
      if (profile.email) {
        validEmails.push(profile.email);
      }
      if (profile["companies"]) {
        for (const company of profile["companies"]) {
          if (company.email) {
            validEmails.push(company.email);
          }
        }
      }
    }
    return validEmails;
  }

  editCompany(workspace, companies, callback) {
    const title = "Edit Workspace";
    const confirmButtonText = "Save ";
    const cancelButtonText = "Cancel";
    // let isLastWorkspace = (companies&&companies.length==1)?true:false;
    const payload = [
      {
        type: "addCompany",
        // workspaceSubtitle:'Please select your main workspace. You can add other workspaces later in the app.',
        data: workspace,
      },
    ];
    this.dialogRef = this.alertService.customDialog(
      title,
      "",
      confirmButtonText,
      cancelButtonText,
      false,
      "",
      payload
    );
    const i = this;
    this.dialogRef.afterClosed().subscribe((result) => {
      // return;
      if (result) {
        if (result.type == "delete") {
          this.deleteCompany(result.workspace, companies, function (data) {
            if (data && !data.type) {
              data.type = "delete";
            }
            callback(data);
            i.getUserAccount(true, function () {});
          });
        } else if (result.type == "save") {
          this.patchCompany(result.payload, function (data) {
            callback(data);
            i.getUserAccount(true, function () {});
          });
        }
      }
    });
  }

  filterWorkspaceByEmail(email, workspaces) {
    const res = [];
    for (const wp of workspaces) {
      if (wp.email == email) {
        res.push(wp);
      }
    }
    return res;
  }

  checkIfCanRemoveWorkspaceSafely(workspace, workspaces) {
    if (workspace.email != this.userAccount.email) {
      return true;
    }
    const workspacesWithMatchingEmail = this.filterWorkspaceByEmail(
      workspace.email,
      workspaces
    );
    if (workspacesWithMatchingEmail && workspacesWithMatchingEmail.length > 1) {
      return true;
    }

    return false;
  }

  deleteCompany(workspace, workspaces, callback?) {
    const canRemoveWorkspaceSafely = this.checkIfCanRemoveWorkspaceSafely(
      workspace,
      workspaces
    );

    const payload = [
      {
        type: "updateEmail",
      },
    ];

    if (!canRemoveWorkspaceSafely) {
      const dialogRef = this.alertService.customDialog(
        "Update primary email",
        "Your primary email is linked to this workspace. Do you want to update it before you leave?",
        "",
        "",
        false,
        "",
        payload,
        true
      );

      dialogRef.afterClosed().subscribe((result) => {
        if (!result) {
          return;
        }
        if (result.type == "update_and_leave") {
          callback({
            type: "update_and_leave",
            workspace,
          });
        } else if (result.type == "leave") {
          this.deleteWorkspace(workspace, false, callback);
        }
      });
      return;
    }
    this.deleteWorkspace(workspace, true, callback);
  }

  deleteWorkspace(workspace, needConfirmation, callback) {
    const path =
      environment.celoApiEndpoint +
      `/api/v2/account/workplaces/` +
      workspace.companyId;
    if (!needConfirmation) {
      this.sharedService
        .deleteObjectById(path, { companyId: workspace.companyId })
        .subscribe(
          (data) => {
            if (data) {
              callback(data);
            }
          },
          (err) => {
            this.alertService.confirm(
              "",
              this.sharedService.STANDARD_ERROR_MESSAGE,
              "OK",
              "",
              true
            );
            callback(null);
          }
        );
      return;
    }
    this.alertService
      .confirm(
        "Leave workspace?",
        "Are you sure you want to leave " + workspace.companyName + "?",
        "LEAVE",
        "CANCEL"
      )
      .subscribe((result) => {
        if (result) {
          this.sharedService
            .deleteObjectById(path, { companyId: workspace.companyId })
            .subscribe(
              (data) => {
                if (data) {
                  callback(data);
                }
              },
              (err) => {
                this.alertService.confirm(
                  "",
                  this.sharedService.STANDARD_ERROR_MESSAGE,
                  "OK",
                  "",
                  true
                );
                callback(null);
              }
            );
        }
      });
  }

  patchCompany(company, callback?) {
    if (!company) {
      return;
    }
    const path =
      environment.celoApiEndpoint +
      `/api/v2/account/workplaces/` +
      company.companyId;
    const patch = [];

    const departmentPatch = {
      value: company.departmentId ? company.departmentId : "",
      path: "/departmentId",
      op: "add",
    };
    const positionPatch = {
      value: company.position ? company.position : "",
      path: "/position",
      op: "add",
    };

    patch.push(departmentPatch);
    patch.push(positionPatch);

    this.sharedService.patchObjectById(path, patch).subscribe(
      (data) => {
        if (data) {
          callback(data);
          this.alertService.showSnackBar("Saved", 2);
        }
      },
      (err) => {
        callback(null);
      }
    );
  }

  editPreferredName(preferredName, callback?) {
    const title = "Preferred Name";
    const confirmButtonText = "Save ";
    const cancelButtonText = "Cancel";
    const payload = [
      {
        type: "editField",
        data: {
          value: preferredName,
          fieldName: "Preferred Name",
          key: "firstName",
          maxlength: 45,
        },
      },
      {
        type: "text",
        data: "‘Preferred name’ is the name you are known by. Your preferred name will be used throughout the Celo app instead of your first legal name.",
      },
    ];
    this.dialogRef = this.alertService.customDialog(
      title,
      "",
      confirmButtonText,
      cancelButtonText,
      false,
      "",
      payload
    );
    const i = this;
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result && result.payload) {
        this.save(result.payload, callback);
      }
    });
  }

  editTitle(userTitle, callback?) {
    const title = "Title";
    const confirmButtonText = "Save ";
    const cancelButtonText = "Cancel";
    const payload = [
      {
        type: "selectField",
        options: this.titles,
        data: {
          value: userTitle,
          fieldName: "Title",
          key: "title",
        },
      },
      {
        type: "text",
        data: "",
      },
    ];
    this.dialogRef = this.alertService.customDialog(
      title,
      "",
      confirmButtonText,
      cancelButtonText,
      false,
      "",
      payload
    );
    const i = this;
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result && result.payload) {
        this.save(result.payload, callback);
      }
    });
  }

  editFullName(firstName, lastName, callback?) {
    const title = "Full name";
    const confirmButtonText = "Save ";
    const cancelButtonText = "Cancel";
    const payload = [
      {
        type: "editField",
        data: {
          value: firstName,
          fieldName: "First Name",
          key: "legalFirstName",
          maxlength: 45,
        },
      },
      {
        type: "editField",
        data: {
          value: lastName,
          fieldName: "Last Name",
          key: "lastName",
          maxlength: 45,
        },
      },
      {
        type: "text",
        data: "Please use your full legal name to help verify your identity. You can verify your identity using the mobile app.",
      },
    ];
    this.dialogRef = this.alertService.customDialog(
      title,
      "",
      confirmButtonText,
      cancelButtonText,
      false,
      "",
      payload
    );
    const i = this;
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result && result.payload) {
        result.payload["legalFirstNameOld"] = firstName;
        result.payload["lastNameOld"] = lastName;
        callback(result.payload);
        // this.save(result.payload,callback)
      }
    });
  }

  save(payload, callback) {
    const patch = [];
    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(payload, key)) {
        const value = payload[key];
        patch.push({
          value,
          path: "/" + key,
          op: "add",
        });
      }
    }
    const path = `${environment.celoApiEndpoint}/api/v2/account`;
    this.sharedService.patchObjectById(path, patch).subscribe(
      (data) => {
        this.alertService.showSnackBar("Saved", 3, "black");
        if (data && callback) {
          callback(data);
        }
      },
      (err) => {
        let message = this.sharedService.STANDARD_ERROR_MESSAGE;
        const title = this.sharedService.STANDARD_ERROR_MESSAGE;
        if (err && err.error) {
          for (const key in err.error) {
            if (Object.prototype.hasOwnProperty.call(err.error, key)) {
              const element = err.error[key];
              message = element[0];
            }
          }
        }
        message = message["message"] ? message["message"] : message;
        this.alertService.alert(title, message, true);
      }
    );
  }

  verifyNumber(countryCode, phoneNumber, callback?) {
    const path = environment.celoApiEndpoint + "/api/Account/Phone/Verify";
    const params = {
      countryCode,
      phoneNumber,
    };

    const lastMessageTime = this.sharedService.getLastSuccessfulMessageTime();
    if (lastMessageTime) {
      const previous = new Date(lastMessageTime);
      const now = new Date();
      if ((now.getTime() - previous.getTime()) / 1000 < 60) {
        if (callback) {
          callback(null);
        }
        return this.alertService.showSnackBar(
          "Please wait " +
            (60 - Math.floor((now.getTime() - previous.getTime()) / 1000)) +
            " seconds to send another code",
          2
        );
      }
    }
    this.sharedService.postObjectById(path, {}, params).subscribe(
      (resp) => {
        this.sharedService.setLastSuccessfulMessageTime();
        if (callback) {
          callback(resp);
        }
      },
      (err) => {
        let message = this.sharedService.STANDARD_ERROR_MESSAGE;
        const error =
          err.error && err.error.errors ? err.error.errors[0] : null;
        if (error) {
          if (error.code == 429001) {
            message = "Please try again later.";
          } else if (error.code == 429002) {
            message =
              "We're unable to verify your phone number. Please contact support@celohealth.com.";
          }
        }
        this.alertService.confirm("", message, "", "Cancel", false);
        if (callback) {
          callback(null);
        }
      }
    );
  }

  verifyEmail(email, callback?) {
    const path =
      environment.celoApiEndpoint + "/api/Account/EmailVerifications";
    const params = {
      email,
    };

    const lastMessageTime = this.sharedService.getLastSuccessfulEmailTime();
    if (lastMessageTime && !this.sharedService.canSendEmail(lastMessageTime)) {
      if (callback) {
        callback(null);
      }
      return;
    }
    this.sharedService.postObjectById(path, {}, params).subscribe(
      (resp) => {
        this.sharedService.setLastSuccessfulEmailTime();
        if (callback) {
          callback(resp);
        }
      },
      (err) => {
        const title = this.sharedService.STANDARD_ERROR_MESSAGE;

        const defaultMessage = this.sharedService.STANDARD_ERROR_MESSAGE;
        let message = this.sharedService.getErrorMessageFromError(err);
        message = message
          ? message
          : this.sharedService.getOnboardingErrorMessage(err);
        message = message
          ? message
          : this.sharedService.getPasswordErrorMessage(err);
        message = message ? message : defaultMessage;

        err = err.error ? err.error : err;
        message = err.message ? err.message : message;
        this.alertService.confirm(title, message, "", "Cancel", false);
        if (callback) {
          callback(null);
        }
      }
    );
  }

  onAccountCreated(accountCreateData, userProfile) {
    const toStore = {
      verificationId: accountCreateData["verificationId"],
      email: accountCreateData["email"],
      userId: userProfile.userId ? userProfile.userId : "no userId",
    };
    localStorage.setItem("onboard_info", JSON.stringify(toStore));
    this.analytics.logEvent("sign_up");
  }

  onAfterSettingPin() {
    // this.userFirstInsideTheApp();
  }

  userFirstInsideTheApp(userId?: string) {
    const onboardInfo = localStorage.getItem("onboard_info");
    if (!onboardInfo) {
      this.sharedService.navigateTo("/conversations");
      return;
    }
    const onboard_info = JSON.parse(onboardInfo);
    if (
      onboard_info["email"] &&
      onboard_info["verificationId"] &&
      onboard_info["userId"] &&
      userId == onboard_info["userId"]
    ) {
      this.analytics.logEvent("first_login");
      this.sharedService.navigateTo("/onboard/workspace-add", {
        email: onboard_info["email"],
        verificationId: onboard_info["verificationId"],
      });
    } else {
      this.sharedService.navigateTo("/conversations");
    }
  }

  getCompanies(
    email?: string,
    callback?: (companies: CompanyBasicModel[]) => void
  ) {
    const page = "0";
    const pageSize = "1000";

    const path = `${environment.celoApiEndpoint}/api/v2/Companies`;
    const params = {
      Page: page,
      PageSize: pageSize,
    };

    if (email) {
      params["EmailDomain"] = email;
    }

    return this.sharedService
      .getObjectById<CompanyBasicModelApiPagedResult>(path, params)
      .subscribe((companies) => {
        if (companies?.data) {
          callback(companies.data);
        }
      });
  }

  getProfessions(callback?) {
    const page = "0";
    const pageSize = "1000";
    const path = `${environment.celoApiEndpoint}/api/settings/professions`;
    const params = {
      Page: page,
      PageSize: pageSize,
    };
    return this.sharedService.getObjectById(path, params).subscribe(
      (professions: any) => {
        // //to remove
        // for (const profession of professions) {
        //   profession.canVerify = true;
        //   if(profession['name']=="Non-healthcare Professional"){
        //     profession.canVerify = false;
        //   }
        // }

        callback(professions);
      },
      (err) => {
        callback(undefined);
      }
    );
  }

  isInTheSameNetwork(contact: ContactModel, myCompaniesAndPartnerCompanies) {
    let hasCommanWorkspace = this.sharedService.checkCommonWorkspace(
      contact["workplaces"],
      myCompaniesAndPartnerCompanies
    );
    return (
      (contact["connection"] && contact["connection"].state == "Accepted") ||
      hasCommanWorkspace
    );
  }
}
