<div class="page-title appear_1">Get started on Celo</div>
<div class="card-container">
  <div *ngFor="let card of cards; let index = index" class="cta-card">
    <div
      matRipple
      [matRippleColor]="'rgba(10,10,10,0.03)'"
      class="cta-card-button celo-cursor-pointer"
      (click)="openActions(card.actions, card.id)"
    >
      <img [src]="card.image" alt="" />
      <div class="title">{{ card.title }}</div>
      <div class="description">{{ card.description }}</div>
    </div>
  </div>
</div>
