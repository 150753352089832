import { Component, OnInit } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { Router } from "@angular/router";
import { AuthService } from "@modules/core";
import { Store } from "@ngrx/store";
import { AuthActions } from "app/state/auth";

@Component({
  selector: "app-logout-oidc",
  templateUrl: "./logout-oidc.component.html",
  styleUrls: ["./logout-oidc.component.scss"],
})
export class LogoutOidcComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private analytics: AngularFireAnalytics,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit() {
    this.store.dispatch(AuthActions.logoutSuccess());

    this.analytics.logEvent("sign_out");
    this.analytics.logEvent("logout");
    this.authService.afterLogout();
    this.router.navigateByUrl("/");
  }
}
