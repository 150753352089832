import { Injectable, NgZone } from "@angular/core";
import { concatNotNull } from "@utils";
import ZoomVideo, {
  ActiveSpeaker,
  ErrorTypes,
  ExecutedFailure,
  MediaDevice,
  Participant,
  Stream,
  VideoClient,
} from "@zoom/videosdk";
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  concat,
  defer,
  last,
  pairwise,
  retry,
  tap,
  throwError,
} from "rxjs";
import { map, startWith } from "rxjs/operators";
import { ErrorDescriptor } from "utils/error-utils";

/**
 * The Zoom SDK seems to have a typo with one of it's errors types (OPRATION_LOCKED), so to be safe the probably
 * correct value is added here.
 *
 * The zoom SDK can throw other errors besides the ones included in `ErrorTypes`, for example, `startAudio` can
 * throw `USER_FORBIDDEN_MICROPHONE`
 */
export type ZoomErrorType =
  | ErrorTypes
  | "OPERATION_LOCKED"
  | "USER_FORBIDDEN_MICROPHONE"
  | "MISSING_SYSTEM_REQUIREMENTS"
  | "CAN_NOT_DETECT_CAMERA"
  | "CAN_NOT_FIND_CAMERA"
  | "VIDEO_USER_FORBIDDEN_CAPTURE"
  | "VIDEO_ESTABLISH_STREAM_ERROR"
  | "VIDEO_CAMERA_IS_TAKEN"
  | "INVALID_DEVICE_ID";

/**
 * These are not true error types thrown by Zoom, but are added to help us identity some errors more easily where
 * the Zoom SDK does not.
 */
export type CustomZoomErrorType = "INVALID_OPERATION_CAMERA_IS_STARTING";

const zoomErrorReasonMap: {
  [P in ZoomErrorType]?: {
    [reason: string]: CustomZoomErrorType;
  };
} = {
  INVALID_OPERATION: {
    "Camera is starting,please wait.": "INVALID_OPERATION_CAMERA_IS_STARTING",
  },
};

export const zoomErrorMap: {
  [P in ZoomErrorType | CustomZoomErrorType]: ErrorDescriptor | null;
} = {
  INVALID_OPERATION: null,
  INTERNAL_ERROR: null,
  OPERATION_TIMEOUT: null,
  INSUFFICIENT_PRIVILEGES: {
    title: "Enable Camera and Microphone Permission",
    message:
      "Permission to access your camera and microphone is required to use this feature.",
  },
  IMPROPER_MEETING_STATE: null,
  INVALID_PARAMETERS: null,
  OPRATION_LOCKED: null,
  OPERATION_LOCKED: null,
  MISSING_SYSTEM_REQUIREMENTS: {
    title: "System Requirements Error",
    message:
      "Your device does not meet the system requirements to use this feature.",
  },
  USER_FORBIDDEN_MICROPHONE: {
    title: "Enable Microphone Permission",
    message:
      "Permission to access your microphone is required to use this feature.",
  },
  CAN_NOT_DETECT_CAMERA: null,
  CAN_NOT_FIND_CAMERA: null,
  VIDEO_USER_FORBIDDEN_CAPTURE: {
    title: "Enable Camera Permission",
    message: "Permission to access your camera is required to enable video.",
  },
  VIDEO_ESTABLISH_STREAM_ERROR: null,
  VIDEO_CAMERA_IS_TAKEN: {
    title: "Camera Already In Use",
    message:
      "Your device's camera is already in use by another application. Please stop any other applications using your device's camera and enable video or rejoin the call to resolve the issue.",
  },
  INVALID_OPERATION_CAMERA_IS_STARTING: null,
  INVALID_DEVICE_ID: null,
};

export const zoomErrorTypes = new Set(
  Object.keys(zoomErrorMap) as ZoomErrorType[]
);

export const isExecutedFailure = (value: unknown): value is ExecutedFailure => {
  if (typeof value !== "object" || Array.isArray(value)) return false;
  // The Zoom SDK is incorrectly typed. ExecutedFailures are thrown by various methods, e.g. startVideo without
  // a reason property, so checking for the 'reason' property is not done. The SDK can also throw ExecutedFailures
  // with values that are not documented under `ErrorType`.
  return Object.hasOwn(value, "type");
};

export class ZoomError extends Error {
  public type: ZoomErrorType | CustomZoomErrorType | null = null;
  public reason: string | null = null;

  private constructor(
    type: ZoomErrorType | CustomZoomErrorType | null,
    reason?: string | null
  ) {
    super();
    this.name = "ZoomError";
    this.message = concatNotNull([type, reason]);
    this.type = type;
    this.reason = reason ?? null;
  }

  public getErrorDescriptor(): ErrorDescriptor | null {
    if (!this.type) return null;
    return zoomErrorMap[this.type] ?? null;
  }

  public static fromType(errorType: ZoomErrorType) {
    return new ZoomError(errorType);
  }

  public static fromExecutedFailure(failure: ExecutedFailure) {
    const alternateType = zoomErrorReasonMap[failure.type]?.[failure.reason];
    return new ZoomError(alternateType ?? failure.type, failure.reason);
  }
}

export interface JoinCallModel {
  sessionName: string;
  token: string;
  userName: string;
}

export interface VideoCell {
  xPos: number;
  yPos: number;
}

export interface VideoLayout {
  cellWidth: number;
  cellHeight: number;
  cells: VideoCell[];
}

export interface CurrentUserAudioVideoStatus {
  isMicOn: boolean;
  isVideoOn: boolean;
}

export enum ParticipantEvent {
  UserAdded = "user-added",
  UserRemoved = "user-removed",
  UserUpdated = "user-updated",
}

export enum ParticipantDataUpdateTrigger {
  Init = "init",
}

export interface ZoomParticipantData {
  triggeredBy: ParticipantDataUpdateTrigger | ParticipantEvent;
  participants: Participant[];
}

@Injectable({
  providedIn: "root",
})
export class ZoomService {
  private client: typeof VideoClient | null = null;
  private stream: null | typeof Stream = null;

  private allParticipantsSubject =
    new BehaviorSubject<ZoomParticipantData | null>(null);
  public allParticipants$ = this.allParticipantsSubject.asObservable();

  private activeSpeakersSubject = new Subject<ActiveSpeaker[]>();
  public activeSpeakers$ = this.activeSpeakersSubject.asObservable();

  public lastActiveSpeaker$: Observable<ActiveSpeaker | null> =
    this.activeSpeakers$.pipe(
      startWith([] as ActiveSpeaker[]),
      pairwise(),
      map(([previous, current]) => {
        const previousUserIds = new Set(previous.map((p) => p.userId));
        const newSpeakers = current.filter(
          (s) => !previousUserIds.has(s.userId)
        );
        const speakers = newSpeakers.length ? newSpeakers : current;
        return speakers.length ? speakers[0] : null;
      })
    );

  private isAudioMutedSubject = new BehaviorSubject<boolean>(true);
  public isAudioMuted$ = this.isAudioMutedSubject.asObservable();

  private isCapturingVideoSubject = new BehaviorSubject<boolean>(false);
  public isCapturingVideo$ = this.isCapturingVideoSubject.asObservable();

  private CurrentUserAudioVideoStatusSubject =
    new BehaviorSubject<CurrentUserAudioVideoStatus>({
      isMicOn: false,
      isVideoOn: false,
    });
  public currentUserAudioVideoStatus$ =
    this.CurrentUserAudioVideoStatusSubject.asObservable();

  private microphoneListSubject = new BehaviorSubject<MediaDevice[]>([]);
  public microphoneList$ = this.microphoneListSubject.asObservable();

  private speakerListSubject = new BehaviorSubject<MediaDevice[]>([]);
  public speakerList$ = this.speakerListSubject.asObservable();

  constructor(private ngZone: NgZone) {}

  public joinSession = ({ sessionName, token, userName }: JoinCallModel) => {
    return this.ngZone.runOutsideAngular(() => {
      const { audio, video } = ZoomVideo.checkSystemRequirements();
      if (!audio || !video)
        return throwError(() =>
          ZoomError.fromType("MISSING_SYSTEM_REQUIREMENTS")
        );

      const leaveObservable = this.isInMeeting()
        ? defer(() => this.client.leave()).pipe(
            retry(3),
            catchError(() => EMPTY)
          )
        : EMPTY;

      const initZoomClientObservable = defer(async () => {
        ZoomVideo.destroyClient();
        this.client = ZoomVideo.createClient();
        try {
          await this.client.init("en-US", "Global", {
            patchJsMedia: true,
            stayAwake: true,
            leaveOnPageUnload: true,
          });
        } catch (err) {
          console.error("Failed to init VideoClient", err);
          throw ZoomError.fromExecutedFailure(err);
        }
        this.initEventListeners();
      }).pipe(retry(3));

      return concat(
        leaveObservable,
        initZoomClientObservable,
        defer(() => this.client.join(sessionName, token, userName)),
        defer(async () => {
          const isHardwareAccelerationEnabled = await this.client
            .getMediaStream()
            .enableHardwareAcceleration(true);
          console.log(
            "isHardwareAccelerationEnabled",
            isHardwareAccelerationEnabled
          );
        })
      ).pipe(
        last(),
        tap({
          next: () => {
            this.stream = this.client.getMediaStream();
            this.updateParticipants(ParticipantDataUpdateTrigger.Init);
          },
        })
      );
    });
  };

  public requestPermissions() {
    return defer(() => ZoomVideo.getDevices()).pipe(
      catchError((error) =>
        throwError(() => ZoomError.fromType("INSUFFICIENT_PRIVILEGES"))
      )
    );
  }

  // For best practices around enabling audio see: https://developers.zoom.us/docs/video-sdk/web/best-practices/#audio
  public startAudio = () => {
    return defer(async () => {
      try {
        await this.stream.startAudio({
          backgroundNoiseSuppression: true,
          mute: false,
        });
      } catch (err) {
        if (isExecutedFailure(err)) throw ZoomError.fromExecutedFailure(err);
        throw err;
      }
    }).pipe(
      tap(() => {
        this.isAudioMutedSubject.next(this.stream.isAudioMuted());
      })
    );
  };

  public muteAudio = () => {
    return defer(() => this.stream.muteAudio()).pipe(
      tap(() => {
        this.isAudioMutedSubject.next(this.stream.isAudioMuted());
      })
    );
  };

  public unmuteAudio = () => {
    return defer(() => this.stream.unmuteAudio()).pipe(
      tap(() => {
        this.isAudioMutedSubject.next(this.stream.isAudioMuted());
      })
    );
  };

  public toggleAudio = () => {
    return defer(() => {
      return this.stream.isAudioMuted() ? this.unmuteAudio() : this.muteAudio();
    });
  };

  public getActiveMicrophoneDevice() {
    return this.stream.getActiveMicrophone();
  }

  public getActiveSpeakerDevice() {
    return this.stream.getActiveSpeaker();
  }

  public setActiveMicrophone(microphoneId: string) {
    return defer(async () => {
      try {
        await this.stream.switchMicrophone(microphoneId);
      } catch (err) {
        if (isExecutedFailure(err)) throw ZoomError.fromExecutedFailure(err);
        throw err;
      }
    });
  }

  public setActiveSpeaker(speakerId: string) {
    return defer(async () => {
      try {
        await this.stream.switchSpeaker(speakerId);
      } catch (err) {
        if (isExecutedFailure(err)) throw ZoomError.fromExecutedFailure(err);
        throw err;
      }
    });
  }

  public startVideo = () => {
    return defer(async () => {
      try {
        await this.stream.startVideo({
          // hd: this.stream.isSupportHDVideo(),
          mirrored: true,
        });
      } catch (err) {
        if (isExecutedFailure(err)) throw ZoomError.fromExecutedFailure(err);
        throw err;
      }
    }).pipe(
      tap(() => {
        this.isCapturingVideoSubject.next(this.stream.isCapturingVideo());
      })
    );
  };

  public stopVideo = () => {
    return defer(async () => {
      try {
        await this.stream.stopVideo();
      } catch (err) {
        if (isExecutedFailure(err)) throw ZoomError.fromExecutedFailure(err);
        throw err;
      }
    }).pipe(
      tap(() => {
        this.isCapturingVideoSubject.next(this.stream.isCapturingVideo());
      })
    );
  };

  public toggleVideo = () => {
    return defer(() => {
      return this.stream.isCapturingVideo()
        ? this.stopVideo()
        : this.startVideo();
    });
  };

  public getStream = () => {
    return this.stream;
  };

  public getCurrentUserInfo = () => {
    // Note: In some cases getCurrentUserInfo returns undefined, but this is not documented anywhere.
    return this.client.getCurrentUserInfo() ?? null;
  };

  /**
   * Returns a boolean representing whether leaving was successful.
   */
  public leave = () => {
    return defer(async () => {
      try {
        // See: https://developers.zoom.us/docs/video-sdk/web/best-practices/#cleanup-the-video-sdk
        await this.client.leave();
        this.isAudioMutedSubject.next(true);
        this.isCapturingVideoSubject.next(false);
      } catch (err) {
        return false;
      } finally {
        ZoomVideo.destroyClient();
      }
      return true;
    });
  };

  private onUserAddedListener = () => {
    this.client.on(ParticipantEvent.UserAdded, (payload) => {
      this.updateParticipants(ParticipantEvent.UserAdded);
    });
  };

  private onActiveSpeakerListener = () => {
    this.client.on("active-speaker", (payload) => {
      this.activeSpeakersSubject.next(payload);
    });
  };

  private onUserRemovedListener = () => {
    this.client.on(ParticipantEvent.UserRemoved, (payload) => {
      this.updateParticipants(ParticipantEvent.UserRemoved);
    });
  };

  private onUserUpdatedListener = () => {
    this.client.on(ParticipantEvent.UserUpdated, (payload) => {
      this.updateParticipants(ParticipantEvent.UserUpdated);
      const currentUserInfo = this.client.getCurrentUserInfo();
      if (currentUserInfo == null) {
        return;
      }
      const { muted, bVideoOn } = currentUserInfo;
      const status: CurrentUserAudioVideoStatus = {
        isMicOn: !muted,
        isVideoOn: bVideoOn,
      };
      this.CurrentUserAudioVideoStatusSubject.next(status);
    });
  };

  private onDeviceChangeListener = () => {
    this.client.on("device-change", () => {
      this.microphoneListSubject.next(this.stream.getMicList());
      this.speakerListSubject.next(this.stream.getSpeakerList());
    });
  };

  private updateParticipants(triggeredBy: ZoomParticipantData["triggeredBy"]) {
    this.allParticipantsSubject.next({
      triggeredBy,
      participants: this.client.getAllUser(),
    });
  }

  // All available events: https://marketplacefront.zoom.us/sdk/custom/web/modules/VideoClient.html#on
  public initEventListeners = () => {
    this.onUserAddedListener();
    this.onUserRemovedListener();
    this.onUserUpdatedListener();
    this.onActiveSpeakerListener();
    this.onDeviceChangeListener();
  };

  private isInMeeting() {
    return this.client?.getSessionInfo().isInMeeting ?? false;
  }

  /*
  Calculation is based on origin coordinate being top left (same applies to child element)

  0,0
  ┌──────────────────┐
  │                  │
  │                  │
  │                  │
  │                  │
  │                  │
  └──────────────────┘
                canvasWidth, canvasHeight
  */
  public getAvatarLayout(
    canvasWidth: number,
    canvasHeight: number,
    count: number,
    centerLastRow: boolean = false
  ): VideoLayout {
    let cellWidth = canvasWidth;
    if (count > 1 && count <= 4) {
      cellWidth = Math.floor(canvasWidth / 2);
    } else if (count > 4) {
      cellWidth = Math.floor(canvasWidth / 3);
    }
    let cellHeight = canvasHeight;
    if (count > 2) {
      cellHeight = Math.floor(canvasHeight / 2);
    }
    const cells2d: VideoCell[][] = [];
    let currentRow: VideoCell[] = [];
    let xPos = 0;
    let yPos = 0;
    for (let i = 0; i < count; i++) {
      if (xPos + cellWidth > canvasWidth) {
        xPos = 0;
        yPos = cellHeight;
        cells2d.push(currentRow);
        currentRow = [];
      }
      currentRow.push({ xPos, yPos });
      xPos += cellWidth;
    }
    cells2d.push(currentRow);

    if (centerLastRow) {
      if (cells2d.length == 2 && cells2d[0].length != cells2d[1].length) {
        const leftOffset =
          (cells2d[0].length - cells2d[1].length) * Math.floor(cellWidth / 2);
        for (let i = 0; i < cells2d[1].length; i++) {
          cells2d[1][i].xPos += leftOffset;
        }
      }
    }

    return {
      cellWidth,
      cellHeight,
      cells: cells2d.flat(),
    };
  }
  /*
    Calculation is based on origin coordinates being bottom left (same applies to child element)

              canvasWidth, canvasHeight
    ┌──────────────────┐
    │                  │
    │                  │
    │                  │
    │                  │
    │                  │
    └──────────────────┘
    0,0
    refer to https://developers.zoom.us/docs/video-sdk/web/video/#render-multiple-participant-videos
  */
  public getVideoLayout(
    canvasWidth: number,
    canvasHeight: number,
    count: number
  ): VideoLayout {
    const { cellWidth, cellHeight, cells } = this.getAvatarLayout(
      canvasWidth,
      canvasHeight,
      count
    );
    // Convert top left to bottom left origin for container and child
    const videoCells: VideoCell[] = cells.map((cell) => {
      return {
        xPos: cell.xPos,
        yPos: canvasHeight - (cell.yPos + cellHeight),
      };
    });
    return {
      cellWidth,
      cellHeight,
      cells: videoCells,
    };
  }
}
