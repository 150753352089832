import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  ConversationParticipantModelV2,
  FullUserProfileModel,
  MessageModel,
  MessageStatuses,
} from "@types";
import { SubscriptionContainer, concatNotNull } from "@utils";
import { ConversationsService, SharedService } from "../../modules/core";

@Component({
  selector: "app-message-status",
  templateUrl: "./message-status.component.html",
  styleUrls: ["./message-status.component.scss"],
})
export class MessageStatusComponent implements OnChanges, OnDestroy {
  @Input() userAccount: FullUserProfileModel;
  @Input() message: MessageModel;
  @Input() conversationType: string;
  @Input() id: string;
  @Input() refreshCount: number;
  @Input() pMap: { [key: string]: ConversationParticipantModelV2 };
  @Output() updateMessage = new EventEmitter<any>();

  user: any;
  seenBy = [];
  deliveredTo = [];
  loading: boolean;
  private subscriptions = new SubscriptionContainer();

  public deletedBy: ConversationParticipantModelV2 | null = null;

  constructor(
    private sharedService: SharedService,
    private conversationsService: ConversationsService
  ) {}

  ngOnChanges() {
    this.user = this.pMap[this.message.sentBy];
    this.setStatus();
    this.loading = true;
    this.unsubscribe();
    const getMessageSubscription = this.conversationsService
      .getMessage(this.message.conversationId, this.message.marker)
      .subscribe({
        next: (message) => {
          this.message = message;
          this.setStatus();
          this.updateMessage.emit(this.message);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });

    this.subscriptions.add(getMessageSubscription);
  }

  unsubscribe() {
    this.subscriptions.unsubscribe();
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  setStatus() {
    this.deletedBy = this.getDeletedBy();
    this.seenBy = this.getSeenBy();
    this.deliveredTo = this.getDelivered();
  }

  private getDeletedBy() {
    if (!this.message.deletedBy) return null;
    const participant = this.pMap[this.message.deletedBy];
    if (!participant) {
      throw new Error("Failed to find participant who deleted message");
    }
    return this.mapParticipant(participant, this.message.deletedOnUtc);
  }

  private mapParticipant(
    participant: ConversationParticipantModelV2,
    date?: string
  ) {
    const title = participant.title;
    const firstName = participant.firstName;
    const lastName = participant.lastName;
    const userId = participant.userId;
    const identityVerificationStatus = participant.identityVerificationStatus;
    const workspaces = participant.workplaces;
    const professions = participant.professions;
    const profilePicture = participant.profilePicture;
    const name = concatNotNull([
      participant.title,
      participant.firstName,
      participant.lastName,
    ]);
    const isExternal = participant?.isExternal ?? null;

    return {
      date,
      title,
      firstName,
      lastName,
      userId,
      profilePicture,
      name,
      identityVerificationStatus,
      workspaces,
      professions,
      isExternal,
    };
  }

  doFilter(stat: MessageStatuses) {
    this.sharedService.sortDateArrayByField(
      this.message.statuses,
      "createdOnUtc"
    );
    return this.message.statuses
      .filter((s) => s.status === stat && this.pMap[s.createdBy])
      .map((s) => {
        const participant = this.pMap[s.createdBy];
        return this.mapParticipant(participant, s.createdOnUtc);
      });
  }

  getSeenBy() {
    return this.doFilter(MessageStatuses.Read);
  }

  getDelivered() {
    const seenIds = {};
    for (const x of this.getSeenBy()) {
      seenIds[x.userId] = true;
    }
    return this.doFilter(MessageStatuses.Delivered).filter(
      (p) => !seenIds[p.userId]
    );
  }
}
