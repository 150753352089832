import { Injectable, OnDestroy } from "@angular/core";
import { SYSTEM_USER_ID } from "@common";
import { environment } from "@env";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  IRetryPolicy,
  LogLevel,
  RetryContext,
} from "@microsoft/signalr";
import { SoundPlayService } from "@modules/shared/sound-play.service";
import { Store } from "@ngrx/store";
import {
  CaseExportEntry,
  ConversationModelV2,
  ConversationMuteUpdate,
  ConversationType,
  ConversationUpdate,
  ConversationUpdateAction,
  DoNotDisturbUpdate,
  FullUserProfileModel,
  MessageStatusModel,
  MessageStatuses,
  MessageUpdate,
  NotificationPayload,
  PinnedConversationUpdate,
  UserActivityModel,
  UserProfileWithAllWorkspacesModel,
  VideoCallStatus,
  VideoCallUpdate,
} from "@types";
import { concatNotNull } from "@utils";
import { SignalRActions, selectConversation } from "app/state";
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  fromEvent,
} from "rxjs";
import { distinctUntilChanged, first, share } from "rxjs/operators";
import { NotificationData } from "utils/notification-utils";
import { PinscreenService } from "../../../pinscreen/pinscreen.service";
import { MessageService } from "../old/message.service";
import { SharedService } from "../old/shared.service";
import { UserAccountService } from "../old/user-account.service";
import {
  AccountService,
  ApplicationInsightsService,
  AuthService,
  UsersService,
} from "../services";
import { NotificationService } from "../services/notification.service";
import { UserService } from "./../services/user.service";
import { ConversationService } from "./conversation.service";

class ExponentialBackoffRetryPolicy implements IRetryPolicy {
  private maxAttempts: number | null = null;

  public constructor(maxAttempts: number | null = null) {
    this.maxAttempts = maxAttempts;
  }

  public nextRetryDelayInMilliseconds(
    retryContext: RetryContext
  ): number | null {
    if (
      this.maxAttempts !== null &&
      retryContext.previousRetryCount + 1 > this.maxAttempts
    ) {
      return null;
    }
    // Exponential backoff with a maximum delay of 60 seconds
    return Math.min(2 ** retryContext.previousRetryCount * 1000, 60000);
  }
}

export enum ConnectionStatus {
  Connected = "connected",
  Disconnected = "disconnected",
  Reconnecting = "reconnecting",
}

/** @deprecated */
@Injectable()
export class ConService implements OnDestroy {
  _connection: any;

  private MessageSubject = new Subject<MessageUpdate[]>();
  public Message$ = this.MessageSubject.asObservable().pipe(share());

  private ConnectionSubject = new Subject<any>();
  public ConnectionChange = this.ConnectionSubject.asObservable().pipe(share());
  private BlockSubject = new Subject<any>();
  public BlockChange = this.BlockSubject.asObservable().pipe(share());

  private BroadcastSubject = new Subject<any>();
  public Broadcast$ = this.BroadcastSubject.asObservable().pipe(share());
  private PhotoDetailsUpdateSubject = new Subject<any>();
  private PhotoDetailsUpdateRequiredSubject = new Subject<void>();
  public PhotoDetails$ =
    this.PhotoDetailsUpdateSubject.asObservable().pipe(share());

  private conversationExportEntrySubject = new Subject<CaseExportEntry>();
  public conversationExportEntrySubjectDetails$ =
    this.conversationExportEntrySubject.asObservable().pipe(share());

  public PhotoDetailsUpdateRequired$ =
    this.PhotoDetailsUpdateRequiredSubject.asObservable().pipe(share());

  private ConnectionStatusSubject = new BehaviorSubject<ConnectionStatus>(
    ConnectionStatus.Connected
  );

  private userActivityUpdateSubject = new Subject<UserActivityModel>();
  public userActivityUpdate$ = this.userActivityUpdateSubject.asObservable();

  private videoCallUpdateSubject = new Subject<VideoCallUpdate>();
  public videoCallUpdate$ = this.videoCallUpdateSubject.asObservable();

  private hasPendingConnectionsSubject = new Subject<void>();
  public hasPendingConnections$ =
    this.hasPendingConnectionsSubject.asObservable();

  public connectionStatus$ = this.ConnectionStatusSubject.asObservable().pipe(
    distinctUntilChanged()
  );

  private userStatusSubject = new Subject<any>();
  public userStatus$ = this.userStatusSubject.asObservable();

  private userDNDSubject = new Subject<DoNotDisturbUpdate>();
  public userDND$ = this.userDNDSubject.asObservable();

  private userActionSubject = new Subject<any>();
  public userAction$ = this.userActionSubject.asObservable();

  private notificationsSubject = new Subject<NotificationPayload>();
  public notifications$ = this.notificationsSubject.asObservable();

  public userId: string | null = null;
  disconnected = true;
  connecting = false;
  messages: any[] = [];
  loadingConversationIds: string[] = [];
  user: FullUserProfileModel;
  hubConnection: HubConnection | null = null;
  subscription: any;

  private retryTimeout: NodeJS.Timeout | number | null = null;
  private retryStartTime: Date | null = null;
  private previousRetryCount: number = 0;
  private retryPolicy: IRetryPolicy = new ExponentialBackoffRetryPolicy(2);
  private webLockResolver: (() => void) | null = null;

  private heartbeatIntervalId: NodeJS.Timeout | number | null = null;
  private heartbeatFocusListener: () => void | null = null;
  private heartbeatTimeoutMs: number = 5000;
  private logSkippedHeartbeatSampleRate: number = 10; // 1 in 10
  private skippedHeartbeatCount: number = 0;
  private signalRWatchdogTimer: NodeJS.Timeout | number | null = null;
  private hasStartedInitialConnection: boolean = false;

  constructor(
    private authService: AuthService,
    private conversationService: ConversationService,
    private messageService: MessageService,
    private soundPlayService: SoundPlayService,
    private userAccountService: UserAccountService,
    private sharedService: SharedService,
    private pinService: PinscreenService,
    private userService: UserService,
    private appInsightsService: ApplicationInsightsService,
    private accountService: AccountService,
    private usersService: UsersService,
    private store: Store,
    private notificationService: NotificationService
  ) {
    this.webLockResolver = this.tryAcquireWebLock();

    fromEvent(window, "focus").subscribe({
      next: () => {
        if (!this.hasStartedInitialConnection) return;
        this.reconnectSignalR(null, true);
      },
    });

    this.pinService.isLocked$.subscribe({
      next: (isLocked) => {
        if (isLocked || !this.hasStartedInitialConnection) return;
        this.reconnectSignalR(null, true);
      },
    });

    this.sharedService.onFocusChange.subscribe((foucsed) => {
      if (foucsed) {
        this.beat();
      }
    });

    this.userService.currentUser$.subscribe({
      next: (user) => {
        if (!user) return;

        this.user = user;
        this.userId = user.userId ?? null;

        if (this.userId) {
          this.listenToUserDND(this.userId);
        }
      },
    });

    this.authService.isAuthenticated$.subscribe({
      next: (isAuthenticated) => {
        if (!isAuthenticated) {
          this.stopSignalRHubConnection();
          return;
        }
        this.initSignalRHubConnection().subscribe({
          next: (hubConnection) => {
            this.hubConnection = hubConnection;
            this.hasStartedInitialConnection = true;
            if (this.userId) {
              this.subscribeUserStatus([this.userId]);
            }
          },
        });
      },
    });

    this.setupSignalRWatchdogTimer();

    this.initHeartbeat();
  }

  getName(conversation: any, loggedInUserId: string) {
    if (conversation.name && conversation.name.trim()) {
      return conversation.name.trim();
    }
    const others = this.getOthers(conversation, loggedInUserId);
    if (others.length > 1) {
      return (
        others
          .slice(0, 6)
          .map((p) => p.firstName || p.lastName)
          .join(", ") +
        (others.length > 6 ? `, ... , +${others.length - 6}` : "")
      );
    }
    if (others[0]) {
      return `${others[0].firstName || ""} ${others[0].lastName || ""}`;
    }
  }

  getOthers(conversation, loggedInUserId) {
    return conversation.participants.filter((p) => p.userId !== loggedInUserId);
  }

  ngOnDestroy(): void {
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }

    if (this.signalRWatchdogTimer) {
      clearInterval(this.signalRWatchdogTimer);
    }

    this.webLockResolver?.();
  }

  initHeartbeat() {
    this.beat();

    if (this.heartbeatIntervalId) {
      clearInterval(this.heartbeatIntervalId);
      this.heartbeatIntervalId = null;
    }

    this.heartbeatIntervalId = setInterval(() => {
      this.beat();
    }, this.heartbeatTimeoutMs);

    if (this.heartbeatFocusListener) {
      window.removeEventListener("focus", this.heartbeatFocusListener);
      this.heartbeatFocusListener = null;
    }

    this.heartbeatFocusListener = () => {
      if (!document.hasFocus()) return;
      this.beat();
    };
    window.addEventListener("focus", this.heartbeatFocusListener);
  }

  beat() {
    try {
      const state = {
        isLocked: this.pinService.isLocked(),
        hasFocus: document.hasFocus(),
        isAuthenticated: this.authService.isAuthenticated(),
        hasPin: this.pinService.hasPin(),
      };

      if (
        state.hasPin &&
        !state.isLocked &&
        state.hasFocus &&
        state.isAuthenticated
      ) {
        this.skippedHeartbeatCount = 0;

        if (this.hubConnection?.state === HubConnectionState.Connected) {
          try {
            this.reportOnlineSignalr();
          } catch {
            this.reportOnlineRest();
          }
        } else {
          this.reportOnlineRest();
        }
        return;
      }

      if (
        this.skippedHeartbeatCount === 0 &&
        environment.enableAppInsightsHeartbeatLogging
      ) {
        this.appInsightsService.trackTrace("Skipped heartbeat", state);
      }

      this.skippedHeartbeatCount += 1;

      if (this.skippedHeartbeatCount >= this.logSkippedHeartbeatSampleRate) {
        this.skippedHeartbeatCount = 0;
        return;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);

      if (environment.enableAppInsightsHeartbeatLogging) {
        this.appInsightsService.trackException(error);
      }
    }
  }

  reportOnlineRest() {
    this.accountService.updatePresence().subscribe({
      next: () => this.setLastOnlineTime(),
      error: () => {
        // eslint-disable-next-line no-console
        console.error("Failed to update online status");
      },
    });
  }

  reportOnlineSignalr() {
    this.hubConnection?.invoke("UpdateUserStatus");
    this.setLastOnlineTime();
  }

  setLastOnlineTime() {
    const time = new Date();
    localStorage.setItem("lastOnline", time.toString());
  }

  getLastOnlineTime() {
    const lastTime = localStorage.getItem("lastOnline");
    let time;
    if (!lastTime) {
      return null;
    }
    time = new Date(lastTime);
    return time;
  }

  stopHeartBeat(heartbeatIntervalId) {
    clearInterval(heartbeatIntervalId);
    this.heartbeatIntervalId = undefined;
  }

  updateUserAction(conversationId, actionStatus, actionType) {
    const instance = this;
    const action = {
      conversationId,
      isActioning: actionStatus,
      action: actionType,
    };
    if (!instance.disconnected) {
      this.hubConnection?.invoke("UpdateUserAction", action);
    }
  }

  subscribeUserStatus(userIds: string[]) {
    if (!this.disconnected) {
      this.hubConnection?.invoke("AddToGroups", "User", userIds);
    }
  }

  unsubscribeUserStatus(userIds: string[]) {
    if (!this.disconnected) {
      this.hubConnection?.invoke("RemoveFromGroups", "User", userIds);
    }
  }

  subscribeConversationActions(conversationIds: string[]) {
    if (!this.disconnected) {
      this.hubConnection?.invoke(
        "AddToGroups",
        "Conversation",
        conversationIds
      );
    }
  }

  unsubscribeConversationActions(conversationIds: string[]) {
    if (!this.disconnected) {
      this.hubConnection?.invoke(
        "RemoveFromGroups",
        "Conversation",
        conversationIds
      );
    }
  }

  private isCall() {
    return location.pathname.startsWith("/call");
  }

  private registerHubConnectionHandlers(hubConnection: HubConnection) {
    hubConnection.onreconnecting((err) => {
      this.disconnected = true;
      this.connecting = true;
      this.ConnectionStatusSubject.next(ConnectionStatus.Reconnecting);
    });

    hubConnection.onreconnected(() => {
      this.disconnected = false;
      this.connecting = false;
      this.ConnectionStatusSubject.next(ConnectionStatus.Connected);
    });

    hubConnection.onclose((error) => {
      this.disconnected = true;
      this.connecting = false;
      this.ConnectionStatusSubject.next(ConnectionStatus.Disconnected);
      this.reconnectSignalR(error);
    });

    if (!this.isCall()) {
      this.registerNonVideoCallHubConnectionHandlers(hubConnection);
    }

    hubConnection.on(
      "onVideoCallUpdates",
      (videoCallUpdate: VideoCallUpdate) => {
        this.videoCallUpdateSubject.next(videoCallUpdate);
        this.handleVideoCallUpdate(videoCallUpdate);
        this.store.dispatch(
          SignalRActions.videoCallUpdate({
            update: videoCallUpdate,
          })
        );
      }
    );
  }

  private registerNonVideoCallHubConnectionHandlers(
    hubConnection: HubConnection
  ) {
    hubConnection.on("onBlockChanged", (data: any) => {
      this.BlockSubject.next(data);
    });

    hubConnection.on("onConnectionChanged", (data: any) => {
      this.ConnectionSubject.next(data);
      this.handleConnectionNotification(data);
    });

    hubConnection.on("onUserActivityUpdated", (data: UserActivityModel[]) => {
      this.handleActivityFeedNotification(data[0]);
    });

    hubConnection.on(
      "onConversationMuteUpdated",
      (data: ConversationMuteUpdate) => {
        this.store.dispatch(SignalRActions.conversationMuteUpdated(data));
      }
    );

    hubConnection.on("onNotify", (payload: NotificationPayload) => {
      this.notificationsSubject.next(payload);
      if (payload.type === "WorkspaceNewMember") {
        this.handleWorkspaceUpdateNotification(payload);
      } else if (payload.type === "TeamOnOffCall") {
        this.handleTeamOnOffCallNotification(payload);
      }
    });

    hubConnection.on(
      "onConversationUpdated",
      (conversation: ConversationUpdate) => {
        if (
          conversation.action === ConversationUpdateAction.Participants ||
          conversation.action === ConversationUpdateAction.Data
        ) {
          this.pullConversation(conversation.id, (res) => {
            this.conversationService.replaceConversationDataById(res);
          });
        }
        this.conversationService.sortConversations();
      }
    );

    hubConnection.on(
      "onMessageUpdates",
      (updates: MessageUpdate[] | MessageUpdate[][]) => {
        let messages: MessageUpdate[];

        if (updates.length && Array.isArray(updates[0])) {
          // sometimes backend seems to send array of arrays
          messages = updates[0] as MessageUpdate[];
        } else {
          messages = updates as MessageUpdate[];
        }

        messages.forEach((message) => {
          this.store.dispatch(
            SignalRActions.messageUpdate({
              update: message,
            })
          );
        });

        // #TODO handle receive message sound and notifications
      }
    );

    hubConnection.on("onBroadcastMessageUpdates", (messages: any[]) => {
      this.BroadcastSubject.next(messages);
    });

    hubConnection.on("onPhotoUpdates", (photoDetails: any[]) => {
      this.PhotoDetailsUpdateSubject.next(photoDetails);
      this.PhotoDetailsUpdateRequiredSubject.next();
    });

    hubConnection.on("onUserStatusUpdated", (userStatus: any) => {
      this.userStatusSubject.next(userStatus);
    });

    hubConnection.on(
      "onUserDoNotDisturbUpdated",
      (doNotDisturbUpdate: DoNotDisturbUpdate) => {
        this.userDNDSubject.next(doNotDisturbUpdate);
        this.userService.handleDoNotDisturbUpdate(doNotDisturbUpdate);
      }
    );

    hubConnection.on("onUserActioning", (action: any) => {
      this.userActionSubject.next(action);
    });

    hubConnection.on(
      "onConversationPinnedUpdated",
      (conversationPinned: PinnedConversationUpdate) => {
        this.store.dispatch(
          SignalRActions.conversationPinnedUpdated(conversationPinned)
        );
      }
    );

    hubConnection.on("onCaseExported", (caseExportEntry: CaseExportEntry) => {
      this.conversationExportEntrySubject.next(caseExportEntry);
    });
  }

  private isSignalRConnectedOrConnecting(): boolean {
    if (!this.hubConnection) return false;
    const state = this.hubConnection.state;
    return (
      state === HubConnectionState.Connected ||
      state === HubConnectionState.Connecting ||
      state === HubConnectionState.Reconnecting ||
      this.connecting
    );
  }

  private getRetryDelay(error?: Error | null) {
    this.retryStartTime =
      this.retryStartTime === null || this.previousRetryCount === 0
        ? new Date()
        : this.retryStartTime;

    const delay = this.retryPolicy.nextRetryDelayInMilliseconds({
      retryReason: error instanceof Error ? error : new Error(),
      elapsedMilliseconds: new Date().getTime() - this.retryStartTime.getTime(),
      previousRetryCount: this.previousRetryCount,
    });

    return delay;
  }

  // See: https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-7.0&tabs=visual-studio#bsleep
  private tryAcquireWebLock(): (() => void) | null {
    if (!navigator?.locks?.request) return null;

    let lockResolver: (() => void) | null = null;
    if (navigator?.locks?.request) {
      const promise = new Promise<void>((res) => {
        lockResolver = res;
      });

      navigator.locks.request(crypto.randomUUID(), { mode: "shared" }, () => {
        return promise;
      });
    }

    return lockResolver;
  }

  public userTriggeredReconnectSignalR() {
    if (this.ConnectionStatusSubject.value !== ConnectionStatus.Disconnected)
      return;
    this.ConnectionStatusSubject.next(ConnectionStatus.Disconnected);
    this.reconnectSignalR(null, true);
  }

  private reconnectSignalR(
    error?: Error | null,
    ignorePreviousRetryCount?: boolean
  ) {
    if (this.isSignalRConnectedOrConnecting()) return;

    if (ignorePreviousRetryCount) {
      this.previousRetryCount = 0;
    }

    let delay = ignorePreviousRetryCount ? 0 : this.getRetryDelay(error);
    if (delay === null) return;

    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
    }

    this.retryTimeout = setTimeout(() => {
      if (this.isSignalRConnectedOrConnecting()) return;

      this.ConnectionStatusSubject.next(ConnectionStatus.Reconnecting);

      this.initSignalRHubConnection().subscribe({
        next: (hubConnection) => {
          this.hubConnection = hubConnection;
          if (this.userId) {
            this.subscribeUserStatus([this.userId]);
          }
        },
      });
    }, delay);
  }

  private getSignalRNegotiateURL(): string {
    let url = environment.celoSocketLocation;

    const drid = this.authService.getDataRegion();
    if (drid) {
      const queryString = new URLSearchParams({ drid });
      url += `?${queryString.toString()}`;
    } else {
      this.appInsightsService.trackTrace("Failed to get data region");
    }

    return url;
  }

  /**
   * Sets up a timer that will periodically check the SignalR HubConnection state and update the
   * connection status observable.
   *
   * This is a band-aid fix.
   */
  private setupSignalRWatchdogTimer() {
    this.signalRWatchdogTimer = setInterval(() => {
      if (!this.hasStartedInitialConnection) return;

      const state = this.hubConnection?.state;

      let status: ConnectionStatus;
      switch (state) {
        case HubConnectionState.Connected:
        case HubConnectionState.Connecting:
          status = ConnectionStatus.Connected;
          break;
        case HubConnectionState.Reconnecting:
          status = ConnectionStatus.Reconnecting;
          break;
        case HubConnectionState.Disconnected:
        case HubConnectionState.Disconnecting:
          status = ConnectionStatus.Disconnected;
          break;
        default:
          status = ConnectionStatus.Disconnected;
      }

      this.ConnectionStatusSubject.next(status);
    }, 5000);
  }

  private initSignalRHubConnection(): Observable<HubConnection> {
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => this.authService.getAccessToken(),
    };

    const hubConnection = new HubConnectionBuilder()
      .withUrl(this.getSignalRNegotiateURL(), options)
      // Automatic reconnect is unreliable so we always use our implementation
      // .withAutomaticReconnect(this.retryPolicy)
      .configureLogging(
        environment.production ? LogLevel.Information : LogLevel.Debug
      )
      .build();

    this.registerHubConnectionHandlers(hubConnection);

    const observable = new Observable<HubConnection>((subscriber) => {
      this.disconnected = false;

      const connect = async () => {
        this.connecting = true;

        if (
          this.hubConnection?.state &&
          this.hubConnection?.state !== HubConnectionState.Disconnected
        ) {
          this.appInsightsService.trackTrace(
            "Failed to init SignalR as it is not in the Disconnected state"
          );
          this.disconnected = false;
          this.connecting = false;
          return;
        }

        if (this.hubConnection) {
          this.hubConnection.baseUrl = this.getSignalRNegotiateURL();
        }

        try {
          await hubConnection.start();
        } catch (err) {
          this.disconnected = true;
          const delay =
            err instanceof Error
              ? this.getRetryDelay(err)
              : this.getRetryDelay();
          this.previousRetryCount += 1;

          if (delay) {
            // eslint-disable-next-line no-console
            console.log(
              `[${new Date().toISOString()}] Retrying SignalR HubConnection in ${delay} ms`
            );

            this.retryTimeout = setTimeout(() => connect(), delay);
          } else {
            // eslint-disable-next-line no-console
            console.log(
              `[${new Date().toISOString()}] Maximum retry attempts reached`
            );
            this.disconnected = true;
            this.connecting = false;
            this.ConnectionStatusSubject.next(ConnectionStatus.Disconnected);
          }
          return;
        }

        this.previousRetryCount = 0;
        this.disconnected = false;
        this.connecting = false;
        this.ConnectionStatusSubject.next(ConnectionStatus.Connected);

        subscriber.next(hubConnection);
        subscriber.complete();
        return;
      };

      subscriber.add(() => {
        if (this.retryTimeout === null) return;
        clearTimeout(this.retryTimeout);
      });

      connect();
    });

    return observable;
  }

  private stopSignalRHubConnection() {
    this.hubConnection?.stop();
    this.hubConnection = null;
  }

  handleActivityFeedNotification = (data: UserActivityModel) => {
    this.userActivityUpdateSubject.next(data);
  };

  handleWorkspaceUpdateNotification(payload: NotificationPayload) {
    if (
      !payload["title"] ||
      !payload["text"] ||
      !payload["resource"] ||
      !payload["resource"]["id"]
    ) {
      return;
    }

    const title = payload["title"];
    const body = payload["text"];
    const targetUrl = `/network/contact/${payload["resource"]["id"]}`;

    const notificationObj = {
      title,
      id: payload.id,
      icon: "https://app.celohealth.com/assets/icon.png",
      body,
      requireInteraction: true,
      target: targetUrl,
      data: { url: targetUrl },
    };

    this.showPushNotification(notificationObj);
  }

  private handleTeamOnOffCallNotification(payload: NotificationPayload) {
    const targetUrl = `/roles/${payload.resource.id}`;
    const notificationObj: NotificationData = {
      title: payload.title,
      id: payload.id,
      icon: "https://app.celohealth.com/assets/icon.png",
      body: payload.text,
      requireInteraction: true,
      target: targetUrl,
      data: { url: targetUrl },
    };
    this.showPushNotification(notificationObj);
  }

  handleConnectionNotification(data) {
    if (!data.connection || !data.connection["createdBy"]) {
      return;
    }
    let title = "";
    let body = "";
    let targetUrl = ``;

    const id = `${data.connection.id}_${data.connection.lastModifiedOnUtc}`;

    if (
      data.connection["createdBy"] == this.userId &&
      data.connection["state"] == "Accepted"
    ) {
      title = "New Connection";
      body =
        data.connection.responsorFullName +
        " accepted your connection request.";
      targetUrl = `/network/contact/${data.connection["userId"]}`;
      let notificationObj = {
        title,
        id,
        icon: "https://app.celohealth.com/assets/icon.png",
        body,
        requireInteraction: true,
        target: targetUrl,
        data: { url: targetUrl },
      };
      this.showPushNotification(notificationObj);
    } else if (
      data.connection["createdBy"] != this.userId &&
      data.connection["state"] == "Pending"
    ) {
      this.hasPendingConnectionsSubject.next();
      title = "Connection Request";
      body =
        data.connection.creatorFullName + " would like to connect with you.";
      targetUrl = `/notifications`;
      let notificationObj = {
        title,
        id,
        icon: "https://app.celohealth.com/assets/icon.png",
        body,
        requireInteraction: true,
        target: targetUrl,
        data: { url: targetUrl },
      };
      this.showPushNotification(notificationObj);
    }
  }

  listenToUserDND(userId: string) {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.userDND$.subscribe((userDND) => {
      if (userDND.userId == userId) {
        this.user["doNotDisturbToUtc"] = userDND.doNotDisturbToUtc;
      }
    });
  }

  pullConversation(
    conversationId: string,
    callback: (conversation: ConversationModelV2) => void
  ) {
    this.conversationService.getConversationByIdApi(conversationId).subscribe(
      (res) => {
        if (res) {
          callback(res);
        }
      },
      (err) => {}
    );
  }

  updateConversationDataByContent(data: any) {
    this.conversationService.addOrReplaceConversation(data);
  }

  private getVideoCallNotificationTitle(
    update: VideoCallUpdate,
    conversation: ConversationModelV2,
    createdBy: UserProfileWithAllWorkspacesModel
  ): string {
    const createdByName = concatNotNull([
      createdBy.firstName,
      createdBy.lastName,
    ]);

    if (conversation.type === ConversationType.Chat) return createdByName;

    switch (update.status) {
      case VideoCallStatus.InProgress:
        return concatNotNull([createdByName, conversation.name], " @ ");
      case VideoCallStatus.Ended:
        return conversation.name;
    }
  }

  private shouldHandleVideoCallUpdate(update: VideoCallUpdate): boolean {
    // Notifications for incoming calls are only shown while the app is locked or the window is not focused
    // VideoCallStatus.Ended notifications are always handled as they only result in notifications being dismissed
    if (update.status === VideoCallStatus.Ended) return true;
    return this.pinService.isLocked() || !document.hasFocus();
  }

  public handleVideoCallUpdate(update: VideoCallUpdate) {
    this.updateNotificationsFromVideoCallUpdate(update);
  }

  private updateNotificationsFromVideoCallUpdate(update: VideoCallUpdate) {
    if (update.createdBy === this.userService.getUserId(true)) return;

    if (!this.shouldHandleVideoCallUpdate(update)) return;

    combineLatest({
      conversation: this.store
        .select(selectConversation(update.conversationId))
        .pipe(first()),
      createdBy: this.usersService.getUser(update.createdBy),
    })
      .pipe(first())
      .subscribe({
        next: ({ conversation, createdBy }) => {
          switch (update.status) {
            case VideoCallStatus.InProgress:
              const currentUserId = this.userService.getUserId(true);
              const currentUserParticipant = conversation.participants.find(
                (p) => p.userId === currentUserId
              );
              if (!currentUserParticipant)
                throw new Error("Failed to find current user in conversation");

              // Don't show video call notifications for muted conversations
              const isConversationMuted =
                currentUserParticipant.mutedToUtc &&
                new Date(currentUserParticipant.mutedToUtc) >= new Date();
              if (isConversationMuted) return;

              const targetUrl = `/conversations/${update.conversationId}/messages`;
              const notificationData: NotificationData = {
                title: this.getVideoCallNotificationTitle(
                  update,
                  conversation,
                  createdBy
                ),
                id: concatNotNull([update.id, update.status], "|"),
                tag: update.id,
                icon: environment.origin + "/assets/icon.png",
                requireInteraction: true,
                target: targetUrl,
                data: { url: targetUrl, isCall: true, callId: update.id },
                body: "Started a video call.",
              };
              this.showPushNotification(notificationData);
              break;
            case VideoCallStatus.Ended:
              // There's a race condition here, but it should be very rare, and will not break the application, so it's
              // left as-is for now.
              this.notificationService.dismissVideoCallNotification(update.id);
              break;
            default:
              throw new Error(`Unhandled call status: ${update.status}`);
          }
        },
      });
  }

  public showPushNotification(
    notificationObj: NotificationData,
    ignoreDNDStatus?: boolean
  ) {
    this.notificationService.showPushNotification(
      notificationObj,
      ignoreDNDStatus
    );
  }

  checkNeedConversationRefresh(message: any) {
    let needConversationRefresh = false;
    if (!message) {
      return;
    }
    if (message.sentBy == SYSTEM_USER_ID) {
      needConversationRefresh = true;
    }
    return needConversationRefresh;
  }

  markDelivered(messages: MessageUpdate[]) {
    const convoGrouped = {};
    for (const message of messages) {
      const sb = message["sentBy"];
      if (
        sb !== SYSTEM_USER_ID &&
        sb !== this.userId &&
        !this.hasMe(this.userId, message["Statuses"] || [])
      ) {
        const id = message["conversationId"];
        convoGrouped[id] = convoGrouped[id] || [];
        convoGrouped[id].push({
          MessageId: message["id"],
          Status: "Delivered",
        });
      }
    }

    for (const convoId in convoGrouped) {
      if (convoId !== undefined && convoGrouped.hasOwnProperty(convoId)) {
        const statuses = convoGrouped[convoId];
        if (!statuses || !statuses.length) {
          return;
        }
        const path =
          environment.celoApiEndpoint +
          "/api/Conversations/" +
          convoId +
          "/UpdateMessageStatuses";
        this.sharedService
          .postObjectById(path, {}, statuses)
          .subscribe((r) => {});
      }
    }
  }

  hasMe(id, statuses: MessageStatusModel[]): boolean {
    for (const status of statuses) {
      if (status.createdBy === id) {
        if (
          status.status === MessageStatuses.Delivered ||
          status.status === MessageStatuses.Read
        ) {
          return true;
        }
      }
    }
    return false;
  }

  getChatId(receiver_id: any) {
    const sender_id = this.userId;
    return this.sharedService.getChatId(receiver_id, sender_id);
  }
}
