<div class="box">
  <h4 class="mat-subtitle-1 header">
    {{ header || "Send to a conversation" }}
  </h4>

  <hr />

  <!-- Attachment preview -->
  <div *ngIf="attached && attached.length" matRipple class="attach-box">
    <div class="attach-preview">
      <div class="preview">
        <img
          *ngIf="attachmentType === 'MEDIA'"
          class="fh"
          [src]="
            '/api/v2/media/' + attached[0].id + '?size=Small' | clink | secure
          "
        />
        <div *ngIf="attachmentType === 'DOCUMENT'" class="doc fw fh">
          <i class="material-icons align-self-center">insert_drive_file</i>
        </div>
      </div>
      <div class="text">
        <div class="name celo-elipsis">
          {{ attached[0].fileName ? attached[0].fileName : "No name" }}
        </div>
        <div
          *ngIf="attached[0].patientData && attached[0].patientData.uid"
          class="patient"
        >
          Patient :{{ attached[0].patientData.uid }}
        </div>
      </div>
    </div>
    <div *ngIf="attached.length === 1" class="subtext">
      1 <span class="lowercase">{{ attachmentType }}</span> selected
    </div>
    <div *ngIf="attached.length > 1" class="subtext">
      + {{ attached.length - 1 }}
      <span class="lowercase">{{ attachmentType }}</span
      ><span *ngIf="attached.length > 2">s</span> selected
    </div>
  </div>

  <!-- Searchbar -->
  <div class="searchbar-container">
    <app-basic-textfield
      class="searchbar"
      [placeholder]="'Search by Name, Groups, Cases & Patient ID'"
      icon="search"
      (enter)="handleSearchEnter()"
      [formControl]="searchQuery"
    ></app-basic-textfield>
  </div>

  <!-- Create new conversation button -->
  <div (click)="openCreateConvo()">
    <app-create-new-conversation-button
      [buttonText]="createNewConversationButtonText || 'Send to a new chat'"
    ></app-create-new-conversation-button>
  </div>

  <!-- Filter description -->
  <div class="bar font-14">
    <span *ngIf="patientUid">Showing conversations for {{ patientUid }}</span>
    <span *ngIf="!patientUid">
      <div *ngIf="patientUids?.length > 1">Showing general conversations</div>
      <div *ngIf="!patientUids || patientUids.length === 0">
        Showing all conversations
      </div>
    </span>
  </div>

  <!-- Conversations list -->
  <div class="content">
    <ng-container *ngIf="isSearching && userAccount; else defaultContent">
      <div *ngIf="isSearchLoading && !searchResults.length" class="spinner">
        <app-empty-state [isLoading]="true" message="Searching...">
        </app-empty-state>
      </div>
      <app-conversation-results
        *ngIf="!(isSearchLoading && !searchResults.length)"
        [conversations]="searchResults"
        [userAccount]="userAccount"
        [searchKeyword]="searchResultsQuery"
        [isSelectionList]="true"
        [selectedConversationId]="convoId"
        (conversation)="onConversationSelected($event)"
      ></app-conversation-results>
    </ng-container>

    <ng-template #defaultContent>
      <div *ngIf="isLoading && !conversations.length" class="spinner">
        <app-empty-state [isLoading]="true" message="Conversations">
        </app-empty-state>
      </div>
      <mat-list>
        <ng-container *ngFor="let convo of conversations">
          <app-conversation-card
            class="celo-cursor-pointer"
            [userAccount]="userAccount"
            (click)="onConversationSelected(convo)"
            [selected]="convoId === convo.id"
            [inConversationList]="false"
            [activeOnly]="true"
            [hideBorder]="true"
            [convo]="convo"
          >
          </app-conversation-card>

          <hr class="conversation-card-divider" />
        </ng-container>

        <div
          *ngIf="isLoading && conversations && conversations.length"
          class="spinner-box"
        >
          <div class="spinner">
            <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
          </div>
        </div>
        <button
          *ngIf="!isLoading && hasNext"
          class="fw"
          mat-button
          (click)="onLoadMore()"
        >
          Load more
        </button>
      </mat-list>
    </ng-template>
  </div>

  <!-- Dialog actions -->
  <mat-dialog-actions align="end">
    <button mat-button class="celo-secondary-button uppercase" mat-dialog-close>
      Cancel
    </button>
    <button
      mat-button
      class="celo-primary-button uppercase"
      (click)="conversationId.emit(convoId); select(convoId)"
      [disabled]="!convoId"
    >
      {{ sendButtonText || "Send" }}
    </button>
  </mat-dialog-actions>
</div>
