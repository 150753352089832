import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { LibraryService } from "@modules/library/library.service";
import { retry, tap } from "rxjs/operators";
import { environment } from "@env";
import { AlertService } from "@modules/core";
import { DocumentsService } from "../documents.service";

@Component({
  selector: "app-document-details",
  templateUrl: "./document-details.component.html",
  styleUrls: ["./document-details.component.scss"],
})
export class DocumentDetailsComponent implements OnChanges {
  @Input() document: any;
  @Input() userAccount: any;
  @Output() update = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Input() isFromLibrary: boolean;

  formFields = [];

  hasPatient = false;
  patient = {
    uid: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    gender: null,
  };

  isLoading = false;

  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
    private libraryService: LibraryService,
    private documentsService: DocumentsService,
    private http: HttpClient
  ) {}

  ngOnChanges() {
    this.setPatientData(this.document);
    // this.loadDocumentObject();
    if (this.document) {
      this.onNewDocumentObject();
    }
  }

  setFormFields() {
    this.formFields = [
      {
        value: this.document.fileName,
        type: "text",
        disabled: false,
        name: "File Name",
        key: "fileNameAlias",
      },
      {
        value: this.document.fileDescription,
        type: "textarea",
        disabled: false,
        name: "Description",
        key: "fileDescription",
      },
    ];
  }

  setPatientData(document: any) {
    this.patient = {
      uid: "",
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      gender: null,
    };
    this.hasPatient = false;
    this.patient = { ...document.patientData };
    if (document.patientData && document.patientData.uid) {
      this.hasPatient = true;
    }
  }

  private onNewDocumentObject() {
    this.isLoading = false;
    this.setPatientData(this.document);
    this.setFormFields();
  }

  private loadDocumentObject() {
    const path = `${environment.celoApiEndpoint}/api/PatientFiles/${this.document.id}/Details`;
    this.isLoading = true;
    this.http
      .get<any>(path)
      .pipe(
        retry(3),
        tap(() => (this.isLoading = false))
      )
      .subscribe(
        (document) => {
          this.document = document;
          this.setPatientData(document);
          this.setFormFields();
        },
        () => {
          this.alertService.alert("Error", "Error loading file details", true);
        }
      );
  }

  openShare = () => {};
  onPatientSave(data: any) {
    const i = this;
    data["fileDescription"] = this.document["fileDescription"];
    data["fileNameAlias"] = this.document["fileName"];

    this.documentsService.updateDocumentPut(
      this.document.id,
      data,
      function (resp) {
        // this.onPhotoContainerUpdate(resp);
        if (!resp) {
          // i.alertService.showSnackBar('Sorry! Error updating patient data.',3,'white');
          i.setPatientData(i.document);
          return;
        }
        i.alertService.showSnackBar("Patient details updated", 3);
        i.libraryService.emitReloadDocument(true);
        i.setFormFields();
        i.setPatientData(resp);
        i.update.emit();
      }
    );
  }

  onDocumentDataUpdate(updatedVersion) {
    this.document = updatedVersion;
    this.setFormFields();
    this.setPatientData(updatedVersion);
    this.update.emit();
  }

  delete() {
    this.deleteClicked.emit();
  }
}
