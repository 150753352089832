import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  Output,
  Provider,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

const BASIC_TEXT_FIELD_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => BasicTextfieldComponent),
  multi: true,
};

@Component({
  selector: "app-basic-textfield",
  templateUrl: "./basic-textfield.component.html",
  styleUrls: ["./basic-textfield.component.scss"],
  providers: [BASIC_TEXT_FIELD_CONTROL_VALUE_ACCESSOR],
})
export class BasicTextfieldComponent implements ControlValueAccessor {
  @Input() public placeholder: string | null = "";
  @Input() public type: string = "text";
  @Input() public maxLength: number | null = null;
  @Input() public icon: string | null = null;

  @Output() public enter: EventEmitter<void> = new EventEmitter();

  private _value: string = "";

  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    this._value = value;
    this.setTouched();
    this.onChange?.(value);
  }

  public disabled: boolean = false;

  @ViewChild("input") private input: ElementRef<HTMLInputElement> | null;

  private isTouched: boolean = false;
  private onChange: Function | null = null;
  private onTouched: Function | null = null;

  @HostListener("click")
  handleClick() {
    this.input?.nativeElement?.focus();
  }

  public handleEnter() {
    this.enter.emit();
  }

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private setTouched() {
    if (this.isTouched) return;
    this.isTouched = true;
    this.onTouched?.();
  }
}
