import { DatePipe } from "@angular/common";
import { Pipe } from "@angular/core";
import { DateTime } from "luxon";

export type DateFormatType =
  | "light"
  | "medium"
  | "full"
  | "shortTime"
  | "longDate"
  | "longDateFullMonth"
  | "dateOfBirth"
  | "fullDate"
  | "relativeDate"
  | "relativeDateTime"
  | "relativeDay"
  | "medium-uppercase-period"
  | "lastActiveTime";

export interface RelativeDateTimeOptions {
  includeTime?: boolean;
  format?: string;
}

@Pipe({
  name: "dateFormat",
})
export class DateFormatPipe extends DatePipe {
  protected dateTimeFormatter = new Intl.DateTimeFormat([], {
    timeZoneName: "long",
  });

  transform(
    value: Date | string | number,
    format?: DateFormatType | string,
    timezone?: string,
    locale?: string
  ): string | null;

  transform(
    value: null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): null;

  transform(
    value: Date | string | number | null | undefined,
    format?: DateFormatType | string,
    timezone?: string,
    locale?: string
  ): string | null {
    if (value == null) return null;
    const date = value instanceof Date ? value : new Date(value);

    // See: https://celohealth.atlassian.net/wiki/spaces/CH/pages/1522597891/International+Date+Formatting
    const formatType = format as DateFormatType;
    switch (formatType) {
      case "light":
        return this.transformLight(date);
      case "medium":
        return this.transformMedium(date);
      case "medium-uppercase-period":
        return this.transformMedium(date, true);
      case "full":
        return this.transformFull(date);
      case "shortTime":
        return this.transformShortTime(date);
      case "longDate":
        return this.transformLongDate(date);
      case "longDateFullMonth":
        return this.transformLongDateFullMonth(date);
      case "dateOfBirth":
        return this.transformDateOfBirth(date);
      case "fullDate":
        return this.transformFullDate(date);
      case "relativeDate":
        return this.transformRelativeDate(date);
      case "relativeDateTime":
        return this.transformRelativeDate(date, {
          includeTime: true,
        });
      case "relativeDay":
        return this.transformRelativeDate(date, {
          format: "EEE, d MMM yyyy",
        });
      case "lastActiveTime":
        return this.transformLastActiveTime(date);
      default:
        return this.transformFull(date);
    }
  }

  private transformLight(date: Date): string {
    if (this.isToday(date)) {
      return `${super.transform(date, "h:mm")} ${this.getLowerCasePeriod(
        date
      )}`;
    }

    if (this.isThisYear(date)) {
      return super.transform(date, "d MMM");
    }

    return super.transform(date, "d MMM yyyy");
  }

  private transformMedium(
    date: Date,
    isUpperCasePeriod: boolean = false
  ): string {
    const format = "d MMM yyyy h:mm";

    let period = this.getLowerCasePeriod(date);
    if (isUpperCasePeriod) {
      period = period.toUpperCase();
    }

    return `${super.transform(date, format)} ${period}`;
  }

  private transformFull(date: Date): string {
    const format = "d MMM yyyy h:mm";
    return `${super.transform(date, format)} ${this.getLowerCasePeriod(
      date
    )} ${this.getTimeZoneName(date)}`;
  }

  private transformShortTime(date: Date): string {
    const format = "h:mm";
    return `${super.transform(date, format)} ${this.getLowerCasePeriod(date)}`;
  }

  private transformLongDate(date: Date): string {
    const format = "d MMM yyyy";
    return super.transform(date, format);
  }

  private transformLongDateFullMonth(date: Date): string {
    const format = "d MMMM yyyy";
    return super.transform(date, format);
  }

  private transformDateOfBirth(date: Date): string {
    return DateTime.fromJSDate(date).toUTC().toFormat("d MMMM yyyy");
  }

  private transformFullDate(date: Date): string {
    const format = "EEEE, d MMMM, y";
    return super.transform(date, format);
  }

  private transformRelativeDate(
    date: Date,
    options?: RelativeDateTimeOptions
  ): string {
    const format = options?.format || "d MMM yyyy";
    let datePart = this.getTemporalName(date);

    if (!datePart) {
      datePart = super.transform(date, format);
    }

    let timePart = "";
    if (options?.includeTime) {
      timePart = this.transformShortTime(date);
    }

    return timePart ? `${timePart} ${datePart}` : datePart;
  }

  private transformLastActiveTime(date: Date): string {
    const now = Date.now();
    const millisecondsAgo = now - date.getTime();
    const secondsAgo = millisecondsAgo / 1000;
    const minutesAgo = secondsAgo / 60;

    if (secondsAgo < 60) {
      return "Online";
    }

    if (minutesAgo < 60) {
      const minutes = Math.floor(minutesAgo);
      return `Last active ${minutes} min${minutes > 1 ? "s" : ""} ago`;
    }

    if (this.isThisYear(date)) {
      const temporalName = this.getTemporalName(date)?.toLowerCase();
      const relativeDate = temporalName ?? super.transform(date, "d MMM");
      const time = super.transform(date, "h:mm");
      const period = this.getLowerCasePeriod(date);
      return `Last active ${relativeDate} at ${time} ${period}`;
    }

    return `Last active ${super.transform(date, "d MMM yyyy")}`;
  }

  private isSameYear(a: Date, b: Date): boolean {
    return a.getFullYear() === b.getFullYear();
  }

  private isThisYear(date: Date): boolean {
    const today = new Date();
    return this.isSameYear(today, date);
  }

  private isSameDay(a: Date, b: Date): boolean {
    return (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate()
    );
  }

  private isToday(date: Date): boolean {
    const today = new Date();
    return this.isSameDay(today, date);
  }

  private isYesterday(date: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return this.isSameDay(yesterday, date);
  }

  private isTomorrow(date: Date): boolean {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return this.isSameDay(tomorrow, date);
  }

  private getTimeZoneName(date: Date): string {
    return this.dateTimeFormatter
      .formatToParts(date)
      .find((p) => p.type === "timeZoneName").value;
  }

  private getLowerCasePeriod(date: Date): string {
    return super.transform(date, "aa").toLowerCase();
  }

  private getTemporalName(date: Date): string | null {
    if (this.isToday(date)) {
      return "Today";
    }

    if (this.isTomorrow(date)) {
      return "Tomorrow";
    }

    if (this.isYesterday(date)) {
      return "Yesterday";
    }

    return null;
  }
}
