import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-profile-dialog",
  templateUrl: "./profile-dialog.component.html",
  styleUrls: ["./profile-dialog.component.scss"],
})
export class ProfileDialogComponent implements OnInit {
  contactId: string;
  hideSecureMessageButton = false;

  @Output() userDataChanged = new EventEmitter<any>();
  @Output() exit = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data) {
      if (this.data.contactId) {
        this.contactId = this.data.contactId;
      }
      if (this.data.hideSecureMessageButton) {
        this.hideSecureMessageButton = this.data.hideSecureMessageButton;
      }
    }
  }
}
