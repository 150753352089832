import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env";
import { BehaviorSubject, EMPTY, Observable, of, throwError } from "rxjs";
import { catchError, filter, map, switchMap, take } from "rxjs/operators";
import { AuthService } from "../services/auth.service";

// These endpoints don't require authentication and are used during onboarding
const ignoredCeloApiPaths = [
  "/api/settings/countries",
  "/api/Onboard/Email/Verify",
  "/api/Onboard/EmailVerifyCode/check",
  "/api/Settings/Titles",
  "/api/Onboard/RegisterUser",
];

const ignoredUrls: string[] = [
  environment.celoAuthApiEndpoint,
  ...ignoredCeloApiPaths.map((path) => `${environment.celoApiEndpoint}${path}`),
];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  public constructor(private authService: AuthService) {}

  private getHeadersObservable(
    excludeAuthorization: boolean = false
  ): Observable<Record<string, string>> {
    const headers: Record<string, string> = {
      AppVersion: environment.appVersion,
    };

    const dataRegion = this.authService.getDataRegion();
    if (dataRegion) {
      headers["Celo-Region"] = dataRegion;
    }

    if (excludeAuthorization) return of(headers);

    return this.authService.getAccessTokenObservable().pipe(
      map((accessToken) => {
        if (accessToken) {
          headers.Authorization = `Bearer ${accessToken}`;
        }
        return headers;
      })
    );
  }

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const excludeAuthorization = ignoredUrls.some((url) =>
      request.url.startsWith(url)
    );

    return this.getHeadersObservable(excludeAuthorization).pipe(
      switchMap((headers) => {
        const newRequest = request.clone({ setHeaders: headers });
        return next.handle(newRequest).pipe(
          catchError((err) => {
            if (err.status !== 401) {
              return throwError(err);
            }

            if (this.authService.isRefreshingToken) {
              return this.refreshTokenSubject.asObservable().pipe(
                filter((res) => res !== null),
                take(1),
                switchMap(() =>
                  this.getHeadersObservable(excludeAuthorization)
                ),
                switchMap((headers) => {
                  const requestWithRenewedToken = request.clone({
                    setHeaders: headers,
                  });
                  return next.handle(requestWithRenewedToken);
                })
              );
            }

            this.authService.isRefreshingToken = true;

            this.refreshTokenSubject.next(null);

            return this.authService.refreshToken().pipe(
              catchError((err) => {
                //Check if another tab has set a valid access token
                return this.authService.getAccessTokenObservable().pipe(
                  switchMap(() => {
                    if (this.authService.hasValidAccessToken()) {
                      return of(EMPTY);
                    }
                    this.authService.isRefreshingToken = false;
                    this.authService.logout("Unable to refresh token");
                    return throwError(err);
                  })
                );
              }),
              switchMap(() => {
                this.authService.isRefreshingToken = false;
                this.refreshTokenSubject.next(
                  this.authService.getAccessToken()
                );

                return this.getHeadersObservable(excludeAuthorization);
              }),
              switchMap((headers) => {
                const requestWithRenewedToken = request.clone({
                  setHeaders: headers,
                });
                return next.handle(requestWithRenewedToken);
              })
            );
          })
        );
      })
    );
  }
}
