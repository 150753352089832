<div class="close-button">
  <button mat-icon-button mat-dialog-close>
    <i class="material-icons">close</i>
  </button>
</div>
<div class="profile">
  <app-profile
    [contactId]="contactId"
    [hideSecureMessageButton]="hideSecureMessageButton"
  >
  </app-profile>
</div>
