<div class="fields celo-input-outline">
  <mat-form-field appearance="outline" class="fw">
    <mat-label class="label">Location</mat-label>
    <mat-select
      [required]="required"
      id="countryField"
      [(ngModel)]="country"
      #countryField="ngModel"
      (ngModelChange)="filter.value = ''; onKey(''); selected.emit(country)"
      (openedChange)="filter.value = ''; onKey(''); filter.focus()"
      required
      name="countryField"
      #selectRef
    >
      <mat-select-trigger *ngIf="country !== null">
        <div class="flag-option">
          <img
            class="flag"
            [attr.src]="'../../../assets/flags/' + country.shortName + '.svg'"
            [attr.alt]="country.name + ' flag'"
          />
          {{ " " + country.name }}
        </div>
      </mat-select-trigger>
      <mat-form-field class="filter">
        <mat-icon matPrefix>search</mat-icon>
        <input
          id="filter"
          matInput
          #filter
          (keyup)="onKey($event.target.value)"
          (keydown)="$event.stopPropagation()"
          placeholder="Search"
          autocomplete="off"
        />
      </mat-form-field>
      <mat-option
        *ngFor="let countryObj of selectedCountries"
        [value]="countryObj"
      >
        <div class="flag-option">
          <img
            class="flag"
            [attr.src]="
              '../../../assets/flags/' + countryObj.shortName + '.svg'
            "
            [attr.alt]="countryObj.name + ' flag'"
            loading="lazy"
          />
          {{ " " + countryObj.name }}
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
