<div #parent class="fh">
  <form #title class="search-box" (submit)="searchNow()">
    <div class="input-container white celo-input-outline">
      <mat-form-field *ngIf="canShow" class="appear_1" appearance="outline">
        <mat-label
          >Search <span class="lowercase">{{ type }}</span></mat-label
        >
        <input
          matInput
          #input
          type="text"
          [(ngModel)]="queryOriginal"
          (ngModelChange)="change()"
          name="search"
          autocomplete="off"
          cdkFocusInitial
        />
      </mat-form-field>
    </div>
  </form>
  <div
    class="results-box"
    [style.height.px]="parent.offsetHeight - title.offsetHeight"
  >
    <div *ngIf="results && results.length > 0" class="results-list">
      <div class="results-title">Results</div>
      <div
        *ngFor="let result of results"
        matRipple
        class="result celo-hoverable p-3"
        (click)="select(result)"
        [matRippleColor]="color"
      >
        <app-search-message-card
          [sender]="pMap[result.sentBy]"
          [message]="result"
          [highlightString]="queryOriginal"
        ></app-search-message-card>
      </div>
    </div>
    <div *ngIf="searching">
      <app-empty-state
        *ngIf="!no_internet"
        [isLoading]="true"
        [message]="'Searching'"
      ></app-empty-state>
      <app-empty-state
        *ngIf="no_internet"
        [type]="'no_internet'"
        [title]="'No Internet Connection'"
        [message]="
          'Celo needs an internet connection to safely fetch your messages.'
        "
      ></app-empty-state>
    </div>
    <div *ngIf="!searching" class="after-search">
      <div
        *ngIf="results.length === 0"
        class="empty font-grey font-13 t-center"
      >
        <div class="empty-content">
          <div *ngIf="!searched">
            <img
              src="../../../assets/search-conversation.svg"
              class="mx-auto appear_anim_1"
              alt=""
            />
            <div class="title appear_anim_2">
              Search <span class="lowercase">{{ type }}</span>
            </div>
            <div class="subtitle appear_anim_3">
              Enter a keyword to search<span *ngIf="type" class="lowercase">
                the {{ type }}</span
              >.
            </div>
          </div>
          <div *ngIf="searched" class="empty-results">
            <div *ngIf="endReached" class="title appear_anim_1">
              - No results found -
            </div>
            <div *ngIf="!endReached" class="appear_anim_1">
              <img
                src="../../../assets/search-conversation.svg"
                class="mx-auto"
                alt=""
              />
              <div class="title">No results found</div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="searched" class="loadmore mx-auto p-2">
        <div
          *ngIf="oldestMessage && oldestMessage.sentOnUtc && !endReached"
          class="subtitle appear_anim_1"
        >
          until {{ oldestMessage.sentOnUtc | dateFormat: "longDate" }}
        </div>
        <button
          *ngIf="!endReached && !searching"
          mat-button
          class="fw celo-primary-button appear_anim_2"
          (click)="loadMore()"
        >
          <span class="t-center">LOAD MORE MESSAGES</span>
        </button>
        <div *ngIf="results.length > 0 && endReached">
          <div class="p-3 t-center font-13 font-grey appear_anim_3 subtitle">
            - That's all -
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
