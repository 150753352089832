import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ConversationResolver } from "app/conversation.resolver";
import { CanDeactivateGuard } from "app/deactivate.guard";
import { ForceCreatePasswordGuard } from "app/guards/force-create-password.guard";
import { AuthGuard } from "app/guards/auth.guard";
import { PinGuard } from "app/guards/pin.guard";
import { MessagesComponent } from "app/messages/messages.component";
import { RolesHomeComponent } from "./roles-home/roles-home.component";

const routes: Routes = [
  {
    path: ":teamId",
    component: RolesHomeComponent,
    canActivate: [AuthGuard, ForceCreatePasswordGuard, PinGuard],
    children: [
      {
        path: "conversations",
        children: [
          {
            path: ":id/messages",
            component: MessagesComponent,
            canDeactivate: [CanDeactivateGuard],
            data: {
              isRolesTab: true,
            },
          },
          {
            path: ":id/messages/:message_id",
            component: MessagesComponent,
            resolve: {
              conversation: ConversationResolver,
            },
            canDeactivate: [CanDeactivateGuard],
            data: {
              isRolesTab: true,
            },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, PinGuard, ConversationResolver, CanDeactivateGuard],
})
export class RolesRoutingModule {}
