import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { ConversationsService, UserService } from "@modules/core";
import { VoipService } from "@modules/core/services/voip.service";
import { ConversationModelV2, ConversationType } from "@types";
import {
  SubscriptionContainer,
  concatNotNull,
  createDialogConfig,
} from "@utils";
import { ReplaySubject } from "rxjs";

export interface VoipIncomingCallDialogData {
  callId: string;
  conversationId: string;
  maxRingLength: number;
}

export interface VoipIncomingCallDialogResult {
  action: "Join" | "Decline" | "Locked";
}

@Component({
  selector: "app-voip-incoming-call-dialog",
  templateUrl: "./voip-incoming-call-dialog.component.html",
  styleUrls: ["./voip-incoming-call-dialog.component.scss"],
})
export class VoipIncomingCallDialogComponent implements OnInit, OnDestroy {
  public conversationName: string | null = null;
  public conversationType: ConversationType | null = null;
  public conversationPhotoUrl: string | null;

  public otherParticipantUserId: string | null = null;
  public isLoading: boolean = true;
  public isActioned: boolean = false;

  private conversationSubject = new ReplaySubject<ConversationModelV2>(1);
  private conversation$ = this.conversationSubject.asObservable();
  private subscriptions: SubscriptionContainer = new SubscriptionContainer();

  public constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: VoipIncomingCallDialogData,
    private matDialogRef: MatDialogRef<
      VoipIncomingCallDialogComponent,
      VoipIncomingCallDialogResult
    >,
    private conversationsService: ConversationsService,
    private userService: UserService,
    private voipService: VoipService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public static openDialog(
    matDialog: MatDialog,
    data: VoipIncomingCallDialogData
  ): MatDialogRef<
    VoipIncomingCallDialogComponent,
    VoipIncomingCallDialogResult
  > {
    const config = createDialogConfig<VoipIncomingCallDialogData>(data);
    config.disableClose = true;
    config.closeOnNavigation = false;
    return matDialog.open<
      VoipIncomingCallDialogComponent,
      VoipIncomingCallDialogData,
      VoipIncomingCallDialogResult
    >(VoipIncomingCallDialogComponent, config);
  }

  public ngOnInit(): void {
    const conversationSubscription = this.conversationsService
      .getConversation(this.dialogData.conversationId)
      .subscribe({
        next: this.handleConversationLoaded.bind(this),
      });

    this.subscriptions.add(conversationSubscription);
  }

  private handleConversationLoaded(conversation: ConversationModelV2) {
    this.conversationSubject.next(conversation);
    this.conversationType = conversation.type;
    this.conversationName = conversation.name;

    const currentUserId = this.userService.getUserId(true);

    switch (conversation.type) {
      case ConversationType.Chat:
        // Use user avatar
        const participant = conversation.participants.find(
          (p) => p.userId !== currentUserId
        );
        if (!participant) throw new Error("Missing other participant");
        this.conversationName = concatNotNull([
          participant.firstName,
          participant.lastName,
        ]);
        this.otherParticipantUserId = participant.userId;
        break;
      case ConversationType.Group:
        // Use group avatar
        if (!conversation.photoId) break;
        this.conversationPhotoUrl =
          this.conversationsService.getConversationProfileUri(
            conversation.id,
            conversation.photoId
          );
        break;
    }

    this.isLoading = false;
  }

  public onDecline() {
    this.matDialogRef.close({
      action: "Decline",
    });
  }

  public onJoin() {
    this.isActioned = true;
    const subscription = this.conversation$.subscribe({
      next: (conversation) => {
        const currentUserId = this.userService.getUserId();
        const currentUserConversationParticipant =
          conversation.participants.find((p) => p.userId === currentUserId);
        const teamId = currentUserConversationParticipant.teamId;

        // The design specifies that we should navigate to the conversation, but if the user is already at that conversation
        // then clicking join would appear to do nothing so... ¯\_(ツ)_/¯
        if (
          this.conversationsService.isAlreadyAtConversationRoute(
            this.dialogData.conversationId
          )
        ) {
          this.voipService.navigateToJoinCall(
            this.dialogData.conversationId,
            this.dialogData.callId
          );
        } else {
          this.conversationsService.navigateToConversation(
            this.dialogData.conversationId,
            teamId
          );
        }

        this.matDialogRef.close({
          action: "Join",
        });
      },
      error: () => {
        this.isActioned = false;
      },
    });
    this.subscriptions.add(subscription);
  }
}
