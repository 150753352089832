import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  ApiRequestOptions,
  ContactModelPagedResult,
  GetCompanyContactsRequestOptions,
  GetCompaniesRequestOptions,
  CompanyBasicModelApiPagedResult,
} from "types";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class CompaniesService {
  public constructor(private apiService: ApiService) {}

  public getContacts({
    companyId,
    fetchAll,
    ...options
  }: GetCompanyContactsRequestOptions &
    ApiRequestOptions): Observable<ContactModelPagedResult> {
    const path = `/api/Companies/${companyId}/Contacts`;
    const defaultOptions: Omit<GetCompanyContactsRequestOptions, "companyId"> =
      {
        pageSize: 100,
      };
    if (fetchAll) {
      return this.apiService.getAllByOffset({
        path,
        queryParams: { ...defaultOptions, ...options },
      });
    }
    return this.apiService.get({
      path,
      queryParams: { ...defaultOptions, ...options },
    });
  }

  public getCompanies({
    fetchAll,
    ...options
  }: GetCompaniesRequestOptions &
    ApiRequestOptions): Observable<CompanyBasicModelApiPagedResult> {
    const path = `/api/v2/Companies`;
    const defaultOptions: GetCompaniesRequestOptions = {
      pageSize: 100,
    };
    if (fetchAll) {
      return this.apiService.getAllByOffset({
        path,
        queryParams: { ...defaultOptions, ...options },
      });
    }
    return this.apiService.get({
      path,
      queryParams: { ...defaultOptions, ...options },
    });
  }
}
