import { ConversationType, MessageModel } from "@types";

export interface NotificationData extends NotificationOptions {
  id: string;
  title: string;
  target: string;
}

export interface MessageNotificationData {
  teamId?: string;
  isTeamChat?: boolean;
  isCurrentUserTeamMember?: boolean;
  isCurrentUserOnCallForTeam?: boolean;
  conversationType?: ConversationType;
}

export const getNotificationTargetUrl = (
  message: MessageModel,
  data?: MessageNotificationData
): string => {
  // Navigate to roles tab if this message is for a role the current user is a member of
  if (data?.teamId && data?.isTeamChat && data?.isCurrentUserTeamMember) {
    const url = `/roles/${data.teamId}/conversations/${message.conversationId}/messages`;
    return url;
  }

  if (data?.conversationType === ConversationType.External) {
    const url = `/external/${message.conversationId}/messages`;
    return url;
  }

  const url = `/conversations/${message.conversationId}/messages`;
  return url;
};
