<button
  mat-button
  [disableRipple]="true"
  class="basic celo-link"
  [style.font-size]="fontSize"
  [style.font-weight]="fontWeight"
  [style.padding]="padding"
>
  {{ text }}
</button>
