import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "@env";
import { SharedService, UserAccountService } from "@modules/core";

export interface LibraryShareImgData {
  fromConversationId?: string;
  fromMessageId?: string;
}

export type LibraryShareImgResult = boolean;

@Component({
  selector: "app-library-share-img",
  templateUrl: "./library-share-img.component.html",
  styleUrls: ["./library-share-img.component.scss"],
})
export class LibraryShareImgComponent implements OnInit {
  conversations: Array<any> = [];
  userAccount: any;
  isLoading = false;
  convoId = null;
  photos: any[];
  fromConversationType: string;
  toConversationType: string;
  isFromLibrary: boolean = false;
  patientUid = undefined;
  patientUids: string[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LibraryShareImgData,
    private userService: UserAccountService,
    private sharedService: SharedService,
    private ref: MatDialogRef<LibraryShareImgComponent, LibraryShareImgResult>,
    private router: Router
  ) {}

  ngOnInit() {
    const instance = this;
    this.userService.getUserAccount(false, function (acc) {
      instance.userAccount = acc;
    });
    this.patientUid = this.getPatientUid();
    this.patientUids = this.getUniquePatientIds();
  }

  getPatientUid() {
    const ids = this.getUniquePatientIds();
    if (ids.length === 1) {
      this.patientUid = ids[0];
    }
    return this.patientUid;
  }

  getUniquePatientIds(): string[] {
    const ids = [];
    for (const photo of this.photos) {
      if (
        photo.patientData &&
        photo.patientData.uid &&
        ids.indexOf(photo.patientData.uid) === -1
      ) {
        ids.push(photo.patientData.uid);
      }
    }
    return ids;
  }

  openCreateConvo() {
    const qp = { isPhotoShare: true };
    let idx = 0;
    for (const photo of this.photos) {
      qp[`photo${idx}`] = photo.id;
      idx += 1;
    }
    this.ref.close(true);
    this.router.navigate(
      [
        "/conversations/new-message",
        { outlets: { sidenav: "docs", primary: "company" } },
      ],
      {
        queryParams: qp,
      }
    );
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  send(convoId: string) {
    const photos = this.photos.map((photo) => {
      return {
        photoId: photo.id,
        content: "",
        marker: this.uuidv4(),
      };
    });
    this.isLoading = true;

    const path = `${environment.celoApiEndpoint}/api/v2/Conversations/${convoId}/media/send`;
    this.sharedService.postObjectById(path, {}, photos).subscribe(() => {
      this.isLoading = false;
      this.ref.close(true);
      this.router.navigate([`/conversations/${convoId}/messages`]);
    });
  }
}
