import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FileUploader } from "ng2-file-upload";

@Component({
  selector: "app-drop-box",
  templateUrl: "./drop-box.component.html",
  styleUrls: ["./drop-box.component.scss"],
})
export class DropBoxComponent implements OnInit {
  @Output() browse = new EventEmitter();
  dropZoneActive: string;
  uploader: FileUploader;
  hideSubtitle: boolean;
  singleFileOnly = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public uploaderInput: FileUploader,
    private ref: MatDialogRef<DropBoxComponent>
  ) {}

  ngOnInit() {
    this.uploader = this.uploaderInput;
    this.hideSubtitle = this.uploaderInput["hideSubtitle"];
    this.singleFileOnly = this.uploaderInput["singleFileOnly"];
  }

  browseNow() {
    this.browse.emit();
    this.closeNow();
  }

  closeNow() {
    this.ref.close(this.uploader);
  }

  dropZoneState(state: boolean) {
    this.dropZoneActive = state ? "valid" : "";
  }

  handleDrop(files: FileList) {
    this.ref.close(this.uploader);
  }
}
