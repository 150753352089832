import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { map, merge, ReplaySubject, timer, withLatestFrom } from "rxjs";
import { DateFormatPipe } from "../pipes/dateFormat/date-format.pipe";

@Component({
  selector: "app-celo-user-status",
  templateUrl: "./celo-user-status.component.html",
  styleUrls: ["./celo-user-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class CeloUserStatusComponent implements OnChanges {
  @Input() public lastActiveOnUtc: string | null | undefined = null;

  private lastActiveOnUtcSubject = new ReplaySubject<string>(1);

  public text$ = merge(
    timer(0, 5000).pipe(
      withLatestFrom(this.lastActiveOnUtcSubject),
      map(([, lastActiveOnUtc]) => lastActiveOnUtc)
    ),
    this.lastActiveOnUtcSubject.asObservable()
  ).pipe(
    map((lastActiveOnUtc) =>
      this.dateFormatPipe.transform(lastActiveOnUtc, "lastActiveTime")
    )
  );

  public isOnline$ = this.text$.pipe(map((text) => text === "Online"));

  public constructor(private dateFormatPipe: DateFormatPipe) {}

  public ngOnChanges(changes: SimpleChanges): void {
    this.lastActiveOnUtcSubject.next(this.lastActiveOnUtc);
  }
}
