import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "@env";
import {
  AlertService,
  ConversationService,
  ConversationsService,
  SharedService,
  SnackbarService,
} from "@modules/core";
import { AlertComponent } from "@modules/core/components/alert/alert.component";
import { NetworkUserPickerMode } from "@modules/user-picker/network-user-picker.service";
import { UserPickerService } from "@modules/user-picker/user-picker.service";
import { Store } from "@ngrx/store";
import {
  Conversation,
  ConversationParticipantModelV2,
  ConversationType,
} from "@types";
import { isNotNullOrUndefined } from "@utils";
import {
  conversationsFeature,
  ConversationsPageActions,
  selectConversation,
} from "app/state";
import { Observable } from "rxjs";
import { filter, pairwise, tap } from "rxjs/operators";

export enum MessageDetailOpenWindowType {
  Invite = "invite",
}

@Component({
  selector: "app-message-detail",
  templateUrl: "./message-detail.component.html",
  styleUrls: ["./message-detail.component.scss", "../../../celo-input.scss"],
})
export class MessageDetailComponent implements OnInit, OnDestroy, OnChanges {
  hasPatient = false;
  patient: any = {
    uid: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    gender: null,
  };

  userId = "";
  cName = "";
  cSubject = "";
  @Input() participants: any[];
  @Input() activeAdminList: string[] = [];
  @Input() conversation: Conversation;
  @Input() userAccount: any;
  @Input() leftChat: boolean;
  @Input() type: ConversationType | "UserProfile" = ConversationType.Chat;
  @Input() chatParticipantId = "";
  @Input() chatParticipant: any;
  @Input() refreshCount = 0;
  @Output() leftChatEvent = new EventEmitter<boolean>();
  @Output() openWindow = new EventEmitter<MessageDetailOpenWindowType>();
  @Output() closeClick = new EventEmitter();
  @Output() isAdmin = new EventEmitter<boolean>();
  @Output() conversationChange = new EventEmitter<any>();
  @Output() userDataChanged = new EventEmitter<any>();
  @ViewChild("form1") form1: any;
  saving: boolean;
  formChangesSubscription: any;
  hideSecureMessageButton = false;

  // Used in template
  private isConversationUpdating = false;
  isInConversation = true;

  @Input() showParticipantProfile = false;
  @Input() profileInFocus: string = "";

  constructor(
    private alertService: AlertService,
    private sharedService: SharedService,
    private conversationService: ConversationService,
    private userPickerService: UserPickerService,
    private conversationsService: ConversationsService,
    private snackbarService: SnackbarService,
    private matDialog: MatDialog,
    private store: Store
  ) {}

  ngOnInit() {
    if (this.formChangesSubscription) {
      this.formChangesSubscription = this.form1.form.valueChanges.subscribe(
        (x) => {
          this.saving = false;
        }
      );
    }

    this.store
      .select(selectConversation(this.conversation.id))
      .pipe(filter(isNotNullOrUndefined))
      .subscribe({
        next: (conversation) => {
          this.conversation = conversation;
          this.setViewData(this.conversation);
        },
      });

    this.store
      .select(conversationsFeature.selectIsSaving)
      .pipe(pairwise())
      .subscribe({
        next: ([isSavingPrevious, isSavingCurrent]) => {
          this.saving = isSavingCurrent;

          if (isSavingPrevious && !isSavingCurrent) {
            this.form1.form.markAsPristine();
            this.conversationChange.emit(this.conversation);
          }
        },
      });
  }

  ngOnChanges() {
    this.hideSecureMessageButton = false;
    if (
      this.type == ConversationType.Chat ||
      this.type == ConversationType.SelfChat ||
      this.type == ConversationType.TeamChat
    ) {
      this.hideSecureMessageButton = true;
    }

    this.isInConversation = !this.conversation.participants.find(
      (cp) => cp.userId === this.userAccount.userId
    ).leftOnUtc;
  }

  ngOnDestroy() {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  updateConvo(data) {
    if (this.type === "UserProfile") return;

    this.store.dispatch(
      ConversationsPageActions.editConversationDetails({
        conversationId: this.conversation.id,
        conversationType: this.type,
        subject: this.cSubject,
        name: this.cName ? this.cName.trim() : this.cName,
        patientData: this.hasPatient ? this.patient : null,
      })
    );
  }

  private setViewData(convo: any) {
    this.cSubject = this.conversation.subject;
    this.cName = this.conversation.name;
    if (this.conversation.patientData) {
      this.patient = { ...this.conversation.patientData };
      this.hasPatient = true;
    } else {
      this.patient = {
        uid: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        gender: null,
      };
      this.hasPatient = false;
    }
  }

  cancelConvo() {
    this.setViewData(this.conversation);
  }

  remove(participantId: string, participantName?) {
    this.alertService
      .confirm(
        "Remove participant",
        "Are you sure you want to remove " +
          (participantName ? participantName : "this participant") +
          "?",
        "Remove"
      )
      .subscribe((isConfirm) => {
        if (isConfirm) {
          const x: Array<any> = [{ userId: participantId }];
          const path =
            environment.celoApiEndpoint +
            "/api/Conversations/{conversationId}/Participants".replace(
              "{" + "conversationId" + "}",
              String(this.conversation.id)
            );

          this.sharedService.deleteObjectById(path, x).subscribe((convo) => {
            this.participants = this.participants.filter(
              (p: any) => p.userId !== participantId
            );
            this.conversationChange.emit(convo);
          });
        }
      });
  }

  isLastActiveAdmin() {
    if (!this.userAccount) {
      return false;
    }
    if (
      this.activeAdminList &&
      this.activeAdminList.length == 1 &&
      this.activeAdminList[0] == this.userAccount.userId
    ) {
      return true;
    }

    return false;
  }

  isLastActiveParticipant() {
    for (const participant of this.participants) {
      if (
        participant.isActive &&
        participant.userId != this.userAccount.userId
      ) {
        return false;
      }
    }
    return true;
  }

  leaveConvo() {
    if (this.isLastActiveAdmin() && !this.isLastActiveParticipant()) {
      this.alertService.confirm(
        "Assign a new " + this.type.toLocaleLowerCase() + " admin",
        "Please assign a new admin before leaving this " +
          this.type.toLocaleLowerCase() +
          ". ",
        "OK",
        "",
        true
      );
      return;
    }
    const i = this;
    this.alertService
      .confirm(
        "Leave " + this.type.toLocaleLowerCase(),
        "If you choose to leave, you will no longer be a part of this " +
          this.type.toLowerCase() +
          " and will not receive further messages from this " +
          this.type.toLowerCase() +
          ".",
        "Leave",
        "Cancel",
        false
      )
      .subscribe((confirm: boolean) => {
        if (confirm) {
          const path =
            environment.celoApiEndpoint +
            "/api/v2/conversations/{conversationId}/leave".replace(
              "{" + "conversationId" + "}",
              String(this.conversation.id)
            );
          this.sharedService.postObjectById(path).subscribe((resp) => {
            i.leftChat = true;
            i.leftChatEvent.emit(true);
          }),
            (err) => {
              this.alertService.confirm(
                "",
                this.sharedService.STANDARD_ERROR_MESSAGE,
                "Ok",
                "",
                true
              );
            };
        }
      });
  }

  showProfile(participant: ConversationParticipantModelV2): void {
    this.showParticipantProfile = true;
    // this.chatParticipantId = participant.userId;
    this.profileInFocus = participant.userId;
  }

  onAddParticipants(): void {
    const currentlySelectedUsersIds = this.participants
      .map((p) => p.userId as string)
      .filter(isNotNullOrUndefined);

    this.userPickerService.openNetworkUserPicker({
      header: "Add Participants",
      selectedHeader: "Participants",
      searchPlaceholder: "Search network",
      selectedQuantityLabels: {
        one: "participant",
        plural: "participants",
        zero: "participants",
      },
      initiallySelectedUserIds: currentlySelectedUsersIds,
      disabledUserIds: currentlySelectedUsersIds,
      excludeTeams: true,
      mode: NetworkUserPickerMode.MULTIPLE_SELECT,
      submitCallback: (users) => {
        const participantUserIds = users
          .filter((u) => !u.isDisabled)
          .map((u) => u.id);
        return this.addParticipants(participantUserIds);
      },
    });
  }

  private addParticipants(participantUserIds: string[]): Observable<void> {
    this.isConversationUpdating = true;
    return this.conversationService
      .addParticipants(this.conversation.id, participantUserIds)
      .pipe(
        tap({
          next: (conversation) => {
            this.conversationChange.emit(conversation);
          },
          error: (e) => {
            this.isConversationUpdating = false;
          },
          complete: () => {
            this.isConversationUpdating = false;
          },
        })
      ) as Observable<void>;
  }

  onExportToPdf(): void {
    const ref = AlertComponent.openDialog(this.matDialog, {
      title: "Warning",
      message: "Sharing content outside of Celo is your responsibility",
      acceptButtonText: "Confirm Export",
      closeButtonText: "Cancel",
      acceptOnly: false,
    });

    ref.afterClosed().subscribe((confirm: boolean) => {
      if (!confirm) return;

      this.conversationsService
        .exportToPdf(this.conversation.id)
        .subscribe(({ operationId }) => {
          this.snackbarService.show(
            "Exporting PDF... Your download will start automatically",
            10
          );
          console.log("Operation ID:", operationId);
        });
    });
  }
}
