import { BehaviorSubject } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import { ClientAppSettings } from "@types";
import { SubscriptionContainer } from "@utils";

@Injectable({
  providedIn: "root",
})
export class SettingsService implements OnDestroy {
  private settingsSubject = new BehaviorSubject<ClientAppSettings | null>(null);
  public settings$ = this.settingsSubject.asObservable();

  private subscriptions: SubscriptionContainer = new SubscriptionContainer();

  public constructor(
    private apiService: ApiService,
    private authService: AuthService
  ) {
    const isAuthenticatedSubscription =
      this.authService.isAuthenticated$.subscribe({
        next: () => {
          this.loadSettings();
        },
      });
    this.subscriptions.add(isAuthenticatedSubscription);
    this.loadSettings();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private loadSettings() {
    if (!this.authService.isAuthenticated()) {
      this.settingsSubject.next(null);
      return;
    }

    this.getSettingsFromApi().subscribe({
      next: (settings) => this.settingsSubject.next(settings),
      error: () => this.settingsSubject.next(null),
    });
  }

  private getSettingsFromApi() {
    const path = "/api/Settings";
    return this.apiService.get<ClientAppSettings>({
      path,
    });
  }

  public getSettings(): ClientAppSettings | null {
    return this.settingsSubject.value;
  }
}
