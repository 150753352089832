<div mat-dialog-title class="title-container">
  <mat-icon svgIcon="home_outline"></mat-icon>
  <span class="title">{{ title }}</span>
</div>

<mat-dialog-content class="dialog-content">
  <div id="content">{{ content }}</div>
</mat-dialog-content>

<mat-dialog-actions [align]="'center'">
  <button
    id="close"
    [mat-dialog-close]="true"
    class="celo-primary-button uppercase"
  >
    Done
  </button>
</mat-dialog-actions>
