import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { LibraryService } from "@modules/library/library.service";
import { LibraryItemComponent } from "@modules/library/library-item/library-item.component";

@Component({
  selector: "app-media-details",
  templateUrl: "./media-details.component.html",
  styleUrls: ["./media-details.component.scss"],
})
export class MediaDetailsComponent
  extends LibraryItemComponent
  implements OnInit
{
  loaded = false;
  @Input() ownFile: boolean;
  @Input() type = "photo";
  @Input() public disabled: boolean = false;
  @Input() public exported: boolean = false;

  constructor(libraryService: LibraryService) {
    super(libraryService);
  }

  protected getType() {
    return this.type;
  }

  public handleClick() {
    if (this.disabled) return;
    this.onClick();
  }
}
