<div class="name-field">
  <div class="avatar">
    <app-avatar
      [name]="firstName + ' ' + lastName"
      [userId]="userId"
      [width]="40"
      [height]="40"
      [hasImage]="
        userAccount.userId === userId ? !!userAccount.picture : !!profilePic
      "
      [picUrl]="
        userAccount.userId === userId ? userAccount.picture : profilePic
      "
      [showBadge]="showBadge"
      [badgeSize]="18"
      [workspaces]="workspaces"
      [identityVerificationStatus]="identityVerificationStatus"
      [showTooltip]="false"
      [user]="user"
      [isExternal]="user?.isExternal ?? false"
    >
    </app-avatar>
  </div>
  <div class="name ml-2 font-14">
    <div *ngIf="userAccount.userId === userId">
      {{
        mustShowName
          ? firstName + " " + lastName
          : firstName + " " + lastName + " (You)"
      }}
    </div>
    <div *ngIf="userAccount.userId !== userId">
      {{ firstName }} {{ lastName }}
    </div>
    <div *ngIf="description" class="font-12 font-grey">
      {{ description }}
    </div>
  </div>
</div>
