<div
  *ngIf="
    isLoadingDepartment ||
      isLoadingColleagues ||
      isLoadingConnections ||
      isLoadingTeams ||
      (networkPage === 0 && isLoadingNetwork);
    else results
  "
>
  <app-empty-state
    [spinner]="true"
    [animation]="false"
    [isLoading]="true"
    [message]="' '"
    [diameter]="16"
    [stroke]="2"
  ></app-empty-state>
</div>

<ng-template #results>
  <!-- Connections -->
  <div
    *ngIf="
      connections &&
      connections.contacts &&
      connections.contacts.length &&
      !hideConnections
    "
    class="section"
  >
    <div class="header">
      <div class="title">Connections</div>
      <hr />
    </div>
    <div class="content">
      <mat-checkbox
        *ngIf="selectable"
        class="select-all"
        (change)="groupAction($event.checked, connections, 'connection')"
        [checked]="
          connections.state === 'checked' || connections.state === 'disabled'
        "
        [indeterminate]="connections.state === 'indeterminate'"
        [disabled]="connections.state === 'disabled'"
      >
        <span>Select All</span>
      </mat-checkbox>
      <app-contact-list
        [uneditableContactIds]="uneditableContactIds"
        [isAddingParticipants]="isAddingParticipants"
        *ngIf="connections"
        [contactGroup]="connections"
        [type]="'connections'"
        [selectable]="selectable"
        [userAccount]="userAccount"
        [selectedContactsMap]="selectedContactsMap"
        (contactClicked)="emitClick($event, 'connection')"
        (contactSelected)="emaitContact($event, 'connection')"
        (remove)="remove.emit($event)"
      ></app-contact-list>
    </div>
  </div>

  <!-- Teams/Roles -->
  <div *ngIf="teams.length" class="section">
    <div class="header">
      <div class="title">Roles</div>
      <div class="subtitle">Found in your workspaces</div>
      <hr />
    </div>
    <div class="content">
      <app-basic-user-card
        *ngFor="let team of teams"
        [name]="team.name"
        [description]="teamDescriptions.get(team.id)"
        leftContentMargin="20px"
        nameFontSize="17px"
        descriptionFontSize="13px"
        class="team celo-hoverable celo-cursor-pointer celo-no-select"
        (click)="handleRoleClicked(team)"
        mat-ripple
      >
        <app-roles-icon start></app-roles-icon>
      </app-basic-user-card>
    </div>
  </div>

  <!-- Colleagues -->
  <div
    *ngIf="colleagues && colleagues.contacts && colleagues.contacts.length"
    class="section"
  >
    <div class="header">
      <div class="title">Colleagues</div>
      <div class="subtitle">Found in your workspaces</div>
      <hr />
    </div>
    <div class="content">
      <mat-checkbox
        *ngIf="selectable"
        class="select-all"
        (change)="groupAction($event.checked, colleagues, 'colleague')"
        [checked]="
          colleagues.state === 'checked' || colleagues.state === 'disabled'
        "
        [indeterminate]="colleagues.state === 'indeterminate'"
        [disabled]="colleagues.state === 'disabled'"
      >
        <span>Select All</span>
      </mat-checkbox>
      <app-contact-list
        [uneditableContactIds]="uneditableContactIds"
        [isAddingParticipants]="isAddingParticipants"
        *ngIf="colleagues"
        [contactGroup]="colleagues"
        [type]="'colleagues'"
        [selectable]="selectable"
        [userAccount]="userAccount"
        [selectedContactsMap]="selectedContactsMap"
        (contactClicked)="emitClick($event, 'colleague')"
        (contactSelected)="emaitContact($event, 'colleague')"
        (remove)="remove.emit($event)"
      ></app-contact-list>
    </div>
  </div>

  <!-- Workspaces/departments -->
  <div *ngFor="let department of groupedLocalContactsDepartments">
    <div
      *ngIf="department.contacts && department.contacts.length"
      class="section"
    >
      <div class="header">
        <div class="title">{{ department.departmentName }}</div>
        <div class="subtitle">
          <span>
            {{ department.workspaceName }}
          </span>
          <span *ngIf="department.workspaceVerificationStatus === 'Verified'">
            <app-badge
              class="inline-block"
              [clickable]="false"
              [isWorkspaceVerified]="true"
            ></app-badge>
          </span>
        </div>
        <hr />
      </div>
      <div class="content">
        <mat-checkbox
          *ngIf="selectable"
          class="select-all"
          (change)="groupAction($event.checked, department, 'colleague')"
          [checked]="
            department.state === 'checked' || department.state === 'disabled'
          "
          [indeterminate]="department.state === 'indeterminate'"
          [disabled]="department.state === 'disabled'"
        >
          <span>Select All</span>
        </mat-checkbox>
        <app-contact-list
          [companyInFocus]="{ id: department.companyId }"
          [companyInFocusId]="department.companyId"
          [uneditableContactIds]="uneditableContactIds"
          [isAddingParticipants]="isAddingParticipants"
          *ngIf="department.contacts"
          [contactGroup]="department"
          [type]="'workspace'"
          [selectable]="selectable"
          [userAccount]="userAccount"
          [selectedContactsMap]="selectedContactsMap"
          (contactClicked)="emitClick($event, 'colleague')"
          (contactSelect)="emaitContact($event, 'colleague')"
          (remove)="remove.emit($event)"
        ></app-contact-list>
      </div>
    </div>
  </div>

  <!-- People/Network -->
  <div
    *ngIf="
      (network?.contacts?.length || !isVerified || !isDiscoverable) &&
      !hideNetwork
    "
    class="section"
  >
    <div class="header">
      <div class="title">People</div>
      <div class="subtitle">Results from Celo network</div>
      <hr />
    </div>

    <!-- Verification CTA -->
    <div *ngIf="!isVerified" class="empty">
      <img
        src="../../../../assets/network-empty.png"
        alt="No results"
        width="128"
        height="128"
      />
      <div>
        <h4 class="mat-body-2">Get suggestions</h4>
        <p class="mat-body">
          Verify your identity or workspace to get suggestions and search the
          network.
        </p>
        <a class="celo-link" (click)="verifyIdentity()">
          <button mat-button>Verify Identity</button>
        </a>
      </div>
    </div>

    <!-- Discoverability CTA -->
    <div *ngIf="isVerified && !discoverable" class="empty">
      <img
        src="../../../../assets/network-empty.png"
        alt="No results"
        width="128"
        height="128"
      />
      <div>
        <h4 class="mat-body-2">Join the Celo community</h4>
        <p class="mat-body">
          You can get suggestions and search the network by becoming
          discoverable.
        </p>
        <a class="celo-link" routerLink="/edit/privacy">
          <button mat-button>Go to settings</button>
        </a>
      </div>
    </div>

    <!-- People/Network results -->
    <div *ngIf="isVerified && isDiscoverable" class="content">
      <mat-checkbox
        *ngIf="selectable"
        class="select-all"
        (change)="groupAction($event.checked, network)"
        [checked]="network.state === 'checked' || network.state === 'disabled'"
        [indeterminate]="network.state === 'indeterminate'"
        [disabled]="network.state === 'disabled'"
      >
        <span>Select All</span>
      </mat-checkbox>
      <app-contact-list
        [uneditableContactIds]="uneditableContactIds"
        [isAddingParticipants]="isAddingParticipants"
        [contactGroup]="network"
        [type]="'network'"
        [selectable]="selectable"
        [userAccount]="userAccount"
        [selectedContactsMap]="selectedContactsMap"
        (contactClicked)="emitClick($event, 'people')"
        (contactSelected)="emaitContact($event, 'people')"
        (remove)="remove.emit($event)"
      ></app-contact-list>
      <div *ngIf="isLoadingNetwork">
        <app-empty-state
          [spinner]="true"
          [animation]="false"
          [isLoading]="true"
          [message]="' '"
          [diameter]="16"
          [stroke]="2"
        ></app-empty-state>
      </div>
      <div #networkAnchor id="networkAnchor"></div>
    </div>
  </div>

  <!-- No results -->
  <div
    *ngIf="
      !connections?.contacts?.length &&
      !colleagues?.contacts?.length &&
      !network?.contacts?.length &&
      !teams.length &&
      !groupedLocalContactsDepartments?.length &&
      isVerified &&
      isDiscoverable
    "
    class="empty-state"
  >
    <img src="../../../../assets/no-results-found-directory-v2.svg" alt="" />
    <div class="title">No results found</div>
    <div>
      <div class="subtitle">
        You can search for people by their name, position or department.
      </div>
      <div class="invite-link">
        <div class="subtitle invite-subtitle">
          Invite your colleagues directly to Celo using your personal invite
          link
        </div>
        <a class="celo-link" (click)="copyProfileLinkButtonClick()"
          ><button mat-button>Share invite link</button></a
        >
      </div>
    </div>
  </div>
</ng-template>
