<div class="content">
  <div class="message-preview">
    <div *ngIf="message.sentOnUtc" class="date">
      {{ message.sentOnUtc | dateFormat: "longDate" }}
    </div>
    <div class="preview-content-container">
      <div class="avatar">
        <app-avatar
          class="avatar-container"
          [title]="user.title"
          [name]="user.firstName + ' ' + user.lastName"
          [name_identity_verified_as]="
            user.legalFirstName + ' ' + user.lastName
          "
          [userId]="user.userId"
          [width]="40"
          [height]="40"
          [hasImage]="!!user.profilePicture"
          [picUrl]="user.profilePicture"
          [workspaces]="user.workspaces"
          [identityVerificationStatus]="user.identityVerificationStatus"
          [user]="user"
        >
        </app-avatar>
      </div>
      <div class="message-card-container">
        <app-message-card
          [userAccount]="userAccount"
          [message]="message"
          [pMap]="pMap"
          [refreshCount]="refreshCount"
          [pic_size]="'small'"
          [conversationType]="conversationType"
        ></app-message-card>
      </div>
    </div>
  </div>
  <div class="h_1">
    <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
  </div>
  <div class="scrollable">
    <div *ngIf="deletedBy">
      <div class="title">DELETED BY</div>
      <div class="participants-statuses-grid">
        <app-avatar-with-name
          [firstName]="deletedBy.firstName"
          [lastName]="deletedBy.lastName"
          [description]="deletedBy.date | dateFormat: 'medium'"
          [userId]="deletedBy.userId"
          [profilePic]="deletedBy.profilePicture"
          [userAccount]="userAccount"
          [showBadge]="true"
          [workspaces]="deletedBy.workspaces"
          [identityVerificationStatus]="deletedBy.identityVerificationStatus"
          [user]="deletedBy"
        >
        </app-avatar-with-name>
      </div>
    </div>

    <div *ngIf="seenBy.length !== 0">
      <div class="title">SEEN BY</div>
      <div class="participants-statuses-grid">
        <div *ngFor="let user of seenBy" class="mb-2">
          <app-avatar-with-name
            [firstName]="user.firstName"
            [lastName]="user.lastName"
            [description]="user.date | dateFormat: 'medium'"
            [userId]="user.userId"
            [profilePic]="user.profilePicture"
            [userAccount]="userAccount"
            [showBadge]="true"
            [workspaces]="user.workspaces"
            [identityVerificationStatus]="user.identityVerificationStatus"
            [user]="user"
          >
          </app-avatar-with-name>
        </div>
      </div>
    </div>

    <div class="title" *ngIf="deliveredTo.length !== 0">
      <mat-icon>check_circle</mat-icon>
      <span>DELIVERED TO</span>
    </div>

    <div class="participants-statuses-grid">
      <div *ngFor="let user of deliveredTo" class="mb-2">
        <app-avatar-with-name
          [firstName]="user.firstName"
          [lastName]="user.lastName"
          [description]="user.date | dateFormat: 'medium'"
          [userId]="user.userId"
          [profilePic]="user.profilePicture"
          [userAccount]="userAccount"
          [showBadge]="true"
          [workspaces]="user.workspaces"
          [identityVerificationStatus]="user.identityVerificationStatus"
          [user]="user"
        >
        </app-avatar-with-name>
      </div>
    </div>
  </div>
</div>
