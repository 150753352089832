import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "@env";

@Pipe({
  name: "capitalizeFirstLetter",
})
export class CapitalizeFirstLetterPipe implements PipeTransform {
  public transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value !== "string") {
      this.logErrorMessage(value);
      return value;
    }

    const firstNonWhitespaceCharIndex = value.search(/\S/);
    if (firstNonWhitespaceCharIndex === -1) return value;

    return (
      value.substring(0, firstNonWhitespaceCharIndex) +
      value.charAt(firstNonWhitespaceCharIndex).toUpperCase() +
      value.substring(firstNonWhitespaceCharIndex + 1)
    );
  }

  private logErrorMessage(value: unknown) {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.error(
        `'capitalizeFirstLetter' received non-string type '${typeof value}'`
      );
    }
  }
}
