<div
  *ngIf="
    type !== 'connections' ||
    (type === 'connections' &&
      contactGroup &&
      contactGroup.contacts &&
      contactGroup.contacts.length)
  "
>
  <div
    *ngIf="title"
    [ngClass]="{ top_margin: !noTopMargin }"
    class="header celo-cursor-pointer"
    (click)="loadContacts(); raiseAcordinActionEvents()"
  >
    <div class="title celo-elipsis">
      {{ title }}
      <span
        ><app-badge
          *ngIf="isWorkspaceVerified"
          class="inline-block"
          [clickable]="false"
          [isWorkspaceVerified]="true"
        ></app-badge
      ></span>
    </div>
    <div *ngIf="!disableExpansion" class="arrow">
      <i *ngIf="!hidden" class="material-icons-round">expand_less</i>
      <i *ngIf="hidden" class="material-icons-round">chevron_right</i>
    </div>
  </div>

  <div class="content" *ngIf="!hidden">
    <mat-checkbox
      *ngIf="
        selectable &&
        contactGroup.contacts &&
        contactGroup.contacts.length &&
        (type !== 'workspace' || page_type === 'new_broadcast')
      "
      class="select-all"
      (change)="groupAction($event.checked, contactGroup)"
      [checked]="
        contactGroup.state === 'checked' || contactGroup.state === 'disabled'
      "
      [indeterminate]="contactGroup.state === 'indeterminate'"
      [disabled]="contactGroup.state === 'disabled'"
    >
      <span>Select All</span>
    </mat-checkbox>
    <div *ngIf="type === 'workspace'">
      <div *ngFor="let department of groupedLocalContactsDepartments">
        <mat-checkbox
          *ngIf="selectable"
          class="select-all"
          (change)="groupAction($event.checked, department)"
          [checked]="
            department.state === 'checked' || department.state === 'disabled'
          "
          [indeterminate]="department.state === 'indeterminate'"
          [disabled]="department.state === 'disabled'"
        >
          <div>{{ department.departmentName }}</div>
        </mat-checkbox>
        <div *ngIf="!selectable" class="select-all">
          {{ department.departmentName }}
        </div>
        <app-contact-list
          [companyInFocus]="companyInFocus"
          [companyInFocusId]="companyInFocusId"
          [uneditableContactIds]="uneditableContactIds"
          [isAddingParticipants]="isAddingParticipants"
          [contactGroup]="department"
          [type]="'workspace'"
          [selectable]="selectable"
          [userAccount]="userAccount"
          [selectedContactsMap]="selectedContactsMap"
          (contactClicked)="contactClicked.emit($event)"
          (contactSelected)="contactSelected.emit($event)"
          (remove)="remove.emit($event)"
        ></app-contact-list>
      </div>
    </div>
    <div *ngIf="type === 'connections'">
      <app-contact-list
        [uneditableContactIds]="uneditableContactIds"
        [isAddingParticipants]="isAddingParticipants"
        [contactGroup]="contactGroup"
        [type]="'connections'"
        [selectable]="selectable"
        [userAccount]="userAccount"
        [selectedContactsMap]="selectedContactsMap"
        (contactClicked)="contactClicked.emit($event)"
        (contactSelected)="contactSelected.emit($event)"
        (remove)="remove.emit($event)"
      ></app-contact-list>
    </div>
    <div *ngIf="type === 'suggestions'">
      <app-contact-list
        [uneditableContactIds]="uneditableContactIds"
        [isAddingParticipants]="isAddingParticipants"
        [contactGroup]="contactGroup"
        [type]="'suggestions'"
        [selectable]="selectable"
        [userAccount]="userAccount"
        [selectedContactsMap]="selectedContactsMap"
        (contactClicked)="contactClicked.emit($event)"
        (contactSelected)="contactSelected.emit($event)"
        (remove)="remove.emit($event)"
      ></app-contact-list>
    </div>
    <div *ngIf="loading" class="loading">
      <app-empty-state
        [spinner]="true"
        [animation]="false"
        [isLoading]="true"
        [message]="' '"
        [diameter]="16"
        [stroke]="2"
      ></app-empty-state>
    </div>
  </div>
</div>
