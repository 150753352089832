import { Component, Input } from "@angular/core";
import { DescriptionItem } from "../basic-user-card/basic-user-card.component";

@Component({
  selector: "app-basic-user-avatar-card",
  templateUrl: "./basic-user-avatar-card.component.html",
  styleUrls: ["./basic-user-avatar-card.component.scss"],
})
export class BasicUserAvatarCardComponent {
  @Input() public userId: string | null = null;
  @Input() public name: string | null = null;
  @Input() public description: string | null = null;
  @Input() public descriptionItems: DescriptionItem[] = [];
  @Input() public isWorkspaceVerified: boolean | null = null;
  @Input() public isIdentityVerified: boolean | null = null;
  @Input() public isProfessionVerified: boolean | null = null;
  @Input() public isExternal: boolean | null = null;
  @Input() public fetchImage: boolean = true;
  @Input() public isTeam: boolean = false;
  @Input() public leftContentMargin: string | null = null;

  /** Text that will be appended to the end of `name`. */
  @Input() public suffix: string | null = null;

  @Input() public isAvatarEnabled: boolean = true;
  @Input() public contentWidth: string | null = null;
  @Input() public descriptionMaxWidth: string | null = null;

  @Input() public padding: string | null = null;
}
