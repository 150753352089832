import { Component, Input } from "@angular/core";
import { AlertService } from "app/modules/core/old/alert.service";
import { FileUploader } from "ng2-file-upload";

@Component({
  selector: "app-drop-zone",
  templateUrl: "./drop-zone.component.html",
  styleUrls: ["./drop-zone.component.scss"],
})
export class DropZoneComponent {
  @Input()
  formats: string[];

  @Input()
  type: string;

  @Input()
  uploader: FileUploader;

  constructor(private alertService: AlertService) {}

  app() {
    const payload = [
      {
        type: "downloadMobileAppInfo",
        message: "Record and upload videos using the Celo mobile app!",
      },
    ];
    this.alertService.customDialog(
      "Celo mobile app ",
      "",
      "DONE",
      "",
      true,
      "vertical",
      payload
    );
  }
}
