<div class="usercard" #usercard>
  <div class="avatar">
    <app-avatar
      [name]="firstName + ' ' + lastName"
      [showBadge]="showBadge"
      [identityVerificationStatus]="identityVerificationStatus"
      [workspaces]="workspaces"
      [userId]="userId"
      [width]="40"
      [height]="40"
      [hasImage]="!!profilePic"
      [picUrl]="profilePic"
      [user]="contact"
      [isWorkspaceVerified]="isWorkspaceVerified"
      [isProfessionVerified]="professionVerified"
    >
    </app-avatar>
  </div>
  <div class="texts fw block" #texts>
    <div *ngIf="profileId === userId" class="name celo-elipsis">
      <span *ngIf="replaceMyName">{{ replaceMyName }}</span>
      <span *ngIf="!replaceMyName">{{ firstName }} {{ lastName }}</span>
    </div>
    <div *ngIf="profileId !== userId" class="name celo-elipsis">
      {{ firstName }} {{ lastName }}
    </div>
    <div *ngIf="!hideProfession" class="subtitle-text">
      <div *ngIf="profession && professionVerified">
        {{ profession }}
      </div>
    </div>
    <div *ngIf="!hideWorkspace" class="subtitle-text">
      <div *ngIf="workspaces && workspaces.length">
        <div>
          <div
            *ngFor="let company of workspaces; let i = index"
            [ngClass]="{ light: companyInFocusId !== company.companyId }"
          >
            <div
              *ngIf="
                (company.isActive !== false && showAllWorkspaces) ||
                companyInFocusId === company.companyId
              "
              class="celo-elipsis fw"
            >
              <ng-container *ngIf="onlyShowPosition; else fullWorkspaceInfo">
                {{ company.position?.trim() || "" }}
              </ng-container>
              <ng-template #fullWorkspaceInfo>
                {{
                  company.position &&
                  (company.position | isValidString) &&
                  showPosition
                    ? company.position + ", "
                    : ""
                }}{{
                  company.departmentName && showDepartment
                    ? company.departmentName
                    : "No Department"
                }}{{
                  company.companyName && showCompany
                    ? ", " + company.companyName
                    : ", No company name"
                }}
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="replaceWorkspace" class="subtitle-text light">
      {{ replaceWorkspace }}
    </div>
  </div>
  <div class="info">
    <div class="content">
      <div *ngIf="isAdmin" class="admin">Admin</div>
    </div>
  </div>
</div>
