const segmenter = new Intl.Segmenter();

/**
 * Returns a string from concatenating `values` (non-string values are converted to a string using `toString`).
 * Values that are null, undefined, or have a length of zero are filtered out. The default seperator is a single space.
 */
export const concatNotNull = (
  values: (string | { toString: () => string } | undefined | null)[],
  seperator: string = " "
): string => {
  return values
    .filter((value) => value != null)
    .map((value) => (typeof value === "string" ? value : value.toString()))
    .filter((value) => value.length > 0)
    .join(seperator);
};

/**
 * Null-safe version of `string.localeCompare`. Items that are null are sorted to the end of the list.
 */
export const localeCompareIfNotNull = (
  a?: string | null,
  b?: string | null,
  localeCompareArgs?: {
    locales?: string | string[];
    options?: Intl.CollatorOptions;
  }
): number => {
  if (a && b)
    return a.localeCompare(
      b,
      localeCompareArgs?.locales,
      localeCompareArgs?.options
    );
  if (a && !b) return -1;
  if (b && !a) return 1;
  return 0;
};

/** Returns true if `value` has any emoji */
export const hasEmoji = (value: string): boolean => {
  // Note: the global flag is required, and this regex must be recreated between each use as
  // global mode is stateful
  const isEmojiRegex =
    /[\p{Emoji_Presentation}\p{Extended_Pictographic}]|([0-9*#]\uFE0F?\u20E3)/gu;
  return isEmojiRegex.test(value);
};

/** Returns true if value contains any characters not considered an emoji */
export const hasNonEmoji = (value: string): boolean => {
  return getCharacterLength(value) && !hasEmoji(value);
};

/**
 * Returns the length of a string in characters (code points).
 *
 * This is **not** the same as `string.length` which returns the number of UTF-16 code units
 * where a single character can be comprised of multiple code units.
 *
 * For example:
 * - `"🦮".length` = 2
 * - `[..."🦮"].length` = 1
 * - `🏄‍♀️.length` = 5
 * - `[..."🏄"].length` = 4
 * - `[...new Intl.Segmenter().segment("🏄")].length` = 1
 */
export const getCharacterLength = (value: string): number => {
  return [...segmenter.segment(value)].length;
};
