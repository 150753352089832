<div *ngIf="type" class="content celo-input-outline">
  <div class="subtitle mb-2">{{ config[type].subtitle }}</div>
  <div>
    <div class="subtitle">Email</div>
    <mat-form-field
      appearance="outline"
      class="fw email-field no-bottom-form-field celo-chip"
      subscriptSizing="dynamic"
    >
      <mat-chip-grid #chipList aria-label="Invite via email">
        <mat-chip-row
          *ngFor="let email of emails"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(email)"
        >
          {{ email }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip-row>
        <input
          [disabled]="emails.length === 20 ? true : false"
          [placeholder]="
            emails.length === 20
              ? ''
              : 'Type or copy and paste up to 20 emails.'
          "
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
          [(ngModel)]="emailInput"
          (paste)="onPaste()"
        />
      </mat-chip-grid>
    </mat-form-field>
    <div class="footers">
      <div class="invalid_text">
        <span
          *ngIf="
            emailInput && !valid && !(emailInput && isValidEmail(emailInput))
          "
          class="appear_1"
          >Please enter a valid email</span
        ><span>&nbsp;</span>
      </div>
      <div class="counter">{{ emails.length }}/20</div>
    </div>
  </div>
  <div class="flex">
    <button
      [disabled]="
        (saving || !emails.length) && !(emailInput && isValidEmail(emailInput))
      "
      mat-button
      class="celo-primary-button add"
      [ngClass]="{ 'side-mode': sideMode }"
      (click)="sendInvite()"
    >
      <span>{{ config[type].button }}</span>
      <span *ngIf="emails && emails.length"> ({{ emails.length }})</span>
    </button>
  </div>
  <div *ngIf="!hideTertiaryButton">
    <button
      mat-button
      class="celo-tertiary-button"
      (click)="secondaryButtonClick()"
    >
      {{ config[type].copy_button }}
    </button>
  </div>
</div>
