import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "@env";
import { AlertComponent } from "@modules/core/components";
import { ImgOverlayService } from "@modules/shared/img-overlay.service";
import { ZipFilesService } from "@modules/shared/zip-files.service";
import { AlertService } from "app/modules/core/old/alert.service";
import { FileUploadService } from "app/modules/core/old/file-upload.service";
import { config } from "configurations/config";
import { FileUploader } from "ng2-file-upload";
import { LibraryService } from "../library.service";
import { MediaService } from "./media.service";

@Component({
  selector: "app-media",
  templateUrl: "./media.component.html",
  styleUrls: ["./media.component.scss"],
})
export class MediaComponent implements OnInit, OnDestroy, AfterViewChecked {
  length: number;
  actionSub = null;
  loading = true;
  searchTerm = "";
  pageSize = 20;
  hideLoadMore = false;
  filter = "All";
  isSelecting = false;
  dropZoneActive = "";
  validUploadFormats = ["image/jpg", "image/png", "image/jpeg"];
  public uploader: FileUploader;
  userAccount: any;
  userId: string;
  selected: any[];

  constructor(
    private libraryService: LibraryService,
    private imgOverlayService: ImgOverlayService,
    private zipFilesService: ZipFilesService,
    private mediaService: MediaService,
    private fileUploadService: FileUploadService,
    private _changeDetectionRef: ChangeDetectorRef,
    private alertService: AlertService,
    private matDialog: MatDialog
  ) {
    const type = "Photos";
    // let url=`${environment.celoApiEndpoint}/api/photos/`;
    const url = `${environment.celoApiEndpoint}/api/v2/media/`;
    const data = {
      type,
      queueLimit: config.max_file_upload_limit + 1,
      allowPatient: true,
      url,
    };
    const instance = this;
    this.fileUploadService.initialize(data, function () {
      instance.reload();
    });
    this.uploader = this.fileUploadService.uploader;
    this.selected = [];
  }

  ngOnInit() {
    this.loading = true;
    // this.searchSub = this.libraryService.searchPhotoEmitted$.subscribe(this.doSearch.bind(this))
    this.actionSub = this.libraryService.photoActionEmitted$.subscribe(
      this.onAction.bind(this)
    );
  }

  ngAfterViewChecked(): void {
    this._changeDetectionRef.detectChanges();
  }

  fileDropped() {}
  dropZoneState(state: boolean) {
    this.dropZoneActive = state ? "valid" : "";
  }

  handleDrop(files: FileList) {
    this.cancelSelect();
  }

  handleFilesSelection(files: FileList) {
    this.cancelSelect();
  }

  cancelSelect() {
    this.libraryService.emitCanSelect(this.isSelecting);
  }

  closeUpload() {
    this.fileUploadService.uploader.clearQueue();
  }

  queryChange(searchTerm: string) {
    if (typeof searchTerm != "string") {
      return;
    }

    if (this.searchTerm != searchTerm) {
      this.loading = true;
      this.searchTerm = searchTerm;
    }
  }

  ngOnDestroy() {
    this.actionSub.unsubscribe();
    this.libraryService.emitCanSelect(false);
    this.closeUpload();
  }

  onAction(action: string) {
    if (action === "preview") {
      // this.previewPhotos()
    } else if (action === "download") {
      this.downloadMedia();
    } else if (action === "delete") {
      this.deleteMedia();
    } else if (action === "share") {
      this.sharePhotos();
    }
  }

  selsectionChange(selectedPhotos: any[]) {
    this.selected = selectedPhotos;
  }

  private getSelected(): any[] {
    return this.selected;
  }

  private sharePhotos() {
    const photos = this.getSelected();
    if (!photos.length) {
      this.noneSelected();
      return;
    }
    if (photos && photos.length) {
      this.mediaService.sharePhotos(photos, {
        isFromLibrary: true,
      });
    }
  }

  public downloadMedia() {
    const media = this.getSelected();

    if (!media.length) {
      this.noneSelected();
      return;
    }
    AlertComponent.openDialog(this.matDialog, {
      acceptOnly: true,
      acceptButtonText: "Ok",
      title: "The download will begin shortly.",
      message: `Compressing ${media.length} files for download.`,
    });
    this.zipFilesService.downloadZip(
      media.map((photo) => {
        let format = "";
        switch (photo.mediaType) {
          case "Photo":
            format = "png";
            break;
          case "Video":
            format = "mp4";
            break;

          default:
            break;
        }
        const fileName = this.zipFilesService.createFileName(
          photo.createdOn,
          format,
          photo.patientData
        );
        return {
          url: `${environment.celoApiEndpoint}/api/v2/media/${photo.id}`,
          fileName,
          pp: (blob: Blob) =>
            this.imgOverlayService.addOverlayToBlob(
              blob,
              photo,
              photo.mediaType
            ),
        };
      })
    );
  }

  noneSelected() {
    this.alertService.showSnackBar("No photos selected !", 2);
    return;
  }

  public deleteMedia() {
    const photos = this.getSelected();
    if (!photos.length) {
      this.noneSelected();
      return;
    }
    const i = this;
    let count = 0;
    this.mediaService.deleteMedia(photos, "media", function (status) {
      count++;
      if (count >= photos.length) {
        i.reload();
      }
    });
  }

  public reload() {
    this.loading = true;
    this.pageSize = 20;
    this.libraryService.emitReloadPhoto(true);
  }

  app() {
    const payload = [
      {
        type: "downloadMobileAppInfo",
        message: "Record and upload videos using the Celo mobile app!",
      },
    ];
    this.alertService.customDialog(
      "Celo mobile app ",
      "",
      "DONE",
      "",
      true,
      "vertical",
      payload
    );
  }
}
