import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { InvitationModel, InvitationType } from "./../../../../../types/api-v2";
import { Component, Inject, OnInit } from "@angular/core";

export interface InvitationAlreadyUsedData {
  invitation: InvitationModel;
}

export interface InvitationAlreadyUsedResult {}

@Component({
  selector: "app-invitation-already-used-dialog",
  templateUrl: "./invitation-already-used-dialog.component.html",
  styleUrls: ["./invitation-already-used-dialog.component.scss"],
})
export class InvitationAlreadyUsedDialogComponent implements OnInit {
  public title: string = "";
  public message: string = "";

  public constructor(
    @Inject(MAT_DIALOG_DATA) private data: InvitationAlreadyUsedData
  ) {}

  public ngOnInit(): void {
    this.title = this.data.invitation.metadata?.name ?? "Invitation";
    this.message = this.getMessage();
  }

  private getMessage() {
    const type = this.data.invitation.type;
    if (type === InvitationType.UserSpecificWorkspaceJoin) {
      return "This invite link has expired. Please contact your workspace administrator for a new invite link.";
    }
    return "This invite link has already been used.";
  }
}
