<div class="parent">
  <div>
    <div>
      <app-celo-pick-participants
        [title]="''"
        [participants]="participants"
        [selectedParticipantIds]="selectedParticipantIds"
        [hideProfession]="false"
        [hideSelectionInfo]="true"
        (pick)="onUpdate($event)"
        (preview)="openPreview($event)"
        [showBadge]="true"
      ></app-celo-pick-participants>
    </div>
    <div class="buttons">
      <div *ngIf="selectedParticipantIds?.length && !saving" class="p-2">
        <div class="button">
          <button
            class="celo-primary-button next"
            (click)="editConversationSave()"
            [disabled]="loading || !selectedParticipantIds?.length"
          >
            <span
              >REMOVE
              <span *ngIf="selectedParticipantIds?.length"
                >({{ selectedParticipantIds.length }})</span
              ></span
            >
          </button>
        </div>
        <div class="button">
          <button
            class="celo-button-orange-outline cancel"
            [disabled]="loading"
            (click)="cancel()"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
