<mat-dialog-title class="mat-headline-6">{{
  title | capitalizeFirstLetter
}}</mat-dialog-title>

<mat-dialog-content class="font-14">
  <p>{{ content | capitalizeFirstLetter }}</p>

  <section [formGroup]="confirmationForm">
    <p>
      <mat-checkbox formControlName="termsAndConditions" class="bold">
        I have read and agree to the terms above.<span class="required">*</span>
      </mat-checkbox>
    </p>
  </section>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close class="celo-secondary-button uppercase">
    Cancel
  </button>
  <button
    mat-button
    [disabled]="confirmationForm.invalid"
    (click)="submit()"
    class="celo-primary-button uppercase"
  >
    Confirm Export
  </button>
</mat-dialog-actions>
