import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ConversationModel, FullUserProfileModel } from "types";
import { ConversationService, SharedService } from "../../core";

@Component({
  selector: "app-conversation-list",
  templateUrl: "./conversation-list.component.html",
  styleUrls: ["./conversation-list.component.scss"],
})
export class ConversationListComponent implements OnInit, OnDestroy {
  @Input() public conversations: ConversationModel[] | null = null;
  @Input() public userAccount: FullUserProfileModel | null = null;
  @Input() public canLoadMore = false;
  @Input() public loading = false;
  @Input() public routePrefixes: string[] = [];
  @Input() public isRolesSidenav: boolean = false;
  @Input() public disableConversationActions: boolean = false;

  @Output() public loadMore = new EventEmitter(); // todo: should emit when we scroll, not button click;

  private inboxSubscriber: any;

  public constructor(
    private conversationService: ConversationService,
    private sharedService: SharedService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit() {
    this.inboxSubscriber = this.conversationService.onInboxSorted.subscribe(
      () => {
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  public ngOnDestroy() {
    if (this.inboxSubscriber) {
      this.inboxSubscriber.unsubscribe();
    }
  }

  public isOnline() {
    return this.sharedService.isOnline();
  }

  public conversationHasher(idx: number, convo: ConversationModel) {
    let lmid = null;
    if (convo.lastMessage) {
      lmid = convo.lastMessage.id;
    }
    let pl = null;
    if (convo.participants) {
      pl = convo.participants.length;
    }
    let pid = null;
    if (convo.patientData) {
      pid = convo.patientData.uid;
    }
    return `${convo.id}-${lmid}-${pl}-${convo.name}-${pid}-${convo.subject}`;
  }

  public muteStatusChange(participantData, conversation) {
    this.conversationService.updateMuteStatusInConversation(
      participantData.userId,
      conversation.id,
      participantData.mutedToUtc,
      participantData.muteInterval
    );
  }

  public getRouterLink(conversation: ConversationModel) {
    return [...this.routePrefixes, conversation.id, "messages"];
  }
}
