import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { ConversationService } from "@modules/core";
import {
  BasicWorkplaceModel,
  ConversationModelV2,
  ConversationParticipantModelV2,
  ConversationRoleUpdate,
  FullUserProfileModel,
  ParticipantRole,
} from "@types";

@Component({
  selector: "app-celo-participant-card",
  templateUrl: "./celo-participant-card.component.html",
  styleUrls: ["./celo-participant-card.component.scss"],
})
export class CeloParticipantCardComponent implements OnInit {
  @Input() participant: ConversationParticipantModelV2;
  @Input() profileId: string;
  @Input() replaceMyName: string;
  @Input() replaceWorkspace: string;
  @Input() hideWorkspace: boolean;
  @Input() canRemove = false;
  @Input() isAdmin = false;
  @Input() hideProfession = true;
  @Input() userAccount: FullUserProfileModel;
  @Input() conversation: ConversationModelV2;
  @Input() adminIds: string[] = [];
  @Input() type: string;
  @Output() remove = new EventEmitter<void>();
  @Output() showProfile = new EventEmitter<ConversationParticipantModelV2>();

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  private menuX = 0;
  private menuY = 0;
  private chatId: string;

  public isCurrentUserAdmin = false;
  public isSelf = false;
  public isBlocked = false;
  public hasSharedWorkspaces = false;
  public isConnected = false;

  constructor(private conversationService: ConversationService) {}

  ngOnInit() {
    this.chatId = this.conversationService.getChatId(this.participant.userId);
    this.isCurrentUserAdmin = this.adminIds.includes(this.userAccount.userId);
    this.isSelf = this.userAccount.userId === this.participant.userId;
    this.isBlocked = this.checkIsBlocked();
    this.hasSharedWorkspaces = this.checkHasSharedWorkspaces();
    this.isConnected = this.checkHasActiveConnection();
  }

  onParticipantClick($event: MouseEvent): void {
    this.menuX = $event.offsetX;
    this.menuY = $event.offsetY + 10;

    this.trigger.menuClosed.subscribe(() => {
      this.menuX = 0;
      this.menuY = 0;
    });

    this.trigger.openMenu();
  }

  onSecureMessage(): void {
    this.conversationService
      .createAndNavigateToChat(this.participant.userId)
      .subscribe({
        next: (conversation) => {},
        error: (e) => {},
      });
  }

  onViewProfileClick(): void {
    this.showProfile.emit(this.participant);
  }

  onMakeAdminClick(): void {
    const roles: ConversationRoleUpdate[] = [
      {
        userId: this.participant.userId,
        role: ParticipantRole.Administrator,
      },
    ];
    this.conversationService.modifyRoles(this.conversation.id, roles);
  }

  onRemoveAdminClick(): void {
    const roles: ConversationRoleUpdate[] = [
      {
        userId: this.participant.userId,
        role: ParticipantRole.Contributor,
      },
    ];
    this.conversationService.modifyRoles(this.conversation.id, roles);
  }

  onRemovePartipantClick(): void {
    this.remove.emit();
  }

  private checkIsBlocked(): boolean {
    return (
      this.participant.blockedByMe?.isBlocked ||
      this.participant.blockedMe?.isBlocked
    );
  }

  private getActiveWorkspaces(workspaces: BasicWorkplaceModel[]): string[] {
    return workspaces
      .filter((w) => w.leftOnUtc === null && w.isActive)
      .map((w) => w.companyId);
  }

  private checkHasSharedWorkspaces(): boolean {
    const participantWorkspaces = this.getActiveWorkspaces(
      this.participant.workplaces
    );
    const currentUserWorkspaces = new Set(
      this.getActiveWorkspaces(this.userAccount.workplaces)
    );
    return participantWorkspaces.some((c) => currentUserWorkspaces.has(c));
  }

  private checkHasActiveConnection(): boolean {
    return this.participant?.connection?.state === "Accepted";
  }
}
