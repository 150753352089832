import { Injectable } from "@angular/core";
import { ApiService, ConService } from "@modules/core";
import {
  MessageUpdate,
  UserActivityModel,
  UserActivityModelApiPagedResult,
} from "@types";
import { BehaviorSubject, Observable, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  // TODO refactor legacy public fields
  public hasNextPage: boolean = true;
  public unseenConnections: boolean;
  public unseenActivityFeed: boolean;

  private nextPageUri: string = "/api/v2/activities";

  private activitiesSubject = new BehaviorSubject<UserActivityModel[] | null>(
    null
  );
  public activities$ = this.activitiesSubject.asObservable();

  constructor(private conService: ConService, private apiService: ApiService) {
    this.conService.userActivityUpdate$.subscribe({
      next: this.handleUserActivityUpdate.bind(this),
    });

    this.conService.hasPendingConnections$.subscribe({
      next: () => (this.unseenConnections = true),
    });

    this.conService.Message$.subscribe({
      next: this.handleMessageUpdates.bind(this),
    });
  }

  private handleUserActivityUpdate(data: UserActivityModel) {
    this.unseenActivityFeed = true;
    const newActivities = [data, ...this.activitiesSubject.value];
    this.activitiesSubject.next(newActivities);
  }

  private handleMessageUpdates(messages: MessageUpdate[]) {
    const newActivities = [...this.activitiesSubject.value];
    let hasChanges = false;

    for (const message of messages) {
      if (!message.deletedOnUtc || !message.marker) continue;
      const index = newActivities.findIndex(
        (s) => s.metadata?.marker === message.marker
      );
      if (index === -1) continue;
      const clone = structuredClone(newActivities[index]);
      clone.metadata.content = message.content;
      clone.metadata.deletedBy = message.deletedBy;
      clone.metadata.deletedOnUtc = message.deletedOnUtc;
      newActivities[index] = clone;
      hasChanges = true;
    }

    if (!hasChanges) return;
    this.activitiesSubject.next(newActivities);
  }

  public getActivities(
    nextPage: boolean
  ): Observable<UserActivityModelApiPagedResult> {
    const path = nextPage ? this.nextPageUri : "/api/v2/activities";
    const body = {
      PageSize: 6,
    };

    return this.apiService
      .get<UserActivityModelApiPagedResult>({ path, body })
      .pipe(
        tap({
          next: (page) => {
            this.hasNextPage = page.hasNext;
            this.nextPageUri = page.nextPageUri;

            if (nextPage) {
              this.activitiesSubject.next(
                this.activitiesSubject.value.concat(page.data)
              );
            } else {
              this.activitiesSubject.next(page.data);
            }
          },
        })
      );
  }
}
