import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss']
})
export class PopupMenuComponent implements AfterViewInit {
  @Input() public disabled: boolean = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | null = null;

  public menuX = 0;
  public menuY = 0;

  public ngAfterViewInit(): void {
    this.trigger.menuClosed.subscribe(() => {
      this.menuX = 0;
      this.menuY = 0;
    });
  }

  public onClick(mouseEvent: MouseEvent): void {
    this.menuX = mouseEvent.offsetX;
    this.menuY = mouseEvent.offsetY + 10;
    this.trigger?.openMenu();
  }
}
