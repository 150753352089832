import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { IntegrationServiceConfirmationModel } from "@types";
import { createDialogConfig } from "@utils";

export interface ExportConfirmationDialogData
  extends IntegrationServiceConfirmationModel {}

export interface ExportConfirmationDialogResult {
  isConfirmed?: boolean;
}

@Component({
  selector: "app-export-confirmation-dialog",
  templateUrl: "./export-confirmation-dialog.component.html",
  styleUrls: ["./export-confirmation-dialog.component.scss"],
})
export class ExportConfirmationDialogComponent implements OnInit {
  public title: string = "";
  public content: string = "";

  public confirmationForm = this.formBuilder.group({
    termsAndConditions: [false, Validators.requiredTrue],
  });

  public constructor(
    @Inject(MAT_DIALOG_DATA) private data: ExportConfirmationDialogData,
    private matDialogRef: MatDialogRef<
      ExportConfirmationDialogComponent,
      ExportConfirmationDialogResult
    >,
    private formBuilder: FormBuilder
  ) {}

  public static openDialog(
    matDialog: MatDialog,
    data: ExportConfirmationDialogData
  ): MatDialogRef<
    ExportConfirmationDialogComponent,
    ExportConfirmationDialogResult
  > {
    const config = createDialogConfig<ExportConfirmationDialogData>(data, {
      panelClasses: ["export-confirmation"],
    });
    return matDialog.open<
      ExportConfirmationDialogComponent,
      ExportConfirmationDialogData,
      ExportConfirmationDialogResult
    >(ExportConfirmationDialogComponent, config);
  }

  public ngOnInit(): void {
    this.title = this.data.title ?? "Please read and confirm below";
    this.content = this.data.content ?? "";
  }

  public submit() {
    this.matDialogRef.close({
      isConfirmed: true,
    });
  }
}
