import { SYSTEM_USER_ID } from "@common";
import { environment } from "@env";
import {
  ClearUnreadConversationModelV3,
  Conversation,
  ConversationModelV2,
  ConversationModelV3,
  ConversationParticipant,
  ConversationParticipantModelV2,
  ConversationParticipantModelV3,
  ConversationType,
  FullUserProfileModel,
  Message,
  MessageModel,
  MessageType,
  MessageUpdate,
  PinnedConversationUpdate,
  VideoCallUpdate,
} from "@types";
import { produce } from "immer";
import { addOrReplaceElement, partition, sorted } from "./array-utils";
import { ConversationParticipantUtils } from "./conversation-participant-utils";
import { dateCompareIfNotNull, maxDate } from "./date-utils";
import { MessageUtils } from "./message-utils";
import { concatNotNull, localeCompareIfNotNull } from "./string-utils";
import {
  isNotNullOrUndefined,
  isNullOrUndefined,
} from "./type-predicate-utils";
import { assertIsExhaustive } from "./typescript-utils";

const getConversationProfileUri = (
  conversation: Conversation
): string | null => {
  if (conversation.photoId == null) return null;
  return `/api/v2/Conversations/${conversation.id}/media/${conversation.photoId}`;
};

const getLatestReadToDate = (
  conversation: Conversation,
  user: FullUserProfileModel
): Date => {
  const participant = ConversationParticipantUtils.findConversationParticipant(
    conversation.participants,
    user.userId
  );

  if (!participant) throw new Error("Failed to find participant");

  return maxDate(
    [
      conversation.localAsReadToUtc,
      participant.latestReadMessageUtc,
      participant.asReadToUtc,
      participant.joinedOnUtc,
    ]
      .filter(isNotNullOrUndefined)
      .map((s) => new Date(s))
  );
};

const updateConversation = (
  conversation: Conversation,
  user: FullUserProfileModel
): void => {
  const userId = user.userId;

  conversation.profilePictureUri = getConversationProfileUri(conversation);
  conversation.pinnedOnUtc =
    getConversationPinnedOnUtcForUserId(conversation, userId) ?? null;
  conversation.sortTime = getConversationSortTime(conversation, userId);

  conversation.participants = conversation.participants
    ? ConversationParticipantUtils.sort(conversation.participants, userId)
    : conversation.participants;

  const latestReadToDate = getLatestReadToDate(
    conversation,
    user
  ).toISOString();
  conversation.unreadMessages = conversation.unreadMessages.filter(
    (m) => m.sentOnUtc > latestReadToDate
  );
  conversation.unreadMessageCount = conversation.unreadMessages.length;
};

const addParticipant = (
  conversation: Conversation,
  participant: ConversationParticipant,
  userId: string
): void => {
  conversation.participants = addOrReplaceElement(
    conversation.participants ?? [],
    participant,
    (p) => p.userId === participant.userId
  );

  ConversationParticipantUtils.sort(conversation.participants, userId);
};

const addParticipantModelV3 = (
  conversation: Conversation,
  participant: ConversationParticipantModelV3,
  userId: string
): void => {
  const newParticipant =
    ConversationParticipantUtils.fromConversationParticipantModelV3(
      participant
    );

  conversation.participants = addOrReplaceElement(
    conversation.participants ?? [],
    newParticipant,
    (p) => p.userId === participant.userId
  );

  ConversationParticipantUtils.sort(conversation.participants, userId);
};

const updateLastMessage = (
  conversation: Conversation,
  message: Message
): void => {
  /**
   * Rules for updating a conversation's last message:
   *
   * - A conversation has no messages/last messages OR
   * - The last messages sentOnUtc value is less than or equal to the new message's value OR
   * - The last messages sentOnUtc value is greater than the new message's AND
   *   the new message's marker is the same as the last messages AND
   *   the new messages's last modified/deletedOnUtc value is greater than the last message's
   */
  if (
    conversation.lastMessage &&
    !MessageUtils.isMessageNewerThanLastMessage(
      message,
      conversation.lastMessage
    )
  ) {
    return;
  }

  conversation.lastMessage = message;
};

const updateUnreadMessages = (
  conversation: Conversation,
  message: Message,
  user: FullUserProfileModel
): void => {
  // Messages sent by the system or the current user do not contribute to a conversation's unread count
  if (message.sentBy === SYSTEM_USER_ID || message.sentBy === user.userId)
    return;

  const latestReadToDate = getLatestReadToDate(conversation, user);
  const isRead = new Date(message.sentOnUtc) <= latestReadToDate;
  if (isRead) return;

  const hasMessage = conversation.unreadMessages.some(
    (m) => m.marker === message.marker
  );
  if (hasMessage) return;

  conversation.unreadMessages.push({
    marker: message.marker,
    sentOnUtc: message.sentOnUtc,
  });
};

const getConversationPinnedOnUtcForUserId = (
  conversation: Conversation,
  userId: string
): string | null => {
  return (
    conversation.participants?.find((p) => p.userId === userId)?.pinnedOnUtc ??
    null
  );
};

const getConversationSortTime = (
  conversation: Conversation,
  userId: string
): string => {
  if (conversation.lastMessage) {
    // Business rule:
    // Messages sent by the current user are sorted by their creation time.
    // This is to ensure the order of messages from the sender's perspective are always
    // sorted to the bottom.It is known this logic does not handle all cases
    // but to consistent with other clients (e.g. Android and iOS) this logic remains here.
    if (conversation.lastMessage.sentBy === userId) {
      return (
        conversation.lastMessage.createdOnUtc ??
        conversation.lastMessage.sentOnUtc
      );
    }

    return (
      conversation.lastMessage.sentOnUtc ??
      conversation.lastMessage.createdOnUtc
    );
  }
  return conversation.lastModifiedOnUtc ?? conversation.createdOnUtc;
};

export const getConversationName = (
  conversation: ConversationModelV2,
  currentUserId: string
): string => {
  if (conversation.type !== ConversationType.Chat) return conversation.name;

  const otherParticipant = conversation.participants.find(
    (p) => p.userId !== currentUserId
  );

  if (!otherParticipant) throw new Error("Missing other participant");

  const conversationName = concatNotNull([
    otherParticipant.firstName,
    otherParticipant.lastName,
  ]);

  return conversationName;
};

export const getParticipantFullName = (
  participant: ConversationParticipantModelV2
): string => {
  return concatNotNull([participant.firstName, participant.lastName]);
};

const sortSearchResultsGroup = (
  conversations: Conversation[],
  userId: string
): Conversation[] => {
  return sorted(conversations, (a, b) => {
    const dateA = getConversationSortTime(a, userId);
    const dateB = getConversationSortTime(b, userId);
    let sortVal = dateCompareIfNotNull(dateA, dateB);
    if (dateA === dateB) {
      let aName = getConversationName(a, userId);
      let bName = getConversationName(b, userId);
      sortVal = localeCompareIfNotNull(aName, bName);
      // at this point we no longer do another locale compare, if the IDs are the same then something
      // has gone catastrophically wrong
      if (sortVal === 0) sortVal = localeCompareIfNotNull(a.id, b.id);
    }

    return sortVal;
  });
};

const sortSearchResultsByName = (
  conversations: Conversation[],
  query: string
): Conversation[] => {
  return produce(conversations, (draft) => {
    let lowerCaseQuery = query.toLowerCase();
    for (let i = 0; i < draft.length; i++) {
      if (!draft[i].name) {
        continue;
      }
      const conversationName: string = draft[i].name;
      if (conversationName.toLowerCase().indexOf(lowerCaseQuery) !== -1) {
        const temp = draft[i];
        draft.splice(i, 1);
        draft.unshift(temp);
      }
    }
  });
};

const sortSearchResultsByType = (
  conversations: Conversation[],
  type: ConversationType
): Conversation[] => {
  return produce(conversations, (draft) => {
    for (let i = 0; i < draft.length; i++) {
      if (draft[i].type === type) {
        const temp = draft[i];
        draft.splice(i, 1);
        draft.unshift(temp);
      }
    }
  });
};

const sortSearchResultsParticipantsByName = (
  conversations: Conversation[],
  query: string,
  ignoreChats?: boolean
): Conversation[] => {
  return produce(conversations, (draft) => {
    draft.forEach((conversation) => {
      if (ignoreChats && conversation.type === ConversationType.Chat) return;

      conversation.participants =
        ConversationParticipantUtils.sortSearchResultParticipants(
          conversation.participants,
          query
        );
    });
  });
};

export class ConversationUtils {
  public static isConversation = (value: unknown): value is Conversation => {
    return value["_type"] === "Conversation";
  };

  public static getComputedMessageCreatedOnUtc = (
    conversations: Conversation[]
  ): Date => {
    const now = new Date();
    if (!conversations.length) return now;
    const timestamps = conversations
      .flatMap((c) => [c.lastModifiedOnUtc, c.lastMessage?.sentOnUtc])
      .filter(isNotNullOrUndefined)
      .sort()
      .reverse();
    return maxDate([now, new Date(timestamps[0])]);
  };

  public static fromConversationModelV3 = (
    model: ConversationModelV3,
    user: FullUserProfileModel
  ): Conversation => {
    const id = model.id;
    if (!id) throw new Error("id is required"); // TODO refactor validation, possibly with zod
    const participants = model.participants?.map((p) =>
      ConversationParticipantUtils.fromConversationParticipantModelV3(p)
    );

    if (!participants) throw new Error("participants is required");

    const participant =
      ConversationParticipantUtils.findConversationParticipant(
        participants,
        user.userId
      );

    if (!participant) throw new Error("participant is required");

    const conversation: Conversation = {
      _type: "Conversation",
      id: model.id,
      teamIds: model.teamIds,
      subject: model.subject,
      name: model.name,
      createdBy: model.createdBy,
      type: model.type,
      createdOnUtc: model.createdOnUtc,
      lastModifiedOnUtc: model.lastModifiedOnUtc,
      lastMessage: model.lastMessage
        ? MessageUtils.fromMessageModel(model.lastMessage)
        : null,
      patientData: model.patientData,
      isReal: model.isReal,
      invitation: model.invitation,
      participants,
      photoId: model.photoId,
      unreadMessages: model.unreadMessages ?? [],
      unreadMessageLimit:
        model.unreadMessageLimit ?? environment.defaultUnreadMessageLimit,
      unreadMessageCount: 0,
      isArchived: participant.isHidden ?? false,
    };

    updateConversation(conversation, user);

    return conversation;
  };

  public static updateFromConversationModelV3 = (
    conversation: Conversation,
    model: ConversationModelV3,
    user: FullUserProfileModel
  ): Conversation => {
    return produce(conversation, (draft) => {
      draft.subject = model.subject;
      draft.name = model.name;
      (draft.lastMessage = model.lastMessage
        ? MessageUtils.fromMessageModel(model.lastMessage)
        : null),
        (draft.patientData = model.patientData);
      draft.invitation = model.invitation;
      draft.unreadMessages = model.unreadMessages ?? [];
      draft.unreadMessageLimit =
        model.unreadMessageLimit ?? environment.defaultUnreadMessageLimit;

      for (const participantModel of model.participants) {
        const existingParticipantIndex = draft.participants.findIndex(
          (p) => p.userId === participantModel.userId
        );

        if (existingParticipantIndex === -1) {
          const newParticipant =
            ConversationParticipantUtils.fromConversationParticipantModelV3(
              participantModel
            );
          draft.participants.push(newParticipant);
          continue;
        }

        const existingParticipant =
          draft.participants[existingParticipantIndex];
        const updatedParticipant =
          ConversationParticipantUtils.updateFromConversationParticipantModelV3(
            existingParticipant,
            participantModel
          );

        draft.participants.splice(
          existingParticipantIndex,
          1,
          updatedParticipant
        );
      }

      updateConversation(draft, user);
    });
  };

  public static updateFromPinnedConversationUpdate = (
    conversation: Conversation,
    update: PinnedConversationUpdate
  ): Conversation => {
    return produce(conversation, (draft) => {
      const { pinnedOnUtc, userId } = update;

      const conversationParticipantIndex =
        draft.participants?.findIndex((cp) => cp.userId === userId) ?? null;

      if (
        conversationParticipantIndex === null ||
        conversationParticipantIndex === -1
      ) {
        return draft;
      }

      draft.pinnedOnUtc = pinnedOnUtc;
    });
  };

  public static updateFromVideoCallUpdate = (
    conversation: Conversation,
    update: VideoCallUpdate,
    user: FullUserProfileModel,
    participant: ConversationParticipantModelV3 | null
  ): { conversation: Conversation; message: Message } => {
    let message: Message;

    const clone = produce(conversation, (draft) => {
      const lastMessage = draft.lastMessage;
      if (
        lastMessage?.type === MessageType.VideoCall &&
        lastMessage?.metadata.resourceId === update.id
      ) {
        message = MessageUtils.updateMessageFromVideoCallUpdate(
          update,
          lastMessage
        );
      } else {
        message = MessageUtils.createVideoCallMessage(update);
      }

      updateUnreadMessages(draft, message, user);
      updateLastMessage(draft, message);
      if (participant) addParticipantModelV3(draft, participant, user.userId);
      updateConversation(draft, user);
    });

    return {
      conversation: clone,
      message,
    };
  };

  public static updateFromMessageUpdate = (
    conversation: Conversation,
    update: MessageUpdate,
    user: FullUserProfileModel,
    participant: ConversationParticipantModelV3 | null
  ): Conversation => {
    return produce(conversation, (draft) => {
      const message = MessageUtils.fromMessageModel(update);
      updateUnreadMessages(draft, message, user);
      updateLastMessage(draft, message);
      if (participant) addParticipantModelV3(draft, participant, user.userId);
      updateConversation(draft, user);
    });
  };

  public static updateFromMessageModel = (
    conversation: Conversation,
    messageModel: MessageModel,
    user: FullUserProfileModel
  ): Conversation => {
    return produce(conversation, (draft) => {
      const message = MessageUtils.fromMessageModel(messageModel);
      updateUnreadMessages(draft, message, user);
      updateLastMessage(draft, message);
      updateConversation(draft, user);
    });
  };

  public static updateFromMessage = (
    conversation: Conversation,
    message: Message,
    user: FullUserProfileModel
  ): Conversation => {
    return produce(conversation, (draft) => {
      updateUnreadMessages(draft, message, user);
      updateLastMessage(draft, message);
      updateConversation(draft, user);
    });
  };

  public static updateFromClearUnreadConverationModelV3 = (
    conversation: Conversation,
    model: ClearUnreadConversationModelV3,
    user: FullUserProfileModel
  ): Conversation => {
    return produce(conversation, (draft) => {
      const participant = ConversationUtils.findConversationParticipant(
        draft,
        user.userId
      );
      if (!participant) throw new Error("Failed to find participant");

      const dates = [model.asReadToUtc, participant.asReadToUtc]
        .filter(isNotNullOrUndefined)
        .map((date) => new Date(date));

      participant.asReadToUtc = maxDate(dates).toISOString();

      updateConversation(draft, user);
    });
  };

  public static updateFromViewedMessage = (
    conversation: Conversation,
    message: MessageModel,
    user: FullUserProfileModel
  ): Conversation => {
    return produce(conversation, (draft) => {
      const sentOnUtc = new Date(message.sentOnUtc);

      if (conversation.localAsReadToUtc) {
        const localAsReadToUtcDate = new Date(conversation.localAsReadToUtc);
        if (sentOnUtc <= localAsReadToUtcDate) return;
      }

      conversation.localAsReadToUtc = sentOnUtc.toISOString();

      updateConversation(draft, user);
    });
  };

  public static sort = (conversations: Conversation[]): Conversation[] => {
    const [pinned, unpinned] = partition(
      conversations,
      (c) => c.pinnedOnUtc != null
    );

    const sortedPinned = sorted(pinned, (a, b) => {
      return Date.parse(b.pinnedOnUtc) - Date.parse(a.pinnedOnUtc);
    });

    const sortedUnpinned = sorted(unpinned, (a, b) => {
      return dateCompareIfNotNull(a.sortTime, b.sortTime);
    });

    return [...sortedPinned, ...sortedUnpinned];
  };

  public static isArchived = (
    conversation: Conversation,
    userId: string
  ): boolean => {
    const participant =
      ConversationParticipantUtils.findConversationParticipant(
        conversation.participants,
        userId
      );

    if (!participant) throw new Error("Failed to find participant");

    return participant.isHidden ?? false;
  };

  public static sortSearchResults = (
    conversations: Conversation[],
    userId: string,
    query: string
  ): {
    chats: Conversation[];
    groups: Conversation[];
    cases: Conversation[];
    archived: Conversation[];
    external: Conversation[];
  } => {
    let chats = [];
    let groups = [];
    let cases = [];
    let archived = [];
    let external = [];

    conversations.forEach((conversation) => {
      const isArchived = ConversationUtils.isArchived(conversation, userId);

      if (isArchived) {
        archived.push(conversation);
        return;
      }

      const conversationType = conversation.type;
      switch (conversationType) {
        case ConversationType.Chat:
        case ConversationType.SelfChat:
        case ConversationType.TeamChat:
          chats.push(conversation);
          break;
        case ConversationType.Group:
          groups.push(conversation);
          break;
        case ConversationType.Case:
          cases.push(conversation);
          break;
        case ConversationType.External:
          external.push(conversation);
          break;
        default:
          return assertIsExhaustive(conversationType);
      }
    });

    chats = sortSearchResultsGroup(chats, userId);
    groups = sortSearchResultsGroup(groups, userId).reverse();
    cases = sortSearchResultsGroup(cases, userId).reverse();
    archived = sortSearchResultsGroup(archived, userId).reverse();
    external = sortSearchResultsGroup(external, userId);

    groups = sortSearchResultsByName(groups, query);
    cases = sortSearchResultsByName(cases, query);
    archived = sortSearchResultsByName(archived, query);
    archived = sortSearchResultsByType(archived, ConversationType.Group);
    archived = sortSearchResultsByType(archived, ConversationType.Chat);

    groups = sortSearchResultsParticipantsByName(groups, query);
    cases = sortSearchResultsParticipantsByName(cases, query);
    archived = sortSearchResultsParticipantsByName(archived, query, true);

    return {
      chats,
      groups,
      cases,
      archived,
      external,
    };
  };

  public static findConversationParticipant = (
    conversation: Conversation,
    userId: string
  ): ConversationParticipant | null => {
    if (!conversation.participants?.length) return null;
    return ConversationParticipantUtils.findConversationParticipant(
      conversation.participants,
      userId
    );
  };

  public static findConversationParticipantModelV2 = (
    conversation: ConversationModelV3,
    userId: string
  ): ConversationParticipantModelV2 | null => {
    if (!conversation.participants?.length) return null;
    return ConversationParticipantUtils.findConversationParticipantModelV2(
      conversation.participants,
      userId
    );
  };

  /**
   * Returns a copy of `conversations` containing only External chats where the current user is an active
   * participant.
   */
  public static filterExternalConversations(
    conversations: Conversation[],
    userId: string
  ) {
    return produce(conversations, (draft) => {
      const filtered = draft.filter(
        (c) =>
          c.type === ConversationType.External &&
          c.participants?.some(
            (p) => p.userId === userId && isNullOrUndefined(p.leftOnUtc)
          )
      );
      return filtered;
    });
  }

  /**
   * Returns a copy of `conversations` containing only TeamChats where the current user is member of
   * that conversation's team.
   */
  public static filterTeamChats(
    conversations: Conversation[],
    teamId: string,
    userId: string
  ): Conversation[] {
    return produce(conversations, (draft) => {
      const filtered = draft.filter(
        (c) =>
          c.type === ConversationType.TeamChat &&
          c.participants?.find((p) => p.userId === userId)?.teamId === teamId
      );
      return filtered;
    });
  }

  public static isMuted(conversation: Conversation, userId: string): boolean {
    const participant = ConversationUtils.findConversationParticipant(
      conversation,
      userId
    );
    if (!participant) throw new Error("Failed to find participant");
    if (!participant.mutedToUtc) return false;
    const mutedToDate = new Date(participant.mutedToUtc);
    const now = new Date();
    return now < mutedToDate;
  }

  public static isLastActiveParticipant(
    conversation: Conversation,
    userId: string
  ): boolean {
    const participants = conversation.participants;
    if (!participants) return false;
    return ConversationParticipantUtils.isLastActiveParticipant(
      participants,
      userId
    );
  }
}
