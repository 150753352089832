<div class="bar">
  <!-- TODO: Move this to a separate component -->
  <div class="icon-container">
    <mat-icon color="primary" svgIcon="video-on"></mat-icon>
  </div>
  <div class="text-container">
    Video call in progress.
    <button class="join-button" mat-button (click)="onJoin()">
      {{ call.isOpen ? "Return to call." : "Click to join." }}
    </button>
  </div>
</div>
