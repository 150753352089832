<div class="backdrop">
  <div class="information-container">
    <div id="avatar-container">
      <app-voip-conversation-avatar
        [conversation]="conversation"
        [size]="80"
      ></app-voip-conversation-avatar>
    </div>
    <div class="text-container">
      <div class="conversation-name">
        {{ conversationName }}
      </div>
      <div *ngIf="!disableMessage">
        {{ message }}
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="!disableBackground && (!(isCapturingVideo$ | async) || isCallEnded)"
  class="solid-background"
></div>
