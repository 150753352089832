import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-edit-profile-pic",
  templateUrl: "./edit-profile-pic.component.html",
  styleUrls: ["./edit-profile-pic.component.scss"],
})
export class EditProfilePicComponent {
  @Input()
  profile: any;

  @Input()
  refreshCount: number;

  @Input()
  doNotDisturbToUtc: string;

  @Input()
  nocache;

  @Output()
  edit = new EventEmitter();

  @Input()
  width = 200;
}
