import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@modules/core";

@Injectable()
export class AuthGuard {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const extras = this.router.getCurrentNavigation()?.extras;
    if (extras?.state?.ignoreAuth || this.authService.isAuthenticated()) return true;
    return this.router.createUrlTree(['/'])
  }
}
