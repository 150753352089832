<form [formGroup]="formGroup">
  <div class="celo-input-outline">
    <!-- Name -->
    <ng-container *ngIf="!hideName">
      <ng-container *ngIf="readonly; else nameField">
        <div class="field-title">Name</div>
        <p class="field-value">{{ name | trim }}</p>
      </ng-container>

      <ng-template #nameField>
        <mat-form-field appearance="outline" class="name-field">
          <mat-label>Name</mat-label>
          <input
            matInput
            placeholder="Name"
            name="name"
            formControlName="name"
          />
        </mat-form-field>
      </ng-template>
    </ng-container>

    <!-- Description -->
    <ng-container *ngIf="readonly; else descriptionField">
      <ng-container *ngIf="description">
        <div *ngIf="!hideDescriptionHeader" class="field-title mt-10">
          Description
        </div>
        <p
          #descriptionRef
          class="field-value clamp"
          [innerHtml]="description | trim | linkify"
        ></p>
        <div
          *ngIf="isDescriptionOverflowing"
          id="read-more"
          class="field-value celo-link"
          role="button"
          (click)="readMore()"
        >
          Read more
        </div>
      </ng-container>
    </ng-container>

    <ng-template #descriptionField>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea
          id="description"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="5"
          cdkAutosizeMaxRows="5"
          matInput
          placeholder="Description"
          name="description"
          formControlName="description"
        ></textarea>
      </mat-form-field>
    </ng-template>
  </div>
</form>
