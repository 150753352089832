import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { environment } from "@env";
import { AlertService, SharedService } from "@modules/core";
import { DocumentsService } from "@modules/library/documents/documents.service";
import { take } from "rxjs/operators";
@Component({
  selector: "app-edit-fields",
  templateUrl: "./edit-fields.component.html",
  styleUrls: ["./edit-fields.component.scss"],
})
export class EditFieldsComponent implements OnChanges {
  @Input()
  formFields: any;

  @Input()
  type: string;

  @Input()
  formSubmitInfo: any;

  @Input()
  editMode: boolean;

  @Input() patient: any;
  @Input() file_id: string;

  @Output() update = new EventEmitter<any>();
  @ViewChild("autosize") autosize: CdkTextareaAutosize;

  original: any;

  myForm: UntypedFormGroup;
  formObject: any;
  hasChanged = false;
  saving: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private alertService: AlertService,
    private ngZone: NgZone,
    private documentsService: DocumentsService
  ) {}

  ngOnChanges() {
    this.original = [...this.formFields];
    this.buildForm();
    this.onChanges();
    this.hasChanged = false;
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  buildForm() {
    this.formObject = {};
    for (const field of this.formFields) {
      this.formObject[field.key] = { value: field.value, disabled: false };
    }
    this.myForm = this.formBuilder.group(this.formObject);
  }

  onChanges(): void {
    this.myForm.valueChanges.subscribe((val) => {
      this.saving = false;
      if (this.myForm.valid) {
        this.hasChanged = true;
      } else {
        this.hasChanged = false;
      }
    });
  }

  cancel() {
    this.formObject = [...this.original];
    this.ngOnChanges();
  }

  save() {
    this.saving = true;

    if (this.type == "photo") {
      const updatePatientPhotoModel: any = {
        patientData: this.patient,
      };
      for (const field of this.formFields) {
        updatePatientPhotoModel[field.key] =
          this.myForm.controls[field.key].value;
      }
      const path =
        environment.celoApiEndpoint +
        "/api/v2/media/{photoId}".replace(
          "{" + "photoId" + "}",
          String(this.formSubmitInfo.photo_id)
        );

      this.sharedService
        .putObjectById(path, {}, updatePatientPhotoModel)
        .subscribe(
          (resp) => {
            this.update.emit(resp);
            this.hasChanged = false;
          },
          (err) => {
            this.alertService.alert(
              "Sorry",
              "The given information cannot be saved !"
            );
          }
        );
    } else if (this.type == "document") {
      const updatePatientPhotoModel: any = {
        patientData: this.patient,
      };
      for (const field of this.formFields) {
        updatePatientPhotoModel[field.key] =
          this.myForm.controls[field.key].value;
      }
      const i = this;
      this.documentsService.updateDocumentPut(
        this.file_id,
        updatePatientPhotoModel,
        function (resp) {
          i.update.emit(resp);
          i.hasChanged = false;
          i.alertService.showSnackBar("Saved", 2);
        }
      );
    }
  }
}
