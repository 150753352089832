import { Component, Input } from "@angular/core";
import { ProfileService } from "app/modules/profile/profile.service";
import { SharedService } from "../../core";

@Component({
  selector: "app-connections-list",
  templateUrl: "./connections-list.component.html",
  styleUrls: ["./connections-list.component.scss"],
})
export class ConnectionsListComponent {
  @Input() list;
  @Input() floatRight = false;
  @Input() userAccount = {};

  constructor(
    private profileService: ProfileService,
    private sharedService: SharedService
  ) {}

  openProfile(id) {
    this.sharedService.navigateTo("notifications", { profile_id: id });
  }

  onRemove(id) {
    for (let index = 0; index < this.list.length; index++) {
      if (this.list[index].connection.id == id) {
        this.list.splice(index, 1);
        return;
      }
    }
  }

  onAccept(id) {
    for (const request of this.list) {
      if (request.connection.id == id) {
        request.accepted = true;
        return;
      }
    }
  }

  acceptConnection(connection) {
    const instance = this;
    this.profileService.acceptConnectionRequest(connection.id, function (resp) {
      instance.onAccept(connection.id);
    });
  }

  deleteConnection(connection) {
    const instance = this;
    this.profileService.rejectConnectionRequest(
      connection.connection.id,
      function (resp) {
        instance.onRemove(connection.connection.id);
      }
    );
    // let instance = this;
    // this.profileService.deleteConnection(connection.connection.id,function(resp){
    //   instance.onRemove(connection.connection.id);
    // })
  }
}
