<div [ngClass]="{ hidden: loading }" class="onboard">
  <div class="content">
    <div class="contents">
      <img class="logo" src="../../../assets/logo-v2.svg" alt="" />
      <img class="cover" src="../../../assets/workspace-add.svg" alt="" />
      <div class="title">Workspace details</div>
      <div class="subtitle">
        Based on your email, you are eligible to join the following workspaces.
      </div>
      <div class="fields celo-input-outline">
        <app-add-company-details
          [company]="''"
          [workspace]="workspaces"
          [department]="''"
          [position]="''"
          [email]="email"
          [hideEmailField]="true"
          (validity)="workspace_details_valid = $event"
          (dataChange)="workspace_details = $event"
          [workspaceSubtitle]="
            'Please select your main workspace. You can add other workspaces later in the app. '
          "
          (companiesLoaded)="onCompaniesLoaded($event)"
          [autoCreateWorkspaceOnNoWorkspaces]="false"
          [countryCode]="''"
        ></app-add-company-details>
      </div>
      <!-- <div class="subtitle">Can’t see your workspace on the list?</div>
      <div class="subtitle"><a class="celo-link" [routerLink]="['/onboard/workspace-register']" [queryParams]="{email:email}">Register new workspace</a></div> -->
      <div class="buttons">
        <a class="button" [routerLink]="['/conversations']" (click)="skip()">
          <button mat-button class="celo-secondary-button">SKIP</button>
        </a>
        <div class="button">
          <button
            mat-button
            [disabled]="loading"
            class="celo-primary-button"
            (click)="joinWorkspace()"
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" class="loading">
  <div class="spinner-box">
    <div class="spinner">
      <!-- <mat-spinner diameter=20 strokeWidth=2></mat-spinner> -->
      <app-empty-state
        [no_message]="true"
        [isLoading]="true"
        [spinner]="false"
        [animation]="true"
      ></app-empty-state>
    </div>
  </div>
</div>
