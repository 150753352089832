import { Component, Inject, OnInit } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { createDialogConfig } from "@utils";
import { ErrorDescriptor } from "utils/error-utils";

export interface AlertData {
  title?: string;
  message?: string;
  acceptOnly?: boolean;
  acceptButtonText?: string;
  closeButtonText?: string;
  reverseButtonOrder?: boolean;

  /** Whether the user can use escape or clicking on the backdrop to close the modal. */
  disableClose?: boolean;
}

export type AlertResult = boolean;

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
  public title?: string;
  public message?: string;
  public acceptOnly: boolean = true;
  public acceptButtonText = "Ok";
  public closeButtonText = "Close";
  public reverseButtonOrder: boolean = false;

  public constructor(@Inject(MAT_DIALOG_DATA) private dialogData: AlertData) { }

  /**
   * Show a modal dialog with an error message.
   */
  public static openErrorDialogFromErrorDescriptor(
    matDialog: MatDialog,
    descriptor?: ErrorDescriptor | null
  ): MatDialogRef<AlertComponent, AlertResult> {
    const config = createDialogConfig<AlertData>({
      title: "Error",
      message: "An unexpected error occurred. Please try again.",
      acceptButtonText: "Close",
    });

    if (descriptor) {
      config.data.title = descriptor.title;
      config.data.message = descriptor.message;
    }

    config.autoFocus = "dialog";
    return matDialog.open<AlertComponent, AlertData, AlertResult>(
      AlertComponent,
      config
    );
  }

  /**
   * Show a modal dialog with an error message.
   */
  public static openErrorDialog(
    matDialog: MatDialog,
    message: string | null = null
  ): MatDialogRef<AlertComponent, AlertResult> {
    const config = createDialogConfig<AlertData>({
      title: "Error",
      message: message ?? "An unexpected error occurred. Please try again.",
      acceptButtonText: "Close",
    });
    config.autoFocus = "dialog";
    return matDialog.open<AlertComponent, AlertData, AlertResult>(
      AlertComponent,
      config
    );
  }

  public static openDialog(
    matDialog: MatDialog,
    data: AlertData
  ): MatDialogRef<AlertComponent, AlertResult> {
    const config = createDialogConfig<AlertData>(data, { disableClose: data.disableClose });
    return matDialog.open<AlertComponent, AlertData, AlertResult>(
      AlertComponent,
      config
    );
  }

  public ngOnInit() {
    const {
      title,
      message,
      acceptOnly,
      acceptButtonText,
      closeButtonText,
      reverseButtonOrder,
    } = this.dialogData;
    this.title = title;
    this.message = message;
    this.acceptOnly = acceptOnly ?? this.acceptOnly;
    this.acceptButtonText = acceptButtonText ?? this.acceptButtonText;
    this.closeButtonText = closeButtonText ?? this.closeButtonText;
    this.reverseButtonOrder = reverseButtonOrder ?? this.reverseButtonOrder;
  }
}
