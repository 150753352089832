import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  Contact,
  DepartmentContactGroup,
} from "app/modules/conversations/new-conversations/contacts-expansion-panel/contacts-expansion-panel.component";
import { ContactModel } from "types";

@Component({
  selector: "app-workspace-list",
  templateUrl: "./workspace-list.component.html",
  styleUrls: ["./workspace-list.component.scss"],
})
export class WorkspaceListComponent {
  loading: boolean;
  page: number;
  sub: any;
  @Input() groupedLocalContactsDepartments: DepartmentContactGroup[] = [];
  NO_DEPARTMENT = <any>{ name: "No Department" };
  @Input() workspaceId: string;
  @Input() userAccount: any = {};
  @Input() searchTerm: string;
  @Output() contact = new EventEmitter<Contact>();

  onCardClick(contact: ContactModel) {
    this.contact.emit(contact);
  }
}
