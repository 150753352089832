import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "app-user-picker-layout",
  templateUrl: "./user-picker-layout.component.html",
  styleUrls: ["./user-picker-layout.component.scss"],
})
export class UserPickerLayoutComponent {
  @Input() isLoading: boolean = false;
  @Input() hideRightContent: boolean = false;

  @HostBinding("class")
  public get klass() {
    return this.hideRightContent ? "hide-right-content" : null;
  }
}
