<ng-container
  *ngIf="{
    mentionsSearchResults: mentionsSearchResults$ | async
  } as data"
>
  <div class="mentions-menu-container">
    <div
      *ngIf="data.mentionsSearchResults?.length && messageInput.isFocused"
      class="mentions-menu"
    >
      <app-participant-list
        [participantsList]="data.mentionsSearchResults"
        [userId]="userId"
        [selectable]="true"
        [preventFocus]="true"
        (newMentionClicked)="handleMention($event)"
      ></app-participant-list>
    </div>
  </div>

  <app-message-input
    #messageInput
    [initialValue]="initialValue"
    [disableSubmit]="data.mentionsSearchResults !== null"
    [maxLength]="maxLength"
    (submitEvent)="handleSubmit($event)"
    (changeEvent)="handleChange($event)"
    (actionEvent)="handleAction($event)"
    (validationEvent)="handleValidationError($event)"
    (pasteEvent)="handlePaste($event)"
  ></app-message-input>
</ng-container>
