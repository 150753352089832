import { Component } from "@angular/core";
import { NetworkUserPickerService } from "./../network-user-picker.service";

@Component({
  selector: "app-conversation-type-buttons",
  templateUrl: "./conversation-type-buttons.component.html",
  styleUrls: ["./conversation-type-buttons.component.scss"],
})
export class ConversationTypeButtonsComponent {
  public constructor(
    private networkUserPickerService: NetworkUserPickerService
  ) {}

  public handleGroupClick() {
    this.networkUserPickerService.onCreateGroup();
  }

  public handleCaseClick() {
    this.networkUserPickerService.onCreateCase();
  }
}
