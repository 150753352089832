import { AbstractControl, ValidatorFn } from "@angular/forms";
import { isValidPhoneNumber } from "libphonenumber-js";

export const isNotWhitespaceValidator: ValidatorFn = (
  control: AbstractControl
) => {
  const value = control.value;
  if (!value || value.trimEnd()?.length === 0) {
    return { isWhitespace: true };
  }
  return null;
};

export const isValidPhoneNumberValidator: ValidatorFn = (
  control: AbstractControl
) => {
  const value = control.value;

  if (typeof value !== "string" || !isValidPhoneNumber(value)) {
    return { isInvalidPhoneNumber: true };
  }

  return null;
};
