import { Component, Inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

export type SnackbarTheme = "light" | "dark";

export interface SnackbarData {
  message: string;
  theme: SnackbarTheme;
}

@Component({
  selector: "app-snackbar",
  templateUrl: "snackbar.html",
  styleUrls: ["snackbar.css"],
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {}
}
