import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class DirectoryService {
  public static NO_DEPARTMENT = "No Department";
  public static ALL_DEPARTMENTS = "All Departments";

  private searchFilter: SearchContactFilter;
  private searchFilterSubject = new Subject<SearchContactFilter>();
  private searchContactSubject = new Subject<any[]>();

  constructor(private http: HttpClient) {
    this.searchFilter = { pageSize: 999999 };
    this.searchFilterSubject.subscribe((filter) => {
      const path = `${environment.celoApiEndpoint}/api/Companies/${filter.companyId}/Contacts`;

      let params = new HttpParams().set("Page", "0");

      if (filter.pageSize) {
        params.set("PageSize", filter.pageSize.toString());
      }

      if (filter.oncallStatus === "oncall") {
        params = params.set("IsOnCall", "true");
      }
      if (
        filter.department &&
        filter.department != DirectoryService.NO_DEPARTMENT &&
        filter.department != DirectoryService.ALL_DEPARTMENTS
      ) {
        params = params.set("Department", filter.department);
      }
      if (filter.fullName) {
        params = params.set("FullName", filter.fullName);
      }

      this.http
        .get<any>(path, { params })
        .pipe(map((x) => x.data))
        .subscribe((contacts) => {
          if (filter.department == DirectoryService.NO_DEPARTMENT) {
            contacts = contacts.filter((c) => !c.department);
          }
          this.searchContactSubject.next(contacts);
        });
    });
  }

  resetSearch(filter: SearchContactFilter) {
    this.searchFilter = filter;
    this.searchFilterSubject.next(this.searchFilter);
  }

  searchByDepartment(department: string) {
    this.searchFilter.department = department;
    this.searchFilterSubject.next(this.searchFilter);
  }

  searchByOnCall(oncall: string) {
    this.searchFilter.oncallStatus = oncall;
    this.searchFilterSubject.next(this.searchFilter);
  }

  searchByFullName(fullName: string) {
    this.searchFilter.fullName = fullName;
    this.searchFilterSubject.next(this.searchFilter);
  }

  getSearchContacts(): Observable<any[]> {
    return this.searchContactSubject.asObservable();
  }

  getGroupedContacts(contacts: any[], userAccount: any) {
    if (contacts === null) {
      return null; // clears async pipe
    }
    const group = {};
    for (const contact of contacts) {
      if (contact.userId !== userAccount.userId) {
        const departmentName =
          contact.department || DirectoryService.NO_DEPARTMENT;
        group[departmentName] = group[departmentName] || [];
        group[departmentName].push(contact);
      }
    }
    const pairs = [];
    if (group[DirectoryService.NO_DEPARTMENT]) {
      pairs.push({
        departmentName: DirectoryService.NO_DEPARTMENT,
        departmentContacts: group[DirectoryService.NO_DEPARTMENT],
      });
      delete group[DirectoryService.NO_DEPARTMENT];
    }
    if (userAccount.department && group[userAccount.department.name]) {
      pairs.push({
        departmentName: userAccount.department.name,
        departmentContacts: group[userAccount.department.name],
      });
      delete group[userAccount.department.name];
    }
    for (const k in group) {
      pairs.push({ departmentName: k, departmentContacts: group[k] });
    }
    return pairs;
  }
}

export interface SearchContactFilter {
  companyId?: string;
  department?: string;
  fullName?: string;
  pageSize?: number;
  oncallStatus?: string;
}
