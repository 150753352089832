import { MentionModel } from "@types";

export class InvalidMentionError extends Error {
    public constructor(content: string, mention: MentionModel) {
        super();

        let serializedMention: string;
        try {
            serializedMention = JSON.stringify(mention, null, 2);
        } catch {
            serializedMention = "<Failed to serialize>";
        }

        this.message = [
            "Invalid mention",
            `Content = ${content}`,
            `Mention = ${serializedMention}`,
        ].join(", ");
    }
}