import { Component } from "@angular/core";
import { UserPickerService } from "@modules/user-picker/user-picker.service";
import { WorkspaceService } from "app/workspace/workspace.service";
import { AlertService, AnalyticsService, SharedService } from "../../core";

@Component({
  selector: "app-conversations-home",
  templateUrl: "./conversations-home.component.html",
  styleUrls: ["./conversations-home.component.scss"],
})
export class ConversationsHomeComponent {
  cards = [
    {
      title: "Set up your team",
      description: "Join or create a workspace with your colleagues.",
      image: "../assets/ctas/cta-set-up-your-team.svg",
      id: "create_team",
      actions: {
        title: "Set up your team",
        steps: [
          {
            title: "Join workspace via link",
            description:
              "Have you been shared an invite link? Tap that link to join your workspace instantly",
            image: "../assets/ctas/cta-join-via-link.svg",
          },
          {
            title: "Create a workspace",
            description:
              "You can create your own workspace and invite your team, big or small.",
            image: "../assets/ctas/cta-create-a-workspace.svg",
            button: "Create workspace",
            id: "create_workspace",
          },
          {
            title: "Join via email",
            description:
              "Use your work email to find existing workspaces you can join instantly.",
            image: "../assets/ctas/cta-join-workspace-via-email.svg",
            button: "Join workspace",
            id: "join_workspace",
          },
        ],
      },
    },
    {
      title: "Connect with colleagues",
      description: "Connect with your colleagues and start chatting.",
      image: "../assets/ctas/cta-add-your-colleagues.svg",
      id: "add_colleagues",
      actions: {
        title: "Connect with colleagues",
        steps: [
          {
            title: "Add your colleagues",
            description:
              "You can add your colleagues by email, SMS or by sharing your profile link.",
            image: "../assets/ctas/cta-set-up-your-team.svg",
            button: "Add colleagues",
            id: "add_colleagues",
          },
          {
            title: "Search and connect",
            description:
              "Search and connect with your colleagues already on Celo from the network tab.",
            image: "../assets/ctas/cta-search-and-connect.svg",
            button: "Search Celo",
            id: "search_celo_colleagues",
          },
        ],
      },
    },
    {
      title: "Start a conversation",
      description:
        "Start a new chat or move your group from another messaging app.",
      image: "../assets/ctas/cta-add-your-colleagues-copy.svg",
      id: "create_conversation",
    },
  ];

  constructor(
    public alertService: AlertService,
    public workspaceService: WorkspaceService,
    public analyticsService: AnalyticsService,
    public sharedService: SharedService,
    private userPickerService: UserPickerService
  ) {}

  openActions(actions, buttton_id) {
    const payload = [
      {
        data: actions,
        type: "home_cta",
      },
    ];
    let action = "";
    switch (buttton_id) {
      case "create_team":
        action = "set_up_your_team";
        break;
      case "add_colleagues":
        action = "add_your_colleagues";
        break;
      case "create_conversation":
        action = "start_a_conversation";
        break;
    }

    this.analyticsService.raiseEvent("CTA", {
      action,
    });

    if (action == "start_a_conversation") {
      this.createGroup();
      return;
    }
    this.alertService
      .customDialog("", "", "", "", false, "", payload)
      .afterClosed()
      .subscribe((res) => {
        if (!res) {
          return;
        }
        switch (res.type) {
          case "create_workspace":
            this.createWorkspace();
            this.analyticsService.raiseEvent("CTA", {
              action: "create_workspace",
            });
            break;
          case "join_workspace":
            this.analyticsService.raiseEvent("CTA", {
              action: "join_workspace",
            });
            this.joinWorkspace();
            break;
          case "add_colleagues":
            this.addColleagues();
            this.analyticsService.raiseEvent("CTA", {
              action: "add_colleagues",
            });
            break;
          case "search_celo_colleagues":
            this.search();
            this.analyticsService.raiseEvent("CTA", {
              action: "search_celo",
            });
            break;
          default:
            break;
        }
      });
  }

  joinWorkspace() {
    const instance = this;
    this.workspaceService.joinWorkspaceFlow(function (result) {
      if (!result) {
        return;
      }
      if (result["type"] == "create_workspace") {
        return instance.createWorkspace();
      }
      instance.alertService.showSnackBar(
        "Workspace has been added successfully",
        3
      );
      if (result.recentlyAddedWorkspaceId) {
        instance.sharedService.navigateToDirectory(
          result.recentlyAddedWorkspaceId
        );
      }
    });
  }

  createWorkspace() {
    const instance = this;
    this.workspaceService.createWorkspaceFlow(function (result) {
      if (!result) {
        return;
      }
      if (result["type"] == "join_workspace") {
        return instance.joinWorkspace();
      }
      instance.alertService.showSnackBar(
        "Workspace has been created successfully",
        3
      );
      if (result.id) {
        instance.sharedService.navigateToDirectory(result.id);
      }
    });
  }

  addColleagues() {
    this.sharedService.invite("user");
  }

  createGroup() {
    this.userPickerService.openNetworkUserPicker({
      header: "New Chat",
      subheader: "My Network",
      searchPlaceholder: "Search network",
      selectedHeader: "Participants",
      selectedQuantityLabels: {
        zero: "participants",
        one: "participant",
        plural: "participants",
      },
    });
  }

  search() {
    this.sharedService.navigateToDirectorySearch();
  }
}
