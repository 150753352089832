<div class="link-options">
  <div *ngIf="title" class="title">{{ title }}</div>
  <div class="subtitle">{{ subtitle }}</div>
  <div *ngIf="!hideLink" class="invite-link celo-elipsis celo-cursor-pointer" matRipple (click)="linkClick.emit(link)">
    <div class="celo-link" target="_blank">
      {{ link ? link : linkFallback }}
    </div>
  </div>
  <div class="link-buttons">
    <div *ngIf="!disableEmailInvite">
      <a *ngIf="link" (click)="shareClick.emit()">
        <button mat-button class="celo-hoverable">
          <div class="content">
            <mat-icon svgIcon="email_outline"></mat-icon>
            <span>Invite via email</span>
          </div>
        </button>
      </a>
      <a *ngIf="!link" (click)="error()">
        <button mat-button class="celo-hoverable">
          <div class="content">
            <mat-icon svgIcon="email_outline"></mat-icon>
            <span>Invite via email</span>
          </div>
        </button>
      </a>
    </div>
    <div>
      <button mat-button class="celo-hoverable" (click)="copyClick.emit(link)">
        <div class="content">
          <mat-icon>content_copy</mat-icon>
          <span>&nbsp;&nbsp;{{ copyButttonText }}</span>
        </div>
      </button>
    </div>
    <div *ngIf="canReset">
      <button mat-button class="celo-hoverable" (click)="resetLink.emit(link)">
        <div class="content">
          <mat-icon svgIcon="ic-reset"></mat-icon>
          <span>&nbsp;&nbsp;Reset invite link</span>
        </div>
      </button>
    </div>
  </div>
</div>