<div class="pb-1 parent">
  <div class="titlebar">
    <div class="pb-2">
      <div class="title">{{ title }}</div>
      <div class="subtitle">
        <span *ngIf="showMaxCount">
          {{ selectedContacts.length }} of {{ maxCapacity }} {{ salutation }}s
          selected
        </span>
        <span *ngIf="!showMaxCount">
          {{ selectedContacts.length }} participant{{
            selectedContacts.length === 1 ? "" : "s"
          }}
          selected
        </span>
      </div>
      <div
        *ngIf="showAddParticipantsButton"
        matRipple
        class="subbutton celo-hoverable"
        (click)="editParticipants.emit()"
      >
        <button mat-icon-button >
          <i class="material-icons">edit</i>
        </button>
        <span>Add Participants</span>
      </div>
    </div>
  </div>
  <div class="list_content">
    <div>
      <div
        *ngFor="let contact of selectedContacts"
        class="chip celo-hoverable"
        (click)="removable ? checkAndRemove(contact) : ''"
      >
        <div class="avatar">
          <app-avatar
            [name]="contact.firstName + ' ' + contact.lastName"
            [userId]="contact.userId"
            [width]="40"
            [height]="40"
            [hasImage]="!!contact.profilePic || !!contact.profilePicture"
            [picUrl]="contact.profilePic"
          ></app-avatar>
        </div>
        <div class="chip-name celo-elipsis">
          <span *ngIf="!replaceMyName"
            >{{ contact.firstName }} {{ contact.lastName }}</span
          >
          <span *ngIf="replaceMyName">
            <span *ngIf="contact.userId === loggedInUserId">{{
              replaceMyName
            }}</span>
            <span *ngIf="contact.userId !== loggedInUserId"
              >{{ contact.firstName }} {{ contact.lastName }}</span
            >
          </span>
        </div>
        <div *ngIf="!contact.disableRemove && removable" class="close-button">
          <i class="material-icons">cancel</i>
        </div>
      </div>
      <div #bottom id="bottom"></div>
    </div>
    <div>
      <div *ngIf="selectedContacts.length === 0" class="empty_message">
        <div class="message">
          {{ empty_message }}
        </div>
      </div>
    </div>
  </div>
</div>
