import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { TeamsService, UserService } from "@modules/core";
import { Team, TeamMemberRole } from "@types";
import { concatNotNull } from "@utils";
import { TeamMemberWithDepartment } from "../roles-members-list/roles-members-list.component";

@Component({
  selector: "app-roles-members-list-item",
  templateUrl: "./roles-members-list-item.component.html",
  styleUrls: ["./roles-members-list-item.component.scss"],
})
export class RolesMembersListItemComponent implements OnInit, OnChanges {
  @Input() public member: TeamMemberWithDepartment | null = null;
  @Input() public team: Team | null = null;

  public isOnCall: boolean = false;
  public isAdmin: boolean = false;
  public isSelf: boolean = false;
  public isCurrentUserAdmin: boolean = false;

  public get name() {
    if (!this.member?.user?.fullName) return "";
    return `${this.member.user.fullName}${this.isSelf ? " (You)" : ""}`;
  }

  public get description() {
    const profession = this.member?.user?.profession;
    const department = this.member?.departmentName;
    return concatNotNull([profession, department], ", ");
  }

  @HostBinding("class") get klass() {
    return this.isSelf ? "" : "celo-hoverable celo-cursor-pointer";
  }

  public constructor(
    private teamsService: TeamsService,
    private userService: UserService
  ) {}

  public ngOnInit(): void {
    const userId = this.userService.getUserId(true);
    this.isSelf = this.member?.user?.userId === userId;
    this.isCurrentUserAdmin = this.team
      ? this.teamsService.isCurrentUserAdmin(this.team)
      : false;
    this.updateStatuses();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.updateStatuses();
  }

  private updateStatuses() {
    if (this.member?.user?.userId && this.team) {
      this.isOnCall = this.teamsService.isOnCall(
        this.member.user.userId,
        this.team
      );
      this.isAdmin = this.member.role === TeamMemberRole.Administrator;
    } else {
      this.isAdmin = false;
      this.isOnCall = false;
    }
  }
}
