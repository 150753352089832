<div
  *ngFor="let participant of participants"
  class="celo-hoverable"
  [class.celo-cursor-pointer]="currentUserId !== participant.userId"
  matRipple
  [matRippleDisabled]="currentUserId === participant.userId"
  [attr.role]="currentUserId === participant.userId ? null : 'button'"
>
  <app-conversation-participant-popup-menu
    [participant]="participant"
    [isAdmin]="participant.role === 'Administrator'"
    [isCurrentUserAdmin]="isCurrentUserAdmin"
    [disabled]="currentUserId === participant.userId"
    [removeText]="removeParticipantText"
    (action)="action.emit($event)"
  >
    <app-basic-user-avatar-card
      *ngIf="!participant.isExternal"
      [userId]="participant.userId"
      [name]="participant.firstName + ' ' + participant.lastName"
      [padding]="'9px 20px'"
      [suffix]="currentUserId === participant.userId ? ' (You)' : null"
    >
      <div
        *ngIf="participant.role === 'Administrator'"
        end
        class="flex place-items-center admin"
      >
        Admin
      </div>
    </app-basic-user-avatar-card>
  </app-conversation-participant-popup-menu>
</div>
