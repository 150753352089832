<div class="open-slow" [ngClass]="{ 'open-slow-to-height': seenBy.length }">
  <span *ngFor="let icon of seenBy; let i = index">
    <div class="seen-bubbles">
      <div
        *ngIf="i === maxBubbles"
        class="extra"
        [matTooltip]="'Click for more info'"
      >
        <div>
          <span>+{{ seenBy.length - maxBubbles }}</span>
        </div>
      </div>
      <div
        *ngIf="icon.participant && i < maxBubbles"
        class="seen-bubble-padding seen-bubble-style"
        [matTooltip]="
          'Seen by ' +
          icon.participant.firstName +
          ' ' +
          icon.participant.lastName
        "
      >
        <div class="avatar">
          <app-avatar
            class="celo-cursor-pointer"
            [name]="
              icon.participant.firstName + ' ' + icon.participant.lastName
            "
            [userId]="icon.participant.userId"
            [width]="16"
            [height]="16"
            [hasImage]="!!icon.participant.profilePicture"
          >
          </app-avatar>
        </div>
      </div>
    </div>
  </span>
</div>
<div *ngIf="!seenBy.length" class="status-bar">
  <div *ngIf="userAccount.userId === message.sentBy">
    <div class="with-icon" *ngIf="getDelivered().length">
      <mat-icon matTooltip="Delivered">check_circle</mat-icon>
    </div>

    <div *ngIf="!getDelivered().length">
      <div class="with-icon" *ngIf="!getSending().length || getSent().length">
        <mat-icon matTooltip="Sent">check_circle_outline</mat-icon>
      </div>

      <div
        *ngIf="getSending().length && getSent().length === 0"
        class="with-icon"
      >
        <div
          *ngIf="message.NOT_SENT"
          (click)="
            $event.preventDefault();
            $event.stopPropagation();
            message.NOT_SENT_RETRY_FUNC()
          "
          class="send-fail"
        >
          <span>Failed, Click to retry</span>
          <mat-icon class="icon_v" matTooltip="Retry"
            >settings_backup_restore</mat-icon
          >
        </div>
        <span *ngIf="!message.NOT_SENT">
          <mat-icon matTooltip="Sending">panorama_fish_eye</mat-icon>
        </span>
      </div>
      <!-- <div class="with-icon" *ngIf="getSent().length">
        <mat-icon matTooltip="Sent">check_circle_outline</mat-icon>
      </div>

      <div *ngIf="!getSent().length">
        1
        <div *ngIf="getSending().length" class="with-icon">
          <div
            *ngIf="message.NOT_SENT"
            (click)="$event.preventDefault();$event.stopPropagation();message.NOT_SENT_RETRY_FUNC();"
            class="send-fail">
            <span>Failed, Click to retry</span>
            <mat-icon class="icon_v" matTooltip="Retry">settings_backup_restore</mat-icon>
          </div>
          <span *ngIf="!message.NOT_SENT">
            <mat-icon matTooltip="Sending">panorama_fish_eye</mat-icon>
          </span>
        </div>
      </div> -->
    </div>
  </div>
</div>
