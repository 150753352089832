<div class="celo-text-14 celo-grey">
  {{ message }}
</div>
<div class="box">
  <div class="codes">
    <div *ngFor="let app of apps" class="app celo-hoverable">
      <a [href]="app.target_url" target="_blank">
        <div class="fw">
          <img class="qrcode" [src]="app.qrcode_url" alt="" />
        </div>
        <div class="badges">
          <img  [src]="app.badge_url" alt="" />
        </div>
      </a>
    </div>
  </div>
</div>
