import { Observable, OperatorFunction, pipe } from "rxjs";
import { buffer, debounceTime, map, toArray } from "rxjs/operators";

export const flatMap = <T, O>(project: (value: T) => O[] | null | undefined) =>
  pipe(
    toArray<T>(),
    map((array) => array.flatMap((elem) => project(elem) ?? []))
  );

// From: https://stackoverflow.com/a/62598663
export type BufferDebounce = <T>(debounce: number) => OperatorFunction<T, T[]>;
export const bufferDebounce: BufferDebounce = (debounce) => (source) =>
  new Observable((observer) =>
    source.pipe(buffer(source.pipe(debounceTime(debounce)))).subscribe({
      next(x) {
        observer.next(x);
      },
      error(err) {
        observer.error(err);
      },
      complete() {
        observer.complete();
      },
    })
  );
