import {
  Component,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnDestroy,
  OnInit,
  Output,
  TrackByFunction,
} from "@angular/core";
import { concatNotNull } from "@utils";
import { Subscription } from "rxjs";
import {
  ConversationParticipantModelV2,
  FullUserProfileModel,
  MessageModel,
} from "types";
import { ConversationService } from "../../../modules/core";

@Component({
  selector: "app-messages-list",
  templateUrl: "./messages-list.component.html",
  styleUrls: ["./messages-list.component.scss"],
})
export class MessagesListComponent implements OnInit, OnDestroy {
  @Input() messages: MessageModel[]; // group of messages sent by one person in quick succession.
  @Input() userAccount: FullUserProfileModel;
  @Input() patientId: string;
  @Input() isOnDND: boolean;
  @Input() forceChangeDetection: number;
  @Input() public isBlocked: boolean = false;

  @Input() conversationId: string;
  @Input() conversationType: string;
  @Input() pMap: { [key: string]: ConversationParticipantModelV2 };
  @Output() selectMessage = new EventEmitter<MessageModel>();
  @Output() quote_clicked = new EventEmitter<MessageModel>();
  @Output() forward = new EventEmitter<MessageModel>();
  @Output() delete = new EventEmitter<MessageModel>();
  @Output() copyMessage = new EventEmitter<MessageModel>();
  @Input() searchQuery: string;

  private foundChatSub: Subscription | null = null;

  iterableDiffer: IterableDiffer<any>;

  @Input() public isConversationAdmin: boolean = false;
  @Input() public isActiveParticipant: boolean = false;
  @Input() public isPreviewMode: boolean = false;

  constructor(
    private _iterableDiffers: IterableDiffers,
    private conversationService: ConversationService
  ) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
  }

  ngOnInit() {
    if (this.conversationService.foundChatEmitted$) {
      this.foundChatSub = this.conversationService.foundChatEmitted$.subscribe(
        (finderObject) => {
          const message = finderObject["message"];
          if (finderObject["searchQuery"]) {
            this.searchQuery = finderObject["searchQuery"];
          }
          this.acknowledgeHighlight(message.id);
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.foundChatSub?.unsubscribe();
  }

  acknowledgeHighlight(id) {
    this.messages.forEach((message) => {
      if (message.id == id) {
        this.doHighlight(message);
        this.resetHighlight(message);
      }
    });
  }

  doHighlight(message) {
    setTimeout(() => {
      message["highlight"] = true;
    }, 10);
  }

  resetHighlight(message) {
    setTimeout(() => {
      message["highlight"] = false;
    }, 2000);
  }

  private getMessageIdentifier = (message: MessageModel): string => {
    return concatNotNull([
      message.marker,
      message.content,
      message.replyTo,
      message.deletedBy,
      message.deletedOnUtc,
    ]);
  };

  public trackByFn: TrackByFunction<MessageModel> = (index, message) => {
    if (!message.replyToMessage) return this.getMessageIdentifier(message);
    return concatNotNull([
      this.getMessageIdentifier(message),
      this.getMessageIdentifier(message.replyToMessage),
    ]);
  };
}
