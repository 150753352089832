import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { ForceCreatePasswordGuard } from "./force-create-password.guard";

@Injectable({
  providedIn: "root",
})
export class NotForceCreatePasswordGuard  {
  public constructor(
    private forceCreatePasswordGuard: ForceCreatePasswordGuard,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot) {
    if (this.forceCreatePasswordGuard.canActivate(route) === true) {
      return this.router.parseUrl("/conversations");
    }
    return true;
  }
}
