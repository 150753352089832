<app-celo-user-card
  [userId]="contact.userId"
  [contact]="contact"
  [workspaces]="contact.workplaces"
  [title]="contact.title"
  [firstName]="contact.firstName"
  [lastName]="contact.lastName"
  [profilePic]="contact.profilePic"
  [position]="contact.position"
  [companyInFocus]="companyInFocus"
  [companyInFocusId]="companyInFocus ? companyInFocus.id : ''"
  [showCompany]="showCompany"
  [showAllWorkspaces]="showAllWorkspaces"
  [department]="contact.department"
  [showDepartment]="showDepartment"
  [hideWorkspace]="hideWorkspace"
  [identityVerificationStatus]="contact.identityVerificationStatus"
  [showBadge]="showBadge"
  [hideProfession]="hideProfession"
  [profession]="
    contact.professions[0] && contact.professions[0].category
      ? contact.professions[0].profession
      : ''
  "
  [professionVerified]="
    contact.professions[0] &&
    contact.professions[0].verificationStatus === 'Verified'
  "
>
</app-celo-user-card>
