<div *ngIf="formFields && editMode">
  <form [formGroup]="myForm">
    <div *ngFor="let field of this.formFields">
      <div *ngIf="field.type === 'text'">
        <mat-form-field>
          <label class="font-14 font-grey">{{ field.name }}</label>
          <input matInput [formControlName]="field.key" [name]="field.key" />
        </mat-form-field>
      </div>
      <div *ngIf="field.type === 'textarea'">
        <mat-form-field>
          <label class="font-14 font-grey">{{ field.name }}</label>
          <textarea
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMaxRows="5"
            matInput
            [formControlName]="field.key"
            [name]="field.key"
            rows="1"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="open-slow" [ngClass]="{'open-slow-to-height':hasChanged}">
      <button mat-button class="celo-secondary-button" (click)="save()">Save</button>
      <button mat-button class="celo-secondary-button thin-font" (click)="cancel()">Cancel</button>
    </div> -->
    <app-celo-cancel-save-buttons
      [showNow]="hasChanged && !saving"
      (cancel)="cancel()"
      (save)="save()"
    >
    </app-celo-cancel-save-buttons>
  </form>
</div>
<div *ngIf="!editMode" class="field-data">
  <div *ngFor="let field of this.formFields">
    <div *ngIf="field.type === 'textarea' || field.type === 'text'">
      <div class="font-14 font-grey">{{ field.name }}</div>
      <div class="font-14">
        {{ field.value ? field.value : "No " + field.name }}
      </div>
    </div>
  </div>
</div>
