import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProfileService } from "app/modules/profile/profile.service";
import { SharedService, UserAccountService } from "../../core";
import { NotificationsService } from "../notifications.service";
import { finalize, tap } from "rxjs";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  userAccount: any;
  page = 0;
  total: any;
  pageSize: any = 10;
  focusSub: any;
  profile_id: string;

  public isLoadingMore: boolean = false;

  constructor(
    private userAccountService: UserAccountService,
    public profileService: ProfileService,
    public notificationsService: NotificationsService,
    public sharedService: SharedService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((paramMap) => {
      this.profile_id = paramMap.get("profile_id");
    });
    this.profileService.pendingConnections = [];

    const instance = this;
    this.userAccountService.getUserAccount(false, function (account) {
      if (!account) {
        return;
      }
      instance.userAccount = account;
    });
    this.getConnectionRequests();
    this.getActivities();
    this.markNotificationsRead();
    this.focusSub = this.sharedService.onFocusChange.subscribe((foucsed) => {
      if (
        foucsed &&
        (this.notificationsService.unseenConnections ||
          this.notificationsService.unseenActivityFeed)
      ) {
        this.markNotificationsRead();
      }
    });
  }

  onAccept(id) {
    for (const request of this.profileService.pendingConnections) {
      if (request.connection.id == id) {
        request.accepted = true;
        return;
      }
    }
  }

  onSidenavClose() {
    this.sharedService.navigateTo("/notifications");
  }

  markNotificationsRead() {
    const instance = this;
    this.profileService.updateNotificationStatuses(true, true, function (resp) {
      if (!resp || !resp.data) {
        return;
      }
      instance.profileService.pendingConnections =
        instance.profileService.pendingConnections.concat(resp.data);
      instance.total = resp.total;
    });
  }

  getConnectionRequests() {
    const instance = this;
    this.profileService.getPendingConnectionRequest(
      this.pageSize,
      this.page,
      function (resp) {
        if (!resp || !resp.data) {
          return;
        }
        instance.profileService.pendingConnections =
          instance.profileService.pendingConnections.concat(resp.data);
        instance.total = resp.total;
      }
    );
  }

  getActivities() {
    this.notificationsService.getActivities(false).subscribe();
  }

  loadMoreActivities() {
    if (this.isLoadingMore) return;
    this.isLoadingMore = true;
    this.notificationsService
      .getActivities(true)
      .pipe(
        finalize(() => {
          this.isLoadingMore = false;
        })
      )
      .subscribe();
  }

  loadMore() {
    this.page++;
    this.getConnectionRequests();
  }

  ngOnDestroy() {
    if (this.focusSub) {
      this.focusSub.unsubscribe();
    }
  }
}
