import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForceCreatePasswordGuard } from "app/guards/force-create-password.guard";
import { AuthGuard } from "app/guards/auth.guard";
import { PinGuard } from "app/guards/pin.guard";
import { ConsentsComponent } from "./consents/consents.component";
import { DocumentsComponent } from "./documents/documents.component";
import { LibraryComponent } from "./library.component";
import { MediaComponent } from "./media/media.component";

const routes: Routes = [
  {
    path: "",
    component: LibraryComponent,
    canActivate: [AuthGuard, PinGuard, ForceCreatePasswordGuard],
    children: [
      {
        path: "",
        redirectTo: "photos",
        pathMatch: "full",
      },
      {
        path: "photos",
        component: MediaComponent,
      },
      {
        path: "consents",
        component: ConsentsComponent,
      },
      {
        path: "documents",
        component: DocumentsComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LibraryRoutingModule {}
