import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from "@angular/core";
import { ConversationUtils } from "@utils";
import { Conversation, ConversationModelV2 } from "types";
import { ConversationService } from "../../core";
import { MuteStatusEvent } from "./../conversation-results-group/conversation-results-group.component";

@Component({
  selector: "app-conversation-results",
  templateUrl: "./conversation-results.component.html",
  styleUrls: ["./conversation-results.component.scss"],
})
export class ConversationResultsComponent implements OnChanges, OnDestroy {
  @Input() isSelectionList: boolean = false;
  @Input() selectedConversationId: string | null = null;
  @Input() conversations: Conversation[];
  @Input() userAccount: any;
  @Input() searchKeyword: string;
  @Input() canLoadMore: boolean;
  @Input() isLoading: boolean = false;
  @Input() isLoadingMore: boolean = false;

  @Output() loadMore = new EventEmitter(); // todo: should emit when we scroll, not button click;
  @Output() public conversation = new EventEmitter<ConversationModelV2>();

  chats = [];
  groups = [];
  cases = [];
  archived = [];
  refreshCount: any;

  constructor(private conversationService: ConversationService) {}

  ngOnDestroy() {
    this.conversations = undefined;
  }

  ngOnChanges() {
    if (this.conversations && this.conversations.length) {
      this.search();
    }
  }

  public onConversationSelected(conversation: ConversationModelV2) {
    this.conversation.emit(conversation);
  }

  resetAll() {
    this.chats = [];
    this.groups = [];
    this.cases = [];
    this.archived = [];
  }

  search() {
    this.resetAll();

    const sortedResults = ConversationUtils.sortSearchResults(
      this.conversations,
      this.userAccount.userId,
      this.searchKeyword
    );

    this.chats = sortedResults.chats;
    this.groups = sortedResults.groups;
    this.cases = sortedResults.cases;
    this.archived = sortedResults.archived;
  }

  muteStatusChange({ participant, conversation }: MuteStatusEvent) {
    // this.conversationService.muteStatusChange(participantData, conversation);
    this.conversationService.updateMuteStatusInConversation(
      participant.userId,
      conversation.id,
      participant.mutedToUtc,
      participant.muteInterval
    );
  }
}
