import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ProfileService } from "app/modules/profile/profile.service";
import { environment } from "environments/environment";
import { AlertService, SharedService } from "../../modules/core";

@Component({
  selector: "app-workspace-members",
  templateUrl: "./workspace-members.component.html",
  styleUrls: ["./workspace-members.component.scss"],
})
export class WorkspaceMembersComponent {
  @Input() participants = [];
  selectedParticipantIds = [];
  participantsToRemove = [];

  @Input() removedParticipantIds: string[] = [];
  @Input() workspaceId: string;
  @Input() conversation: any;
  @Output() closeClick = new EventEmitter<any>();
  @Output() needRefresh = new EventEmitter<any>();

  saving = false;
  loading: boolean;

  constructor(
    private profileService: ProfileService,
    private sharedService: SharedService,
    private alertService: AlertService
  ) {}

  openPreview(id) {
    this.profileService.openProfile(id);
  }

  onUpdate(changePacket) {
    this.selectedParticipantIds = changePacket["selected"]
      ? changePacket["selected"]
      : this.selectedParticipantIds;
    this.participantsToRemove = this.selectedParticipantIds;
  }

  cancel() {
    this.closeWindow();
  }

  closeWindow() {
    this.closeClick.emit(null);
  }

  editConversationSave() {
    this.loading = true;
    this.removeLoop(this.participantsToRemove, 0);
  }

  removeLoop(participantsToRemove: string[], index) {
    if (index >= participantsToRemove.length) {
      this.alertService.customDialog(
        "Success",
        participantsToRemove.length +
          " member" +
          (participantsToRemove.length > 1 ? "s" : "") +
          " removed successfully.",
        "Done",
        "",
        true
      );
      this.needRefresh.emit();
      this.closeWindow();
      this.loading = false;
      return;
    }
    const path =
      environment.celoApiEndpoint +
      "/api/v2/workspaces/" +
      this.workspaceId +
      "/users/" +
      participantsToRemove[index];
    this.sharedService.deleteObjectById(path).subscribe(
      (result) => {
        this.removeLoop(this.participantsToRemove, ++index);
      },
      (err) => {
        this.loading = false;
        this.alertService.confirm(
          "",
          this.sharedService.STANDARD_ERROR_MESSAGE,
          "Ok",
          "",
          true
        );
      }
    );
  }
}
