<div class="card-container">
  <app-celo-user-card
    [canRemove]="canRemove"
    [isAdmin]="isAdmin"
    (remove)="remove.emit($event)"
    [userId]="participant.userId"
    [title]="participant.title"
    [firstName]="participant.firstName"
    [lastName]="participant.lastName"
    [profilePic]="participant.profilePicture"
    [position]="participant.position"
    [workspaces]="participant.workplaces"
    [showAllWorkspaces]="true"
    [profileId]="profileId"
    [replaceMyName]="
      participant.firstName + ' ' + participant.lastName + ' (You)'
    "
    [hideWorkspace]="hideWorkspace"
    [replaceWorkspace]="replaceWorkspace"
    [showBadge]="true"
    [identityVerificationStatus]="participant.identityVerificationStatus"
    [contact]="participant"
    [hideProfession]="hideProfession"
    [profession]="
      participant.professions[0] && participant.professions[0].category
        ? participant.professions[0].profession
        : ''
    "
    [professionVerified]="
      participant.professions[0] &&
      participant.professions[0].verificationStatus === 'Verified'
    "
  >
  </app-celo-user-card>

  <ng-container *ngIf="!isSelf">
    <div
      [matMenuTriggerFor]="menu"
      class="menu-button"
      (click)="onParticipantClick($event)"
      [style.left.px]="menuX"
      [style.top.px]="menuY"
    ></div>
    <mat-menu #menu="matMenu" overlapTrigger="true">
      <div class="menu-title">
        {{ participant.firstName + " " + participant.lastName }}
      </div>

      <mat-divider></mat-divider>

      <button
        *ngIf="!isBlocked && (hasSharedWorkspaces || isConnected)"
        mat-menu-item
        (click)="onSecureMessage()"
      >
        Secure Message
      </button>
      <button mat-menu-item (click)="onViewProfileClick()">View Profile</button>
      <button
        *ngIf="isCurrentUserAdmin"
        mat-menu-item
        (click)="isAdmin ? onRemoveAdminClick() : onMakeAdminClick()"
      >
        {{ isAdmin ? "Remove as an Admin" : "Make Admin" }}
      </button>
      <button
        *ngIf="isCurrentUserAdmin"
        mat-menu-item
        (click)="onRemovePartipantClick()"
      >
        Remove from {{ type }}
      </button>
    </mat-menu>
  </ng-container>
</div>
