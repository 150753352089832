import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "toDisplayDate",
})
export class StringDateToDisplayFormatPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, args?: any): any {
    if (!value) {
      return null;
    }
    const date = new Date(value);
    const dateString = this.datePipe.transform(date, "mediumDate");
    return dateString;
  }
}
