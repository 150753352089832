import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { environment } from "@env";
import { SharedService, UserAccountService } from "@modules/core";
import { AlertService } from "app/modules/core/old/alert.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { RolesService } from "../roles.service";

export interface State {
  flag: string;
  name: string;
  population: string;
}
@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.scss"],
})
export class AdminDashboardComponent {
  userAccount: any;

  stateCtrl = new UntypedFormControl();
  filteredStates: Observable<State[]>;
  memberCtrl = new UntypedFormControl();
  filteredMembers: Observable<any[]>;
  members: any[] = [];
  groups = [];
  refreshCount = 0;

  list: any[];
  query: string;
  loading: boolean;
  invite_success: boolean;
  success: any[];
  failed: any[];
  workspace: any;

  constructor(
    private userAccountService: UserAccountService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private rolesService: RolesService
  ) {
    const instance = this;
    this.userAccountService.getUserAccount(true, function (acc) {
      instance.userAccount = acc;
      if (instance.userAccount) {
        instance.getWorkspace();
        instance.getDepartmentMembersList();
      }
    });

    this.filteredMembers = this.memberCtrl.valueChanges.pipe(
      startWith(""),
      map((member) =>
        member ? this._filterMembers(member) : this.members.slice()
      )
    );
  }

  getWorkspace() {
    if (this.userAccount.workplaces) {
      this.workspace = this.userAccount.workplaces[0];
    }
  }

  getDepartmentMembersList() {
    const i = this;
    if (!this.workspace || !this.workspace.departmentId) {
      this.list = [];
      this.loading = false;
      return;
    }
    const path =
      environment.celoApiEndpoint +
      "/api/Companies/{companyId}/Contacts".replace(
        "{" + "companyId" + "}",
        String(this.workspace.companyId)
      );
    const body = {
      Department: this.workspace.departmentName,
      Page: 0,
      PageSize: 1000,
    };
    this.sharedService.getObjectById(path, body).subscribe(
      (data) => {
        this.sharedService.sortArrayByField(data.data, "firstName");
        this.formatByField(data.data, "isOnCall");
        i.list = data.data;
        i.loading = false;
      },
      (err) => {
        i.loading = false;
      }
    );
  }

  formatByField(array, fieldName) {
    let pivot = 0;
    for (let index = 0; index < array.length; index++) {
      const contact = array[index];
      if (contact[fieldName] == true || contact[fieldName] == "true") {
        array.splice(index, 1);
        array.splice(pivot++, 0, contact);
      }
    }
  }

  membersToAdd(members: any[]) {
    this.success = [];
    this.failed = [];

    if (members && members.length > 0) {
      const instance = this;

      let names = "";
      names = members
        .map(function (elem) {
          return elem.firstName + " " + elem.lastName;
        })
        .join(", ");

      names = names ? names : "selected members";

      const body =
        "Are you sure you want to add " + names + " to this department?";
      this.alertService
        .confirm("Confirm", body, "Add", "Cancel")
        .subscribe((data) => {
          if (!data) {
            return;
          }
          this.addMembers(members, 0, function () {
            instance.getDepartmentMembersList();
            let title = "";
            let body = "";
            title = instance.failed.length > 0 ? "Failed" : "Success";
            body +=
              instance.success.length > 0
                ? "<div>Successfully added " +
                  instance.success.join(", ") +
                  " to " +
                  instance.workspace.departmentName +
                  " department. </div>"
                : "";
            body +=
              instance.failed.length > 0
                ? "<div>Failed to add " + instance.failed.join(", ") + ".</div>"
                : "";
            instance.alertService.confirm(title, body, "Ok", "", true);
          });
        });
    }
  }

  addMembers(members: any[], index, callback?) {
    const instance = this;
    this.addMemberToDepartment(members[index], function (respones) {
      if (respones) {
        instance.success.push(
          members[index].firstName + " " + members[index].lastName
        );
      } else {
        instance.failed.push(
          members[index].firstName + " " + members[index].lastName
        );
      }
      index++;
      if (members[index]) {
        instance.addMembers(members, index, callback);
      } else {
        if (callback) {
          callback();
        } else {
          return;
        }
      }
    });
  }

  addMemberToDepartment(member: any, callback) {
    if (!member) {
      // return;
      callback(false);
      return;
    }
    if (this.alreadyExist(member)) {
      callback(false);
      return;
      // return;
    }
    // this.loading = true;
    const operations = [];
    const patch = {
      op: "replace",
      path: "/departmentId",
      value: this.workspace.departmentId,
    };
    operations.push(patch);
    const instance = this;

    const path =
      environment.celoApiEndpoint +
      "/api/Admin/Companies/{companyId}/Users/{userId}"
        // const path = environment.celoApiEndpoint + '/api/Admin/Users/{userId}/Workspace/{companyId}'
        .replace("{" + "companyId" + "}", String(this.workspace.companyId))
        .replace("{" + "userId" + "}", String(member.userId));

    instance.editMemberPatch(member.userId, operations, path, function (resp) {
      if (resp) {
        // return true;
        callback(true);
        return;
      } else {
        // return false;
        callback(false);
        return;
      }
    });
  }

  alreadyExist(member: any) {
    for (const item of this.list) {
      if (item.userId == member.userId) {
        return true;
      }
    }
    return false;
  }

  removeMemberFromDepartment(member: any) {
    if (!member) {
      return;
    }
    const instance = this;
    this.alertService
      .confirm(
        "Are you sure you want to remove " +
          member.firstName +
          " " +
          member.lastName +
          " from this department?",
        "You can add this user again to this department later.",
        "Remove User",
        "Cancel"
      )
      .subscribe((data) => {
        if (!data) {
          return;
        }
        // instance.loading = true;
        const operations = [];
        const patch = {
          op: "add",
          path: "/departmentId",
          value: "",
        };
        operations.push(patch);
        // patch = {
        //     op:'replace',
        //     path:'/department',
        //     value:null
        // }
        // operations.push(patch);

        const path =
          environment.celoApiEndpoint +
          "/api/Admin/Companies/{companyId}/Users/{userId}"
            // const path = environment.celoApiEndpoint + '/api/Admin/Users/{userId}/Workspace/{companyId}'
            .replace("{" + "companyId" + "}", String(this.workspace.companyId))
            .replace("{" + "userId" + "}", String(member.userId));

        instance.editMemberPatch(
          member.userId,
          operations,
          path,
          function (resp) {
            if (resp) {
              instance.alertService.showSnackBar(
                "Successfully removed " +
                  member.firstName +
                  " " +
                  member.lastName,
                2
              );
            } else {
            }
            instance.getDepartmentMembersList();
          }
        );
      });
  }

  toggleOnCall(member: any) {
    // this.refreshCount++;

    let currentStatus = false;
    if (!member) {
      return;
    }
    if (member) {
      currentStatus = member.isOnCall == "true" ? true : false;
    }
    const instance = this;
    this.alertService
      .confirm(
        "Are you sure you want to change the On Call status of " +
          member.firstName +
          " " +
          member.lastName +
          " ?",
        "",
        currentStatus ? "Turn Off" : "Turn On",
        "Cancel"
      )
      .subscribe((data) => {
        if (!data) {
          return;
        }
        // instance.loading = true;

        const updatedStatus = currentStatus ? "false" : "true";
        const operations = [];
        const patch = {
          op: "replace",
          path: "/isOnCall",
          value: updatedStatus,
        };
        operations.push(patch);
        const path =
          environment.celoApiEndpoint +
          "/api/Admin/Companies/{companyId}/Users/{userId}"
            .replace("{" + "companyId" + "}", String(this.workspace.companyId))
            .replace("{" + "userId" + "}", String(member.userId));

        instance.editMemberPatch(
          member.userId,
          operations,
          path,
          function (resp) {
            instance.getDepartmentMembersList();
            if (resp) {
            } else {
            }
          }
        );
        instance.refreshCount++;
      });
  }

  private _filterMembers(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.members.filter(
      (member) => member.firstName.toLowerCase().indexOf(filterValue) === 0
    );
  }

  editMemberPatch(user_id: string, patch, path, callback) {
    const instance = this;

    this.rolesService.adminUserPatch(
      this.workspace.companyId,
      user_id,
      patch,
      path,
      function (data) {
        if (data) {
          callback(data);
          instance.refreshCount++;
          return;
        }
        instance.loading = false;
        callback(false);
      }
    );
  }

  close(result) {
    this.invite_success = result;
  }
}
