import { InvitationModel } from "./../../../../types/api-v2";
import { InvitationsService } from "./../services/invitations.service";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { LinkActionComponent } from "../components/link-action/link-action.component";
import { AlertService } from "./alert.service";
import { AnalyticsService } from "./analytics.service";
import { SharedService } from "./shared.service";
import { UserAccountService } from "./user-account.service";
import { InvitationType } from "types";
import { isHttpErrorResponse, isCeloErrorResponse } from "@utils";
import { CompanyErrorComponent } from "app/company-error/company-error.component";
import { getErrorDescriptor } from "utils/error-utils";

/** @deprecated */
@Injectable({
  providedIn: "root",
})
export class LinkService {
  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
    private sharedService: SharedService,
    private analyticsService: AnalyticsService,
    private userAccountService: UserAccountService,
    private invitationsService: InvitationsService,
    private matDialog: MatDialog
  ) {}

  actionLinkDialog(
    code: string,
    invitationObj: InvitationModel,
    callback?: Function
  ) {
    const data = {
      code,
      invitationObj,
    };
    let eventType = "";
    switch (invitationObj.type) {
      case "WorkspaceJoin":
        eventType = "workspace";
        break;
      case "Group":
        eventType = "group";
        break;
      case "Case":
        eventType = "case";
        break;
      case "User":
        eventType = "user";
        break;

      default:
        break;
    }
    const config = new MatDialogConfig();
    config.panelClass = "link-action-dialog";
    config.data = data;
    const dialogRef = this.dialog.open(LinkActionComponent, config);
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.errored) {
        this.analyticsService.raiseEvent("link_actioned", {
          link_type: eventType,
          success: "false",
        });
        let payload: string | any[] | undefined = [];
        if (result.data?.error?.errors[0]?.code == "400312") {
          switch (result.resultType) {
            case "Group":
              payload = [
                {
                  type: "celoSnackbar",
                  title: invitationObj?.metadata?.name,
                  icon: "avatar-group",
                  text: "This group has reached the maximum number of participants. Please contact the group admin to join.",
                },
              ];
              break;
            case "Case":
              payload = [
                {
                  type: "celoSnackbar",
                  title: invitationObj?.metadata?.name,
                  icon: "avatar-patient",
                  text: "This case has reached the maximum number of participants. Please contact the case admin to join.",
                },
              ];
              break;
          }
          this.alertService.customDialog(
            "",
            "",
            "",
            "",
            false,
            "vertical",
            payload
          );
          return;
        }
        this.alertService.showSnackBar(
          "Something went wrong. Please try again.",
          4
        );
        return;
      }
      if (result && result.resultType) {
        this.analyticsService.raiseEvent("link_actioned", {
          link_type: eventType,
          success: "true",
        });
        this.userAccountService.getUserAccount(true);
        if (callback) {
          callback(result);
        }
      }
    });
  }

  private getInvitationCode(link: string): string | null {
    const url = new URL(link);
    const invitationCode = url.searchParams.get("code");
    return invitationCode;
  }

  isPotentialInvitationLink(link: string) {
    const invitationCode = this.getInvitationCode(link);
    return !!invitationCode;
  }

  handleLink(link: string) {
    const invitationCode = this.getInvitationCode(link);
    if (!invitationCode) return;
    this.handleInviteCode(invitationCode, true);
  }

  handleInviteCode(code: string, fireGetEvent?: boolean) {
    this.sharedService.removeInviteCode();
    this.invitationsService.getInvitation(code).subscribe({
      next: (invitation) => {
        let linkGetEventType: string;
        switch (invitation?.type) {
          case InvitationType.UserSpecificWorkspaceJoin:
            this.handleUserSpecificWorkspaceInvite(invitation);
            linkGetEventType = "workspace";
            break;
          case InvitationType.WorkspaceJoin:
            this.handleWorkspaceInvite(invitation, code);
            linkGetEventType = "workspace";
            break;
          case InvitationType.Group:
            this.handleConversationInvite(invitation, code);
            linkGetEventType = "group";
            break;
          case InvitationType.Case:
            this.handleConversationInvite(invitation, code);
            linkGetEventType = "case";
            break;
          case InvitationType.User:
            this.handleProfileInvite(invitation, code);
            linkGetEventType = "user";
            break;
          default:
            throw new Error(`Unhandled invitation type ${invitation.type}`);
        }

        if (fireGetEvent) {
          this.analyticsService.raiseEvent("link_get", {
            link_type: linkGetEventType,
            isActioned: `${invitation.isActioned}`,
          });
        }
      },
      error: (err) => {
        let payload = [
          {
            type: "celoSnackbar",
            icon: "",
            text: "This link is invalid. Please ensure you have the correct link and try again.",
          },
        ];
        this.alertService.customDialog(
          "",
          "",
          "",
          "",
          false,
          "vertical",
          payload
        );
      },
    });
  }

  handleWorkspaceInvite(invitation: InvitationModel, code: any) {
    let instance = this;
    if (!invitation.isActive) {
      let payload = [
        {
          type: "celoSnackbar",
          icon: "avatar-workspace",
          text: "The invite link for this workspace has been reset. Please ensure you have the latest invite link to join the workspace.",
        },
      ];
      instance.alertService.customDialog(
        "",
        "",
        "",
        "",
        false,
        "vertical",
        payload
      );
      return;
    }
    return instance.actionLinkDialog(
      code,
      invitation,
      function (result: { resultType: string }) {
        if (result && result.resultType) {
          if (result.resultType == "WorkspaceJoin") {
            // this will ensure that the correct success screen is desplayed
            instance.handleInviteCode(code);
          }
        }
      }
    );
  }

  handleUserSpecificWorkspaceInvite(invitation: InvitationModel) {
    this.invitationsService.openActionInvitationDialog(invitation).subscribe({
      next: (actionedInvitation) => {
        if (
          actionedInvitation.actionResponse?.type !==
          InvitationType.UserSpecificWorkspaceJoin
        )
          return;

        if (!invitation?.id) throw new Error("Invalid invitation id");

        this.actionLinkDialog(invitation.id, invitation, () => {});
      },
      error: (err) => {
        if (isHttpErrorResponse(err) && isCeloErrorResponse(err.error)) {
          const descriptor = getErrorDescriptor(err.error);
          CompanyErrorComponent.openDialog(this.matDialog, {
            title: invitation.metadata.name,
            content: descriptor.message,
          });
          return;
        }
      },
    });
  }

  handleProfileInvite(invitation: InvitationModel, code: any) {
    let instance = this;
    if (!invitation.isActive) {
      let payload = [
        {
          type: "celoSnackbar",
          icon: "",
          text: "This link is invalid. Please ensure you have the correct link and try again.",
        },
      ];
      instance.alertService.customDialog(
        "",
        "",
        "",
        "",
        false,
        "vertical",
        payload
      );
      return;
    }
    return instance.actionLinkDialog(
      code,
      invitation,
      function (result: { resultType: string }) {
        if (result && result.resultType) {
          if (result.resultType == "WorkspaceJoin") {
            // this will ensure that the correct success screen is desplayed
            instance.handleInviteCode(code);
          }
        }
      }
    );
  }

  handleConversationInvite(invitation: InvitationModel, code: string) {
    if (!invitation.type) throw new Error("Invalid invitation type");
    let instance = this;
    let icon = "avatar-group";
    switch (invitation.type) {
      case "Group":
        icon = "avatar-group";
        break;
      case "Case":
        icon = "avatar-patient";
        break;
    }
    if (!invitation.isActive) {
      let payload = [
        {
          type: "celoSnackbar",
          icon: icon,
          text:
            "The invite link for this " +
            invitation.type.toLocaleLowerCase() +
            " has been reset. Please ensure you have the latest invite link to join the " +
            invitation.type.toLocaleLowerCase() +
            ".",
        },
      ];
      instance.alertService.customDialog(
        "",
        "",
        "",
        "",
        false,
        "vertical",
        payload
      );
      return;
    }
    return instance.actionLinkDialog(
      code,
      invitation,
      function (result: { resultType: string }) {
        if (result && result.resultType) {
          // instance.handleInviteCode(code);
          if (result.resultType == "WorkspaceJoin") {
            // this will ensure that the correct success screen is desplayed
            instance.handleInviteCode(code);
          } else if (
            result.resultType == "Group" ||
            result.resultType == "Case"
          ) {
            // this will ensure that the correct success screen is desplayed
            instance.handleInviteCode(code);
          }
        }
      }
    );
  }
}
