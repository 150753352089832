import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { UserPickerService } from "@modules/user-picker/user-picker.service";
import { ConService, MessageService } from "../../core";

@Component({
  selector: "app-conversations-header",
  templateUrl: "./conversations-header.component.html",
  styleUrls: ["./conversations-header.component.scss"],
})
export class ConversationsHeaderComponent implements OnInit, OnDestroy {
  @Input() public isOnCall: any;
  @Input() public heading: string = "Messages";
  @Input() public searchPlaceholder: string =
    "Search by Name, Groups, Cases & Patient ID";

  @Input() public isCreateChatEnabled: boolean = true;

  @Output() query = new EventEmitter<string>();

  searchTerm = "";
  isSavingOnCall = false;
  connectionStatus$ = null;
  messageSent$: any;

  constructor(
    private conService: ConService,
    private messageService: MessageService,
    private userPickerService: UserPickerService
  ) {}

  ngOnInit() {
    this.connectionStatus$ = this.conService.connectionStatus$;
    this.messageSent$ = this.messageService.onMessageSent.subscribe(() => {
      this.searchTerm = "";
      this.searchShouldExit(this.searchTerm);
    });
  }

  ngOnDestroy() {
    if (this.messageSent$) {
      this.messageSent$.unsubscribe();
    }
  }

  searchNow(searchTerm) {
    this.query.emit(searchTerm);
  }

  searchShouldExit(searchTerm) {
    if (!searchTerm || searchTerm.length < 3) {
      this.query.emit("");
    }
  }

  newChat() {
    this.userPickerService.openNetworkUserPicker({
      subheader: "My Network",
      searchPlaceholder: "Search network",
      selectedHeader: "Participants",
      selectedQuantityLabels: {
        zero: "participants",
        one: "participant",
        plural: "participants",
      },
    });
  }

  reconnect() {
    this.conService.userTriggeredReconnectSignalR();
  }
}
