<div class="content">
  <div class="flex">
    <mat-form-field class="filter align-self-center">
      <input
        matInput
        (keyup)="applyFilter()"
        placeholder="Search"
        [(ngModel)]="query"
      />
    </mat-form-field>
    <div class="end align-self-center">
      <span class="m-1">
        <button mat-button class="celo-primary-button" (click)="pickUsers()">
          <i class="material-icons icon-m">add</i>
          <span>&nbsp;Add users</span>
        </button>
      </span>
    </div>
  </div>
  <div *ngIf="dataSource && dataSource.data && !loading">
    <div class="table-shadow">
      <div *ngIf="dataSource.data.length > 0" class="my-table">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- ID Column -->
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let row" class="normal-font">
              <app-avatar-with-name
                [title]="row.title"
                [firstName]="row.firstName"
                [lastName]="row.lastName"
                [description]="row.position ?? 'No Position'"
                [userId]="row.userId"
                [profilePic]="row.profilePic"
                [refreshCount]="refreshCount"
                [userAccount]="userAccount"
              >
              </app-avatar-with-name>
              <div></div>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="isOnCall" [stickyEnd]="true">
            <th mat-header-cell *matHeaderCellDef class="oncall-header">
              On Call Status
            </th>
            <td mat-cell *matCellDef="let row">
              <div class="oncall">
                <div>
                  <mat-slide-toggle
                    hideIcon
                    [checked]="row.isOnCall === 'true'"
                    [color]="'primary'"
                    (click)="toggleOnCall(row); $event.preventDefault()"
                  ></mat-slide-toggle>
                </div>
              </div>
            </td>
          </ng-container>

          <!-- Progress Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <div class="flex">
                <button class="center" mat-button (click)="remove(row)">
                  <span class="font-grey">Remove</span>
                </button>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="table-row"
          ></tr>
        </table>
      </div>
      <div *ngIf="dataSource.data.length === 0" class="p-5">
        <app-empty-state
          message="This list is empty"
          [isEmpty]="true"
          class="fh"
        ></app-empty-state>
      </div>
    </div>
  </div>
  <div *ngIf="!dataSource || !dataSource.data || loading" class="loading-box">
    <app-empty-state
      message="LOADING"
      [isLoading]="true"
      class="fh"
      [diameter]="30"
    ></app-empty-state>
  </div>
</div>
