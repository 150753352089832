import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ConversationParticipantModelV2, ParticipantRole } from "@types";
import { ConversationParticipantPopupMenuAction } from "../conversation-participant-popup-menu/conversation-participant-popup-menu.component";

@Component({
  selector: "app-conversation-participants-list",
  templateUrl: "./conversation-participants-list.component.html",
  styleUrls: ["./conversation-participants-list.component.scss"],
})
export class ConversationParticipantsListComponent implements OnChanges {
  @Input() public participants: ConversationParticipantModelV2[] = [];
  @Input() public removeParticipantText: string | null = null;
  @Input() public currentUserId: string | null = null;

  @Output() public action =
    new EventEmitter<ConversationParticipantPopupMenuAction>();

  public isCurrentUserAdmin: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["currentUserId"] || changes["participants"]) {
      const currentUserParticipant = this.participants.find(
        (p) => p.userId === this.currentUserId
      );
      this.isCurrentUserAdmin =
        currentUserParticipant?.role === ParticipantRole.Administrator ?? false;
    }
  }
}
