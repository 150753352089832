<div
  *ngIf="conversations && conversations.length && userAccount"
  class="appear_1"
  (click)="isOnline() ? '' : sharedService.noInternetSnackbar()"
>
  <div
    *ngFor="let convo of conversations; trackBy: conversationHasher"
    [ngClass]="{ 'no-pointer-events': !isOnline() }"
  >
    <a
      [routerLink]="getRouterLink(convo)"
      #rla="routerLinkActive"
      routerLinkActive="active"
    >
      <app-conversation-card
        [userAccount]="userAccount"
        [selected]="rla.isActive"
        [inConversationList]="true"
        [refreshCount]="convo.refreshCount"
        (muteStatus)="muteStatusChange($event, convo)"
        [convo]="convo"
        [isRolesSidenav]="isRolesSidenav"
        [disableConversationActions]="disableConversationActions"
      ></app-conversation-card>
    </a>
  </div>
  <div style="padding-left: 1em; padding-bottom: 1em">
    <div
      *ngIf="conversations.length > 0 && canLoadMore && !loading"
      style="padding-top: 1em"
    >
      <div style="padding-top: 1em">
        <button
          class="fw button-hover"
          mat-button
          (click)="loadMore.emit($event)"
        >
          Load More
        </button>
      </div>
    </div>
    <div *ngIf="loading" class="spinner-box">
      <div class="spinner">
        <mat-spinner diameter="20" strokeWidth="2"></mat-spinner>
      </div>
    </div>
  </div>
</div>
