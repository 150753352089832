import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "@env";
import {
  ConversationPickerDialogComponent,
  ConversationPickerDialogData,
} from "@modules/conversations/conversation-picker-dialog/conversation-picker-dialog.component";
import { ZipFilesService } from "@modules/shared/zip-files.service";
import { CreateMessagePatientFileModel, PatientFileDetailsModel } from "@types";
import { AlertService } from "app/modules/core/old/alert.service";
import { UrlHelperService } from "app/modules/shared/auth.img.service";
import { Observable, Subject } from "rxjs";

export interface ShareDocumentsOptions {
  fromConversationId?: string;
  fromMessageId?: string;
}

@Injectable({
  providedIn: "root",
})
export class DocumentsService {
  downloadDocs(documents) {
    this.zipFilesService.downloadZip(
      documents.map((document) => {
        const fileName = this.zipFilesService.createFileNameFromFileObject(
          document.fileName,
          "pdf",
          document.patientData
        );
        return {
          url: `${environment.celoApiEndpoint}/api/PatientFiles/${document.id}`,
          fileName,
        };
      })
    );
  }

  constructor(
    private urlHelperService: UrlHelperService,
    private dialog: MatDialog,
    private http: HttpClient,
    private router: Router,
    private alertService: AlertService,
    private zipFilesService: ZipFilesService,
  ) {}

  public download(id: any) {
    const url = `${environment.celoApiEndpoint}/api/PatientFiles/${id}`;
    this.urlHelperService.get(url).subscribe((m) => {
      window.open(m);
    });
  }

  getUniquePatientIds(documents: PatientFileDetailsModel[]): string[] {
    const ids = [];
    for (const doc of documents) {
      if (
        doc.patientData &&
        doc.patientData.uid &&
        ids.indexOf(doc.patientData.uid) === -1
      ) {
        ids.push(doc.patientData.uid);
      }
    }
    return ids;
  }

  openCreateConvoWithDocuments(documents: any[]) {
    const qp = { isDocShare: true };
    let idx = 0;
    for (const doc of documents) {
      qp[`doc${idx}`] = doc.id;
      idx += 1;
    }
    this.router.navigate(["/conversations/new"], { queryParams: qp });
  }

  public share(
    documents: PatientFileDetailsModel[],
    options?: ShareDocumentsOptions | null
  ): Observable<boolean> {
    const patientIds = this.getUniquePatientIds(documents);
    const doneSubject = new Subject<boolean>();
    const data: ConversationPickerDialogData = {
      patientUids: patientIds,
      attachments: documents,
      attachmentType: "DOCUMENT",
    };

    if (patientIds.length === 1) {
      data.patientUid = patientIds[0];
    }

    ConversationPickerDialogComponent.openDialog(this.dialog, data)
      .afterClosed()
      .subscribe({
        next: (result) => {
          if (!result) return;
          if (result.newConvo) {
            this.openCreateConvoWithDocuments(documents);
            doneSubject.next(true);
          } else if (result.conversationId) {
            const { fromConversationId, fromMessageId } = options;
            const messages = documents.map((d) => {
              const createModel: CreateMessagePatientFileModel = {
                fileId: d.id,
                content: "",
                marker: this.uuidv4(),
              };

              if (fromConversationId) {
                createModel.fromConversationId = fromConversationId;
              }

              if (fromMessageId) {
                createModel.fromMessageId = fromMessageId;
              }

              return createModel;
            });
            const path = `${environment.celoApiEndpoint}/api/Conversations/${result.conversationId}/SendPatientFile`;

            return this.http
              .post(path, messages, { responseType: "text" })
              .subscribe(
                (resp) => {
                  this.alertService.alert(
                    "Success",
                    "Document successfully shared",
                    true
                  );
                  doneSubject.next(true);
                },
                (err) => {
                  this.alertService.alert(
                    "Error",
                    "Error sharing file to conversation",
                    true
                  );
                }
              );
          }
        },
      });

    return doneSubject.asObservable();
  }

  updateDocumentPut(id: string, data: any, callback?) {
    const editor_callback = data.callback;
    const updatePatientDocumentModel: any = {
      patientData: data.patientData,
    };
    updatePatientDocumentModel["fileDescription"] = data.fileDescription;
    if (data.fileNameAlias) {
      updatePatientDocumentModel["fileNameAlias"] = data.fileNameAlias;
    }
    const path = `${environment.celoApiEndpoint}/api/PatientFiles/${id}`;

    this.http.put(path, updatePatientDocumentModel).subscribe(
      (resp) => {
        if (editor_callback) {
          editor_callback(true);
        }
        callback(resp);
        // this.alertService.showSnackBar('Patient details updated',3);
      },
      (err) => {
        let errorMessage = "";
        if (err.status == 400) {
          const errBody = err.error;
          if (errBody.uid) {
            errorMessage += " " + errBody.uid[0];
          }
          if (errBody.dateOfBirth) {
            errorMessage += " " + errBody.dateOfBirth[0];
          }
        }

        this.alertService.confirm("", errorMessage, "Ok", "");

        if (editor_callback) {
          editor_callback(false);
        }
        callback(false);
        // this.alertService.alert('Sorry','The given information cannot be saved !');
      }
    );
  }

  public deleteDocuments(documents, callback) {
    this.alertService
      .confirm(
        "Confirm",
        "Are you sure you would like to remove this document from your Celo Library?",
        "Remove Document",
        "No. I will decide later"
      )
      .subscribe((result) => {
        if (result) {
          for (const document of documents) {
            const url = `${environment.celoApiEndpoint}/api/PatientFiles/${document.id}`;
            this.http.delete(url).subscribe(
              (data) => {
                callback(true);
              },
              (error) => {
                this.alertService.alert("Error", "Error delete file", true);
              }
            );
          }
        }
      });
  }

  uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
