import { CdkTextareaAutosize } from "@angular/cdk/text-field";
import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AlertComponent } from "@modules/core/components";
import { take } from "rxjs/operators";
import { PatchTeamRequestOptions, Team } from "types";
import { isNotWhitespaceValidator } from "validators";
import { TeamsService } from "../../core/services/teams.service";
import { RoleDetailsReadMoreDialogComponent } from "../role-details-read-more-dialog/role-details-read-more-dialog.component";
import { SnackbarService } from "./../../core/services/snackbar.service";

export interface RoleDetailsFormSaveState {
  name: string;
  description: string;
}

@Component({
  selector: "app-role-details-form",
  templateUrl: "./role-details-form.component.html",
  styleUrls: ["./role-details-form.component.scss"],
})
export class RoleDetailsFormComponent
  implements OnInit, OnChanges, AfterContentChecked
{
  @Input() public teamId: string | null = null;
  @Input() public readonly: boolean = false;
  @Input() public hideName: boolean = false;
  @Input() public hideDescriptionHeader: boolean = false;

  private _name: string | null = null;
  private _description: string | null = null;

  @Input() public set name(value: string | null) {
    this._name = value ?? "";
    this.nameFormControl.reset(value ?? "");
  }

  public get name() {
    return this._name;
  }

  @Input() public set description(value: string | null) {
    this._description = value ?? "";
    this.descriptionFormControl.reset(value ?? "");
  }

  public get description() {
    return this._description;
  }

  public get isSaveable() {
    return this.formGroup.dirty && this.formGroup.valid && !this.isSaving;
  }

  @Output() public saved: EventEmitter<Team> = new EventEmitter();

  public nameFormControl: FormControl<string> = new FormControl<string>("", {
    nonNullable: true,
    validators: [Validators.required, isNotWhitespaceValidator],
  });

  public descriptionFormControl: FormControl<string> = new FormControl<string>(
    "",
    {
      nonNullable: true,
    }
  );

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: this.nameFormControl,
    description: this.descriptionFormControl,
  });

  public isSaving: boolean = false;
  public isDescriptionOverflowing: boolean = false;

  @ViewChild("autosize") private autosize: CdkTextareaAutosize | null = null;

  @ViewChild("descriptionRef")
  private descriptionElement: ElementRef<HTMLElement> | null = null;

  constructor(
    private teamsService: TeamsService,
    private snackbarService: SnackbarService,
    private ngZone: NgZone,
    private matDialog: MatDialog
  ) {}

  public ngAfterContentChecked(): void {
    this.updateReadMore();
  }

  private updateReadMore() {
    const elem = this.descriptionElement?.nativeElement;
    if (!elem) return;
    this.isDescriptionOverflowing = elem.offsetHeight < elem.scrollHeight;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.isSaving = false;
  }

  public ngOnInit(): void {
    this.nameFormControl.setValue(this.name ?? "");
    this.descriptionFormControl.setValue(this.description ?? "");
    this.triggerResize();
  }

  triggerResize() {
    this.ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize?.resizeToFitContent(true));
  }

  public reset() {
    this.resetControls();
  }

  public save() {
    if (!this.teamId) return;

    let options: PatchTeamRequestOptions = { teamId: this.teamId };

    if (this.nameFormControl.dirty) {
      options.name = this.nameFormControl.value?.trim();
    }

    if (this.descriptionFormControl.dirty) {
      options.description = this.descriptionFormControl.value?.trim();
    }

    this.isSaving = true;
    this.teamsService.patchTeam(options).subscribe({
      next: (team) => this.saved.emit(team),
      error: () => {
        AlertComponent.openErrorDialog(this.matDialog);
        this.isSaving = false;
      },
      complete: () => {
        this.snackbarService.show("Role updated sucessfully");
        this.isSaving = false;
      },
    });
  }

  private resetControls() {
    this.formGroup.reset({
      name: this.name,
      description: this.description,
    });
  }

  public readMore() {
    if (!this.description) return;
    RoleDetailsReadMoreDialogComponent.openDialog(this.matDialog, {
      description: this.description,
    });
  }
}
