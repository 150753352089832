<div class="celo-cursor-pointer" (click)="onClick()">
  <ng-container *ngIf="type !== 'TeamChat' || isRolesTab; else teamChatAvatar">
    <app-celo-avatar
      [width]="40"
      [height]="40"
      [type]="type"
      [participant]="participantToShow ? participantToShow : ''"
      [showBadge]="participantToShow?.isExternal ? false : true"
      [conversationPictureUri]="
        !!conversation.photoId && leftChat ? conversationPictureUri : null
      "
    ></app-celo-avatar>
  </ng-container>
  <ng-template #teamChatAvatar>
    <app-roles-icon></app-roles-icon>
  </ng-template>
</div>
<div class="subject-text-section" (click)="onClick()">
  <div class="main-title celo-elipsis">
    {{ name }}<span *ngIf="type === 'SelfChat'"> (You)</span>
  </div>

  <div class="sub-title">
    <div
      *ngIf="
        ((!canShowStatus || !userStatus) && !typing.length) ||
        (!allowedToShowLastOnline && !allowedToShowTyping)
      "
      class="appear_1"
    >
      {{ subtitle }}
    </div>
    <div
      *ngIf="
        type === 'Chat' &&
        canShowStatus &&
        userStatus &&
        allowedToShowLastOnline
      "
    >
      <div *ngIf="!typing.length" class="appear_1">
        <app-celo-user-status
          [lastActiveOnUtc]="userStatus?.updatedOnUtc"
        ></app-celo-user-status>
      </div>
    </div>
    <div
      *ngIf="typing.length > 0 && allowedToShowTyping"
      class="appear_1 typing_text"
    >
      <div *ngIf="typing.length === 1">
        <span *ngIf="type === 'Chat'">Typing...</span>
        <span *ngIf="type !== 'Chat'"
          >{{ typing[0].firstName }} is typing...</span
        >
      </div>
      <div *ngIf="typing.length === 2">
        {{ typing[0].firstName }} and {{ typing[1].firstName }} are typing...
      </div>
      <div *ngIf="typing.length === 3">
        {{ typing[0].firstName }}, {{ typing[1].firstName }} and
        {{ typing[2].firstName }} are typing...
      </div>
      <div *ngIf="typing.length > 3">
        {{ typing.length }} people are typing...
      </div>
    </div>
  </div>
</div>
<div class="icons">
  <div *ngIf="isOpened">
    <button mat-icon-button (click)="close()">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>
  <div *ngIf="!isOpened">
    <button
      mat-icon-button
      (click)="call()"
      *ngIf="(isStartVideoCallUiEnabled$ | async) && isCallAvailable"
    >
      <mat-icon svgIcon="video-on"></mat-icon>
    </button>
    <button mat-icon-button (click)="search()">
      <mat-icon>search</mat-icon>
    </button>
    <button mat-icon-button (click)="openInfo()">
      <mat-icon>info_outline</mat-icon>
    </button>
  </div>
</div>
