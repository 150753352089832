<div
  class="library"
  #library
  infiniteScroll
  [scrollWindow]="false"
  [infiniteScrollDistance]="2"
  [infiniteScrollUpDistance]="1.5"
  [infiniteScrollThrottle]="50"
  (scrolled)="onNewScrollPosition()"
>
  <div
    *ngIf="uploader.queue && uploader.queue.length === 0"
    class="library-container"
    ng2FileDrop
    [uploader]="uploader"
    (fileOver)="dropZoneState($event)"
    (onFileDrop)="handleDrop($event)"
    [ngClass]="{ valid: dropZoneActive === 'valid' }"
  >
    <div class="drop-highlight">
      <div>
        <i class="material-icons icon-xxl">cloud_upload</i>
        <div>UPLOAD</div>
      </div>
    </div>

    <div class="library-tools" #tools>
      <div class="search-bar">
        <div class="topbar-input">
          <app-celo-topbar-search-input
            [placeholder]="'Search by Patient ID or Patient Name'"
            (doSearch)="queryChange($event)"
          >
          </app-celo-topbar-search-input>
        </div>
      </div>

      <div class="drop-zone">
        <app-drop-zone
          [type]="'documents'"
          [formats]="['pdf']"
          [uploader]="uploader"
        ></app-drop-zone>
      </div>

      <div class="toolbar">
        <app-library-secondbar-toolbar
          (startSelect)="startSelect()"
          (cancelSelect)="cancelSelect()"
          [pageName]="'Documents'"
          [singleFileId]="
            getSelectedCount() === 1 && getFirstSelected()
              ? getFirstSelected().payload.id
              : ''
          "
          [singleFileName]="
            getFirstSelected()
              ? getFirstSelected().payload.fileName + '.pdf'
              : 'Patient File.pdf'
          "
          [singleFileType]="'document'"
          [selectedFileCount]="getSelectedCount()"
        >
        </app-library-secondbar-toolbar>
      </div>
    </div>

    <div
      class="library-list"
      [style.height.px]="library.offsetHeight - tools.offsetHeight"
    >
      <app-empty-state
        *ngIf="loading && (!documents || documents.length === 0)"
        [isLoading]="true"
        [message]="LOADING"
      >
      </app-empty-state>
      <div *ngIf="!loading && documents.length === 0">
        <app-empty-state
          *ngIf="!searchTerm"
          [title]="'Document Sharing'"
          [message]="
            'Safely share patient documents with colleagues and connections.'
          "
          [isEmpty]="true"
          [image]="'empty_states/empty-state-documents.svg'"
        ></app-empty-state>
        <app-empty-state
          *ngIf="searchTerm"
          [title]="'No results found'"
          [message]="
            'Try searching by Patient ID, Patient Name or Document Name.'
          "
          [isEmpty]="true"
          [image]="'no-results-found-directory-v2.svg'"
        ></app-empty-state>
      </div>

      <div *ngIf="documents.length > 0" >
        <div class="group">
          <div class="list row">
            <div
              #tile
              class="col-xs-12 col-sm-6 col-lg-4 col-xl-3 item"
              *ngFor="let document of documents"
              [style.height.px]="tile.offsetWidth"
            >
              <div class="grow-shadow card">
                <app-document
                  [libItem]="document"
                  (selected)="onSelected($event)"
                  [ownFile]="userId === document.payload.creator.userId"
                ></app-document>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="loading && !hideLoadMore">
          <app-empty-state [isLoading]="true" [message]="'Loading Documents'">
          </app-empty-state>
        </div>
        <div *ngIf="hideLoadMore" class="list-end">- That's all -</div>
      </div>
    </div>
  </div>
  <div *ngIf="uploader.queue && uploader.queue.length !== 0">
    <app-file-upload-preview
      (closeMe)="closeUpload()"
      [type]="'Documents'"
      [isInSecureLibrary]="true"
      [uploader]="uploader"
    >
    </app-file-upload-preview>
  </div>
</div>
