import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "environments/environment";
import { AlertService, AnalyticsService, SharedService } from "@modules/core";

@Component({
  selector: "app-email-confirm",
  templateUrl: "./email-confirm.component.html",
  styleUrls: [
    "./email-confirm.component.scss",
    "../onboard.scss",
    "../../../celo-input.scss",
  ],
})
export class EmailConfirmComponent implements OnInit {
  verification_id = "";
  code = "";
  loading: boolean;
  email: any;
  resendingCode: boolean;
  countryName: string;
  countryCode: string;
  countryIsAvailable: string;
  message: string;
  dataRegionId: string;

  constructor(
    private sharedService: SharedService,
    private alertService: AlertService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((paramMap) => {
      this.verification_id = paramMap.get("verification_id");
      this.email = paramMap.get("email");
      this.countryName = paramMap.get("country_name");
      this.countryCode = paramMap.get("country_code");
      this.countryIsAvailable = paramMap.get("country_is_available");
      this.dataRegionId = paramMap.get("data_region_id");
    });

    this.analyticsService.raiseEvent("celo_page_view", {
      flow: "signing_up",
      page_name: "email_code_page",
    });
  }

  resendCode() {
    this.resendingCode = true;
    const instance = this;

    this.sharedService.sendOnboardEmail(
      this.email,
      this.dataRegionId,
      true,
      function (verification_id) {
        if (verification_id) {
          instance.verification_id = verification_id;
        }
        instance.resendingCode = false;
        instance.loading = false;
        instance.message = "";
      }
    );
    this.analyticsService.buttonClickEvent("resend_email_code_link", {
      flow: "signing_up",
    });
  }

  verifyEmail() {
    if (!this.sharedService.isOnline()) {
      this.sharedService.offlineGenericErrorMessageSnackbar();
      return;
    }
    this.loading = true;
    const body = {
      email: this.email,
      code: this.code,
      verificationId: this.verification_id,
    };

    const path =
      environment.celoApiEndpoint + "/api/Onboard/EmailVerifyCode/check";
    this.sharedService.postObjectById(path, {}, body).subscribe(
      (response) => {
        this.analyticsService.raiseEvent("email_code_submitted", {
          flow: "signing_up",
          success: "true",
        });

        const token = response["token"];
        this.loading = false;
        this.sharedService.navigateTo("/onboard/profile-create", {
          verification_id: this.verification_id,
          email: this.email,
          country_code: this.countryCode,
          country_name: this.countryName,
          country_is_available: this.countryIsAvailable,
          data_region_id: this.dataRegionId,
          token,
        });
      },
      (err) => {
        this.analyticsService.raiseEvent("email_code_submitted", {
          flow: "signing_up",
          success: "false",
        });

        this.loading = false;
        const code = this.sharedService.getErrorCode(err);
        if (code == "400109" || code == "400105") {
          this.alertService
            .confirm(
              "You've entered the code too many times",
              "Please tap on “Resend Code” to get a new code. Need help? Contact us at support@celohealth.com",
              "RESEND CODE",
              "CANCEL",
              false
            )
            .subscribe((result) => {
              if (result) {
                this.resendCode();
              }
            });
          return;
        } else if (code == "400104") {
          this.alertService
            .confirm(
              "It looks like you stayed on this screen for too long.",
              "Tap on 'Resend Code' to continue.",
              "RESEND CODE",
              "CANCEL",
              false
            )
            .subscribe((result) => {
              if (result) {
                this.resendCode();
              }
            });
          return;
        }
        this.message = this.sharedService.STANDARD_ERROR_MESSAGE;
        this.message = this.sharedService.getErrorMessageFromError(
          err,
          this.message
        );
      }
    );
  }

  contactSupportEvent() {
    this.analyticsService.buttonClickEvent("email_support_link", {
      flow: "signing_up",
    });
  }
}
