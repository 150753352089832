<div>
  <div class="font-11 font-grey time capitalize">
    {{ message.sentOnUtc | dateFormat: "relativeDay" }}
  </div>
  <div class="content">
    <div class="icon">
      <app-avatar
        class="avatar-container"
        [name]="sender.firstName + ' ' + sender.lastName"
        [userId]="sender.userId"
        [width]="40"
        [height]="40"
        [hasImage]="!!sender.profilePicture"
      >
      </app-avatar>
    </div>
    <div class="texts">
      <div class="name">
        <span *ngIf="message.sentBy && sender && currentUser">
          {{
            currentUser.userId === message.sentBy
              ? "You"
              : sender.firstName + " " + sender.lastName
          }}
        </span>
      </div>
      <div class="message">
        <div
          innerHTML="{{ message.content | searchHighlight: highlightString }}"
          [class.video-call]="message.type === 'VideoCall'"
        ></div>
        <div
          *ngIf="
            message.type === 'VideoCall' &&
            message.metadata.resourceStatus === 'Ended'
          "
          [class.video-call]="message.type === 'VideoCall'"
        >
          {{ getDurationText(message.metadata.resourceCallDurationInSeconds) }}
        </div>
        <div
          *ngIf="
            message.type === 'VideoCall' &&
            message.metadata.resourceStatus === 'InProgress'
          "
        >
          Tap to join
        </div>
      </div>
    </div>
  </div>
</div>
