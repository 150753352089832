export interface EditHistoryActionResult<T = string> {
    /**
     * `true` if the latest value has changed as a result of the corresponding action, otherwise `false`.
     */
    isChanged: boolean;
    previousState: T | null;
    currentState: T | null;
}

export class EditHistory<T = string> {
    private undoStack: T[] = [];
    private redoStack: T[] = [];

    public get latestState(): T | null {
        return this.undoStack.at(-1) ?? null;
    }

    public undo(): EditHistoryActionResult<T> {
        return this.swap(this.undoStack, this.redoStack);
    }

    public redo(): EditHistoryActionResult<T> {
        return this.swap(this.redoStack, this.undoStack);
    }

    private swap(from: T[], to: T[]): EditHistoryActionResult<T> {
        const previousState = this.latestState;
        const state = from.pop();

        if (state !== undefined) {
            to.push(state);
        }

        return {
            isChanged: state !== undefined,
            currentState: this.latestState,
            previousState: previousState
        };
    }

    public add(value: T): void {
        this.undoStack.push(value);
        this.redoStack = [];
    }
}
