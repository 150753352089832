import { Component, Host, HostBinding, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { SubscriptionContainer } from "@utils";

@Component({
  selector: "app-library",
  templateUrl: "./library.component.html",
  styleUrls: ["./library.component.scss"],
})
export class LibraryComponent implements OnDestroy {
  @HostBinding("class.celo-grid") celoGrid: Host = true;
  isPhotosPage = false;
  isConsentsPage = false;
  isDocumentsPage = false;

  private subscriptions = new SubscriptionContainer();

  constructor(private router: Router) {
    const routeSubscription = this.router.events.subscribe({
      next: (evt) => {
        if (!(evt instanceof NavigationEnd)) return;
        this.isPhotosPage = false;
        this.isConsentsPage = false;
        this.isDocumentsPage = false;
        if (evt.url === "/secure/photos" || evt.url === "/secure") {
          this.isPhotosPage = true;
        } else if (evt.url === "/secure/consents") {
          this.isConsentsPage = true;
        } else if (evt.url === "/secure/documents") {
          this.isDocumentsPage = true;
        }
      },
    });

    this.subscriptions.add(routeSubscription);
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
