<div class="container-fluid login-container h-100">
  <div class="row h-100">
    <div
      class="col-xl-4 col-lg-2 align-self-end d-flex justify-content-end"
    ></div>
    <div class="col-xl-4 col-lg-8 my-auto justify-content-center">
      <div class="logo-container">
        <img src="/assets/logo-v2.svg" />
      </div>
      <div class="m-2">
        <div class="title">Welcome to Celo!</div>
        <div class="subtitle">Please create a new password.</div>
      </div>

      <div class="form">
        <div class="celo-input-outline">
          <mat-form-field class="fw" appearance="outline">
            <mat-label>Your temporary password</mat-label>
            <input
              matInput
              placeholder="Your temporary password"
              type="password"
              autocomplete="off"
              required
              [(ngModel)]="tempPassword"
              (keyup.enter)="current.focus()"
              (ngModelChange)="errorMessage = ''"
            />
          </mat-form-field>
          <mat-form-field class="fw" appearance="outline">
            <mat-label>Create new password</mat-label>
            <input
              matInput
              #current
              placeholder="Create new password"
              type="password"
              autocomplete="off"
              required
              [(ngModel)]="password"
              (keyup.enter)="confirm.focus()"
              (ngModelChange)="errorMessage = ''"
            />
          </mat-form-field>
          <mat-form-field class="fw" appearance="outline">
            <mat-label>Re-enter new password</mat-label>
            <input
              matInput
              #confirm
              placeholder="Re-enter new password"
              type="password"
              autocomplete="off"
              required
              [(ngModel)]="passwordConfirm"
              (keyup.enter)="submit()"
              (ngModelChange)="errorMessage = ''"
            />
          </mat-form-field>

          <div *ngIf="errorMessage" class="error appear_1">
            <span class="text">{{ errorMessage }}</span>
          </div>
          <div class="subsubtitle m-3">
            {{ hint }}
          </div>
          <button mat-button class="celo-primary-button" (click)="submit()">
            NEXT
          </button>
        </div>
      </div>
    </div>
    <div
      class="col-xl-4 col-lg-2 align-self-end d-flex justify-content-start"
    ></div>
  </div>
</div>
